import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'stringSubstr'
})
export class StringSubstrPipe implements PipeTransform {
  transform(value: string): string {
    let string = value.toLowerCase().trim();
    if (string.includes('t.s.')) {
      console.log('1', string);
      string = string.replace('t.s.', '');
    }

    if (string.includes('(loe)')) {
      console.log('2', string);
      string = string.replace('(loe)', '');
    }

    if (string.includes('-')) {
      console.log('4', string);
      string = string.replace('-', '');
    }

    if (string.includes('(ani)')) {
      console.log('4', string);
      string = string.replace('(ani)', '');
    }

    if (string.includes('en ')) {
      console.log('5', string);
      string = string.replace('en ', '');
    }

    if (string.includes('daw')) {
      console.log('6', string);
      string = string.replace('daw', '');
    }

    if (string.includes('smr')) {
      console.log('7', string);
      string = string.replace('smr', '');
    }

    if (string.includes('dam')) {
      console.log('8', string);
      string = string.replace('dam', '');
    }

    if (!string.includes('(loe)') && !string.includes('-') && !string.includes('en ') && string.includes('daw')
      && !string.includes('t.s.') && string.includes('(ani)') && string.includes('smr') && string.includes('dam')) {
      string = value
    }

    console.log('final', string);
    return string.toUpperCase().trim();
  }
}
