import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Rutas} from '../../../../utiles/Rutas';

@Injectable({
  providedIn: 'root'
})
export class ComercialService {


  private _alumnoPromocion: any = null;

  constructor(private http: HttpClient) {}


  getSelects() {
    return this.http.get(Rutas + 'get/selects?token=' + localStorage.getItem('token'));
  }

  getData() {
    return this.http.get(Rutas + 'get/contacto_matricula?token=' + localStorage.getItem('token'));
  }
  postContactoMatricula(form) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const data = JSON.stringify(form);
    console.log(data);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    return this.http.post(Rutas + 'add/contacto_matricula', params, {headers: headers});
  }

  updateEstado(contacto: number, estado: number) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const data = JSON.stringify({contacto, estado});
    console.log(data);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    return this.http.post(Rutas + 'update/estado_contacto', params, {headers: headers});
  }

  addLinea(observacion, contacto) {
  const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
  const data = JSON.stringify({observacion, contacto});
  console.log(data);
  const params = 'json=' + data + '&token=' + localStorage.getItem('token');
  return this.http.post(Rutas + 'add/linea', params, {headers: headers});
  }

  addReferido(referido) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const data = JSON.stringify({referido});
    console.log(data);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    return this.http.post(Rutas + 'add/referido_matricula', params, {headers: headers});
  }

  addMedio(medio) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const data = JSON.stringify({medio});
    console.log(data);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    return this.http.post(Rutas + 'add/medio_comercial', params, {headers: headers});
  }

  addCicloofertado(ciclo, aforo, cursoEscolar) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const data = JSON.stringify({ciclo, aforo, cursoEscolar});
    console.log(data);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    return this.http.post(Rutas + 'add/ciclo_ofertado', params, {headers: headers});
  }

  getDiarios() {
    return this.http.get(Rutas + 'get/contactos_diarios?token=' + localStorage.getItem('token'));
  }

  getPlazas(year) {
    return this.http.get(Rutas + 'get/plazas?curso=' + year + '&token=' + localStorage.getItem('token'));
  }


  getCursosEscolares() {
    return this.http.get(Rutas + 'get/cursos_escolares?token=' + localStorage.getItem('token'));
  }

  getCursosInitPieData() {
    return this.http.get(Rutas + 'get/init_pie?token=' + localStorage.getItem('token'));
  }

  getCursosPieData(estado) {
    return this.http.get(Rutas + 'get/pie?estado=' + estado + '&token=' + localStorage.getItem('token'));
  }

  getInitPolar() {
    return this.http.get(Rutas + 'get/init_polar?token=' + localStorage.getItem('token'));
  }

  getCursosPolarData(estado) {
    return this.http.get(Rutas + 'get/polar?curso=' + estado + '&token=' + localStorage.getItem('token'));
  }
  getBarrasData() {
    return this.http.get(Rutas + 'get/barras?token=' + localStorage.getItem('token'));
  }
  getBarrasTotalData() {
    return this.http.get(Rutas + 'get/barras_total?token=' + localStorage.getItem('token'));
  }
  getTotalesData() {
    return this.http.get(Rutas + 'get/totales?token=' + localStorage.getItem('token'));
  }

  updateDatosComercialContacto(json) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    return this.http.post(Rutas + 'update/contacto_matricula', params, {headers: headers});
  }


  setPromo(value){
    this._alumnoPromocion = value;
  }
  getPromo(){
    return this._alumnoPromocion;
  }
  resetPromo(){
    this._alumnoPromocion = null;
  }


}
