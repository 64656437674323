<section id="asignaturas">
  <div class="row cajaasignaturas">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">Asignaturas</h5>
        </div>
        <div class="row cajacajas">
          <div class="col-3" *ngFor="let asignatura of asignaturas">

            <div class="paneles" [routerLink]="['/alumno/estado',asignatura.id]">
              <i> {{asignatura.asignatura}}</i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
