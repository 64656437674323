import {Component, ViewChild, OnInit, OnDestroy} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {LoginService} from '../services/login.service';
import {Subscription} from 'rxjs/Subscription';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit, OnDestroy {
  @ViewChild('f') loginForm: NgForm;
  private authSubscription: Subscription = new Subscription();
  private logoSubscription: any;
  private roleSubscription: Subscription = new Subscription();

  public logo: string;
  public title: string;
  public saveUsername: boolean;
  fieldTextType: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _login: LoginService
  ) {

    if (this._login.getIdentity()) {
      this._login.checkToken();
      this._login.getImages();
    }
  }

  ngOnDestroy() {
    this.authSubscription.unsubscribe();
    this.logoSubscription.unsubscribe();
    this.roleSubscription.unsubscribe();
  }

  ngOnInit() {
    this.logoSubscription = this._login.getLogo().subscribe(data => {
     /* this.logo = RutasImg + data['id'].logo;
      console.log(this.logo);
      this.title = data['id'].title;*/
    });
    this.authSubscription = this._login.auth$.subscribe(auth => {
      if (auth) {
        this.roleSubscription = this._login.role$.subscribe(res => {
          if (res === 'ROLE_ADMIN') {
            this.router.navigate(['/admin/stats'], {
              relativeTo: this.route
            });

          } else if (res === 'ROLE_PROFESOR') {
            this.router.navigate(['/maestro/clases'], {
              relativeTo: this.route
            });

          } else if (res === 'ROLE_ALUMNO') {
            this.router.navigate(['/alumno/asignaturas'], {
              relativeTo: this.route
            });
          } else if (res === 'ROLE_PADRE') {
            this.router.navigate(['/padre/lista'], {
              relativeTo: this.route
            });
          }
        });
      }
    });

  }

  onSubmit() {
    if (this.loginForm.valid) {
      this._login.login(
        this.loginForm.value.username,
        this.loginForm.value.password
      );
      this._login.auth$.subscribe(auth => {
        if (auth) {
          this.roleSubscription = this._login.role$.subscribe(res => {
            if (res === 'ROLE_ADMIN' || res === 'ROLE_ADMINISTRATIVO') {
              this.router.navigate(['/admin/stats'], {
                relativeTo: this.route
              });
            } else if (res === 'ROLE_PROFESOR') {
              this.router.navigate(['/maestro/clases'], {
                relativeTo: this.route
              });
            } else if (res === 'ROLE_ALUMNO') {
              this.router.navigate(['/alumno/asignaturas'], {
                relativeTo: this.route
              });
            } else if (res === '') {
              this.router.navigate(['/padre/lista'], {
                relativeTo: this.route
              });
            }
          });
        }
      });
    }
  }

  // On Forgot password link click
  onForgotPassword() {
    this.router.navigate(['forgot'], {relativeTo: this.route.parent});
  }


  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
}
