<h4 class="info-top"><i class="ft-user"></i> Cursos Extracurriculares: {{dato?.nombre}} {{dato?.apellidos}} </h4>
<div class="row">
  <div class="col-md-6 mb-5">
    <select class="form-control" [ngModel]="cursillo" (ngModelChange)="addAlumnoCursillo($event)">
      <option selected value= null>Seleccionar Curso Extracurricular</option>
      <option *ngFor="let cv of cursosIntensivos" [ngValue]="cv.id"> {{cv.cursillo}}</option>
    </select>
  </div>


</div>


  <div class="table-responsive" *ngIf="Ce.length > 0">
    <table class="table" style="table-layout: fixed">
      <thead>
      <tr class="d-flex">
        <th class="col-3" scope="col">Curso Extracurricular</th>
        <th class="col-2" scope="col">Fecha y hora Inicio</th>
        <th class="col-2" scope="col">Fecha y hora Fin</th>
        <th class="col-1" scope="col">Año</th>
        <th class="col-2" scope="col">Estado</th>
        <th class="col-2" scope="col">Acciones</th>
      </tr>
      </thead>
      <tbody>
      <tr class="d-flex" *ngFor="let cursoE of Ce; let i = index">
        <td class="col-3" style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
          <li class="list-inline-item danger p-0" matTooltip= "{{cursoE.cursillo}} --> {{cursoE.descripcion}}"
              (mouseenter)="$event.stopImmediatePropagation()"
              (mouseleave)="$event.stopImmediatePropagation()"
              #tooltip="matTooltip" (click)="tooltip.toggle()">
            <i class="fa fa-info-circle font-medium-2"></i>
          </li>
          {{cursoE.cursillo}}
        </td>
        <td class="col-2">{{cursoE.inicio?.date | date:'dd/MM'}} -- {{cursoE.fin?.date | date:'dd/MM'}}</td>
        <td class="col-2">{{cursoE.hInicio}} -- {{cursoE.hFin}}</td>
        <td class="col-1">{{cursoE?.anyo}}</td>
        <td class="col-2">
          <div *ngIf="cursoE.finalizado === true"> FINALIZADO</div>
          <div *ngIf="cursoE.finalizado === false"> EN PROCESO</div>
        </td>
        <td class="col-2">
          <ul class="list-inline">
            <li class="list-inline-item dark p-0" (click)="open(pdf, cursoE)">
              <i class="fa fa-print font-medium-3 mr-2"></i>
            </li>
            <li class="list-inline-item danger p-0" (click)="delete(cursoE.id)">
              <i class="fa fa-trash font-medium-3 mr-1"></i>
            </li>
          </ul>
        </td>
      </tr>
      </tbody>
    </table>
  </div>



<ng-template #pdf let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Exportar a PDF</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">

        <div class="input-group">
          <label for="typeahead-basic1" class="input-group-text">Fecha de matricula: </label>
          <input id="typeahead-basic1" type="date" class="form-control" [ngModel]="fechaActual | date:'yyyy-MM-dd'"
                 (ngModelChange)="fechaActual=$event" name="something4">
        </div>
        <div class="input-group">
          <label for="typeahead-basic2" class="input-group-text">Fotocopia DNI: </label>

          <input id="typeahead-basic2" type="checkbox" class="form-control unique" [ngModel]="adjuntos.dni" (ngModelChange)="adjuntos.dni=$event"
                  name="something4">
        </div>
        <div class="input-group">
          <label for="typeahead-basic3" class="input-group-text">Fotocopia DNI: </label>

          <input id="typeahead-basic3" type="checkbox" class="form-control unique" [ngModel]="adjuntos.sip" (ngModelChange)="adjuntos.sip=$event"
                 name="something4">
        </div>
        <div class="input-group">
          <label for="typeahead-basic4" class="input-group-text">Observaciones: </label>

          <textarea id="typeahead-basic4"class="form-control" [ngModel]="observaciones" (ngModelChange)="observaciones=$event"
                    name="something4"></textarea>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">

    <button type="button" class="btn btn-outline-dark" (click)="save()">Exportar matricula</button>
  </div>
</ng-template>
