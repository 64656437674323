import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Subject} from 'rxjs';
import {Rutas} from '../../../../utiles/Rutas';
import {LoginService} from '../../../pages/services/login.service';
import Swal from 'sweetalert2';

@Injectable()
export class ClasesMService {

  private clasesSource = new Subject<any>();
  public clases$ = this.clasesSource.asObservable();

  private alumnosSource = new Subject<any>();
  public alumnos$ = this.alumnosSource.asObservable();

  private asignaturasSource = new Subject<any>();
  public asignaturas$ = this.asignaturasSource.asObservable();

  private idExamenSource = new Subject<any>();
  public idExamen = this.idExamenSource.asObservable();

  private examenesSource = new Subject<any>();
  public examenes$ = this.examenesSource.asObservable();

  private estadoSource = new Subject<boolean>();
  public estado$ = this.estadoSource.asObservable();

  constructor (private http: HttpClient, private _login: LoginService) {
  }

  activar (id: number, check: boolean, idAsig) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const valor = {
      id: id,
      check: check,
      asignatura: idAsig
    };
    const json = {valor};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'update/estado/examen', params, {headers: headers}).subscribe(res => {
      if (res['code'] === 200) {
      }
    }, error => {
      console.log(error);
    });
  }

  addEstado (examen: number, check: boolean) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const valor = {
      examen: examen,
      check: check
    };
    const json = {valor};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'add/visible', params, {headers: headers}).subscribe(res => {
      if (res['code'] === 200) {
        this.estadoSource.next(res['id']);
      }
    }, error => {
      console.log(error);
    });
  }

  updateEstado (check: boolean, id: number) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const valor = {
      check: check,
      id: id
    };
    const json = {valor};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'update/visible', params, {headers: headers}).subscribe(res => {
      if (res['code'] === 200) {
      }
    }, error => {
      console.log(error);
    });
  }

  getAlumnoExamen (id: number, curso: number) {
    this.http.get(Rutas + 'examenes/alumnos?id=' + id + ' &curso= ' + curso + '&token=' + localStorage.getItem('token'))
      .subscribe(data => {
        if (data['code'] === 200) {
          this.alumnosSource.next(data['id']);
        }
      }, err => {
        console.log(err);
      });
  }

  getAlumnosExamenes (id: number, curso: number) {
    return this.http.get(Rutas + 'examenes/alumnos?id=' + id + ' &curso= ' + curso + '&token=' + localStorage.getItem('token'));
  }


  deleteFileExamen (id: number) {
    this.http.get(Rutas + 'delete/file/examen?id=' + id + '&token=' + localStorage.getItem('token'))
      .subscribe(data => {
        if (data['code'] === 200) {
          this.examenesSource.next(data['id']);
        }
      }, err => {
        console.log(err);
      });
  }


  getFiles (id: number) {
    this.http.get(Rutas + 'examen/files?id=' + id + '&token=' + localStorage.getItem('token'))
      .subscribe(data => {
        if (data['code'] === 200) {
          this.examenesSource.next(data['id']);
        }
      }, err => {
        console.log(err);
      });
  }

  addNota (nota: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {nota};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'add/nota', params, {headers: headers}).subscribe(res => {
      if (res['code'] === 200) {
        this.alumnosSource.next(res['id']);
      }
    }, error => {
      console.log(error);
    });
  }

  notaAdd (nota: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {nota};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    return this.http.post(Rutas + 'add/nota', params, {headers: headers});
  }

  updateNota (nota: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {nota};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'update/nota', params, {headers: headers}).subscribe(res => {
      if (res['code'] === 200) {
        this.examenesSource.next(data['id']);
      }
    }, error => {
      console.log(error);
    });
  }


  getExamenesEntregados (id: number) {
    this.http.get(Rutas + 'examenes/entregados?id=' + id + '&token=' + localStorage.getItem('token'))
      .subscribe(data => {
        if (data['code'] === 200) {
          this.examenesSource.next(data['id']);
        }
      }, err => {
        console.log(err);
      });
  }

  getExamenesNoEntregados (id: number) {
    this.http.get(Rutas + 'examenes/no_entregados?id=' + id + '&token=' + localStorage.getItem('token'))
      .subscribe(data => {
        if (data['code'] === 200) {
          this.examenesSource.next(data['id']);
        }
      }, err => {
        console.log(err);
      });
  }


  getExamenes (id_asignatura: number, aula: number) {
    const id = this._login.getIdentity().id;
    this.http.get(Rutas + 'get/examenes?id=' + id + '&asignatura=' + id_asignatura + '&aula=' + aula + '&token=' + localStorage.getItem('token'))
      .subscribe(data => {
        if (data['code'] === 200) {
          this.examenesSource.next(data['id']);
        }
      }, err => {
        console.log(err);
      });
  }

  updateExamen (examen: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {examen};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'update/examen', params, {headers: headers})
      .subscribe(res => {
      if (res['code'] === 200) {
        this.examenesSource.next(data['id']);
      }
    }, error => {
      console.log(error);
    });
  }

  deleteExamen (id: number) {
    Swal.fire({
      title: '',
      text: 'Deseas eliminar este examen?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.http.get(Rutas + 'delete/examen?id=' + id + '&token=' + localStorage.getItem('token'))
          .subscribe(data => {
            if (data['code'] === 200) {
              Swal.fire('', 'El examen fue eliminado', 'success');
              this.examenesSource.next(data['id']);
              setTimeout(() => { document.location.reload(); }, 2000);
            }
          }, err => {
            console.log(err);
          });
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Swal.fire('', 'No fue eliminado el examen', 'error');
      }
    })
  }

  getCursos () {
    const id = this._login.getIdentity().id;
    this.http.get(Rutas + 'maestro/cursos?id=' + id +  '&token=' + localStorage.getItem('token'))
      .subscribe(data => {
        if (data['code'] === 200) {
          this.clasesSource.next(data['id']);
        }
      }, err => {
        console.log(err);
      });
  }


  getClases () {
    const id = this._login.getIdentity().id;
    this.http.get(Rutas + 'maestro/clases?id=' + id + '&token=' + localStorage.getItem('token'))
      .subscribe(data => {
        if (data['code'] === 200) {
          this.clasesSource.next(data['id']);
        }
      }, err => {
        console.log(err);
      });
  }

  getAlumnos (id: number) {
    this.http.get(Rutas + 'maestro/alumnos?id=' + id + '&token=' + localStorage.getItem('token'))
      .subscribe(data => {
        if (data['code'] === 200) {
          this.alumnosSource.next(data['id']);
        }
      }, err => {
        console.log(err);
      });
  }

  asignaturasHoy (curso: number) {
    // tslint:disable-next-line:max-line-length
    this.http.get(Rutas + 'get/asignaturas/hoy?usuario=' + this._login.getIdentity().id + ' &aula= ' + curso + '&dia=' + '&token=' + localStorage.getItem('token'))
      .subscribe(data => {
        if (data['code'] === 200) {
          this.asignaturasSource.next(data['id']);
        }
      }, err => {
        console.log(err);
      });
  }

  getAsignaturas (id: number) {
    this.http.get(Rutas + 'maestro/asignaturas?id=' + id + '&token=' + localStorage.getItem('token'))
      .subscribe(data => {
        if (data['code'] === 200) {
          this.asignaturasSource.next(data['id']);
        }
      }, err => {
        console.log(err);
      });
  }

  addExamen (examen: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    examen.user = this._login.getIdentity().id;
    const json = {examen};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'add/examen' , params, {headers: headers})
      .subscribe(res => {
      if (res['code'] === 200) {
        console.log(data);
        this.idExamenSource.next(res['id']);
      }
    }, error => {
      console.log(error);
    });
  }

  addCreateExamen (examen: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {examen};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'add/html/examen', params, {headers: headers})
      .subscribe(res => {
      if (res['code'] === 200) {
        this.idExamenSource.next(res['id']);
      }
    }, error => {
      console.log(error);
    });
  }

  noteUpdate(datos) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {datos};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
   return this.http.post(Rutas + 'update/nota', params, {headers: headers});
  }

}
