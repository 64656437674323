import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';


@Component({
  selector: 'app-alumnos',
  templateUrl: './alumnos.component.html',
  styleUrls: ['./alumnos.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AlumnosComponent {
  public clase: Array<any> = [];
  public claseSubscription: Subscription = null;
  public id: number;
  public type: string;
  navLinks = [{}];

  constructor (private route: ActivatedRoute, private router: Router) {
    this.navLinks = [
      {path: 'datos', label: 'Datos'},
      {path: 'examenes', label: 'Examenes'},
      {path: 'notas-finales', label: 'Notas'},
      {path: 'asistencias', label: 'Asistencias'},
      {path: 'padres', label: 'Padres'}];
  }

}

