
<div class="row" >

  <div class="col-12">
    <div class="row">

      <div class="col-xl-2 col-md-3
      mrcol conti" *ngFor="let alumno of alumnos" [id]="alumno.id" (click)="open(alumno.id)">


        <div  class="chead" >
          <img class="avatar" [src]="alumno.img | images: ''" alt="..." width="100">
        </div>
            <div class="row " >



            <div class="col-12" style="text-align: center">

                <mat-card-title>{{alumno.nombre | titlecase}}</mat-card-title><br>
                <mat-card-subtitle>{{alumno.apellidos | titlecase}}</mat-card-subtitle>


            </div>


      </div>
    </div>


  </div>


</div>
</div>

