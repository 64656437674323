import {Component, OnInit, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {LoginService} from '../../../pages/services/login.service';
import {InboxService} from '../../inbox/inbox.service';
import {Subscription} from 'rxjs/Subscription';
import {UsersService} from '../../admin-users/services/users.service';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit, OnDestroy {
  datos: any = {};
  mensajes: any = {};
  numeroSubscription = new Subscription();
  search: string;
  items: any[] = [];
  tipo: string;
  aula: number;

  constructor(private router: Router, private _login: LoginService,
              private _inbox: InboxService, private _user: UsersService) {
    this.datos = JSON.parse(localStorage.getItem('identity'));
    this._inbox.mensajesNoLeidos();
  }

  /*searchAction() {
    /!*    console.log(this.items.length + ' cantidad de items');
        console.log(this.search.length + ' cantidad de caracteres');
        console.log(this.tipo + ' ' + this.aula + ' tipo y aula');*!/
    if (this.tipo !== undefined) {
      if (this.search.length > 0) {
        const valor = this.items.filter((item) => {
          const nombre = item.nombre + ' ' + item.apellidos;
          return nombre.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
        });
        this._user.usersSource.next(valor);
      } else {
        this._user.getUsers(this.tipo, this.aula);
      }
    }
  }*/

  logout() {
    this._login.logout();
  }

  editar() {
    this.router.navigate(['/admin/profile']);
  }

  ngOnInit() {
    this.numeroSubscription = this._inbox.numero$.subscribe(res => {
      this.mensajes = res;
    });
    this._user.users$.subscribe(resp => {
      if (resp.tipo !== undefined) {
        this.tipo = resp['tipo'];
        this.aula = resp['aula'];
      }
      this.items = resp;
    });
  }

  ngOnDestroy() {
    this.numeroSubscription.unsubscribe();
  }
}
