import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Subject} from 'rxjs/Subject';
import {Router} from '@angular/router';
import {Rutas} from '../../../../utiles/Rutas';
import {LoginService} from '../../../pages/services/login.service';
import swal from 'sweetalert2';
import {consoleTestResultHandler} from 'tslint/lib/test';
import {NgxSpinnerService} from 'ngx-spinner';


@Injectable()
export class AulasService {
  private cursoCentroSource = new Subject<any>();
  public cursoCentro$ = this.cursoCentroSource.asObservable();

  private cursosSource = new Subject<any>();
  public cursos$ = this.cursosSource.asObservable();

  private cursosFilterSource = new Subject<any>();
  public cursosFilter$ = this.cursosFilterSource.asObservable();

  private cursoBoletinSource = new Subject<any>();
  public cursoBoletin$ = this.cursoBoletinSource.asObservable();

  private centroSource = new Subject<any>();
  public centro$ = this.centroSource.asObservable();

  private cursoSource = new Subject<any>();
  public curso$ = this.cursoSource.asObservable();

  private anyoCursoSource = new Subject<any>();
  public anyoCurso = this.anyoCursoSource.asObservable();


  private menuSource = new Subject<string>();
  public menu$ = this.menuSource.asObservable();

  constructor(private http: HttpClient, private router: Router, private _login: LoginService,
              private spinner: NgxSpinnerService) {
  }

  getMenu(valor: string) {
    this.menuSource.next(valor);
  }


  edit(curso: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {curso};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'edit/curso', params, {headers: headers}).subscribe(datas => () =>
      error => {
        console.log(error);
      });
  }

  add(curso: any) {
    curso.id = this._login.getIdentity().centro.id;
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {curso};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'add/cursos', params, {headers: headers}).subscribe(datas => {
      console.log(datas);
      if (datas['code'] === 200) {
        this.cursosSource.next(datas['id']);
        this.router.navigate(['/admin/aulas/lista']);
      }
    });
  }


  getAnyoCurso(id: number) {
    this.http.get(Rutas + 'get/anyo/curso?id=' + id + '&token=' + localStorage.getItem('token'))
      .subscribe(data => {
        if (data['code'] === 200) {
          this.anyoCursoSource.next(data['id']);
        } else {
          return false;
        }
      });
  }

  getAsignatura(id: number) {
    this.http.get(Rutas + 'get/asignatura?id=' + id + '&token=' + localStorage.getItem('token'))
      .subscribe(data => {
        if (data['code'] === 200) {
          this.anyoCursoSource.next(data['id']);
        } else {
          return false;
        }
      });
  }

  getCursosCentro() {
    const id = this._login.getIdentity().centro.id;
    this.http.get(Rutas + 'cursos/centro?id=' + id + '&token=' + localStorage.getItem('token'))
      .subscribe(data => {
        if (data['code'] === 200) {
          this.cursoCentroSource.next(data['id']);
        } else {
          return false;
        }
      });
  }

  getCursos(id: number) {
    this.http.get(Rutas + 'get/cursos?id=' + id + '&token=' + localStorage.getItem('token'))
      .subscribe(data => {
        if (data['code'] === 200) {
          this.cursosSource.next(data['id']);
        } else {
          return false;
        }
      });
  }

  getCursosFilter(id: number) {
    this.http.get(Rutas + 'get/cursosss/filtered?id=' + id + '&token=' + localStorage.getItem('token'))
      .subscribe(data => {
        if (data['code'] === 200) {
          console.log(data);
          this.cursosFilterSource.next(data['id']);
        } else {
          return false;
        }
      });
  }

  getCurso(id: number) {
    this.http.get(Rutas + 'get/curso?id=' + id + '&token=' + localStorage.getItem('token'))
      .subscribe(data => {
        console.log(data);
        if (data['code'] === 200) {
          this.cursoSource.next(data['id']);
        } else {
          return false;
        }
      });
  }

  deleteCursos(id: number) {
    this.http.get(Rutas + 'delete/curso?id=' + id + '&token=' + localStorage.getItem('token'))
      .subscribe(data => {
        if (data['code'] === 200) {
          this.cursoSource.next(data['id']);
          swal.fire( 'Mensaje', 'Se ha eliminado ', 'success');
        } else {
          return false;
        }
      });
  }

  getCursoBoletin(id: number) {

    this.http.get(Rutas + 'get/curso/boletin?id=' + id + '&token=' + localStorage.getItem('token'))
      .subscribe(data => {
        if (data['code'] === 200) {
          this.cursoBoletinSource.next(data['id']);
          console.log(data['id']);
        } else {
          console.log(data['id']);
          return false;
        }
      });
  }

  getBoletin(curso, tipo) {
   return this.http.get(Rutas + 'get/boletin/curso?curso=' + curso + '&tipo=' + tipo + '&token=' + localStorage.getItem('token'));
  }

  getClaustro(curso, tipo) {
    return this.http.get(Rutas + 'get/claustro/curso?curso=' + curso + '&tipo=' + tipo + '&token=' + localStorage.getItem('token'));
  }

  getCentro() {
    const id = this._login.getIdentity().centro.id;
    this.http.get(Rutas + 'centro?token=' + localStorage.getItem('token') + '&id=' + id)
      .subscribe(data => {
        if (data['code'] === 200) {
          this.centroSource.next(data['id']);
          console.log(data['id']);
        } else {
          console.log(data['id']);
          return false;
        }
      });
  }

  generateBoletin(datas, fecha) {
    this.spinner.show();
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const datos = {
      html: datas,
      date: fecha
    };
    const json = {datos};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'get/pdf', params, {headers: headers}).subscribe(res => {
      console.log(res);
      if ( res['code'] === 200) {
        this.spinner.hide();
      window.open(Rutas + res['id']);

      }
      });
  }

  getAulasFisicas() {
    return this.http.get(Rutas + 'get/clases_fisicas?token=' + localStorage.getItem('token'));
  }

  addAulaFisica(numClase) {

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {numClase};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    return this.http.post(Rutas + 'add/clase_fisica', params, {headers: headers})
  }

  updateAlquilable(numClase, alquilable) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {numClase, alquilable};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    return this.http.post(Rutas + 'update/alquilable', params, {headers: headers})
  }

  updateNumClase(numClase, id) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {numClase, id};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    return this.http.post(Rutas + 'update/numero_clase', params, {headers: headers})
  }

  updateClase(clase) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = clase;
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    return this.http.post(Rutas + 'update/clase_fisica', params, {headers: headers})
  }

  generateNotasFinales(id: number) {
    return this.http.get(Rutas + 'generate_notas_finales_aula?aula=' + id + '&token=' + localStorage.getItem('token'))

  }

}
