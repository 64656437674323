<h4 class="info-top">
  <i class="ft-home"></i> Crear aula</h4>
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <div class="px-3">
          <form class="form  form-horizontal striped-rows form-bordere" novalidate="" (ngSubmit)="onSubmit()"
                #f="ngForm">
            <input type="hidden" [id]="id" name="id">
            <div class="form-body">
              <div class="form-group row">
                <label class="col-md-3 label-control">Modulo</label>
                <div class="col-md-5">
                  <select class="form-control" [(ngModel)]="nivel" name="curso_centro">
                    <ng-container *ngIf="cursosCentro.length > 0">
                      <option [value]="undefined">Selecciona un modulo...</option>
                      <option selected *ngFor="let cursoCentro of cursosCentro" (click)="showAnyoCurso(cursoCentro.id)"
                              [ngValue]="cursoCentro.id" required>{{cursoCentro.nivel}} - {{cursoCentro.acronimo}}
                      </option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-3 label-control">Letra aula</label>
                <div class="col-md-5">
                  <input type="text" class="form-control" placeholder="Nombre aula" [(ngModel)]="aula" required
                         name="aula">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-3 label-control">Ratio</label>
                <div class="col-md-5">
                  <input type="number" class="form-control" placeholder="Ratio del aula" [(ngModel)]="ratio" required
                         name="ratio">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-3 label-control">Año</label>
                <div class="col-md-5">
                  <select class="form-control" [(ngModel)]="anyoCurso" name="anyo_curso">
                    <option [value]="undefined">Selecciona el año del curso</option>
                    <option value=1>1º</option>
                    <option value=2>2º</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-3 label-control">Turno</label>
                <div class="col-md-5">
                  <select class="form-control" [(ngModel)]="turno" name="turno" (change)="filtro()" disabled>
                    <option [value]="undefined">Selecciona el turno</option>
                    <option value="1">Mañana</option>
                    <option value="0">Tarde</option>
                  </select>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-md-3 label-control">Clase</label>
                <div class="col-md-5">
                  <select class="form-control" [(ngModel)]="aulaSeleccionada" name="clase" [disabled]="aulasFiltered.length === 0" >
                    <option [value]="undefined">Selecciona el número de Aula</option>
                    <option *ngFor="let aula of aulasFiltered" [value]=aula.id >{{aula.numeroClase}}</option>

                  </select>
                </div>
                <button type="button" class="btn success"><i class="ft-plus-circle" (click)="addFormaContacto()"></i>
                </button>
              </div>

              <div class="form-group row">
                <label class="col-md-3 label-control">Calendario inicio y fin</label>
                <div class="col-md-5">
                  <select class="form-control" [(ngModel)]="iniCurso" name="curso_evento" required>
                    <option *ngFor="let curso of eventoCurso; let i = index" [value]="curso.id">{{curso.evento}} - {{curso.inicio.date | date:'yyyy'}}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row" *ngIf="curso">
                <label class="col-md-3 label-control">Año escolar</label>
                <div class="col-md-5">
                  <input type="number" class="form-control" placeholder="Año escolar" [(ngModel)]="curso" required
                         name="curso">
                </div>
              </div>
              <div class="form-actions">
                <button type="submit" class="btn btn-raised btn-primary" [disabled]="!f.valid">
                  <i class="fa ft-save"></i> Guardar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
