import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ComercialService} from './services/comercial.service';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {DatePipe} from '@angular/common';
import {MatPaginator} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ExcelService} from './services/excel.service';
import {AddContactoComponent} from './modales/add-contacto/add-contacto.component';
import {EstadoCambioComponent} from './modales/estado-cambio/estado-cambio.component';
import {AddLineaComponent} from './modales/add-linea/add-linea.component';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-comercial',
  templateUrl: './comercial.component.html',
  styleUrls: ['./comercial.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ComercialComponent implements OnInit {

  public alumno = '';
  public fContacto = '';
  public email = '';
  public contacto = '';
  public telefono = '';
  public ciclo = '';
  public estado = '';
  public fecha = '';
  public refer = '';
  public curso = '';
  datos: any;
  dataSource: any;
  columnas = ['ciclo', 'alumno', 'fContacto', 'fecha', 'email', 'contacto', 'telefono', 'referido',  'estado', 'acciones'];
  actividadesColumn = ['fecha', 'observaciones'];
  expandedElement: any | null;

  lineaForm: FormGroup
  searchForm: FormGroup



  spinner = true;
  estados: any;
  referidos: any;
  formaContacto: any;
  ciclos: any;
  cursosEscolares: any;
  accionesList: any;
  exportCsvGoogle: any;

  alumnoPromocion: any;

  @ViewChild('input', { static: true }) inputDialog: TemplateRef<any>;
  @ViewChild('linea', { static: true }) lineaDialog: TemplateRef<any>;
  @ViewChild('seguro', { static: true }) seguroDialog: TemplateRef<any>;
  @ViewChild('refer', { static: true }) referDialog: TemplateRef<any>;

  @ViewChild('medio', { static: true }) medioDialog: TemplateRef<any>;
  @ViewChild('MatPaginator', {static: false}) paginator: MatPaginator;

  constructor( private comercialService: ComercialService, private dialog: MatDialog, private date: DatePipe,
               private snack: MatSnackBar, private exportService: ExcelService, private route: Router, private acRoute: ActivatedRoute) {


  }

  ngOnInit(): void {
    this.alumnoPromocion = this.comercialService.getPromo();
    this.comercialService.resetPromo();
    if (this.alumnoPromocion !== null) {
    this.addData();
    }
    this.dataSource = new MatTableDataSource();
    this.initForms();
    this.getSelectData();
    this.getData();
  }

  getSelectData() {
    this.comercialService.getSelects().subscribe( (res) => {
        console.log('Lista', res);
        this.estados = res['estados'];
        this.referidos = res['referidos'];
        this.formaContacto = res['forma_contacto'];
        this.ciclos = res['ciclos'];
        this.cursosEscolares = res['cursoEscolar'];
      }, error => {
        console.log('Ha fallado');
      }
    );
  }

  async initForms(): Promise<void> {

    this.searchForm = new FormGroup( {
      alumno: new FormControl(),
      fContacto: new FormControl(''),
      fecha: new FormControl(''),
      email: new FormControl(''),
      contacto: new FormControl(''),
      telefono: new FormControl(''),
      ciclo: new FormControl(''),
      referido: new FormControl(''),
      estado: new FormControl(),
      fromDate: new FormControl(),
      toDate: new FormControl(),
      curso: new FormControl(),
    });

  }



  getData() {
    this.comercialService.getData().subscribe( (res) => {
      console.log(res);
      this.dataSource.data = res;
      this.datos = res;
      this.dataSource.filterPredicate = this.getFilterPredicate();
      this.setFilters();
      this.spinner = false;
    });
  }

  setFilters(): void {
    this.dataSource.paginator = this.paginator;
  }

  addData() {
    const dialogRef = this.dialog.open(AddContactoComponent,
      { hasBackdrop: true, width: '90%', data: {element: this.alumnoPromocion , update: false }});
    dialogRef.afterClosed().subscribe( (result: FormGroup) => {
     if (result ) {
       if (result.controls['ciclo'].value === null) {
         result.controls['ciclo'].setValue('');
       }

       this.comercialService.postContactoMatricula(result.value).subscribe( (res) => {
         this.datos.unshift(res);
         this.dataSource.data =  this.datos;
       });
     }
    });
  }

  setElements(element) {
    console.log(element);
    this.accionesList = new MatTableDataSource(element.actividad_contactos);
    console.log(this.accionesList.data);

  }

  addLinea(element) {
    const dialogRef = this.dialog.open(AddLineaComponent, { hasBackdrop: true, width: '50%'});
    dialogRef.afterClosed().subscribe( (result: FormGroup) => {
      if (result ) {
        this.comercialService.addLinea(result.value.observacion, element.id).subscribe( (res) => {
          console.log(res);
          this.getData();
        });
      }
        });

  }

  changeEstado($event, contacto) {
    console.log($event.target.value, contacto);
    const dialogRef = this.dialog.open(EstadoCambioComponent, {hasBackdrop: true});
    dialogRef.afterClosed().subscribe( result => {
      if (result) {
        console.log($event.target.value);
        this.comercialService.updateEstado(contacto.id, $event.target.value).subscribe( (res) => {
          const resp = [];
          resp.push(res);
          const i = this.dataSource.data.findIndex(item => item.id === res['id']);
          this.dataSource.data[i] = res;
          if ( $event.target.value === '3') {
            // tslint:disable-next-line:max-line-length
            this.route.navigate(['admin/crear/alumnos'], {queryParams: {nombre: contacto.alumno.nombre, apellidos: contacto.alumno.apellidos}});
          }


        });
      }
    });
  }


  getFilterPredicate(): any {
    return ( row: any, filters: string) => {
      const filterArray = filters.split('$');
      const ciclo = filterArray[0];
      const alumno = filterArray[1];
      const fContacto = filterArray[2];
      const fecha = filterArray[3];
      const email = filterArray[4];
      const contacto = filterArray[5];
      const telefono = filterArray[6];
      const referido = filterArray[7];
      const estado = filterArray[8];
      const curso = filterArray[9];
      console.log(filterArray);



      const matchFilter = [];
      console.log(row);
      // Filtra datos de la row
      const columnAlumno = row.alumno.nombre && row.alumno.apellidos === null ? '' : row.alumno.nombre + ', ' + row.alumno.apellidos;
      const columnFContacto = row.forma_contacto.forma_contacto === null ? '' : row.forma_contacto.forma_contacto;
      const columnFecha = row.fecha === null ?  '' : this.date.transform(row.fecha, 'dd-MM-YYY');
      const columnEmail = row.infoContacto.mail === null ? '' : row.infoContacto.mail;
      // tslint:disable-next-line:max-line-length
      const columnContacto =  row.infoContacto.nombre && row.infoContacto.apellidos === null ? '' : row.infoContacto.nombre + ', ' + row.infoContacto.apellidos;
      const columnTelefono = row.infoContacto.telefono === null ? '' : row.infoContacto.telefono;
      const columnReferido = row.infoContacto.referido.descripcion === null ? '' : row.infoContacto.referido.descripcion;
      // tslint:disable-next-line:max-line-length
      const columnCiclo = row.ciclo.ciclo.ciclo && row.ciclo.ciclo.modalidad === null ? '' : row.ciclo.ciclo.ciclo + '-' + row.ciclo.ciclo.modalidad;
      const columnEstado = row.estado.id + '' === null ?  '' : row.estado.id + '';
      const columnCurso = row.curso_escolar === null ?  '' : row.curso_escolar.id + '';
      // const columnEntrada = row.fechaEntrada === null ?  '' : this.date.transform(row.fechaEntrada, 'dd-MM-YYY');
      // const columnSalida = row.fechaSalida === null ?  '' : this.date.transform(row.fechaSalida, 'dd-MM-YYY');




      // Verificamos que concuerda
      const customAlumno = columnAlumno.toLowerCase().includes(alumno);
      const customFContacto = columnFContacto.toLowerCase().includes(fContacto);
      const customFecha = columnFecha.toLowerCase().includes(fecha);
      const customEmail = columnEmail.toLowerCase().includes(email);
      const customContacto = columnContacto.toLowerCase().includes(contacto);
      const customTelefono = columnTelefono.toLowerCase().includes(telefono);
      const customReferido = columnReferido.toLowerCase().includes(referido);
      const customCiclo = columnCiclo.toLowerCase().includes(ciclo);
      const customEstado = columnEstado.toLowerCase().includes(estado);
      const customCurso = columnCurso.toLowerCase().includes(curso);
      // const customEntrada = columnEntrada.toLowerCase().includes(entrada);
      // const customSalida = columnSalida.toLowerCase().includes(salida);

      // Push de los que concuerdan ( true o false)
      matchFilter.push(customCiclo);
      matchFilter.push(customAlumno);
      matchFilter.push(customFContacto);
      matchFilter.push(customFecha);
      matchFilter.push(customEmail);
      matchFilter.push(customContacto);
      matchFilter.push(customTelefono);
      matchFilter.push(customReferido);
      matchFilter.push(customEstado);
      matchFilter.push(customCurso);
      // matchFilter.push(customEntrada);
      // matchFilter.push(customSalida);


      return matchFilter.every(Boolean);
    };
  }

  applyFilter(): void {
    console.log(this.searchForm.get('fContacto').value);
    const al = this.searchForm.get('alumno').value;
    const fcon = this.searchForm.get('fContacto').value;
    const fec = this.date.transform(this.searchForm.get('fecha').value, 'dd-MM-yyyy');
    const ema = this.searchForm.get('email').value;
    const cont = this.searchForm.get('contacto').value;
    const telf = this.searchForm.get('telefono').value;
    const ref = this.searchForm.get('referido').value;
    const cicl = this.searchForm.get('ciclo').value;
    const est = this.searchForm.get('estado').value;
    const cur = this.searchForm.get('curso').value;
    // const ent = this.date.transform(this.searchForm.get('entrada').value, 'dd-MM-yyyy');
    // const sal = this.date.transform(this.searchForm.get('salida').value, 'dd-MM-yyyy');

    this.alumno = al === null ? '' : al;
    this.fContacto = fcon === null ? '' : fcon;
    this.fecha = fec === null ? '' : fec;
    this.email = ema === null ? '' : ema;
    this.contacto = cont === null ? '' : cont;
    this.telefono = telf === null ? '' : telf;
    this.ciclo = cicl === null ? '' : cicl;
    this.refer = ref === null ? '' : ref;
    this.estado = est === null ? '' : est;
    this.curso = cur === null ? '' : cur;
    // this.salida = sal === null ? '' : sal;


    // Creamos el string para busqueda separados por $
    // tslint:disable-next-line:max-line-length
    const  filterValue = this.ciclo + '$' + this.alumno + '$' + this.fContacto + '$' + this.fecha + '$' + this.email  + '$' + this.contacto + '$' + this.telefono + '$'
     + this.refer + '$'  + this.estado + '$' + this.curso;

    this.dataSource.filter = filterValue.trim().toLowerCase();

  }


  getDateRange(): void {
    if (this.searchForm.get('fromDate').value && this.searchForm.get('toDate').value) {
      this.dataSource.data = this.datos;
      // getting date from calendar
      const fromDate = this.searchForm.get('fromDate').value;
      const toDate = this.searchForm.get('toDate').value;

      this.dataSource.data = this.dataSource.data
        .filter(e => new Date(e.fecha) >= fromDate && new Date(e.fecha) <= toDate)
        .sort((a, b) => a.fecha - b.fecha);

    }

  }

  resetFechas(): void {
    this.dataSource.data = this.datos;
    this.searchForm.get('fromDate').reset();
    this.searchForm.get('toDate').reset();
  }

  resetAll(): void {
    this.resetFechas();
    this.searchForm.reset();
    this.dataSource.filter = '';
  }

  exportDatosAlumnos() {
    this.exportGoogleCSV();
    this.exportService.exportExcel(this.exportCsvGoogle, 'Reporte' + Date.now());
  }

  exportGoogleCSV() {
    this.exportCsvGoogle = [];
    for (const a of this.dataSource.filteredData) {
      this.exportCsvGoogle.push({
        'Ciclo': a.ciclo.ciclo.ciclo?.trim() + '-' + a.ciclo.ciclo.modalidad?.trim(),
        'Alumno': a.infoContacto.nombre?.trim() + ' ' + a.infoContacto.apellidos?.trim() ,
        'FormaContacto': a.forma_contacto.forma_contacto?.trim(),
        'Fecha': a.fecha?.trim(),
        'Email': a.infoContacto.mail?.trim(),
        'Contacta': a.alumno.nombre?.trim() + ' ' + a.alumno.apellidos?.trim(),
        'Telefono': a.infoContacto.telefono?.trim(),
        'Referido': a.infoContacto.referido.descripcion?.trim(),
        'Estado': a.estado.descripcion?.trim()
      });
    }
  }

  editar(element, index) {
    console.log(element);
    const dialogRef = this.dialog.open(AddContactoComponent, {
      hasBackdrop: true,
      width: '90%',
      data: {element , update: true}
    });
    // console.log(index);
    dialogRef.afterClosed().subscribe( (result: FormGroup) => {
      if (result) {
        // console.log(result);
        if (result.controls['ciclo'].value == null) {
          result.controls['ciclo'].setValue('');
        }
        const json = {
          id: result.value.id,
          alumno: {
            id: result.value.id,
            nombre: result.value.alNombre,
            apellidos: result.value.alApellidos
          },
          cicloId: result.value.ciclo,
          cursoEscolarId: result.value.cursoEscolar,
          formaContactoId: result.value.formaContacto,
          infoContacto: {
            id: result.value.id,
            apellidos: result.value.contactoApellidos,
            mail: result.value.email,
            nombre: result.value.contactoNombre,
            telefono: result.value.telefono,
          },
          referidoId: result.value.referido
        };

        // console.log(json);
        this.comercialService.updateDatosComercialContacto(json).subscribe( (res: any) => {
          console.log(res);
          const foundIndex = this.dataSource.data.findIndex(x => x.id === res.id);
          this.dataSource.data[foundIndex] = res;
          console.log(this.dataSource.data[foundIndex]);
          this.setFilters();
        });
      }
    });
  }

}
