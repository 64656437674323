<form [formGroup]="notasForm">
  <div class="row mb-3">
    <mat-form-field class="col-lg-5 col-md-4 col-sm-6 col-xs-12">
      <mat-label>Asignatura</mat-label>
      <mat-select [(value)]="asignatura" (selectionChange)="setAsignatura()" formControlName="asig">
        <mat-option *ngFor="let asignatura of asignaturas"
                    [value]="asignatura.id">{{asignatura.asignatura}}</mat-option>
      </mat-select>
      <mat-hint>Seleccione una asignatura</mat-hint>
    </mat-form-field>

    <mat-form-field class="col-lg-5 col-md-4 col-sm-6 col-xs-12" *ngIf="listadoExamenes.length != 0">
      <mat-label>Parciales</mat-label>
      <mat-select multiple formControlName="parciales">
        <mat-option *ngFor="let examen of listadoExamenes" [value]="examen.id">
          {{examen.examen}}
        </mat-option>
      </mat-select>
      <mat-hint>Seleccione una o varias notas parciales</mat-hint>
    </mat-form-field>
    <div>
      <a class="btn btn-secondary btn-sm" style="margin-bottom: 0!important; margin-top: 1rem" (click)="getExamenes()" *ngIf="notasForm.value.parciales">
        Obtener Notas
      </a>
    </div>
  </div>
</form>

<div class="row" *ngIf="tests.length > 0">
  <div class="col-lg-10 col-md-4 col-sm-6 col-xs-12">
    <p>
      Seleccionar el tipo de nota final:
      <label style="margin-left: 15px">
        <input type="radio" name="tipo" value="1" [(ngModel)]="tipo"> Primer Trimestre
      </label>
      <label style="margin-left: 15px">
        <input type="radio" name="tipo" value="2" [(ngModel)]="tipo"> Segundo Trimestre
      </label>
      <label style="margin-left: 15px">
        <input type="radio" name="tipo" value="3" [(ngModel)]="tipo"> Tercer Trimestre
      </label>
    </p>
  </div>
  <div>
    <a class="btn btn-secondary btn-sm" (click)="addNotaFinal()">Subir Nota Final</a>
  </div>
</div>

<div class="row">
  <table class="porciento" *ngIf="tests.length != 0">
    <tbody>
    <tr>
      <td class="col-auto" style="text-align: left">
        <p id="porcentaje"><input type="checkbox" value="true" [(ngModel)]="porcentaje">&nbsp;Deseas ingresar
          porcentaje para cada parcial?</p>
        <p id="textoporcentaje" style="{{porcentaje? 'visibility: visible' : 'visibility: hidden'}}">Ingresa el porcentaje de cada parcial</p>
      </td>
      <td *ngFor="let item of partialSelected; let i = index;">
        <div class="{{porcentaje? 'col-auto parcial' : 'col-auto noparcial'}}" id="{{'z_' + i}}">
          <label for="x_{{i}}">{{item.examen}}</label>
          <input class="form-control w-50" style="text-align: center" type="number" step="any" id="x_{{i}}" placeholder="100" [(ngModel)]="item.porcentaje">
        </div>
      </td>
      <td class="col-auto" id="four" style="{{porcentaje? 'visibility: visible' : 'visibility: hidden'}}">
        <button [disabled]="!porcentaje" id="btnCalcular" class="btn btn-secondary btn-sm" style="margin-top: 2.5em" (click)="notesPercentage($event)">Calcular</button>
      </td>
    </tr>
    </tbody>
  </table>

</div>
<div class="row">
  <table class="table" *ngIf="tests.length > 0" style="border: none">
    <thead>
    <tr>
      <th class="col-auto" scope="col">Alumno</th>
      <th class="col-auto" scope="col" *ngFor="let ex of partialSelected; let e = index">{{ex.examen}}</th>
      <th class="col-auto" scope="col">Nota Final</th>
    </tr>
    </thead>
    <tbody id="cuerpo">
    <tr *ngFor="let test of tests; let i = index">
      <td class="col-auto">{{test.nombre | titlecase}} {{test.apellidos | titlecase}}</td>
      <td *ngFor="let examen of test.examenes; let x = index">
        <div class="col-auto" id="a_{{i}}{{x}}">{{examen.nota}}&nbsp; &nbsp;
          <li class="success p-0 list-inline-item">
            <i class="ft-edit-2 font-medium-3 mr-2" (click)="editar(i,x)"></i>
          </li>
        </div>
        <div class="col-auto" hidden id="b_{{i}}{{x}}">
          <input style="text-align: center" type="text" size="2" [(ngModel)]="examen.nota" name="k{{i}}{{x}}"/>
          <small class="upload"><i class="ft-upload-cloud" (click)="add(examen.id_nota, examen.nota, i, x)"></i></small>
        </div>
      </td>
      <td class="col-auto">{{test.notaFinal}}</td>
    </tr>
    </tbody>
  </table>
</div>
