<h2 mat-dialog-title class="text-center">Mover Alumnos</h2>
<mat-dialog-content>
  <div class="row">
    <div class="col-4">
      <div style="margin-bottom: 10px; margin-top: 10px"
           *ngIf="data.length != 0">
        <div class="row rows" style="">
          <div ngbDropdown class="d-inline-block">
            <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>Seleccionar Alumno/s</button>
            <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12" style="margin-bottom: 10px; margin-top: 10px"
                 *ngIf="data.length != 0">

              <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="scrollable-menu">

                <table class="table">
                  <tr>
                    <th><input type="checkbox" [checked]="check" (change)="toggle()"/></th>
                    <th>Todos</th>
                  </tr>
                  <tr *ngFor="let alumno of data; let j= index">
                    <td><input name="foo" type="checkbox" [checked]="activar" [value]="j"
                               (change)="change(alumno, $event)"/></td>
                    <td>{{alumno.nombre | titlecase}} {{alumno.apellidos | titlecase}}</td>
                  </tr>
                </table>


              </div>

            </div>
          </div>


        </div>

      </div>
      <div >
        <div class="form-group " >
          <label class=" label-control">Ciclo:</label><br>
          <div class="">
            <select class="form-control"  (change)=" getAulasP($event)" >
              <option  [value]="null">Seleccione ciclo</option>

              <option *ngFor="let curso of cursos" [value]="curso.id"> {{curso.nivel}} - {{curso.acronimo}}</option>


            </select>
          </div>
        </div>
      </div>
      <div style="margin-bottom: 10px; margin-top: 10px" >
        <div class="form-group " >
          <label class=" label-control">Aula:</label><br>
          <div class="">
            <select class="form-control"   [(ngModel)]="aula">
              <option  [value]="null">Seleccione aula</option>

              <option *ngFor="let aula of aulas" [value]="aula.id"> {{aula.aula}} </option>


            </select>
          </div>
        </div>
      </div>

    </div>
    <div class="col-5 offset-3">
      <div class="table-responsive">
        <table class="table table-striped table-sm w-100" *ngIf="alumnosName.length >0">
          <thead>
          <tr>
            <th scope="col">Alumnos</th>


          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let alumno  of alumnosName" >

            <td>{{alumno.nombre}}, {{alumno.apellidos}}</td>


          </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>

</mat-dialog-content>
<mat-dialog-actions>
  <button type="button" class="btn btn-raised btn-secondary ml-2"  mat-dialog-close>
    <i class="ft-x-circle"></i> Cancelar
  </button>
  <button type="button" class="btn btn-raised btn-primary ml-5"   (click)="moveAlumnos()" [disabled]="alumnos.length === 0 && !aula">
    <i class="ft-save"></i> Guardar
  </button>
</mat-dialog-actions>


