// Importamos los componentes necesarios
import {Pipe, PipeTransform} from '@angular/core';

// Le ponemos un nombre a la tuberia
@Pipe({name: 'porcentaje_faltas'})

// Definimos la clase implementado la interface PipeTransform
export class PorcentajeFaltasPipe implements PipeTransform {
  transform (faltas: number, total_dias: number, horas_semana: number): number {
    const semanas = total_dias / 5;
    const total_horas = horas_semana * semanas;
    faltas = faltas * 3;
    const a = (faltas * 100) / total_horas;
    const t = a.toString();
    const regex = /(\d*.\d{0,2})/;
    return parseFloat(t.match(regex)[0]);


  }
}
