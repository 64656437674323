import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Subject} from 'rxjs/Subject';
import {Rutas} from '../../../../utiles/Rutas';
import {LoginService} from '../../../pages/services/login.service';


@Injectable()
export class HttpService {
  rutas = Rutas;
  private usersSource = new Subject<any>();
  public users$ = this.usersSource.asObservable();

  // ESTO ES DE HECTOR
  private horariosSource = new Subject<any>();
  public horarios$ = this.horariosSource.asObservable();

  private asignaturasSource = new Subject<any>();
  public asignaturas$ = this.asignaturasSource.asObservable();
  private aulaSource = new Subject<any>();
  public aula$ = this.aulaSource.asObservable();
  private parametrosSource = new Subject<any>();
  public parametros$ = this.parametrosSource.asObservable();
  private maestroSource = new Subject<any>();
  public maestro$ = this.maestroSource.asObservable();
  private cursoSource: Subject<any> = new Subject<any>();
  public curso$ = this.cursoSource.asObservable();
  private cursoAddSource: Subject<any> = new Subject<any>();
  public cursoAdd$ = this.cursoAddSource.asObservable();
  private cursoOcupadoSource: Subject<any> = new Subject<any>();
  public cursoOcupado$ = this.cursoOcupadoSource.asObservable();

  private alumnoSource = new Subject<any>();
  public alumno$ = this.alumnoSource.asObservable();
  private datosSource = new Subject<any>();
  public datos$ = this.datosSource.asObservable();

  id_centro: number;
  id_usuario: number;


  constructor(private http: HttpClient, private _login: LoginService) {
    const identity = JSON.parse(localStorage.getItem('identity'));
    this.id_centro = identity.centro.id;
    this.id_usuario = identity.id;
  }


  getCurso(id) {
    this.http.get(this.rutas + 'agenda/curso?token=' + localStorage.getItem('token') + '&id=' + id)
      .subscribe(data => {
        if (data['code'] === 200) {
          this.cursoSource.next(data['id']);
        }
      }, error => {
        console.log(error);
      });
  }

  ocupado(posicion: any, dia: any, id: any): Promise<any> {
    const url = this.rutas + 'agenda/ocupado?token=' +
      localStorage.getItem('token') + '&id=' + id + '&posicion=' + posicion + '&dia=' + dia;
    return this.http.get(url).toPromise();
  }

  comprobarOcupado(posicion: any, dia: any, id: any, start: any, end: any) {
    const url = this.rutas + 'agenda/ocupado?token=' +
      localStorage.getItem('token') + '&id=' + id + '&posicion=' + posicion + '&dia=' + dia + '&start=' + start + '&end=' + end;
    return this.http.get(url).subscribe(resp => {
      if (resp['code'] === 200) {
        this.cursoOcupadoSource.next(resp['id']);
      }
    });
  }

  getProfesores() {
    this.http.get(this.rutas + 'agenda/profesores?token=' + localStorage.getItem('token') + '&id=' + this.id_centro)
      .subscribe(data => {
        if (data['code'] === 200) {
          this.usersSource.next(data['id']);
        }
      }, error => {
        console.log(error);
      });
  }

  getHorariosProfesor(id: number) {
    this.http.get(this.rutas + 'agenda/maestro?token=' + localStorage.getItem('token') + '&id=' + id)
      .subscribe(data => {
        if (data['code'] === 200) {
          this.horariosSource.next(data['id']);
        }
      }, error => {
        console.log('ERROR', error);
      });
  }

  getAsignaturas(id, curso) {
    this.http.get(this.rutas + 'agenda/asignaturas?token=' + localStorage.getItem('token') + '&id=' + id + '&curso=' + curso)
      .subscribe(data => {
        if (data['code'] === 200) {
          this.asignaturasSource.next(data['id']);
        }
      }, error => {
        console.log(error);
      });
  }

  add(objeto: any) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    const json = {objeto};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(this.rutas + 'agenda/add', params, {headers: headers}).subscribe(resp => {
      return resp;
    });
  }

  addAsignatura(objeto: any) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    const json = {objeto};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(this.rutas + 'agenda/add', params, {headers: headers})
      .subscribe(resp => {
        if (resp['code'] === 200) {
          this.cursoAddSource.next(resp['id']);
        }
      }, error => {
        console.log('ERROR POST');
        console.log(error);
      });
  }

  remove(id) {
    this.http.get(this.rutas + 'agenda/remove?token=' + localStorage.getItem('token') + '&id=' + id)
      .subscribe(data => {
        if (data['code'] === 200) {
        }
      }, error => {
        console.log(error);
      });
  }

  get(id) {
    this.http.get(this.rutas + 'agenda/aula?token=' + localStorage.getItem('token') + '&id=' + id)
      .subscribe(data => {
        if (data['code'] === 200) {
          console.log(data['id']);
          this.aulaSource.next(data['id']);

        }
      }, error => {
        console.log(error);
      });
  }

  getAula() {
    const id = this._login.getIdentity().id;
    const role = this._login.getIdentity().role;
    let url = 'alumno/id/curso?id=';
    let wath = false;
    if (role === 'ROLE_PROFESOR') {
      url = 'profesor/id/curso?id=';
      wath = true;
    }
    return new Promise((resolve, reject) => {
      console.log('Consola 1', Rutas + url + id + '&token=' + localStorage.getItem('token'));
      this.http.get(Rutas + url + id + '&token=' + localStorage.getItem('token'))
        .subscribe(res => {
          if (res['code'] === 200) {
            if (!wath) {
              console.log(res);
              resolve(res['id'].id);
            } else {
              resolve(res['id']);
            }
          } else {
            reject(false);
          }
        });
    }).then(res => {
      if (!wath) {
        this.http.get(Rutas + 'get/aula?id=' + res + '&token=' + localStorage.getItem('token'))
          .subscribe(data => {
            if (data['code'] === 200) {
              console.log(data);
              this.datosSource.next(data['id']);
            } else {
              return false;
            }
          });
      } else {
        this.datosSource.next(res);
      }
    });
  }

  getAulaWithId(id) {
    const identificador = id;
    const role = id;
    let url = 'alumno/id/curso?id=';
    let wath = false;
    if (role === 'ROLE_PROFESOR') {
      url = 'profesor/id/curso?id=';
      wath = true;
    }
    return new Promise((resolve, reject) => {
      console.log('Consola 1', Rutas + url + identificador + '&token=' + localStorage.getItem('token'));
      this.http.get(Rutas + url + identificador + '&token=' + localStorage.getItem('token'))
        .subscribe(res => {
          if (res['code'] === 200) {
            if (!wath) {
              resolve(res['id'].id);
            } else {
              resolve(res['id']);
            }
          } else {
            reject(false);
          }
        });
    }).then(res => {
      if (!wath) {
        this.http.get(Rutas + 'get/aula?id=' + res + '&token=' + localStorage.getItem('token'))
          .subscribe(data => {
            if (data['code'] === 200) {
              this.datosSource.next(data['id']);
            } else {
              return false;
            }
          });
      } else {
        this.datosSource.next(res);
      }
    });
  }

  getConfiguracion() {
    this.http.get(this.rutas + 'agenda/activa?token=' + localStorage.getItem('token'))
      .subscribe(data => {
        if (data['code'] === 200) {
          this.parametrosSource.next(data['id']);
        }
      }, error => {
        console.log(error);
      });
  }

  getClasesMaestro() {
    this.http.get(this.rutas + 'agenda/maestro?token=' + localStorage.getItem('token') + '&id=' + this.id_usuario)
      .subscribe(data => {
        if (data['code'] === 200) {
          this.maestroSource.next(data['id']);
        }
      }, error => {
        console.log(error);
      });
  }
}


