import {Component, OnDestroy, OnInit} from '@angular/core';
import {HijosPadreService} from './services/hijos-padre.service';
import {Subscription} from 'rxjs/Subscription';



@Component({
  selector: 'app-hijos-padre',
  templateUrl: './hijos-padre.component.html',
  styleUrls: ['./hijos-padre.component.scss']
})
export class HijosPadreComponent implements OnInit, OnDestroy {
  hijos: any[] = [];
  asistencias: any[] = [];
  fechas: any[] = [];
  tasks: any[] = [];
  examenes: any[] = [];
  hijosSubscription = new Subscription();

  constructor (private _hijos: HijosPadreService) {
    this._hijos.getHijos();
  }

  ngOnDestroy() {
    this.hijosSubscription.unsubscribe();
  }

  ngOnInit () {

    this.hijosSubscription = this._hijos.datos$.subscribe(res => {
      this.hijos = res;
      localStorage.setItem('aula', this.hijos[0].aula)
      console.log(res);
      res.forEach(value => {
        this._hijos.getAsistencias(value.id);
        this._hijos.getTareas(value.id);
        this._hijos.getExamenes(value.id);
      });
      this._hijos.asistencias$.subscribe(resp => {

        for (let i = 0; i < resp.length; i++) {
          this.asistencias[i] = resp[i];
        }
        let f = '';
        let longitud = 0;
        for (let i = 0; i < this.asistencias.length; i++) {
          if (f !== resp[i].fecha) {
            longitud = this.fechas.push(resp[i].fecha);
            }
          if (longitud > 3) {
            return;
          }
          f = resp[i].fecha;
        }
        });
      this._hijos.tareas$.subscribe(respu => {
        let count = 4;
        if (respu.length < 4) {
          count = respu.length;
        }
        for (let i = 0; i < count; i++) {
          this.tasks[i] = respu[i];
        }
      });

      this._hijos.examenes$.subscribe(resc => {
        let count = 4;
        if (resc.length < 4) {
          count = resc.length;
        }
        for (let i = 0; i < count; i++) {
          this.examenes[i] = resc[i];
        }
      });


    });
  }
}
