import {Component, OnDestroy, ViewChild, OnInit} from '@angular/core';
import {Horarios} from '../../../componentes/agenda/modelo/horarios';
import {NgForm} from '@angular/forms';
import {AdminAgendaService} from '../services/admin-agenda.service';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {HorasMinutosPipe} from '../../../../pipes/horasMinutos.pipe';


@Component({
  selector: 'app-config-agenda',
  templateUrl: './add-configuracion.component.html'
})
export class AddConfiguracionComponent implements OnInit, OnDestroy {
  horarios: Horarios;
  @ViewChild('f') loginForm: NgForm;
  hora_inicio: any;
  hora_fin: any;
  id: number;
  horarioSubsription: Subscription = new Subscription();
  active: boolean;


  constructor (private _agenda: AdminAgendaService, private route: ActivatedRoute) {
    this.horarios = new Horarios(null, null, null, null, null, '');
    this.route.params.subscribe(resp => {
      this.id = resp['id'];
      if (this.id) {
        this._agenda.get(this.id);
      }
    });

  }

  ngOnInit () {
    this.horarioSubsription = this._agenda.horario$.subscribe(resp => {
      this.horarios = new Horarios(resp.horaInicio, resp.minutoInicio, resp.horaFin, resp.minutoFin, resp.tramo, resp.nombre);
      const pipe = new HorasMinutosPipe();
      this.hora_inicio = pipe.transform(resp.horaInicio, resp.minutoInicio);
      this.hora_fin = pipe.transform(resp.horaFin, resp.minutoFin);
    });
  }

  ngOnDestroy () {
    this.horarioSubsription.unsubscribe();
  }

  onSubmit () {
    if (this.loginForm.valid) {
      const valor_inicio = this.loginForm.value.inicio.split(':');
      if (valor_inicio.length !== 2) {
        return;
      }
      const valor_final = this.loginForm.value.fin.split(':');
      if (valor_final.length !== 2) {
        return;
      }

      if (Number.isNaN(this.loginForm.value.tramo)) {
        return false;
      }

      if (this.id) {
        this.horarios = new Horarios(valor_inicio[0], valor_inicio[1], valor_final[0], valor_final[1],
          this.loginForm.value.tramo, this.loginForm.value.nombre);
        this.horarios.id = this.id;
        this._agenda.update(this.horarios);
      } else {
        this.horarios = new Horarios(valor_inicio[0], valor_inicio[1], valor_final[0], valor_final[1],
          this.loginForm.value.tramo, this.loginForm.value.nombre);
        this._agenda.add(this.horarios);
      }

    }
    return;
  }


}
