// Importamos los componentes necesarios
import { Pipe, PipeTransform } from '@angular/core';

// Le ponemos un nombre a la tuberia
@Pipe({name: 'role'})

// Definimos la clase implementado la interface PipeTransform
export class RolePipe implements PipeTransform {
transform(value: number): string {
    const role = ['Maestro' , 'Administracion'];
    return role[value];
 }
}
