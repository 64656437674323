// Importamos los componentes necesarios
import { Pipe, PipeTransform } from '@angular/core';

// Le ponemos un nombre a la tuberia
@Pipe({ name: 'milliseconds' })

// Definimos la clase implementado la interface PipeTransform
export class FromMillisecondsPipe implements PipeTransform {
    transform(value: number): Date {
        const actual = new Date().getTime();
        const date = new Date(actual - value);
        return date;
    }
}
