import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ErrorPageComponent } from './error/error-page.component';
import { LoginPageComponent } from './login/login-page.component';
import { ForgotPasswordPageComponent } from './forgot-password/forgot-password-page.component';
import { HttpClientModule } from '@angular/common/http';



// ROUTING
import { PagesRoutingModule } from './pages-routing';

// SERVICES
import { LoginService } from './services/login.service';
import {CreatePasswordComponent} from './forgot-password/create-password.component';
import {NgxSpinnerModule} from 'ngx-spinner';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        PagesRoutingModule,
        HttpClientModule,
        NgxSpinnerModule,

    ],
    declarations: [
        ErrorPageComponent,
        LoginPageComponent,
        ForgotPasswordPageComponent,
        CreatePasswordComponent
    ],
    providers: [
        LoginService
    ]
})
export class PagesModule {}
