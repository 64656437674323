import {Component} from '@angular/core';
import {Router} from '@angular/router';


@Component({
  selector: 'app-aula',
  templateUrl: './aulas.component.html',
  styleUrls: ['aulas.component.scss']

})
export class AulasComponent {
  navLinks = [{}];

  constructor(private _router: Router) {
    this.navLinks = [{path: 'lista', label: 'Listar'}, {path: 'add', label: 'Crear aula'}];
  }
}
