<div class="table-responsive">
  <table class="table">
    <thead>
    <tr>
      <th>Nombre</th>
      <th>Hora inicio</th>
      <th>Hora fin</th>
      <th>Tramos</th>
      <th>Activo</th>
      <th>Acciones</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let horario of horarios; let i = index">
      <td>{{horario.nombre}}</td>
      <td>{{horario.horaInicio | horas_minutos: horario.minutoInicio}}</td>
      <td>{{horario.horaFin | horas_minutos: horario.minutoFin }}</td>
      <td>{{horario.tramo}}</td>
      <td><input type="checkbox"  value="{{horario.checked}}" [checked]="horario.checked" (change)="updateCheck(horario.id)" ></td>
      <td>
        <ul class="list-inline">
          <li class="list-inline-item success p-0">
            <i class="ft-edit-2 font-medium-3 mr-2" (click)="update(horario.id)"></i>
          </li>
        </ul>
      </td>
    </tr>
    </tbody>
  </table>
</div>
