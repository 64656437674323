import {Component, OnDestroy, OnInit} from '@angular/core';
import {AdminAgendaService} from '../services/admin-agenda.service';
import {Subscription} from 'rxjs/Subscription';
import {Router} from '@angular/router';



@Component({
  selector: 'app-agenda-lista',
  templateUrl: './agenda-lista.component.html'
})
export class AgendaListaComponent implements OnInit, OnDestroy {
  horarios: any;
  horariosSubscription: Subscription = new Subscription();

  constructor (private _agenda: AdminAgendaService, private route: Router) {
    this._agenda.list();
  }

  ngOnInit () {
    this.horariosSubscription = this._agenda.lista$.subscribe(resp => {
      this.horarios = resp;
    });
  }

  ngOnDestroy () {
    this.horariosSubscription.unsubscribe();
  }

  update (id) {
    this.route.navigate(['/admin/agenda/config', id]);
  }

  updateCheck (id: number) {
    this._agenda.check(id);
  }
}
