import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {LoginService} from '../services/login.service';


@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./forgot-password-page.component.scss']
})

export class CreatePasswordComponent implements OnInit {
  @ViewChild('f') loginForm: NgForm;
  token: any;
  id: number;
  aviso: boolean;
  texto: string;

  constructor(private _router: Router, private _route: ActivatedRoute, private _login: LoginService) {
    this._login.validateToken(this.token);
  }

  ngOnInit() {
    this.token = this._route.snapshot.queryParams.token;
    this._login.activate$.subscribe(res => {
      if (!res) {

        this._router.navigate(['/public/login']);
      }
    });
    this._login.update$.subscribe(res => {
      if (res) {
        this.aviso = true;
        this.texto = 'En breve seras redireccionado';
        setTimeout(() => this.redirigir(), 5000);
      }
    });
  }

  redirigir() {
    this._router.navigate(['/public/login']);
  }

  // On submit click, reset form fields
  onSubmit() {
    if (this.loginForm.valid) {
      if (this.loginForm.value.password === this.loginForm.value.password_r) {
        this.loginForm.value.token = this.token;
        this._login.updatePassword(this.loginForm.value);
      } else {
        this.aviso = true;
        this.texto = 'No coinciden las contraseñas';
        setTimeout(() => this.aviso = false, 3000);
      }
    }
  }
}
