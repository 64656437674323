import {Component, ViewChild, OnInit, ViewEncapsulation} from '@angular/core';

import {UsersService} from '../services/users.service';

import {NgForm} from '@angular/forms';
import {Datos} from '../../../../modelos/datos.modelo';
import {ActivatedRoute} from '@angular/router';
import swal from 'sweetalert2';
import {AddCicloComponent} from '../../comercial/modales/add-ciclo/add-ciclo.component';
import {MatDialog} from '@angular/material/dialog';
import {AddTutorComponent} from './modals/add-tutor/add-tutor.component';

@Component({
  selector: 'app-tutores',
  templateUrl: './tutores.component.html',
  styleUrls: ['../users.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TutoresComponent implements OnInit {
  public id: number;
  dato: any;
  existe: boolean;
  active: boolean;
  tutores: any = [];
  @ViewChild('p') loginFormP: NgForm;

  public tutor: any = {
    nombre: '',
    apellidos: '',
    municipio: '',
    dni: '',
    nacimiento: '',
    direccion: '',
    id: '',
    id_municipio: '',
    id_alumno: ''
  };
  telefono: string;


  constructor (private _user: UsersService, private _route: ActivatedRoute, private dialog: MatDialog) {
    this.dato = new Datos(0,
      '', '', '', '', new Date(), '', '', [], 0, 0, '', [], [], [], false, false,'','','','', {nivel: '', id: ''}, '', '', '', '');
    this.id = this._route.snapshot.parent.params['id'];
    this._user.getDato(this.id);
  }


  ngOnInit() {
    this._user.dato$.subscribe(res => {
      if (res.tutores.length > 0) {
        this.existe = true;
        console.log(res);
        this.tutores = res.tutores;
      }
      this.dato = res;
    });
  }

  removeUser(id: number, index: number) {
    swal.fire({
      title: 'BORRAR',
      text: '¿Seguro que quiere borrar a ' + this.tutores[index].nombre + ' ' + this.tutores[index].apellidos + '?',
      icon: 'error',
      showCancelButton: true,
      confirmButtonText: 'Si, Eliminar!',
      cancelButtonText: 'No, Cancelar!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
          swal.fire( 'Mensaje', 'Se ha eliminado', 'success',
          );
          this._user.removeUser(id);
          this.tutor = {};
          this.tutores.splice(index, 1);
        }
      });
  }

  onSubmit() {
    if (this.loginFormP.valid) {
      this.loginFormP.value.id_municipio = this.dato['id_municipio'];
      this.loginFormP.value.direccion = this.dato['direccion'];
      this.loginFormP.value.id_alumno = this.dato.id;
      console.log(this.loginFormP.value);
      this._user.addPadre(this.loginFormP.value, this.telefono, this.dato.id);
      this.existe = true;
    }
  }

  showSearchModal() {
    const dialogRef2 = this.dialog.open( AddTutorComponent  );
    dialogRef2.afterClosed().subscribe( result => {
      if ( result) {
        console.log(result);
        this._user.addTutorExistente(this.id, result).subscribe( (res) => {
          console.log(res);
          this._user.getDato(this.id);
        })
      }
    });
  }


}
