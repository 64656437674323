<div class="row">
  <h4 class="info-top"><i class="ft-user"></i> Hijos Info</h4>
  <!-- TABLA TUTORES -->
  <div class="col-12" *ngIf='!existe'>
    <table class="table table-striped">
      <thead>
      <tr>
        <th scope="col">Nombre</th>
        <th scope="col">Apellidos</th>
        <th scope="col">Dni</th>
        <th scope="col" class="info align-content-center">
          <i class="ft-plus font-medium-3 mr-2" (click)="existe = !existe; active=!active"></i>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let hijo of hijos; let i = index">
        <td>{{hijo.nombre}}</td>
        <td>{{hijo.apellidos}}</td>
        <td>{{hijo.dni}}</td>
        <td>
          <ul class="list-inline">
            <li class="list-inline-item danger p-0" (click)="removeUser(hijo.id,i)">
              <i class="ft-x font-medium-3 mr-2"></i>
            </li>
            <li class="success p-0 list-inline-item" [routerLink]="['/user/alumno/' , hijo.id]">
              <i class="ft-user font-medium-3 mr-2"></i>
            </li>
          </ul>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <!--  END AÑADIR HIJOS-->
  <div class="col-12" *ngIf="existe">
    <form class="form form-horizontal striped-rows form-bordered" novalidate="" (ngSubmit)="onSubmit()" #f="ngForm">
      <div class="form-body">
        <div class="form-group row">
          <label class="col-md-3 label-control">Nombre</label>
          <div class="col-md-5">
            <input [(ngModel)]="nombre" required type="text" class="form-control width-input" name="nombre"
                   placeholder="Nombre">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-3 label-control">Apellidos</label>
          <div class="col-md-5">
            <input [(ngModel)]="apellidos" required type="text" class="form-control width-input" name="apellidos"
                   placeholder="Apellidos">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-3 label-control">Dni</label>
          <div class="col-md-5">
            <input [(ngModel)]="dni" type="text" class="form-control width-input" name="dni" placeholder="Dni">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-3 label-control">Curso</label>
          <div class="col-md-5">
            <select class="form-control"
                    [(ngModel)]='curso'
                    name="curso"
                    required>
              <option *ngFor="let curso of cursos" [value]="curso.id">{{curso.nivel}}</option>
            </select>
          </div>
        </div>
        <div class="form-actions">
          <button type="submit" class="btn btn-raised btn-primary ">Crear</button>
          <button type="button" class="btn btn-raised btn-danger" (click)="existe = !existe; active=!active">Cancelar
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
