import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';
  exportCsvGoogle: any;

  constructor() { }

  public exportExcel(jsonData: any[], fileName: string): void {

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, fileName);
  }

  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: this.fileType});
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }

  public exportExcelMultipleUsers(data: any[], cabeceras: any [], filename: string): void {
    const dataTofilter = data;
    let users;
    const wb = XLSX.utils.book_new();
    for ( const curso of cabeceras) {
      users = dataTofilter.filter((item) => item.nivel.toLowerCase().includes(curso.nivel.toLowerCase()));
      this.exportGoogleCSV(users);
      const ws = XLSX.utils.json_to_sheet(this.exportCsvGoogle);
      if ( curso.nivel.length > 30) {
        XLSX.utils.book_append_sheet(wb, ws, curso.nivel.slice(3, 33));
      } else {
        XLSX.utils.book_append_sheet(wb, ws, curso.nivel);
      }

    }
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, filename);
  }

  exportGoogleCSV(users) {
    this.exportCsvGoogle = [];
    for (const a of users) {
      this.exportCsvGoogle.push({
        'Apellidos': a.apellidos ,
        'Nombre': a.nombre,
        'DNI' : a.dni,
      });
    }
  }
}
