<!--Forgot Password Starts-->
<section id="forgot-password">
  <div class="container-fluid ">
    <div class="row full-height-vh">
      <div class="col-12 d-flex align-items-center justify-content-center">
        <div class="card card-inverse  text-center width-400 fondo">
          <div class="card-img overlap" >
            <img  id="logo" alt="logo" class="mb-1" src="assets/img/centro/logo.png" width="80" >
          </div>

          <div class="card-body">
            <div class="card-block">
              <div class="text-center">
                <h4 class="text-uppercase text-bold-400 white">RESTABLECER CONTRASEÑA</h4>
                <h5 class="text-uppercase text-bold-300 white" *ngIf="aviso">{{texto}}</h5>
              </div>
              <form class="pt-4" (ngSubmit)="onSubmit()" #f="ngForm">
                <div class="form-group" >
                  <input type="password" class="form-control" name="password" id="password" placeholder="Nueva contraseña" ngModel>
                </div>
                <div class="form-group">
                  <input type="password" class="form-control" name="password_r" id="password_r" placeholder="Repite tu nueva contraseña" ngModel>
                </div>

                <div class="form-group pt-2">
                  <div class="text-center mt-3">
                    <button type="submit" class="btn btn-danger btn-raised btn-block">Aceptar</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate" [fullScreen]="true"></ngx-spinner>
</section>


<!--Forgot Password Starts-->
<!--<section id="forgot-password">
  <div class="container-fluid">
    <div class="row full-height-vh">
      <div class="col-12 d-flex align-items-center justify-content-center">
        <div class="card card-inverse  text-center width-400 fondo">
          <div class="card-img overlap" >
            <img  id="logo" alt="logo" class="mb-1" src="assets/img/centro/logo.png" width="80" >
          </div>

          <div class="card-body">
            <div class="card-block">
              <div class="text-center">
                <h4 class="text-uppercase text-bold-400 white">Restablecer contraseña</h4>
              </div>
              <p  class="font-small-2"> Introduzca su correo electrónico  y presione <b>Aceptar</b>, recibirá un E-mail con las instrucciones para restablecer su contraseña.</p>

              <form class="pt-4" (ngSubmit)="onSubmit()" #f="ngForm">
                <div class="form-group" style="margin-bottom: 0 !important;">
                  <input type="email" class="form-control" name="inputEmail" id="inputEmail" placeholder="Correo electronico" ngModel>
                </div>
                <div class="form-group ">
                  <div class="text-center mt-3">
                    <button type="submit" class="btn btn-danger btn-raised btn-block">Aceptar</button>
                  </div>
                </div>
              </form>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div><ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate" [fullScreen]="true"></ngx-spinner>
</section>-->
