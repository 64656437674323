// Smart DataTable
export const settings = {
  columns: {
    id: {
      title: 'ID',
      filter: false,
      editable: false
    },
    asignatura: {
      title: 'Asignatura',
      filter: false,
    },
    hora: {
      title: 'Horas',
      filter: false,
    },

    curso: {
      title: 'Curso',
      filter: false,
      editable: false
    }

  },
  attr: {
    class: 'table table-responsive'
  },


  edit: {
    editButtonContent: '<i class="ft-edit-2 info font-medium-1 mr-2"></i>',
    confirmSave: true
  },
  delete: {
    deleteButtonContent: '<i class="ft-x danger font-medium-1 mr-2"></i>',
    confirmDelete: true
  },

  add: {
    confirmCreate: true,
    addButtonContent: '<i class="ft-plus success font-medium-1 mr-2"></i>',
  },



  actions: {
    position: 'right',
    title: 'Acciones'
  }
};






