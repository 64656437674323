

    <div style="display: block;">
      <canvas baseChart
              [datasets]="lineChartData"
              [labels]="lineChartLabels"
              [options]="lineChartOptions"
              [colors]="lineChartColors"
              [legend]="lineChartLegend"
              [chartType]="lineChartType"

           ></canvas>
      <p>Ratio de asistencia durante el periodo escolar.</p>
    </div>



