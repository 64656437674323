import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {AlumnosService} from '../services/alumnos.service';


@Component({
  selector: 'app-examenes-alumno',
  templateUrl: './examenes-alumno.component.html',
  styleUrls: ['../alumnos.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExamenesAlumnoComponent implements OnInit, OnDestroy {
  examenesSubscription: Subscription = new Subscription();
  alumnosSubscription: Subscription = new Subscription();
  id: number;
  examenes: any;
  alumno: any;
  dato: string;

  constructor (private _route: ActivatedRoute, private _alumnos: AlumnosService) {
    this.id = this._route.snapshot.parent.params['id'];
    this._alumnos.getExamenes(this.id);
    this._alumnos.getAlumno(this.id);
  }

  ngOnInit () {
    this.examenesSubscription = this._alumnos.examenes$.subscribe(res => {
    this.examenes = res;
    });

    this.alumnosSubscription = this._alumnos.alumno$.subscribe(res => {
      this.alumno = res.dato;
      this.dato = this.alumno.nombre + ' ' + this.alumno.apellidos;
    });
  }

  ngOnDestroy () {
    this.examenesSubscription.unsubscribe();
    this.alumnosSubscription.unsubscribe();
  }

}
