import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {AppComponent} from './app.component';
import {HttpClientModule} from '@angular/common/http';
import {NgxPaginationModule} from 'ngx-pagination';

// MODULOS
import {PagesModule} from './components/pages/pages-module';
import {AdminModule} from './components/admin/template/admin.module';
import {MaestroModule} from './components/maestro/template/maestro.module';
import {AlumnoModule} from './components/alumno/template/alumno.module';
import {PipesModule} from './pipes.module';

// ROUTES
import {APP_ROUTING} from './app.routes';
import * as $ from 'jquery/dist/jquery.min.js';
import {PadreModule} from './components/padre/template/padre.module';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';

import {DatePipe} from '@angular/common';
import { AsistenciasComponent } from './components/componentes/asistencias/asistencias.component';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {MatButtonModule} from '@angular/material/button';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {MatTableExporterModule} from 'mat-table-exporter';
import {CalendarModule} from 'angular-calendar';
import {WebcamModule} from 'ngx-webcam';
import {PdfComponent} from './components/admin/admin-aulas/pdf/pdf.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {MatDialogModule} from '@angular/material/dialog';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {KtdGridModule} from '@katoid/angular-grid-layout';
import {TablonModule} from './components/componentes/tablon/tablon.module';
import {TablonService} from './components/componentes/tablon/tablon.service';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {MatFormFieldModule} from '@angular/material/form-field';

@NgModule({
  declarations: [
    AppComponent,
    AsistenciasComponent,
  ],
  imports: [
    NgxSpinnerModule,
    BrowserModule,
    PagesModule,
    FormsModule,
    APP_ROUTING,
    HttpClientModule,
    AdminModule,
    MaestroModule,
    AlumnoModule,
    PadreModule,
    NgxPaginationModule,
    SweetAlert2Module.forRoot(),
    MatTableModule,
    MatButtonModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatTableExporterModule,
    CalendarModule,
    PipesModule,
    WebcamModule,
    MatDialogModule,
    NgxChartsModule,
    BrowserAnimationsModule,
    KtdGridModule,
    TablonModule,

  ],


  providers: [
    {provide: LOCALE_ID, useValue: 'es'},
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    DatePipe,
    TablonService
  ],
  bootstrap: [AppComponent],
  entryComponents: [PdfComponent]
})
export class AppModule {

}
