<h4 class="info-top"><i class="ft-user"></i> Historial de Notas: {{dato?.nombre}} {{dato?.apellidos}} </h4>

<div class="table-responsive" *ngIf="Ce">
  <table class="table" style="table-layout: fixed"  *ngFor="let cursoE of Ce; let x = index">
    <thead>
    <tr class="d-flex">
      <th class="col-12" scope="col" >{{cursoE.curso}}</th>
    </tr>
    <tr class="d-flex">
      <th class="col-8" scope="col">Asignatura</th>
      <th class="col-4" scope="col">Nota Final</th>
    </tr>
    </thead>
    <tbody>
    <tr class="d-flex" *ngFor="let examen of cursoE.notas; let i = index">
      <td class="col-8">
        {{examen.asignatura}}
      </td>
      <td class="col-4">
        <div id="a_{{i}}{{x}}">{{examen.nota}}
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</div>
