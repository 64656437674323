import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule} from '@angular/forms';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';

import {InboxModule} from '../../../components/admin/inbox/inbox.module';
import {TaskboardModule} from '../../../taskboard/taskboard.module';
import {PipesModule} from '../../../pipes.module';
import {MatTabsModule} from '@angular/material/tabs';
import {Ng2SmartTableModule} from 'ng2-smart-table';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {QuillModule} from 'ngx-quill';
import {AgendaModule} from '../../../agenda/agenda.module';



// BASE
import {FooterComponent} from './footer/footer.component';
import {NavbarComponent} from './navbar/navbar.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {CustomizerComponent} from './customizer/customizer.component';
import {MainComponent} from './main/main.component';
import {PadreComponent} from './padre.component';


// GUARD
import {AuthGuardPadreService} from '../../services/auth-guard-padre.service';


// ROUTING
import {HttpClientModule} from '@angular/common/http';
import {PadreRoutingModule} from './padre.routing';





// SERVICES
import {HijosPadreService} from '../hijos-padre/services/hijos-padre.service';





// COMPONENTES
import {PadreAsistenciasComponent} from '../hijos-padre/componentes/padre-asistencias.component';
import {PadreExamenesComponent} from '../hijos-padre/componentes/padre-examenes.component';
import {PadreTareasComponent} from '../hijos-padre/componentes/padre-tareas.component';
import {HijosPadreComponent} from '../hijos-padre/hijos-padre.component';
import {MenuPadreComponent} from '../hijos-padre/componentes/menu-padre.component';
import {PadreInboxComponent} from '../inbox/padre-inbox.component';







@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    PadreRoutingModule,
    HttpClientModule,
    FormsModule,
    NgxDatatableModule,
    InboxModule,
    TaskboardModule,
    PipesModule,
    MatTabsModule,
    Ng2SmartTableModule,
    BrowserAnimationsModule,
    QuillModule,
    AgendaModule,


  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    MainComponent,
    PadreComponent,
    CustomizerComponent,
    SidebarComponent,
    HijosPadreComponent,
    MenuPadreComponent,
    PadreAsistenciasComponent,
    PadreExamenesComponent,
    PadreTareasComponent,
    PadreInboxComponent
  ],
  providers: [AuthGuardPadreService, HijosPadreService],

  exports: []

})
export class PadreModule {
}
