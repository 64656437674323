import {AfterViewInit, Component, Input, OnInit, ViewChild, Inject} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {UsersService} from '../../../admin-users/services/users.service';
import {ActivatedRoute} from '@angular/router';
import Swal from 'sweetalert2';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-obs-modal',
  templateUrl: './obs-modal.component.html',
  styleUrls: ['./obs-modal.component.scss']
})
export class ObsModalComponent implements OnInit, AfterViewInit {
  dato = { nombre: '', apellidos: ''};
  @Input() public id: any;
  Observaciones: FormGroup;
  obs: any[] = [];
  displayedColumns = ['observacion', 'fecha'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  constructor(public activeModal: NgbActiveModal, private _user: UsersService,
              private _route: ActivatedRoute, private fb: FormBuilder) {
    this.crearFormulario();
  }

  ngOnInit() {
    this._user.dato$.subscribe(res => {
      console.log(res);
      this.dato = res;
    });
    document.getElementById('tabla').hidden = true;
  }

  ngAfterViewInit() {
    this._user.getDato(this.id);
    this.getObs();
  }

  crearFormulario(): any {
    this.Observaciones = this.fb.group({
      obs: [''],
    });
  }

  getObs() {
    this._user.getObsClaustro(this.id).subscribe( (res: any) => {
      if (res['code'] === 200) {
        this.obs = res['id'];
        if (this.obs.length > 0) {
          this.dataSource = new MatTableDataSource(this.obs);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          document.getElementById('tabla').hidden = false;
          console.log(this.obs);
        }
      } else {
        console.log(res);
      }
    });
  }

  onSubmit() {
    if (this.Observaciones.value.obs) {
      const user = {
        idUser: this.id,
        observacion: this.Observaciones.value.obs,
        tipo: 1
      }
      this._user.addObservaciones(user).subscribe( res => {
        if (res['code'] === 200) {
          Swal.fire('', 'Observación Guardada', 'success');
          this.getObs();
          this.Observaciones.reset();
        } else {
          console.log(res);
        }
      });
    } else {
      Swal.fire('', 'Favor ingresar la Observación', 'warning');
    }
  }

}
