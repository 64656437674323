<h4 class="info-top">
    <i class="ft-user"></i> Centro Info</h4>
<div class="row">


    <div class="col-md-12">
        <div class="card">
            <div class="card-body">
                <div class="px-3">
                    <form class="form form-horizontal striped-rows form-bordered" novalidate="" (ngSubmit)="onSubmit()" #f="ngForm">
                        <input type="hidden" name="id" [(ngModel)]="centro.id">

                        <div class="form-body">
                          <div class="form-group row">
                                <label class="col-md-3 label-control">Nombre centro</label>
                                <div class="col-md-5">
                                    <input type="text" class="form-control" placeholder="Nombre del centro" [(ngModel)]="centro.nombre" required name="nombre" [disabled]="!active">
                                </div>
                            </div>



                            <div class="form-group row">
                                <label class="col-md-3 label-control" >Direccion</label>
                                <div class="col-md-5">
                                    <input type="text" class="form-control" placeholder="Direccion" [(ngModel)]="centro.direccion" required name="direccion" [disabled]="!active">
                                </div>
                            </div>


                            <div class="form-group row">
                                <label class="col-md-3 label-control" >Provincias</label>
                                <div class="col-md-5">
                                    <select [(ngModel)]='centro.id_provincia' name="provincia" class="form-control" [disabled]="!active" (change)="getMunicipios(centro.id_provincia)">
                                    <ng-container *ngIf="provincias.length > 0">
                                      <option *ngFor="let pro of provincias" [value]="pro.id" selected>{{pro.provincia}}</option>
                                    </ng-container>

                                    <ng-container *ngIf="centro.provincia">
                                      <option [value]="centro.id_provincia" selected>{{centro.provincia}}</option>
                                    </ng-container>
                                  </select>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-md-3 label-control">Municipio</label>
                                <div class="col-md-5">
                                    <select class="form-control" [(ngModel)]="centro.id_municipio" required name="municipio" [disabled]="!active">
                                    <ng-container *ngIf="municipios.length > 0">
                                      <option *ngFor="let municipio of municipios" [value]="municipio.id" selected>{{municipio.municipio}}</option>
                                    </ng-container>
                                    <ng-container *ngIf="centro.id_municipio">
                                      <option [value]="centro.id_municipio" selected>{{centro.municipio}}</option>
                                    </ng-container>
                                  </select>
                                </div>
                            </div>
                          <div class="form-group row">
                                <label class="col-md-3 label-control" >Cif</label>
                                <div class="col-md-5">
                                  <input type="text" class="form-control" placeholder="Cif" [(ngModel)]="centro.cif" required name="cif" [disabled]="!active">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-md-3 label-control">Razon social</label>
                                <div class="col-md-5">
                                    <input type="text" class="form-control" placeholder="Razon social" [(ngModel)]="centro.razon_social" required name="razon_social" [disabled]="!active">
                                </div>
                            </div>

                        </div>

                      <div class="form-group row" id="longitud" hidden>
                        <label class="col-md-3 label-control">Longitud</label>
                        <div class="col-md-5">
                          <input type="text" class="form-control" placeholder="Longitud" [(ngModel)]="centro.longitud" required name="long" disabled>
                        </div>
                      </div>

                      <div class="form-group row" id="latitud" hidden>
                        <label class="col-md-3 label-control">Latitud</label>
                        <div class="col-md-5">
                          <input type="text" class="form-control" placeholder="Latitud" [(ngModel)]="centro.latitud" required name="lat" disabled>
                        </div>
                      </div>

                        <div class="col-12" id="mapa" hidden>
                          <div class="border-0" xmlns="http://www.w3.org/1999/html">
                            <div class="card text-white bg-dark border-0 p-1 mb-3">
                              <input type="text" class="form-control mb-2" (keydown.enter)="$event.preventDefault()"
                                     placeholder="Introduzca una dirección" autocorrect="off" autocapitalize="off" spellcheck="off"
                                     id="place" #search  [disabled]="!active">
                              <agm-map [latitude]="lat" [longitude]="long" [zoom]="zoom">
                                <agm-marker  [latitude]="lat" [longitude]="long" [markerDraggable]="true"
                                            (dragEnd)="markerDragEnd($event)" [markerClickable]="!active"></agm-marker>
                              </agm-map>
                              <div class="row">
                                <div class="form-group col-9 p-3">
                                  <label>Dirección</label>
                                  <br/>
                                  <label style="color: white">{{address}}</label>

                                </div>
                                <div class="form-group col-3 p-3">
                                  <label for="inputRadio">Distancia Máxima </label>
                                  <input type="number" step="any" class="form-control" name="radio" [(ngModel)]="centro.radio"
                                         id="inputRadio"  [disabled]="!active">
                                  <p  class="badge badge-info">Distancia en metros </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="form-actions">
                            <button type="button" (click)="activar()" class="btn btn-raised btn-success mr-1" [disabled]="active">
                                <i class="ft-edit-2"></i> Editar
                            </button>
                            <button type="submit" class="btn btn-raised btn-primary"  [disabled]="!active">
                                <i class="ft-save"></i> Guardar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
