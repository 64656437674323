import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-administracion',
  templateUrl: './admin-administracion.component.html',
  styleUrls: ['./admin-administracion.component.scss']
})
export class AdminAdministracionComponent  {

  navLinks = [{}];

  constructor () {
    this.navLinks = [{path: 'docu', label: 'Gestor documental'}, {path: 'clases', label: 'Gestión de Aulas'},
      {path: 'cursosintensivos', label: 'Cursos Intensivos'}, {path: 'exportaciones', label: 'Exportaciones'}];


  }

}
