<div class="row justify-content-center match-height">
    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <div class="px-3">

                    <form class="form" class="form form-horizontal striped-rows form-bordered" novalidate="" (ngSubmit)="onSubmit()" #f="ngForm">

                        <div class="form-body">

                            <div class=" form-group row" *ngIf="modulos_educativos">
                                <label for="basicSelect">Modulo educativo</label>
                                <select (change)="getModulos()" class="form-control" ngModel name="modulos_educativos">
                                    <option>Selecciona una opcion</option>
                                    <option *ngFor="let modulo_e of modulos_educativos" [value]="modulo_e.id">{{modulo_e.modulo_educativo}}</option>
                                </select>
                            </div>

                            <div class="form-group row" *ngIf="modulo_basico">
                                <label for="basicSelect">Modulo</label>
                                <select class="form-control" ngModel required name="modulos">
                                            <option>Seleccione una opcion</option>
                                            <option *ngFor="let modulo of modulos" [value]="modulo.id">{{modulo.modulo}}</option>
                                    </select>
                            </div>

                            <div class="form-group row" *ngIf="modulo_bachiller">
                                <label for="basicSelect">Modulo</label>
                                <select class="form-control" ngModel required name="modulos">
                                            <option>Seleccione una opcion</option>
                                            <option *ngFor="let modulo of modulos" [value]="modulo.id">{{modulo.modalidad}} {{modulo.curso}}</option>
                                        </select>
                            </div>


                            <div class="form-group row" *ngIf="familia_fp">
                                <label for="basicSelect">Familia</label>
                                <select class="form-control" ngModel required name="modulos" (change)="getCiclos()">
                                            <option>Seleccione una opcion</option>
                                            <option *ngFor="let modulo of modulos" [value]="modulo.id">{{modulo.familia}}</option>
                                        </select>
                            </div>

                            <div class="form-group row" *ngIf="modulo_fp">
                                <label for="basicSelect">Ciclos formativos</label>
                                <select class="form-control" ngModel required name="ciclos">
                                    <option>Seleccione una opcion</option>
                                    <option *ngFor="let ciclo of ciclos" [value]="ciclo.id">{{ciclo.ciclo}}</option>
                                 </select>
                            </div>
                          <div class="form-group row" *ngIf="modulo_fp">
                            <label for="basicSelect">Acrónimo</label>
                            <input type="text" class="form-control" ngModel required name="acr">
                          </div>





                            <div class="form-actions right">
                                <button type="button" class="btn btn-raised btn-warning mr-1" (click)="reset()">
                                    <i class="ft-x" ></i> Cancel
                                </button>
                                <button type="submit" class="btn btn-raised btn-primary" [disabled]="!f.valid">
                                    <i class="fa fa-check-square-o"></i> Guardar
                                </button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
</div>
