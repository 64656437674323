// Importamos los componentes necesarios
import {Pipe, PipeTransform} from '@angular/core';

// Le ponemos un nombre a la tuberia
@Pipe({name: 'acortar'})

// Definimos la clase implementado la interface PipeTransform
export class AcortarPipe implements PipeTransform {
  transform (value: string, largo: number): string {
    let cadena;
    if (value) {
      if (value.length > largo) {
        cadena = value.slice(0, largo) + '...';
      } else {
        cadena = value;
      }
      return cadena;
    }
    return value;
  }

}
