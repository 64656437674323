<div class="row">

  <div class="col-md-6">
    <div class="card">
      <div class="card-body">
        <div class="px-3">
          <form class="form form-horizontal striped-rows form-bordered" novalidate="" (ngSubmit)=" onSubmitPass()"
                #p="ngForm">
            <input type="hidden" [(ngModel)]="id" name="id">
            <div class="form-body">
              <div class="row">
                <div class="form-group col-12 mb-2">
                  <label>Password</label>
                  <div class="position-relative has-icon-left">
                    <input type="password" class="form-control" placeholder="Introduce el nuevo password"
                           [(ngModel)]="n_password" required name="n_password">
                  </div>
                </div>
                <div class="form-group col-12 mb-2">
                  <label>Repita el password</label>
                  <div class="position-relative has-icon-left">
                    <input type="password" class="form-control" placeholder="Password" [(ngModel)]="r_password" required
                           name="r_password">
                  </div>
                </div>


                <div class="form-group col-12 mb-2">
                  <label>Introduce el password actual</label>
                  <div class="position-relative has-icon-left">
                    <input type="password" class="form-control" placeholder="Password" [(ngModel)]="pass" required
                           name="pass">
                  </div>
                </div>
              </div>
            </div>

            <div class="form-actions right">
              <button type="submit" class="btn btn-raised btn-primary" [disabled]="!p.valid">
                <i class="fa fa-check-square-o"></i> Guardar
              </button>
            </div>
          </form>

        </div>
      </div>
    </div>
  </div>


  <div class="col-md-6">
    <div class="card">
      <div class="card-body">
        <div class="px-3">
          <form class="form form-horizontal striped-rows form-bordered" novalidate="" (ngSubmit)="onSubmit()"
                #f="ngForm">
            <input type="hidden" [(ngModel)]="id" name="id">
            <div class="form-body">
              <div class="row">
                <div class="form-group col-12 mb-2">
                  <label>Username</label>
                  <div class="position-relative has-icon-left">
                    <input type="text" class="form-control" placeholder="Dato de contacto" [(ngModel)]="user" required
                           name="user">
                  </div>
                </div>
                <div class="form-group col-12 mb-2">
                  <label>Password</label>
                  <div class="position-relative has-icon-left">
                    <input type="password" class="form-control" placeholder="Password" [(ngModel)]="pass" required
                           name="pass">
                  </div>
                </div>
              </div>
            </div>

            <div class="form-actions right">
              <button type="submit" class="btn btn-raised btn-primary" [disabled]="!f.valid">
                <i class="fa fa-check-square-o"></i> Guardar
              </button>
            </div>
          </form>

        </div>
      </div>
    </div>
  </div>
</div>
