import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {Rutas} from '../../../../utiles/Rutas';
import {LoginService} from '../../../pages/services/login.service';


@Injectable()
export class DocumentosService {



  constructor(private http: HttpClient, private router: Router, private _login: LoginService) {
  }

  getTipoDocumentos() {
    return this.http.get(Rutas + 'get/all_tipos?' + '&token=' + localStorage.getItem('token'));
  }


  getDocumentosUser(id, rol) {
    return this.http.get(Rutas + 'get/tipos_rol?id_user=' + id + '&rol=' + rol  + '&token=' + localStorage.getItem('token'));
  }

  addTipoDocumento(datos) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {datos};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    return this.http.post(Rutas + 'add/tipos_documento', params, {headers: headers});
  }

  updateTipoDocumento(datos) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {datos};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    return  this.http.post(Rutas + 'update/tipos_documento', params, {headers: headers});
  }

  addDocumento(data) {
    return this.http.post(Rutas + 'add/documento_user', data, {responseType: 'text' as 'json'});
  }

  deleteDocumentosUser(id_user, id) {
    return this.http.get(Rutas + 'delete/documento?id_user=' + id_user + '&id=' + id  + '&token=' + localStorage.getItem('token'));
  }

  addCursoExtracurricular(data) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {data};
    const dato = JSON.stringify(json);
    const params = 'json=' + dato + '&token=' + localStorage.getItem('token');
    return this.http.post(Rutas + 'post/cursillo', params, {headers: headers});
  }

  getCursoExtracurriculares() {
    return this.http.get(Rutas + 'get/cursillos?' + '&token=' + localStorage.getItem('token'));
  }

  deleteCursoVerano(id) {
    return this.http.get(Rutas + 'delete/cursillo?&id=' + id  + '&token=' + localStorage.getItem('token'));
  }

  getCambiarEstadoCurso(id) {
    return this.http.get(Rutas + 'finaliza/cursillo?id=' + id + '&token=' + localStorage.getItem('token'));
  }

}
