// Importamos los componentes necesarios
import {Pipe, PipeTransform} from '@angular/core';


// Le ponemos un nombre a la tuberia
@Pipe({name: 'sin_nota'})

// Definimos la clase implementado la interface PipeTransform
export class SinNotaPipe implements PipeTransform {
  transform (notas: any[], alumno: any): any {
      let value: any;
      let id_examen: number;
      notas.forEach(item => {
        if( item.id_alumno === alumno.id) {
          value = item.nota;
          id_examen = item.id_examen;
        }
      });
      return value;
  }

}
