import {Component, OnInit, TemplateRef} from '@angular/core';
import {UsersService} from '../../services/users.service';
import {ActivatedRoute} from '@angular/router';
import {Dato} from '../../../../../modelos/datos.modelo';
import Swal from 'sweetalert2';
import {MatriculaCursillosService} from '../../../../../services/matricula-cursillos.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cursos-extracurriculares',
  templateUrl: './cursos-extracurriculares.component.html',
  styleUrls: ['./cursos-extracurriculares.component.scss']
})
export class CursosExtracurricularesComponent implements OnInit {
  id: any;
  dato: Dato;
  active: any;
  tipos: any;
  Ce: any[] = [];
  cursillo: any = null;
  cursilloSelect: any = null;
  cursosIntensivos: any[] = [];
  fechaActual = new Date();
  observaciones = '';
  adjuntos = {
    dni: false,
    sip: false,
  };
  closeResult = '';

  constructor(private _user: UsersService, private route: ActivatedRoute, private cursillosService: MatriculaCursillosService,
              private modalService: NgbModal) { }

  ngOnInit(): void {
   this.getUser();
   this.getCursosExtracurriculares();
   this.getCursosExtraAlumno();
  }

  getUser(): void {
    this.id = this.route.snapshot.parent.params['id'];
    this._user.getDato(this.id);
    this._user.dato$.subscribe((res: any) => {
      this.dato = res;
    });
  }

  getCursosExtracurriculares(): void {
    this._user.getCursoExtracurricularesFitrado().subscribe( (resp: any) => {
      this.cursosIntensivos = resp['id'];
      console.log(this.cursosIntensivos);
    });
  }

  getCursosExtraAlumno(): void {
    this._user.getCursoExtraAlumno(this.id).subscribe( (resp: any) => {
      this.Ce = resp['id'];
      console.log(this.Ce);
    });
  }




  addAlumnoCursillo(e) {
    if (e !== null) {
      const data = {
        idCurso: e,
        idUser: this.id
      }
      this._user.addCursoExtracurricular(data).subscribe( (resp: any) => {
        if (resp['code'] === 200) {
          Swal.fire('', 'Datos Guardados', 'success');
          console.log(resp, 'guardar');
          this.Ce = [];
          this.Ce = resp['id'];
        }
      }, error => {
        Swal.fire('', 'No se pudo editar, se ha generado algun error', 'warning');
        console.log(error);
      });
    }
  }

  save() {
    console.log()
    this.cursillosService.generatePdfAbastos('download', this.dato, this.cursilloSelect, this.cursosIntensivos,
      this.observaciones, this.adjuntos, this.fechaActual);
  }

  open(content: TemplateRef<any>, cursillo) {
    this.cursilloSelect = cursillo;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
      this.closeResult = `Dismissed ${(reason)}`;
    });
  }

  delete(id) {
    console.log(id);
    Swal.fire({
      title: 'Deseas Eliminarlo?',
      showDenyButton: true,
      reverseButtons: true,
      confirmButtonText: 'Eliminar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          idCurso: id,
          idUser: this.id
        }
        this._user.deleteCursoVerano(data).subscribe( (res: any) => {
          if (res['code'] === 200) {
            Swal.fire('', 'Dato Eliminado', 'success');
            console.log(res, 'editar');
            this.Ce = [];
            this.Ce = res['id'];
          }
        }, error => {
          console.log(error);
          Swal.fire('', 'No se pudo editar, se ha generado algun error', 'warning');
        });
      } else if (result.isDenied) {
        Swal.fire('', 'No se ha eliminado', 'info')
      }
    })
  }
}
