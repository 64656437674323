import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DoughnutComponent} from './doughnut/doughnut.component';
import {ChartsModule} from 'ng2-charts';
import {TotalHorasPipe} from './pipe/total_horas.pipe';
import {PorcentajeFaltasPipe} from './pipe/porcentaje_faltas.pipe';


@NgModule({
  declarations: [
    DoughnutComponent,
    TotalHorasPipe,
    PorcentajeFaltasPipe

  ],
  imports: [
    CommonModule,
    ChartsModule
  ],
  exports: [
    DoughnutComponent,
    TotalHorasPipe,
    PorcentajeFaltasPipe]
})
export class GraficasModule {
}
