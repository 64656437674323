// Importamos los componentes necesarios
import {Pipe, PipeTransform} from '@angular/core';

// Le ponemos un nombre a la tuberia
@Pipe({name: 'mail'})

// Definimos la clase implementado la interface PipeTransform
export class MailPipe implements PipeTransform {
  transform(value: string): boolean {
    const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    // Se muestra un texto a modo de ejemplo, luego va a ser un icono
    if (emailRegex.test(value)) {
      return true;
    } else {
      return false;
    }

  }
}
