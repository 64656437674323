import {Component, OnDestroy, OnInit} from '@angular/core';
import {AsistenciasService} from '../services/asistencias.service';
import {Subscription} from 'rxjs/Subscription';
import {Router, ActivatedRoute} from '@angular/router';
import {LocalDataSource} from 'ng2-smart-table';

import {DomSanitizer} from '@angular/platform-browser';
import {DatePipe} from '@angular/common';


@Component({
  selector: 'app-lista-alumnosA',
  templateUrl: './lista-alumnos.component.html',
  styleUrls: ['../asistencias.component.scss'],
  providers: [DatePipe]
})
export class ListaAlumnosAComponent implements OnDestroy, OnInit {


  private alumnos = [];


  alumnosSubscription: Subscription = null;
  source: LocalDataSource;
  id: number;
  asignatura: number;
  curso: number;
  fecha: Date;
  clase: any = {};
  check: boolean;
  aviso: string;

  constructor (private _asistencias: AsistenciasService, private _route: ActivatedRoute,
               private _sanitizer: DomSanitizer, private  _date: DatePipe) {
    this.asignatura = this._route.snapshot.parent.params['id'];
    this.curso = this._route.snapshot.parent.params['curso'];
    this._asistencias.getAlumnos(this.curso, this.asignatura);
    this._asistencias.claseActual(this.curso, this.asignatura);


  }


  ngOnInit () {
    this.alumnosSubscription = this._asistencias.datos$.subscribe(data => {
      if (data) {
        this.alumnos = data;
      } else {
        this.aviso = 'Hoy no tienes clase en este aula';
      }
    });

    this._asistencias.aula$.subscribe(res => {
      this.clase = res;
      this._asistencias.checkAsistenciaDia(res.id);
    });

    this._asistencias.check$.subscribe(res => {
      console.log(res);
      this.check = res;
    });
  }


  public settings = {
    selectMode: 'multi',
    actions: false,
    columns: {
      id: {
        title: 'ID',
        filter: false,
        editable: false
      },

      nombre: {
        title: 'Nombre',
        filter: false,
      },
      apellidos: {
        title: 'Apellidos',
        filter: false,
      },
      fecha: {
        title: 'Fecha',
        filter: false,
        editable: false,
        valuePrepareFunction: () => {
          const raw = new Date();
          const formatted = this._date.transform(raw, 'dd MMM yyyy');
          return formatted;
        }
      },


    },
    attr: {
      class: 'table table-responsive'
    },
    edit: {
      editButtonContent: '',
      confirmSave: false
    },
    delete: {
      deleteButtonContent: '',
      confirmDelete: false
    },

    add: {
      addButtonContent: '',
      comfirmAdd: false
    },


  };


  ngOnDestroy (): void {
    this.alumnosSubscription.unsubscribe();
  }

  onUserRowSelect ($event) {
    if ($event.data === null) {
      console.log($event);
      this._asistencias.addAsistencias($event.selected, this.clase.id);
    } else {
      console.log($event);
    }
  }

}

