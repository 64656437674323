<!--Simple Table Starts-->
<section id="simple">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Notas examen {{aula}}</h4>
        </div>
        <div class="card-body">
          <div class="card-block">

            <table class="table table-striped my-table">
              <thead>
              <tr>
                <th scope="col">Nombre</th>
                <th scope="col">Apellidos</th>
                <th scope="col">Fecha</th>
                <th scope="col">Nota
                  <input type="checkbox" [checked]="ok" (change)="habilitar()" />
                </th>
                <th scope="col">Estado</th>
                <th scope="col">Visible
                  <input  type="checkbox" [checked]="check" (change)="toggle()" />
                </th>
                <th scope="col">Acciones</th>
              </tr>
              </thead>
              <tbody *ngIf="alumnos">
              <tr *ngFor="let alumno of alumnos; let i = index">
                <td>{{alumno.nombre}}</td>
                <td>{{alumno.apellidos}}</td>

                <td > {{ alumno.fecha.date | date: "dd-MM-yy"}}</td>
                <td id="a_{{i}}">
                  <i *ngIf="alumno.nota ; else no">
                    {{alumno.nota}}
                  </i>
                  <ng-template #no><i class="ft-stop-circle"></i></ng-template>
                </td>
                <td hidden id="b_{{i}}" class="input-nota">
                  <input id="h_{{i}}" type="text" [(ngModel)]="alumno.nota" name="{{i}}"  (keyup.enter)="add(alumno, alumno.nota, i)"/>
                  <small id="c_{{i}}" class="upload"><i class="ft-upload-cloud"   (click)="add(alumno, alumno.nota, i)"></i></small>
                </td>
                <td  scope="row">
                  <i [ngClass]="{'ft-check success': alumno.estado, 'ft-alert-circle danger': !alumno.estado }"></i>
                </td>
                <td scope="row"><input name="foo" type="checkbox" [checked]="alumno.visible" [value]="i" (change)="change(i)"> </td>
                <td>
                  <ul class="list-inline">
                    <li class="list-inline-item success p-0" *ngIf="alumno.id_examen">
                      <i class="ft-upload font-medium-3 mr-2" (click)="upFile(alumno.id_examen)"></i>
                    </li>
                    <li class="success p-0 list-inline-item">
                      <i class="ft-edit-2 font-medium-3 mr-2" (click)="editar(i)"></i>
                    </li>
                    <li *ngIf="alumno.files[0]" class="success p-0 list-inline-item">
                      <i *ngIf="!files; else sel" id="ab_{{i}}" class="ft-file font-medium-3 mr-2"
                         (click)="openFiles()"></i>

                      <ng-template #sel>
                        <select name='fileUrl' [(ngModel)]='fileUrl' (change)="openFile()">
                          <option *ngFor="let file of alumno.files; let i = index;" value="{{file.url}}">{{i + 1}}
                          </option>
                        </select>
                      </ng-template>
                    </li>

                  </ul>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <input hidden id="buscadorPersona" type="file" class="form-control-file" name="files"
         (change)="fileChangeEvent($event)"/>
</section>
