import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import 'rxjs/add/operator/map';
import {LoginService} from '../pages/services/login.service';
import {RutasImg} from '../../utiles/Rutas';

@Injectable()
export class UploadService {
  readonly url: string;

  constructor(private _http: HttpClient, private _login: LoginService) {
    this.url = RutasImg + 'add/files';
  }

  makeFileRequestFoto(id, files, name: string, action: string) {
    const url = this.url;
    const token = this._login.getToken();
    const tipo = action;
    return new Promise(function (resolve, reject) {
      const formData: any = new FormData();
      const xhr = new XMLHttpRequest();
      formData.append(name, files, files.name);
      formData.append('token', token);
      formData.append('id', id);
      formData.append('tipo', tipo);
      console.log(formData);

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {

          if (xhr.status === 200) {
            console.log(xhr);
            resolve(JSON.parse(xhr.response));
          } else {
            reject(xhr.response);
          }
        }
      };
      xhr.open('POST', url, true);
      xhr.send(formData);
      console.log(formData);
    });
  }

  makeFileRequest(id, files: Array<File>, name: string, action: string) {
    const url = this.url;
    const token = this._login.getToken();
    const tipo = action;
    return new Promise(function (resolve, reject) {
      const formData: any = new FormData();
      const xhr = new XMLHttpRequest();
      formData.append('id', id);
      for (let i = 0; i < files.length; i++) {
        formData.append(name, files[i], files[i].name);
      }
      formData.append('token', token);
      formData.append('tipo', tipo);

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {

          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.response));
          } else {
            reject(xhr.response);
          }
        }
      };
      xhr.open('POST', url, true);
      xhr.send(formData);
    });
  }


  makeFile(formData) {
    return this._http.post(RutasImg + 'add/justificante', formData);
  }
}
