<div class="row">
  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
    <label>Asignatura</label>
    <select class="form-control form-control" name="asignatura" [(ngModel)]="asignatura"
            (change)="setAsignatura(asignatura)">
      <option value="">Seleccione una asignatura...</option>
      <option *ngFor="let asignatura of asignaturas" [ngValue]="asignatura">{{asignatura.asignatura}}</option>
    </select>
  </div>
</div>

<div class="row">
  <table class="table" *ngIf="notasFinales.length > 0" style="border: 0">
    <thead>
    <tr class="d-flex">
      <th class="col-4" scope="col">Alumno</th>
      <th class="col-2" scope="col" *ngFor="let tipo of notasFinales[0].notas">{{tipo.tipo}}</th>
    </tr>
    </thead>
    <tbody id="cuerpo">
    <tr class="d-flex" *ngFor="let Nota of notasFinales; let i = index;">
      <td class="col-4">{{Nota.nombre | titlecase}}  {{Nota.apellidos | titlecase}}</td>
      <td class="col-2" *ngFor="let examen of Nota.notas; let x = index">
        <div id="a_{{i}}{{x}}" *ngIf="examen.nota?.nota != null">{{examen.nota?.nota}}&nbsp; &nbsp;
          <li class="success p-0 list-inline-item" *ngIf="examen.aa != true">
            <i class="ft-edit-2 font-medium-3 mr-2" (click)="editar(i,x)"></i>
          </li>
        </div>
        <div class="col-auto" hidden id="b_{{i}}{{x}}" *ngIf="examen.nota?.nota != null">
          <input style="text-align: center" type="text" size="2" [(ngModel)]="examen.nota.nota" name="k{{i}}{{x}}"/>
          <small class="upload"><i class="ft-upload-cloud" (click)="add(examen.nota.id, examen.nota.nota, examen.tipoId ,i, x)"></i></small>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</div>
