import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-add-referido',
  templateUrl: './add-referido.component.html',
  styleUrls: ['./add-referido.component.scss']
})
export class AddReferidoComponent implements OnInit {

  referidopForm: FormGroup;

  constructor(public dialoRef: MatDialogRef<AddReferidoComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder) {
    this.initForms();
  }

  ngOnInit(): void {
  }

  initForms(): void{
    this.referidopForm = new FormGroup({
      referido: new FormControl('', Validators.required)
    });
  }

}
