import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AlquilerService} from '../services/alquiler.service';
import {MatChip} from '@angular/material/chips';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-calendario-alquiler',
  templateUrl: './listar-alquiler.component.html',
  styleUrls: ['./listar-alquiler.component.scss']
})
export class ListarAlquilerComponent implements OnInit {

  aulas: any = []
  contactos: any = []
  addForm: FormGroup;
  aulaSeleccionada: number;
  dias = [ {dia: 'L', active: false},
    {dia: 'M', active: false},
    {dia: 'X', active: false},
    {dia: 'J', active: false},
    {dia: 'V', active: false},
    {dia: 'S', active: false},
    {dia: 'D', active: false},
  ];

  @ViewChild('add', { static: true }) addDialog: TemplateRef<any>;
  constructor(private auquilerService: AlquilerService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getAlquilables();
    this.getContactos();
    this.setForms();
    const dias = [ true, false, true, false, false, false, false];
    console.log(JSON.stringify(dias));
  }

  getAlquilables() {
     this.auquilerService.getAlquilables().subscribe( (res) => {
       this.aulas = res;
       console.log(res);
     });
  }

  getContactos() {
    this.auquilerService.getInfoContactos().subscribe( (res) =>{
      console.log(res);
      this.contactos = res;
    });
  }
  setForms() {
    this.addForm = new  FormGroup({
      'contacto': new FormControl('', Validators.required),
      'aula': new FormControl(this.aulaSeleccionada),
      'finicio': new FormControl('', Validators.required),
      'ffin': new FormControl('', Validators.required),
      'hfin': new FormControl('', Validators.required),
      'hinicio': new FormControl('', ),
      'diasd': new FormControl(''),
      'taula': new FormControl('', Validators.required),
      'observacion': new FormControl(''),
      'descripcion': new FormControl(''),

    });
  }

  seleccionar(id) {
   this.aulaSeleccionada = id;
    const dialogRef = this.dialog.open(this.addDialog, { hasBackdrop: true, width: '90%'});
    dialogRef.afterClosed().subscribe( result => {
      if (result ) {
        this.addForm.controls['aula'].setValue(this.aulaSeleccionada) ;
        this.addForm.controls['diasd'].setValue(JSON.stringify(this.dias))
        this.addForm.controls['hinicio'].setValue(this.addForm.controls['hinicio'].value.hour + ':' + this.addForm.controls['hinicio'].value.minute);
        this.addForm.controls['hfin'].setValue(this.addForm.controls['hfin'].value.hour + ':' + this.addForm.controls['hfin'].value.minute);

        console.log(this.addForm.value);
        this.auquilerService.addAlquiler(this.addForm.value).subscribe( ( res ) => {
          this.getAlquilables();
        });
      }
    });

  }
  setSelected(c: MatChip, i) {
    c.toggleSelected();
    this.dias[i].active = !this.dias[i].active;
    console.log(JSON.stringify(this.dias));
  }

}
