import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Router} from '@angular/router';
import {Rutas} from '../../../../utiles/Rutas';
import {LoginService} from '../../../pages/services/login.service';


@Injectable()
export class StatsService {
  private statsSource = new Subject<any>();
  public stats$ = this.statsSource.asObservable();

  private statSource = new Subject<any>();
  public stat$ = this.statSource.asObservable();
  id_centro: number;


  constructor (private http: HttpClient, private router: Router, private _login: LoginService) {
    this.id_centro = this._login.getIdentity().centro.id;
  }
  getCentroStats () {
    this.http.get(Rutas + 'stats/centro?id=' + this.id_centro + '&token=' + localStorage.getItem('token')).subscribe(data => {
      if (data['code'] === 200) {
      this.statsSource.next(data['id']);
      }
    }, err => {
      console.log(err);
    });
  }
}
