import {Component, ViewChild, OnDestroy, OnInit, ViewEncapsulation, TemplateRef} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';
import {CentroService} from '../../admin-centro/services/centro.service';
import {Router, ActivatedRoute} from '@angular/router';
import {LoginService} from '../../../pages/services/login.service';
import {UsersService} from '../services/users.service';
import {UploadService} from '../../../services/upload.service';
import {NgForm} from '@angular/forms';
import {Datos} from '../../../../modelos/datos.modelo';
import {RutasImg} from '../../../../utiles/Rutas';
import {HttpClient} from '@angular/common/http';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {WebcamImage, WebcamInitError} from 'ngx-webcam';
import {Observable, Subject} from 'rxjs';
import Swal from 'sweetalert2';
import {DatePipe} from '@angular/common';
import {ComercialService} from '../../comercial/services/comercial.service';
import {StringSubstrPipe} from '../../../../pipes/filtertext';

// *** PDF ***
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-datos',
  templateUrl: './datos.component.html',
  styleUrls: ['../users.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class DatosComponent implements OnInit, OnDestroy {
  closeResult = '';
  public provinciasSubscription: Subscription = null;
  public municipiosSubscription: Subscription = null;
  public datoSubscription: Subscription = null;
  public cursosSubscription: Subscription = null;
  public active: boolean;

  public errors: WebcamInitError[] = [];
  documents: any;
  docList = [];
  acceso = '0';
  otroAcceso: any;
  aula: string;
  aulas: any[] = [];
  aulasP: any[] = [];
  asignar = false;
  activar: boolean;
  promocionar = false;
  oldPromocionar = false;

  // latest snapshot
  public webcamImage: WebcamImage = null;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  public id: number;
  public type: string;
  public provincias: any[] = [];
  public municipios: any[] = [];
  public provincia: number;
  public municipio: number;
  public tipo: string;
  public filesToUpload: Array<File>;
  isAlumno = false;
  cursos: any[] = [];
  clase: string;
  idAula: any;
  ac: boolean;

  private movil: any[] = [];
  private telefono: any[] = [];
  private fax: any[] = [];
  private email: any[] = [];

  // Fechas
  fechas = new Date;

  // Para saber el mes actual
  dia = this.fechas.getDate();
  // Para saber el mes actual
  mes = this.fechas.getMonth();
  // Para saber el año actual
  anyo = this.fechas.getFullYear();

  fechaActual = new Date(this.anyo, this.mes, this.dia);

  anyoEscolar = this.anyo;
  cursoDesde = this.anyo;
  cursoHasta = this.anyo + 2;

  private FoCerNoCom = false;
  private FoCar = false;
  private FoDNI = false;
  private FoNUSS = false;
  private InSaEs = false;
  private FoCuBa = false;
  private OrDoBa = false;
  private HoInPa = false;
  observaciones: string;
  promocion: any = {
    acronimo: '',
    id: null,
    anyo_curso: null,
    aula: '',
    curso: null,
    modulo: 2,
    p_id: null,
  }
  promocionNew: any = {
    acronimo: '',
    id: null,
    anyo_curso: null,
    aula: '',
    curso: null,
    modulo: 2,
    p_id: null,

  }

  @ViewChild('f') loginForm: NgForm;
  @ViewChild('p') promocionForm: NgForm;

  public dato: Datos;

  constructor(private _route: ActivatedRoute, private _centro: CentroService, private _user: UsersService,
              private _upload: UploadService, private _login: LoginService, private _router: Router, private _http: HttpClient,
              private modalService: NgbModal, private datePipe: DatePipe, private comercialService: ComercialService,
              private sub: StringSubstrPipe) {
    this.dato = new Datos(0, '', '', '', '', new Date(), '', '', [],
      0, 0, '', [], [], [], false, false, '',
      '', '', '', {nivel: '', id: 4}, '', '', '', '');

    this.id = this._route.snapshot.parent.params['id'];
    this.type = this._route.snapshot.parent.params['type'];
    if (this.type === 'alumnos') {
      this.getAulasP();
      this._user.getCursosCentro();
      this.isAlumno = true;
      this.getDocumentos();
    }
    this._centro.getProvincias();

    if (this.id !== 0) {
      this._user.getDato(this.id);

      this.datoSubscription = this._user.dato$.subscribe(res => {
        this.dato = res;
        console.log(res);
        this.promocion = res.promocion;
        if (this.promocion !== null) {
          this.promocionar = true;
          this.oldPromocionar = true;
          this.ac = false;


        } else {
          this.promocion = {
            acronimo: '',
            id: null,
            anyo_curso: null,
            aula: '',
            curso: null,
            modulo: null,
            p_id: null,
          };
          this.promocionar = false;
          this.ac = true;
        }
        console.log(this.ac);

        if (this.isAlumno) {
          this.verificarAula(this.dato.curso?.id, this.id);
        }
      });
    }

  }

  ngOnInit() {
    this.provinciasSubscription = this._centro.provincias$.subscribe(res => {
      this.provincias = res;
    });
    this.cursosSubscription = this._user.cursos$.subscribe(res => {
      this.cursos = res;
    });
    this.municipiosSubscription = this._centro.municipios$.subscribe(res => {
      this.municipios = res;
    });
  }

  ngOnDestroy() {
    if (this.provinciasSubscription != null) {
      this.provinciasSubscription.unsubscribe();
    }
  }

  onSubmit() {
    console.log(this.loginForm);
    if (this.loginForm.valid) {
      delete this.loginForm.value.clase;
      console.log(this.loginForm.value);
      if (!this.idAula) {
        this._user.edit(this.loginForm.value);
        this._user.getDato(this.id);
      }
      if (this.idAula) {
        const datos = {
          idUser: this.id,
          idAula: this.idAula
        };
        this._user.updateAula(datos).subscribe((res) => {
          if (res['code'] === 200) {
            Swal.fire('', 'Alumno asignado a una aula', 'success');
            this._user.getDato(this.id);
            this.idAula = null;
          }
        }, error => {
          console.log(error);
        })
      }
    }
  }

  verificarAula(idCurso, id) {
    this._user.verificarAula(idCurso, id).subscribe(data => {
      console.log(data);
      if (data['id'].lista_aulas.length > 0) {
        this.aulas = data['id'].lista_aulas;

        if (data['id'].aula_alumno === null) {
          this.aula = data['id'].aula_alumno;
          this.asignar = true;
        } else {
          this.aula = data['id'].aula_alumno.anyo_curso.toString() + ' '
            + data['id'].aula_alumno.acronimo + ' ' + data['id'].aula_alumno.aula + ' - ' + data['id'].aula_alumno.curso;
          this.clase = data['id'].aula_alumno.id;
          this.asignar = false;
        }
        this.activar = this.aula !== null;
      } else {
        this.aula = null;
        this.asignar = false;
      }
    });
  }

  getDocumentos() {
    this._user.getDocumentos(this.id).subscribe((res) => {
      this.documents = res['id'];
      this.documentos();
    });
  }

  getMunicipios() {
    this._centro.getMunicipios(+this.dato.id_provincia);
  }

  addPhoto(type: string) {
    this._upload
      .makeFileRequest(this.id, this.filesToUpload, 'imagen', type)
      .then(
        (result: any) => {
          if (type === 'user') {
            const identity = this._login.getIdentity();
            identity.img = '/' + result.id;
            localStorage.setItem('identity', JSON.stringify(identity));
            this.dato['img'] = identity.img;
          } else {
            this.dato['titulos'] = result.img;
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  fileChangeEvent(fileinput: any, type: string) {
    this.filesToUpload = <Array<File>>fileinput.target.files;

    this.addPhoto(type);
    this.modalService.dismissAll();
  }

  // MODAL
  open(content: TemplateRef<any>) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      if (result === 'foto') {
        this.subirfoto();
      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  async generatePdf(action = 'download') {
    const documentDefinition = await this.getDocumentDefinition();
    switch (action) {
      case 'open':
        pdfMake.createPdf(documentDefinition).open();
        break;
      case 'print':
        pdfMake.createPdf(documentDefinition).print();
        break;
      case 'download':
        pdfMake.createPdf(documentDefinition).download(this.dato.nombre + ' ' + this.dato.apellidos + '.pdf');
        break;
      default:
        pdfMake.createPdf(documentDefinition).open();
        break;
    }
  }

  getTutoresObject() {
    if (this.dato?.tutores.length > 0) {
      return {
        table: {
          widths: [80, 110, 30, 120, 40, 80],
          body: [
            ...this.dato?.tutores.map(ed => {
              return [ { text: 'Nombre Tutor/a', style: 'tableHeader4'}, { text: ed.nombre + ' ' + ed.apellidos, fontSize: 9},
                {text: 'Email', style: 'tableHeader4'}, {text: ed.dni.includes('@') ? ed.dni : '', fontSize: 9},
                {text: 'Telefono', style: 'tableHeader4'}, {text: ed.dato === null ? '' : ed.dato, fontSize: 9}];
            })
          ]
        }
      };
    } else {
      return {
        table: {
          widths: [80, 110, 30, 120, 40, 80],
          body: [
            [
              {text: 'Nombre Tutor/a', style: 'tableHeader4'}, {text: ' '},
              {text: 'Email', style: 'tableHeader4'}, {text: ' '},
              {text: 'Telefono', style: 'tableHeader4'}, {text: ' '}
            ]
          ]
        }
      };
    }
  }


  getTitulosObject() {
    if (this.dato.titulos) {
      return {
        table: {
          widths: ['*'],
          body: [
            [
              {text: 'TITULOS', style: 'tableHeader1'}
            ],
            ...this.dato.titulos.map(ed => {
              return [ed.titulo];
            }),
          ]
        },
      };
    } else {
      return {
        table: {
          widths: ['*'],
          body: [
            [
              {text: 'TITULOS', style: 'tableHeader1'}
            ],
            [
              {text: ' '}
            ],
          ]
        }
      };
    }
  }

  filtrarMoviles() {
    this.movil = [];
    if (this.dato.contactos) {
      for (let i = 0; i <= this.dato.contactos?.length - 1; i++) {
        if (this.dato.contactos[i].tipo === 2) {
          this.movil.push(this.dato.contactos[i].dato + '\n');
        }
      }
      return this.movil;
    } else {
      return ' ';
    }
  }

  filtrarTelefonos() {
    this.telefono = [];
    if (this.dato.contactos) {
      for (let i = 0; i <= this.dato.contactos?.length - 1; i++) {
        if (this.dato.contactos[i].tipo === 3) {
          this.telefono.push(this.dato.contactos[i].dato + '\n');
        }
      }
      return this.telefono;
    } else {
      return ' ';
    }
  }

  filtrarFax() {
    this.fax = [];
    if (this.dato.contactos) {
      for (let i = 0; i <= this.dato.contactos?.length - 1; i++) {
        if (this.dato.contactos[i].tipo === 4) {
          this.fax.push(this.dato.contactos[i].dato + '\n');
        }
      }
      return this.fax;
    } else {
      return ' ';
    }
  }

  filtrarEmail() {
    this.email = [];
    if (this.dato.contactos) {
      for (let i = 0; i <= this.dato.contactos?.length - 1; i++) {
        if (this.dato.contactos[i].tipo === 1) {
          this.email.push(this.dato.contactos[i].dato.toLowerCase() + '\n');
        }
      }
      return this.email;
    } else {
      return ' ';
    }
  }

  // Para mostrar la foto del alumno.
  // Para que haya un rowSpan debe ser mayor que uno, para que no haya poner 1 o 0
  async imagenPdf(url: string, width: number, alineacion: string, rowSpan: number) {
    if (rowSpan > 1) {
      return {
        image: await this.getBase64ImageFromURL(url),
        width: width,
        alignment: alineacion,
        rowSpan: rowSpan
      };
    } else {
      return {
        image: await this.getBase64ImageFromURL(url),
        width: width,
        alignment: alineacion
      };
    }
  }

  // Para generar una imgen
  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = error => {
        reject(error);
      };
      img.src = url + '?r=' + Math.floor(Math.random() * 100000);
    });
  }

  trueFalse(check: string) {
    switch (check) {
      case 'FoCerNoCom':
        this.FoCerNoCom = this.FoCerNoCom !== true;
        break;
      case 'FoCar':
        this.FoCar = this.FoCar !== true;
        break;
      case 'FoDNI':
        this.FoDNI = this.FoDNI !== true;
        break;
      case 'FoNUSS':
        this.FoNUSS = this.FoNUSS !== true;
        break;
      case 'InSaEs':
        this.InSaEs = this.InSaEs !== true;
        break;
      case 'FoCuBa':
        this.FoCuBa = this.FoCuBa !== true;
        break;
      case 'OrDoBa':
        this.OrDoBa = this.OrDoBa !== true;
        break;
      case 'HoInPa':
        this.HoInPa = this.HoInPa !== true;
        break;
    }
  }

  seleccionado(check: string) {
    switch (check) {
      case 'FoCerNoCom':
        if (this.FoCerNoCom === true) {
          return {text: 'X', alignment: 'center'};
        } else {
          return {text: ' '};
        }
      case 'FoCar':
        if (this.FoCar === true) {
          return {text: 'X', alignment: 'center'};
        } else {
          return {text: ' '};
        }
      case 'FoDNI':
        if (this.FoDNI === true) {
          return {text: 'X', alignment: 'center'};
        } else {
          return {text: ' '};
        }
      case 'FoNUSS':
        if (this.FoNUSS === true) {
          return {text: 'X', alignment: 'center'};
        } else {
          return {text: ' '};
        }
      case 'InSaEs':
        if (this.InSaEs === true) {
          return {text: 'X', alignment: 'center'};
        } else {
          return {text: ' '};
        }
      case 'FoCuBa':
        if (this.FoCuBa === true) {
          return {text: 'X', alignment: 'center'};
        } else {
          return {text: ' '};
        }
      case 'OrDoBa':
        if (this.OrDoBa === true) {
          return {text: 'X', alignment: 'center'};
        } else {
          return {text: ' '};
        }
      case 'HoInPa':
        if (this.HoInPa === true) {
          return {text: 'X', alignment: 'center'};
        } else {
          return {text: ' '};
        }
    }
  }

  tablaInfo(): any {
    console.log(this.dato.curso.nivel);
    const smr = this.dato.curso.nivel?.includes('SMR');
    if (smr) {
      return {
        table: {
          widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
          body: [
            [
              {text: 'Ciclo Formativo', style: ['tableHeader2', 'tituloTextoPequenyo']},
              {text: 'Importe anual Matrícula', style: ['tableHeader2', 'tituloTextoPequenyo']},
              {text: 'Forma de Pago', style: ['tableHeader2', 'tituloTextoPequenyo']},
              {text: 'Fecha del primer vencimiento', style: ['tableHeader2', 'tituloTextoPequenyo']},
              {text: 'Pago de 20 cuotas de', style: ['tableHeader2', 'tituloTextoPequenyo']},
              {text: 'Coste total', style: ['tableHeader2', 'tituloTextoPequenyo']},
            ],
            [
              {text: 'SISTEMAS MICROINFORMATICOS Y REDES', style: 'textoPequenyo'},
              {text: '275 €', style: ['textoPequenyo', 'textoCentrado']},
              {text: 'Aplazado', style: ['textoPequenyo', 'textoCentrado']},
              {text: '01/09/' + this.cursoDesde, style: ['textoPequenyo', 'textoCentrado']},
              {text: '275 €', style: ['textoPequenyo', 'textoCentrado']},
              {text: '6.050 €', style: ['textoPequenyo', 'textoCentrado']},
            ]
          ]
        }
      }
    }

    const daw = this.dato.curso.nivel?.includes('DAW');
    if (daw) {
      return {
        table: {
          widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
          body: [
            [
              {text: 'Ciclo Formativo', style: ['tableHeader2', 'tituloTextoPequenyo']},
              {text: 'Importe anual Matrícula', style: ['tableHeader2', 'tituloTextoPequenyo']},
              {text: 'Forma de Pago', style: ['tableHeader2', 'tituloTextoPequenyo']},
              {text: 'Fecha del primer vencimiento', style: ['tableHeader2', 'tituloTextoPequenyo']},
              {text: 'Pago de 20 cuotas de', style: ['tableHeader2', 'tituloTextoPequenyo']},
              {text: 'Coste total', style: ['tableHeader2', 'tituloTextoPequenyo']},
            ],
            [
              {text: 'DESARROLLO DE APLICACIONES WEB', style: 'textoPequenyo'},
              {text: '275 €', style: ['textoPequenyo', 'textoCentrado']},
              {text: 'Aplazado', style: ['textoPequenyo', 'textoCentrado']},
              {text: '01/09/' + this.cursoDesde, style: ['textoPequenyo', 'textoCentrado']},
              {text: '275 €', style: ['textoPequenyo', 'textoCentrado']},
              {text: '6.050 €', style: ['textoPequenyo', 'textoCentrado']},
            ]
          ]
        }
      }
    }

    const dam = this.dato.curso.nivel?.includes('DAM');
    if (dam) {
      return {
        table: {
          widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
          body: [
            [
              {text: 'Ciclo Formativo', style: ['tableHeader2', 'tituloTextoPequenyo']},
              {text: 'Importe anual Matrícula', style: ['tableHeader2', 'tituloTextoPequenyo']},
              {text: 'Forma de Pago', style: ['tableHeader2', 'tituloTextoPequenyo']},
              {text: 'Fecha del primer vencimiento', style: ['tableHeader2', 'tituloTextoPequenyo']},
              {text: 'Pago de 20 cuotas de', style: ['tableHeader2', 'tituloTextoPequenyo']},
              {text: 'Coste total', style: ['tableHeader2', 'tituloTextoPequenyo']},
            ],
            [
              {text: 'DESARROLLO DE APLICACIONES MULTIPLATAFORMA', style: 'textoPequenyo'},
              {text: '275 €', style: ['textoPequenyo', 'textoCentrado']},
              {text: 'Aplazado', style: ['textoPequenyo', 'textoCentrado']},
              {text: '01/09/' + this.cursoDesde, style: ['textoPequenyo', 'textoCentrado']},
              {text: '275 €', style: ['textoPequenyo', 'textoCentrado']},
              {text: '6.050 €', style: ['textoPequenyo', 'textoCentrado']},
            ]
          ]
        }
      }
    }

    const mp = this.dato.curso.nivel?.includes('Marketing y Publicidad');
    if (mp) {
      return {
        table: {
          widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
          body: [
            [
              {text: 'Ciclo Formativo', style: ['tableHeader2', 'tituloTextoPequenyo']},
              {text: 'Importe anual Matrícula', style: ['tableHeader2', 'tituloTextoPequenyo']},
              {text: 'Forma de Pago', style: ['tableHeader2', 'tituloTextoPequenyo']},
              {text: 'Fecha del primer vencimiento', style: ['tableHeader2', 'tituloTextoPequenyo']},
              {text: 'Pago de 20 cuotas de', style: ['tableHeader2', 'tituloTextoPequenyo']},
              {text: 'Coste total', style: ['tableHeader2', 'tituloTextoPequenyo']},
            ],
            [
              {text: 'TECNICO SUPERIOR DE MARKETING Y PUBLICIDAD', style: 'textoPequenyo'},
              {text: '250 €', style: ['textoPequenyo', 'textoCentrado']},
              {text: 'Aplazado', style: ['textoPequenyo', 'textoCentrado']},
              {text: '01/09/' + this.cursoDesde, style: ['textoPequenyo', 'textoCentrado']},
              {text: '250 €', style: ['textoPequenyo', 'textoCentrado']},
              {text: '5.500 €', style: ['textoPequenyo', 'textoCentrado']},
            ]
          ]
        }
      }
    }

    const d = this.dato.curso.nivel?.includes('Animaciones');
    if (d) {
      return {
        table: {
          widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
          body: [
            [
              {text: 'Ciclo Formativo', style: ['tableHeader2', 'tituloTextoPequenyo']},
              {text: 'Importe anual Matrícula', style: ['tableHeader2', 'tituloTextoPequenyo']},
              {text: 'Forma de Pago', style: ['tableHeader2', 'tituloTextoPequenyo']},
              {text: 'Fecha del primer vencimiento', style: ['tableHeader2', 'tituloTextoPequenyo']},
              {text: 'Pago de 20 cuotas de', style: ['tableHeader2', 'tituloTextoPequenyo']},
              {text: 'Coste total', style: ['tableHeader2', 'tituloTextoPequenyo']},
            ],
            [
              {text: 'ANIMACIONES 3D, JUEGOS Y ENTORNOS INTERACTIVOS (VJ-ART)', style: 'textoPequenyo'},
              {text: '350 €', style: ['textoPequenyo', 'textoCentrado']},
              {text: 'Aplazado', style: ['textoPequenyo', 'textoCentrado']},
              {text: '01/09/' + this.cursoDesde, style: ['textoPequenyo', 'textoCentrado']},
              {text: '350 €', style: ['textoPequenyo', 'textoCentrado']},
              {text: '7.700 €', style: ['textoPequenyo', 'textoCentrado']},
            ],
            [
              {text: 'ANIMACIONES 3D, JUEGOS Y ENTORNOS INTERACTIVOS (VJ-PROG)', style: 'textoPequenyo'},
              {text: '350 €', style: ['textoPequenyo', 'textoCentrado']},
              {text: 'Aplazado', style: ['textoPequenyo', 'textoCentrado']},
              {text: '01/09/' + this.cursoDesde, style: ['textoPequenyo', 'textoCentrado']},
              {text: '350 €', style: ['textoPequenyo', 'textoCentrado']},
              {text: '7.700 €', style: ['textoPequenyo', 'textoCentrado']},
            ],
            [
              {text: 'ANIMACIONES 3D, JUEGOS Y ENTORNOS INTERACTIVOS (ANIM)', style: 'textoPequenyo'},
              {text: '320 €', style: ['textoPequenyo', 'textoCentrado']},
              {text: 'Aplazado', style: ['textoPequenyo', 'textoCentrado']},
              {text: '01/09/' + this.cursoDesde, style: ['textoPequenyo', 'textoCentrado']},
              {text: '320 €', style: ['textoPequenyo', 'textoCentrado']},
              {text: '7.040 €', style: ['textoPequenyo', 'textoCentrado']},
            ]
          ]
        }
      }
    }

    const dd = this.dato.curso.nivel?.includes('Animación');
    if (dd) {
      return {
        table: {
          widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
          body: [
            [
              {text: 'Ciclo Formativo', style: ['tableHeader2', 'tituloTextoPequenyo']},
              {text: 'Importe anual Matrícula', style: ['tableHeader2', 'tituloTextoPequenyo']},
              {text: 'Forma de Pago', style: ['tableHeader2', 'tituloTextoPequenyo']},
              {text: 'Fecha del primer vencimiento', style: ['tableHeader2', 'tituloTextoPequenyo']},
              {text: 'Pago de 20 cuotas de', style: ['tableHeader2', 'tituloTextoPequenyo']},
              {text: 'Coste total', style: ['tableHeader2', 'tituloTextoPequenyo']},
            ],
            [
              {text: 'ANIMACIÓN 3D MULTIPLATAFORMA', style: 'textoPequenyo'},
              {text: '290 €', style: ['textoPequenyo', 'textoCentrado']},
              {text: 'Aplazado', style: ['textoPequenyo', 'textoCentrado']},
              {text: '01/09/' + this.cursoDesde, style: ['textoPequenyo', 'textoCentrado']},
              {text: '290 €', style: ['textoPequenyo', 'textoCentrado']},
              {text: '6.380 €', style: ['textoPequenyo', 'textoCentrado']},
            ]
          ]
        }
      }
    }
  }

  async getDocumentDefinition() {
    sessionStorage.setItem('resume', JSON.stringify(this.dato));
    return {
      content: [
        // 1. CABECERA
        await this.imagenPdf('assets/img/progresa-color.png', 220, 'center', 0),
        {
          text: [
            {
            text:  'Matrícula ', bold: true, fontSize: 28, alignment: 'center', border: [false, false, false, false]
            },
            {
              text:  this.cursoDesde + ' - ' + this.cursoHasta, bold: true, fontSize: 18
            }
          ],
          margin: [0, 10, 0, 0]
        },
        {
          // Salto de linea
          text: ' '
        },
        {
          table: {
            widths: '*',
            body: [
              [
                {
                  text: this.dato.curso.familia + '. ' + this.sub.transform(this.dato.curso.nivel),
                  bold: true,
                  fontSize: 20,
                  alignment: 'center',
                  color: 'white',
                  border: [false, false, false, false],
                  background: '#4AD3B9'
                },
              ]
              ]
          },
          margin: [0, 0, 0, 15],
        },
        // 2. DATOS PERSONALES DEL ALUMNO
        {
          table: {
            headerRows: 0,
            widths: ['*', 200, '*', 150],
            body: [
              [
                {text: 'Apellidos', style: 'tableHeader4', border: [true, true, false, false]},
                { text: this.dato.apellidos, border: [false, true, false, false]},
                {text: 'Nombre', style: 'tableHeader4', border: [false, true, false, false]},
                { text: this.dato.nombre, border: [false, true, true, false]},
              ]
            ]
          }
        },
        {
          table: {
            headerRows: 0,
            widths: [80, 70, 80, 80, '*', 100],
            body: [
              [
                {text: 'Fecha nacimiento', style: 'tableHeader4', border: [true, true, false, false]},
                { text: this.datePipe.transform(this.dato.nacimiento, 'dd-MM-YYYY'),  border: [false, true, false, false]},
                {text: 'DNI/Pasaporte', style: 'tableHeader4', border: [false, true, false, false]},
                { text: this.dato.dni, border: [false, true, false, false]},
                {text: 'Provincia', style: 'tableHeader4', border: [false, true, false, false]},
                { text: this.dato.provincia, border: [false, true, true, false]}
              ]
            ]
          }
        },
        {
          table: {
            headerRows: 0,
            widths: ['*', 140, '*', 220],
            body: [
              [
                  {text: 'Población', style: 'tableHeader4', border: [true, true, false, false]},
                { text: this.dato.municipio, border: [false, true, false, false]},
                {text: 'Domicilio', style: 'tableHeader4', border: [false, true, false, false]},
                { text: this.dato.direccion, border: [false, true, true, false]},
              ]
            ]
          }
        },
        {
          table: {
            headerRows: 0,
            widths: ['*', 120, 100, 150],
            body: [
              [
                {text: 'Provincia de nacimiento', style: 'tableHeader4', border: [true, true, false, false]},
                { text: this.dato.provinciaNacimiento === null ? ' ' : this.dato.provinciaNacimiento, border: [false, true, false, false]},
                {text: 'Ciudad de nacimiento', style: 'tableHeader4', border: [false, true, false, false]},
                { text: this.dato.ciudadNacimiento === null ? ' ' : this.dato.ciudadNacimiento, border: [false, true, true, false]},
              ]
            ]
          }
        },
        {
          table: {
            headerRows: 0,
            widths: ['*', 120, 110, 120],
            body: [
              [
                {text: 'Nacionalidad', style: 'tableHeader4', border: [true, true, false, false]},
                { text: this.dato.Nacionalidad === null ? ' ' : this.dato.Nacionalidad, border: [false, true, false, false]},
                {text: 'País de estudios previos', style: 'tableHeader4', border: [false, true, false, false]},
                { text: this.dato.paisEstudiosPrevios === null ? ' ' : this.dato.paisEstudiosPrevios, border: [false, true, true, false]},
              ]
            ]
          }
        },
        {
          table: {
            headerRows: 0,
            widths: ['*', 80, '*', 100, '*', 200],
            body: [
              [
                {text: 'Móvil', style: 'tableHeader4', border: [true, true, false, false]},
                { text: this.filtrarMoviles(),  border: [false, true, false, false]},
                {text: 'Fijo', style: 'tableHeader4', border: [false, true, false, false]},
                { text: this.filtrarTelefonos(),  border: [false, true, false, false]},
                {text: 'Email', style: 'tableHeader4', border: [false, true, false, false]},
                { text: this.filtrarEmail(), fontSize: 10, border: [false, true, true, false]}
              ]
            ]
          }
        },
        // Titulos
        this.getTitulo(),

        // 3. TUTORES
        this.getTutoresObject(),
        // 4. DOCUMENTACIÓN APORTADA (A RELLENAR POR EL CENTRO)
        {
          text: 'Documentación aportada',
          bold: true,
          fontSize: 16,
          alignment: 'center',
          color: '#4AD3B9',
          margin: [0, 12, 0, 12]
        },
        {
          table: {
            headerRows: 0,
            widths: [10, 155, 10, 300],
            body: [
              ...this.docList.map(a => {
                return a;
              }),

            ]
          }
        },
        {
          text: 'Cuenta bancaria',
          bold: true,
          fontSize: 16,
          alignment: 'center',
          color: '#4AD3B9',
          margin: [0, 12, 0, 12]
        },
        {
          table: {
            widths: ['*'],
            body: [
              [
                {text: ' ', border: [true, true, true, false], style: 'tableHeader5'}
              ],
              [
                {text: this.dato.iban ? this.dato.iban['dato'] : ' ', border: [true, false, true, true], alignment: 'center'}
              ]
            ]
          },
        },
        {
          // Salto de linea
          text: ' '
        },

        // 7. OBSERVACIONES
        {
          table: {
            widths: ['*'],
            body: [
              [
                {text: 'Observaciones', border: [true, true, true, false], style: 'tableHeader5'}
              ],
              [
                {text: this.dato?.observacionesMatricula === '' ? ' ' : this.dato?.observacionesMatricula, border: [true, false, true, true],
                  fontSize: 9, alignment: 'justify'}
              ]
            ],


          }
        },

        // 8. FIRMA
        {
          style: ['textoPequenyo', 'justificado'],
          text: [
            {
              text: 'Recibida la información detallada de las características del curso, contenido y demás cuestiones relacionadas, ' +
                'el alumno/titular acepta las condiciones generales y académicas amparadas en este contrato y ',
              margin: [0, 0, 0, 20], fontSize: 9
            },
            {
              text: 'SOLICITA LA MATRÍCULA PARA EL CURSO', bold: true, fontSize: 9
            }
          ],
        },
        {
          // Salto de linea
          text: ' '
        },
        {
          style: ['textoPequenyo', 'justificado'],
          // text: 'FECHA: ' + this.datePipe.transform(this.fechaActual, 'dd-MM-YYYY')
          text: 'Valencia a    ' + new Date(this.fechaActual).getDate() + '    de    ' +
            (new Date(this.fechaActual).toLocaleString('default', { month: 'long' })) +
            '    de    ' + new Date(this.fechaActual).getFullYear()
        },
        {
          // Salto de linea
          text: ' '
        },
        {
          columns: [
            {
              style: ['tituloTextoPequenyo'],
              table: {
                widths: ['*'],
                heights: [70],
                body: [
                  [
                    {text: 'Firma del alumno:'}
                  ]
                ]
              }
            },
            {
              style: ['tituloTextoPequenyo'],
              table: {
                widths: ['*'],
                heights: [70],
                body: [
                  [
                    {text: 'Firma y sello del centro:'}
                  ]
                ]
              }
            }
          ],
          columnGap: 10,
          pageBreak: 'after'
        },
        // INFORMACION
        {
          text: 'Coste del Ciclo Formativo', style: ['tituloTextoPequenyo', 'negrita']
        },
        {
          style: ['textoPequenyo', 'justificado'],
          ul: [
            'El coste de los Ciclos Formativos corresponde a los dos cursos de formación. Este coste se divide en 20 cuotas de ' +
            'igual cuantía, de septiembre a junio (ambos incluidos), o pago único con descuento.',

            'Los Ciclos Formativos ofertados por Progresa Formación y los costes asociados a los mismos se resumen en la siguiente ' +
            'Tabla de Costes:'
          ]
        },
        this.tablaInfo(),
        {
          style: ['textoPequenyo', 'justificado'],
          ul: [
            'El coste de las matrículas de los dos años, podrá abonarse en efectivo en la secretaria del centro, ' +
            'o al siguiente número de cuenta. '
          ]
        },
        {
          // Salto de linea
          text: ' '
        },
        {
          text: 'TRANSFERENCIA BANCARIA AL BBVA',
          style: ['negrita', 'centrado']
        },
        {
          text: 'CTA IBAN ES7401827710410201664282',
          style: ['negrita', 'centrado'],
          background: '#4AD3B9'
        },
        {
          // Salto de linea
          text: ' '
        },
        {
          text: 'CLÁUSULAS Y CONDICIONES GENERALES', style: ['tituloTextoPequenyo', 'negrita']
        },
        {
          text: '1. PROGRESA CENTRO INTEGRADO DE FORMACIÓN S.L. (a partir de ahora, PROGRESA) se compromete a ofrecer sus servicios ' +
            'de enseñanza enlostérminos que se estipularán en este contrato y en el Anexo, y a hacer entrega al estudiante de un ' +
            'certificado o título una vez el estudiantecumplacon los requisitos para su graduación que se describen en el catálogo de ' +
            'estudios vigentes, el cual le es entregado al estudiante previo asuadmisión.', style: ['textoPequenyo', 'justificado']
        },
        {
          text: '2. El estudiante y la persona que suscriba el contrato junto a él (a partir de ahora, “tercero suscribiente"), ' +
            'deberán cumplir con sus obligacionesfinancieras con PROGRESA como requisito para graduarse.',
          style: ['textoPequenyo', 'justificado']
        },
        {
          style: ['textoPequenyo', 'justificado'],
          text: [
            {
              text: '3. El estudiante y el tercero suscribiente se comprometen a hacer pago de la cuantía que resultase a abonar de ' +
                'acuerdo con el presentecontrato.'
            },
            {
              text: 'Asimismo, el estudiante y tercero suscribiente, aceptan que una situación de insolvencia posterior no supondrá ' +
                'causa pararescindir, ', style: ['negrita']
            },
            {
              text: 'anular o cancelar, el contrato sin la obligación de abonar la totalidad del curso, salvo que se hiciese en el ' +
                'plazo máximo de 14 díasdesde lafirma del contrato.', style: ['negrita', {decoration: 'underline'}]
            }

          ]
        },
        {
          text: '4. El estudiante y el tercero suscribiente autorizan a PROGRESA para que investigue el historial de crédito del ' +
            'estudiante y del tercero suscribiente, y a obtener información relativa, estrictamente, al cumplimiento de las obligaciones ' +
            'financieras por parte de los mismos a terceraspersonas sólo en aquello relativo a este contrato.',
          style: ['textoPequenyo', 'justificado']
        },
        {
          text: '5. Si fuese necesaria la reclamación judicial de la deuda, las costas y honorarios del abogado será cubierto por el ' +
            'estudiante y el tercerosuscribiente.', style: ['textoPequenyo', 'justificado']
        },
        {
          text: '6. PROGRESA no garantiza ni promete empleo al graduado, sin embargo, le asistirá activamente en la busqueda del mismo ' +
            'durante el tiempo que duren los estudios contratados.', style: ['textoPequenyo', 'justificado']
        },
        {
          text: '7. Los derechos de este Contrato no son transferibles a terceros por parte del estudiante ni el tercero suscribiente.',
          style: ['textoPequenyo']
        },
        {
          text: '8. PROGRESA se reserva el derecho a hacer cambios en el programa en términos de duración del mismo; libros de texto; ' +
            'reglamento de estudios;servicios y facilidades disponibles para el estudiante, pero estos cambios no serán de aplicación ' +
            'para el estudiante durante la duración delpresente contrato, en tanto en cuanto el estudiante permanezca matriculado, ni ' +
            'significarán una carga económica adicional a su cuenta.', style: ['textoPequenyo', 'justificado']
        },
        {
          text: '9. Que el alumno de Progresa Centro Integrado de Formación suscribiente de este contrato autoriza a Progresa Centro ' +
            'Integrado de Formación aque pueda comunicar al padre/madre/tutor del mismo sobre cualquier falta de asistencia, problemas ' +
            'de conducta, o falta de rendimiento, al fin de alcanzar así soluciones favorables a ambas partes.',
          style: ['textoPequenyo', 'justificado']
        },
        {
          style: ['textoPequenyo', 'justificado'],
          text: [
            {
              text: '10. ',
            },
            {
              text: 'Junto al presente documento se acompaña un Anexo de "POLÍTICA DE TERMINACIÓN"” y “"POLÍTICA DE PLAZOS IMPAGADOS", ' +
                'que determina las causas de cancelación, rescisión y desistimiento del contrato por ambas partes, y sus consecuencias, ' +
                'así como de los impagos.', style: 'negrita'
            }
          ]
        },
        {
          text: '11. Este contrato de estudios, los documentos que acompañan el mismo, y el Anexo anteriormente referido, constituyen un ' +
            'documento legal y puede ser cancelado o rescindido siguiendo los términos y condiciones establecidos.',
          style: ['textoPequenyo', 'justificado']
        },
        {
          style: ['textoPequenyo', 'justificado'],
          text: [
            {
              text: '12. ',
            },
            {
              text: 'Todos estos documentos deben ser leído por el estudiante y el tercero suscribiente en su totalidad, no debe ' +
                'contener espacios en blanco y, con la firma, se admite que cualquier duda sobre su contenido ha sido aclarada antes ' +
                'de firmarlo. El estudiante tiene derecho a obtener una copia del presente contrato, el cual se hace entrega en el ' +
                'momento de la firma del mismo.', style: 'negrita'
            }
          ]
        },
        {
          text: 'CERTIFICO QUE HE LEÍDO EL PRESENTE CONTRATO Y HE RECIBIDO COPIA DEL MISMO, ASÍ COMO DEL CATÁLOGO DE ESTUDIOS, Y ME ' +
            'COMPROMETO A ASISTIR PUNTUALMENTE A LAS CLASES Y A OBSERVAR LAS NORMAS DE CONDUCTA Y REGLAMENTOS VIGENTES.',
          style: ['textoPequenyo', 'justificado', 'negrita'], margin: [ 0, 5, 0, 0]
        },
        {
          text: 'ATENCIÓN: ASIMISMO, LOS SUCRIBIENTES DECLARAN RESPONSABLEMENTE QUE EL ESTUDIANTE NO PADECE NINGUNA ENFERMEDAD O ' +
            'TRASTORNO QUE PUEDA SUPONER UN IMPEDIMENTO O TRABA PARA LA ENSEÑANZA.',
          style: ['textoPequenyo', 'justificado', 'negrita', {decoration: 'underline'}]
        },
        {
          text: 'Esta comunicación se requiere sólo a los efectos de determinar la conveniencia de los estudios solicitados y, en su ' +
            'caso, poder adoptar la enseñanza a sus necesidades.', style: ['textoPequenyo', 'justificado', 'negrita']
        },
        {
          columns: [
            {
              style: ['tituloTextoPequenyo'],
              margin: [ 0, 5, 0, 0],
              table: {
                widths: ['*'],
                heights: [60],
                body: [
                  [
                    {text: 'Firma del alumno:', border: [true, true, true, false]}
                  ],
                  [
                    {text: 'Nombre Apellidos:', border: [true, false, true, true]}
                  ]
                ]
              }
            },
            {
              style: ['tituloTextoPequenyo'],
              margin: [ 0, 5, 0, 0],
              table: {
                widths: ['*'],
                heights: [60],
                body: [
                  [
                    {text: 'Firma del tutor:', border: [true, true, true, false]}
                  ],
                  [
                    {text: 'Nombre Apellidos:', border: [true, false, true, true]}
                  ]
                ]
              }
            }
          ],
          columnGap: 10
        },
        {
          margin: [ 0, 5, 0, 0],
          text: [
            {text: 'Tratamiento de datos personales.\n', style: ['negrita', 'tituloTextoPequenyo']},
            {
              text: 'Los datos personales recabados serán objeto de tratamiento por parte de PROGRESA, CENTRO INTEGRADO DE FORMACIÓN' +
                ' SL y serán tratados según dispone la LO 15/99 de 13 de diciembre y el Reglamento Europeo de Protección de Datos' +
                ' Personales (2016/679). La finalidad del tratamiento es la de poder prestar el servicio por el cual nos has' +
                ' facilitado los datos (recibir la formación solicitada) y poder hacerte llegar información sobre nuestra oferta' +
                ' formativa de manera periódica, al estar legitimados para ello. No vamos a ceder los datos facilitados a terceras' +
                ' empresas o entidades, salvo por imperativo legal o para poder gestionar el curso en el que te hayas matriculado.' +
                ' En se sentido, en función de la tipología de curso, los datos tendrán que ser facilitados a entidades públicas como' +
                ' la Conselleria de Educación o El SEPE para poder ser gestionados como corresponde. Los derechos de acceso,' +
                ' rectificación, cancelación y oposición al tratamiento de datos pueden ser ejercidos a la dirección' +
                ' progresa@cieep.com adjuntando copia del DNI y fundamentando la petición.', style: ['textoPequenyo', 'justificado']
            }
          ],
          pageBreak: 'after'
        },
         await this.imagenPdf('assets/img/progresa-color.png', 220, 'left', 0),
        {
          // Salto de linea
          text: ' '
        },
        {
          text: 'POLÍTICA DE TERMINACIÓN',
          style: [{fontSize: 9}, {alignment: 'center'}, 'negrita', {decoration: 'underline'}]
        },
        {
          text: '-> Cancelación del contrato por PROGRESA CENTRO INTEGRADO DE FORMACIÓN S.L.:',
          style: ['textoPequenyo', 'negrita', {decoration: 'underline'}], margin: [ 0, 5, 0, 0]
        },
        {
          style: ['textoPequenyo', 'justificado'], margin: [ 0, 5, 0, 0],
          text: [
            {
              text: 'PROGRESA se reserva el derecho a cancelar o rescindir el contrato ',
            },
            {
              text: 'en el caso de que el estudiante incurra en una o más de las siguientes circunstancias:',
              style: ['negrita', {decoration: 'underline'}]
            }
          ]
        },
        {
          style: ['textoPequenyo', 'justificado'], margin: [ 0, 5, 0, 0],
          text: [
            {
              text: 'I.- Que el estudiante, y/o la persona que suscriba en su caso el contrato con él, hayan ocultado alguna enfermedad ' +
                'o  trastorno que pudiese suponer un impedimento o traba para la enseñaza, al ser un incumplimiento  de  ' +
                'ladeclaración  responsable  recogida en la firma de las “CLÁUSULAS Y CONDICIONES”. En este caso, ',
            },
            {
              text: 'se devengará, y se deberá abonar por el estudiante y el tercero suscribiente, el total del curso.', style: 'negrita'
            }
          ]
        },
        {
          style: ['textoPequenyo', 'justificado'], margin: [ 0, 5, 0, 0],
          text: [
            {
              text: 'II.-  Que  el  curso  no  alcance a  tener  suficientes  estudiantes  matriculados,  en  cuyo  caso  PROGRESA  ' +
                'se  lo notificará al estudiante y al tercero suscribiente, ',
            },
            {
              text: 'y se reembolsará todo el dinero que se haya abonado.', style: 'negrita'
            }
          ]
        },
        {
          style: ['textoPequenyo', 'justificado'], margin: [ 0, 5, 0, 0],
          text: [
            {
              text: 'III.- Que el estudiante no siga o cumpla con las normas o reglamentos establecidos por PROGRESA.' +
                '  En  este caso, ',
            },
            {
              text: 'se devengará, y se deberá abonar por el estudiante y el tercero suscribiente, el total del curso.', style: 'negrita'
            }
          ]
        },
        {
          style: ['textoPequenyo', 'justificado'], margin: [ 0, 5, 0, 0],
          text: [
            {
              text: 'IV.- Que por motivos legales o de fuerza mayor se requiera la terminación del curso. En este caso, ',
            },
            {
              text: 'se reembolsará la parte proporcional del curso que no se haya podido impartir.', style: 'negrita'
            }
          ]
        },
        {
          style: ['textoPequenyo', 'justificado'], margin: [ 0, 5, 0, 0],
          text: [
            {
              text: 'V.- Que el estudiante no cumpla con las normas y procedimientos para un ambiente libre de uso, ' +
                'posesión y distribución de drogas y/o alcohol. En este caso, ',
            },
            {
              text: 'se  devengará, y se deberá abonar por el estudiante y el tercero suscribiente, el total del curso.', style: 'negrita'
            }
          ]
        },
        {
          style: ['textoPequenyo', 'justificado'], margin: [ 0, 5, 0, 0],
          text: [
            {
              text: 'VI.- Si PROGRESA cancela un curso antes de su comienzo, y no es ninguno de los supuestos  anteriores, ',
            },
            {
              text: 'reembolsará todo el dinero pagado por el estudiante y el tercero suscribiente.', style: 'negrita'
            }
          ]
        },
        {
          style: ['textoPequenyo', 'justificado'], margin: [ 0, 5, 0, 0],
          text: [
            {
              text: 'VII.- Si PROGRESA cancelara un curso luego de haber comenzado, y dentro de un período máximo de catorce (14)días ' +
                'de su comienzo siempre y cuando no se trate de ninguno de los supuestos anteriores, ',
            },
            {
              text: 'reembolsará todoel  dinero  pagado  hastaese  momento. ', style: 'negrita'
            },
            {
              text: ' Si dicha cancelación se produce con posterioridad a esoscatorce (14)días, y no se halladentro de ninguno de los ' +
                'supuestos  anteriores,',
            },
            {
              text: 'se reembolsará la parte proporcional delcurso que no se haya impartido.', style: 'negrita'
            }
          ]
        },
        {
          text: '-> Cancelación (o desistimiento) del contrato por el Estudiante y/o la persona suscribiente:',
          style: ['textoPequenyo', 'negrita', {decoration: 'underline'}], margin: [ 0, 10, 0, 0]
        },
        {
          style: ['textoPequenyo', 'justificado'], margin: [ 0, 5, 0, 0],
          text: [
            {
              text: 'I.- El estudiante y la persona suscribiente tendrán la oportunidad de ',
            },
            {
              text: 'darse de baja, sin penalidad', style: 'negrita'
            },
            {
              text: ', en un máximo de catorce (14) días después de haber suscrito el contrato. ',
            },
            {
              text: 'El solicitante tiene derecho a que se le reembolse todo el dinero que haya pagado.', style: 'negrita'
            }
          ]
        },
        {
          style: ['textoPequenyo', 'justificado'], margin: [ 0, 5, 0, 0],
          text: [
            {
              text: 'II.- Si el solicitante pide la cancelación luego de más de catorce (14) días de haber firmado el contrato y ' +
                'haber pagado la matrícula, ',
            },
            {
              text: 'pero siempre que esta cancelación sea antes de comenzar su enseñanza,', style: {decoration: 'underline'}
            },
            {
              text: ' el solicitante tiene derecho a que se le reembolse todo el dinero que haya pagado, excepto la matrícula abonada.\n',
              style: 'negrita'
            },
            {
              text: 'Como excepción a lo anterior y sólo para este punto, PROGRESA ofrece adicionalmente la posibilidad de que el ' +
                'estudiante y el tercero suscribiente '
            },
            {
              text: 'soliciten la devolución del importe abonado en concepto de matriculación,',
              style: [{decoration: 'underline'}, 'negrita']
            },
            {
              text: ' sólo en los casos que se detallan a continuación y mediante justificación oportuna: '
            }
          ]
        },
        {
          style: ['textoPequenyo', 'justificado'],
          margin: [ 0, 5, 0, 0],
          text: '- Enfermedad o accidente grave, siempre y cuando no suponga una  violación de la DECLARACIÓN RESPONSABLE ' +
            'prevista al final del documento “CLÁUSULAS Y CONDICIONES”.',
        },
        {
          style: ['textoPequenyo'],
          text: '- Deber inexcusable de carácter público.',
        },
        {
          style: ['textoPequenyo', 'justificado'],
          text: 'No haber superado los requisitos de acceso al primer curso del ciclo formativo de grado matriculado. PROGRESA ' +
            'confirmará la recepción de la documentación y revisará la veracidad de dichos justificantes. En dichos casos, PROGRESA ' +
            'devolverá el importe de la matrícula, excepto 50€ por curso en concepto de gastos de administración y gestión. ',
        },
        {
          style: ['textoPequenyo', 'justificado'], margin: [ 0, 5, 0, 0],
          text: [
            {
              text: 'III.- Si el solicitante pide la cancelación luego de más de catorce (14) días de haber firmado el contrato y ' +
                'haber pagado la matrícula,  ',
            },
            {
              text: 'pero una vez iniciada su enseñanza,', style: {decoration: 'underline'}
            },
            {
              text: ' y no existiese ninguna de las causas recogidas en el siguiente apartado IV,'
            },
            {
              text: ' se devengará,y se deberá abonar por el estudiante y el tercero suscribiente, el total del curso. ',
              style: ['negrita']
            }
          ]
        },
        {
          style: ['textoPequenyo', 'justificado'], margin: [ 0, 5, 0, 0],
          text: [
            {
              text: 'IV.- Si PROGRESA no cumple sustancialmente con sus obligaciones de enseñaza, salvo por motivos excepcionales ' +
                'y  razonados que se comunicarán al estudiante, otorgará al estudiante y el tercero suscribiente, ',
            },
            {
              text: 'el derecho a la rescisión del contrato y a que sea reembolsada la parte proporcional del curso que no se ' +
                'haya podido impartir.', style: 'negrita'
            }
          ]
        },
        {
          style: ['textoPequenyo', 'justificado'], margin: [ 0, 5, 0, 0],
          text: [
            {
              text: 'Hacer constar que en los anteriores casos en los cuales PROGRESA no devolverá cuantía alguna es a razón de o bien ' +
                'dado el incumplimiento de las condiciones por parte del estudiante y que han sido debidamente informadas y libre ' +
                'y voluntariamente aceptadas por las partes, o bien dado el estado de avance del curso al no ser entonces posible ' +
                'cubrir el puesto del estudiante sin causar con ello un perjuicio al que pudiese ser el nuevo estudiante. Ello ' +
                'también viene a tener su razón de ser en el perjuicio económico que se le causa a PROGRESA al no poder cubrir el ' +
                'puesto del estudiante para ese curso económico.',
            }
          ],
        },
        {
          columns: [
            {
              style: ['tituloTextoPequenyo'],
              margin: [ 0, 30, 0, 0],
              table: {
                widths: ['*'],
                heights: [60],
                body: [
                  [
                    {text: 'Firma del alumno:', border: [false, false, false, false]}
                  ],
                  [
                    {text: 'Nombre Apellidos:', border: [false, false, false, false]}
                  ]
                ]
              },
              pageBreak: 'after'
            },
            {
              style: ['tituloTextoPequenyo'],
              margin: [ 0, 30, 0, 0],
              table: {
                widths: ['*'],
                heights: [60],
                body: [
                  [
                    {text: 'Firma del tutor:', border: [false, false, false, false]}
                  ],
                  [
                    {text: 'Nombre Apellidos:', border: [false, false, false, false]}
                  ]
                ]
              }
            }
          ],
          columnGap: 10
        },
        {
          text: 'POLITICA DE PLAZOS IMPAGADOS ',
          style: [{fontSize: 9}, {alignment: 'center'}, 'negrita', {decoration: 'underline'}]
        },
        {
          style: ['textoPequenyo', 'justificado'], margin: [ 0, 5, 0, 0],
          text: [
            {
              text: 'La falta de pago en los plazos establecidos supone el incumplimiento de las obligaciones contraídas con la ' +
                'formalización de matrícula, y generará una deuda a favor de PROGRESA por la cantidad pendiente de pago del importe ' +
                'total del curso, cuyo abono será exigible para el acceso a los servicios ofrecidos porPROGRESA en el año en curso y ' +
                'en cursos posteriores como condición previa de matrícula.',
            }
          ]
        },
        {
          style: ['textoPequenyo', 'justificado'], margin: [ 0, 5, 0, 0],
          text: [
            {
              text: 'La Dirección del Centro notificará a los estudiantes que no se encuentren al corriente de pago su situación ' +
                'irregular, que deberán subsanar conforme a los plazos e instrucciones que reciban en dicha notificación. En caso de ' +
                'incumplimiento de las mismas, supondrá que se declare la pérdida de los derechos derivados de la matrícula y ' +
                'determinará la cuantía de la deuda contraída.',
            }
          ]
        },
        {
          style: ['textoPequenyo', 'justificado'], margin: [ 0, 5, 0, 0],
          text: [
            {
              text: 'Los/as estudiantes que hagan efectivo el pago de la deuda contraída con PROGRESA dentro del curso académico ' +
                'deberán acreditar dicho pago en el Centro donde reciban la enseñaza, el cual reactivará la matrícula a petición ' +
                'de la persona interesada. PROGRESA denegará la expedición de títulos y certificados cuando los/as estudiantes ' +
                'tuvieran pagos pendientes de satisfacer. ',
            }
          ]
        },
        {
          text: 'Asimismo, el estudiante y la persona que suscriba en su caso el contrato junto a él, aceptan que una situación de ' +
            'insolvencia posterior no supondrá causa para rescindir el contrato sin la obligación de abonar la totalidad del curso. ',
          style: ['textoPequenyo', 'negrita', {decoration: 'underline'}], margin: [ 0, 5, 0, 0]
        },
        {
          columns: [
            {
              style: ['tituloTextoPequenyo'],
              margin: [ 0, 40, 0, 0],
              table: {
                widths: ['*'],
                heights: [60],
                body: [
                  [
                    {text: 'Firma del alumno:', border: [false, false, false, false]}
                  ],
                  [
                    {text: 'Nombre Apellidos:', border: [false, false, false, false]}
                  ]
                ]
              }
            },
            {
              style: ['tituloTextoPequenyo'],
              margin: [ 0, 40, 0, 0],
              table: {
                widths: ['*'],
                heights: [60],
                body: [
                  [
                    {text: 'Firma del tutor:', border: [false, false, false, false]}
                  ],
                  [
                    {text: 'Nombre Apellidos:', border: [false, false, false, false]}
                  ]
                ]
              }
            }
          ],
          columnGap: 10
        },
      ],
      styles: {
        tableHeader1: {
          bold: true,
          alignment: 'center',
          fillColor: '#003300',
          color: 'white'
        },
        tableHead2: {
          bold: true,
          alignment: 'center',
          fillColor: '#ffffcc',

        },
        tableHeader2: {
          bold: true,
          alignment: 'center',
          fillColor: '#4AD3B9',
          fontSize: 9
        },

        tableHeader4: {
          bold: true,
          alignment: 'center',
          fillColor: '#4AD3B9',
          color: '#ffffff',
          fontSize: 10
        },

        tableHeader5: {
          bold: true,
          fillColor: '#4AD3B9',
          color: '#ffffff',
          fontSize: 10
        },

        tableHeader21: {
          bold: true,

          fillColor: '#ffffcc',
          fontSize: 6
        },
        tableHeader3: {
          bold: true,
          alignment: 'center',
        },
        textoPequenyo: {
          fontSize: 8
        },
        centrado: {
          alignment: 'center',
        },
        justificado: {
          alignment: 'justify',
        },
        tituloTextoPequenyo: {
          fontSize: 10
        },
        negrita: {
          bold: true
        }
      }
    };
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public handleImage(webcamImage: WebcamImage): void {
    console.log('received webcam image', webcamImage);
    this.webcamImage = webcamImage;

  }

  subirfoto() {
    const blobData = this.b64toBlob(this.webcamImage.imageAsBase64, `image/jpeg`);
    console.log(blobData);
    this._upload.makeFileRequestFoto(this.id, blobData, 'imagen', 'user').then(
      (result: any) => {
        console.log(result);
        this._user.getDato(this.id);
      },
      error => {
        console.log(error);
      }
    );
  }

  b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  openFoto(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', windowClass: 'modalFoto'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      console.log(result);

      this.subirfoto();

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  otra() {
    this.webcamImage = null;
  }

  public handleInitError(error: WebcamInitError): void {
    if (error.mediaStreamError && error.mediaStreamError.name === 'NotAllowedError') {
      this.modalService.dismissAll();
      this.mostrarAlert();
    }
  }

  mostrarAlert() {
    Swal.fire('', 'Debe dar persmisos de camara', 'warning');

  }

  documentos() {
    console.log(this.documents.length);
    for (let i = 0; i <= (this.documents.length); i++) {
      console.log(i);
      if (i % 2 !== 0 && i !== 0) {
        if (((i - 1) % 2) === 0 && (i) === this.documents.length) {
          this.docList.push([this.comprobarCheck(this.documents[i - 1].entregado),
            {text: this.documents[i - 1].tipo.tipo, style: ['tableHeader5']},
            {text: ''},
            {text: ''}]);
        } else {
          this.docList.push([this.comprobarCheck(this.documents[i - 1].entregado),
            {text: this.documents[i - 1].tipo.tipo, style: ['tableHeader5']},
            this.comprobarCheck(this.documents[i].entregado),
            {text: this.documents[i].tipo.tipo, style: ['tableHeader5']}]);
        }
      }
    }
    console.log(this.docList);
  }

  comprobarCheck(check) {
    console.log(check);
    if (check) {
      return {text: 'X', alignment: 'center'};
    } else {
      return {text: ''};
    }
  }

//   [
//     this.seleccionado('FoCerNoCom'),
// {text: 'Fotocopia certificado de notas compulsada'},
// {text: '', border: [false, false, false, false], style: ['tableHeader2']},
// this.seleccionado('InSaEs'),
//   {text: 'Informe salud escolar'}
// ],


  cambiarAula(clase) {
    console.log(clase);
    this.idAula = clase;
  }


  async generatePdfAbastos(action = 'download') {
    const documentDefinition = await this.getDocumentDefinitionAbastos();
    switch (action) {
      case 'open':
        pdfMake.createPdf(documentDefinition).open();
        break;
      case 'print':
        pdfMake.createPdf(documentDefinition).print();
        break;
      case 'download':
        pdfMake.createPdf(documentDefinition).download('Abastos-' + this.dato.nombre + ' ' + this.dato.apellidos + '.pdf');
        break;
      default:
        pdfMake.createPdf(documentDefinition).open();
        break;
    }
  }

  async getDocumentDefinitionAbastos() {
    sessionStorage.setItem('resume', JSON.stringify(this.dato));
    return {
      content: [
        {
          // 1. CABECERA
          columns: [
            // await this.imagenPdf('https://apiescuela.cieep.com/images/centro/logo_con_datos.png', 220, 'left', 0),
            await this.imagenPdf(RutasImg + 'images/centro/logo_con_datos.png', 220, 'left', 0),
            {
              table: {
                widths: ['*'],
                body: [
                  [
                    {text: 'MATRICULA', bold: true, fontSize: 20, alignment: 'center', border: [false, false, false, false]}
                  ],
                  [
                    {text: 'CURSO: ' + this.cursoDesde + '/' + this.cursoHasta, border: [false, false, false, false]}
                  ],
                  [
                    // tslint:disable-next-line:max-line-length
                    {
                      text: 'FECHA DE MATRICULA: ' + this.datePipe.transform(this.fechaActual, 'dd-MM-YYYY'),
                      border: [false, false, false, false]
                    }
                    // {text: 'FECHA DE MATRICULA: ' + '', border: [false, false, false, false]}
                  ]
                ]
              }
            },
            await this.imagenPdf(RutasImg + this.dato.img, 70, 'right', 0)
          ],
          columnGap: 10
        },
        {
          text: ' '
        },
        {
          table: {
            widths: [80, '*'],
            body: [
              [
                {text: this.dato.curso.id === 1 ? 'CFGM' : 'CFGS', style: 'tableHead2'},
                {text: this.dato.curso.nivel}
              ],

            ],
          }
        },
        {
          // Salto de linea
          text: ' '
        },
        // 2. DATOS PERSONALES DEL ALUMNO
        {
          table: {
            widths: ['*', '*', '*', '*', '*', '*'],
            body: [
              [{text: 'DATOS PERSONALES DEL ALUMNO', style: 'tableHeader1', colSpan: 6}, {}, {}, {}, {}, {}],
              [

                {width: 200, text: 'Apellidos, Nombre', style: 'tableHead2'},
                {text: this.dato.apellidos + ', ' + this.dato.nombre, colSpan: 5}

              ],
              [
                {text: 'DNI/Pasaporte', style: 'tableHead2'},
                {text: this.dato.dni},
                {text: 'Fecha nacimiento', style: 'tableHead2'},
                {text: this.datePipe.transform(this.dato.nacimiento, 'dd-MM-YYYY')},
                {text: 'Provincia', style: 'tableHead2'},
                {text: this.dato.provincia}
              ],
            ]
          }
        },
        this.getTitulo(),
        {
          // Salto de linea
          text: ' ',
        },
        // 4. DOCUMENTACIÓN APORTADA (A RELLENAR POR EL CENTRO)
        {
          table: {
            widths: [10, 'auto', 'auto', 10, '*'],
            body: [
              [
                {text: 'DOCUMENTACIÓN APORTADA (A RELLENAR POR EL CENTRO)', style: 'tableHeader1', colSpan: 5}, {}, {}, {}, {}
              ],
              ...this.docList.map(a => {
                return a;
              }),

            ]
          }
        },

        {
          // Salto de linea
          text: ' '
        },
        // OBSERVACIONES
        {
          table: {
            widths: ['*'],
            heights: ['*', 30],
            body: [
              [
                {text: 'OBSERVACIONES', style: 'tableHeader1'}
              ],
              [
                {text: this.observaciones, alignment: 'justify'}
              ]
            ],
          }
        },
        {
          table: {
            widths: ['*'],
            heights: [150],
            body: [
              [
                {text: '', border: [false, false, false, false]}
              ],
            ],


          }, layout: 'noBorders'
        },
        // SALTO DE LINEA
        {
          text: ' ',
        },
        // 8. FIRMA
        {
          style: ['textoPequenyo', 'justificado'],
          text: [
            {
              text: 'Recibida la información detallada de las características del curso, contenido y demás cuestiones relacionadas, ' +
                'el alumno/titular acepta las condiciones generales y académicas amparadas en este contrato y ', margin: [0, 0, 0, 20]
            },
            {
              text: 'SOLICITA LA MATRÍCULA PARA EL CURSO', bold: true
            }
          ],
        },
        {
          // Salto de linea
          text: ' '
        },
        {
          style: ['textoPequenyo', 'justificado'],
          text: 'Valencia a ' + this.datePipe.transform(this.fechaActual, 'dd-MM-YYYY')
        },
        {
          // Salto de linea
          text: ' '
        },
        {
          columns: [
            {
              style: ['tituloTextoPequenyo'],
              table: {
                widths: ['*'],
                heights: [70],
                body: [
                  [
                    {text: 'Firma del alumno:'}
                  ]
                ]
              }
            },
            {
              style: ['tituloTextoPequenyo'],
              table: {
                widths: ['*'],
                heights: [70],
                body: [
                  [
                    {text: 'Firma y sello del centro:'}
                  ]
                ]
              }
            }
          ],
          columnGap: 10,
        },
      ],
      styles: {
        tableHeader1: {
          bold: true,
          alignment: 'center',
          fillColor: '#003300',
          color: 'white'
        },
        tableHead2: {
          bold: true,
          alignment: 'center',
          fillColor: '#ffffcc',
          fontSize: 10

        },
        tableHeader2: {
          bold: true,
          alignment: 'center',
          fillColor: '#ffffcc',
          fontSize: 9
        },
        tableHeader3: {
          bold: true,
          alignment: 'center',
        },
        textoPequenyo: {
          fontSize: 8
        },
        centrado: {
          alignment: 'center',
        },
        justificado: {
          alignment: 'justify',
        },
        tituloTextoPequenyo: {
          fontSize: 10
        },
        negrita: {
          bold: true
        }
      }
    };
  }

  getTitulo() {
    if (this.dato.titulos.length > 0) {
      switch (this.dato.titulos[0].titulo) {
        case 'ESO':
          return {
            table: {
              widths: ['auto', 10, '*', 10, '*', 10, '*'],
              body: [
                [
                  {text: 'Requisito de acceso:', style: 'tableHeader4', border: [true, true, false, false]},
                  {text: '', border: [false, true, false, false]},
                  {text: 'Título de Bachiller',  style: 'tableHeader4', border: [false, true, false, false]},
                  {text: '', border: [false, true, false, false]},
                  {text: 'Prueba de acceso',  style: 'tableHeader4', border: [false, true, false, false]},
                  {text: 'X', border: [false, true, false, false]},
                  {text: 'ESO', style: 'tableHeader4', border: [false, true, true, false]}
                ],
                [
                  {text: 'Otros:', style: 'tableHeader4', border: [true, true, false, false]},
                  {text: '', colSpan: 6, border: [false, true, true, false]},
                  {},
                  {},
                  {},
                  {},
                  {}
                ]
              ]
            }
          };
          break;

        case 'TITULO DE BACHILLER':
          return {
            table: {
              widths: ['auto', 10, '*', 10, '*', 10, '*'],
              body: [
                [
                  {text: 'Requisito de acceso:', style: 'tableHeader4', border: [true, true, false, false]},
                  {text: 'X', border: [false, true, false, false]},
                  {text: 'Título de Bachiller',  style: 'tableHeader4', border: [false, true, false, false]},
                  {text: '', border: [false, true, false, false]},
                  {text: 'Prueba de acceso',  style: 'tableHeader4', border: [false, true, false, false]},
                  {text: '', border: [false, true, false, false]},
                  {text: 'ESO', style: 'tableHeader4', border: [false, true, true, false]}
                ],
                [
                  {text: 'Otros:', style: 'tableHeader4', border: [true, true, false, false]},
                  {text: '', colSpan: 6, border: [false, true, true, false]},
                  {},
                  {},
                  {},
                  {},
                  {}
                ]
              ]
            }
          };
          break;

        case 'PRUEBA DE ACCESO':
          return {
            table: {
              widths: ['auto', 10, '*', 10, '*', 10, '*'],
              body: [
                [
                  {text: 'Requisito de acceso:', style: 'tableHeader4', border: [true, true, false, false]},
                  {text: '', border: [false, true, false, false]},
                  {text: 'Título de Bachiller',  style: 'tableHeader4', border: [false, true, false, false]},
                  {text: 'X', border: [false, true, false, false]},
                  {text: 'Prueba de acceso',  style: 'tableHeader4', border: [false, true, false, false]},
                  {text: '', border: [false, true, false, false]},
                  {text: 'ESO', style: 'tableHeader4', border: [false, true, true, false]}
                ],
                [
                  {text: 'Otros:', style: 'tableHeader4', border: [true, true, false, false]},
                  {text: '', colSpan: 6, border: [false, true, true, false]},
                  {},
                  {},
                  {},
                  {},
                  {}
                ]
              ]
            }
          };
        default:
          return {
            table: {
              widths: ['auto', 10, '*', 10, '*', 10, '*'],
              body: [
                [
                  {text: 'Requisito de acceso:', style: 'tableHeader4', border: [true, true, false, false]},
                  {text: '', border: [false, true, false, false]},
                  {text: 'Título de Bachiller',  style: 'tableHeader4', border: [false, true, false, false]},
                  {text: '', border: [false, true, false, false]},
                  {text: 'Prueba de acceso',  style: 'tableHeader4', border: [false, true, false, false]},
                  {text: '', border: [false, true, false, false]},
                  {text: 'ESO', style: 'tableHeader4', border: [false, true, true, false]}
                ],
                [
                  {text: 'Otros:', style: 'tableHeader4', border: [true, true, false, false]},
                  {text: this.dato.titulos[0].titulo, colSpan: 6, border: [false, true, true, false]},
                  {},
                  {},
                  {},
                  {},
                  {}
                ]
              ]
            }
          };
      }
    } else {
      return '';
    }
  }

  savePromocion() {
    console.log('SAVE PROMOCION');
    if (this.promocion.p_id === null) {
      const data = {
        curso: this.promocion.id,
        user: this.id
      };
      this._user.addPromocion(data).subscribe((res) => {
        this._user.getDato(this.id);
      });
    } else {
      const data = {
        curso: this.promocion.id,
        id: this.promocion.p_id,
      };
      this._user.updatePromocion(data).subscribe((res) => {
        this._user.getDato(this.id);
      });
    }
    Swal.fire({
      title: '',
      text: '¿Añadir a lista de contactos comerciales?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.comercialService.setPromo(this.dato);
        this._router.navigateByUrl('/admin/comercial');
      }
    });


  }

  removePromocion() {
    console.log(this.promocion);
    if (this.oldPromocionar === true && this.promocion !== null && this.promocionar === false) {
      console.log('BORRAR PROMOCION?')
      Swal.fire({
        title: '',
        text: 'Se eliminará la promoción, ¿está segur@?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this._user.removerPromocion(this.promocion.p_id).subscribe(res => {
            console.log(res);
            this.promocion = {
              acronimo: '',
              id: null,
              anyo_curso: null,
              aula: '',
              curso: null,
              modulo: null,
              p_id: null,
            }
            this.ac = true;
          });

        } else {
          this.promocionar = true;
        }
      });
    }
  }

  modificar() {
    this.ac = true;
  }

  getAulasP() {
    this._user.verificarAula(this.promocion.modulo, this.id).subscribe(data => {
      console.log(data);
      if (data['id'].lista_aulas.length > 0) {

        this.aulasP = data['id'].lista_aulas;

      }
    });
  }

  promocionarCurso() {
    if (this.promocion.id) {
      this._user.removerPromocion(this.promocion.p_id).subscribe(res => {
        console.log(res);
        const datos = {
          idUser: this.id,
          idAula: this.promocion.id,
          idModulo: this.promocion.modulo,
        };
        this._user.updateAula(datos).subscribe((resp) => {
          if (resp['code'] === 200) {
            Swal.fire('', 'Alumno promocionado con exito', 'success');

            this._user.getDato(this.id);

            this.idAula = null;
          }
        }, error => {
          console.log(error);
        });


      });
    }
  }

}
