import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AlquilerService} from '../services/alquiler.service';
import Swal from 'sweetalert2';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-crear-alquiler',
  templateUrl: './crear-contacto.component.html',
  styleUrls: ['./crear-contacto.component.scss']
})
export class CrearContactoComponent implements OnInit {
  datosAlquiler: FormGroup;
  estados: any;
  referidos: any;
  formaContacto: any;

  constructor(public dialogRef: MatDialogRef<CrearContactoComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, private serviceAlquiler: AlquilerService, private fb: FormBuilder) {
    this.getSelects();
    this.crearFormulario();
  }

  ngOnInit(): void {
  }

  crearFormulario() {
    this.datosAlquiler = this.fb.group({
      contactoNombre: ['', Validators.required],
      contactoApellidos: ['', Validators.required],
      empresa: [''],
      cifNif: [''],
      email: [''],
      estado: [''],
      telefono: ['', Validators.required],
      web: [''],
      formaContacto: ['', Validators.required],
      observaciones: [''],
      referido: ['', Validators.required],
      comentarios: [''],
    });
  }

  getSelects() {
    this.serviceAlquiler.getSelects().subscribe( (res) => {
        console.log(res);
        this.estados = res['estados'];
        this.referidos = res['referidos'];
        this.formaContacto = res['forma_contacto'];
      }, (error) => {
        console.log(error);
      }
    );
  }

  addReferido() {
    (async () => {
      const { value: referido } = await Swal.fire({
        title: 'Añadir Referido',
        input: 'text',
        allowOutsideClick: false,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Guardar',
        reverseButtons: true,
      })
      if (referido) {
        this.serviceAlquiler.addReferido(referido).subscribe((res) => {
          console.log(res);
          Swal.fire('', 'Se ha guardado correctamente', 'success');
          this.referidos = res;
        }, (error) => {
          console.log(error)
        });
      }
    })()
  }

  addFormaContacto() {
    (async () => {
      const { value: formaContacto } = await Swal.fire({
        title: 'Añadir Forma de Contacto',
        input: 'text',
        allowOutsideClick: false,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Guardar',
        reverseButtons: true,
      })
      if (formaContacto ) {
        this.serviceAlquiler.addMedio(formaContacto).subscribe((res) => {
          console.log(res);
            Swal.fire('', 'Se ha guardado correctamente', 'success');
           this.formaContacto = res;
        }, (error) => {
          console.log(error)
        });
      }
    })()
  }

  cancelar() {
    this.dialogRef.close();
  }

  guardar() {

    console.log(this.datosAlquiler.value);
    this.dialogRef.close(this.datosAlquiler.value);
  }
}
