<div class="col-xs-12 col-md-12 row">
  <div class="form-group col-md-6 col-xs-12">
    <div class="input-group mb-2">
      <div class="input-group-prepend ">
        <span class="input-group-text bg-dark text-light border-dark"
              id="basic-addon3">Seleccione fecha de inicio: </span>
      </div>
      <input type="date" class="form-control" [(ngModel)]="inicio" aria-describedby="basic-addon3"
             (change)="getentreFechasInicio(inicio, fin)">
    </div>
  </div>
  <div class="form-group col-md-6 col-xs-12">
    <div class="input-group mb-2">
      <div class="input-group-prepend ">
                <span class="input-group-text bg-dark text-light border-dark"
                      id="basic-addon2">Seleccione fecha de fin: </span>
      </div>
      <input type="date" class="form-control" [(ngModel)]="fin"
             aria-describedby="basic-addon2" (change)="getentreFechas(inicio, fin)">
    </div>
  </div>
  <div class="float-right ml-auto">
    <button type="button" id="btn" class="btn btn-outline-danger mb-0" *ngIf="role === 'ROLE_ADMIN'" (click)="generarfichaje()" [hidden]="elemento">
      <i class="fa fa-exclamation-triangle"></i>
    </button>
  </div>
</div>
<div class="mat-elevation-z8">
</div>

<div class="card mb-3">
  <div class="card-header" *ngIf="user">
    <i class="fa fa-user-clock"></i>
    <span class="float-left" id="usr">Seguimiento {{user.nombre}} {{user.apellidos}}</span>
    <span class="float-right" *ngIf="role === 'ROLE_ADMIN' && arreglados.length > 0">
      <button class="btn-outline-dark" type="button" [ngClass]="editButton()"
              (click)="changeEditarMode()" title="Editar horas"> <i class="fa fa-edit"></i>
      </button>
    </span>
  </div>
  <div class="card-body">

    <table class="table table-striped" width="100%" cellspacing="0" data-page-length="10"
           data-paging="true" *ngIf="editarMode">
      <tbody>
      <td>
        <input type="date" class="form-control fechaAdd" [(ngModel)]="fechaAdd" aria-describedby="basic-addon3">
      </td>
      <td>
        <ngb-timepicker name="entrada" [(ngModel)]="timeEntradaAdd"></ngb-timepicker>
      </td>
      <td>
        <ngb-timepicker name="salida" [(ngModel)]="timeSalidaAdd"></ngb-timepicker>
      </td>
      <td>
        <a class="btn btn-outline-dark m-0 btnAnyadir" title="Añadir fichaje" (click)="addFichaje()">
          <i class="fa fa-plus  text-primary btnAnyadirIcon"></i>
        </a>
      </td>
      </tbody>
    </table>
    <table class="table table-striped" id="dataTable" width="100%" cellspacing="0" data-page-length="10"
           data-paging="true" *ngIf="arreglados.length > 0">
      <thead class="thead-dark">
      <tr>
        <th>Día</th>
        <th>Hora Entrada</th>
        <th>Hora Salida</th>
        <th>Horas</th>
      </tr>
      </thead>
      <tbody>

      <tr *ngFor="let data of arreglados | paginate: { itemsPerPage: 10, currentPage: actualPage }; let i = index">
        <td>{{data.dia | date: 'EEEE dd/MM/yyyy' | titlecase }}</td>
        <td >
          <div *ngIf="data.entrada != 0">
            {{data.entrada | date: 'HH:mm' }}
            <span class="float-right" *ngIf="editarMode">
            <button class="btn btn-outline-dark m-0" (click)="show(data)" title="Editar hora">
              <i class="fa fa-edit"></i>
            </button>
          </span>
            <div *ngIf="mostrar.has(data) && editarMode" class="alto">
              <ngb-timepicker class="ngb-timepickerTable" name="entrada"
                              [(ngModel)]="data.timeEntrada"></ngb-timepicker>
              <a class="btn btn-primary saveButtonSeguimientoEmpleadoEdit" role="button"
                 title="Guardar cambios" (click)="actualizarFichaje(i, 'ENTRADA')">
                <i class="fa fa-save saveSeguimiento"></i>
              </a>
            </div>
          </div>

        </td>
        <td>
          <div *ngIf="data.salida != 0" >
            {{data.salida | date: 'HH:mm' }}
            <span class="float-right" *ngIf="editarMode">
              <button class="btn btn-outline-dark m-0" (click)="show(data)"
                      role="button" title="Editar hora"> <i class="fa fa-edit"></i>
              </button>
            </span>
            <div *ngIf="mostrar.has(data) && editarMode" class="alto">
              <ngb-timepicker class="ngb-timepickerTable" name="salida" [(ngModel)]="data.timeSalida"></ngb-timepicker>
              <a class="btn btn-primary saveButtonSeguimientoEmpleadoEdit" role="button" title="Guardar cambios"
                 (click)="actualizarFichaje(i, 'SALIDA')">
                <i class="fa fa-save saveSeguimiento"></i>
              </a>
            </div>
          </div>
        </td>
        <td>
          {{data.horas | minutos}}
          <span class="float-right" *ngIf="editarMode">
            <a class="btn btn-danger m-0" title="Eliminar fichaje"
               (click)="eliminarFichaje(data.id_entrada, data.id_salida, i)">
              <i class="fa fa-trash editSeguimiento "></i>
            </a>
          </span>
        </td>
      </tr>
      </tbody>
      <tfoot>
      <td colspan="3">
        <strong>Total Horas</strong>
      </td>
      <td>
        <strong>
          {{getTotalCost() | minutos}}
        </strong>
      </td>
      </tfoot>
    </table>
    <pagination-controls (pageChange)="actualPage = $event" *ngIf="arreglados.length > 0"></pagination-controls>
  </div>
  <div *ngIf="inicio && fin" class="card-footer text-muted flex py-2 px-3">
    <ng-container>
      <p class="small text-muted m-0 py-2">
        Entre {{inicio | date:'EEEE dd MMMM yyyy' | titlecase}} y {{fin | date:'EEEE dd MMMM yyyy' | titlecase}}
      </p>
    </ng-container>
    <div class="float-right ml-auto">
      <button type="button" class="btn btn-outline-danger mb-0" *ngIf="role === 'ROLE_ADMIN' && arreglados.length > 0" (click)="generatePdf()">
        <i class="fa fa-file-pdf-o"></i>
      </button>
    </div>
  </div>
  <img id='imgToExport' [src]='this.logo' style='display:none'/>

</div>
