import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {AdminAlumnoService} from '../services/admin-alumno.service';
import {AlumnosService} from '../../../maestro/admin-alumnos/services/alumnos.service';
import {DatePipe} from '@angular/common';
import {LoginService} from '../../../pages/services/login.service';
import {AsistenciasService} from '../../../maestro/admin-asistencias/services/asistencias.service';


@Component({
  selector: 'app-alumnos-asistencias',
  templateUrl: './alumno-asistencias.component.html',
  styleUrls: ['../alumno-asignaturas.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class AlumnoAsistenciasComponent implements OnInit, OnDestroy {

  id: number;
  fecha: Date;
  asistencias: any[] = [];
  asistenciasSubscription: Subscription = null;
  faltas = 0;
  datos = '';
  evento = false;

  // Fechas
  fechas = new Date;

  // Para saber el mes actual
  mes = this.fechas.getMonth();
  // Para saber el año actual
  anyo = this.fechas.getFullYear();

  // Esto cogera el dia 1 del mes y año actual
  dateFrom = new Date(this.anyo, this.mes, 1);
  // Esto cogera el ultimo dia del mes y año actual
  dateTo = new Date(this.anyo, this.mes + 1, 0);

  // Asignaturas
  asignatura: number;
  asignaturaSeleccionada: string;

  public asignaturas: Array<any> = [];
  public asignaturasSubscription: Subscription = null;

  constructor(private _alumno: AlumnosService,
              private _route: ActivatedRoute,
              private _date: DatePipe,
              private _asistencia: AsistenciasService,
              private _login: LoginService,
              private _admin: AdminAlumnoService) {
  }

  ngOnInit() {
    this.id = this._login.getIdentity().id;

    this.getAsistencias();

    this.asignaturaID();
  }

  getAsistencias() {
    this._alumno.getAsistenciasYfaltas(this.id, this.evento);
    this.asistenciasSubscription = this._alumno.asistenciasYfaltas$.subscribe(res => {
      this.asistencias = res;
      if (this.asistencias.length > 0) {
        this.datos = this.asistencias[0].nombre + ' ' + this.asistencias[0].apellidos;
        this.asistencias.forEach(item => {
          if (!item.estado) {
            this.faltas++;
          }
        });
      }
    });
  }

  listado(evento) {
    this._alumno.getAsistenciasYfaltas(this.id, evento.target.value);
  }

  // Para saber el ID de una asignatura
  asignaturaID() {
    this.asignatura = this._route.snapshot.parent.params['asignatura'];
    this._admin.getAsignaturas();

    this.asignaturasSubscription = this._admin.datos$.subscribe(res => {
      this.asignaturas = res;
      for (const asig of this.asignaturas) {
        if (asig.id == this.asignatura) {
          this.asignaturaSeleccionada = asig.asignatura;
        }
      }
    });
  }

  // Para guardar un comentario
  saveComentario(comentario, id, i) {
    if (comentario) {
      this.asistencias[i].comentario = comentario;
      this._asistencia.addComentario(comentario, id);
      return;
    }
  }

  // Para eliminarun comentario
  deleteComentario(index) {
    this.asistencias[index].comentario = '';
    this._asistencia.deleteComentario(this.asistencias[index].id);
  }

  // Para saber si es falta o asistencia
  change(position: number) {
    const estado = !this.asistencias[position].estado;
    this.asistencias[position].estado = !this.asistencias[position].estado;
    const id = this.asistencias[position].id;
    this._asistencia.update(estado, id);
  }

  ngOnDestroy(): void {
    this.asistenciasSubscription.unsubscribe();
    this.asignaturasSubscription.unsubscribe();
  }
}

