import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Router} from '@angular/router';
import {Rutas} from '../../../../utiles/Rutas';
import {LoginService} from '../../../pages/services/login.service';

@Injectable()
export class AsistenciasService {

  constructor (private http: HttpClient, private _login: LoginService) {
  }

  private datosSource = new Subject<any>();
  public datos$ = this.datosSource.asObservable();

  private aulaSource = new Subject<any>();
  public aula$ = this.aulaSource.asObservable();

  private checkSource = new Subject<boolean>();
  public check$ = this.checkSource.asObservable();


  update (estado: boolean, id: number) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const datos = {
      id: id,
      estado: estado
    };
    const json = {datos};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'update/asistencia', params, {headers: headers}).subscribe(data => {
      if (data['code'] === 200) {
        // this.datosSource.next(data['id']);
      }
    }, error => {
      console.log(error);
    });
  }

  checkAsistenciaDia (id: number) {
    this.http.get(Rutas + 'check/asistencias?id=' + id + '&token=' + localStorage.getItem('token'))
      .subscribe(data => {
        if (data['code'] === 200) {
          if (data['id'] === 'true') {
            this.checkSource.next(true);
          } else {
            this.checkSource.next(false);
          }

        }
      }, err => {
        console.log(err);
      });
  }


  addComentario (comentario, id) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const datos = {
      id: id,
      comentario: comentario
    };
    const json = {datos};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'add/comentario', params, {headers: headers}).subscribe(resp => {
      if (resp['code'] === 200) {
        // this.datosSource.next(data['id']);
      }
    }, error => {
      console.log(error);
    });
  }

  deleteComentario (id) {
    this.http.get(Rutas + 'delete/comentario?id=' + id + '&token=' + localStorage.getItem('token'))
      .subscribe(data => {
      }, err => console.log(err));
  }


  addAsistencias (usuarios: any, id: number) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const datos = {
      id: id,
      usuarios: usuarios,
      dia: new Date()
    };

    const json = {datos};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'add/asistencias', params, {headers: headers}).subscribe(data => {
      if (data['code'] === 200) {
        // this.datosSource.next(data['id']);
      }
    }, error => {
      console.log(error);
    });
  }

  claseActual (curso: number, asignatura: number) {
    const dia = 'lunes';
    this.http.get(Rutas + 'clase/actual?id=' + this._login.getIdentity().id + '&dia=' + dia + '&curso=' + curso + ' &asignatura=' + asignatura + '&token=' + localStorage.getItem('token'))
      .subscribe(data => {
        if (data['code'] === 200) {
          this.aulaSource.next(data['id']);
        }
      }, err => {
        console.log(err);
      });


  }

  getAlumnos (curso: number, asignatura: number) {
    const dias = ['lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado', 'domingo'];
    const f = new Date();
    const dia = dias[f.getUTCDay() - 1];
    this.http.get(Rutas + 'get/alumnos/clase?id=' + this._login.getIdentity().id + '&dia=' + dia + '&curso=' + curso + ' &asignatura=' + asignatura + '&token=' + localStorage.getItem('token'))
      .subscribe(data => {
        if (data['code'] === 200) {
          this.datosSource.next(data['id']);
        }
      }, err => {
        console.log(err);
      });
  }

  add (nota: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {nota};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'add/nota', params, {headers: headers}).subscribe(data => {
      if (data['code'] === 200) {
        this.checkSource.next(true);
      }
    }, error => {
      console.log(error);
    });
  }

  updateN (nota: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {nota};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'update/nota', params, {headers: headers}).subscribe(data => {
      if (data['code'] === 200) {
        this.datosSource.next(data['id']);
      }
    }, error => {
      console.log(error);
    });
  }

  sendPush () {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = { id: 2};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'send/push', params, {headers: headers}).subscribe(data => {
      if (data['code'] === 200) {
       console.log(data['id']);
      }
    }, error => {
      console.log(error);
    });
  }


}
