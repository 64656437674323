import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminComponent implements OnInit {
  public clase: Array<any> = [];
  public claseSubscription: Subscription = null;
  public id: number;
  public type: string;
  navLinks = [{}];

  constructor(private route: ActivatedRoute, private router: Router) {
    this.navLinks = [
      {path: 'datos', label: 'Datos'},
      {path: 'task', label: 'Tareas'},
      {path: 'examenes', label: 'Notas examenes'},
      {path: 'examen', label: 'Examen'}
    ];
  }

  ngOnInit() {
  }
}
