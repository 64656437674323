import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ErrorPageComponent} from './error/error-page.component';
import {LoginPageComponent} from './login/login-page.component';
import {ForgotPasswordPageComponent} from './forgot-password/forgot-password-page.component';
import {AppComponent} from '../../app.component';
import {CreatePasswordComponent} from './forgot-password/create-password.component';


const routes: Routes = [
  {
    path: 'public',
    component: AppComponent,
    children: [
      {
        path: 'error',
        component: ErrorPageComponent,
        data: {
          title: 'Error Page'
        }
      },
      {
        path: 'login/',
        component: LoginPageComponent,
        data: {
          title: 'Login Page'
        }
      },
      {
        path: 'forgot',
        component: ForgotPasswordPageComponent,
        data: {
          title: 'Forgot password'
        }
      },
      {
        path: 'password',
        component: CreatePasswordComponent,
        data: {
          title: 'Create password'
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {
}
