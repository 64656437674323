 <h2 mat-dialog-title class="text-center">Nuevo anuncio</h2>
<mat-dialog-content class="text-center" >
  <div [formGroup]="mensajeForm"   style="padding: 24px; ">
    <div class="row">
      <div class="col-6">
        <div class="flex-wrap margin">
          <div class="flex-wrap">
            <div class="margin">
              Imagen de Cabecera
              <div class="flex margin"  style="text-align: justify">
                <input [disabled]="imagenCabecera" type="file" id="fileInput" name="fileInput" accept="image/*"
                       (change)="selectFiles($event)"/>
                <button  type="button" *ngIf="imagenCabecera" (click)="imagenCabecera = undefined; imageSrc = undefined">borrar</button>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="col-6">
        <img src="{{imageSrc}}" *ngIf="imageSrc" width="200px">
      </div>
      <div class="col-12 ">
        <label class="form-label">Título</label>
        <input type="text" class="form-control" formControlName="titulo" >
      </div>

    </div>
    <div class="row">
      <div class="col-6">
        <label class="form-check-label">Destinatarios</label>
        <div class="row">
          <div class="col-12">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="inlineCheckbox1" formControlName="todos"
                     [value]="destino.todos" (change)="print()">
              <label class="form-check-label" for="inlineCheckbox1">Todos</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="inlineCheckbox2" formControlName="alumnos"
                     [value]="destino.alumnos" (change)="print()">
              <label class="form-check-label" for="inlineCheckbox2">Alumnos</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="inlineCheckbox3" formControlName="padres"
                     [value]="destino.padres" (change)="print()">
              <label class="form-check-label" for="inlineCheckbox3">Padres</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="inlineCheckbox4" formControlName="profesores"
                     [value]="destino.profesores" (change)="print()">
              <label class="form-check-label" for="inlineCheckbox4">Profesores  |</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="inlineCheckbox5" formControlName="aulas"
                     [value]="destino.aulas" (change)="print()">
              <label class="form-check-label" for="inlineCheckbox5">Aulas</label>
            </div>

          </div>
        </div>

      </div>
      <div class="col-6">
        <div class="flex-wrap margin">
          <div class="flex-wrap">
            <div class="margin">
              Adjuntar archivos
              <div class="flex margin" style="text-align: justify">
                <input ngfSelect type= "file" (change) = "file($event)"/>
                <button  type="button" *ngIf="filesToUpload.length > 0" (click)="filesToUpload = []">borrar</button>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>

    <div class="row" *ngIf="showAulas">
      <div class="col-2">
        <label class="form-label">Aula</label>
        <select class="custom-select form-control"  formControlName="curso" >
          <option selected value="">Selecciona</option>

          <option *ngFor="let curso of cursosFiltered" [ngValue]="curso" >{{curso.anyo_curso}} {{curso.acronimo}} {{curso.aula}} - {{curso.curso}}</option>
        </select>
      </div>

      <div class="col-2">
        <label class="form-label">Destinatarios</label>
        <select class="custom-select form-control"  formControlName="cursoDestinatarios" >
          <option selected value="">Selecciona</option>
          <option  [ngValue]="false">Alumnos</option>
          <option  [ngValue]="true">Alumnos y padres</option>
        </select>
      </div>
      <div class="col-2 botones">
        <button   class="aceptar btn btn-raised btn-primary m"  (click)="addCurso()" >
          Añadir
        </button>

      </div>
      <div class="col-6">
        <ul>
          <li *ngFor="let dest of cursosDest">
            <label>{{dest.aula}} - <label *ngIf="dest.destinatarios">Alumnos y padres</label>  <label *ngIf="!dest.destinatarios">Alumnos</label> </label>
          </li>
        </ul>
      </div>
    </div>



    <div class="row">
      <div class="col-12">
        <label class="form-label">Anuncio</label>
        <angular-editor formControlName="mensaje" [config]="config"></angular-editor>
      </div>

    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions  >
  <div class="row">
    <div class="col-1"></div>
    <div class="col-10">
      <button mat-button matDialogClose  class="aceptar float-left" >Cancelar</button>
      <button mat-button class="aceptar" (click)="send()"  [disabled]="!mensajeForm.valid">Enviar</button>
    </div>
    <div class="col-1"></div>
  </div>
</mat-dialog-actions>
