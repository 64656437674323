<section>
  <div class="container mb-3 " style="width: 98% !important;">
    <div class="row">
      <div class="col-8">
        <h5 class="m-3" *ngIf="yearO != undefined">SEGUIMIENTO DE MATRICULAS/PLAZAS PARA EL CURSO: {{yearO}} </h5>

      </div>
      <div class="col-2 offset-1 p-3">
        <label class="form-label">Seleccionar año:</label>
        <select class="custom-select form-select " [(ngModel)]="yearO" (change)="cambiarAnyo()">
          <option  *ngFor="let y of years" [value]="y">{{y}}</option>

        </select>
      </div>
    </div>
    <table mat-table
                 [dataSource]="plazas" multiTemplateDataRows
                 class="mat-elevation-z8">
    <ng-container matColumnDef="modulo"  >
      <th mat-header-cell *matHeaderCellDef>MODULO</th>
      <td mat-cell *matCellDef="let element" > {{element.modulo}} </td>
    </ng-container>
    <ng-container matColumnDef="ocupadasT" >
      <th mat-header-cell *matHeaderCellDef>OCUPADAS</th>
      <td mat-cell *matCellDef="let element"> {{element.ocupadasT}} </td>
    </ng-container>
    <ng-container matColumnDef="ratioT" >
      <th mat-header-cell *matHeaderCellDef>TOTALES</th>
      <td mat-cell *matCellDef="let element"> {{element.ratioT}} </td>
    </ng-container>
    <ng-container matColumnDef="restantes" >
      <th mat-header-cell *matHeaderCellDef>RESTANTES</th>
      <td mat-cell *matCellDef="let element"> {{element.restantes}} </td>
    </ng-container>




    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
        <div class="example-element-detail"
             [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <div class="container">
            <table class="table table-hover">
              <thead class="table-dark">
              <tr>
                <th style="color: black !important;">Aula</th>
                <th style="color: black !important;">Ocupadas</th>
                <th style="color: black !important;">Totales</th>
                <th style="color: black !important;">Restantes</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let data of element.data">
                <td>{{data.anyo_curso}} - {{data.acronimo}} {{data.aula}} {{data.curso}} </td>
                <td>{{data.ocupadas}}</td>
                <td>{{data.ratio}}</td>
                <td>{{data.ratio - data.ocupadas}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
        class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>
  </div>


  <mat-card style="width: 100%">
    <mat-card-header>
      <mat-card-title class="pl-5">Estadisticas de contactos - Contactos del dia: {{ contactosDiarios.length}}</mat-card-title>

    </mat-card-header>
    <mat-card-content>

      <ktd-grid [cols]="cols"
                [rowHeight]="rowHeight"
                [layout]="layout"
                (layoutUpdated)="print($event)"
      >
        <ktd-grid-item id="0">
          <div class="pruebas">
            <div class="row">
              <div [formGroup]="cursoForm"   style="padding: 16px">

                <div  >
                  <label class="form-label">Curso Escolar</label>
                  <select class="custom-select form-control"  formControlName="curso" (change)="changePieData()">
                    <option selected value="">Cualquiera</option>
                    <option *ngFor="let curso of cursosEscolares" [value]="curso.id">{{curso.cursoEscolar}}</option>
                  </select>
                </div>



              </div>
            </div>
            <div class="row" *ngIf="datosPie">
              <div class="col-6" *ngFor="let dato of datosPie">
                <app-pie-chart [datos]="dato"></app-pie-chart>
              </div>

            </div>
          </div>
        </ktd-grid-item>
        <ktd-grid-item id="1">
          <div class="pruebas">
            <div class="row">
              <div [formGroup]="polarForm"   style="padding: 16px">

                <div  >
                  <label class="form-label">Curso Escolar</label>
                  <select class="custom-select form-control"  formControlName="cursoPolar" (change)="changePolarData()">
                    <option selected value="">Cualquiera</option>
                    <option *ngFor="let curso of cursosEscolares" [value]="curso.id">{{curso.cursoEscolar}}</option>
                  </select>
                </div>



              </div>
            </div>
            <div class="row" *ngIf="datospolar.length !== 0">
              <div class="col-6" *ngFor="let fake of fakeNgFor">
                <app-polar-chart [datos]="datospolar"  titulo="Donde nos ha conocido:"></app-polar-chart>
              </div>
              <div class="col-6" *ngFor="let fake of fakeNgFor">
                <app-polar-chart [datos]="datospolar2"   titulo="Formas de contacto:"></app-polar-chart>
              </div>
            </div>
          </div>

        </ktd-grid-item>
        <ktd-grid-item id="2" >
          <div class="pruebas">
            <div style="text-align: center">
              <p>Histórico de contacos por año y ciclo</p>
            </div>
            <div class="row" *ngIf="barData.length !== 0">
              <div class="col-6" *ngFor="let bar of barData">
                <app-bar-chart [datos]="bar"></app-bar-chart >
              </div>

            </div>
          </div>

        </ktd-grid-item>
        <ktd-grid-item id="3">
          <div class="pruebas">
            <div style="text-align: center">
              <p>Ratio de matriculaciones por año escolar</p>
            </div>
            <div class="row" *ngIf="b.length !== 0">

              <div class="col-4" *ngFor="let data of b; let i = index">

                <app-bar-total-chart [total]="a[i]" [datos]="data"  ></app-bar-total-chart>
              </div>
            </div>
          </div>
        </ktd-grid-item>

        <ktd-grid-item id="4">
          <div class="pruebas">
            <div style="text-align: center">
              <p>Ratio matriculaciones/contactos</p>
            </div>
            <div class="row" *ngIf="totaldata.length !== 0">
              <div class="col-12" >
                <app-bar-total-chart [total]="totaldata.total" [datos]="totaldata.matriculados"  ></app-bar-total-chart>
              </div>
            </div>
          </div>
        </ktd-grid-item>
      </ktd-grid>

    </mat-card-content>

  </mat-card>
</section>

