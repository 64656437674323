import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';


@Component({
  selector: 'app-grafica',
  templateUrl: './grafica.component.html',
  encapsulation: ViewEncapsulation.None
})


export class GraficaComponent implements OnInit {
  @Input() datos: any[] = [];
  cantidad: number;
  texto: string;

  // Doughnut
  public doughnutChartLabels: string[];
  public doughnutChartData: number[];
  public doughnutChartType = 'doughnut';
  public colors: Array<any> = [ {
    backgroundColor: ['#65adf1', '#fa1a1a']
  }];

  constructor () {
  }

  ngOnInit () {
    this.doughnutChartLabels = [this.datos[0], this.datos[1]];
    this.doughnutChartData = [this.datos[2], this.datos[3]];
    this.cantidad = this.datos[4];
    this.texto = this.datos[5];
  }

  // events
  public chartClicked (e: any): void {
    console.log(e);
  }

  public chartHovered (e: any): void {
    console.log(e);
  }
}
