// Smart DataTable
export const settings = {
  columns: {
    id: {
      title: 'ID',
      filter: false,
      editable: false
    },

    nombre: {
      title: 'Nombre',
      filter: false,
      editable: false
     },

   apellidos: {
      title: 'Apellidos',
      filter: false,
      editable: false
    },
    nota: {
      title: 'Nota',
      filter: false,
      editable: true
    },
    fecha: {
      title: 'Entregado',
      filter: false,
      editable: false
    },
    tipo: {
      title: 'Tipo',
      filter: false,
      editable: false
    },





  },
  attr: {
    class: "table table-responsive"
  },
  edit:{
    editButtonContent: '<i class="ft-edit success font-medium-1 mr-2"></i>',
    confirmSave: true
  },
  delete:{
    deleteButtonContent: '<i class="ft-upload info font-medium-1 mr-2"></i>',
    confirmDelete: true
  },





  actions: {
    position: 'right',
    title: 'Acciones',
    add: false,
    custom: [{ name: 'ver', title: `<i  class="ft-file info font-medium-1 mr-2"></i>`,

     },

  ],

}





};



