import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Subject} from 'rxjs/Subject';
import {Rutas} from '../../../../utiles/Rutas';
import {LoginService} from '../../../pages/services/login.service';


@Injectable()
export class AlumnosService {
  private alumnoSource = new Subject<any>();
  public alumno$ = this.alumnoSource.asObservable();
  private datosSource = new Subject<any>();
  public datos$ = this.datosSource.asObservable();
  private examenesSource: Subject<any> = new Subject<any>();
  examenes$ = this.examenesSource.asObservable();

  // ASÍ SE INICIALIZA UN OBSERVABLE
  /* private asistenciasYfaltasSource: Subject<any> = new Subject<any>(); ->
      - El source es para indicarle el next del Observable */
  /* asistenciasYfaltas$ = this.asistenciasYfaltasSource.asObservable(); ->
      - Es para acceder a él desde el componente .ts que llame al servicio */

  private asistenciasYfaltasSource: Subject<any> = new Subject<any>();
  asistenciasYfaltas$ = this.asistenciasYfaltasSource.asObservable();

  private asistenciasSource: Subject<any> = new Subject<any>();
  asistencias$ = this.asistenciasSource.asObservable();

  private faltasSource: Subject<any> = new Subject<any>();
  faltas$ = this.faltasSource.asObservable();

  private totalSource = new Subject<any>();
  public total$ = this.totalSource.asObservable();

  private totalHorasSource: Subject<any> = new Subject<any>();
  totalHoras$ = this.totalHorasSource.asObservable();

  constructor(private  http: HttpClient, private _login: LoginService) {
  }

  getPadres(id: number) {
    this.http.get(Rutas + 'dato/padre/alumno?id=' + id + '&token=' + localStorage.getItem('token')).subscribe(res => {
      if (res['code'] === 200) {
        console.log(res);
        this.datosSource.next(res['id']);
      }
    }, err => {
      console.log(err);
    });
  }

  getTotalDias() {
    this.http.get(Rutas + 'stats/total/dias?token=' + localStorage.getItem('token')).subscribe(res => {
      console.log(res);
      if (res['code'] === 200) {
        let sin_findes = 0;
        const start_end = res['id'][0];
        const festivos = res['id'][2];
        // const agenda = res['id'][1];
        console.log(start_end);
        sin_findes = this.calcularDiferencias(start_end[0], start_end[1]);
        console.log(sin_findes);
        for (let i = 0; i < festivos.length; i++) {
          sin_findes = sin_findes - this.calcularDiferencias(festivos[i], festivos[i]);
        }
        this.totalSource.next(sin_findes);
      }
    }, err => {
      console.log(err);
    });
  }


   calcularDiferencias(inicio, fin) {
    const fechaInicio = new Date(inicio.start.date).getTime();
    const fechaFin = new Date(fin.end.date).getTime();
    console.log(fechaInicio, fechaFin);
    const day_as_milliseconds = 86400000;
    const diff_in_millisenconds = fechaFin - fechaInicio;
    const diff_in_days = diff_in_millisenconds / day_as_milliseconds;
    const dias = this.restarWeekend(diff_in_days.toFixed(), fechaInicio, fechaFin);
    return dias;

  }

  restarWeekend(total, inicio, fechaFin) {
    let t = 0;
    for (let i = 0; i < total; i++) {
      const actual = new Date(inicio + ((1000 * 60 * 60 * 24) * i));
      const ini = actual.getTime();
      if (ini <= fechaFin) {
        if (actual.getDay() !== 5 && actual.getDay() !== 6) {
          t++;
        }
      }
    }
    return t;
  }

  getTotalHoras(id: number) {
    this.http.get(Rutas + 'stats/total/horas/clases?id=' + id + '&token=' + localStorage.getItem('token')).subscribe(res => {
      if (res['code'] === 200) {
        this.totalHorasSource.next(res['id']);
      }
    }, err => {
      console.log(err);
    });
  }

  getTasks(order: boolean, type: string, id: number) {
    this.http.get(Rutas + 'alumno/tasks?id=' + id + '&orden=' + order + '&tipo=' + type + '&token=' +
      localStorage.getItem('token')).subscribe(res => {
      if (res['code'] === 200) {
        this.datosSource.next(res['id']);
      }
    }, err => {
      console.log(err);
    });
  }

  getAlumno(id) {
    this.http.get(Rutas + 'dato/alumno?id=' + id + '&token=' + localStorage.getItem('token'))
      .subscribe(data => {
        if (data['code'] === 200) {
          this.alumnoSource.next(data['id']);
        }
      }, err => {
        console.log(err);
      });
  }

  getAsistenciasYfaltas(id: number, tipo: boolean) {
    this.http.get(Rutas + 'asistencias/alumno?id=' + id + '&estado=' + tipo + '&token=' + localStorage.getItem('token'))
      .subscribe(data => {
        if (data['code'] === 200) {
          this.asistenciasYfaltasSource.next(data['id']);
        }
      }, err => {
        console.log(err);
      });
  }

  getFaltas(id: number) {
    this.http.get(Rutas + 'asistencias/alumno?id=' + id + '&estado=false' + '&token=' + localStorage.getItem('token'))
      .subscribe(data => {
        if (data['code'] === 200) {
          this.faltasSource.next(data['id']);
        }
      }, err => {
        console.log(err);
      });
  }

  getAsistencias(id: number) {
    this.http.get(Rutas + 'asistencias/alumno?id=' + id + '&estado=true' + '&token=' + localStorage.getItem('token'))
      .subscribe(data => {
        if (data['code'] === 200) {
          this.asistenciasSource.next(data['id']);
        }
      }, err => {
        console.log(err);
      });
  }


  getExamenes(id: number) {
    this.http.get(Rutas + 'examen/alumno?id=' + id + '&maestro=' + this._login.getIdentity().id + '&token=' + localStorage.getItem('token'))
      .subscribe(data => {
        if (data['code'] === 200) {
          this.examenesSource.next(data['id']);
        }
      }, err => {
        console.log(err);
      });
  }
}
