import {RouteInfo} from './sidebar.metadata';

// Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  {
    path: '/alumno/asignaturas',
    title: 'Asignaturas',
    icon: 'ft-book',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: []
  },
  {
    path: '/alumno/asistencias',
    title: 'Asistencias',
    icon: 'ft-user',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: []
  },
  {
    path: '/alumno/inbox',
    title: 'Mensajeria interna',
    icon: 'ft-mail',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: []
  },  { path: '/alumno/tablonUser', title: 'Tablón de anuncios', icon: 'ft-mail', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

  {
    path: '/alumno/horario',
    title: 'Horario',
    icon: 'ft-clock',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: []
  },
];
