<section id="task" *ngIf="!ver_">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">Tareas alumno</h5>
        </div>
        <table class="table table-striped tablatareas">
          <thead>
            <tr>
              <th>Asignatura
                <!-- <i [ngClass]="{'ft-chevron-up': order , 'ft-chevron-down': !order}" (click)="ordenar('asignatura')"></i> -->
              </th>
              <th>Tarea
                <!-- <i [ngClass]="{'ft-chevron-up': order , 'ft-chevron-down': !order}" (click)="ordenar('tarea')"></i> -->
              </th>
              <th>Estado
                <!-- <i [ngClass]="{'ft-chevron-up': order , 'ft-chevron-down': !order}" (click)="ordenar('estado_t')"></i> -->
              </th>
              <th>Fecha
                <!-- <i [ngClass]="{'ft-chevron-up': order , 'ft-chevron-down': !order}" (click)="ordenar('fecha_t')"></i> -->
              </th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let task of tasks; let i = index">
              <td>{{task.asignatura}}</td>
              <td>{{task.taskTitle}}</td>
              <td>
                <i [ngClass]="{'ft-check success' : task.status === 'completed', 'ft-zap warning': task.status === 'inProcess', 'ft-info danger':task.status === 'todo' }"></i>
              </td>
              <td> {{ task.createdOn.date | date: "dd-MM-yy" }}</td>
              <td>
                <ul class="list-inline">
                  <li class="list-inline-item success p-0">
                    <i class="ft-eye font-medium-3 mr-2" (click)="ver(i)"></i>
                  </li>
                  <li *ngIf="task.taskFile" class="success p-0 list-inline-item">
                    <i class="ft-file font-medium-3 mr-2" (click)="verFile(i)"></i>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>


<section id="verTask" *ngIf="ver_ && task">
  <div class="row">
    <div class="col-sm-9">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">{{task.taskTitle}}</h5>
        </div>
        {{task.taskMessage}}

        <hr>


      </div>

      <table class="table table-files" *ngIf="task.files.length > 0">
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Fecha</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let file of task.files; let i = index">
            <td>{{i}}</td>
            <td>{{file.fecha.date | date: 'dd/MM/yy'}}</td>
            <td>
              <ul class="list-inline">
                <li class="list-inline-item danger p-0">
                  <i class="ft-delete font-medium-3 mr-2" (click)="deleteFile(file.id, task.id)"></i>
                </li>
                <li class="success p-0 list-inline-item">
                  <i class="ft-file font-medium-3 mr-2" (click)="verFile(file.url)"></i>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>

      </table>

    </div>

    <div class="col-3">
      <ul class="list-group tool-panel">
        <li class="list-group-item" (click)="ver_ = false">Volver
          <i class="ft-arrow-left"></i>
        </li>
        <li class="list-group-item" *ngIf="task.taskFile">Descargar tarea:
          <i (click)='verFile(task.index)' class="ft-download"></i>
        </li>
        <li class="list-group-item">Creado:
          <small>{{task.createdOn.date| date: 'dd/MM/yy' }}</small>
        </li>
        <li class="list-group-item">Estado:
          <i (click)="changeEstado(task.index, task.status)" [ngClass]="{'ft-check success' : task.status === 'completed', 'ft-zap warning': task.status === 'inProcess', 'ft-info danger':task.status === 'todo' }"></i>
        </li>
        <li class="list-group-item">Subir tarea:
          <i (click)="openViewer()" class="ft-upload-cloud"></i>
        </li>
      </ul>
    </div>

  </div>


</section>

<div class="form-group" id="barraImages" hidden>
  <div class="input-group">
    <span class="input-group-btn">
      <span class="btn btn-default btn-file">
        <input id="buscadorPersona" name="filebutton-normal" type="file" class="file" (change)="fileChangeEvent($event)">
      </span>
    </span>
    <input type="text" class="form-control" readonly>
  </div>
</div>