import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgForm} from '@angular/forms';
import {LoginService} from '../../../../pages/services/login.service';
import {CrudEmpleadosService} from '../../services/crud-empleados.service';

declare var $;

@Component({
  selector: 'app-horario',
  templateUrl: './horario.component.html',
  styleUrls: ['./horario.component.scss']
})
export class HorarioComponent implements OnInit {

  @ViewChild('horarioForm') horarioForm: NgForm;
  @Output() public emisor = new EventEmitter<any>();
  public fantasma: any;
  public dias: any[];
  public horas: any[];
  public horarios: any[];
  timeEntrada = {hour: 9, minute: 0};
  timeSalida = {hour: 14, minute: 0};
  role = '';
  id: any;
  public diaEdit = 0;
  public disabled = true;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _crud: CrudEmpleadosService,
    private _login: LoginService
  ) {
    this.role = this._login.getIdentity().role;
    this.id = this._route.snapshot.parent.params['id'];

    this.dias = [
      {valor: 1, nombre: 'Lunes'},
      {valor: 2, nombre: 'Martes'},
      {valor: 3, nombre: 'Miércoles'},
      {valor: 4, nombre: 'Jueves'},
      {valor: 5, nombre: 'Viernes'},
      {valor: 6, nombre: 'Sabado'},
      {valor: 0, nombre: 'Domingo'},
    ];
    this.horas = [
      {valor: 0, nombre: '00'}, {valor: 1, nombre: '01'}, {valor: 2, nombre: '02'},
      {valor: 3, nombre: '03'}, {valor: 4, nombre: '04'}, {valor: 5, nombre: '05'},
      {valor: 6, nombre: '06'}, {valor: 7, nombre: '07'}, {valor: 8, nombre: '08'},
      {valor: 9, nombre: '09'}, {valor: 10, nombre: '10'}, {valor: 11, nombre: '11'},
      {valor: 12, nombre: '12'}, {valor: 13, nombre: '13'}, {valor: 14, nombre: '14'},
      {valor: 15, nombre: '15'}, {valor: 16, nombre: '16'}, {valor: 17, nombre: '17'},
      {valor: 18, nombre: '18'}, {valor: 19, nombre: '19'}, {valor: 20, nombre: '20'},
      {valor: 21, nombre: '21'}, {valor: 22, nombre: '22'}, {valor: 23, nombre: '23'},
    ];
  }

  ngOnInit() {
    if (this.id !== 0) {
      this.traerHorarios();
    }
  }



  open(id, idin, idout, idm) {
    const dia = $('#' + id);
    const hin = $('#' + idin);
    const hout = $('#' + idout);
    const margen = $('#' + idm);

    // SI TODOS LOS INPUTS ESTAN LLENOS
    // hin[0].validity.valid && hout[0].validity.valid &&
    if (dia[0].validity.valid && margen[0].validity.valid) {
      // ES UN POST NUEVO. LA id ESTA VACIA
      if (this.id === 0) {

        const item = {

          dia: Number(dia.val()),
          inat: this.timeEntrada,
          outat: this.timeSalida,
          margen: Number(margen.val()),

        };

        this.horarios.push(item);
        this.fantasma = 'lleno';
        dia.val('');
        hin.val('');
        hout.val('');
        margen.val('');
        this.controlPaso();

        // ES UN PUT. LA id ESTA LLENA
      } else {

        const item = {
          id: this.id,
          dia: Number(dia.val()),
          inat: this.timeEntrada,
          outat: this.timeSalida,
          margen: Number(margen.val()),
        };

        this._crud.addHorario(item).subscribe( res => {
          if ( res['code'] === 200) {
            console.log(res['id']);
            this.horarios = res['id'].horarios;
          }
        });

        dia.val('');
        hin.val('');
        hout.val('');
        margen.val('');
      }

      // SI ALGUN INPUT ESTA INVALIDO
    } else {
      this.controlValido(id);
      this.controlValido(idm);
      // this.controlValido(idin);
      // this.controlValido(idout);

      const alerta = {
        ttlModal: 'Alerta',
        txtModal: 'Revise los campos en rojo'
      };
      this.emisor.emit(alerta);
    }
  }

  controlValido(id) {
    const elemento = $('#' + id);
    if (!elemento[0].validity.valid) {
      if (!elemento[0].classList.contains('ng-invalid')) {
        elemento[0].classList.add('ng-invalid');
        elemento[0].classList.add('ng-pristine');
        elemento[0].classList.add('ng-touched');
      }
    } else {
      if (elemento[0].classList.contains('ng-invalid')) {
        elemento[0].classList.toggle('ng-invalid');
        elemento[0].classList.toggle('ng-pristine');
        elemento[0].classList.toggle('ng-touched');
      }
    }
  }

  borrar(i) {

    if (this.id === 0) {
      this.horarios.splice(i, 1);
      this.controlPaso();
    } else {
      const idt = this.horarios[i].id;
      this._crud.removeHorario(this.id, idt).subscribe( res => {
        if ( res['code'] === 200) {
          console.log(res['id']);
          this.horarios = res['id'].horarios;
        }
      });

      this.controlPaso();
    }
  }

  controlPaso() {
    if (this.horarios.length === 0) {
      this.fantasma = undefined;
      const alerta = {
        ttlModal: 'Alerta',
        txtModal: 'Al menos debe introducir un horario'
      };
      this.emisor.emit(alerta);
    }
  }

  diasemana(i: number) {
    console.log(i);
    if (i === 0) {
      return 'Domingo';
    } else {
      console.log(this.dias[i - 1].nombre);
      return this.dias[i - 1].nombre;
    }
  }

  traerHorarios() {
    this._crud.getEmpleado(this.id).subscribe(res => {
      if (res['code'] === 200) {
        this.horarios = res['id'];
        console.log(this.horarios);
      }
    });
  }

  able() {
    this.disabled = false;
  }

  update(dia) {
    this.disabled = true;
    dia.dia = Number(dia.dia);
    console.log(dia);
    this._crud.updateHorario(dia).subscribe(res => {
      this.traerHorarios();
    });
  }
}
