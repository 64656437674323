<h2 mat-dialog-title class="text-center">{{data === undefined ? 'Añadir' : 'Editar'}} Curso Intensivos</h2>
<mat-dialog-content>
  <form [formGroup]="inputForm" autocomplete="off">
    <div class="form-body">
      <div class="row">
        <div class="col-md-4">
          <label class="form-label">Nombre Curso Extracurricular</label>
          <mat-form-field appearance="outline" class="col-md-12">
            <input matInput matNativeControl type="text" formControlName="cursillo">
          </mat-form-field>
        </div>

        <div class="col-md-8">
          <label class="form-label">Descripcion</label>
          <mat-form-field appearance="outline" class="col-md-12">
            <textarea matInput matNativeControl type="text" rows="5" formControlName="descripcion"> </textarea>
          </mat-form-field>
        </div>

        <div class="col-md-2">
          <label class="form-label">Importe</label>
          <mat-form-field appearance="outline" class="col-md-12">
            <input matInput matNativeControl type="number" formControlName="importe">
          </mat-form-field>
        </div>

        <div class="col-md-2">
          <label class="form-label">Fecha Inicio</label>
          <mat-form-field appearance="outline" class="col-md-12">
            <input matInput [matDatepicker]="dp3" formControlName="inicio" readonly>
            <mat-hint>DD/MM/YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
            <mat-datepicker #dp3 disabled="false"></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col-md-2">
          <label class="form-label">Fecha Fin</label>
          <mat-form-field appearance="outline" class="col-md-12">
            <input matInput [matDatepicker]="dp4" formControlName="fin" readonly>
            <mat-hint>DD/MM/YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="dp4"></mat-datepicker-toggle>
            <mat-datepicker #dp4 disabled="false"></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col-md-2">
          <label class="form-label">Hora Inicio Curso</label>
          <mat-form-field appearance="outline" class="col-md-12">
            <input matInput matNativeControl type="time" formControlName="hInicio">
          </mat-form-field>
        </div>

        <div class="col-md-2">
          <label class="form-label">Hora Fin Curso</label>
          <mat-form-field appearance="outline" class="col-md-12">
            <input matInput matNativeControl type="time" formControlName="hFin">
          </mat-form-field>
        </div>

        <div class="col-md-2">
          <label class="form-label">Año</label>
          <mat-form-field appearance="outline" class="col-md-12">
            <input matInput matNativeControl type="number" formControlName="anyo">
          </mat-form-field>
          <small *ngIf="anyo">
            Formato <strong>no válido</strong>
          </small>
        </div>
      </div>
    </div>
    <div class="form-actions row">
      <div class="col-6">
        <button mat-button matDialogClose class="btn btn-raised btn-outline-danger float-right cerrar">
          <i class="ft-x"></i> Cancelar
        </button>
      </div>
      <div class="col-6">
        <button mat-button class="btn btn-raised btn-outline-primary float-left" (click)="data === undefined ? guardar() : editar()"
                [disabled]="!inputForm.valid"> <i class="ft-save"></i> {{data === undefined ? 'Guardar' : 'Editar'}}
        </button>
      </div>
    </div>
  </form>
</mat-dialog-content>
