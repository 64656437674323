import {Router} from '@angular/router';
import {Component, Input, ViewChild, OnDestroy, OnInit} from '@angular/core';
import {CursosService} from '../services/cursos.service';
import {Subscription} from 'rxjs/Subscription';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-cursos',
  templateUrl: './cursos.component.html'
})

export class CursosComponent implements OnDestroy, OnInit {
  @ViewChild('f') loginForm: NgForm;

  private moduloSubscription: Subscription = null;
  private modalidadSubscription: Subscription = null;
  private familiaSubscription: Subscription = null;
  private cicloFormativoSubscription: Subscription = null;

  modulos_educativos: any = [];
  modulos: any = [];
  ciclos: any = [];
  modulo_bachiller: boolean;
  modulo_fp: boolean;
  familia_fp: boolean;
  modulo_basico: boolean;


  constructor (private _cursos: CursosService, private _router: Router) {
    this._cursos.getCiclosFormativos();
  }

  reset () {
    this.modulos = [];
    this.ciclos = [];
    this.modulo_fp = false;
    this.familia_fp = false;
    this.modulo_basico = false;
    this.modulo_bachiller = false;
    }

  ngOnInit (): void {
    this.moduloSubscription = this._cursos.moduloEducativo$.subscribe(data => {
      this.modulos_educativos = data;
    });
  }

  ngOnDestroy (): void {
    if (this.moduloSubscription !== null) {
      this.moduloSubscription.unsubscribe();
    }
    if (this.modalidadSubscription !== null) {
      this.modalidadSubscription.unsubscribe();
    }
    if (this.cicloFormativoSubscription !== null) {
      this.cicloFormativoSubscription.unsubscribe();
    }
  }


  getModulos (): void {
    const id = this.loginForm.value.modulos_educativos;
    if (id === '4') {
      this.modulo_fp = false;
      this.familia_fp = false;
      this.modulo_basico = false;
      this.modulo_bachiller = true;
      this._cursos.getModulos(id);
      this.modalidadSubscription = this._cursos.modulo$.subscribe(data => {
        this.modulos = data;
      });
    }

    if (id === '5' || id === '6' || id === '7') {
      this.modulo_bachiller = false;
      this.modulo_basico = false;
      this.modulo_fp = true;
      this.familia_fp = true;
      this._cursos.getModulos(id);
      this.modalidadSubscription = this._cursos.modulo$.subscribe(data => {
        this.modulos = data;
      });
    }

    if (id === '1' || id === '2' || id === '3') {
      this.modulo_basico = true;
      this.modulo_bachiller = false;
      this.modulo_fp = false;
      this.familia_fp = false;
      this._cursos.getModulosBasicos(id);
      this.moduloSubscription = this._cursos.modulo$.subscribe(data => {
        this.modulos = data;
      });
    }
  }


  getCiclos () {
    const id = this.loginForm.value.modulos;
    this._cursos.getCiclosEducativos(id);
    this.cicloFormativoSubscription = this._cursos.cicloEducativo$.subscribe(data => {
      this.ciclos = data;
    });

  }

  onSubmit () {
    if (this.loginForm.valid) {

      console.log(this.loginForm.value);
      this._cursos.add(this.loginForm.value);
      this._router.navigate(['/admin/centro/lista']);

    }
  }

}


