import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {AdminExamenesService} from './services/admin-examenes.service';
import {ClasesMService} from '../admin-clases/services/clasesM.service';
import Swal from 'sweetalert2';
import {FormControl, FormGroup, Validators} from '@angular/forms';


@Component({
  selector: 'app-admin-examenes',
  templateUrl: './admin-examenes.component.html',
  styleUrls: ['./admin-examenes.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AdminExamenesComponent implements OnInit, OnDestroy {
  notasForm: FormGroup;
  public asignaturas: any[];
  asignatura: any;
  tipo = '';
  editable: boolean;

  public partialSelected: any[] = [];
  public listadoExamenes: any[] = [];
  porcentaje = false;

  listadoExamenesSubscription: Subscription = new Subscription();
  examenesSubscription: Subscription = new Subscription();
  public id: number;
  check: boolean;
  activar: boolean;
  public tests: any[] = [];
  constructor(private _route: ActivatedRoute, private router: Router, private _clases: ClasesMService,
              private _service: AdminExamenesService) {
    this.id = this._route.snapshot.parent.params['id'];
    this.setForm();
  }

  ngOnInit(): void {
    this._service.getAsignaturas(this.id).then(res => this.asignaturas = res['id']);
    this.listadoExamenesSubscription = this._service.listadoExamenes$.subscribe(
      resp => {
        this.listadoExamenes = resp;
        console.log(this.listadoExamenes);
      });
    this.examenesSubscription = this._service.examenes$.subscribe(
      resp => {
        console.log(resp);
        this.calcularMedia(resp);
      });
  }

  ngOnDestroy(): void {
    this.listadoExamenesSubscription.unsubscribe();
    this.examenesSubscription.unsubscribe();
  }

  setForm(): void {
    this.notasForm = new FormGroup({
      parciales : new FormControl('', Validators.required),
      asig: new FormControl('', Validators.required),
    });
  }

  // Obtiene los parciales
  getExamenes() {
    if (this.notasForm.value.parciales) {
      const datos = {
        examenes: this.notasForm.value.parciales,
        curso: this.id,
        asignatura: this.notasForm.value.asig
      }
      this.partialPorcentaje()
      console.log(this.partialSelected);
      this._service.getExamenes(datos);
    } else {
      Swal.fire('', 'Debe Seleccionar algún parcial', 'warning')
    }
  }

// obtiene el id e la asignatura, luego llama al servicio para traer el listado de examenes
  setAsignatura() {
    console.log(this.asignatura);
    if (this.asignatura) {
      this._service.listadoExamenes(this.id, this.asignatura);
    } else {
      Swal.fire('', 'Seleccionar una Asignatura', 'warning');
    }
  }

  // Sube la nota final
  addNotaFinal() {
    const notafinal = [];
    if (this.controlTipo()) {
      for (let i = 0; i < this.tests.length; i++) {
        const dato = {
          curso: parseInt(String(this.id), 10),
          asignatura: parseInt(this.notasForm.value.asig, 10),
          user: this.tests[i].id_alumno,
          tipo: parseInt(this.tipo, 10),
          nota: this.tests[i].notaFinal
        }
        notafinal.push(dato);
      }
      console.log(notafinal);
      this._service.addNotaFinal(notafinal, this.id, this.tipo, this.notasForm.value.asig);
    }
  }

  // Controla que el campo tipo este seleccionado
  controlTipo() {
    if (this.tipo === '') {
      Swal.fire('', 'Seleccione el Tipo de Nota Final', 'warning');
      return false;
    } else {
      return true;
    }
  }

  // Calcula la nota media por porcentaje y deshabilita el boton
  notesPercentage($event: MouseEvent) {
    console.log(this.partialSelected);
    const porciento = this.controlPorcentaje();
    if (porciento) {
      for (let i = 0; i < this.tests.length; i++) {
        let aux = 0;
        for (let j = 0; j < this.tests[i].examenes.length; j++) {
          const nota = (this.tests[i].examenes[j].nota * this.partialSelected[j].porcentaje) / 100;
          aux += nota;
        }
        this.tests[i].notaFinal = Math.round(aux);
      }
      ($event.target as HTMLButtonElement).disabled = true;
      Swal.fire('', 'Se ha Calculado el Promedio por Porcentaje', 'success');
      console.log(this.tests);
    }
  }

  // Para habilitar la edición de la nota
  editar(i, x) {
    if (!this.editable) {
      document.getElementById('a_' + i + x).hidden = true;
      document.getElementById('b_' + i + x).hidden = false;
    } else {
      document.getElementById('a_' + i + x).hidden = false;
      document.getElementById('b_' + i + x).hidden = true;
    }
    this.editable = !this.editable;
  }

  // Editar nota, se verifica que la nota ingresada sea un numero y que este en un rango de 1 a 10
  add(id_examen: any, note: any, i: number, x: number) {
    const notas = parseInt(note, 10)
    if (!isNaN(notas)) {
      if (notas > 0 && notas <= 10) {
        const datos = {
          id_nota: id_examen,
          nota: notas
        };
        console.log(datos);
        this._clases.noteUpdate(datos).subscribe((res) => {
          console.log(res);
          this.getExamenes();
        }, (error) => {
          console.log(error);
        });
        document.getElementById('a_' + i + x).hidden = false;
        document.getElementById('b_' + i + x).hidden = true;
      } else {
        Swal.fire('', 'El rango de notas es de 1 a 10', 'warning');
      }
    } else {
      Swal.fire('', 'Haz Introducido una Letra', 'warning')
    }
  }

  // Calcula la media de las notas y agrega un campo de nota final
  calcularMedia(p: Array<any>) {
    let i = 0;
    p.map((obj) => {
      let aux = 0;
      for (let j = 0; j < p[i].examenes.length; j++) {
        aux += p[i].examenes[j].nota;
      }
      obj.notaFinal = Math.round(aux / p[i].examenes.length);
      i++;
      return obj;
    });
    this.tests = p;
  }

  // Se filtra el listado de parciales con los que se ha seleccionado y se agrega un campo porcentaje
  partialPorcentaje() {
    this.partialSelected = [];
    this.partialSelected = this.listadoExamenes.filter(o1 => this.notasForm.value.parciales.some(o2 => o1.id === o2));
    let e = 0;
    this.partialSelected.map((obj) => {
      obj.porcentaje = null;
      e++;
      return obj;
    });
  }

  // Control de los datos introducidos en los porcentajes de notas
  controlPorcentaje() {
    let ok = true;
    let suma = 0;
    for (let i = 0; i < this.partialSelected.length; i++) {
      if (this.partialSelected[i].porcentaje !== null) {
      if (!isNaN(this.partialSelected[i].porcentaje)) {
          suma += this.partialSelected[i].porcentaje;
      } else {
        Swal.fire('', 'Haz ingresa alguna letra', 'warning');
        ok = false;
        break;
      }
      } else {
       Swal.fire('', 'No haz ingresado los porcentajes', 'warning');
       ok = false;
       break;
      }
    }

    if ((suma > 100 || suma < 100) && ok === true) {
      Swal.fire('', 'La suma de los porcentajes debe ser igual a 100', 'warning');
      ok = false;
    }
    return ok;
  }
}
