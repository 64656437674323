import {NgModule} from '@angular/core';
import {Routes, RouterModule, CanActivate} from '@angular/router';
import {AuthGuardMaestroService   as AuthGuardMaestro} from '../../services/auth-guard-maestro.service';
import { MaestroComponent} from './maestro.component';

import {ClasesMComponent} from '../admin-clases/clasesM.component';
import {ClaseMComponent} from '../admin-clases/componentes/clases.component';
import {AlumnosMComponent} from '../admin-clases/componentes/alumnosM.component';
import {AsignaturasMComponent} from '../admin-clases/componentes/asignaturasM.component';
import {InfoAsignaturaMComponent} from '../admin-clases/componentes/info-asignaturaM.component';
import {GenerarExamenMComponent} from '../admin-clases/componentes/generar-examen.component';
import {ListaExamenesComponent} from '../admin-clases/componentes/lista-examenes.component';
import {ListaNotasMComponent} from '../admin-clases/componentes/lista-notasM.component';
import {ListaFilesExamenesComponent} from '../admin-clases/componentes/lista-files-examenes.component';
import {CheckAsistenciasComponent} from '../admin-asistencias/componentes/check-asistencias.component';
import {TaskboardComponent} from '../../../taskboard/taskboard.component';
import {AlumnosComponent} from '../admin-alumnos/alumnos.component';
import {DatosAlumnoComponent} from '../admin-alumnos/componentes/datos-alumno.component';
import {AlumnosExamenComponent} from '../admin-clases/componentes/alumnos-examen.component';
import {TaskAlumnoComponent} from '../admin-alumnos/componentes/task-alumno.component';
import {ExamenesAlumnoComponent} from '../admin-alumnos/componentes/examenes-alumno.component';
import {MaestroInboxComponent} from '../inbox/maestro-inbox.component';
import {ProfileContactoComponent} from '../../admin/admin-profile/componentes/profile-contacto.component';
import {ProfileEditComponent} from '../../admin/admin-profile/componentes/profile-edit.component';
import {ProfilePasswordComponent} from '../../admin/admin-profile/componentes/profile-password.component';
import {ProfileComponent} from '../../admin/admin-profile/profile.component';
import {AgendaMaestroComponent} from '../../componentes/agenda/agenda-maestro/agenda-maestro.component';
import {PadresAlumnoComponent} from '../admin-alumnos/componentes/padres-alumno.component';
import {AdminExamenesComponent} from '../admin-examenes/admin-examenes.component';
import {ConfigExamenesComponent} from '../admin-examenes/componentes/config-examenes.component';
import {AsistenciasComponent} from '../../componentes/asistencias/asistencias.component';
import {AdminNotasfinalComponent} from '../admin-notasfinal/admin-notasfinal.component';
import {JornadaComponent} from '../../admin/admin-users/componentes/jornada/jornada.component';
import {TablonComponent} from '../../componentes/tablon/tablon.component';
import {TablonUserComponent} from '../../componentes/tablon/tablon-user/tablon-user.component';
import {NotaFinalAlumnosComponent} from '../admin-aulumnos/componentes/nota-final-alumnos/nota-final-alumnos.component';

const routes: Routes = [
  {
    path: 'maestro',
    component: MaestroComponent,
    canActivate: [AuthGuardMaestro],
    children: [
      {
        path: 'alumnos/:id',
        component: AlumnosComponent,
        children: [
          {path: 'datos', component: DatosAlumnoComponent},
          {path: 'examenes', component: ExamenesAlumnoComponent},
          {path: 'notas-finales', component: NotaFinalAlumnosComponent},
          {path: 'asistencias', component: AsistenciasComponent},
          {path: 'padres', component: PadresAlumnoComponent},

          {path: '**', pathMatch: 'full', redirectTo: 'datos'}
        ]
      },
      {
        path: 'clases',
        component: ClasesMComponent,
        data: {
          title: 'Lista clases'
        }
      },
      {
        path: 'clase/:id',
        component: ClaseMComponent,
        children: [
          {path: '', redirectTo: 'alumnos', pathMatch: 'full'},
          {path: 'alumnos', component: AlumnosMComponent},
          {path: 'asignaturas', component: AsignaturasMComponent},
          {path: 'examenes', component: AdminExamenesComponent },
          {path: 'notas/:type/:asign', component: AdminNotasfinalComponent }
        ]
      },
      {
        path: 'info/:curso/:id',
        component: InfoAsignaturaMComponent,
        children: [
          {path: '', redirectTo: 'lista-alumnos', pathMatch: 'full'},
          {path: 'examen', component: GenerarExamenMComponent},
          {path: 'lista-examenes', component: ListaExamenesComponent},
          {path: 'lista-notas/:id', component: ListaNotasMComponent},
          {path: 'lista-files/:id', component: ListaFilesExamenesComponent},
          {path: 'lista-alumnos', component: CheckAsistenciasComponent},

          {path: 'alumnos-examen/:id', component: AlumnosExamenComponent }

        ]
      },
      {
        path: 'profile',
        component: ProfileComponent,
        children: [
          {path: 'editar', component: ProfileEditComponent},
          {path: 'contacto', component: ProfileContactoComponent},
          {path: 'password', component: ProfilePasswordComponent},
          {path: '**', pathMatch: 'full', redirectTo: 'editar'}

        ]
      },
      {
        path: 'horario',
        component: AgendaMaestroComponent,
      },
      {
        path: 'jornada',
        component: JornadaComponent,
        data: {
          title: 'Jornada'
        }
      },
      {
        path: 'inbox',
        component:  MaestroInboxComponent
      },
      {
        path: 'tablonUser',
        component: TablonUserComponent,
      },
      {
        path: 'examenes',
        component: AdminExamenesComponent,
        children: [
          {path: 'confs/:id', component: ConfigExamenesComponent},
          {path: 'confs', component: ConfigExamenesComponent},
          ]
      }
      ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MaestroRoutingModule {
}
