<div style="display: block; padding-top: 20px" #containerRef *ngIf="datos.datos.lenght != 0">
  <div style="text-align: center">
    <p>{{titulo}}</p>
  </div>
  <ngx-charts-bar-horizontal style="width: 80%;"
    [view]="[containerRef.offsetWidth, 400]"
    [scheme]="colorScheme "
    [results]="single"
    [gradient]="gradient"
    [xAxis]="false"
    [yAxis]="true"
    [legend]="showLegend"

    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="false"
    [showDataLabel]="true"
  >
  </ngx-charts-bar-horizontal>

</div>
