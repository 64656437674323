import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Rutas} from '../../../../utiles/Rutas';


@Injectable()
export class CustomizeService {


  constructor (private http: HttpClient) {}

  addTitle (title: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {title};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'add/title', params, {headers: headers}).subscribe(datas => {
      if (datas['code'] === 200) {
        console.log(datas);
      }
    }, error => {
      console.log(error);
    });
  }
}
