import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AngularEditorModule} from '@kolkov/angular-editor';
import { TablonUserComponent } from './tablon-user/tablon-user.component';
import {MatCardModule} from '@angular/material/card';
import {MatDividerModule} from '@angular/material/divider';
import {MatButtonModule} from '@angular/material/button';
import { TablonModalComponent } from './tablon-modal/tablon-modal.component';
import {MatDialogModule} from '@angular/material/dialog';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { DetalleModalComponent } from './detalle-modal/detalle-modal.component';



@NgModule({
  declarations: [
    TablonUserComponent,
    TablonModalComponent,
    DetalleModalComponent
  ],
  imports: [
    CommonModule,
    AngularEditorModule,
    MatCardModule,
    MatDividerModule,
    MatButtonModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
  ]
})
export class TablonModule {

}
