<section id="task">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">Tareas alumno</h5>
        </div>
        <table class="table table-striped tablatareas">
          <thead>
            <tr>
              <th class="">Asignatura </th>
              <th class="">Tarea </th>
              <th class="">Estado </th>
              <th class="">Fecha </th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let task of tareas; let i = index">
              <td>{{task.asignatura}}</td>
              <td>{{task.taskTitle}}</td>
              <td>
                <i [ngClass]="{'ft-check success' : task.status === 'completed', 'ft-zap warning': task.status === 'inProcess', 'ft-info danger':task.status === 'todo' }"></i>
              </td>
              <td> {{ task.fecha.date | date: "dd-MM-yy" }}</td>

            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>