import {Component, ViewChild, TemplateRef, OnInit, AfterViewInit, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';
import {Subject} from 'rxjs/Subject';
import {ModalDismissReasons, NgbCalendar, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CalendarEvent, CalendarEventTimesChangedEvent, CalendarMonthViewDay, DAYS_OF_WEEK
} from 'angular-calendar';
import {CalendarioService} from './services/calendario.service';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-calendario',
  styleUrls: ['./calendario.component.css'],
  templateUrl: './calendario.component.html'
})

export class CalendarioComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('modalEvents') modalEvents: TemplateRef<any>;
  view = 'month';
  viewDate: Date = new Date();
  modalData: {
    action: string,
    event: CalendarEvent
  };
  closeResult: string;
  refresh: Subject<any> = new Subject();
  tipos: any;

  event = {
    start: new Date(),
    end: new Date(),
    from: '',
    to: '',
    title: '',
    color: '#ad2121',
    type: 1,
    id: '',
    typo: 1,
    h_inicio: {
      hour: 0,
      minute: 0,
      second: 0
    },
    h_fin: {
      hour: 0,
      minute: 0,
      second: 0
    }
  };

  inicio: any;
  fin: any;

  hoveredDate: NgbDate;
  fromDate: NgbDate;
  toDate: NgbDate;
  locale = 'es';
  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  weekendDays: number[] = [DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY];

  activeDayIsOpen = true;
  events: CalendarEvent[] = [];
  tiposSubscription: Subscription = new Subscription();
  eventosSubscription: Subscription = new Subscription();
  paramsSubscription: Subscription = new Subscription();

  fcInicio: any;
  fcFin: any;
  inicioFijo: any;
  finFijo: any;

  constructor(private modal: NgbModal, private calendar: NgbCalendar, public service: CalendarioService) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getToday();
    this.service.lista_tipos();
    this.service.lista();
    this.service.params();
  }

  ngOnInit() {
    this.paramsSubscription = this.service.params$.subscribe(resp => {
      console.log(resp);
      this.event.h_inicio.hour = resp.horaInicio;
      this.event.h_inicio.minute = resp.minutoInicio;
      this.event.h_fin.hour = resp.horaFin;
      this.event.h_fin.minute = resp.minutoFin;
      this.inicio = {hour: resp.horaInicio, minute: resp.minutoInicio};
      this.fin = {hour: resp.horaFin, minute: resp.minutoFin};
      this.inicioFijo = this.inicio;
      this.finFijo = this.fin;
      this.fcInicio = new FormControl('', (control: FormControl) => {
        const value = control.value;
        if (value.hour >= this.inicioFijo.hour && value.hour <= this.finFijo.hour) {
          return null;
        }
        return {FueraRango: true};
      });
      this.fcFin = new FormControl('', (control: FormControl) => {
        const value = control.value;
        if (value.hour >= this.inicioFijo.hour && value.hour <= this.finFijo.hour) {
          return null;
        }
        return {FueraRango: true};
      });
    });
    this.tiposSubscription = this.service.tipos$.subscribe(resp => {
      this.tipos = resp;
    });
    this.service.modal$.subscribe(resp => {
      if (resp) {
        this.modal.open(this.modalEvents);
      }
    });

    this.service.event$.subscribe(resp => {
      console.log(resp);
      this.event = resp;
      const start = new Date(resp.start);
      const end = new Date(resp.end);
      this.fromDate.year = start.getFullYear();
      this.fromDate.month = start.getMonth() + 1;
      this.fromDate.day = start.getDay();
      this.toDate.year = end.getFullYear();
      this.toDate.month = end.getMonth() + 1;
      this.toDate.day = end.getDay();
    });

    this.eventosSubscription = this.service.eventos$.subscribe(resp => {
      console.log(resp);
      this.events = resp;
      this.refresh.next();
    });
  }

  ngOnDestroy() {
    this.eventosSubscription.unsubscribe();
    this.tiposSubscription.unsubscribe();
    this.paramsSubscription.unsubscribe();
  }

  eventClicked({date, events}: { date: Date, events: CalendarEvent[] }) {
    console.log(date);
    this.activeDayIsOpen = true;
    this.viewDate = date;
    this.view = 'day';
  }

  beforeMonthViewRender({body}: { body: CalendarMonthViewDay[] }): void {
    body.forEach(day => {
      if (day.badgeTotal > 0) {
        day.cssClass = 'evento';
      }
    });
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

  dayClicked({date, events}: { date: Date, events: CalendarEvent[] }): void {
    console.log(events);
    this.activeDayIsOpen = true;
    this.viewDate = date;
    this.view = 'day';
  }

  eventTimesChanged({event, newStart, newEnd}: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    this.handleEvent('Dropped or resized', event);
    this.refresh.next();
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = {event, action};
    this.modal.open(this.modalEvents, {size: 'lg'});
  }

  open(content) {
    this.modal.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

 getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  openAdd() {
    this.fromDate = this.calendar.getToday();
    this.toDate = this.calendar.getToday();
    this.event = {
      start: new Date(),
      end: new Date(),
      from: '',
      to: '',
      title: '',
      color: '',
      type: 1,
      id: '',
      typo: 1,
      h_inicio: {
        hour: 0,
        minute: 0,
        second: 0
      },
      h_fin: {
        hour: 0,
        minute: 0,
        second: 0
      }
    };
    this.open(this.modalEvents);
  }

  addEvent(id?: any): void {
    let setEnd = '';

    this.event.h_inicio.hour = this.inicio.hour;
    this.event.h_inicio.minute = this.inicio.minute;
    this.event.h_fin.hour = this.fin.hour;
    this.event.h_fin.minute = this.fin.minute;

    const setFrom = this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day + ' '
      + this.inicio.hour + ':' + this.inicio.minute + ':00';

    this.event.from = this.fromDate.day + '-' + this.fromDate.month + '-' + this.fromDate.year + ' '
      + this.inicio.hour + ':' + this.inicio.minute + ':00';

    if (this.toDate === null) {
      this.event.start = new Date(setFrom);
      this.event.end = new Date(setFrom);
      this.event.to = this.fromDate.day + '-' + this.fromDate.month + '-' + this.fromDate.year + ' '
        + this.fin.hour + ':' + this.fin.minute + ':00';
      setEnd = this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day + ' '
        + this.fin.hour + ':' + this.fin.minute + ':00';

    } else if (this.fromDate && this.toDate) {
      setEnd = this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day + ' '
        + this.fin.hour + ':' + this.fin.minute + ':00';
      this.event.to = this.toDate.day + '-' + this.toDate.month + '-' + this.toDate.year + ' '
        + this.fin.hour + ':' + this.fin.minute + ':00';

      this.event.start = new Date(setFrom);
      this.event.end = new Date(setEnd);

    }
    if (id) {
      this.event.id = id + '';
    }
    const newEvent: CalendarEvent = {
      start: new Date(setFrom),
      end: new Date(setEnd),
      title: this.event.title,
      cssClass: 'custom-event',
      color: {
        primary: '#488aff',
        secondary: '#bbd0f5'
      }
    };
    this.events.push(newEvent);
    this.refresh.next();
    console.log(this.event);
    this.service.add(this.event);
  }

  ngAfterViewInit(): void {
  }
}
