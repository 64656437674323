import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ComercialService} from '../../services/comercial.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AddReferidoComponent} from '../add-referido/add-referido.component';
import {AddCicloComponent} from '../add-ciclo/add-ciclo.component';
import {AddTipoContactoComponent} from '../add-tipo-contacto/add-tipo-contacto.component';

@Component({
  selector: 'app-add-contacto',
  templateUrl: './add-contacto.component.html',
  styleUrls: ['./add-contacto.component.scss']
})
export class AddContactoComponent implements OnInit {

  inputForm: FormGroup;
  cicloForm: FormGroup;
  estados: any;
  referidos: any;
  ciclos: any;
  cursosEscolares: any;
  formaContacto: any;
  ciclosFiltered = [];
  id: any;



  constructor(public dialoRef: MatDialogRef<AddContactoComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder,
              private comercialService: ComercialService, private snack: MatSnackBar, private dialog: MatDialog) {
    this.crearFomulario();
    this.getSelectData();

  }

  ngOnInit(): void {
  }

  crearFomulario(): void {
    this.inputForm = new FormGroup({
      id: new FormControl(''),
      alNombre: new FormControl(''),
      alApellidos: new FormControl(''),
      contactoNombre: new FormControl(''),
      contactoApellidos: new FormControl(''),
      email: new FormControl('', Validators.email),
      telefono: new FormControl(''),
      cursoEscolar: new FormControl('', Validators.required),
      ciclo: new FormControl({value: '', disabled: true}, Validators.required),
      formaContacto: new FormControl('', Validators.required),
      estado: new FormControl('', Validators.required),
      observaciones: new FormControl('', Validators.required),
      referido: new FormControl('', Validators.required),
      enviar: new FormControl(true),
      emailComercial: new FormControl( 'orientador@cieep.com'),
    });

  }

  addReferido() {
    const dialogRef1 = this.dialog.open( AddReferidoComponent, {hasBackdrop: true});
    dialogRef1.afterClosed().subscribe( result => {
      if ( result) {
        console.log(result);
        this.comercialService.addReferido(result).subscribe( (res) => {
            this.referidos = res;
          }, error => {this.showSnack(); }
        );
      }
    });
  }

  getSelectData() {
    this.comercialService.getSelects().subscribe( (res) => {
        console.log('Lista', res);
        this.estados = res['estados'];
        this.referidos = res['referidos'];
        this.formaContacto = res['forma_contacto'];
        this.ciclos = res['ciclos'];
        this.cursosEscolares = res['cursoEscolar'];
      if (this.data.update === true) {
        this.setDatos(this.data.element);
      } else if ( this.data.update === false && this.data.element !== null) {
        this.setNewDataFromPromocion(this.data.element);
      }
      }, error => {
        console.log('Ha fallado');
      }
    );
  }

  filterCiclosByCursoEscolar() {
    this.ciclosFiltered = [];
    console.log(this.inputForm.value.cursoEscolar);
    this.inputForm.controls['ciclo'].disable();
    this.cursosEscolares.map(  a => {
      if (a.id === parseInt(this.inputForm.value.cursoEscolar, 10)) {
        this.ciclosFiltered = a.ciclosOfertados;
        this.inputForm.controls['ciclo'].enable();
      }
    });
    console.log(this.ciclosFiltered);
  }

  addCiclo( ) {
    console.log(this.inputForm.value.cursoEscolar)
    if ( this.inputForm.value.cursoEscolar === '') {
      this.snack.open('Primero debe escoger Curso Escolar', 'Aceptar');
    } else {
      const dialogRef2 = this.dialog.open( AddCicloComponent, {data: {
        list: this.ciclos
        } , hasBackdrop: true});
      dialogRef2.afterClosed().subscribe( result => {
        if ( result) {
          console.log(result);
          this.comercialService.addCicloofertado(result.value.ciclo, result.value.aforo, this.inputForm.value.cursoEscolar)
            .subscribe( (res) => {
              console.log(res);
            this.comercialService.getSelects().subscribe( (resp) => {
              console.log(resp);
              this.cursosEscolares = resp['cursoEscolar'];
            });
            }, error => {this.showSnack(); }
          );
        }
      });
    }
  }

  addTipoContacto() {
    const dialogRef3 = this.dialog.open( AddTipoContactoComponent, {hasBackdrop: true});
    dialogRef3.afterClosed().subscribe( (result: FormGroup) => {
      if ( result) {
        this.comercialService.addMedio(result.value.medio).subscribe((res) => {
            this.formaContacto = res;
          }, error => {this.showSnack(); }
        );
      }
    });
  }
  showSnack() {
    this.snack.open('Ha ocurrido un error, pongase en contacto con el administrador.', 'Aceptar');
  }

  setDatos(datos): void {

    this.inputForm.controls.id.setValue(datos.id);
    this.inputForm.controls.alNombre.setValue(datos.alumno.nombre);
    this.inputForm.controls.alApellidos.setValue(datos.alumno.apellidos);
    this.inputForm.controls.contactoNombre.setValue(datos.infoContacto.nombre);
    this.inputForm.controls.contactoApellidos.setValue(datos.infoContacto.apellidos);
    this.inputForm.controls.email.setValue(datos.infoContacto.mail);
    this.inputForm.controls.telefono.setValue(datos.infoContacto.telefono);
    this.inputForm.controls.cursoEscolar.setValue(datos.ciclo.cursoEscolar.id);
    this.filterCiclosByCursoEscolar();
    this.inputForm.controls.ciclo.setValue(datos.ciclo.id);
    this.inputForm.controls.formaContacto.setValue(datos.forma_contacto.id);
    this.inputForm.controls.estado.setValue(datos.estado.id);
    this.inputForm.controls.observaciones.setValue(datos.actividad_contactos[0].observacion);
    this.inputForm.controls.referido.setValue(datos.infoContacto.referido.id);
    this.inputForm.controls.enviar.setValue(false);
    this.inputForm.controls.emailComercial.setValue('orientador@cieep.com');
  }

  guardar() {
   delete this.inputForm.value.id;
    this.dialoRef.close(this.inputForm);
  }

  editar() {
    console.log(this.inputForm);
    this.dialoRef.close(this.inputForm);
  }

  private setNewDataFromPromocion(datos) {
    console.log(datos);
    this.inputForm.controls.alNombre.setValue(datos.nombre);
    this.inputForm.controls.alApellidos.setValue(datos.apellidos);
    this.inputForm.controls.contactoNombre.setValue(datos.nombre);
    this.inputForm.controls.contactoApellidos.setValue(datos.apellidos);
    this.inputForm.controls.email.setValue(datos.contactos.find(obj => {return obj.tipo === 1}).dato ?? '');
    this.inputForm.controls.telefono.setValue(datos.contactos.find(obj => {return obj.tipo === 2}).dato ?? '');
    this.inputForm.controls.emailComercial.setValue('orientador@cieep.com');
  }
}
