





<div class="contenedor">
  <h2 class="titulo">MIS CLASES</h2>
  <div class="row" *ngIf='clases.length > 0'>

    <div *ngFor="let clase of clases" class=" col-xl-4 col-md-12 col-lg-6 " style="padding-bottom: 20px">
    <mat-card>
      <mat-card-header class="card-head">
        <mat-card-subtitle>
          {{clase.curso}}
        </mat-card-subtitle>
        <mat-card-title>
          {{clase.anyo_curso}}º - {{clase.acronimo}} {{clase.aula}}
        </mat-card-title>

      </mat-card-header>

      <mat-card-content>
        <label class="lbl1">{{clase.nivel}}</label>
        <label class="lbl">Asignaturas</label>
        <div *ngFor="let asi of clase.asi_hoy">
          <mat-divider></mat-divider>
          <p style="color: #9f9f9f" *ngIf="!asi.estado">{{asi.asignatura}}</p>
          <p style="color: black" *ngIf="asi.estado">{{asi.asignatura}}</p>
        </div>
      </mat-card-content>
      <mat-divider inset></mat-divider>
      <mat-card-actions>
        <button mat-button (click)="go(clase)">VER</button>

      </mat-card-actions>
      <mat-card-footer>
        <mat-progress-bar mode="determinate" value="100"  [appProgressBarColor]="clase.color" ></mat-progress-bar>
      </mat-card-footer>
    </mat-card>
    </div>
<!--    <table class="table">-->
<!--      <thead>-->
<!--      <tr>-->
<!--        <th scope="col">Año</th>-->
<!--        <th scope="col">Aula</th>-->
<!--        <th scope="col">Curso</th>-->
<!--        <th scope="col">Año curso</th>-->
<!--        <th scope="col">Acciones</th>-->
<!--      </tr>-->
<!--      </thead>-->
<!--      <tbody>-->
<!--      <tr *ngFor="let row  of clases; let i = index">-->
<!--        <td>{{row.anyo_curso}}º </td>-->
<!--        <td>{{row.aula}}</td>-->
<!--        <td>{{row.nivel}}</td>-->
<!--        <td>{{row.curso}}</td>-->

<!--        <td>-->
<!--          <ul class="list-inline">-->
<!--            <li class="list-inline-item success p-0">-->
<!--              <i class="ft-eye" (click)="go(row)"></i>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </td>-->
<!--      </tr>-->
<!--      </tbody>-->
<!--    </table>-->
  </div>
</div>

