<div class="info-top"></div>
<button type="button" class="btn btn-outline-success" (click)="abrir()"><span class="ft-plus-circle font-medium-3"></span> Agregar Curso Extracurricular</button>
<div class="table-responsive">
  <table class="table" style="table-layout: fixed">
    <thead>
    <tr class="d-flex">
      <th class="col-3" scope="col">Curso Extracurricular</th>
      <th class="col-2" scope="col">Fecha Inicio y Fin</th>
      <th class="col-2" scope="col">Hora Inicio y Fin</th>
      <th class="col-1" scope="col">Año</th>
      <th class="col-1" scope="col">Importe</th>
      <th class="col-1" scope="col">Estado</th>
      <th class="col-2" scope="col">
        Acciones
      </th>
    </tr>
    </thead>
    <tbody>
    <tr class="d-flex" *ngFor="let cursoE of Ce; let i = index">
      <td class="col-3" style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
        <li class="list-inline-item danger p-0" matTooltip= "{{cursoE.cursillo}} --> {{cursoE.descripcion}}"
            (mouseenter)="$event.stopImmediatePropagation()"
            (mouseleave)="$event.stopImmediatePropagation()"
            #tooltip="matTooltip" (click)="tooltip.toggle()">
          <i class="fa fa-info-circle"></i>
        </li>
        {{cursoE?.cursillo}}
      </td>
      <td class="col-2">{{cursoE.inicio?.date | date:'dd/MM'}} -- {{cursoE.fin?.date | date:'dd/MM'}}</td>
      <td class="col-2">{{cursoE.hInicio}} -- {{cursoE.hFin}}</td>
      <td class="col-1">{{cursoE?.anyo}}</td>
      <td class="col-1">
        {{cursoE?.importe}} €
      </td>
      <td class="col-1">
        <mat-checkbox  (change)="estadoCurso(cursoE.id)" [(ngModel)]="cursoE.finalizado"></mat-checkbox>
      </td>
      <td class="col-2">
        <ul class="list-inline">
          <li class="list-inline-item info p-0" (click)="editar(cursoE)">
            <i class="fa fa-edit font-medium-3 mr-2"></i>
          </li>
          <li class="list-inline-item danger p-0" (click)="delete(cursoE.id)">
            <i class="fa fa-trash font-medium-3 mr-1"></i>
          </li>
        </ul>
      </td>
    </tr>
    </tbody>
  </table>
</div>
