import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Router} from '@angular/router';
import {Rutas} from '../../../../utiles/Rutas';
import {LoginService} from '../../../pages/services/login.service';
import {Subscription} from 'rxjs/Subscription';


@Injectable()
export class ProfileService {
  private userSource = new Subject<any>();
  public user$ = this.userSource.asObservable();

  private contactoSource = new Subject<any>();
  public contactos$ = this.contactoSource.asObservable();

  private tiposSource = new Subject<any>();
  public tipos$ = this.tiposSource.asObservable();

  private authSource = new Subject<boolean>();
  public auth$ = this.authSource.asObservable();

  private passSource = new Subject<any>();
  public pass$ = this.passSource.asObservable();

  constructor (private http: HttpClient, private router: Router, private _login: LoginService) {
  }

  getProfile () {

    this.http.get(Rutas + 'get/dato?token=' + localStorage.getItem('token') + '&id=' + this._login.getIdentity().id).subscribe(data => {
      if (data['code'] === 200) {
        this.userSource.next(data['id']);
      }
    }, error => {
      console.log(error);
    });
  }





  updateAuth (user: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {user};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'check/password', params, {headers: headers}).subscribe(datas => {
      this.authSource.next(datas['id']);
    }, error => {
      console.log(error);
    });
  }

  updatePass (user: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {user};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'update/pass', params, {headers: headers}).subscribe(datas => {
      if (datas['id'] !== false) {
        this.passSource.next(datas['id']);
      } else {
        this.passSource.next(false);
      }
    }, error => {
      console.log(error);
    });
  }

  updateUser (user: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {user};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'update/user', params, {headers: headers}).subscribe(datas => {
      if (datas['id'] !== false) {
        this.userSource.next(datas['id']);
      } else {
        this.userSource.next(false);
      }
    }, error => {
      console.log(error);
    });
  }
  update (user: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    user.id = this._login.getIdentity().id;
    const json = {user};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'update/datos', params, {headers: headers}).subscribe(datas => {
      if (datas['code'] === 200) {
        this.userSource.next(datas['id']);
       // localStorage.setItem('identity', JSON.stringify(datas['id']));
      }
    }, error => {
      console.log(error);
    });
  }

  addContacto (contacto: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {contacto};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'add/contacto', params, {headers: headers}).subscribe(datas => {
      if (datas['code'] === 200) {
        this.contactoSource.next(datas['id']);
      }
    }, error => {
      console.log(error);
    });
  }

  getUser (id: number) {
    this.http.get(Rutas + 'get/user?token=' + localStorage.getItem('token') + '&id=' + id).subscribe(data => {
      if (data['code'] === 200) {
        this.userSource.next(data['id'].username);
      } else {
        return false;
      }
    });
  }

  getContactos (id: number) {
    this.http.get(Rutas + 'get/contactos?token=' + localStorage.getItem('token') + '&id=' + id).subscribe(data => {
      if (data['code'] === 200) {
        this.contactoSource.next(data['id']);
      } else {
        return false;
      }
    });
  }

  getTipos () {
    this.http.get(Rutas + 'get/tipo/contacto?token=' + localStorage.getItem('token')).subscribe(data => {
      if (data['code'] === 200) {
        this.tiposSource.next(data['id']);
      } else {
        return false;
      }
    });
  }

  deleteContacto (id: number) {
    this.http.get(Rutas + 'delete/contacto?id=' + id + '&token=' + localStorage.getItem('token'))
      .subscribe(data => {
        if (data['code'] === 200) {
          this.contactoSource.next(data['id']);
        } else {
          return false;
        }
      });
  }

}
