import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UsersService} from '../../services/users.service';
import swal from 'sweetalert2';
import {Dato} from '../../../../../modelos/datos.modelo';


@Component({
  selector: 'app-convalidaciones',
  templateUrl: './convalidaciones.component.html',
  styleUrls: ['./convalidaciones.component.scss']
})
export class ConvalidacionesComponent implements OnInit, OnDestroy {
  public asignaturas: any [] = [];
  public convalidacion: Array<any> = [];
  idUser: any;
  nota: any;
  editable: Boolean;
  dato: Dato;

  constructor(private route: ActivatedRoute, private router: Router, private _sevice: UsersService) {
    this.idUser = parseInt(this.route.snapshot.parent.params['id'], 10);
  }

  ngOnInit() {
    this._sevice.getDato(this.idUser);
    this._sevice.dato$.subscribe((res: any) => {
      this.dato = res;
    });
    this.getAsignatura();
  }

  ngOnDestroy() {
  }

  getAsignatura() {
    this._sevice.getAsignatura(this.idUser).subscribe(res => {
      const hash = {};
      res['id'] = res['id'].filter(o => hash[o.id] ? false : hash[o.id] = true);
      this.asignaturas = res['id'];
      this.getConvalidacion();
    });
  }

  getConvalidacion() {
    this._sevice.getConvalidacion(this.idUser).then(value => {
      this.convalidacion = value['id'];
      this.cargar()
    });
  }

  cargar() {
    let i = 0;
    this.asignaturas.map((obj) => {
      obj.nota = null;
      obj.id_convalidacion = null;
      obj.aa = null;
      i++;
      return obj;
    });
    for (let e = 0; e < this.convalidacion.length; e++) {
      for (let j = 0; j < this.asignaturas.length; j++) {
        if (this.asignaturas[j].id === this.convalidacion[e].id_asignatura) {
          this.asignaturas[j].nota = this.convalidacion[e].nota;
          this.asignaturas[j].id_convalidacion = this.convalidacion[e].id;
          this.asignaturas[j].aa = this.convalidacion[e].aa
        }
      }
    }
  }

  add(asign: any, nota: any, index) {
    if (asign.id_convalidacion == null) {
      const datos = {
        id: this.idUser,
        asignatura: asign.id,
        nota: parseInt(nota, 10),
        aa: asign.aa === null ? false : asign.aa
      }
      console.log(datos)
      this._sevice.addConvalidacion(datos).subscribe(res => {
        console.log(res);
        if (res['code'] === 200) {

          this.getAsignatura();
        } else {
          console.log(res);
        }
      }, error => {
        console.log(error);
      });
    } else {
      const dato = {
        id: asign.id_convalidacion,
        nota: parseInt(nota, 10),
        aa: asign.aa === null ? false : asign.aa
      }
      console.log(dato)
      this._sevice.updateConvalidacion(dato).subscribe(res => {
        if (res['code'] === 200) {
          this.getAsignatura();
        } else {
          console.log(res);
        }
      }, error => {
        console.log(error);
      });
    }
    this.getAsignatura();
    document.getElementById('a_' + index).hidden = false;
    document.getElementById('b_' + index).hidden = true;
  }

  editar(index: number) {
    if (!this.editable) {
      document.getElementById('a_' + index).hidden = true;
      document.getElementById('b_' + index).hidden = false;
    } else {
      document.getElementById('a_' + index).hidden = false;
      document.getElementById('b_' + index).hidden = true;
    }
    this.editable = !this.editable;
  }

  delete(i: number) {
    console.log(i);
    if (i !== null) {
      this._sevice.removerConvalidacion(i).subscribe(data => {
        if (data['code'] === 200) {
          swal.fire('Mensaje', 'Se ha eliminado', 'success');
          this.getAsignatura();
        }
      }, error => {
        console.log(error);
      });
    }
  }

  change(event, asign, i) {
    if (event.checked) {
      if (asign.id_convalidacion == null) {
        const datos = {
          id: this.idUser,
          asignatura: asign.id,
          nota: 0,
          aa: true
        }
        console.log(datos)
        this._sevice.addConvalidacion(datos).subscribe(res => {
          console.log(res);
          if (res['code'] === 200) {
            this.getAsignatura();
          } else {
            console.log(res);
          }
        }, error => {
          console.log(error);
        });
      }
    } else {
      if (asign.validar === true) {
        const dato = {
          id: asign.id_convalidacion,
          nota: 0,
          aa: false
        }
        console.log(dato)
        this._sevice.updateConvalidacion(dato).subscribe(res => {
          console.log(res);
          if (res['code'] === 200) {
            this.getAsignatura();
          } else {
            console.log(res);
          }
        }, error => {
          console.log(error);
        });
      }
      document.getElementById('a_' + i).hidden = false;
      document.getElementById('b_' + i).hidden = true;
    }
  }
}
