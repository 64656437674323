import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HijosPadreService} from '../services/hijos-padre.service';
import {Subscription} from 'rxjs/Subscription';



@Component({
  selector: 'app-padre-tareas',
  templateUrl: './padre-tareas.component.html',
  styleUrls: ['../hijos-padre.component.scss']
})
export class PadreTareasComponent implements OnInit, OnDestroy {
  tareas: any[] = [];
  tareasSubscribe = new Subscription();

  constructor (private _router: ActivatedRoute, private _hijo: HijosPadreService) {
    let id: number;
    this._router.parent.params.subscribe(params => {
      if (params['id']) {
        id = params['id'];
      }
      this._hijo.getTareas(id);
    });
  }

  ngOnInit () {
    this.tareasSubscribe = this._hijo.tareas$.subscribe(res => {

      this.tareas = res;
    });
  }

  ngOnDestroy () {
    this.tareasSubscribe.unsubscribe();
  }
}
