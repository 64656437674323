import {Component, Input,ViewChild,OnDestroy,OnInit,DoCheck,ViewEncapsulation, ElementRef
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs/Subscription";
import { AsistenciasService } from '../services/asistencias.service';



@Component({
    selector: "app-vista-agenda",
    templateUrl: "./vista-agenda.component.html",
    styleUrls: ["../asistencias.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class VistaAgendaComponent  {
    public clases: Array<any> = [];
    public clasesSubscription: Subscription = null;
   
    
 

constructor(private route: ActivatedRoute,  
    private router: Router,
    private _asistencias: AsistenciasService){}



}

