<h3 class="titulo-cabecera" *ngIf="!curso.isEmpty && !curso.finalizada">{{curso.anyo_curso}} {{curso.acronimo}} {{curso.aula}} - {{curso.curso}}</h3>
<h3 class="titulo-cabecera" *ngIf="!curso.isEmpty && curso.finalizada">{{curso.anyo_curso}} {{curso.acronimo}} {{curso.aula}} - {{curso.curso}} (FINALIZADA)</h3>
<div class="row">

  <div class="col-md-2 img-top" *ngIf="curso.img">
    <img class="img-aula img-thumbnail rounded mx-auto d-block" [src]="curso.img | images: ''">
    <button type="button" class="btn btn-secondary col-12" onclick="document.getElementById('buscadorPersona').click()">Subir Foto</button>
  </div>
  <div class="form-group" id="barraImages" hidden>
    <div class="input-group">
            <span class="input-group-btn">
                <span class="btn btn-default btn-file">
                    <input id="buscadorPersona" name="filebutton-normal" type="file" class="file"
                           (change)="fileChangeEvent($event, 'curso')">
                </span>
            </span>
      <input type="text" class="form-control" readonly>
    </div>
  </div>
  <div class="col-md-10">
    <div class="card">
      <div class="card-body">
        <div class="px-3">
          <form class="form form-horizontal striped-rows form-bordered" novalidate="" (ngSubmit)="onSubmit()"
                #f="ngForm">
            <input type="hidden" name="id_curso" [(ngModel)]="curso.id">
            <div class="form-body">
              <div class="form-group row">
                <label class="col-md-3 label-control">Modulo</label>
                <div class="col-md-5">
                  <select name="curso_centro" [(ngModel)]="curso.id_curso_centro" class="form-control" required>
                    <ng-container *ngIf="cursosCentro.length > 0">
                      <option *ngFor="let cursos of cursosCentro" [value]="cursos.id">{{cursos.nivel}} - {{cursos.acronimo}}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-3 label-control">Letra Aula</label>
                <div class="col-md-5">
                  <input name="aula" [(ngModel)]="curso.aula" type="text" class="form-control"
                         placeholder="Curso" required>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-3 label-control">Ratio</label>
                <div class="col-md-5">
                  <input name="ratio" [(ngModel)]="curso.ratio" type="number" class="form-control width-input"
                         placeholder=" Ratio" required>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-3 label-control">Año</label>
                <div class="col-md-5">
                  <select class="form-control" [(ngModel)]="curso.anyo_curso" name="anyo_curso" required>
                    <option [value]="undefined">Selecciona el año del curso</option>
                    <option [value]=1>1</option>
                    <option [value]=2>2</option>
                  </select>
                </div>
              </div>


              <div class="form-group row">
                <label class="col-md-3 label-control">Turno</label>
                <div class="col-md-5">
                  <select class="form-control" [(ngModel)]="turno" name="turno" (change)="filtro($event)" disabled>
                    <option [value]="null">Selecciona el turno</option>
                    <option value="1">Mañana</option>
                    <option value="0" >Tarde</option>
                  </select>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-md-3 label-control">Clase</label>
                <div class="col-md-5">
                  <select class="form-control" [(ngModel)]="aulaSeleccionada" name="clase" [disabled]="aulasFiltered.length === 0" >
                    <option [value]="null">Selecciona el número de Aula</option>
                    <option *ngFor="let aula of aulasFiltered" [value]=aula.id >{{aula.numeroClase}}</option>

                  </select>
                </div>
                <button type="button" class="btn success"><i class="ft-plus-circle" (click)="addFormaContacto()"></i>
                </button>
              </div>

              <div class="form-group row">
                <label class="col-md-3 label-control">Calendario inicio y fin</label>
                <div class="col-md-5">
                  <select class="form-control" [(ngModel)]="curso.id_evento_curso" name="curso_evento" required>
                    <option *ngFor="let evento of cursosEvento" [value]="evento.id">{{evento.evento}}
                      - {{evento.inicio.date | date:'yyyy'}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-actions">
              <button type="submit" class="btn btn-raised btn-primary" [disabled]="!f.valid">
                <i class="ft-save"></i> Guardar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
