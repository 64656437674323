<h2 class="text-center">Estadisticas a fecha de {{fecha | date}}</h2>
   <div class="row">
     <div class="col-lg-6 col-md-4 col-sm-6 col-xs-12">
       <div class="card bg-white text-dark">
         <div class="card-body text-center">
           <h5 class="card-title " style="margin-top: 25px;">Nº Alumnos y Profesores</h5>
           <app-grafica *ngIf="start" [datos]="datos"></app-grafica>
         </div>
       </div>
     </div>
     <div class="col-lg-6 col-md-4 col-sm-6 col-xs-12">
       <div class="card bg-white text-dark">
         <div class="card-body text-center">
           <h5 class="card-title text-center" style="margin-top: 25px;">Asistencias</h5>
           <app-line-chart *ngIf="start" [asistencias]="asistencias"></app-line-chart>
         </div>
       </div>
     </div>
   </div>
<div class="col-lg-12 col-md-4 col-sm-6 col-xs-12">
  <div class="card bg-white text-dark">
    <div class="card-body text-center">
      <app-fichajes></app-fichajes>
    </div>
  </div>
</div>

