import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {AdminAlumnoService} from './services/admin-alumno.service';
import {UploadService} from '../../services/upload.service';


@Component({
  selector: 'app-alumnos-asignaturas',
  templateUrl: './alumno-asignaturas.component.html',
  styleUrls: ['./alumno-asignaturas.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AlumnoAsignaturasComponent implements OnInit, OnDestroy {
  public asignaturas: Array<any> = [];
  public asignaturasSubscription: Subscription = null;
  public cursoSubscription: Subscription = null;
  curso: any;
  public id: number;

  constructor(private route: ActivatedRoute, private router: Router, private _admin: AdminAlumnoService, private _upload: UploadService) {
    this._admin.getAsignaturas();
    this._admin.getCurso();
  }

  ngOnInit() {
    this.asignaturasSubscription = this._admin.datos$.subscribe(res => {
      const hash = {};
      res = res.filter(o => hash[o.id] ? false : hash[o.id] = true);
      this.asignaturas = res;
    });


  }

  ngOnDestroy() {
    this.asignaturasSubscription.unsubscribe();
  }

}
