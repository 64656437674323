import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-add-ciclo',
  templateUrl: './add-ciclo.component.html',
  styleUrls: ['./add-ciclo.component.scss']
})
export class AddCicloComponent implements OnInit {

  cicloForm: FormGroup;
  ciclos: any;

  constructor(public dialoRef: MatDialogRef<AddCicloComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder) {
    this.initForm();

  }

  ngOnInit(): void {
    this.ciclos = this.data.list;
    console.log(this.ciclos);
  }

  initForm(): void {
  this.cicloForm = new FormGroup( {
    ciclo: new FormControl('', Validators.required),
    modalidad: new FormControl(''),
    aforo: new FormControl('', Validators.required),
  });
}


}
