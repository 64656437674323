import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CsvService {
  downloadFile(data, filename = 'data', type: number) {
    console.log(data);
    let csvData = null;
    if (type === 1) {
      csvData = this.ConvertToCSV(data, ['Ciclo', 'Alumno', 'FormaContacto', 'Fecha', 'Email', 'Contacta',
        'Telefono', 'Referido', 'Estado']);
    } else {
      csvData = this.ConvertToCSV(data, ['Ciclo', 'Alumno', 'FormaContacto', 'Fecha', 'Email', 'Contacta',
        'Telefono', 'Referido', 'Estado']);
    }
    // console.log(csvData);
    const blob = new Blob(['\ufeff' + csvData], {type: 'text/csv;charset=utf-8;'});
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {  // if Safari open in new window to save file with random filename.
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray, headerList) {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    console.log(array);
    let str = '';
    let row = 'S.No,';
    for (const index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (const index in headerList) {
        const head = headerList[index];
        line += ',' + array[i][head];
      }
      str += line + '\r\n';
    }
    return str;
  }
}
