import {Injectable} from '@angular/core';
import {Task} from './taskboard.model';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Router} from '@angular/router';
import {Rutas} from '../utiles/Rutas';
import {LoginService} from '../components/pages/services/login.service';
import {reject} from 'q';

@Injectable()
export class TaskBoardService {
  private taskSource = new Subject<any>();
  public tasks$ = this.taskSource.asObservable();

  private todoSource = new Subject<any>();
  public todo$ = this.todoSource.asObservable();

  private inProcesSource = new Subject<any>();
  public inProcess$ = this.inProcesSource.asObservable();

  private completedSource = new Subject<any>();
  public completed$ = this.completedSource.asObservable();

  constructor (private http: HttpClient, private _login: LoginService) {
  }

  changeStatus (status: string, id: number) {
    this.http.get(Rutas + 'change/status?id=' + id + '&status=' + status + '&token=' + localStorage.getItem('token'))
      .subscribe(
        data => {
          if (data['code'] === 200) {
            this.task(data['id']);
          }
        },
        error => {
          console.log(error);
        }
      );
  }


  add (task: Task) {
    task.createdBy = this._login.getIdentity().id;
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {task};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    return new Promise((resolve, reject) => {
      this.http.post(Rutas + 'add/task', params, {headers: headers}).subscribe(
        datas => {
          if (datas['code'] === 200) {
            this.task(datas['id'].datos);
            resolve(datas['id'].id);
          }
        },
        error => {
          reject(error);
        }
      );
    });

  }

  deleteTask (id: number, type: boolean) {
    this.http.get(Rutas + 'delete/task?id=' + id + '&type=' + type + '&token=' + localStorage.getItem('token'))
      .subscribe(
        data => {
          if (data['code'] === 200) {
            this.task(data['id']);
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  getTask () {
    const id = this._login.getIdentity().id;
    this.http.get(Rutas + 'get/task?id=' + id + '&token=' + localStorage.getItem('token'))
      .subscribe(
        data => {
          if (data['code'] === 200) {
            this.task(data['id']);
          }
        },
        error => {
          console.log(error);
        }
      );
  }


  getMyTask () {
    const id = this._login.getIdentity().id;
    this.http
      .get(
        Rutas + 'my/task?id=' + id + '&token=' + localStorage.getItem('token')
      )
      .subscribe(
        data => {
          if (data['code'] === 200) {
            this.task(data['id']);
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  task (task: any[]) {

    const todo: any = [], inProcess: any = [], completed: any = [];
    task.forEach((valor, indice) => {
      switch (valor.status) {
        case 'todo':
          todo.push(new Task(
            valor.taskTitle,
            valor.taskMessage,
            valor.createdOn,
            valor.createdBy,
            this._login.getIdentity().id,
            valor.status,
            valor.type,
            valor.img,
            valor.id,
            valor.asignatura,
            valor.curso
          ))

          ;
          break;

        case 'inProcess':
          inProcess.push(
            new Task(
              valor.taskTitle,
              valor.taskMessage,
              valor.createdOn,
              valor.createdBy,
              this._login.getIdentity().id,
              valor.status,
              valor.type,
              valor.img,
              valor.id,
              valor.asignatura,
              valor.curso
            )
          );
          break;


        case 'completed':
          completed.push(
            new Task(
              valor.taskTitle,
              valor.taskMessage,
              valor.createdOn,
              valor.createdBy,
              this._login.getIdentity().id,
              valor.status,
              valor.type,
              valor.img,
              valor.id,
              valor.asignatura,
              valor.curso
            )
          );
          break;
      }
    });


    this.completedSource.next(completed);
    this.todoSource.next(todo);
    this.inProcesSource.next(inProcess);
  }
}
