import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CalendarDateFormatter, CalendarModule, CalendarNativeDateFormatter, DateAdapter, DateFormatterParams} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {CalendarioComponent} from './calendario.component';
import {NgbModalModule, NgbDatepickerModule, NgbTimepickerModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CalendarHeaderComponent} from './componentes/calendar-header.component';
import {CalendarioService} from './services/calendario.service';
import {CustomDateFormatter} from './services/customDateFormatter';


@NgModule({
  imports: [
    BrowserAnimationsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,

    }, {
      dateFormatter: {
        provide: CalendarDateFormatter,
        useClass: CustomDateFormatter
      }
    }),
    NgbModalModule,
    NgbDatepickerModule,
    FormsModule,
    NgbTimepickerModule,
    ReactiveFormsModule
  ],
  declarations: [CalendarioComponent, CalendarHeaderComponent],
  exports: [CalendarioComponent, CalendarHeaderComponent],
  providers: [ CalendarioService],
})

export class CalendarioModule {
}

