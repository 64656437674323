import {Component, Input, OnDestroy, OnInit, Renderer2, ViewEncapsulation} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';
import {AulaService} from './services/aula.service';
import {ActivatedRoute} from '@angular/router';
import {UsersService} from '../admin-users/services/users.service';
import {ExcelService} from '../../componentes/excelExport/excel.service';
import {CsvService} from '../../componentes/csvExport/csv.service';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Swal from 'sweetalert2';
import {json} from 'ng2-validation/dist/json';
import swal from 'sweetalert2';
import {AddContactoComponent} from '../comercial/modales/add-contacto/add-contacto.component';
import {FormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {BulkModalComponent} from './bulk-modal/bulk-modal.component';

pdfMake.vfs = pdfFonts.pdfMake.vfs;


@Component({
  selector: 'app-aulas',
  templateUrl: './aulas.component.html',
  styleUrls: ['./aulas.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AulaComponent implements OnInit, OnDestroy {
  @Input() id;
  @Input() cursoId: number;
  aulaId: number;
  alumnosAula: any[] = [];
  alumnos: any[] = [];
  allUsersForFilter: any[] = [];
  estado: boolean;
  nombreSearch = '';
  date = '';
  identificador = 0;
  alToSol: any[] = [];
  exportMails: any = [];
  pga: any = [];
  pgaExcel: any = [];
  aulaname = '';
  private exportAlumnosCsvGoogle: any[] = [];
  private exportSol: any[] = [];
  private datosAlumnos: any[] = [];
  private alumnosSubscription = new Subscription;
  private alumnosAulaSubscription = new Subscription;
  private datoSubscription = new Subscription;
  finalizada: boolean;

  constructor(private _aula: AulaService, private renderer: Renderer2, private route: ActivatedRoute, private exportService: ExcelService,
              private exportCSV: CsvService, private _user: UsersService, private dialog: MatDialog, ) {
    this.aulaId = this.route.snapshot.parent.params['id'];
    this._aula.getAlumnosAula(this.aulaId);
    this._aula.getAlumnosModulo(this.aulaId);
    this._user.getAulaName(this.aulaId).subscribe((res) => {
      console.log(res['id']);
      this.aulaname = res['id'][0] ;
      this.finalizada = res['id'][1]
    });
  }

  ngOnInit() {
    this._alumnosAulaSubscription();
    this._alumnosSubscription();
    this.getAlumnosExport();
    this.pgaExport();
  }

  ngOnDestroy() {
    this.alumnosAulaSubscription.unsubscribe();
    this.alumnosSubscription.unsubscribe();
    this.datoSubscription.unsubscribe();
  }

  allowDrop(ev) {
    ev.preventDefault();
  }

  // cuando coge y cuando suelta
  drag(ev) {
    // ID antigua
    ev.dataTransfer.setData('text/plain', ev.target.id);
  }

  // Cuando suelta
  drop(ev) {
    ev.preventDefault();

    const data = ev.dataTransfer.getData('text');
    if (data) {
      this._aula.add(data, this.aulaId).then(() => {
        this._aula.getAlumnosModulo(this.aulaId);
      });
    }
    return;
  }

  delete(id: number) {
    this._aula.delete(id, this.aulaId).then(res => {
      this._aula.getAlumnosModulo(this.aulaId);
    });
  }

  // ********* Consultas SQL  *********
  getAlumnosExport() {
    this._user.getAlumnosToExportExcelAndCSV(this.aulaId);
    this._datoSubscription();
    this._user.getAlumnosToExportSol(this.aulaId).subscribe((res) => {

      this.alToSol = res['id'];

    });
    this._user.getAlumnosToExportMAil(this.aulaId).subscribe((res) => {
      console.log(res);
      this.exportMails = res['id'];
      console.log(this.exportMails);
    });

  }

  pgaExport() {
    this._user.getAlumnosPga(this.aulaId).subscribe((resp) => {
      console.log(resp['id']);
      if (resp['code'] === 200) {
        this.pga = resp['id'];
      } else {
        Swal.fire('', 'No se pudo traer los datos', 'error');
      }
    });
  }

  // ********* Subsripciones *********
  _datoSubscription() {
    this.datoSubscription = this._user.datosAlumnos$.subscribe(res => {
      this.datosAlumnos = res;
    });
  }

// ********* Alumnos en aula *********
  _alumnosAulaSubscription() {
    this.alumnosAulaSubscription = this._aula.alumnosAula$.subscribe(res => {
      this.alumnosAula = res;
      console.log(this.alumnosAula)
    });
  }

  _alumnosSubscription() {
    this.alumnosSubscription = this._aula.alumnos$.subscribe(res => {
      this.alumnos = res;
      this.allUsersForFilter = this.alumnos;
    });
  }

  searchActionNombre() {
    if (this.nombreSearch.length > 0) {
      const valor = this.allUsersForFilter.filter((item) => {
        const nombre = item.nombre;
        return nombre.toLowerCase().indexOf(this.nombreSearch.toLowerCase()) > -1;
      });
      this._aula.alumnosSource.next(valor);
      this._user.usersSource.next(valor);
    } else {
      this._aula.getAlumnosModulo(this.aulaId);
    }
  }

  // ********* Metodos y funciones para Exportacion a CSV *********
  exportDatosAlumnos(type: number) {
    if (type === 1) {
      this.exportGoogleCSV();
      this.exportCSV.downloadFile(this.exportAlumnosCsvGoogle, 'Exportacion', type);
    } else {
      this.exportCSV.downloadFile(this.datosAlumnos, 'Exportacion', type);
    }
    // this.exportAlumnosCsvGoogle = [];
    // this.datosAlumnos = [];
  }

  exportGoogleCSV() {
    this.exportAlumnosCsvGoogle = [];
    for (const a of this.datosAlumnos) {
      this.exportAlumnosCsvGoogle.push({
        'nombre [Required]': a.nombre,
        'apellidos [Required]': a.apellidos,
        'dato [Required]': a.dato
      });
    }
  }

  exportPgaExcel() {
    for (const a of this.pga) {
      this.pgaExcel.push({
        'nombre': a.nombre,
        'apellidos': a.apellidos,

        'dni': a.dni,
        'P. Imagen': a.permisoImagen ? 'Si' : 'No',
        'P. Padres': a.permisoNotas ? 'Si' : 'No',
        'nacimiento': a.nacimiento,

        'provinciaNacimiento': a.provinciaNacimiento,
        'ciudadNacimiento': a.ciudadNacimiento,
        'provincia': a.provincia,
        'municipio': a.municipio,
        'Nacionalidad': a.Nacionalidad,
        'codPostal': a.codPostal,
        'curso': a.curso,
        'direccion': a.direccion,
        'email': a.email,
        'paisEstudiosPrevios': a.paisEstudiosPrevios,
        'sip': a.sip,
        'telefono': a.telefono,
        'titulo': a.titulo,
      });
    }

    this.exportService.exportExcel(this.pgaExcel, 'PGA - ' + this.aulaname);
    this.pgaExcel = [];
  }


  exportarSol() {
    this.exportSol = [];
    for (const a of this.alToSol) {
      this.identificador++;

      const iban = this.limpiarEspacios(a.IBAN);

      this.exportSol.push({
        'a': '',
        'b': a.nombre + ' ' + a.apellidos,
        'c': a.dni,
        d: ' ',
        e: a.direccion,
        f: ' ',
        g: a.codPostal,
        h: a.municipio,
        i: a.provincia,
        j: '',
        k: '',
        l: '',
        m: '',
        n: iban.slice(4, 8),
        o: iban.slice(8, 12),
        p: iban.slice(12, 14),
        q: iban.slice(14),
        r: '',
        s: 1,
        t: 724,
        u: a.aula.slice(0, 5),
        v: a.email,
        w: 21.00,
        x: 0.00,
        y: '',
        z: '',
        aa: '',
        ab: '',
        ac: '',
        ad: '',
        ae: '',
        af: 0,
        ag: 0,
        ah: 0,
        ai: iban,
        aj: '',
        ak: 1,
        al: 0,
        am: 0,
        an: 3,
        ao: 1,
        ap: 0,
        aq: 0,
        ar: '',
      });
    }
    console.log(this.exportSol);
    this.exportService.exportExcel(this.exportSol, 'prueba');
    this.exportSol = [];
  }

  exportarBancarios() {
    this.exportSol = [];
    for (const a of this.alToSol) {
      this.identificador++;

      const iban = this.limpiarEspacios(a.IBAN);

      this.exportSol.push({
        'Nombre alumno': a.nombre + ' ' + a.apellidos,
        'DNI Alumno': a.dni,

        'Direccion Alumno': a.direccion,

        'CP': a.codPostal,
        'Municipio': a.municipio,
        'Provincia': a.provincia,
        'IBAN': a.IBAN,
        'Titular de la cuenta': a.titular_nombre + ', ' + a.titular_apellidos,
        'Importe matricula': a.importeMatricula,
        'Forma pago matricula': a.formaPagoMatricula,
        'Importe cuota': a.importeCuota,
        'Forma pago cuota': a.formaPagoCuota
      });
    }
    console.log(this.exportSol);
    this.exportService.exportExcel(this.exportSol, 'Bancarios ' + this.aulaname);
    this.exportSol = [];
  }


  limpiarEspacios(iban: string): string {
    if (iban !== null) {
      if (iban.includes(' ')) {
        return iban.replace(/\s/g, '');
      } else {
        return iban;
      }
    } else {
      return '';
    }

  }

  exportarMails() {
    this.exportSol = [];
    for (const a of this.exportMails) {
      console.log(a.tutores[0]);
      this.exportSol.push({
        'Nombre Alumno': a.nombre,
        'Apellidos Alumno': a.apellidos,
        'Email 1': this.checkArrays(a.contactos, 0) === '' ? '' : this.checkArrays(a.contactos, 0).email,
        'Email 2': this.checkArrays(a.contactos, 1) === '' ? '' : this.checkArrays(a.contactos, 1).email,
        'Nombre Tutor 1': this.checkArrays(a.tutores, 0) === '' ? '' : this.checkArrays(a.tutores, 0).nombre,
        'Apellido Tutor 1': this.checkArrays(a.tutores, 0) === '' ? '' : this.checkArrays(a.tutores, 0).apellidos,
        'Email Tutor 1': this.checkArrays(a.tutores, 0) === '' ? '' : this.checkArrays(a.tutores, 0).email,
        'Nombre Tutor 2': this.checkArrays(a.tutores, 1) === '' ? '' : this.checkArrays(a.tutores, 1).nombre,
        'Apellido Tutor 2': this.checkArrays(a.tutores, 1) === '' ? '' : this.checkArrays(a.tutores, 1).apellidos,
        'Email Tutor 2': this.checkArrays(a.tutores, 1) === '' ? '' : this.checkArrays(a.tutores, 1).email,
      });
    }
    console.log(this.exportSol);
    this.exportService.exportExcel(this.exportSol, 'Emails-' + this.aulaname);
    this.exportSol = [];
  }

  exportTutores() {
    this.exportSol = [];
    console.log(this.exportMails);
    for (const a of this.exportMails) {
      console.log(a.tutores[0]);
      this.exportSol.push({
        'Nombre alumno': a.nombre,
        'Apellidos alumno': a.apellidos,
        'DNI alumno': a.dni,
        'F. Nacimiento': a.fechaNacimiento.date === null ? '' : new Date(a.fechaNacimiento.date).toLocaleDateString('ES'),
        'Telefono':  a.telefono === null ? '' : a.telefono[0].telefono,
        'Titulo': a.titulo,
        'Nombre Tutor 1': this.checkArrays(a.tutores, 0) === '' ? '' : this.checkArrays(a.tutores, 0).nombre,
        'Apellido Tutor 1': this.checkArrays(a.tutores, 0) === '' ? '' : this.checkArrays(a.tutores, 0).apellidos,
        'Email Tutor 1': this.checkArrays(a.tutores, 0) === '' ? '' : this.checkArrays(a.tutores, 0).email,
        'Telefono Tutor 1': this.checkArrays(a.tutores, 0) === '' ? '' : this.checkArrays(a.tutores, 0).telefono,
        'Nombre Tutor 2': this.checkArrays(a.tutores, 1) === '' ? '' : this.checkArrays(a.tutores, 1).nombre,
        'Apellido Tutor 2': this.checkArrays(a.tutores, 1) === '' ? '' : this.checkArrays(a.tutores, 1).apellidos,
        'Email Tutor 2': this.checkArrays(a.tutores, 1) === '' ? '' : this.checkArrays(a.tutores, 1).email,
        'Telefono Tutor 2': this.checkArrays(a.tutores, 1) === '' ? '' : this.checkArrays(a.tutores, 1).telefono,
      });
    }
    console.log(this.exportSol);
    this.exportService.exportExcel(this.exportSol, 'Tutores-' + this.aulaname);
    this.exportSol = [];
  }

  checkArrays(ar: any [], pos): any {
    if (ar === null || ar.length === 0) {
      return '';
    } else if (pos === 0) {
      console.log(ar[0]);
      return ar[0];
    } else if (pos === 1) {
      if (ar.length > 1) {
        return ar[1];
      } else {
        return '';
      }
    }
  }

  finalizarAula() {
    swal.fire({
      title: 'FINALIZAR AULA',
      text: '¿Seguro que quiere finalizar este aula?' +
        'El aula se desactivará y la agenda de asignaturas se borrará',
      icon: 'error',
      showCancelButton: true,
      confirmButtonText: 'Si, finalizar',
      cancelButtonText: 'No, Cancelar',
      reverseButtons: true
    })
      .then((result) => {
        if (result.isConfirmed) {
          this._aula.finalizarAula(this.aulaId).subscribe( (res) => {
            this.finalizada = true;

          });
        }
      });
  }

  vaciarAula(): void {
    swal.fire({
      title: 'Vaciar AULA',
      text: '¿Seguro que quiere vaciar este aula? ' +
        'Se vaciará el aula de alumnos, dejandolos asociados a su ciclo pero sin aula asignada',
      icon: 'error',
      showCancelButton: true,
      confirmButtonText: 'Si, vaciar',
      cancelButtonText: 'No, Cancelar',
      reverseButtons: true
    })
      .then((result) => {
        if (result.isConfirmed) {
          this._aula.vaciarAula(this.aulaId).subscribe( (res) => {
            this._aula.getAlumnosAula(this.aulaId);

          });
        }
      });

  }

  transeferirAlumnos(): void {
    const dialogRef = this.dialog.open(BulkModalComponent, { hasBackdrop: true, width: '50%', height: '750px', data: this.alumnosAula });
    dialogRef.afterClosed().subscribe( (result: any) => {
    console.log(result)
      this._aula.getAlumnosAula(this.aulaId);
    });
  }
}
