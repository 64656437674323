<div class="card mb-3">
  <div class="card-header">
    <i class="fa fa-table"></i>
    Seguimiento Empleados
  </div>
  <div>
    <div *ngIf="fichados.length > 0">
      <div class="row">
        <div *ngFor="let user of fichados; let i = index;">
          <div class="cartaFichadaSinCarousel m-2 handClick" [ngClass]="{cartaSinCarousel:(empieza(user.fecha) && user.estado === 'ENTRADA'),
          cartaSinCarouselSalida:(empieza(user.fecha)  && user.estado === 'SALIDA')}">
            <div class="targetaSinCarousel" [ngClass]="{targetaFuera:(empieza(user.fecha)  && user.estado === 'ENTRADA'),
             targetaFueraSalida:(empieza(user.fecha)  && user.estado === 'SALIDA')}" [ngStyle]="{ 'background-image':'url(' + rutaimg + user.img  + ')' }">
            </div>
            <div class="card-body centrado">
              <h6>{{user.nombre | titlecase}}</h6>
              <h6>{{user.apellidos | titlecase}}</h6>
              <p class="card-text">{{user.fecha | date:'HH:mm' | seguimiento: empieza(user.fecha):true}} {{user.estado | seguimiento:
                empieza(user.fecha):false}}
              </p>
              <p *ngIf="user.distancia < radio" class="text-ok"> Distancia: {{user.distancia}} mts</p>
              <p *ngIf="user.distancia > radio" class="text-noOk"> Distancia: {{user.distancia}} mts</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
