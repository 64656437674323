import {
  Component, OnInit, ViewEncapsulation
} from '@angular/core';
import {UsersService} from '../services/users.service';
import {ActivatedRoute} from '@angular/router';
import {MailPipe} from '../../../../pipes/mail.pipe';

@Component({
  selector: 'app-credenciales',
  templateUrl: './credenciales.component.html',
  styleUrls: ['../users.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MailPipe]
})
export class CredencialesComponent implements OnInit {
  public id: number;
  dato: any;
  users: any[] = [];
  email: string;
  editable: boolean;

  constructor (private _user: UsersService, private _route: ActivatedRoute, private mail: MailPipe) {
    this.id = this._route.snapshot.parent.params['id'];
    this._user.getCredenciales(this.id);
  }


  ngOnInit () {
    this._user.getDato(this.id);
    this._user.dato$.subscribe((res: any) => {
      this.dato = res;
    });
    this._user.users$.subscribe(res => {
      this.users = res;
      console.log(this.users);
    });
  }

  setUser (email: any, id: number, index: number) {
    console.log(email , id , index);
    this.email = email;
    const username = this.mail.transform(email);
    if (username) {
      this._user.updateCredentials(email, id).then(res => {
        if (res) {
          this._user.getCredenciales(this.id);
          this.editar(index);
         }
      });
    }

  }

  activar (estado: boolean, index: number) {
    estado = !estado;
    this.users[index].credencial = estado;
    this._user.activar(estado, this.users[index].id);
  }

  editar (index) {
    if (!this.editable) {
      document.getElementById('a_' + index).hidden = true;
      document.getElementById('b_' + index).hidden = false;
    } else {
      document.getElementById('a_' + index).hidden = false;
      document.getElementById('b_' + index).hidden = true;
    }
    this.editable = !this.editable;

  }
}


