<div style="display: block; padding-top: 20px" #containerRef1>
  <div style="text-align: center">
    <p>{{titulo}}</p>
  </div>
  <ngx-charts-pie-chart
    [view]="[containerRef1.offsetWidth, 400]"
    scheme="air"
    [results]="single"



    [labels]="showLabels"
    [labelFormatting]="labelFormat"
    [explodeSlices]="true"

  >
  </ngx-charts-pie-chart>

</div>
