import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ModalCursosIntensivosComponent} from '../../modal/modal-cursos-intensivos/modal-cursos-intensivos.component';
import {DocumentosService} from '../../services/documentos.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cursos-intensivos',
  templateUrl: './cursos-intensivos.component.html',
  styleUrls: ['./cursos-intensivos.component.scss']
})
export class CursosIntensivosComponent implements OnInit {
  dato: {};
  Ce: any[] = [];
  constructor(private dialog: MatDialog, private serivicioCe:  DocumentosService) { }

  ngOnInit(): void {
    this.getCursosExtracurriculares();
  }

  getCursosExtracurriculares(): void {
    this.serivicioCe.getCursoExtracurriculares().subscribe( (resp: any) => {
      this.Ce = resp['id'];
      console.log(this.Ce);
    });
}

  abrir(): void {
    const dialogRef = this.dialog.open(ModalCursosIntensivosComponent,
      { hasBackdrop: true, width: '90%', data: this.dato});
    dialogRef.afterClosed().subscribe( (result) => {
      // console.log(result);
      if (result ) {
        this.serivicioCe.addCursoExtracurricular(result).subscribe( (resp: any) => {
          if (resp['code'] === 200) {
            Swal.fire('', 'Datos Guardados', 'success');
            console.log(resp, 'guardar');
            this.Ce = [];
            this.Ce = resp['id'];
          }
        }, error => {
          Swal.fire('', 'No se pudo editar, se ha generado algun error', 'warning');
          console.log(error);
        });
      }
    });
  }

  editar(index): void {
    console.log(index);
    const dialogRef = this.dialog.open(ModalCursosIntensivosComponent,
      { hasBackdrop: true, width: '90%', data: index});
    dialogRef.afterClosed().subscribe( (result) => {
      // console.log(result);
      if (result ) {
        console.log(result);
        this.serivicioCe.addCursoExtracurricular(result).subscribe( (resp: any) => {
          if (resp['code'] === 200) {
            Swal.fire('', 'Datos Editados', 'success');
            console.log(resp, 'editar');
            this.Ce = [];
            this.Ce = resp['id'];
          }
        }, error => {
          console.log(error);
          Swal.fire('', 'No se pudo editar, se ha generado algun error', 'warning');
        });
      }
    });
  }

  delete(index): void {
    console.log(index);
    Swal.fire({
      title: 'Deseas Eliminarlo?',
      showDenyButton: true,
      reverseButtons: true,
      confirmButtonText: 'Eliminar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.serivicioCe.deleteCursoVerano(index).subscribe( (resp: any) => {
          if (resp['code'] === 200) {
            Swal.fire('', 'Dato Eliminado', 'success');
            console.log(resp, 'editar');
            this.Ce = [];
            this.Ce = resp['id'];
          }
        }, error => {
          console.log(error);
          Swal.fire('', 'No se pudo editar, se ha generado algun error', 'warning');
        });
      } else if (result.isDenied) {
        Swal.fire('', 'No se ha eliminado', 'info')
      }
    })
  }


  estadoCurso(id) {
  this.serivicioCe.getCambiarEstadoCurso(id).subscribe((resp) => {
    console.log(resp);
    if (resp['code'] === 200) {
      Swal.fire('', 'Estado Cambiado', 'success');
      console.log(resp, 'cambiar estado');
      this.Ce = [];
      this.Ce = resp['id'];
    }
  }, error => {
    console.log(error);
    Swal.fire('', 'Error al cambiar Estado del Curso')
  })
  }
}
