<div class="titulo" style="margin-top: 1em">
  <h3 class="titulo-cabecera" *ngIf="curso.length != 0">{{curso[1]}}º {{curso[2]}} {{curso[0]}}</h3>
</div>
<div class="row">
  <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12" style="margin-bottom: 10px; margin-top: 10px">
    <select class="form-control form-control" name="examen" [(ngModel)]="trimestre"
            (change)="cargar($event, pdf2)">
      <option value="def" disabled>Seleccione evaluación ...</option>
      <option value= 1>Primera Evaluación</option>
      <option value= 2>Segunda Evaluación</option>
      <option value= 3>Tercera Evaluación</option>
      <option value= 4>Nota final Curso</option>
    </select>

  </div>
  <div class="boton" style="margin-bottom: 10px; margin-top: 10px"
       *ngIf="datosAlumnos.length != 0">
    <div class="row rows" style="">
      <div ngbDropdown class="d-inline-block">
        <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>Seleccionar Alumno/s</button>
        <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12" style="margin-bottom: 10px; margin-top: 10px"
             *ngIf="datosAlumnos.length != 0">

          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="scrollable-menu">

            <table class="table">
              <tr>
                <th><input type="checkbox" [checked]="check" (change)="toggle()"/></th>
                <th>Todos</th>
              </tr>
              <tr *ngFor="let alumnos of datosAlumnos; let j= index">
                <td><input name="foo" type="checkbox" [checked]="activar" [value]="j"
                           (change)="change(alumnos, $event)"/></td>
                <td>{{alumnos.nombre | titlecase}} {{alumnos.apellidos | titlecase}}</td>
              </tr>
            </table>


          </div>

        </div>
      </div>
    </div>

  </div>
  <div id="boton1" style="margin-bottom: 10px; margin-top: 10px; margin-left: 5px">
    <button type="button" class="btn btn-outline-primary mb-0 obs" [appSort]="imprimir" data-order="asc" data-name="nombre">
      Ordenar por Nombre
    </button>
  </div>
  <div id="boton2" style="margin-bottom: 10px; margin-top: 10px; margin-left: 5px">
    <button type="button" class="btn btn-outline-primary mb-0 obs" [appSort]="imprimir" data-order="asc" data-name="apellidos">
      Ordenar por Apellido
    </button>
  </div>
</div>

<div class="row">
  <div class="card col-lg-4 col-md-4 col-sm-6 col-xs-1" *ngFor="let data of imprimir; let i = index">
    <img class="card-img-top" [src]="data.img | images: ''" alt="Alumno Progresa">
    <div class="card-body row" style="justify-content: center">
      <span class="card-title">{{data.nombre | uppercase}}</span> &nbsp;
      <span class="card-title">{{data.apellidos | uppercase}}</span>
    </div>
    <table class="table table-bordered">
      <thead>
      <tr >
        <th>Asignatura</th>
        <th>Nota</th>
      </tr>
      </thead>
      <tbody class="text-center">
      <tr *ngFor="let nota of imprimir[i].notas; let h = index;">
        <td class="col-lg-9 col-md-9 col-sm-9 col-xs-9"><span>{{nota.asignatura}}</span></td>
        <td class="col-lg-3 col-md-3 col-sm-3 col-xs-3" id="a{{h}}{{i}}">{{nota.nota}}
          <li class="success p-0 list-inline-item" *ngIf="!nota.aa"><i class="ft-edit-2 font-medium-3 mr-2" (click)="editar(h, i)"></i></li>
        </td>
        <td class="col-lg-3 col-md-3 col-sm-3 col-xs-3" id="b{{h}}{{i}}" hidden>
          <input style="text-align: center; margin-right: 1px" type="text" size="2" [(ngModel)]="nota.nota" name="k{{h}}{{i}}"/>
          <li class="success p-0 list-inline-item"><i class="ft-upload-cloud" (click)="add(nota, nota.nota, h, i)"></i></li>
        </td>
      </tr>
      </tbody>
    </table>
    <button type="button" class="btn btn-outline-primary mb-0 obs"  slot="end" (click)="observacion(data)">
      <i class="fa fa-search"> Observación</i>
    </button>
  </div>
</div>

<ng-template #pdf2 let-modal>
  <div class="modal-header" style="text-align: center;">
    <h4 class="modal-title bold">Seleccionar Alumno/s</h4>

  </div>
  <div class="modal-body">
    <table class="table">
      <tr>
        <th><input type="checkbox" [checked]="check" (change)="toggle()"/></th>
        <th>Todos</th>
      </tr>
      <tr *ngFor="let alumnos of datosAlumnos; let j= index">
        <td><input name="foo" type="checkbox" [checked]="activar" [value]="j" (change)="change(alumnos, $event)"/></td>
        <td>{{alumnos.nombre | titlecase}} {{alumnos.apellidos | titlecase}}</td>
      </tr>
    </table>
  </div>
</ng-template>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate" [fullScreen]="true"></ngx-spinner>
