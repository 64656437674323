// Importamos los componentes necesarios
import {Pipe, PipeTransform} from '@angular/core';


// Le ponemos un nombre a la tuberia
@Pipe({name: 'media_nota'})

// Definimos la clase implementado la interface PipeTransform
export class MediaNotaPipe implements PipeTransform {
  transform (notas: any, id: number): any {

    let n = 0;
    let u: any = [];
    let total = 0;
    if (notas) {
      for (let i = 0; i < notas.length; i++) {
        u = notas[i].filter(item => item.id_alumno === id);
        if (u[0]) {
          n = n + 1;
          total = total + u[0].nota;
        }
      }
      const r = total / n;

      if (isNaN(r)) {
        return '-';
      }
      return parseFloat(r + '').toFixed(2);
    }


  }

}
