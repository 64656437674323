import {Injectable, Renderer2} from '@angular/core';

@Injectable()
export class ScreenService {
  screen_width: number;
  screen_heigth: number;

  constructor(private renderer: Renderer2) {
  }

  width_heigth_screen() {
    this.screen_width = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;
    this.screen_heigth = window.innerHeight
      || document.documentElement.clientHeight
      || document.body.clientHeight;
  }


  container_dias_width_heigth(contenedor_dias) {
    this.renderer.setStyle(contenedor_dias.nativeElement, 'width', this.contenedor_width_px() + 'px');
  }


  contenedor_horarios_width_heigth(contenedor_horarios) {
    this.renderer.setStyle(contenedor_horarios.nativeElement, 'width', this.contenedor_width_px() * 0.1 + 'px');
  }

  container_clase_width_heigth(contenedor_clase) {
    this.renderer.setStyle(contenedor_clase.nativeElement, 'width', this.contenedor_width_px() + 'px');
  }
  contenedor_width_px() {
    return Math.ceil(this.screen_width * 0.8);
  }
  contenedor_heigth_px() {
    return Math.ceil(this.screen_heigth * 0.8);
  }

  tamano_tramo(bloques: number) {
    const heigth = this.contenedor_heigth_px();
    let tramo = heigth / bloques;
    if (tramo < 40) {
      tramo = tramo + 10;
    }
    return Math.floor(tramo);
  }
}
