<section id="simple">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="row">
          <div class="card-header col-4">
            <h5 class="card-title">{{datos}}</h5>
          </div>
        </div>
        <div class="row">
          <div class="card-header col-2">
            <select class="form-control" (change)="listado($event)" name="evento" [(ngModel)]="evento">
              <option value="undefined" disabled selected hidden>Select</option>
              <option value=false>Faltas</option>
              <option value=true>Asistencias</option>
            </select>
          </div>

          <div class="card-header col-3">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">Desde</div>
                <input type="date" id="dateFrom" [(ngModel)]="dateFrom">
              </div>
            </div>
          </div>

          <div class="card-header col-3">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">Hasta</div>
                <input type="date" id="dateTo" name="dateTo" [(ngModel)]="dateTo">
              </div>
            </div>
          </div>
        </div>

        <table class="table table-striped" *ngIf="asistencias.length > 0">
          <thead>
          <tr>
            <th scope="col">Estado</th>
            <th scope="col">Nombre</th>
            <th scope="col">Apellidos</th>
            <th scope="col">Fecha</th>
            <th scope="col">Aula</th>
            <th scope="col">Asignatura</th>
            <th scope="col">Acciones</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let asistencia of asistencias; let i = index">
            <ng-template
              [ngIf]="
              (asistencia.fecha.date | date:'yyyy-MM-dd') >= (dateFrom | date:'yyyy-MM-dd')
              &&
              (asistencia.fecha.date | date:'yyyy-MM-dd') <= (dateTo | date:'yyyy-MM-dd')
              &&
              (asistencia.asignatura == [(asignaturaSeleccionada)])">

              <td scope="row"><label>
                <input name="foo" type="checkbox" [checked]="asistencia.estado" [value]="i"
                       (change)="change(i)">
              </label></td>
              <td>{{asistencia.nombre}}</td>
              <td>{{asistencia.apellidos}}</td>
              <td>{{ asistencia.fecha.date | date: "dd-MM-yyyy"}}</td>

              <td>{{asistencia.clase}}</td>
              <td>{{asistencia.asignatura}}</td>
              <td>
                <ul class="list-inline">
                  <li class="list-inline-item success p-0>">
                    <i *ngIf="!asistencia.comentario" class="fa fa-pencil success"
                       [swal]="{ title: 'Comentario', input: 'textarea', 'inputPlaceholder': 'Escribe un comentario' }"
                       (confirm)="saveComentario($event, asistencia.id, i)">
                    </i>
                  </li>
                  <li *ngIf="asistencia.comentario" class="list-inline-item success p-0">
                    <i class="fa fa-comments primary"
                       [swal]="{ title: 'Comentario', input: 'textarea','inputValue': asistencia.comentario }"
                       (confirm)="saveComentario($event, asistencia.id, i)">
                    </i>
                  <li *ngIf="asistencia.comentario" class="list-inline-item success p-0">
                    <i class="fa fa-remove danger"
                       (click)="deleteComentario(i)">
                    </i>
                  </li>
                </ul>
              </td>
            </ng-template>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>
