<!--Simple Table Starts-->
<section id="simple">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Listado de Parciales {{aula}}</h4>
        </div>
        <div class="card-body">
          <div class="card-block">
            <table class="table table-striped">
              <thead>
              <tr>
                <th scope="col">Parcial</th>
                <th scope="col">Tipo</th>
                <th scope="col">Asignatura</th>
                <th scope="col">Activado</th>
                <th scope="col">Acciones</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let examen  of examenes; let i = index">
                <td>{{examen.examen}}</td>
                <td>{{examen.tipo}}</td>
                <td>{{examen.asignatura}}</td>
                <td>

                  <i (click)="activar(examen, i)" [ngClass]="{'ft-slash danger': !examen.estado, 'ft-check success': examen.estado}"></i>
                </td>
                <td>
                  <ul class="list-inline">
                    <li class="list-inline-item danger p-0">
                      <button mat-button (click)="delete(examen.id)">ELIMINAR</button>

                    </li>
                    <li class="success p-0 list-inline-item" >
                      <button mat-button (click)="ver(examen.id)">VER</button>

                    </li>


                  </ul>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
