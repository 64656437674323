import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HijosPadreService } from '../services/hijos-padre.service';
import { Subscription } from 'rxjs/Subscription';


@Component({
  selector: 'app-padre-asistencias',
  templateUrl: './padre-asistencias.component.html',
  styleUrls: ['../hijos-padre.component.scss']
})
export class PadreAsistenciasComponent implements OnInit, OnDestroy {
  asistenciasSubscriber = new Subscription();
  asistencias: any[] = [];
  constructor(private _router: ActivatedRoute, private _hijo: HijosPadreService) {
    let id: number;
    this._router.parent.params.subscribe(params => {
      if (params['id']) {
        id = params['id'];
      }
      this._hijo.getAsistencias(id);
    });
  }
  ngOnInit() {
    this.asistenciasSubscriber = this._hijo.asistencias$.subscribe(res => {
      this.asistencias = res;
    });
  }
  ngOnDestroy() {
    this.asistenciasSubscriber.unsubscribe();
  }
}
