<h2 mat-dialog-title class="text-center">Añadir Contacto Alquiler</h2>
<mat-dialog-content>
  <form [formGroup]="datosAlquiler" (ngSubmit)="guardar()" autocomplete="off">
    <div class="form-body">
      <div class="row">
        <div class="col-md-3">
          <label class="form-label">Nombre</label>
          <mat-form-field appearance="outline" class="col-md-12">
            <input matInput matNativeControl type="text" formControlName="contactoNombre">
          </mat-form-field>
        </div>

        <div class="col-md-3">
          <label class="form-label">Apellido</label>
          <mat-form-field appearance="outline" class="col-md-12">
            <input matInput matNativeControl type="text" formControlName="contactoApellidos">
          </mat-form-field>
        </div>

        <div class="col-md-4">
          <label class="form-label">Empresa (Razon Social)
            <i class="ft-help-circle"
               matTooltip="A completar en caso de realizar el alquiler, en caso de tratarse de particular o autonomo dejar en blanco.">
            </i>
          </label>
          <mat-form-field appearance="outline" class="col-md-12">
            <input matInput matNativeControl type="text" formControlName="empresa">
          </mat-form-field>
        </div>

        <div class="col-md-2">
          <label class="form-label">CIF/NIF
            <i class="ft-help-circle"
               matTooltip="A completar en caso de realizar el alquiler">
            </i>
          </label>
          <mat-form-field appearance="outline" class="col-md-12">
            <input matInput matNativeControl type="text" formControlName="cifNif">
          </mat-form-field>
        </div>

        <div class="col-md-3">
          <label class="form-label">Teléfono</label>
          <mat-form-field appearance="outline" class="col-md-12">
            <input matInput matNativeControl type="text" formControlName="telefono">
          </mat-form-field>
        </div>

        <div class="col-md-3">
          <label class="form-label">Email</label>
          <mat-form-field appearance="outline" class="col-md-12">
            <input matInput matNativeControl type="text" formControlName="email">
          </mat-form-field>
        </div>

        <div class="col-md-3">
          <label class="form-label">Sitio web</label>
          <mat-form-field appearance="outline" class="col-md-12">
            <input matInput matNativeControl type="text" formControlName="web">
          </mat-form-field>
        </div>

        <div class="col-md-3">
          <label class="form-label">Estado</label>
          <mat-form-field appearance="outline" class="col-md-12">
            <select matInput matNativeControl formControlName="estado">
              <option label="--Seleccionar--"></option>
              <option *ngFor="let stat of estados" [ngValue]="stat.id">{{stat.descripcion}}</option>
            </select>
          </mat-form-field>
        </div>

        <div class="col-md-3">
          <label class="form-label">Referido</label>
          <button type="button" class="btn success"><i class="ft-plus-circle" (click)="addReferido()"></i></button>
          <mat-form-field appearance="outline" class="col-md-12">
            <select matInput matNativeControl formControlName="referido">
              <option label="--Seleccionar--"></option>
              <option *ngFor="let ref of referidos" [ngValue]="ref.id">{{ref.descripcion}}</option>
            </select>
          </mat-form-field>
        </div>

        <div class="col-md-3">
          <label class="form-label">Medio de contacto</label>
          <button type="button" class="btn success"><i class="ft-plus-circle" (click)="addFormaContacto()"></i>
          </button>
          <mat-form-field appearance="outline" class="col-md-12">
            <select matInput matNativeControl formControlName="formaContacto">
              <option label="--Seleccionar--"></option>
              <option *ngFor="let con of formaContacto" [ngValue]="con.id">{{con.forma_contacto}}</option>
            </select>
          </mat-form-field>
        </div>

        <div class="col-md-6">
          <label class="form-label">Comentario</label>
          <mat-form-field appearance="outline" class="col-md-12">
            <textarea matInput matNativeControl type="text" rows="4" formControlName="observaciones"></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="form-actions row">
      <div class="col-6">
        <button type="button" class="btn btn-raised btn-secondary float-right" (click)="cancelar()">
          <i class="ft-x"></i> Cancelar
        </button>
      </div>
      <div class="col-6">
        <button type="submit" class="btn btn-raised btn-primary float-left" [disabled]="!datosAlquiler.valid">
          <i class="ft-save"></i> Guardar
        </button>
      </div>
    </div>
  </form>
</mat-dialog-content>

