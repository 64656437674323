<h4 class="info-top"><i class="ft-user"></i> Observaciones Internas: {{dato?.nombre}} {{dato?.apellidos}}</h4>
<div class="row">
  <div class="col-lg-12 col-md-12 col-xs-12">
    <form class="form  form-horizontal striped-rows form-bordered" [formGroup]="Observaciones" (ngSubmit)="onSubmit()" autocomplete="off">
      <div class="form-body">
        <div class="form-group row">
          <label class="col-md-3 label-control">Observación</label>
          <div class="col-md-5">
            <textarea class="form-control width-input" name="observacion" placeholder="Observación" rows="4" formControlName="obs"></textarea>
          </div>
          <div class="col-md-4">
            <button  type="submit" class="btn btn-raised btn-success">Guardar</button>
          </div>
        </div>
      </div>
    </form>

    <div class="example-container mat-elevation-z8" id="tabla">

      <mat-table [dataSource]="dataSource" matSort matSortActive="fecha" matSortDirection="asc">

        <!-- Descripcion Column -->
        <ng-container matColumnDef="observacion">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Observación </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.observacion}} </mat-cell>
        </ng-container>

        <!-- Fecha Column -->
        <ng-container matColumnDef="fecha">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.fecha}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;">
        </mat-row>
      </mat-table>

      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>

  </div>
</div>
