import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {AdminAlumnoService} from '../services/admin-alumno.service';
import {UploadService} from '../../../services/upload.service';
import {RutasImg} from '../../../../utiles/Rutas';



@Component({
  selector: 'app-alumnos-task',
  templateUrl: './alumno-task.component.html',
  styleUrls: ['../alumno-asignaturas.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AlumnoTaskComponent implements OnInit, OnDestroy {
  public tasks: Array<any> = [];
  public tasksSubscription: Subscription = null;
  public task = {
    files : []
  };
  public id: number;
  public type: string;
  public order: boolean;
  public ver_: boolean;
  public index: number;
  public files: any[] = [];
  public filesToUpload: Array<File>;
  public asignatura: number;

  constructor (private _route: ActivatedRoute, private _router: Router, private _admin: AdminAlumnoService,
               private _upload: UploadService) {
    this.asignatura = parseInt(this._route.snapshot.parent.params['asignatura'], 10) ;
    this._admin.getTaskAsignatura(this.asignatura);
  }
  ngOnInit () {
    this.tasksSubscription = this._admin.datos$.subscribe(res => {
      this.tasks = res;
    });
  }
  ngOnDestroy () {
    this.tasksSubscription.unsubscribe();
  }

  deleteFile (id: number, task_id: number) {
    this._admin.deleteFile(id);
    this.tasks.forEach((value, index) => {
      if (value.id === task_id) {
        this.tasks[index].files.forEach((v, i) => {
          if (v.id === id) {
            this.tasks[index].files.splice(i, 1);
          }
        });
      }
    });

  }

  searchArray (valor: string) {
    const array = ['todo', 'inProcess', 'completed'];
    for (let i = 0; i < array.length; i++) {
      if (array[i] === valor) {
        const retorno = i + 1;
        if (retorno > 2) {
          return array[0];
        }
        return array[retorno];
      }
    }
  }

  ordenar (type: string) {
    this.order = !this.order;
    this._admin.getTasks(this.order, type);
  }

  ver (index: number) {

    this.tasks[index].index = index;
    this.task = this.tasks[index];
    this.ver_ = true;
    this.index = index;
  }

  verFile (index: number) {
    window.open(RutasImg + this.tasks[index].taskFile);
  }
  changeEstado (index: number, es: string) {
    const estado = this.searchArray(es);
    const id = this.tasks[index].id;
    this.tasks[index].status = estado;
    this.task = this.tasks[index];
    this._admin.changeStatus(estado, id);
  }

  openViewer () {
    document.getElementById('buscadorPersona').click();
  }

  addTaskFile () {
    const id = this.tasks[this.index].id;
    this._upload
      .makeFileRequest(id, this.filesToUpload, 'imagen', 'task_alumno')
      .then(
        (result: any) => {
          this._admin.getTask(id);
          this._admin.task$.subscribe(res => {
            this.task.files = res;
          });
        },
        error => {
          console.log(error);
        }
      );
  }

  fileChangeEvent (fileinput: any) {
    this.filesToUpload = <Array<File>>fileinput.target.files;
    this.addTaskFile();
  }
}

