<div class="row">
  <div class="col-xl-6">
    <div class="row">
      <form #f="ngForm" (ngSubmit)="onSubmit(f.value); f.reset()">
        <div class="col-12">
          <div class="form-group">
            <input type="text" class="form-control" name="evento" [(ngModel)]="evento.evento" [value]=""
                   placeholder="Nombre del calendario" required>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <label class="col-xs-3 col-form-label">Fecha Inicio</label>
                <div class="col-xs-9">
                  <ngb-datepicker name="inicio" [(ngModel)]="evento.inicio" required #dpInicio
                                  (select)="onDateSelection($event)"
                                  [startDate]="evento.inicio">
                  </ngb-datepicker>
                </div>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <label class="col-xs-3 col-form-label">Fecha Fin</label>
                <div class="col-xs-9">
                  <ngb-datepicker name="fin" [(ngModel)]="evento.fin" required #dpFin
                                  (select)="onDateSelection($event)"
                                  [startDate]="evento.fin">
                  </ngb-datepicker>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4"></div>
        <div class="form-group col-6 ">
          <div class="offset-xs-3 col-xs-9">
            <!--            <button [disabled]="f.invalid" type="submit" class="btn btn-default">Submit</button>-->
            <button type="submit" class="btn btn-raised btn-primary mr-2" [disabled]="f.invalid" title="Guardar">
              <i class="ft-save"></i> Guardar
            </button>
            <span>
              <button class="btn btn-raised btn-danger" title="Cancelar Editar" *ngIf="indexEdit !== -1"
                      (click)="cancelEdit()">
                <i class="ft-x"></i> Cancelar
              </button>
            </span>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="col-xl-6">
    <table class="table table-striped">
      <thead>
      <th>Calendario</th>
      <th>Inicio</th>
      <th>Fin</th>
      <th>Editar</th>
      </thead>
      <tbody>
      <tr *ngFor="let temporada of eventoCurso; let i = index" [ngClass]="{'selectedRow': i == indexEdit}">
        <td>{{temporada.evento}}</td>
        <td>{{temporada.inicio.date | date: 'dd/MM/yyyy' }}</td>
        <td>{{temporada.fin.date | date: 'dd/MM/yyyy' }}</td>
        <td>
          <a (click)="eliminar(i)" title="Eliminar">
            <i class="ft-x font-medium-3 mr-2 danger"></i>
          </a>
          <a (click)="editar(i)" title="Editar">
            <i class="ft-edit-2 font-medium-3 success"></i>
          </a>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>










