<!--Error page starts-->
<section id="error">
    <div class="container-fluid bg-grey bg-lighten-3">
        <div class="container">
            <div class="row full-height-vh">
                <div class="col-md-12 col-lg-3 ml-auto d-flex align-items-center">
                    <div class="row text-center mb-3">
                        <div class="col-12">
                            <img src="assets/img/portrait/avatars/avatar-10.png" alt="User" width="300">
                        </div>
                        <div class="col-12">
                            <h4 class="grey darken-2 font-large-5">Opps...</h4>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-8 d-flex align-items-center justify-content-center">
                    <div class="error-container">
                        <div class="no-border">
                            <div class="text-center text-bold-700 grey darken-2 mt-5" style="font-size: 11rem; margin-bottom: 4rem;">404</div>
                        </div>
                        <div class="error-body">
                            <fieldset class="row py-2">
                                <div class="input-group col-12">
                                    <input type="text" class="form-control " placeholder="Search..." aria-describedby="button-addon2">
                                    <span class="input-group-btn" id="button-addon2">
                                    <a><i class="ft-search"></i></a>
                                    </span>
                                </div>
                            </fieldset>
                            <div class="row py-2 justify-content-center">
                                <div class="col-8">
                                    <a class="btn btn-brown btn-raised btn-block font-medium-2"><i class="ft-home"></i> Back to Home</a>
                                </div>
                            </div>
                        </div>
                        <div class="error-footer bg-transparent">
                            <div class="row">
                                <p class="text-muted text-center col-12 py-1">© 2017 <a>Apex </a>Crafted with <i class="ft-heart font-small-3"></i> by <a href="http://themeforest.net/user/pixinvent/portfolio" target="_blank">PIXINVENT</a></p>
                                <div class="col-12 text-center">
                                    <a class="btn btn-social-icon mr-1 mb-1 btn-facebook">
                                    <span class="fa fa-facebook"></span>
                                    </a>
                                    <a class="btn btn-social-icon mr-1 mb-1 btn-twitter">
                                    <span class="fa fa-twitter"></span>
                                    </a>
                                    <a class="btn btn-social-icon mr-1 mb-1 btn-linkedin">
                                    <span class="fa fa-linkedin"></span>
                                    </a>
                                    <a class="btn btn-social-icon mr-1 mb-1 btn-github">
                                    <span class="fa fa-github"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Error page ends-->