import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';
import {AlumnosService} from '../../maestro/admin-alumnos/services/alumnos.service';
import {ActivatedRoute} from '@angular/router';
import {DatePipe} from '@angular/common';
import {AsistenciasService} from '../../maestro/admin-asistencias/services/asistencias.service';
import {LoginService} from '../../pages/services/login.service';
import {ExcelService} from '../excelExport/excel.service';
import Swal from 'sweetalert2';
import {ImagesPipe} from '../../../pipes/images.pipe';
import {UploadService} from '../../services/upload.service';
import {Rutas} from '../../../utiles/Rutas';

@Component({
  selector: 'app-asistencias',
  templateUrl: './asistencias.component.html',
  styleUrls: ['./asistencias.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AsistenciasComponent implements OnDestroy, OnInit {

  id: number;
  fecha: Date;
  asistenciasYfaltas: any[] = [];
  asistencias: any[] = [];
  faltas: any[] = [];
  asistenciasYfaltasExport: any[] = [];
  alumno: any;

  // AQUÍ SE DEFINE LA SUBSCRIPCION
  asistenciasYfaltasSubscription: Subscription = new Subscription();
  asistenciasSubscription: Subscription = new Subscription();
  faltasSubscription: Subscription = new Subscription();
  alumnoSubcription: Subscription = new Subscription();

  datos = '';
  evento = false;

  // Fechas
  fechas = new Date;

  // Para saber el mes actual
  mes = this.fechas.getMonth();
  // Para saber el año actual
  anyo = this.fechas.getFullYear();

  // Esto cogera el dia 1 del mes y año actual
  dateFrom = new Date(this.anyo, this.mes, 1);
  // Esto cogera el ultimo dia del mes y año actual
  dateTo = new Date(this.anyo, this.mes + 1, 0);

  // Asignaturas
  arrayAsignaturas: any[] = [];
  contador = 0;

  roll = '';
  // En esta variable guardamos la asignatura seleccionada
  asignaturaSelect = 'all';

  constructor(private _alumno: AlumnosService,
              private _route: ActivatedRoute,
              private _date: DatePipe,
              private _asistencia: AsistenciasService,
              private _login: LoginService,
              private exportService: ExcelService,
              private upload: UploadService) {
  }

  ngOnInit() {
    this.roll = this._login.getIdentity().role;
    this.id = this._route.snapshot.parent.params['id'];
    if (this.id == null) {
      this.id = this._login.getIdentity().id;
      console.log(this.id);
    }
    this.getAsistenciasYfaltas();
    this.getAsistencias();
    this.getFaltas();
    this.getAlumno();
  }

  export() {
    // Faltas
    for (const a of this.faltas) {
      this.asistenciasYfaltasExport.push({
        Nombre: a.nombre,
        Apellidos: a.apellidos,
        Fecha: new Date(a.fecha.date),
        Clase: a.clase,
        Asignatura: a.asignatura,
        Estado: 'Falta'
      });
    }

    // Asistencia
    for (const a of this.asistencias) {
      this.asistenciasYfaltasExport.push({
        Nombre: a.nombre,
        Apellidos: a.apellidos,
        Fecha: new Date(a.fecha.date),
        Clase: a.clase,
        Asignatura: a.asignatura,
        Estado: 'Asistencia'
      });
    }

    this.exportService.exportExcel(this.asistenciasYfaltasExport, 'Exportacion');
    this.asistenciasYfaltasExport = [];
  }

  // GET DATOS
  getAsistenciasYfaltas() {
    this._alumno.getAsistenciasYfaltas(this.id, this.evento);
    this.cargarAsistenciasYfaltas();
  }

  getAsistencias() {
    this._alumno.getAsistencias(this.id);
    this.cargarAsistencias();
  }
  getAlumno() {
    this._alumno.getAlumno(this.id);
    this.cargarAlumno();
  }

  getFaltas() {
    this._alumno.getFaltas(this.id);
    this.cargarFaltas();
  }

  listado(evento) {
    if ( evento.target.value === 'true') {
      this.evento = true;
    } else {
      if (evento.target.value === 'false') {
        this.evento = false;
      }
    }
    this._alumno.getAsistenciasYfaltas(this.id, evento.target.value);
  }
  // ***********************************

  // PUSH DATOS
  cargarAsistenciasYfaltas() {
    this.asistenciasYfaltasSubscription = this._alumno.asistenciasYfaltas$.subscribe(res => {
      this.asistenciasYfaltas = res;
      this.quitarDuplicados();
    });
  }

  cargarAsistencias() {
    this.asistenciasSubscription = this._alumno.asistencias$.subscribe(res => {
      this.asistencias = res;
    });
  }
  cargarAlumno() {
    this.alumnoSubcription = this._alumno.alumno$.subscribe(res => {
      this.alumno = res.dato;
      this.datos = this.alumno.nombre + ' ' + this.alumno.apellidos;
    });
  }

  cargarFaltas() {
    this.faltasSubscription = this._alumno.faltas$.subscribe(res => {
      this.faltas = res;
    });
  }
  // ***********************************

  // Con este metodo añado todas las asignaturas en el array "arrayAsignaturas", quitando las asignaturas duplicadas
  quitarDuplicados() {
    for (const asist of this.asistenciasYfaltas) {
      for (let i = 0; i <= this.arrayAsignaturas.length; i++) {
        if (asist.asignatura === this.arrayAsignaturas[i]) {
          this.contador++;
        }
      }
      if (this.contador === 0) {
        this.arrayAsignaturas.push(asist.asignatura);
      }
      this.contador = 0;
    }
  }

  // Para guardar un comentario
  saveComentario(comentario, id, i) {
    console.log(id);
    if (comentario) {
      this.asistenciasYfaltas[i].comentario = comentario;
      this._asistencia.addComentario(comentario, id);
      return;
    }
  }

  // Para eliminarun comentario
  deleteComentario(index) {
    this.asistenciasYfaltas[index].comentario = '';
    this._asistencia.deleteComentario(this.asistenciasYfaltas[index].id);
  }

  // Para saber si es falta o asistencia
  change(position: number) {
    const estado = !this.asistenciasYfaltas[position].estado;
    this.asistenciasYfaltas[position].estado = !this.asistenciasYfaltas[position].estado;
    const id = this.asistenciasYfaltas[position].id;
    this._asistencia.update(estado, id);
  }

  verjustificante(i) {
    const imgi = this.asistenciasYfaltas[i].img;
    window.open(Rutas + imgi);
  }


  fileChangeEvent(event, asistencia, i) {
    const formData = new FormData();
    const file = event.target.files[0];
    const json = {
      id: asistencia.id,
      id_user: this.id
    };
    console.log(json);
    formData.append('json', JSON.stringify(json));
    formData.append('file', file);
    formData.append('token', localStorage.getItem('token'));
   this.upload.makeFile(formData)
      .subscribe((result: any) => {
          this.asistenciasYfaltas[i].img = result.id;
          this.verjustificante(i);
        }, (error) => {
          console.log(error);
        }
      );
  }

  ngOnDestroy(): void {
    this.asistenciasYfaltasSubscription.unsubscribe();
    this.asistenciasSubscription.unsubscribe();
    this.faltasSubscription.unsubscribe();
    this.alumnoSubcription.unsubscribe();
  }

  abrirInput(i) {
    document.getElementById('file-input' + i).click();
  }
}
