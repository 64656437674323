import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Rutas} from '../../../../utiles/Rutas';

@Injectable({
  providedIn: 'root'
})
export class AlquilerService {

  constructor(private http: HttpClient) {}


  getSelects() {
    return this.http.get(Rutas + 'alquiler/all_selects?token=' + localStorage.getItem('token'));
  }

  getData() {
    return this.http.get(Rutas + 'get/all_contactos_alquiler?token=' + localStorage.getItem('token'));
  }

  addContacto(form) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const data = JSON.stringify(form);
    console.log(data);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    return this.http.post(Rutas + 'alquiler/add_contacto', params, {headers: headers});
  }

  updateEstado(contacto: number, estado: number) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const data = JSON.stringify({contacto, estado});
    console.log(data);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    return this.http.post(Rutas + 'alquiler/update_estado', params, {headers: headers});
  }

  addLinea(observacion, contacto) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const data = JSON.stringify({observacion, contacto});
    console.log(data);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    return this.http.post(Rutas + 'alquiler/add_linea', params, {headers: headers});
  }

  addReferido(referido) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const data = JSON.stringify({referido});
    console.log(data);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    return this.http.post(Rutas + 'add/referido_matricula', params, {headers: headers});
  }

  addMedio(medio) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const data = JSON.stringify({medio});
    console.log(data);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    return this.http.post(Rutas + 'add/medio_comercial', params, {headers: headers});
  }

  getAlquilables() {
    return this.http.get(Rutas + 'get/alquilables?token=' + localStorage.getItem('token'));
  }

  getInfoContactos() {
    return this.http.get(Rutas + 'get/info_contactos?token=' + localStorage.getItem('token'));
  }

  addAlquiler(alquiler) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const data = JSON.stringify(alquiler);
    console.log(data);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    return this.http.post(Rutas + 'alquiler/add_new', params, {headers: headers});
  }
}
