import {Component, OnInit, OnDestroy, ChangeDetectorRef, AfterViewChecked} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AulasService} from '../services/aulas.service';
import {ActivatedRoute} from '@angular/router';
import {TitleCasePipe} from '@angular/common';
import {DatePipe} from '@angular/common';
import {Subscription} from 'rxjs';


import {NgxSpinnerService} from 'ngx-spinner';
import {Rutas} from '../../../../utiles/Rutas';


@Component({
  selector: 'app-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.scss']
})
export class PdfComponent implements OnInit, OnDestroy, AfterViewChecked {
  closeResult: string;
  date: any;
  Trimestre = 'def';
  datosCurso: any[] = [];
  datosAlumnos: any[] = [];
  datosCentro: any[] = [];
  imprimir: any[] = [];
  id: any;
  activar = false;
  check: boolean;
  loader = false;
  curso: any = {};

  datosCursosSubscription = new Subscription();
  datosAlumnosSubscription = new Subscription();
  datosCentroSubscription = new Subscription();
  cursoSubscription = new Subscription();

  // tslint:disable-next-line:max-line-length
  constructor(private titlecasePipe: TitleCasePipe, private dates: DatePipe, private _route: ActivatedRoute,
              private service: AulasService, private modalService: NgbModal, private cdr: ChangeDetectorRef,
              private spinner: NgxSpinnerService
  ) {
    this.id = this._route.snapshot.parent.params['id'];
    this.service.getCurso(this.id);
    this.service.getCursoBoletin(this.id);
    this.service.getCentro();
  }


  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  ngOnInit() {
    this.getDatosCursos();
    this.getCentro();
    this.getCurso();
  }

  ngOnDestroy() {
    this.datosAlumnosSubscription.unsubscribe();
    this.datosCursosSubscription.unsubscribe();
    this.datosCentroSubscription.unsubscribe();
    this.cursoSubscription.unsubscribe();
  }

  getDatosCursos() {
    this.datosCursosSubscription = this.service.cursoBoletin$.subscribe(res => {
      this.datosCurso = res;

    });
  }

  getCurso() {
    this.cursoSubscription = this.service.curso$.subscribe(res => {
      console.log(res);
      this.curso = res;
    });

  }

  getCentro() {
    this.datosCentroSubscription = this.service.centro$.subscribe(res => {
      this.datosCentro = res;
    });
  }

  cargar(event, content) {
    this.spinner.show();
    if (this.Trimestre !== 'def') {
      this.service.getBoletin(this.id, this.Trimestre).subscribe(data => {
        if (data['code'] === 200) {
          this.imprimir = [];
          this.datosAlumnos = data['id'];
          console.log(this.datosAlumnos);
          event.srcElement.blur();
          event.preventDefault();
          this.spinner.hide();
        } else {
          console.log(data['id']);
          return false;
        }
      });
    }
  }

  open2(content) {
    console.log(content);

    this.modalService.open(content, {size: 'lg', scrollable: true});
  }

  lanzarPdf() {
    this.modalService.dismissAll();
    const elements: any[] = [];
    let contador = 0;
    const fecha = this.dates.transform(this.date, 'longDate');
    console.log(fecha);
    for (const c of this.imprimir) {

      const element = document.getElementById(contador.toString()).innerHTML;
      elements.push(element.replace('\"', '\\' + '\"'));
      contador++;
    }
    console.log(elements[1]);
    this.service.generateBoletin(elements, fecha);
  }

  toggle() {
    console.log('Toggle');
    this.check = !this.check;
    const checkboxes: any = document.getElementsByName('foo');
    for (let i = 0, n = checkboxes.length; i < n; i++) {
      checkboxes[i].checked = this.check;
    }
    if (this.check) {
      this.imprimir = this.datosAlumnos.slice(0);
    } else {
      this.imprimir = [];
    }


  }

  change(alumno, event) {
    console.log(event.target.checked);
    const fecha = this.date.da
    if (event.target.checked) {

      this.imprimir.push(alumno);
      console.log(this.imprimir);
    } else {

      this.imprimir.forEach((object, index) => {
        if (object.id === alumno.id) {
          this.imprimir.splice(index, 1);
        }
      });
      console.log(this.datosAlumnos);
      console.log(this.imprimir);
    }
  }

  navegar() {
    window.open(Rutas + 'boletin.pdf');
  }
}
