<nav mat-tab-nav-bar>
    <a mat-tab-link *ngFor="let link of navLinks" [routerLink]="link.path" routerLinkActive #rla="routerLinkActive" [active]="rla.isActive">
          {{link.label}}
        </a>
</nav>
<br>
<p style="margin-left: 2em">{{aula}}</p>

<div class="container">
    <router-outlet></router-outlet>
</div>
