import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Subscription} from 'rxjs/Subscription';
import {UsersService} from '../../admin-users/services/users.service';
import {AulaService} from '../services/aula.service';

@Component({
  selector: 'app-bulk-modal',
  templateUrl: './bulk-modal.component.html',
  styleUrls: ['./bulk-modal.component.scss']
})
export class BulkModalComponent implements OnInit {

  check: boolean;
  alumnos = [];
  alumnosName = [];
  activar = false;
  cursos  = [];
  aula : any;
  aulas: any = [];
  public cursosSubscription: Subscription = null;
  constructor(private dialogRef: MatDialogRef<BulkModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any,  private _user: UsersService, private aulaService: AulaService) {
    this._user.getCursosCentro();
  }

  ngOnInit(): void {
    console.log(this.data);
    this.cursosSubscription = this._user.cursos$.subscribe(res => {
      this.cursos = res;
    });
  }

  toggle() {
    console.log('Toggle');
    this.check = !this.check;
    const checkboxes: any = document.getElementsByName('foo');
    for (let i = 0, n = checkboxes.length; i < n; i++) {
      checkboxes[i].checked = this.check;
    }
    if (this.check) {
      for ( let i = 0; i < this.data.length; i++) {
        this.alumnos.push(this.data[i].id);
        this.alumnosName.push(this.data[i]);
       }
    } else {
      this.alumnos = [];
      this.alumnosName = [];
    }
    console.log(this.alumnos);


  }

  change(alumno, event) {
    console.log(event.target.checked);
    if (event.target.checked) {
      this.alumnos.push(alumno.id);
      this.alumnosName.push(alumno);

    } else {

      for ( let i = 0; i < this.alumnos.length; i++) {

        if ( this.alumnos[i] === alumno.id) {

          this.alumnos.splice(i, 1);
          this.alumnosName.splice(i, 1);
        }}


    }
    console.log(this.alumnos);
  }

  getAulasP($event) {

    this._user.getAulasCiclo($event.target.value).subscribe(data => {
      console.log(data);
      this.aulas = data;
    });
  }

  moveAlumnos(): void {
    console.log(this.aula);
    this.aulaService.moveAlumnosAula(this.aula, this.alumnos).subscribe( res => {
      console.log(res);
      this.dialogRef.close(true);
    });
  }

}
