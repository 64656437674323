
<div class="titulo" style="margin-top: 1em">
  <h3 class="titulo-cabecera" *ngIf="!curso.isEmpty">{{curso.anyo_curso}}º {{curso.acronimo}} {{curso.aula}} - {{curso.curso}}</h3>
</div>
<div class="row">
  <mat-form-field class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
    <mat-label>Seleccionar Fecha Entrega</mat-label>
    <input matInput readonly [matDatepicker]="picker" placeholder="Seleccionar Fecha Entrega" [(ngModel)]="date">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12" style="margin-bottom: 10px; margin-top: 10px" *ngIf="date != null">
    <select class="form-control form-control" name="examen" [(ngModel)]="Trimestre"
            (change)="cargar($event, pdf2)">
      <option value="def" disabled>Seleccione evaluación ...</option>
      <option value="1">Primera Evaluación</option>
      <option value="2">Segunda Evaluación</option>
      <option value="3">Tercera Evaluación</option>
      <option value="4">Nota Final curso</option>
    </select>

  </div>
  <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12" style="margin-bottom: 10px; margin-top: 10px"
       *ngIf="datosAlumnos.length != 0">
    <div class="row rows" style="">
      <div ngbDropdown class="d-inline-block">
        <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>Seleccionar Alumno/s</button>
        <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12" style="margin-bottom: 10px; margin-top: 10px"
             *ngIf="datosAlumnos.length != 0">

          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="scrollable-menu">

            <table class="table">
              <tr>
                <th><input type="checkbox" [checked]="check" (change)="toggle()"/></th>
                <th>Todos</th>
              </tr>
              <tr *ngFor="let alumnos of datosAlumnos; let j= index">
                <td><input name="foo" type="checkbox" [checked]="activar" [value]="j"
                           (change)="change(alumnos, $event)"/></td>
                <td>{{alumnos.nombre | titlecase}} {{alumnos.apellidos | titlecase}}</td>
              </tr>
            </table>


          </div>

        </div>
      </div>
      <button type="button" class="btn btn-outline-danger mb-0"  slot="end" (click)="open2(Trimestre === '4'? pdf : pdfC)">
        <i class="fa fa-file-pdf-o"></i>
      </button>

    </div>

  </div>
</div>


<ng-template #pdfC let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Boletines de Notas</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div id="pdfgenerate" class="modal-body">
    <div *ngFor="let data of imprimir; let i = index">
      <div id="{{i}}">
        <div ><table width="100%">
          <tr>
            <td width="33%"><img class="imageProgresa" src="images/centro/Progresa.png" alt=""></td>
            <td width="33%" align="center"></td>
            <td width="33%" style="text-align: right;"><img class="imagenGeneralitat" src="images/centro/Generalitat.png" alt=""></td>
          </tr>
        </table>
        </div>
        <div class="row titul">
          <div class="col-12" >
            <p class="titulo">BOLETIN DE NOTAS</p>
          </div>
          <div class="col-12">
            <p class="parrafo1">D./Dª. Andrea Sanchez Laserna, secretario/a de {{datosCentro.razon_social}}.<br>
              <br>CERTIFICA: Que el alumno {{data.apellidos | titlecase}}, {{data.nombre | titlecase}} natural de
               {{data.municipio}} provincia de {{data.provincia}} nacido el {{data.fecha  | date:'shortDate'}},
              ha cursado en el centro {{datosCentro.razon_social | titlecase}}, con las calificaciones y en los cursos
              que a continuación se expresan los siguientes estudios de FORMACIÓN PROFESIONAL ESPECÍFICA (Ciclo
              Formativo de Grado {{datosCurso[0].nivel === 'Sistemas Microinformáticos y Redes (LOE) - SMR' ? 'Medio' : 'Superior'}}).<br><br>
              Familia: {{datosCurso[0].modulo | titlecase}}.
            </p>
          </div>
          <div class="col-12">
            <p class="tituloTabla">Curso: {{datosCurso[0].familia}}. {{datosCurso[0].nivel}}.</p>
          </div>
          <div class="col-12">
            <table class="table table-bordered">
              <thead>
              <tr >
                <th class="col-2 th1 aa" rowspan="2"  size="2">AÑO</th>
                <th class="col-1 th1 bb" rowspan="2" size="1">CURSO</th>
                <th class="col-6 th1 cc" rowspan="2" size="6">MATERIAS</th>
                <th class="col-1 th1"  colspan="3" size="3">EVALUACIONES</th>

              </tr>
              <tr >

                <th class="col-1 th1 bb" size="1">PRIMERA</th>
                <th class="col-1 th1 bb" size="1">SEGUNDA</th>
                <th class="col-1 th1 bb" size="1">TERCERA</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let nota of imprimir[i].notas">
                <td class="col-2 td1" size="2">{{datosCurso[0].curso}}-{{datosCurso[0].curso + 1}}</td>
                <td class="col-1 td1" size="1" >{{datosCurso[0].anyo_curso}}</td>
                <td class="col-6  asig1" size="6">{{nota.asignatura}}</td>
                <td class="col-1 td1" size="1" *ngIf="!nota.aa">{{nota.nota}}</td>
                <td class="col-1 td1" size="1" *ngIf="nota.aa">AA</td>
                <td class="col-1 td1" size="1" *ngIf="!nota.aa">{{nota.nota2}}</td>
                <td class="col-1 td1" size="1" *ngIf="nota.aa">AA</td>
                <td class="col-1 td1" size="1" *ngIf="!nota.aa">{{nota.nota3}}</td>
                <td class="col-1 td1" size="1" *ngIf="nota.aa">AA</td>
              </tr>

              </tbody>
            </table>
          </div>
        </div>
      </div>
      <hr>
      <div class="col-12">
        <p class="parrafo1">Y para que así conste, a instancia del interesado/a, extendiendo la presente
          certificación en Valencia a {{date  | date:'longDate' }}.
        </p>
      </div>
      <div class="row col-12" style="margin-top: 20px">
        <p class="col-6 parrafo1"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;VºBº <br>El/la Directora/a</p>
        <p class="col-6 parrafo1">El/La Secretario/a</p>
      </div>
      <div class="row col-12" style="margin-top: 100px;">
        <p class="col-6 parrafo1">Peraira Lorca, FCO. Javier</p>
        <p class="col-6 parrafo1">Laserna Sánchez, Andrea</p>
      </div>
      <div class="html2pdf__page-break">

      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss()">
      <i class="fa fa-window-close editClose"></i>
    </button>
    <button type="button" class="btn btn-light" (click)="lanzarPdf()">
      <i class="fa fa-download"></i>
    </button>
  </div>
</ng-template>

<ng-template #pdf let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Boletines de Notas</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div id="pdfgenerate" class="modal-body">
    <div *ngFor="let data of imprimir; let i = index">
      <div id="{{i}}">
        <div ><table width="100%">
          <tr>
            <td width="33%"><img class="imageProgresa" src="images/centro/Progresa.png" alt=""></td>
            <td width="33%" align="center"></td>
            <td width="33%" style="text-align: right;"><img class="imagenGeneralitat" src="images/centro/Generalitat.png" alt=""></td>
          </tr>
        </table>
        </div>
        <div class="row titul">
          <div class="col-12" >
            <p class="titulo">CERTIFICACIÓN ACADÉMICA</p>
          </div>
          <div class="col-12">
            <p class="parrafo">D./Dª. Peraira Lorca, Francisco Javier director/a de {{datosCentro.razon_social}}.<br>
              <br>CERTIFICA: Que el alumno {{data.apellidos | titlecase}}, {{data.nombre | titlecase}} natural de
              {{data.municipio}} provincia de {{data.provincia}} nacido el {{data.fecha  | date:'shortDate'}},
              ha cursado en el centro {{datosCentro.razon_social | titlecase}}, con las calificaciones y en los cursos
              que a continuación se expresan los siguientes estudios de FORMACIÓN PROFESIONAL ESPECÍFICA (Ciclo
              Formativo de Grado {{datosCurso[0].nivel === 'Sistemas Microinformáticos y Redes (LOE) - SMR' ? 'Medio' : 'Superior'}}).<br><br>
              Familia: {{datosCurso[0].modulo | titlecase}}.
            </p>
          </div>
          <div class="col-12">
            <p class="tituloTabla">Curso: {{datosCurso[0].familia}}. {{datosCurso[0].nivel}}.</p>
          </div>
          <div class="col-12">
            <table class="table table-bordered">
              <thead>
              <tr >
                <th class="col-2 th aa"  size="2">AÑO</th>
                <th class="col-1 th bb"  size="1">CURSO</th>
                <th class="col-6 th cc"  size="6">CENTRO EN EL QUE SE REALIZO</th>
                <th class="col-6 th cc"  size="6">MATERIAS</th>
                <th class="col-1 th bb" size="1">ORD.</th>
                <th class="col-1 th bb" size="1">EXTRAORD.</th>

              </tr>

              </thead>
              <tbody>
              <tr *ngFor="let nota of imprimir[i].notas">
                <td class="col-2 td" size="2">{{datosCurso[0].curso}}-{{datosCurso[0].curso + 1}}</td>
                <td class="col-1 td" size="1" >{{datosCurso[0].anyo_curso}}</td>
                <td class="col-6  asig" size="6">Progresa centro integrado de formación S.L.</td>
                <td class="col-6  asig" size="6">{{nota.asignatura}}</td>
                <td class="col-1 td" size="1" *ngIf="!nota.aa">{{nota.nota}}</td>
                <td class="col-1 td" size="1" *ngIf="nota.aa">AA</td>
                <td class="col-1 td" size="1" ></td>

              </tr>
              <div *ngIf="datosCurso[0].asignaturas">
                <tr *ngFor="let nota of datosCurso[0].asignaturas">
                  <td class="col-2 td" size="2">{{datosCurso[0].curso + 1}}-{{datosCurso[0].curso + 2}}</td>
                  <td class="col-1 td" size="1" >{{nota.curso}}</td>
                  <td class="col-6  asig" size="6">Progresa centro integrado de formación S.L.</td>
                  <td class="col-6  asig" size="6">{{nota.asignatura}}</td>
                  <td class="col-1 td" size="1" ></td>
                  <td class="col-1 td" size="1" ></td>

                </tr>
              </div>


              </tbody>
            </table>
          </div>
        </div>
      </div>
      <hr>
      <div class="col-12">
        <p class="parrafo">Y para que así conste, a instancia del interesado/a, extendiendo la presente
          certificación en Valencia a {{date  | date:'longDate' }}.
        </p>
      </div>
      <div class="row col-12" style="margin-top: 10px">
        <p class="col-6 parrafo"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;VºBº <br>El/la Directora/a</p>
        <p class="col-6 parrafo">El/La Secretario/a</p>
      </div>
      <div class="row col-12" style="margin-top: 80px;">
        <p class="col-6 parrafo">Peraira Lorca, FCO. Javier</p>
        <p class="col-6 parrafo">Laserna Sánchez, Andrea</p>
      </div>
      <div class="html2pdf__page-break">

      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss()">
      <i class="fa fa-window-close editClose"></i>
    </button>
    <button type="button" class="btn btn-light" (click)="lanzarPdf()">
      <i class="fa fa-download"></i>
    </button>
  </div>
</ng-template>

<ng-template #pdf2 let-modal>
  <div class="modal-header" style="text-align: center;">
    <h4 class="modal-title bold">Seleccionar Alumno/s</h4>

  </div>
  <div class="modal-body">
    <table class="table">
      <tr>
        <th><input type="checkbox" [checked]="check" (change)="toggle()"/></th>
        <th>Todos</th>
      </tr>
      <tr *ngFor="let alumnos of datosAlumnos; let j= index">
        <td><input name="foo" type="checkbox" [checked]="activar" [value]="j" (change)="change(alumnos, $event)"/></td>
        <td>{{alumnos.nombre | titlecase}} {{alumnos.apellidos | titlecase}}</td>
      </tr>
    </table>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-dark mr-auto"  (click)="modal.close('Close click')">
      <i class="fa fa-window-close editClose"></i>
    </button>
    <button type="button" class="btn btn-outline-danger mb-0"  slot="end" (click)="open2(pdf)">
        <i class="fa fa-file-pdf-o"> Generar Boletines</i>
    </button>
  </div>
</ng-template>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate" [fullScreen]="true"></ngx-spinner>
