import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LoginService} from '../../../pages/services/login.service';
import {Rutas} from '../../../../utiles/Rutas';
import {Subject} from 'rxjs/Subject';


@Injectable()
export class AdminAlumnoService {
  id: number;
  token: string;
  private datosSource = new Subject<any>();
  datos$ = this.datosSource.asObservable();

  private taskSource = new Subject<any>();
  public task$ = this.taskSource.asObservable();

  private claseSource = new Subject<any>();
  public clase$ = new Subject<any>();


  private examenSource = new Subject<any>();
  public examen$ = this.examenSource.asObservable();

  constructor (private http: HttpClient, private _login: LoginService) {
    this.id = this._login.getIdentity().id;
    this.token = localStorage.getItem('token');
  }

  getTaskAsignatura(id: number) {
    console.log(id);
    console.log(this.id);
    this.http.get(Rutas + 'alumno/tasks?id=' + this.id + '&asignatura=' + id + '&token=' + this.token).subscribe(res => {
      if (res['code'] === 200) {
        this.datosSource.next(res['id']);
      }
    }, err => {});
  }
  getExamenesAsignatura (id: number) {
    this.http.get(Rutas + 'alumno/examenes?id=' + this.id + '&asignatura=' + id  + '&token=' + this.token).subscribe(res => {
      if (res['code'] === 200) {
        console.log(res['id']);
        this.datosSource.next(res['id']);
      }
    }, err => {});
  }
  getAsistenciasAsignatura (id: number) {
    this.http.get(Rutas + 'alumno/asistencias?id=' + this.id + '&asignatura=' + id  + '&token=' + this.token).subscribe(res => {
      if (res['code'] === 200) {
        this.datosSource.next(res['id']);
        console.log(res['id']);
      }
    }, err => {});
  }
  getAsignaturas() {
    this.http.get(Rutas + 'alumno/asignaturas?id=' + this.id + '&token=' + this.token).subscribe(res => {
      if (res['code'] === 200) {
        this.datosSource.next(res['id']);
        console.log(res['id']);
      }
    }, err => {});
  }
  getExamen(id: number) {
    this.http.get(Rutas + 'alumno/examen?id=' + id + '&token=' + this.token).subscribe(res => {
      if (res['code'] === 200) {
        this.examenSource.next(res['id']);
      }
    }, err => {});
  }
  getExamenesActivos () {
    this.http.get(Rutas + 'examenes/activos?id=' + this.id + '&token=' + this.token).subscribe(res => {
      if (res['code'] === 200) {
        this.datosSource.next(res['id']);
      }
    }, err => {});
  }
  getTask(id: number) {
    this.http.get(Rutas + 'alumno/task?id=' + id +  '&token=' + this.token).subscribe(res => {
      if (res['code'] === 200) {
        this.taskSource.next(res['id']);
      }
    }, err => {});
  }
  deleteFileExamen(id: number) {
    this.http.get(Rutas + 'alumno/delete/examen?id=' + id +  '&token=' + this.token).subscribe(res => {
      if (res['code'] === 200) {}
    }, err => {});
  }
  deleteFile(id: number) {
    this.http.get(Rutas + 'alumno/delete/taskfile?id=' + id +  '&token=' + this.token).subscribe(res => {
      if (res['code'] === 200) {}
    }, err => {});
  }
  changeStatus(estado: string, id: number) {
    this.http.get(Rutas + 'alumno/status/task?id=' + id + '&estado=' + estado + '&token=' + this.token).subscribe(res => {
      if (res['code'] === 200) {}
    }, err => {});
  }
  changeStatusExamen(estado: boolean, id: number) {
    this.http.get(Rutas + 'alumno/status/examen?id=' + id + '&estado=' + estado + '&token=' + this.token).subscribe(res => {
      if (res['code'] === 200) {}
    }, err => {});
  }
  getAsistencias (order: boolean, type: string) {
    // tslint:disable-next-line:max-line-length
    this.http.get(Rutas + 'alumno/asistencias?id=' + this.id + '&orden=' + order + '&tipo=' + type + '&token=' + this.token).subscribe(res => {
      if (res['code'] === 200) {
        this.datosSource.next(res['id']);
      }
    }, err => {});
  }
  getFaltasAsistencia () {
    this.http.get(Rutas + 'alumno/faltas?id=' + this.id + '&token=' + this.token).subscribe(res => {
      if (res['code'] === 200) {
        this.datosSource.next(res['id']);
      }
    }, err => {});
  }
  getExamenes (order: boolean, type: string) {
    this.http.get(Rutas + 'alumno/examenes?id=' + this.id + '&orden=' + order + '&tipo=' + type + '&token=' + this.token).subscribe(res => {
      if (res['code'] === 200) {
        this.datosSource.next(res['id']);
      }
    }, err => {});
  }
  getTasks(order: boolean, type: string) {
    this.http.get(Rutas + 'alumno/tasks?id=' + this.id + '&orden=' + order + '&tipo=' + type + '&token=' + this.token).subscribe(res => {
      if (res['code'] === 200) {
        this.datosSource.next(res['id']);
      }
    }, err => {});
  }

  getCurso() {
    this.http.get(Rutas + 'alumno/id/curso?token=' + this.token + '&id=' + this.id).subscribe( data => {
      if ( data['code']  === 200 ) {
        this.claseSource.next(data['id']);
        localStorage.setItem('aula', data['id'].id);
      }});
  }


}
