import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {TablonService} from '../tablon.service';
import {MatDialog} from '@angular/material/dialog';
import {Rutas, RutasImg} from '../../../../utiles/Rutas';
import {LoginService} from '../../../pages/services/login.service';
import {DetalleModalComponent} from '../detalle-modal/detalle-modal.component';

@Component({
  selector: 'app-tablon-user',
  templateUrl: './tablon-user.component.html',
  styleUrls: ['./tablon-user.component.scss']
})
export class TablonUserComponent implements OnInit {

  rutaImg= RutasImg;
  mensajes: any;
  mensajeForm: FormGroup;
  identity: any;
  data = {
    'todos' : false,
    'profesores' : false,
    'alumnos': false,
    'tutores': false,
    'aula': null
  }


  constructor(private tablonService: TablonService,  private dialog: MatDialog,  private loginService: LoginService) {
    this.identity = this.loginService.getIdentity()
    if ( this.identity ) {
      this.getMensajesTablon();
    }
  }

  ngOnInit(): void {
  }

  getMensajesTablon() {
    console.log('hola');
    switch (this.identity.role) {
      case 'ROLE_PROFESOR':
        this.data.profesores = true;
        break;
      case 'ROLE_ALUMNO':
        this.data.alumnos = true;
        this.data.aula = localStorage.getItem('aula');
        break;
        case 'ROLE_PADRE':
          this.data.tutores = true;
          this.data.aula = localStorage.getItem('aula');
          break;
      default:
        this.data.todos = true;
        break;
    }
    console.log(this.data);
    this.tablonService.getMensajeTablonUser(this.data).subscribe( ( res ) => {

      this.mensajes = res;
      console.log(this.mensajes);
    });
  }

  openFile(url) {
    window.open(Rutas + url);
  }

  openModalDetalle(mensaje: any) {

    const dialogRef = this.dialog.open(DetalleModalComponent, { hasBackdrop: true, data: mensaje, width: '600px', panelClass: 'custom-modalbox' });

  }

}
