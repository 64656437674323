<section>
  <mat-card style="width: 100%">
    <mat-card-header>
      <mat-card-title  class="pl-5">Listado de contactos
        <button mat-icon-button class="float-right mr-5" (click)="addData()" >
        Añadir <i class="icon-plus"></i>
        </button>

      </mat-card-title>

    </mat-card-header>
    <mat-divider></mat-divider>

    <mat-card-content>
      <div>
        <div class="row">
          <div class="col-6">
            <h5 style="font-weight: bold;">Total: {{ this.dataSource.data.length}}   -   Filtrados: {{ this.dataSource.filteredData.length}}</h5>
          </div>
          <div class="col-2">

          </div>

           <div class="col-2 botones">

             <button class="aceptar btn btn-raised btn-primary" (click)="exportDatosAlumnos()">Exportar Excel</button>
           </div>
          <div class="col-2 botones">
            <button   class="aceptar btn btn-raised btn-primary" (click)="resetAll()" >
              Restablecer filtros <i class="ft-refresh-ccw"></i>
            </button>

          </div>
        </div>
        <div [formGroup]="searchForm"   style="padding: 16px">
          <div class="row">



            <mat-form-field appearance="standard" class="col-lg-2 col-md-4 col-sm-10">
              <mat-label>Fecha inicio - Fecha fin</mat-label>
              <mat-date-range-input  [rangePicker]="picker3" >
                <input matStartDate formControlName="fromDate" placeholder="Fecha inicio">
                <input matEndDate formControlName="toDate" placeholder="Fecha fin">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
              <mat-date-range-picker #picker3></mat-date-range-picker>
            </mat-form-field>

            <div class="col-lg-2 botones">
              <button mat-button  class="aceptar btn btn-raised btn-primary" (click)="getDateRange()" >Aceptar</button>
              <button mat-button  class="restablecer btn btn-raised btn-primary" (click)="resetFechas()">Restablecer</button>
            </div>



            <mat-form-field  class="col-lg-2 col-md-4 col-sm-12" appearance="standard">
              <mat-label>Fecha</mat-label>
              <input matInput [matDatepicker]="picker1" formControlName="fecha" (dateInput)="applyFilter()" >
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>







            <div class="col-lg-2 col-md-6 col-sm-12" >
              <label class="form-label">Estado</label>
              <select class="custom-select form-control"  formControlName="estado" (change)="applyFilter()">
                <option selected value="">Cualquiera</option>
                <option *ngFor="let estado of estados" [value]="estado.id">{{estado.descripcion}}</option>
              </select>
            </div>


            <div class="col-lg-2 col-md-6 col-sm-12" >
              <label class="form-label">Referido</label>
              <select class="custom-select form-control"  formControlName="referido" (change)="applyFilter()">
                <option selected value="">Cualquiera</option>
                <option *ngFor="let ref of referidos" [value]="ref.descripcion">{{ref.descripcion}}</option>
              </select>
            </div>

            <div class="col-lg-2 col-md-6 col-sm-12" >
            <label class="form-label">Año inicio</label>
            <select class="custom-select form-control"  formControlName="curso" (change)="applyFilter()">
              <option selected value="">Cualquiera</option>
              <option *ngFor="let curso of cursosEscolares" [value]="curso.id">{{curso.cursoEscolar | slice: 0 : 4}}</option>
            </select>
          </div>





          </div>

          <div class="row">

            <div class="col-lg-2 col-md-6 col-sm-12" >
              <label class="form-label">Ciclo</label>
              <select class="custom-select form-control"  formControlName="ciclo" (change)="applyFilter()">
                <option selected value="">Todos</option>
                <option *ngFor="let ciclo of ciclos" [value]="ciclo.ciclo +'-' + ciclo.modalidad">{{ciclo.ciclo}}-{{ciclo.modalidad}}</option>
              </select>
            </div>

            <div class="col-lg-2 col-md-6 col-sm-6 col-6">
              <label class="form-label">Alumno</label>
              <input  type="text" class="form-control" formControlName="alumno"  (keyup)="applyFilter()">
            </div>

            <div class="col-lg-2 col-md-6 col-sm-6 col-6">
              <label class="form-label">F.Contacto</label>
              <select class="custom-select form-control"  formControlName="fContacto" (change)="applyFilter()">
                <option selected value="">Todas</option>
                <option *ngFor="let con of formaContacto" [value]="con.forma_contacto">{{con.forma_contacto}}</option>
              </select>
            </div>

            <div class="col-lg-2 col-md-6 col-sm-6 col-6">
              <label class="form-label">Email</label>
              <input  type="text" class="form-control" formControlName="email"  (keyup)="applyFilter()">
            </div>

            <div class="col-lg-2 col-md-6 col-sm-12 ">
              <label class="form-label">Nombre, apellidos</label>
              <input type="text" class="form-control" formControlName="contacto" (keyup)="applyFilter()" >
            </div>

            <div class="col-lg-2 col-md-6 col-sm-12 ">
              <label class="form-label">Teléfono</label>
              <input type="text" class="form-control" formControlName="telefono" (keyup)="applyFilter()" >
            </div>
          </div>
        </div>
      </div>

      <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8">

        <ng-container matColumnDef="ciclo" >
          <th mat-header-cell *matHeaderCellDef>Ciclo</th>
          <td mat-cell  *matCellDef="let element">{{element.ciclo.ciclo.ciclo}}-{{element.ciclo.ciclo.modalidad}}</td>
        </ng-container>

        <ng-container matColumnDef="alumno"  >
          <th mat-header-cell *matHeaderCellDef>Alumno</th>
          <td mat-cell  *matCellDef="let element">{{element.alumno.nombre}}, {{element.alumno.apellidos}}</td>
        </ng-container>

        <ng-container matColumnDef="fContacto"  >
          <th mat-header-cell *matHeaderCellDef>F.Contacto</th>
          <td mat-cell  *matCellDef="let element">{{element.forma_contacto.forma_contacto}}</td>
        </ng-container>

        <ng-container matColumnDef="fecha"  >
          <th mat-header-cell *matHeaderCellDef>Fecha</th>
          <td mat-cell  *matCellDef="let element">{{element.fecha | date: 'dd-MM-YYY'}}</td>
        </ng-container>

        <ng-container matColumnDef="email"  >
          <th mat-header-cell *matHeaderCellDef>Email</th>
          <td mat-cell  *matCellDef="let element">{{element.infoContacto.mail}}</td>
        </ng-container>

        <ng-container matColumnDef="contacto"  >
          <th mat-header-cell *matHeaderCellDef>Contacta</th>
          <td mat-cell  *matCellDef="let element">{{element.infoContacto.nombre}}, {{element.infoContacto.apellidos}} </td>
        </ng-container>


        <ng-container matColumnDef="telefono" >
          <th mat-header-cell *matHeaderCellDef>Telefono</th>
          <td mat-cell  *matCellDef="let element">{{element.infoContacto.telefono}}</td>
        </ng-container>

        <ng-container matColumnDef="referido" >
          <th mat-header-cell *matHeaderCellDef>Referido</th>
          <td mat-cell  *matCellDef="let element">{{element.infoContacto.referido.descripcion}}</td>
        </ng-container>

        <ng-container matColumnDef="estado"  >
          <th mat-header-cell *matHeaderCellDef>Estado</th>
          <td mat-cell  *matCellDef="let element, let i = index">
          <select  class="custom-select form-control" (change)="changeEstado($event , element)" (click)="$event.stopPropagation();">
            <option *ngFor="let est of estados" [value]="est.id" [selected]="element.estado.id === est.id">{{est.descripcion}}</option>
          </select>
        </td>
        </ng-container>

        <ng-container matColumnDef="acciones">
          <th mat-header-cell *matHeaderCellDef >Acciones</th>
          <td mat-cell *matCellDef="let element; let i = index;" class="text-center">
            <div class="danger p-0" (click)="editar(element, i)">
              <i class="fa fa-edit font-medium-3"></i>
            </div>
          </td>
        </ng-container>

        <!--- Parte expandable --->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="columnas.length">
            <div class="example-element-detail"
                 [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

              <mat-tab-group>
                <!-- LISTA DE ACCIONES COMERCIALES -->
                <mat-tab label="Acciones">
                  <div>
                    <button mat-icon-button class="float-right mr-5"  (click)="addLinea( element)" >
                      Añadir linea <i class="icon-plus"></i>
                    </button>
                  </div>
                 <div class="mb-4">
                   <table mat-table [dataSource]="accionesList" class="mat-elevation-z8" *ngIf="accionesList">

                     <!--- Note that these columns can be defined in any order.
                           The actual rendered columns are set as a property on the row definition" -->

                     <!-- Position Column -->
                     <ng-container matColumnDef="fecha">
                       <th mat-header-cell *matHeaderCellDef>Fecha</th>
                       <td mat-cell *matCellDef="let element"> {{element.fecha}} </td>
                     </ng-container>

                     <!-- Name Column -->
                     <ng-container matColumnDef="observaciones">
                       <th mat-header-cell *matHeaderCellDef> Observaciones </th>
                       <td mat-cell *matCellDef="let element"> {{element.observacion}} </td>
                     </ng-container>



                     <tr mat-header-row *matHeaderRowDef="actividadesColumn"></tr>
                     <tr mat-row *matRowDef="let row; columns: actividadesColumn;"></tr>
                   </table>

                 </div>
                </mat-tab>


              </mat-tab-group>



            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnas"></tr>
        <tr mat-row *matRowDef="let element; columns: columnas;"
            class="example-element-row pt-2 pb-2"
            [class.example-expanded-row]="expandedElement === element"
            (click)="expandedElement = expandedElement === element ? null : element; setElements(element)">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>


      </table>
      <mat-paginator #MatPaginator [pageSizeOptions]="[25, 50 , 100]" [pageSize]="25" showFirstLastButtons></mat-paginator>
    </mat-card-content>
  </mat-card>
</section>







<!--- MENSAJE SEGURO CAMBIAR ESTADO -------->










<div *ngIf="spinner" class="swal2-container swal2-center swal2-backdrop-show">
  <img class="spinner-img" src="assets/img/loader/logo.png" >
</div>
