import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {UsersService} from '../services/users.service';

import swal from 'sweetalert2';
import {ActivatedRoute} from '@angular/router';
import {Datos} from '../../../../modelos/datos.modelo';


@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['../users.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContactoComponent implements OnInit {
  public id: number;
  public dato: Datos;
  active_c: boolean;

  constructor(private _user: UsersService, private _route: ActivatedRoute) {
    this.dato = new Datos(0, '', '', '', '', new Date(), '', '', [],
      0, 0, '', [], [], [], false, false, '', '', '', '', {nivel: '', id: ''}, '', '', '', '');
    this.id = this._route.snapshot.parent.params['id'];
    this._user.getDato(this.id);
  }

  ngOnInit() {
    this._user.dato$.subscribe(res => {
      this.dato = res;
    });

  }

  open(valor: string, tipo: number) {
    const user = this.dato.id;
    const newview = this._user;
    swal.fire({
      title: 'Introduce el ' + valor,
      input: 'text'
    }).then(function (text) {
      if (text.value) {
        newview.addContacto(text.value, tipo, user);
      }
    });
  }

  removeContacto(contacto: any) {
    swal.fire({
      title: 'BORRAR',
      text: '¿Seguro que quiere borrar ' + contacto.dato + '?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, Eliminar!',
      cancelButtonText: 'No, Cancelar!',
      reverseButtons: true
    }).then((result) => {
        if (result.isConfirmed) {
          this._user.removeContacto(contacto.id);
        }
      });
    // this._user.removeContacto(id);
  }

}
