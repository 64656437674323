import { Component, OnInit } from '@angular/core';
import { ROUTES } from './sidebar-routes.config';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../../../pages/services/login.service';

declare const $: any;
declare const jquery: any;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];

    constructor(private router: Router,
        private route: ActivatedRoute,
        private _login: LoginService) {
    }

    ngOnInit() {
      const dominio = window.location.host;
      if (dominio !== 'localhost:4200') {
        $.getScript('./assets/js/app-sidebar.js');
      } else {
        $.getScript('./assets/js/app-sidebar.js');
      }
        this.menuItems = ROUTES.filter(menuItem => menuItem);
    }



}
