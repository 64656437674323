import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Router} from '@angular/router';
import {Rutas} from '../../../../utiles/Rutas';
import {LoginService} from '../../../pages/services/login.service';
import {Subscription} from 'rxjs/Subscription';
import {reject} from 'q';


@Injectable()
export class AdminPadreService {
  private usersSource = new Subject<any>();
  public users$ = this.usersSource.asObservable();

  constructor (private http: HttpClient, private router: Router, private _login: LoginService) {
  }

  getHijos (id: number) {
    this.http.get(Rutas + 'padre/lista?token=' + localStorage.getItem('token') + '&id=' + id).subscribe(data => {
      if (data['code'] === 200) {
        this.usersSource.next(data['id']);
      }
    }, error => {
      console.log(error);
    });
  }


  addHijo (user: any) {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
      const json = {user};
      const data = JSON.stringify(json);
      const params = 'json=' + data + '&token=' + localStorage.getItem('token');
      this.http.post(Rutas + 'padre/add/hijo', params, {headers: headers}).subscribe(datas => {
        resolve(datas['id']);
      }, error => {
        reject(error);
      });
    });
  }

}
