import {Component, OnDestroy} from '@angular/core';
import { ClasesMService } from '../services/clasesM.service';
import { Subscription } from 'rxjs/Subscription';
import { Router, ActivatedRoute } from '@angular/router';



@Component({
  selector: 'app-lista-examenes',
  templateUrl: './lista-examenes.component.html',
  styleUrls: ['../clasesM.component.scss']
})
export class ListaExamenesComponent implements OnDestroy {


  examenes = [];
  examenesSubscription: Subscription = null;

  id: number;
  curso: number;
  check: boolean;
  aula = sessionStorage.getItem('aula');

  constructor(private _clases: ClasesMService, private _route: ActivatedRoute, private _router: Router) {
      this.id  = this._route.snapshot.parent.params['id'];
      this.curso = this._route.snapshot.parent.params['curso'];
      this._clases.getExamenes(this.id, this.curso);
      this.examenesSubscription = this._clases.examenes$.subscribe(data => {
      this.examenes = data;
      });
      }
      ngOnDestroy(): void {
    this.examenesSubscription.unsubscribe();
  }

  activar(examen, index: number) {
    this.check = !this.examenes[index].estado;
    this.examenes[index].estado = this.check;
    this._clases.activar(examen.id, this.check, examen.id_asignatura );
  }
  //  For confirm action On Delete
  delete(id: number) {
    this._clases.deleteExamen(id);
  }
  ver(id: number) {
    this._router.navigate(['/maestro/info/' + this.curso + '/' + this.id + '/alumnos-examen', id]);
  }
}
