import { Component, OnInit } from '@angular/core';
import {UsersService} from '../../services/users.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-historial',
  templateUrl: './historial.component.html',
  styleUrls: ['./historial.component.scss']
})
export class HistorialComponent implements OnInit {
  dato: any;
  id: any;
  Ce: any[] = [];

  constructor(private userService: UsersService, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.getUser();
    this.getHistorial();
  }

  getUser(): void {
    this.id = this.route.snapshot.parent.params['id'];
    this.userService.getDato(this.id);
    this.userService.dato$.subscribe((res: any) => {
      this.dato = res;
    });
  }

  getHistorial(): void {
    this.userService.getHistorialNotas(this.id).subscribe((res) => {
      console.log(res['id']);
      this.Ce = res['id'];
    })
  }
}
