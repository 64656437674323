import {Component, OnDestroy, OnInit} from '@angular/core';
import {ClasesMService} from '../services/clasesM.service';
import {Subscription} from 'rxjs/Subscription';
import {Router, ActivatedRoute} from '@angular/router';
import {LocalDataSource} from 'ng2-smart-table';
import {UploadService} from '../../../services/upload.service';
import {RutasImg} from '../../../../utiles/Rutas';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-alumnos-examen',
  templateUrl: './alumnos-examen.component.html',
  styleUrls: ['../clasesM.component.scss']
})
export class AlumnosExamenComponent implements OnDestroy, OnInit {


  alumnos = [];
  alumnosSubscription: Subscription = null;
  source: LocalDataSource;
  id: number;
  asignatura: number;
  curso: number;
  editable: boolean;
  id_examen: number;
  filesToUpload: File[];
  files: boolean;
  fileUrl: string;
  check: boolean;
  nota: number;
  aula = sessionStorage.getItem('aula');
  ok: boolean;
  abierto: boolean;

  constructor (private _clases: ClasesMService, private _route: ActivatedRoute, private _router: Router, private _upload: UploadService
  ) {
    this._route.params.subscribe(res => {
      this.id = res['id'];
      this.asignatura = this._route.snapshot.parent.params['id'];
      this.curso = this._route.snapshot.parent.params['curso'];
      this._clases.getAlumnosExamenes(this.id, this.curso).subscribe(( resp: any) => {
        if (resp['code'] === 200) {
          this.alumnos = resp['id'];
          if (this.alumnos[0]) {
            this.check = this.alumnos[0].visible;
          }
        } else {
          console.log(resp);
        }
      });
    });
  }



  ngOnInit () {
  }

  ngOnDestroy (): void {

  }

  toggle () {
    this.check = !this.check;
    const checkboxes: any = document.getElementsByName('foo');
    for (let i = 0, n = checkboxes.length; i < n; i++) {
      checkboxes[i].checked = this.check;
      this.alumnos[i].status = this.check;
    }
    this._clases.addEstado(this.id, this.check);

  }

  change (position: number) {
    const estado = !this.alumnos[position].estado;
    this.alumnos[position].estado =  !this.alumnos[position].estado;
    const id = this.alumnos[position].id;
    this._clases.updateEstado(estado, id);
  }

  openFiles () {
    this.files = !this.files;
    }

  openFile () {
    this.files = !this.files;
    window.open(RutasImg +  this.fileUrl, '_blank', 'fullscreen=yes');
  }

  editar (index) {
    if (!this.editable) {
      document.getElementById('a_' + index).hidden = true;
      document.getElementById('b_' + index).hidden = false;
      document.getElementById('c_' + index).hidden = false;
    } else {
      document.getElementById('a_' + index).hidden = false;
      document.getElementById('b_' + index).hidden = true;
      document.getElementById('c_' + index).hidden = true;
    }
    this.editable = !this.editable;
  }

  add (alumno: any, notas: number, index) {
    if (notas && !isNaN(notas)) {
      const nota = {
        alumno: alumno.id,
        nota: notas,
        examen: this.id,
        examen_alumno: alumno.id_examen
      };
      console.log(nota);
      this._clases.notaAdd(nota).subscribe((res: any) => {
        if (res['code'] === 200) {
          this.alumnos[index].nota = notas;
          this.alumnos[index].estado = true;
          this.esconder(index);
        } else {
          console.log(res);
        }
      });
    } else {
      Swal.fire('', 'Lo ingresado no es un numero', 'warning');
    }
  }

  esconder( index: number): void {
    document.getElementById('a_' + index).hidden = !document.getElementById('a_' + index).hidden;
    document.getElementById('b_' + index).hidden = !document.getElementById('b_' + index).hidden;
    document.getElementById('c_' + index).hidden  = !document.getElementById('c_' + index).hidden;
    const i = index + 1;
    if ( i < this.alumnos.length) {
      document.getElementById('h_' + i).focus();
    }

}

  upFile (id: number) {
    this.id_examen = id;
    document.getElementById('buscadorPersona').click();
  }

  addPhoto () {
    this._upload
      .makeFileRequest(this.id_examen, this.filesToUpload, 'imagen', 'examen_alumno')
      .then(
        (result: any) => {
          this._clases.getAlumnoExamen(this.id, this.curso);
        },
        error => {
          console.log(error);
        }
      );
  }


  fileChangeEvent (fileinput: any) {
    this.filesToUpload = <Array<File>>fileinput.target.files;
    this.addPhoto();
  }

  habilitar() {
    console.log('Me llamo');
    let x = 0;
    if (!this.ok) {
      for (const i of this.alumnos) {
        document.getElementById('a_' + x).hidden = true;
        document.getElementById('b_' + x).hidden = false;
        document.getElementById('c_' + x).hidden = false;
        x++;
      }
      console.log('aca va abierto');
      }
     if (this.ok) {
      let y = 0;
      for (const c of this.alumnos) {
        document.getElementById('a_' + y).hidden = false;
        document.getElementById('b_' + y).hidden = true;
        document.getElementById('c_' + y).hidden = true;
      y++;
      }
    }
    this.ok = !this.ok;
  }

  open() {
    console.log('vuelvo a abrir');
    let y = 0;
    for (const c of this.alumnos) {
      document.getElementById('a_' + y).hidden = true;
      document.getElementById('b_' + y).hidden = false;
      document.getElementById('c_' + y).hidden = false;
      y++;
    }
  }

}
