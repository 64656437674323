<div class="col">
    <table class="table table-striped tAsi">
        <thead>
            <tr>
                <th scope="col">Asignatura</th>
                <th scope="col">Clases hoy</th>
                <th scope="col">Acciones</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let row  of asignaturas; let i = index">
                <td>{{row.asignatura}}</td>


                 <td *ngIf="row.estado else noClase"> <i class="ft-check-circle success"></i></td>

                 <ng-template #noClase><td><i class="ft-alert-circle danger"></i></td></ng-template>




              <td>

                      <button mat-button [routerLink]="['/maestro/info' , id, row.id]">VER</button>


                </td>
            </tr>
        </tbody>
    </table>
</div>
