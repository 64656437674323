import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminExamenesService} from '../admin-examenes/services/admin-examenes.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-admin-notasfinal',
  templateUrl: './admin-notasfinal.component.html',
  styleUrls: ['./admin-notasfinal.component.scss']
})
export class AdminNotasfinalComponent implements OnInit {
  public asignaturas: any[];
  public id: number;
  public asignatura: any[];
  public notasFinales: any[] = [];
  asig: string;
  editable: boolean;


  constructor(private _route: ActivatedRoute, private router: Router, private _service: AdminExamenesService) {
    this.id = this._route.snapshot.parent.params['id'];
    const asign = this._route.snapshot.params.asign;
    if (asign !== '0') {

    }
  }

  ngOnInit(): void {
    this._service.getAsignaturas(this.id).then(res => this.asignaturas = res['id']);
  }

  setAsignatura(asignatura: any) {
    console.log(asignatura.id)
    if (asignatura.id != null) {
    this.asig = asignatura.id;
    this.getExamenes();
    } else {
      Swal.fire('', 'Seleccionar una Asignatura', 'warning');
    }
  }

  getExamenes(): void {
    this._service.getNotasTrimestrales(this.id, this.asig).subscribe( (resp: any) => {
      console.log(resp['id']);
      this.notasFinales = resp['id'];
    })
  }


  add(id_examen: any, note: any, trimestre, i: number, x: number) {
    const notas = parseInt(note, 10)
    if (!isNaN(notas)) {
      if (notas > 0 && notas <= 10) {
        const nota = {
          id: id_examen,
          nota: notas,
          tipo: trimestre
        };
        console.log(nota);
        this._service.updateTypeNotaFinal(nota).subscribe( (res) => {
           console.log(res);
           this.getExamenes();
         }, (error) => {
           console.log(error);
         });
         document.getElementById('a_' + i + x).hidden = false;
         document.getElementById('b_' + i + x).hidden = true;
       } else {
         Swal.fire('', 'El rango de notas es de 1 a 10', 'warning');
       }
     } else {
       Swal.fire('', 'Haz Introducido una Letra', 'warning')
    }
  }

  editar(i, x): void {
    if (!this.editable) {
      document.getElementById('a_' + i + x).hidden = true;
      document.getElementById('b_' + i + x).hidden = false;
    } else {
      document.getElementById('a_' + i + x).hidden = false;
      document.getElementById('b_' + i + x).hidden = true;
    }
    this.editable = !this.editable;
  }
}
