import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Subject} from 'rxjs/Subject';
import {Rutas} from '../../../../utiles/Rutas';
import {LoginService} from '../../../pages/services/login.service';
import Swal from 'sweetalert2';
import {Router} from '@angular/router';


@Injectable()
export class AdminExamenesService {
  private asignaturasSource: Subject<any> = new Subject();
  public asignaturas$ = this.asignaturasSource.asObservable();

  private alumnosSource: Subject<any> = new Subject();
  public alumnos$ = this.alumnosSource.asObservable();

  private examenesSource: Subject<any> = new Subject();
  public examenes$ = this.examenesSource.asObservable();

  private vaciosSource: Subject<any> = new Subject();
  public vacios$ = this.vaciosSource.asObservable();

  private listadoExamenesSource: Subject<any> = new Subject();
  public listadoExamenes$ = this.listadoExamenesSource.asObservable();

  private examenSource: Subject<any> = new Subject();
  public examen$ = this.examenSource.asObservable();

  id: number;
  token: any;

  constructor(private  http: HttpClient, private _login: LoginService, private router: Router) {
    this.id = this._login.getIdentity().id;
    this.token = this._login.getToken();
  }


  getExamen(id: number, id_curso) {
    // tslint:disable-next-line:max-line-length
    return this.http.get(Rutas + 'examenes/aula/asignatura?id=' + id + '&token=' + localStorage.getItem('token') + '&curso=' + id_curso).toPromise();
  }


  listadoExamenes(id: number, id_asignatura: number) {
    this.http.get(Rutas + 'lista/examenes?curso=' + id + '&id=' + id_asignatura + '&token=' + localStorage.getItem('token'))
      .subscribe(data => {
        if (data['code'] === 200) {
          this.listadoExamenesSource.next(data['id']);
        }
      }, err => {
        console.log(err);
      });
  }

  getVacios(id: number, id_asignatura: number) {
    this.http.get(Rutas + 'get/vacios?curso=' + id + '&id=' + id_asignatura + '&token=' + localStorage.getItem('token'))
      .subscribe(data => {
        if (data['code'] === 200) {
          this.vaciosSource.next(data['id']);
        }
      }, err => {
        console.log(err);
      });
  }


  getAsignaturas(id: number) {
    return this.http.get(Rutas + 'get/curso/asignaturas?curso=' + id + '&id=' + this._login.getIdentity().id
      + '&token=' + localStorage.getItem('token')).toPromise();
  }

  getAlumnos(id: number) {
    this.http.get(Rutas + 'maestro/alumnos?id=' + id + '&token=' + localStorage.getItem('token'))
      .subscribe(data => {
        if (data['code'] === 200) {
          this.alumnosSource.next(data['id']);
        }
      }, err => {
        console.log(err);
      });
  }

  getExamenes(datos) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {datos};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'examenes/aula/asignatura?curso=', params , {headers: headers} )
      .subscribe(dat => {
        if (dat['code'] === 200) {
          this.examenesSource.next(dat['id']);
        }
      }, err => {
        console.log(err);
      });
  }

  addNota(nota: any, id: number, asignatura: number) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {nota};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'add/nota', params, {headers: headers}).subscribe(() => {
      // this.getExamenes(id, asignatura);
    }, error => {
      console.log(error);
    });
  }

  addNotaFinal(nota: any, id, tipo, asign) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const notas = {
      notas: nota
    };
    const json = {notas};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'add/nota/final', params, {headers: headers}).subscribe(res => {
      if (res['code'] === 200) {
        Swal.fire('', 'Notas Guardadas ', 'warning');
        this.router.navigateByUrl(`/maestro/clase/${id}/notas/${tipo}/${asign}`);
      } else {
        if (res['code'] === 888) {
          Swal.fire('', 'Los datos ya existen', 'warning');
        }
      }
    }, error => {
      Swal.fire('', 'Consulte con Soporte', 'warning');
      console.log(error);
    });
  }

  getNotasFinal(id_curso: number, id_asignatura: number, tipo: number) {
    return this.http.get(Rutas + 'get/nota/final?asignatura=' + id_asignatura + '&curso='
      + id_curso + '&tipo=' + tipo + '&token=' + localStorage.getItem('token')).toPromise()
  }

  getNotasFinalUsers(id_curso, user) {
    return this.http.get(Rutas + 'get/nota/final_user?curso=' + id_curso +
       '&user=' + user + '&token=' + localStorage.getItem('token'));
  }

  updateNotasFinal(nota: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {nota};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'update/type_nota/final', params, {headers: headers}).subscribe(res => {
      if (res['code'] === 200) {
        console.log(res);
      } else {
        console.log(res);
      }
    }, error => {
      console.log(error);
    });
  }

  calcularNotasFinal(datos: any[], porcentaje: any[]) {
    let datum: any[] = [];
    if (datos[0].hasOwnProperty('nota') && datos[0].hasOwnProperty('nota2') && datos[0].hasOwnProperty('nota3')) {
      const suma = porcentaje[0].one + porcentaje[1].two +  porcentaje[2].three;
      if (suma === 100) {
        for (let i = 0; i < datos.length; i++) {
          datos[i].nota = (datos[i].nota * porcentaje[0].one) / 100;
          datos[i].nota2 = (datos[i].nota2 * porcentaje[1].two) / 100;
          datos[i].nota3 = (datos[i].nota3 * porcentaje[2].three) / 100;
          datos[i].notaFinal = Math.round(datos[i].nota + datos[i].nota2 + datos[i].nota3);
        }
        datum = datos;
      } else {
        Swal.fire('', 'Los porcentajes no cuadran', 'warning');
      }
    }
    if (datos[0].hasOwnProperty('nota') && datos[0].hasOwnProperty('nota2') && !datos[0].hasOwnProperty('nota3')) {
      const suma = porcentaje[0].one + porcentaje[1].two;
      if (suma === 100) {
        for (let j = 0; j < datos.length; j++) {
          datos[j].nota = (datos[j].nota * porcentaje[0].one) / 100;
          datos[j].nota2 = (datos[j].nota2 * porcentaje[1].two) / 100;
          datos[j].notaFinal = Math.round(datos[j].nota + datos[j].nota2);
        }
        datum = datos;
      } else {
        Swal.fire('', 'Los porcentajes no cuadran', 'warning');
      }
    }
    if (datos[0].hasOwnProperty('nota') && !datos[0].hasOwnProperty('nota2') && !datos[0].hasOwnProperty('nota3')) {
      for (let g = 0; g < datos.length; g++) {
        datos[g].nota = Math.round((datos[g].nota * porcentaje[0].one) / 100);
        datos[g].notaFinal = datos[g].nota;
      }
      datum = datos;
    }
    return datum;
  }

  notaEdit(datos: any[], nota: number, index: number) {
        if (datos[0].hasOwnProperty('nota') && !datos[0].hasOwnProperty('nota2') && !datos[0].hasOwnProperty('nota3')) {
          datos[index].nota = nota;
          datos[index].notaFinal = datos[index].nota;
        }
        if (datos[0].hasOwnProperty('nota') && datos[0].hasOwnProperty('nota2') && !datos[0].hasOwnProperty('nota3')) {
          datos[index].nota = nota;
          datos[index].notaFinal = Math.round((datos[index].nota + datos[index].nota2) / 2);
        }
        if (datos[0].hasOwnProperty('nota') && datos[0].hasOwnProperty('nota2') && datos[0].hasOwnProperty('nota3')) {
          datos[index].nota = nota
          datos[index].notaFinal = Math.round((datos[index].nota + datos[index].nota2 + datos[index].nota3) / 3);
        }
    return datos;
  }

  getNotasTrimestrales( curso, asignatura) {
    return this.http.get(Rutas + 'asignaturas/notas_final_curso?curso=' + curso + '&asignatura=' + asignatura +
      '&token=' + localStorage.getItem('token'));
  }

  updateTypeNotaFinal(nota) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {nota};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    return this.http.post(Rutas + 'update/type_nota/final', params, {headers: headers});
  }
}
