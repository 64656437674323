<i [id]="id"></i>
<mwl-calendar-week-view
  [viewDate]="viewDate"
  [events]="asignaturasMostrar"
  [weekStartsOn]="1"
  [excludeDays]="[6, 0]"
  [dayStartHour]="9"
  [dayEndHour]="21"
  [refresh]="refresh">
</mwl-calendar-week-view>
