import {NgModule} from '@angular/core';
import {CommonModule, DatePipe, TitleCasePipe} from '@angular/common';
import {NgbDatepickerModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';

import {InboxModule} from '../inbox/inbox.module';
import {PipesModule} from '../../../pipes.module';
import {Ng2SmartTableModule} from 'ng2-smart-table';
import {MatTabsModule} from '@angular/material/tabs';


// ADMIN
import {FooterComponent} from './footer/footer.component';
import {NavbarComponent} from './navbar/navbar.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {CustomizerComponent} from './customizer/customizer.component';

import {MainComponent} from './main/main.component';

import {AdminComponent} from './admin.component';
import {AdminInboxComponent} from '../admin-inbox/admin-inbox.component';


// ADMIN CENTRO
import {CursosComponent} from '../admin-centro/componentes/cursos.component';
import {CentroComponent} from '../admin-centro/centro.component';
import {CursosListaComponent} from '../admin-centro/componentes/cursos-lista.component';
import {CursosAsignaturasComponent} from '../admin-centro/componentes/cursos-asignaturas.component';
import {AddComponent} from '../admin-centro/componentes/add.component';
import {AdminAgendaComponent} from '../admin-agenda/admin-agenda.component';
import {AddConfiguracionComponent} from '../admin-agenda/componentes/add-configuracion.component';
import {AgendaListaComponent} from '../admin-agenda/componentes/agenda-lista.component';


// ADMIN AULAS
import {AddAulaComponent} from '../admin-aulas/main-aula/add/add-aula.component';
import {AulasListaComponent} from '../admin-aulas/main-aula/list/aulas-lista.component';
import {AulasViewComponent} from '../admin-aulas/view-aula/aulas-view.component';
import {AulaComponent} from '../aula/aulas.componet';
import {AulasComponent} from '../admin-aulas/main-aula/aulas.component';
import {PdfComponent} from '../admin-aulas/pdf/pdf.component';


// ADMIN USERS
import {UsersComponent} from '../admin-users/users.component';
import {DatosComponent} from '../admin-users/componentes/datos.component';
import {UsersService} from '../admin-users/services/users.service';
import {ContactoComponent} from '../admin-users/componentes/contacto.component';
import {TutoresComponent} from '../admin-users/componentes/tutores.component';
import {BancariosComponent} from '../admin-users/componentes/bancarios.component';
import {TitulosComponent} from '../admin-users/componentes/titulos.component';
import {ListaComponent} from '../admin-users/componentes/lista.component';
import {CredencialesComponent} from '../admin-users/componentes/credenciales.component';
import {HorarioComponent} from '../admin-users/componentes/horario/horario.component';
import {JornadaComponent} from '../admin-users/componentes/jornada/jornada.component';
import {CrearDatosComponent} from '../crear-datos/crear-datos.component';
import {ConvalidacionesComponent} from '../admin-users/componentes/convalidaciones/convalidaciones.component';

// PROFILE ADMIN
import {ProfileComponent} from '../admin-profile/profile.component';
import {ProfileContactoComponent} from '../admin-profile/componentes/profile-contacto.component';
import {ProfileEditComponent} from '../admin-profile/componentes/profile-edit.component';
import {ProfilePasswordComponent} from '../admin-profile/componentes/profile-password.component';
import {ViewComponent} from '../admin-aulas/view-aula/view.component';


// ROUTING
import {AdminRoutingModule} from './admin.routing';
import {AuthGuardService} from '../../services/auth-guard.service';
import {HttpClientModule} from '@angular/common/http';
import {UploadService} from '../../services/upload.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';


// SERVICES
import {CentroService} from '../admin-centro/services/centro.service';
import {CursosService} from '../admin-centro/services/cursos.service';
import {AulaService} from '../aula/services/aula.service';
import {AulasService} from '../admin-aulas/services/aulas.service';
import {ProfileService} from '../admin-profile/services/profile.service';
import {AdminPadreService} from '../admin-padres/services/admin-padre.service';
import {StatsService} from '../admin-stats/service/stats.service';


import {PadreComponent} from '../admin-padres/padre.component';
import {DatosPadreComponent} from '../admin-padres/componentes/datos-padre.component';
import {HijosPadreComponent} from '../admin-padres/componentes/hijos-padre.component';
import {CustomizeService} from './customizer/customizer.service';
import {AdminStatsComponent} from '../admin-stats/admin-stats.component';
import {GraficaComponent} from '../admin-stats/graficas/grafica.component';
import {ChartsModule} from 'ng2-charts';
import {AdminAgendaService} from '../admin-agenda/services/admin-agenda.service';
import {AgendaModule} from '../../componentes/agenda/agenda.module';
import {TemporadaComponent} from '../admin-centro/componentes/temporada.component';


import {registerLocaleData} from '@angular/common';
import localeEs from '@angular/common/locales/es';
import {CalendarioModule} from '../../componentes/calendario/calendario.module';
import {NgxPaginationModule} from 'ngx-pagination';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {WebcamModule} from 'ngx-webcam';
import {MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatTableModule} from '@angular/material/table';
import {FichajesComponent} from '../admin-stats/fichajes/fichajes.component';
import {MinutosPipe} from '../../../pipes/minutos.pipe';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {NgxSpinnerModule} from 'ngx-spinner';

/*** MAPA GOOGLE***/
import {AgmCoreModule} from '@agm/core';
import {MapId} from '../../../utiles/Config';

//
import {LineChartComponent} from '../admin-stats/graficas/line-chart/line-chart.component';
import {AdminAdministracionComponent} from '../admin-administracion/admin-administracion.component';
import {GestorDocumentalComponent} from '../admin-administracion/components/gestor-documental/gestor-documental.component';
import {DocumentosService} from '../admin-administracion/services/documentos.service';
import {DocumentosComponent} from '../admin-users/componentes/documentos/documentos.component';
import {ComercialComponent} from '../comercial/comercial.component';
import {MatCardModule} from '@angular/material/card';
import {MatDividerModule} from '@angular/material/divider';
import {ComercialService} from '../comercial/services/comercial.service';
import {MatIconModule} from '@angular/material/icon';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CrearContactoComponent} from '../alquiler/crear-contacto/crear-contacto.component';
import {ListarContactoComponent} from '../alquiler/listar-contacto/listar-contacto.component';
import {ListarAlquilerComponent} from '../alquiler/listar-alquiler/listar-alquiler.component';
import {AlquilerService} from '../alquiler/services/alquiler.service';
import {CsvService} from '../comercial/services/csv.service';
import {ExcelService} from '../comercial/services/excel.service';
import {GestionClasesComponent} from '../admin-administracion/components/gestion-clases/gestion-clases.component';
import {EstadisticasComercialComponent} from '../comercial/componentes/estadisticas-comercial/estadisticas-comercial.component';
import {PieChartComponent} from '../charts/pie-chart/pie-chart.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {PolarChartComponent} from '../charts/polar-chart/polar-chart.component';
import {BarChartComponent} from '../charts/bar-chart/bar-chart.component';
import {BarTotalChartComponent} from '../charts/bar-total-chart/bar-total-chart.component';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {KtdGridModule} from '@katoid/angular-grid-layout';
import {MatListModule} from '@angular/material/list';
import {MatChipsModule} from '@angular/material/chips';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ExportacionesComponent} from '../admin-administracion/components/exportaciones/exportaciones.component';
import {ObservacionesComponent} from '../admin-users/componentes/observaciones/observaciones.component';
import {TablonModule} from '../../componentes/tablon/tablon.module';
import {TablonComponent} from '../../componentes/tablon/tablon.component';
import {QuillModule} from 'ngx-quill';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {ClaustroComponent} from '../admin-aulas/claustro/claustro.component';
import {MatSortModule} from '@angular/material/sort';
import {SortDirective} from '../admin-aulas/claustro/sort.directive';
import {ObsModalComponent} from '../admin-aulas/claustro/obs-modal/obs-modal.component';
import {AddContactoComponent} from '../comercial/modales/add-contacto/add-contacto.component';
import {AddTipoContactoComponent} from '../comercial/modales/add-tipo-contacto/add-tipo-contacto.component';
import {AddCicloComponent} from '../comercial/modales/add-ciclo/add-ciclo.component';
import {AddReferidoComponent} from '../comercial/modales/add-referido/add-referido.component';
import {EstadoCambioComponent} from '../comercial/modales/estado-cambio/estado-cambio.component';
import {AddLineaComponent} from '../comercial/modales/add-linea/add-linea.component';
import {AddTutorComponent} from '../admin-users/componentes/modals/add-tutor/add-tutor.component';
import {_MatMenuDirectivesModule, MatMenuModule} from '@angular/material/menu';
import {BulkModalComponent} from '../aula/bulk-modal/bulk-modal.component';
import {CursosIntensivosComponent} from '../admin-administracion/components/cursos-intensivos/cursos-intensivos.component';
import {CursosExtracurricularesComponent} from '../admin-users/componentes/cursos-extracurriculares/cursos-extracurriculares.component';
import {ModalCursosIntensivosComponent} from '../admin-administracion/modal/modal-cursos-intensivos/modal-cursos-intensivos.component';
import {HistorialComponent} from '../admin-users/componentes/historial/historial.component';
import {StringSubstrPipe} from '../../../pipes/filtertext';
import {AlumnosComponent} from '../admin-users/componentes/alumnos/alumnos.component';

registerLocaleData(localeEs);


@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    AdminRoutingModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: MapId,
      libraries: ['places']
    }),
    FormsModule,
    NgxDatatableModule,
    InboxModule,
    PipesModule,
    Ng2SmartTableModule,
    BrowserAnimationsModule,
    MatTabsModule,
    ChartsModule,
    AgendaModule,
    CalendarioModule,
    NgbDatepickerModule,
    NgxPaginationModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    WebcamModule,
    MatDialogModule,
    MatTableModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    NgxSpinnerModule,
    MatCardModule,
    MatDividerModule,
    ReactiveFormsModule,
    MatIconModule,
    MatPaginatorModule,
    MatGridListModule,
    NgxChartsModule,
    KtdGridModule,
    MatListModule,
    MatChipsModule,
    MatTooltipModule,
    QuillModule,
    AngularEditorModule,
    MatSortModule,
    _MatMenuDirectivesModule,
    MatMenuModule
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    CustomizerComponent,
    MainComponent,
    AdminComponent,
    TutoresComponent,
    TitulosComponent,
    AdminInboxComponent,
    CentroComponent,
    CursosComponent,
    CursosListaComponent,
    CursosAsignaturasComponent,
    AulaComponent,
    AddAulaComponent,
    AulasListaComponent,
    AulasViewComponent,
    AulasComponent,
    ContactoComponent,
    AddTutorComponent,
    BancariosComponent,
    ViewComponent,
    UsersComponent,
    DatosComponent,
    LineChartComponent,
    ListaComponent,
    AddComponent,
    ProfileComponent,
    ProfileContactoComponent,
    ProfileEditComponent,
    ProfilePasswordComponent,
    CredencialesComponent,
    PadreComponent,
    DatosPadreComponent,
    HijosPadreComponent,
    AdminStatsComponent,
    GraficaComponent,
    AdminAgendaComponent,
    AddConfiguracionComponent,
    AgendaListaComponent,
    TemporadaComponent,
    HorarioComponent,
    JornadaComponent,
    CrearDatosComponent,
    PdfComponent,
    ConvalidacionesComponent,
    FichajesComponent,
    AdminAdministracionComponent,
    EstadisticasComercialComponent,
    GestorDocumentalComponent,
    DocumentosComponent,
    GestionClasesComponent,
    ComercialComponent,
    CrearContactoComponent,
    ListarContactoComponent,
    ListarAlquilerComponent,
    PieChartComponent,
    PolarChartComponent,
    BarChartComponent,
    BarTotalChartComponent,
    ExportacionesComponent,
    ObservacionesComponent,
    TablonComponent,
    ClaustroComponent,
    SortDirective,
    ObsModalComponent,
    AddContactoComponent,
    AddTipoContactoComponent,
    AddCicloComponent,
    AddReferidoComponent,
    EstadoCambioComponent,
    AddLineaComponent,
    BulkModalComponent,
    CursosIntensivosComponent,
    CursosExtracurricularesComponent,
    ModalCursosIntensivosComponent,
    HistorialComponent,
    AlumnosComponent
  ],
  providers: [
    AuthGuardService,
    CentroService,
    CursosService,
    UploadService,
    AulaService,
    AulasService,
    UsersService,
    ProfileService,
    AdminPadreService,
    CustomizeService,
    StatsService,
    AdminAgendaService,
    TitleCasePipe,
    MinutosPipe,
    DatePipe,
    DocumentosService,
    ComercialService,
    MatSnackBar,
    AlquilerService,
    CsvService,
    ExcelService,
    StringSubstrPipe,
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {}},
    {provide: MatDialogRef, useValue: {}},
    {provide: MAT_DIALOG_DATA, useValue: []},
  ],

  exports: [JornadaComponent]

})
export class AdminModule {
}
