import {Component, ViewChild, OnDestroy, OnInit, ViewEncapsulation, Inject} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';
import {CentroService} from '../admin-centro/services/centro.service';
import {Router, ActivatedRoute} from '@angular/router';
import {LoginService} from '../../pages/services/login.service';
import {UsersService} from '../admin-users/services/users.service';
import {UploadService} from '../../services/upload.service';
import {NgForm} from '@angular/forms';
import {Datos} from '../../../modelos/datos.modelo';

// *** PDF ***
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import {HttpClient} from '@angular/common/http';
import {WebcamImage, WebcamInitError} from 'ngx-webcam';
import {Subject} from 'rxjs';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-crear-datos',
  templateUrl: './crear-datos.component.html',
  styleUrls: ['./crear-datos.component.scss']
})
export class CrearDatosComponent implements OnInit, OnDestroy {
  closeResult = '';
  public provinciasSubscription: Subscription = new Subscription();
  public municipiosSubscription: Subscription = new Subscription();
  public cursosSubscription: Subscription = new Subscription();
  public usersSubscription: Subscription = new Subscription();
  public active: boolean;

  public errors: WebcamInitError[] = [];

  // latest snapshot
  public webcamImage: WebcamImage = null;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  public type: string;
  public provincias: any[] = [];
  public municipios: any[] = [];
  public users: any[] = [];
  public provincia: number;
  public municipio: number;
  public tipo: string;
  isAlumno: boolean;
  cursos: any[] = [];
  public permisoImagen: boolean;
  public permisoNotas: boolean;
  nombre = '';
  apellidos = '';

  // Fechas
  fechas = new Date;

  // Para saber el mes actual
  dia = this.fechas.getDate();
  // Para saber el mes actual
  mes = this.fechas.getMonth();
  // Para saber el año actual
  anyo = this.fechas.getFullYear();

  @ViewChild('f') loginForm: NgForm;

  public dato: Datos;

  constructor( private _route: ActivatedRoute, private _centro: CentroService, private _user: UsersService,
               private _upload: UploadService, private _login: LoginService, private _router: Router, private _http: HttpClient
  ) {
    this.dato = new Datos(
      0,
      '',
      '',
      '',
      '',
      new Date(),
      '',
      '',
      [],
      0,
      0,
      '',
      [],
      [],
      [],
      false,
      false,
      '',
      '',
      '',
      '',
      {nivel: '', id: 4},
      '', '', '', ''
    );

    this._route.params.subscribe(params => {
      this.type = params['type'];

    });
    this._route.queryParams.subscribe( queryparams => {
      this.dato.nombre = queryparams['nombre'];
      this.dato.apellidos = queryparams['apellidos'];
    });

    if (this.type === 'alumnos') {
      this._user.getCursosCentro();
      this.isAlumno = true;
    }
    this._centro.getProvincias();
    this._user.getUsers(this.type, 0);
  }

  ngOnInit() {
    this.provinciasSubscription = this._centro.provincias$.subscribe(res => {
      this.provincias = res;
    });
    this.cursosSubscription = this._user.cursos$.subscribe(res => {
      this.cursos = res;
    });
    this.municipiosSubscription = this._centro.municipios$.subscribe(res => {
      this.municipios = res;
    });
    this.usersSubscription = this._user.users$.subscribe(res => {
      this.users = res;
      // console.log(this.users);
    });
  }

  ngOnDestroy() {
    if (this.provinciasSubscription != null) {
      this.provinciasSubscription.unsubscribe();
    }
  }

  onSubmit() {
    if (this.loginForm.valid) {
      console.log(this.loginForm.value)
      this._user.add(this.loginForm.value, this.type);
    }
  }

  getMunicipios() {
    this._centro.getMunicipios(+this.dato.id_provincia);
  }

}
