<div class="row">
  <div class="col-12">
    <div class="form form-horizontal">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Generar Parciales {{aulaClase}}</h4>
        </div>
        <div class="form-body">
          <div class="form-group row">
            <button class="btn btn-raised btn-block" (click)="openAdd()"> Crear</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #modalEvents let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal">Crear Parcial</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <input type="hidden" name="id" [(ngModel)]="event.id">
        <div class="form-group">
          <label>Titulo</label>
          <div class="input-group">
            <input class="form-control" type="text" placeholder="Título" name="titulo"
                   id="titulo" [(ngModel)]="event.title" autocomplete="off" required>
          </div>
        </div>
        <div class="form-group">
          <label>Tipo</label>
          <div class="input-group">
            <select class="form-control" name="tipo"  id="tipo" [(ngModel)]="event.type">
              <option value="">Seleccionar tipo</option>
              <option value="examen">Examen</option>
              <option value="trabajo">Trabajo</option>
            </select>
          </div>
        </div>

        <label><input type="checkbox" value="true" [(ngModel)]="calendario">&nbsp;Desea agregar al calendario?</label>
        <div *ngIf="calendario">
          <div class="form-group">
            <label>Seleccion de fechas</label>
            <div class="input-group">
              <ngb-datepicker #dp (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t"
                              outsideDays="hidden">
              </ngb-datepicker>
            </div>
          </div>
          <div class="form-group">
            <div class="input-group">
              <label>Hora inicio</label>
              <ngb-timepicker [formControl]="fcInicio" [(ngModel)]="inicio" name="h_inicio" ></ngb-timepicker>
              <label>Hora fin</label>
              <ngb-timepicker [formControl]="fcFin" [(ngModel)]="fin" name="h_fin"></ngb-timepicker>
            </div>
            <div class="small form-text text-danger" *ngIf="!fcInicio.valid">
              <div *ngIf="fcInicio.errors['FueraRango']">Debes seleccionar una hora de INICIO entre {{inicioFijo.hour}}
                :{{inicioFijo.minute}} y {{finFijo.hour}}:{{finFijo.minute}}</div>
            </div>
            <div class="small form-text text-danger" *ngIf="!fcFin.valid">
              <div *ngIf="fcFin.errors['FueraRango']">Debes seleccionar una hora de FIN entre {{inicioFijo.hour}}
                :{{inicioFijo.minute}} y {{finFijo.hour}}:{{finFijo.minute}}</div>
            </div>
          </div>
          <ng-template #t let-date let-focused="focused">
        <span class="custom-day"
              [class.focused]="focused"
              [class.range]="isRange(date)"
              [class.faded]="isHovered(date) || isInside(date)"
              (mouseenter)="hoveredDate = date"
              (mouseleave)="hoveredDate = null">
          {{ date.day }}
        </span>
          </ng-template>
         </div>

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="addEvent(event.id, modal)"
              [disabled]="!event.title">Añadir
      </button>
    </div>
  </ng-template>

</div>
