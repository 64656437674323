import {Component, ViewChild, OnInit, ViewEncapsulation} from '@angular/core';
import {UploadService} from '../../../services/upload.service';
import {UsersService} from '../services/users.service';

import {NgForm} from '@angular/forms';
import {Datos} from '../../../../modelos/datos.modelo';
import {ActivatedRoute} from '@angular/router';
import swal from 'sweetalert2';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-titulos',
  templateUrl: './titulos.component.html',
  styleUrls: ['../users.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TitulosComponent implements OnInit {
  dato: Datos;
  activeTitulo: boolean;
  addTitulo: boolean;
  id_file: number;
  filesToUpload: Array<File>;
  id: number;
  @ViewChild('d') loginFormD: NgForm;
  titulo = null;

  constructor(private _user: UsersService, private _upload: UploadService, private _route: ActivatedRoute) {
    this.dato = new Datos(0, '', '', '', '', new Date(), '', '', [],
      0, 0, '', [], [], [], false, false, '',
      '', '', '', {nivel: '', id: ''}, '', '', '', '');
    this.id = this._route.snapshot.parent.params['id'];
    this._user.getDato(this.id);
  }

  ngOnInit() {
    this._user.dato$.subscribe(res => {
      if (res.titulos.length > 0) {
        this.addTitulo = true;
      }
      this.dato = res;
    });
  }


  onSubmit() {
    if (this.loginFormD.valid) {
      this.loginFormD.value.id = this.dato.id;
      this._user.addTitulo(this.loginFormD.value);
      this.addTitulo = true;
    }
  }

  borrarTitulo(id: number, index: number) {
    swal.fire({
      title: 'BORRAR',
      text: '¿Seguro que quiere borrar ' + this.dato.titulos[index].titulo + '?',
      icon: 'error',
      showCancelButton: true,
      confirmButtonText: 'Si, Eliminar!',
      cancelButtonText: 'No, Cancelar!',
      reverseButtons: true
    })
      .then((result) => {
        if (result.isConfirmed) {
          swal.fire('Mensaje', 'Se ha eliminado ' + this.dato.titulos[index].titulo, 'success');
          this.dato.titulos.splice(index, 1);
          if (this.dato.titulos.length === 0) {
            this.addTitulo = false;
          }
          this._user.removeTitulo(id);
        }
      });
  }

  addFile() {
    this._upload
      .makeFileRequest(this.id_file, this.filesToUpload, 'imagen', 'file')
      .then(
        (result: any) => {
          if (result) {
            this._user.getDato(this.id);
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  fileChangeEventFile(fileinput: any, type: string) {
    this.filesToUpload = <Array<File>>fileinput.target.files;
    this.addFile();
  }

  addtitulo() {
    (async () => {
      const { value: titulo } = await Swal.fire({
        title: 'Ingresar el Título',
        input: 'text',
        allowOutsideClick: false,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Guardar',
        reverseButtons: true,
      })
      if (titulo) {
        const datos = { id: this.dato.id, titulo: titulo.toUpperCase()};
        this._user.addTitulo(datos);
      }
    })()
  }


  agregar() {
    let en = false;
    const datos = this.dato.titulos;
    for (let i = 0; i < datos.length ; i++) {
      if (datos[i].titulo === this.titulo) {
        en = true;
      }
    }
    if (this.titulo !== 'null' && !en) {
    const dato = { id: this.dato.id, titulo: this.titulo};
    console.log(dato);
    this._user.addTitulo(dato);
    }
    this.titulo = 'null';
  }
}
