import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {AlumnosService} from '../services/alumnos.service';
import {RutasImg} from '../../../../utiles/Rutas';


@Component({
  selector: 'app-task-alumno',
  templateUrl: './task-alumno.component.html',
  styleUrls: ['../alumnos.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TaskAlumnoComponent implements OnInit, OnDestroy {
  public tasks: Array<any> = [];
  public tasksSubscription: Subscription = null;
  public task = {
    files: []
  };
  public type: string;
  public order: boolean;
  public ver_: boolean;
  public id: number;
  public index: number;
  public files: any[] = [];

  constructor (private _route: ActivatedRoute, private _alumnos: AlumnosService) {
    this.id = this._route.snapshot.parent.params['id'];
    this._alumnos.getTasks(false, 'fecha_t', this.id);
  }

  ngOnInit () {
    this.tasksSubscription = this._alumnos.datos$.subscribe(res => {
      this.tasks = res;
    });
  }

  ngOnDestroy () {
    this.tasksSubscription.unsubscribe();
  }

  ordenar (type: string) {
    this.order = !this.order;
    this._alumnos.getTasks(this.order, type, this.id);
  }

  ver (index: number) {
    this.tasks[index].index = index;
    this.task = this.tasks[index];
    this.ver_ = true;
    this.index = index;
  }
  verFile (index: string) {
    window.open(RutasImg + index);
  }

}

