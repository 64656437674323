<mat-card style="width: 100%">
  <mat-card-header>
    <mat-card-title>Calendario alquileres

    </mat-card-title>

  </mat-card-header>
  <mat-card-content>
    <div class="row">
      <div class="col-3" *ngFor="let aula of aulas">
        <mat-card class="aulas">
          <mat-card-header>
            <mat-card-title>
              Aula {{aula.numeroClase}}
              <button mat-icon-button class="float-right " style="margin-bottom: 2px" (click)="seleccionar(aula.id)">
                <i class="icon-plus"></i>
              </button>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content style="text-align: center">
            <mat-list>
              <div>
                <mat-list-item *ngIf="aula.aulaManyana != null">
                  <label>  <i class="ft-check-circle icono" ></i> {{aula.aulaManyana}} - Mañanas</label>

                </mat-list-item>
                <mat-divider *ngIf="aula.aulaTarde !== null"></mat-divider>
              </div>
              <div *ngIf="aula.aulaTarde != null">
                <mat-list-item >

                  <label>
                    <i class="ft-check-circle icono"></i> {{aula.aulaTarde}} - Tardes
                  </label>

                </mat-list-item>
                <mat-divider></mat-divider>
              </div>
              <div *ngFor="let alquiler of aula.alquileres; let i = index">
                <mat-divider *ngIf="i !== 0 "></mat-divider>
                <mat-list-item>

                      <label *ngIf="alquiler.contacto.empresa === null"><button type="button" class="btn " ngbPopover="{{alquiler.observaciones}}. {{alquiler.descripcion}}" triggers="mouseenter:mouseleave" popoverTitle="Información">
                        <i class="ft-info icono" ></i>
                      </button> {{alquiler.contacto.apellidos}}, {{alquiler.contacto.nombre}}</label>
                      <label *ngIf="alquiler.contacto.empresa !== null">
                        <i class="ft-info icono" ngbPopover="{{alquiler.observaciones}}. {{alquiler.descripcion}}" triggers="mouseenter:mouseleave" popoverTitle="Información" ></i>
                      {{alquiler.contacto.empresa}}  </label>

                </mat-list-item>
                <mat-divider></mat-divider>
                <div class="row cuadre" style="padding: 0; ">
                  <div class="col-6 colI" style="padding: 0">
                    <label> <i class="ft-calendar"></i> {{alquiler.fInicio | date: 'd/M/yy'}} - {{alquiler.fFin | date: 'd/M/yy'}} </label>
                  </div>
                  <div class="col-6 colD">
                    <label><i class="ft-clock"></i> {{alquiler.hInicio}} - {{alquiler.hFin}}</label>
                  </div>
                </div>
                <div class=" cuadre" >
                  <mat-chip-list   *ngIf="alquiler.dias === null" >

                      <mat-chip >L</mat-chip>
                      <mat-chip >M</mat-chip>
                      <mat-chip >X</mat-chip>
                      <mat-chip >J</mat-chip>
                      <mat-chip >V</mat-chip>
                      <mat-chip >S</mat-chip>
                      <mat-chip >D</mat-chip>


                    </mat-chip-list>
                  <mat-chip-list selectable multiple *ngIf="alquiler.dias !== null" >

                    <mat-chip   [selected]="alquiler.dias[0].active">L</mat-chip>
                    <mat-chip   [selected]="alquiler.dias[1].active">M</mat-chip>
                    <mat-chip   [selected]="alquiler.dias[2].active">X</mat-chip>
                    <mat-chip   [selected]="alquiler.dias[3].active">J</mat-chip>
                    <mat-chip   [selected]="alquiler.dias[4].active">V</mat-chip>
                    <mat-chip   [selected]="alquiler.dias[5].active">S</mat-chip>
                    <mat-chip   [selected]="alquiler.dias[6].active">D</mat-chip>


                  </mat-chip-list>
                </div>
                <mat-divider *ngIf="i < (aula.alquileres.length -1 )"></mat-divider>
              </div>

            </mat-list>
          </mat-card-content>

        </mat-card>
      </div>
    </div>
  </mat-card-content>
</mat-card>


<!-- Modal Para añadir alquiler -->
<ng-template #add class="text-center">
  <h2 mat-dialog-title class="text-center">Nuevo Alquiler</h2>
  <mat-dialog-content class="text-center">
    <div [formGroup]="addForm"   style="padding: 16px">
      <div class="row">

        <div class="col-2">
          <label class="form-label">Contacto alquiler </label>
          <select class="custom-select form-control"  formControlName="contacto" >
            <option selected value="">Selecciona</option>
            <option *ngFor="let cont of contactos" [value]="cont.id">{{cont.empresa}} {{cont.nombre}}, {{cont.apellidos}}</option>
          </select>
        </div>


        <div class="col-2 ">
          <label class="form-label">Fecha inicio </label>
          <input  type="date" class="form-control" formControlName="finicio">
        </div>



        <div class="col-2">

          <label class="form-label">Fecha fin </label>
          <input  type="date" class="form-control" formControlName="ffin">

        </div>

        <div class="col-1">
          <label class="form-label">Hora inicio</label>
          <ngb-timepicker  formControlName="hinicio" [spinners]="false"> </ngb-timepicker>
        </div>

        <div class="col-1">
          <label class="form-label">Hora fin</label>
          <ngb-timepicker  formControlName="hfin" [spinners]="false"></ngb-timepicker>
        </div>

        <div class="col-3">
          <label class="form-label">Dias de la semana <i class="ft-help-circle" matTooltip="No obligatorio. En caso de tratarse de un alquiler de larga duración, marca los dias de la semana en los que el aula queda ocupada">

          </i> </label>
          <mat-chip-list  selectable multiple  >

            <mat-chip #l="matChip" *ngFor="let dia of dias; let i=index" (click)="setSelected(l, i)">{{dia.dia}} </mat-chip>



          </mat-chip-list>
        </div>



      </div>
      <div class="row">

        <div class="col-2">
          <label class="form-label">Tipo de aula </label>
          <select class="custom-select form-control"  formControlName="taula" >
            <option selected value="">Selecciona</option>
            <option  value="blanca">Blanca</option>
            <option  value="informatizada">Informatizada</option>
          </select>
        </div>
        <div class="col-5">
          <label class="form-label">Observaciones sobre el alquiler <i class="ft-help-circle" matTooltip="No obligatorio. Para observaciones como software necesario, o equipo necesario">

          </i> </label>
          <textarea class="form-control" formControlName="observacion"></textarea>
        </div>
        <div class="col-5">
          <label class="form-label">Descripción del servicio</label>
          <textarea class="form-control" formControlName="descripcion"></textarea>
        </div>




    </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions  >
    <div class="row">
      <div class="col-1"></div>
      <div class="col-10">
        <button mat-button matDialogClose  class="aceptar float-left" >Cerrar</button>
        <button mat-button [mat-dialog-close]="true" class="aceptar"  [disabled]="!addForm.valid">Guardar</button>
      </div>
      <div class="col-1"></div>
    </div>

  </mat-dialog-actions>
</ng-template>
