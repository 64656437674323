<h4 class="info-top"><i class="ft-user"></i> Datos: {{dato?.nombre}} {{dato?.apellidos}} </h4>

<div class="row">
  <div class="col-md-3" >
    <div class="row">

      <div class="col-12" *ngIf="dato.img">
        <img class="img-aula img-thumbnail rounded mx-auto" [src]="dato.img | images: ''"
             (click)="openFoto(cam)">
        <button type="button" class="btn-PDF-aula btn btn-raised btn-danger" (click)="open(pdf)">
          <i style="font-size:24px" class="fa">&#xf1c1;</i> Exportar a PDF
        </button>
      </div>
    </div>
    <div class="row" *ngIf="type === 'alumnos'">
      <div class="col-12">


          <mat-checkbox  [(ngModel)]="promocionar" name="asignar" (change)="removePromocion()">¿Añadir/Eliminar prematricula?</mat-checkbox>

      </div>


      <form class="form form-horizontal " #p="ngForm" (ngSubmit)="savePromocion()" *ngIf="type === 'alumnos' && promocionar">
        <div class="col-12">
          <div class="form-group row" >
            <label class=" label-control">Prematricula:</label><br>
            <div class="">
              <select class="form-control" [(ngModel)]='promocion.modulo' name="curso" required [disabled]="ac === false" (change)=" getAulasP() ">
                <option  [selected]="promocion === null"  [value]="null">Seleccione módulo</option>

                  <option *ngFor="let curso of cursos" [value]="curso.id"> {{curso.nivel}} - {{curso.acronimo}}</option>


              </select>
            </div>
          </div>
        </div>



        <div class="col-12">
          <div class="form-group row" >
            <label class="label-control">Aula:</label><br>

              <select class="form-control"  [(ngModel)]='promocion.id' name="clase" [disabled]="ac === false">
                <option label="Seleccionar" [value]="null"></option>



                  <option *ngFor="let clase of aulasP"
                          [value]="clase.id"> {{clase.anyo_curso}} {{clase.acronimo}} {{clase.aula}} - {{clase.curso}}</option>


              </select>
            </div>


        </div>
        <div class="form-actions">
          <button type="submit" class="btn btn-raised btn-primary btn-block " *ngIf="ac === true">
            <i class="ft-upload-cloud"></i> Guardar
          </button>
          <button type="button" class="btn btn-raised btn-primary btn-block " *ngIf="ac === false" (click)="modificar()">
            <i class="ft-save"></i> Modificar
          </button>
          <button type="button" (click)="promocionarCurso()" class="btn btn-raised btn-secondary btn-block" >
            <i class="ft-book"></i> Promocionar
          </button>
        </div>
      </form>




    </div>



  </div>
  <div class="col-md-9" id="content" #content>
    <div class="card">
      <div class="card-body">
        <div class="px-3">
          <form class="form form-horizontal striped-rows form-bordered" novalidate="" (ngSubmit)="onSubmit()"
                #f="ngForm">
            <input type="hidden" name="id" [(ngModel)]="dato.id">
            <div class="form-body">
              <div class="form-group row">
                <label class="col-md-3 label-control">Provincias</label>
                <div class="col-md-5">
                  <select [(ngModel)]='dato.id_provincia' name="provincia"
                          class="form-control"
                          (change)="getMunicipios()">
                    <ng-container *ngIf="provincias.length > 0 ">
                      <option selected *ngFor="let provincia of provincias"
                              [ngValue]="provincia.id">{{provincia.provincia}}
                      </option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-3 label-control">Municipio</label>
                <div class="col-md-5">
                  <select [(ngModel)]='dato.id_municipio'
                          name="id_municipio"
                          required
                          class="form-control">
                    <ng-container *ngIf="municipios">
                      <option *ngFor="let municipio of municipios"
                              [ngValue]="municipio.id"> {{ municipio.municipio }}
                      </option>
                    </ng-container>
                    <ng-container *ngIf="dato.municipio &&  !active">
                      <option [ngValue]="dato.id_municipio">{{dato.municipio}}</option>
                    </ng-container>
                  </select>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-md-3 label-control">Nombre</label>
                <div class="col-md-5">
                  <input [(ngModel)]='dato.nombre' required type="text" class="form-control width-input"
                         name="nombre" placeholder="Nombre">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-3 label-control">Apellidos</label>
                <div class="col-md-5">
                  <input [(ngModel)]='dato.apellidos' required type="text" class="form-control" name="apellidos"
                         placeholder="Apellidos">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-3 label-control">Direccion</label>
                <div class="col-md-5">
                  <input [(ngModel)]='dato.direccion' required type="text" class="form-control" name="direccion"
                         placeholder="Direccion">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-3 label-control">Código postal</label>
                <div class="col-md-5">
                  <input [(ngModel)]='dato.codPostal' required type="text" class="form-control" name="codPostal"
                         placeholder="CP">
                </div>
              </div>

              <div class="form-group row" *ngIf="type === 'alumnos'">
                <label class="col-md-3 label-control">Nacionalidad</label>
                <div class="col-md-5">
                  <input [(ngModel)]='dato.Nacionalidad' required type="text" class="form-control width-input"
                         name="nacionalidad" placeholder="Nacionalidad">
                </div>
              </div>

              <div class="form-group row" *ngIf="type === 'alumnos'">
                <label class="col-md-3 label-control">Provincia nacimiento</label>
                <div class="col-md-5">
                  <input [(ngModel)]='dato.provinciaNacimiento' required type="text" class="form-control width-input"
                         name="provinciaNacimiento" placeholder="Provincia de nacimiento">
                </div>
              </div>
              <div class="form-group row" *ngIf="type === 'alumnos'">
                <label class="col-md-3 label-control">Ciudad nacimiento</label>
                <div class="col-md-5">
                  <input [(ngModel)]='dato.ciudadNacimiento' required type="text" class="form-control width-input"
                         name="ciudadNacimiento" placeholder="Ciudad de nacimiento">
                </div>
              </div>
              <div class="form-group row" *ngIf="type === 'alumnos'">
                <label class="col-md-3 label-control">País de estudios previos</label>
                <div class="col-md-5">
                  <input [(ngModel)]='dato.paisEstudiosPrevios' required type="text" class="form-control width-input"
                         name="paisEstudios" placeholder="País de estudios previos">
                </div>
              </div>
              <div class="form-group row" >
                <label class="col-md-3 label-control">Fecha de Nacimiento</label>
                <div class="col-md-5">
                  <input [(ngModel)]='dato.nacimiento' required type="date" class="form-control" name="nacimiento">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-3 label-control">Dni</label>
                <div class="col-md-5">
                  <input [(ngModel)]='dato.dni' type="text" class="form-control" name="dni" placeholder="Dni"
                         pattern="(([X-Z]{1})([-]?)(\d{7})([-]?)([A-Z]{1}))|((\d{8})([-]?)([A-Z]|[^A-Z]{1}))">
                </div>
              </div>

              <div class="form-group row">
                <label class="col-md-3 label-control">Nª SIP</label>
                <div class="col-md-5">
                  <input [(ngModel)]='dato.sip' type="text" class="form-control" name="sip" placeholder="SIP"
                         >
                </div>
              </div>

              <div class="form-group row">
                <label class="col-md-3 label-control">Observaciones matricula</label>
                <div class="col-md-5">
                  <textarea [(ngModel)]='dato.observacionesMatricula'  class="form-control" name="observacionesMatricula" placeholder="Observaciones relacionadas con la matricula"
                  ></textarea>
                </div>
              </div>

              <div class="form-group row" *ngIf="type === 'alumnos'">
                <label class="col-md-3 label-control">Modulo</label>
                <div class="col-md-5">
                  <select class="form-control" [(ngModel)]='dato.curso.id' name="curso" required>
                    <ng-container *ngFor="let curso of cursos">
                      <option *ngIf="dato.curso.id == curso.id" [value]="curso.id"
                              selected> {{curso.nivel}} - {{curso.acronimo}}</option>
                      <option *ngIf="dato.curso.id != curso.id"
                              [value]="curso.id"> {{curso.nivel}} - {{curso.acronimo}}</option>
                    </ng-container>
                  </select>
                </div>
              </div>

              <div class="form-group row" *ngIf="type === 'alumnos' && activar">
                <label class="col-md-3 label-control">Aula Asignada</label>
                <div class="col-md-5">
                  <input [(ngModel)]='aula' required type="text" class="form-control width-input"
                         name="aula" placeholder="Aula Asignada" disabled>
                </div>
              </div>

              <div class="form-group row" *ngIf="type === 'alumnos' && activar">
                <label class="col-md-3 label-control" style="visibility: hidden"> Desea reasignar el aular?</label>
                <div class="col-md-5">
                  <mat-checkbox  [(ngModel)]="asignar" name="asignar" > Desea reasignar el aula?</mat-checkbox>
                </div>
              </div>

              <div class="form-group row" *ngIf="type === 'alumnos' && asignar">
                <label class="col-md-3 label-control">Seleccionar Aula</label>
                <div class="col-md-5">
                  <select class="form-control" (change)="cambiarAula(clase)" [(ngModel)]='clase' name="clase">
                    <option label="Seleccionar"></option>
                    <option *ngFor="let clase of aulas" [ngValue]="clase.id"> {{clase.anyo_curso}} {{clase.acronimo}} {{clase.aula}} - {{clase.curso}}</option>
                  </select>
                </div>
              </div>

              <div class=" row" *ngIf="type === 'alumnos'">
                <label class="col-md-3 label-control" style="text-align: end">Autoriza</label>
                <div class="col-md-5 ">
                  <div class="form-group form-check">
                    <input type="checkbox" class="form-check-input " id="check1" [(ngModel)]="dato.permisoImagen" [value]="dato.permisoImagen" name="permisoImagen">
                    <label class="form-check-label" for="check1">Uso de imágen</label>
                  </div>

                  <div class="form-group form-check">
                    <input type="checkbox" class="form-check-input " id="check2" [(ngModel)]="dato.permisoNotas" [value]="dato.permisoNotas" name="permisoNotas">
                    <label class="form-check-label" for="check2">Envio de notas</label>
                  </div>

                </div>


              </div>


            </div>
            <div class="form-actions">
              <button type="submit" class="btn btn-raised btn-primary">
                <i class="ft-save"></i> Guardar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #pdf let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Exportar a PDF</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <div class="input-group">
          <label for="typeahead-basic2" class="input-group-text">Curso desde: </label>
          <input id="typeahead-basic2" type="text" class="form-control" [(ngModel)]="cursoDesde" name="something1">
        </div>
        <div class="input-group">
          <label for="typeahead-basic4" class="input-group-text">Curso hasta: </label>
          <input id="typeahead-basic4" type="text" class="form-control" [(ngModel)]="cursoHasta" name="something2">
        </div>
        <div class="input-group">
          <label for="typeahead-basic" class="input-group-text">Año escolar: </label>
          <input id="typeahead-basic" type="text" class="form-control" [(ngModel)]="anyoEscolar" name="something3">
        </div>
        <div class="input-group">
          <label for="typeahead-basic1" class="input-group-text">Fecha de matricula: </label>
          <input id="typeahead-basic1" type="date" class="form-control" [ngModel]="fechaActual | date:'yyyy-MM-dd'"
                 (ngModelChange)="fechaActual=$event" name="something4">
        </div>

      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="generatePdfAbastos()">Exportar matricula Abastos</button>
    <button type="button" class="btn btn-outline-dark" (click)="generatePdf()">Exportar matricula Progresa</button>
  </div>
</ng-template>

<ng-template #cam let-modal>
  <div class="modal-header" style="text-align: center !important;">
    <h4 class="modal-title" id="modal-basic-title2">Fotografia </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <webcam *ngIf="!webcamImage" [height]="500" [width]="500"
            [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
            [captureImageData]="true"
            (initError)="handleInitError($event)"
    ></webcam>
    <div class="form-group" id="barraImages" hidden>
      <div class="input-group">
            <span class="input-group-btn">
                    <span class="btn btn-default btn-file">
                        <input id="buscadorPersona" name="filebutton-normal" type="file" class="file"
                               (change)="fileChangeEvent($event, 'user')">
                    </span>
            </span>
        <input type="text" class="form-control" readonly>
      </div>
    </div>
    <div class="snapshot" *ngIf="webcamImage">

      <img [src]="webcamImage.imageAsDataUrl"/>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" onclick="document.getElementById('buscadorPersona').click()" *ngIf="!webcamImage">Subir foto</button>
    <button type="button" class="btn btn-outline-dark" (click)="triggerSnapshot();" *ngIf="!webcamImage">Tomar foto</button>
    <button type="button" class="btn btn-outline-dark boton-cancelar" (click)="otra()" *ngIf="webcamImage">Descartar foto</button>
    <button type="button" class="btn btn-outline-dark boton-subir" (click)="modal.close('foto')" *ngIf="webcamImage">Guardar foto</button>
  </div>

</ng-template>
