import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Subject} from 'rxjs/Subject';
import {Router} from '@angular/router';
import {Rutas} from '../../../../utiles/Rutas';
import {LoginService} from '../../../pages/services/login.service';
import Swal from 'sweetalert2';
import swal from 'sweetalert2';


@Injectable()
export class UsersService {
  private datoSource = new Subject<any>();
  public dato$ = this.datoSource.asObservable();

  private AsignaturaSource = new Subject<any>();
  asignatura$ = this.AsignaturaSource.asObservable();

  private datosAlumnosSource = new Subject<any>();
  public datosAlumnos$ = this.datosAlumnosSource.asObservable();

  private datosConvalidacionSource = new Subject<any>();
  public datosConvalidacion$ = this.datosConvalidacionSource .asObservable();

  public usersSource = new Subject<any>();
  public users$ = this.usersSource.asObservable();

  private contactosSource = new Subject<any>();
  public contactos$ = this.contactosSource.asObservable();

  private cursosSource = new Subject<any>();
  public cursos$ = this.cursosSource.asObservable();


  constructor(private http: HttpClient, private router: Router, private _login: LoginService) {
  }

  activar(estado: boolean, id: number) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {
      estado: estado,
      id: id
    };
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'activate/credentials', params, {headers: headers}).subscribe(res => {
      if (res['code'] === 200) {
        this.datoSource.next(res['id']);
      }
    }, error => {
      console.log(error);
    });
  }

  getDocumentos(id) {
    return this.http.get(Rutas + 'get/obligatorios?id_user=' + id + '&token=' + localStorage.getItem('token'));
  }

  updateCredentials(email: any, id: number) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {
      email: email,
      id: id
    };
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');

    return new Promise((resolve, reject) => {
      this.http.post(Rutas + 'update/credentials', params, {headers: headers}).subscribe(() => {
        resolve(true);
      }, error => {
        reject(error);
      });
    });
  }

  getCredenciales(id: number) {
    this.http.get(Rutas + 'get/credentials?id=' + id + '&token=' + localStorage.getItem('token')).subscribe(data => {
      if (data['code'] === 200) {
        this.usersSource.next(data['id']);
      }
    }, error => {
      console.log(error);
    });
  }


  getUsers(tipo: string, aula: number) {
    const role = this.role(tipo);
    this.http.get(Rutas + 'get/users?token=' + localStorage.getItem('token') + '&role=' + role + '&aula=' + aula).subscribe(data => {
      if (data['code'] === 200) {
        data['id']['tipo'] = tipo;
        data['id']['aula'] = aula;
        this.usersSource.next(data['id']);
      }
    }, error => {
      console.log(error);
    });
  }

  getCursosCentro() {
    // tslint:disable-next-line:max-line-length
    this.http.get(Rutas + 'cursos/alumno/centro?token=' + localStorage.getItem('token') + '&id=' + this._login.getIdentity().centro.id).subscribe(data => {
      if (data['code'] === 200) {
        this.cursosSource.next(data['id']);
      }
    }, error => {
      console.log(error);
    });
  }

  addPadre(user: any, numero: any, alumno: number) {
    user.role = 5;
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    user.centro = this._login.getIdentity().centro.id;
    console.log(user);
    const json = {user};
    const data = JSON.stringify(json);
    console.log(data);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'add/new/padre', params, {headers: headers}).subscribe(res => {
      console.log(res);
      if (res['code'] === 200) {
        const padreId: any[] = res['id'].tutores;
        const pos = padreId.length - 1;
        this.addContactoPadre(numero, 2, padreId[pos].id, alumno);
      }
      if (res['code'] === 999) {
        Swal.fire('', 'El correo ya existe', 'warning')
      }
    });
  }


  add(user: any, tipo: string, numero?: any, ) {
    user.role = this.role(tipo);
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    user.centro = this._login.getIdentity().centro.id;
    console.log(user);
    const json = {user};
    const data = JSON.stringify(json);
    console.log(data);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'add/new/usuario', params, {headers: headers}).subscribe(res => {
      if (res['code'] === 200) {
        console.log(res);
        this.datoSource.next(res['id']);
        this.getUsers(tipo, 0);
        if ( tipo !== 'padres' ) {

          this.router.navigate(['/admin/users/' + res['id'].id  + '/' + tipo + '/dato']);
        } else {
          const padreId: any[] = res['id'].tutores;
          const pos = padreId.length - 1;

          console.log(padreId, pos);
          // this.addContactoPadre(numero, 2, padreId[pos].id, res['id'].id);
        }
      } else {
        if (res['code'] === 999) {
          Swal.fire('', 'Ya existe el DNI', 'warning');
        }
      }
    }, error => {
      console.log(error);
    });
  }

  addIban(user: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {user};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'add/iban', params, {headers: headers}).subscribe(res => {
      console.log(res);
      if (res['code'] === 200) {
        this.datoSource.next(res['id']);
      } else {
        console.log(res);
      }
    }, error => {
      console.log(error);
    });
  }

  addObservaciones(user: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const data = JSON.stringify(user);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    return this.http.post(Rutas + 'user/log', params, {headers: headers});
  }

  getObs(id: any) {
    return this.http.get(Rutas + 'user/logs/claustro?iduser=' + id + '&token=' + localStorage.getItem('token'));
  }

  getObsClaustro(id: any) {
    return this.http.get(Rutas + 'user/logs?iduser=' + id + '&token=' + localStorage.getItem('token'));
  }

  addTitulo(user: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {user};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'add/titulo', params, {headers: headers}).subscribe(res => {
      if (res['code'] === 200) {
        this.datoSource.next(res['id']);
      }
    }, error => {
      console.log(error);
    });
  }

  removeTitulo(id: number) {
    this.http.get(Rutas + 'remove/titulo?id=' + id + '&token=' + localStorage.getItem('token'))
      .subscribe(data => {
        if (data['code'] === 200) {
          this.datoSource.next(data['id']);
        }
      }, error => {
        console.log(error);
      });
  }

  edit(user: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {user};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'edit/datos/usuario', params, {headers: headers}).subscribe(res => {
      console.log(res);
      if (res['code'] === 200) {
        this.datoSource.next(res['id']);
        Swal.fire('', 'Los datos fueron actualizados correctamente', 'success');
      }
    }, error => {
      console.log(error);
    });
  }

  editContacto(id, valor) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {id, valor};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    return this.http.post(Rutas + 'update/contacto', params, {headers: headers});

  }

  removeContacto(id: number) {
    this.http.get(Rutas + 'remove/contacto?id=' + id + '&token=' + localStorage.getItem('token'))
      .subscribe(data => {
        if (data['code'] === 200) {
          this.datoSource.next(data['id']);
          swal.fire( 'Mensaje', 'Se ha eliminado', 'success');
        }
      }, error => {
        console.log(error);
      });
  }

  removeUser(id: number) {
    this.http.get(Rutas + 'delete/user?id=' + id + '&status=0&token=' + localStorage.getItem('token'))
      .subscribe(() =>
        error => {
          console.log(error);
        });
  }

  addContacto(valor: string, tipo: number, user: Number) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {user, valor, tipo};

    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'add/contacto', params, {headers: headers}).subscribe(res => {
      console.log(res);
      if (res['code'] === 200) {
        this.datoSource.next(res['id']);
      }
    }, error => {
      console.log(error);
    });
  }

  addContactoPadre(valor: string, tipo: number, user: Number, id) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {user, valor, tipo};

    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'add/contacto', params, {headers: headers}).subscribe(res => {
      console.log(res);
      if (res['code'] === 200) {
        console.log(res['id']);
        this.getDato(id);

      }
    }, error => {
      console.log(error);
    });
  }

  getContactos(id: number) {
    this.http.get(Rutas + 'get/contactos?id=' + id + '&token=' + localStorage.getItem('token'))
      .subscribe(data => {
        if (data['code'] === 200) {
          this.contactosSource.next(data['id']);

        }
      }, error => {
        console.log(error);
      });
  }

  getDato(id: number) {
    this.http.get(Rutas + 'get/dato?token=' + localStorage.getItem('token') + '&id=' + id).subscribe(data => {
      if (data['code'] === 200) {
        this.datoSource.next(data['id']);
      }
    }, error => {
      console.log(error);
    });
  }

  role(valor: string) {
    const roles = ['profesores', 'administrativos', 'alumnos', 'padres'];
    for (let i = 0; i < roles.length; i++) {
      if (roles[i] === valor) {
        return i + 2;
      }
    }

  }

  getAlumnosToExportExcelAndCSV(id: number) {
    this.http.get(Rutas + 'alumnos/aula_export?id=' + id + '&token=' + localStorage.getItem('token')).subscribe(data => {
      if (data['code'] === 200) {
        this.datosAlumnosSource.next(data['id']);
      }
    }, error => {
      console.log(error);
    });
  }

  getAlumnosToExportSol(id: number) {
    return this.http.get(Rutas + 'alumnos/aula_export_sol?id=' + id + '&token=' + localStorage.getItem('token'));
  }

  getAulaName(id: number) {
    return this.http.get(Rutas + 'nombre/aula?id=' + id + '&token=' + localStorage.getItem('token'));
  }

  getAlumnosToExportMAil(id: number) {
    return this.http.get(Rutas + 'alumnos/aula_export_mails?id=' + id + '&token=' + localStorage.getItem('token'));
  }

  getAlumnosPga(idAula: number) {
    return this.http.get(Rutas + 'alumnos/aula_export_datos_planos?id=' + idAula + '&token=' + localStorage.getItem('token'));
  }

  getAlumnosToExportDatos(idAula: number) {
     this.http.get(Rutas + 'alumnos/aula_export_datos_planos?id=' + idAula + '&token=' + localStorage.getItem('token'))
       .subscribe( (res) => {
       console.log('RESPUESTA', res['id']);
    })
  }

  getAsignatura(id) {
    return this.http.get(Rutas + 'alumno/asignaturas?id=' + id + '&token=' + localStorage.getItem('token'));
  }

  addConvalidacion(datos) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {datos};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
   return this.http.post(Rutas + 'add/convalidacion/alumno', params, {headers: headers});
  }

  getConvalidacion(id) {
   return this.http.get(Rutas + 'get/convalidaciones/alumno?id=' + id + '&token=' + localStorage.getItem('token')).toPromise();
  }

  updateConvalidacion(datos) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {datos};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    return this.http.post(Rutas + 'update/convalidacion/alumno', params, {headers: headers});
  }

  // -PROMOCIONES
  addPromocion(data) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {data};
    const datos = JSON.stringify(json);
    const params = 'json=' + datos + '&token=' + localStorage.getItem('token');
    return this.http.post(Rutas + 'add/promocion', params, {headers: headers});

  }

  updatePromocion(data) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {data};
    const datos = JSON.stringify(json);
    const params = 'json=' + datos + '&token=' + localStorage.getItem('token');
    return this.http.post(Rutas + 'update/promocion', params, {headers: headers});

  }

  removerPromocion(id) {
    return this.http.get(Rutas + 'delete/promocion?id=' + id + '&token=' + localStorage.getItem('token'));
  }

  removerConvalidacion(id) {
   return this.http.get(Rutas + 'delete/convalidacion/alumno?id=' + id + '&token=' + localStorage.getItem('token'));
  }

  verificarAula(id_ciclo, id_user) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.get(Rutas + 'alumno/aula_aulas?id_ciclo=' + id_ciclo + '&id_user=' + id_user +
      '&token=' + localStorage.getItem('token'), {headers})
  }

  updateAula(datos) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const data = JSON.stringify(datos);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    return this.http.post(Rutas + 'update/aula_alumno', params, {headers: headers});
  }

  getCC() {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.get(Rutas + 'get/cc?token='
    + localStorage.getItem('token'), {headers})
  }

  searchTutores(nombre, apellidos) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const data = JSON.stringify({
      nombre: nombre,
      apellidos: apellidos
      });
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    return this.http.post(Rutas + 'search/tutor', params, {headers: headers});
  }

  addTutorExistente(hijo, tutor) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const data = JSON.stringify({
      hijo: hijo,
      tutor: tutor
    });
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    return this.http.post(Rutas + 'add/tutore', params, {headers: headers});
  }

  getAulasCiclo(id) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    console.log(id);
    const data = {
      ciclo: id
    };
    const params = 'json=' + JSON.stringify(data) + '&token=' + localStorage.getItem('token');
    return this.http.post(Rutas + 'get/aulas/ciclo', params, {headers: headers});
  }

  addCursoExtracurricular(data) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {data};
    const dato = JSON.stringify(json);
    const params = 'json=' + dato + '&token=' + localStorage.getItem('token');
    return this.http.post(Rutas + 'matricula/cursillos', params, {headers: headers});
  }

  getCursoExtracurricularesFitrado() {
    return this.http.get(Rutas + 'get/cursillos/activos?' + '&token=' + localStorage.getItem('token'));
  }

  deleteCursoVerano(data) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {data};
    const dato = JSON.stringify(json);
    const params = 'json=' + dato + '&token=' + localStorage.getItem('token');
    return this.http.post(Rutas + 'delete/matricula/cursillos', params, {headers: headers});
  }

  getCursoExtraAlumno(id) {
    return this.http.get(Rutas + 'get/cursillosby/alumno?id=' + id + '&token=' + localStorage.getItem('token'));
  }

  getHistorialNotas(id) {
    return this.http.get(Rutas + 'historico/cursos?idAlumno=' + id + '&token=' + localStorage.getItem('token'));
  }


}
