import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Horarios} from '../../../componentes/agenda/modelo/horarios';
import {Rutas} from '../../../../utiles/Rutas';
import {Subject} from 'rxjs/Subject';


@Injectable()
export class AdminAgendaService {
  listaSource: Subject<any> = new Subject();
  lista$ = this.listaSource.asObservable();
  horarioSource: Subject<any> = new Subject();
  horario$ = this.horarioSource.asObservable();

  constructor (private http: HttpClient) {
  }

  add (horario: Horarios) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {horario};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'agenda/config', params, {headers: headers}).subscribe(resp => {
        if (resp['code'] === '200') {
          this.list();
        }
      }
      , error => {
        console.log(error);
      });
  }

  get (id) {
    this.http.get(Rutas + 'agenda/get?token=' + localStorage.getItem('token') + '&id=' + id).subscribe(resp => {
      if (resp['code'] === 200) {
        this.horarioSource.next(resp['id']);
      }
    }, err => console.log('Error: :' + err.message));
  }

  list () {
    this.http.get(Rutas + 'agenda/list?token=' + localStorage.getItem('token')).subscribe(resp => {
      if (resp['code'] === 200) {
        this.listaSource.next(resp['id']);
      }
    }, err => console.log('Error: :' + err.message));
  }

  update (horario: Horarios) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {horario};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'agenda/update', params, {headers: headers}).subscribe(resp => {
        if (resp['code'] === '200') {
          this.list();
        }
      }
      , error => {
        console.log(error);
      });
  }

  check (id: number) {
    this.http.get(Rutas + 'agenda/check?token=' + localStorage.getItem('token') + '&id=' + id).subscribe(resp => {
      if (resp['code'] === 200) {
        this.list();
      }
    }, err => console.log('Error: :' + err.message));
  }

}
