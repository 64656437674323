import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {Subscription} from 'rxjs';
import {LoginService} from '../../../../pages/services/login.service';
import {JornadaService} from '../../services/jornada.service';
import {ActivatedRoute} from '@angular/router';
import {UsersService} from '../../services/users.service';
import {CentroService} from '../../../admin-centro/services/centro.service';
import Swal from 'sweetalert2';
import {RutasImg} from '../../../../../utiles/Rutas';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import {TitleCasePipe} from '@angular/common';
import {MinutosPipe} from '../../../../../pipes/minutos.pipe';
import { DatePipe } from '@angular/common';


pdfMake.vfs = pdfFonts.pdfMake.vfs;


@Component({
  selector: 'app-jornada',
  templateUrl: './jornada.component.html',
  styleUrls: ['./jornada.component.scss']
})
export class JornadaComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Output() public pdfemisor = new EventEmitter<any>();
  public fichajes: any[] = [];
  public id: number;
  public anos: any[] = [];
  public meses: any[] = [];
  public dias: any[] = [];
  public entrefechas: any[] = [];
  public config: any;
  public alerta: number;
  public ano: string;
  public mes: string;
  public dia: string;
  public nombre: string;
  public apellidos: string;
  public inicio: Date;
  public fin: Date;
  public idUser: number;
  public arreglados: any[] = [];
  user: any;
  logo: any;
  elemento = true;
  count = 0;
  public isCollapsed: boolean[] = [];
  mostrar = new Set<any>();

  public fichajesSubscription = new Subscription();
  public configSubscription = new Subscription();
  public anosSubscription = new Subscription();
  public mesesSubscription = new Subscription();
  public disSubscription = new Subscription();
  public entrefechasSubscription = new Subscription();
  public datoSubscription: Subscription = null;
  public empresaSubscription: Subscription = new Subscription();
  public logoSubscription: Subscription = new Subscription();

  actualPage = 1;
  role: string;
  editarMode = false;
  private hastaFecha = false;
  private clonadoUltimaPosicion: any;
  timeEntradaAdd = {
    hour: 8,
    minute: 0
  };
  timeSalidaAdd = {
    hour: 9,
    minute: 0
  };
  fechaAdd: any;

  empresa: any;

  // tslint:disable-next-line:max-line-length
  constructor(private _empleados: JornadaService, private _login: LoginService, private _centro: CentroService, private _route: ActivatedRoute,
              private _user: UsersService, private titlecasePipe: TitleCasePipe, private min: MinutosPipe, private dates: DatePipe ) {
    this.role = this._login.getIdentity().role;
    this.alerta = 0;
    this._empleados.getConfig();
  }

  ngOnInit() {
    this.ano = '';
    this.mes = '';
    this.dia = '';
    this.idUser = this._route.snapshot.parent.params['id'];
    if (this.idUser === undefined) {
      this.idUser = this._login.getIdentity().id;
    }
    document.getElementById('basic-addon3').addEventListener('click', (event) => {
      this.count++
      if (this.count === 5) {
        this.elemento = false;
      }
    });
    this.fichajesSubscription = this._empleados.fichajes$.subscribe(res => {
      this.fichajes = res;
      console.log(this.fichajes);
      this.montarArrayTablaFichajes();
    });
    this._user.getDato(this.idUser);
    this.datoSubscription = this._user.dato$.subscribe(res => {
      this.user = res;
    });
    this._centro.getCentro().subscribe( res => {
      if ( res['code'] === 200) {
        this.empresa = res['id'];
      }
    });
    this.logoSubscription = this._login.getLogo().subscribe(data => {
      console.log(data);
      this.logo = RutasImg + data['id'];
    });
    this.configSubscription = this._empleados.config$.subscribe(res => {
      this.config = res;
    });

    this.anosSubscription = this._empleados.anos$.subscribe(res => {
      this.anos = res;
    });

    this.mesesSubscription = this._empleados.meses$.subscribe(res => {
      this.meses = res;
    });

    this.disSubscription = this._empleados.dias$.subscribe(res => {
      this.dias = res;
    });

    this.entrefechasSubscription = this._empleados.entrefechas$.subscribe(res => {
      if (res) {
        console.log(res);
        this.entrefechas = res;
        // this.montadoarray(res);
      }
      // this.filtradoFichajes(); // FUNCION POR TERMINAR Y DETERMINAR LAS CONDICIONALES DE ELIMINAR O ACTUALIZAR FICHAJES
    });
  }

  ngOnDestroy() {
    this.fichajesSubscription.unsubscribe();
    this.configSubscription.unsubscribe();
    this.anosSubscription.unsubscribe();
    this.mesesSubscription.unsubscribe();
    this.disSubscription.unsubscribe();
    this.entrefechasSubscription.unsubscribe();
    this.empresaSubscription.unsubscribe();
  }

  getentreFechas(inicio, fin) {
    this.hastaFecha = true;
    this._empleados.getFichajesEntreFechas(this.idUser, inicio, fin);
  }

  getTotalCost() {
    if (this.arreglados) {
      return this.arreglados.map(t => t.horas).reduce((acc, value) => acc + value, 0);
    }
  }

  generarfichaje() {
    if (this.inicio !== undefined && this.fin !== undefined) {
      const inicio = String(this.inicio);
      const fin = String(this.fin);
      Swal.fire({
        title: 'Generar Fichaje',
        text: '¿Seguro que quiere generar ficha entre ' + this.dates.transform(this.inicio, 'd-M-y') + ' y ' + this.dates.transform(this.fin, 'd-M-y') + '?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        confirmButtonColor: '#3085d6',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Aceptar',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this._empleados.generarfichaje(this.idUser, inicio, fin);
          this.elemento = true;
          this.getentreFechas(this.inicio, this.fin);
        }
      });
    } else {
      Swal.fire('', 'Seleccione Fecha', 'warning');
    }
  }

  changeEditarMode() {
    if (this.editarMode === true) {
      this.editarMode = false;
    } else {
      this.editarMode = true;
    }
  }

  actualizarFichaje(posicionList: any, estado: any) {
    const posicion = posicionList + (10 * (this.actualPage - 1));
    // console.log(this.arreglados[posicion]);
    // console.log('CLONADO: ', this.clonadoUltimaPosicion);
    let fichajeUpdateData = {};

    if (estado === 'ENTRADA') {
      fichajeUpdateData = {
        id: this.arreglados[posicion].id_entrada,
        hour: this.arreglados[posicion].timeEntrada.hour,
        minute: this.arreglados[posicion].timeEntrada.minute
      }
    }

    if (estado === 'SALIDA') {
      fichajeUpdateData = {
        id: this.arreglados[posicion].id_salida,
        hour: this.arreglados[posicion].timeSalida.hour,
        minute: this.arreglados[posicion].timeSalida.minute
      }
    }

    if (fichajeUpdateData) {
      this._empleados.updateFichaje(fichajeUpdateData).subscribe(res => {
        if (res['code'] === 200) {

          if (estado === 'ENTRADA') {
            // tslint:disable-next-line:max-line-length
            this.arreglados[posicion].entrada = new Date(this.arreglados[posicion].entrada.setHours(this.arreglados[posicion].timeEntrada.hour, this.arreglados[posicion].timeEntrada.minute));
            if (this.clonadoUltimaPosicion.entrada === this.clonadoUltimaPosicion.salida && posicion === this.arreglados.length - 1) {
              // tslint:disable-next-line:max-line-length
              this.arreglados[posicion].salida = new Date(this.arreglados[posicion].entrada.setHours(this.arreglados[posicion].timeEntrada.hour, this.arreglados[posicion].timeEntrada.minute));
            }
          }
          if (estado === 'SALIDA') {
            // tslint:disable-next-line:max-line-length
            this.arreglados[posicion].salida = new Date(this.arreglados[posicion].salida.setHours(this.arreglados[posicion].timeSalida.hour, this.arreglados[posicion].timeSalida.minute));
          }

          // tslint:disable-next-line:max-line-length
          this.arreglados[posicion].horas = (this.arreglados[posicion].salida.valueOf() - this.arreglados[posicion].entrada.valueOf()) / 3600000;
        }
      }, error => {
        // console.log(error);
        return error;
      });
    }
  }

  editButton() {
    if (this.editarMode) {
      return 'btn btn-secondary m-0';
    } else {
      return 'btn btn-outline-secondary m-0';
    }
  }

  getentreFechasInicio(inicio: Date, fin: Date) {
    console.log('inicio', inicio)
    console.log('fin', fin)
    if (this.hastaFecha) {
      this.getentreFechas(inicio, fin);
    }
  }

  montarArrayTablaFichajes() {
    console.log('FICHAJES: ', this.fichajes);
    this.arreglados = [];

    let i = 0;
    while (i < this.fichajes.length) {
      let entrada: any;
      let salida: any;

      if (this.fichajes[i].estado === 'ENTRADA' && this.fichajes[i + 1]) {
        console.log('esto es entrada', i)
        entrada = this.fichajes[i];
        if (new Date(this.fichajes[i + 1].fecha.date).getDate() === new Date(this.fichajes[i].fecha.date).getDate()) {
          salida = this.fichajes[i + 1];
        } else {
          salida = 0;
        }
      } else {

        if ( this.fichajes[i].estado === 'SALIDA' && this.fichajes[i - 1].estado === 'SALIDA') {
          console.log('esto es adentro de salida', i);
          salida = this.fichajes[i];
          console.log(salida);
          entrada  = 0;
        } else {
          entrada = this.fichajes[i];
          salida = 0;
        }
      }
      let fila: any;
      if (entrada === 0) {
        fila = {
          dia: new Date(salida.fecha.date),
          entrada: 0,
          id_entrada: 0,
          salida: new Date(salida.fecha.date),
          id_salida: salida.id_fichaje,
          timeEntrada: {
            hour: null,
            minute: null
          },
          timeSalida: {
            hour: new Date(salida.fecha.date).getHours(),
            minute: new Date(salida.fecha.date).getMinutes()
          },
          horas: (new Date(salida.fecha.date).valueOf() - new Date(salida.fecha.date).valueOf()) / 3600000
        };
        this.arreglados.push(fila);

        i = i + 1;
      } else if (salida === 0) {
        fila = {
          dia: new Date(entrada.fecha.date),
          entrada: new Date(entrada.fecha.date),
          id_entrada: entrada.id_fichaje,
          salida: 0,
          id_salida: 0,
          timeEntrada: {
            hour: new Date(entrada.fecha.date).getHours(),
            minute: new Date(entrada.fecha.date).getMinutes()
          },
          timeSalida: {
            hour: null,
            minute: null
          },
          horas: (new Date(entrada.fecha.date).valueOf() - new Date(entrada.fecha.date).valueOf()) / 3600000
        };
        this.arreglados.push(fila);

        i = i + 1;
      } else {
        fila = {
          dia: new Date(entrada.fecha.date),
          entrada: new Date(entrada.fecha.date),
          id_entrada: entrada.id_fichaje,
          salida: new Date(salida.fecha.date),
          id_salida: salida.id_fichaje,
          timeEntrada: {
            hour: new Date(entrada.fecha.date).getHours(),
            minute: new Date(entrada.fecha.date).getMinutes()
          },
          timeSalida: {
            hour: new Date(salida.fecha.date).getHours(),
            minute: new Date(salida.fecha.date).getMinutes()
          },
          horas: (new Date(salida.fecha.date).valueOf() - new Date(entrada.fecha.date).valueOf()) / 3600000
        };

        this.arreglados.push(fila);

        i = i + 2;
      }


    }

    if (this.arreglados.length > 0) {
      // Clonar Última Posición
      this.clonadoUltimaPosicion = JSON.parse(JSON.stringify(this.arreglados[this.arreglados.length - 1]));

    }

    console.log(this.arreglados);
  }


  eliminarFichaje(id_entrada: any, id_salida: any, posicion: any) {
    const ids_entrada_salida = {
      id_entrada: id_entrada,
      id_salida: id_salida
    }

    Swal.fire({
      title: 'BORRAR',
      text: '¿Seguro que quiere borrar el fichaje?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this._empleados.removeFichaje(ids_entrada_salida).subscribe(res => {
          if (res['code'] === 200) {
            Swal.fire('', 'Se ha eliminado el fichaje', 'success');

            this.arreglados.splice(posicion, 1);
          }
        }, error => {
          Swal.fire('ERROR', 'Se ha producido un error inesperado', 'error');
        });
      }
    });
  }

  addFichaje() {
    if (this.fechaAdd) {
      Swal.fire({
        title: 'AÑADIR FICHAJE',
        text: '¿Seguro que quiere añadir el fichaje?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Aceptar',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          const fichajeAdd = {
            id: this.idUser,
            fecha: this.fechaAdd,
            timeEntrada: this.timeEntradaAdd,
            timeSalida: this.timeSalidaAdd
          };

          this._empleados.addFichaje(fichajeAdd).subscribe(res => {
            if (res['code'] === 200) {
              Swal.fire('', 'Se ha creado el nuevo fichaje', 'success');
              this.getentreFechas(this.dateAString(this.inicio), this.dateAString(this.fin));
            }
          }, error => {
            Swal.fire('Se ha producido un error inesperado', 'error');
          });
        }
      });
    } else {
      Swal.fire('', 'Completa todos los datos', 'warning');
    }
  }

  dateAString(date) {
    const fecha = new Date(date).toISOString();
    const trozos = fecha.split('T');
    return trozos[0];
  }

  show(data) {
    if (this.mostrar.has(data)) {
      this.mostrar.delete(data);
    } else {
      this.mostrar.add(data);
    }
  }

  fecha(fecha) {
    return this.dates.transform(fecha, 'dd/MM/yyyy');
  }

  convertirHora(hora) {
    return this.dates.transform(hora, 'HH:mm');
  }

  convertirMinutos(minutos) {
    return this.min.transform(minutos);
  }

  async generatePdf(action = 'download') {
    const documentDefinition = await this.getDocumentDefinition();
    switch (action) {
      case 'open':
        pdfMake.createPdf(documentDefinition).open();
        break;
      case 'print':
        pdfMake.createPdf(documentDefinition).print();
        break;
      case 'download':
        pdfMake.createPdf(documentDefinition).download(this.user.nombre + ' ' + this.user.apellidos + ' '
          + this.fecha(this.inicio) + ' ' + this.fecha(this.fin) + '.pdf');
        break;
      default:
        pdfMake.createPdf(documentDefinition).open();
        break;
    }
  }

  async imagenPdf(url: string, width: number, height: number, alineacion: string, rowSpan: number) {
    if (rowSpan > 1) {
      return {
        image: await this.getBase64ImageFromURL('../../assets/img/centro/logo.png'),
        width: width,
        alignment: alineacion,
        height: height,
        rowSpan: rowSpan
      };
    } else {
      return {
        image: await this.getBase64ImageFromURL('../../assets/img/centro/logo.png'),
        width: width,
        alignment: alineacion
      };
    }
  }

  async getDocumentDefinition() {
    return {
      content: [
        {text: 'Resumen Registros de Horas', fontSize: 20, bold: true, margin: [0, 0, 10, 0]},
        {text: 'Datos de Empresa:', fontSize: 15, bold: true},
        {canvas: [{type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1}]},
        {text: ''},
        {
          columns: [
           await this.imagenPdf(this.logo, 100, 90, 'left', 0),
            {
              width: 'auto',
              text: this.titlecasePipe.transform(this.empresa.razon_social) + '\n' +
              this.empresa.direccion + '  ' + this.empresa.municipio,
              margin: [10, 33, 0, 0],
            },
            {
              width: 'auto',
              text: this.empresa.cif,
              margin: [25, 33, 0, 0],
            },
          ]
        },
        {text: 'Datos de Empleado:', fontSize: 15, bold: true},
        {canvas: [{type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1}]},
        {text: ''},
        {
          columns: [
            {
              widths: ['*'],
              text: this.user.nombre + ' ' + this.user.apellidos,
              margin: [10, 20, 0, 20],
            },
            {
              widths: ['*'],
              text: this.user.dni,
              margin: [10, 20, 0, 20],
            },
            {
              widths: ['*'],
              text: this.fecha(this.user.nacimiento),
              margin: [10, 20, 0, 20],
            },
          ]
        },
        {text: 'Registros entre el ' + this.fecha(this.inicio) + ' y ' + this.fecha(this.fin), fontSize: 15, bold: true},
        {canvas: [{type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1}]},
        {text: ''},
        this.getRegistro(),
        {canvas: [{type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1}]},
        {
          columns: [
            {
              text: 'Total Horas',
              color: '#070707',
              fillColor: '#dedede',
              alignment : 'left',
              bold : true,
              margin: [0, 5, 0, 0]
            },
            {
              text: this.convertirMinutos(this.getTotalCost()),
              color: '#070707',
              fillColor: '#dedede',
              bold : true,
              margin: [136, 5, 0, 0]
            },
          ],
        },
        {text: ''},
        {
          columns: [
            {
              widths: ['*'],
              text: '--------------------------',
              margin: [90, 100, 0, 0],
            },
            {
              widths: ['*'],
              text: '--------------------------',
              margin: [90, 100, 0, 0],
            },
          ]
        },
        {
          columns: [
            {
              widths: ['*'],
              text: 'Firma Empresa',
              margin: [90, 0, 0, 0],
            },
            {
              widths: ['*'],
              text: 'Firma Empleado',
              margin: [90, 0, 0, 0],
            },
          ]
        },
      ]
    }
  }

  private getBase64ImageFromURL(url: string) {
    return new Promise((resolve, reject) => {
      console.log(url)
      const img = new Image();

      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = error => {
        reject(error);
      };
      img.src = url;
    });
  }

  getRegistro() {
    if (this.arreglados.length > 0) {
      return {
        table: {
          headerRows: 1,
          widths: ['*', '*', '*', '*'],
          body: [
            [
              {text: 'Dia', style: 'tableHeader'},
              {text: 'Entrada', style: 'tableHeader'},
              {text: 'Salida', style: 'tableHeader'},
              {text: 'Horas', style: 'tableHeader'}
            ],
            ...this.arreglados.map(ed => {
                if (ed.entrada === 0 && ed.salida !== 0) {
                  ed.entrada = ' ';
                  // tslint:disable-next-line:max-line-length
                  return [this.fecha(ed.dia), ed.entrada, this.convertirHora(ed.salida), this.convertirMinutos(ed.horas)];
                }
              if (ed.entrada !== 0 && ed.salida !== 0) {
                // tslint:disable-next-line:max-line-length
                return [this.fecha(ed.dia),  this.convertirHora(ed.entrada), this.convertirHora(ed.salida), this.convertirMinutos(ed.horas)];
              }

              if (ed.salida === 0 && ed.entrada !== 0) {
                ed.salida = ' ';
                // tslint:disable-next-line:max-line-length
                return [this.fecha(ed.dia),  this.convertirHora(ed.entrada), ed.salida, this.convertirMinutos(ed.horas)];
              }
            })
          ]
        },
        layout: 'lightHorizontalLines',
      }
    } else {
      return {
        table: {
          widths: ['*', '*', '*', '*'],
          body: [
            [
              {text: 'Dia', style: 'tableHeader2'},
              {text: 'Entrada', style: 'tableHeader2'},
              {text: 'Salida', style: 'tableHeader2'},
              {text: 'Horas', style: 'tableHeader2'}
            ],
            [
              {}, {}, {},
            ],
          ]
        },
        layout: 'lightHorizontalLines',
      };
    }
  }
}

