import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';


@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['../main-aula/aulas.component.scss']
})
export class ViewComponent implements OnInit {
  navLinks = [{}];
  id: number;

  constructor (private _router: Router, private _route: ActivatedRoute) {

    this._route.params.subscribe(params => {
      this.id = params['id'];
      this._router.navigate(['/admin/aula/' + params['id'] + '/view/']);
    });
  }
  ngOnInit() {
    this.navLinks = [
      {path: 'view', label: 'Datos'},
      {path: 'agenda', label: 'Agenda'},
      {path: 'aula', label: 'Alumnos'},
      {path: 'pdf', label: 'Generar Boletines'},
      {path: 'claustro', label: 'Claustro'}
    ];
  }
}
