<h2 mat-dialog-title class="text-center">Buscar tutor</h2>
<mat-dialog-content class="text-center">
  <div [formGroup]="searchForm"   style="padding: 16px">
    <div class="row">
      <!--- LINEA OBSERVACION --->

      <div class="col-4">
        <label class="form-label">Nombre </label>
        <input type="text" class="form-control" formControlName="nombre" (input)="search()" >

      </div>

      <div class="col-8">
        <label class="form-label">Apellidos</label>
        <input type="text" class="form-control" formControlName="apellidos" (input)="search()">
      </div>

    </div>
    <div>
      <table class="table-sm table-striped" *ngIf="tutores.length != 0">
        <thead>
        <tr>
          <th scope="col">Nombre</th>
          <th scope="col">Apellidos</th>
          <th scope="col">Añadir</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let tutor of tutores; let i = index">
          <td>{{tutor.nombre}}</td>
          <td>{{tutor.apellidos}}</td>
          <td>
            <i class="ft-plus font-medium-3 mr-2" style="color: #0ABC76" (click)="addTutor(tutor.id)"></i>
          </td>
        </tr>
        </tbody>
      </table>




    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions  >
  <div class="row">
    <div class="col-1"></div>
    <div class="col-10">
      <button mat-button mat-dialog-close  class="aceptar float-left" >Cerrar</button>

    </div>
    <div class="col-1"></div>
  </div>
</mat-dialog-actions>

