import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AlumnosService} from '../../../admin-alumnos/services/alumnos.service';
import {AdminExamenesService} from '../../../admin-examenes/services/admin-examenes.service';
import {Subscription} from 'rxjs/Subscription';

@Component({
  selector: 'app-nota-final-alumnos',
  templateUrl: './nota-final-alumnos.component.html',
  styleUrls: ['./nota-final-alumnos.component.scss']
})
export class NotaFinalAlumnosComponent implements OnInit {
  iduser: string;
  idCurso: string;
  alumnosSubscription: Subscription = new Subscription();
  alumno;
  dato;
  notas;

  constructor(private _route: ActivatedRoute, private notaService: AdminExamenesService, private alumnoService: AlumnosService) {
    this.iduser = this._route.snapshot.parent.params['id'];
    this.idCurso = localStorage.getItem('id_curso');
    this.alumnoService.getAlumno(this.iduser);
  }

  ngOnInit(): void {
    console.log(this.iduser, this.idCurso);
    this.getNotasFinales();
    this.getDatoAlumno();
  }

  getNotasFinales() {
    this.notaService.getNotasFinalUsers(this.idCurso, this.iduser).subscribe( (res) => {
     this.notas = res['id'];
    });
  }

  getDatoAlumno() {
    this.alumnosSubscription = this.alumnoService.alumno$.subscribe(res => {
      this.alumno = res.dato;
      this.dato = this.alumno.nombre + ' ' + this.alumno.apellidos;
    });
  }

}
