<div class="row">
    <div class="col-md-4">
        <div class="card">
            <div class="card-body">
                <div class="px-3">
                    <form class="form" novalidate="" (ngSubmit)="onSubmit()" #f="ngForm">
                        <input type="hidden" [(ngModel)]="user" name="user">
                        <div class="form-body">

                            <div class="row">
                                <div class="col-xl-4 col-lg-6 col-md-12">
                                    <fieldset class="form-group">
                                        <label >Tipo contacto</label>
                                        <select class="form-control" [(ngModel)]="tipo" name="tipo">
                                                <ng-container *ngIf="tipos.length > 0">
                                                    <option *ngFor="let tipo of tipos" [ngValue]="tipo.id" selected required>{{tipo.tipo}}</option>
                                                </ng-container>
                                            </select>
                                    </fieldset>
                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group col-12 mb-2">
                                    <label >Dato de contacto</label>
                                    <div class="position-relative has-icon-left">
                                        <input type="text" class="form-control" placeholder="Dato de contacto" [(ngModel)]="valor" required name="valor">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-actions right">
                            <button type="button" class="btn btn-raised btn-warning mr-1">
                                    <i class="ft-x"></i> Cancel
                                </button>

                            <button type="submit" class="btn btn-raised btn-primary" [disabled]="!f.valid">
                                    <i class="fa fa-check-square-o"></i> Guardar
                                </button>


                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>


    <div class="col-sm-8" *ngIf='rows.length > 0'>
        <div class="table-responsive">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>Tipo</th>
                        <th>Dato</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of rows; let i = index">
                        <td>{{row.tipo}}</td>
                        <td>{{row.dato}}</td>
                        <td>
                            <ul class="list-inline">
                                <li class="list-inline-item danger p-0">
                                    <i class="ft-x font-medium-3 mr-2" (click)="delete(row.id, i)"></i>
                                </li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
