import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';



@Component({
  selector: 'app-padre',
  templateUrl: './padre.component.html'
})
export class PadreComponent {
  navLinks = [{}];
  id: number;

  constructor (private _route: ActivatedRoute) {
    this._route.params.subscribe(params => {
      this.id = params['id'];
    });
    this.navLinks = [{path: 'datos', label: 'Datos'}, { path: 'hijos', label: 'Hijos'}];
  }

}
