import {Component, Input, OnInit} from '@angular/core';
import {Label} from 'ng2-charts';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';

@Component({
  selector: 'app-bar-total-chart',
  templateUrl: './bar-total-chart.component.html',
  styleUrls: ['./bar-total-chart.component.scss']
})
export class BarTotalChartComponent implements OnInit {
  @Input() total: any;

  @Input() datos: any;
  @Input() titulo: any;

  single: any[] = [];
  showLegend = true;
  showLabels = true;
  designatedTotal: number;

  colorScheme = {
    domain: [ '#aae3f5']
  };


  constructor() { }

  ngOnInit(): void {

    this.single.push(this.datos);
    this.designatedTotal = this.total;


  }


}
