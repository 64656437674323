// Importamos los componentes necesarios
import {Pipe, PipeTransform} from '@angular/core';

// Le ponemos un nombre a la tuberia
@Pipe({name: 'get_id_examen'})

// Definimos la clase implementado la interface PipeTransform
export class GetIdExamenPipe implements PipeTransform {
  transform (notas: any, alumno: any): any {
    let value: any;
    if(notas) {
      notas.forEach(item => {
        if ( item.id_alumno === alumno.id) {
          value = item.id_examen_alumno;
        }
      });
    }
    return value;
  }

}
