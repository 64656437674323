import { RolePipe } from './pipes/role.pipe';
import { NgModule } from '@angular/core';
import { ImagesPipe} from './pipes/images.pipe';
import { ContactosPipe } from './pipes/contactos.pipe';
import { UserPipe } from './pipes/user.pipe';
import { ImagesInboxPipe } from './pipes/imagesInbox.pipe';
import { AcortarPipe } from './pipes/acortar.pipe';
import {FromMillisecondsPipe} from './pipes/fromMilliseconds.pipe';
import {MailPipe} from './pipes/mail.pipe';
import {RecortarRolePipe} from './pipes/recortar-role.pipe';
import {HorasMinutosPipe} from './pipes/horasMinutos.pipe';
import {HorarioPipe} from './pipes/horario.pipe';
import {MinutosPipe} from './pipes/minutos.pipe';
import {SeguimientoPipe} from './pipes/seguimiento.pipe';
import {FilterPipe} from './pipes/filter.pipe';
import {StringSubstrPipe} from './pipes/filtertext';




@NgModule({
  imports: [
    // dep modules
  ],
  declarations: [
    ImagesPipe,
    RolePipe,
    ContactosPipe,
    UserPipe,
    ImagesInboxPipe,
    AcortarPipe,
    FromMillisecondsPipe,
    MailPipe,
    RecortarRolePipe,
    HorasMinutosPipe,
    HorarioPipe,
    MinutosPipe,
    SeguimientoPipe,
    FilterPipe,
    StringSubstrPipe

  ],
  exports: [
    ImagesPipe,
    RolePipe,
    ContactosPipe,
    UserPipe,
    ImagesInboxPipe,
    AcortarPipe,
    FromMillisecondsPipe,
    MailPipe,
    RecortarRolePipe,
    HorasMinutosPipe,
    HorarioPipe,
    MinutosPipe,
    SeguimientoPipe,
    FilterPipe,
    StringSubstrPipe
  ]
})
export class  PipesModule {}
