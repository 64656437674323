<div class="col-lg-6 col-md-6" style="margin: 0 auto">
    <img class="img-profile img-thumbnail rounded mx-auto d-block" [src]="empleado.img | images: ''" onclick="document.getElementById('buscadorPersona').click()">
</div>


<div class="form-group" id="barraImages" hidden>
    <div class="input-group">
        <span class="input-group-btn">
                    <span class="btn btn-default btn-file">
                        <input id="buscadorPersona" name="filebutton-normal" type="file" class="file" (change)="fileChangeEvent($event, 'user')">
                    </span>
        </span>
        <input type="text" class="form-control" readonly>
    </div>
</div>


<div class="row justify-content-center match-height">


    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <div class="px-3">

                    <form class="form form-horizontal striped-rows form-bordered" novalidate="" (ngSubmit)="onSubmit()" #f="ngForm">
                      <div class="form-body">
                        <div class="row">
                                <div class="form-group col-12 mb-2">
                                    <label>Nombre</label>
                                    <div class="position-relative has-icon-left">
                                        <input type="text" class="form-control" placeholder="Nombre" [(ngModel)]="empleado.nombre" required name="nombre">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-12 mb-2">
                                    <label >Apellidos</label>
                                    <div class="position-relative has-icon-left">
                                        <input type="text" class="form-control" placeholder="Apellidos" [(ngModel)]="empleado.apellidos" required name="apellidos">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-12 mb-2">
                                    <label >Fecha Nacimiento</label>
                                    <div class="position-relative has-icon-left">
                                        <input type="date" id="issueinput3" class="form-control" name="fecha_nacimiento" [(ngModel)]="empleado.fecha_nacimiento" data-toggle="tooltip" data-trigger="hover" data-placement="top" data-title="Date Opened">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-12 mb-2">
                                    <label>Direccion</label>
                                    <div class="position-relative has-icon-left">
                                        <input type="text" class="form-control" placeholder="Direccion" [(ngModel)]="empleado.direccion" required name="direccion">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-12 mb-2">
                                    <label>Dni</label>
                                    <div class="position-relative has-icon-left">
                                        <input type="text" class="form-control" placeholder="Dni" [(ngModel)]="empleado.dni" required name="dni">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-4 col-lg-6 col-md-12">
                                    <fieldset class="form-group">
                                        <label>Provincia</label>
                                        <select (change)="getMunicipio()" class="form-control" [(ngModel)]='empleado.id_provincia' name="provincia">
                                                <ng-container *ngIf="provincias.length > 0">
                                                    <option *ngFor="let pro of provincias" [value]="pro.id">{{pro.provincia}}</option>
                                                </ng-container>
                                                <ng-container *ngIf="empleado.provincia">
                                                    <option [value]="empleado.id_provincia" selected>{{empleado.provincia}}</option>
                                                </ng-container>

                                            </select>
                                    </fieldset>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-4 col-lg-6 col-md-12">
                                    <fieldset class="form-group">
                                        <label>Municipios</label>
                                        <select class="form-control" [(ngModel)]="empleado.id_municipio" name="municipio">
                                                <ng-container *ngIf="municipios.length > 0 ">
                                                   <option *ngFor="let municipio of municipios" [value]="municipio.id">{{municipio.municipio}}</option>
                                                </ng-container>

                                                <ng-container *ngIf="empleado.municipio">
                                                    <option [value]="empleado.id_municipio" selected>{{empleado.municipio}}</option>
                                                </ng-container>


                                            </select>
                                    </fieldset>
                                </div>
                            </div>
                        </div>





                        <div class="form-actions right">
                            <button type="button" class="btn btn-raised btn-warning mr-1">
                                    <i class="ft-x"></i> Cancel
                                </button>

                            <button type="submit" class="btn btn-raised btn-success" [disabled]="!f.valid">
                                    <i class="fa fa-check-square-o"></i> Editar
                                </button>


                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
</div>
