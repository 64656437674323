import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';
import {Router, ActivatedRoute} from '@angular/router';
import {AdminPadreService} from '../services/admin-padre.service';
import {NgForm} from '@angular/forms';
import {UsersService} from '../../admin-users/services/users.service';
import {AulasService} from '../../admin-aulas/services/aulas.service';


@Component({
  selector: 'app-hijos-datos-padre',
  templateUrl: './hijos-padre.component.html',
  styleUrls: ['../padre.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HijosPadreComponent implements OnInit, OnDestroy {
  id: number;
  nombre: string;
  apellidos: string;
  dni: string;
  existe: boolean;
  hijos: any[] = [];
  cursos: any[] = [];
  curso: string;
  hijosSubscription = new Subscription();
  cursosSubscription = new Subscription();
  @ViewChild('f') loginForm: NgForm;
  constructor (
    private _route: ActivatedRoute,
    private _router: Router,
    private _padre: AdminPadreService,
    private _aulas: AulasService
  ) {
    this.id = this._route.snapshot.parent.params['id'];
    this._padre.getHijos(this.id);
    this._aulas.getCursosCentro();
  }

  ngOnInit () {
    this.hijosSubscription = this._padre.users$.subscribe( resp => this.hijos = resp);
    this.cursosSubscription = this._aulas.cursoCentro$.subscribe( resp => this.cursos = resp);
  }

  ngOnDestroy () {
    this.hijosSubscription.unsubscribe();
    this.cursosSubscription.unsubscribe();
  }

  onSubmit() {
    if (this.loginForm.valid) {
      const user = this.loginForm.value;
      user.id_padre = this.id;
      this._padre.addHijo(user).then( () => {
        this._padre.getHijos(this.id);
        this.existe = false;
      }).catch( err => console.log(err.message + ' Error crear hijo'));
    }
  }
}
