import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-modal-cursos-intensivos',
  templateUrl: './modal-cursos-intensivos.component.html',
  styleUrls: ['./modal-cursos-intensivos.component.scss']
})
export class ModalCursosIntensivosComponent implements OnInit {
  inputForm: FormGroup;

  constructor(public dialoRef: MatDialogRef<ModalCursosIntensivosComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder, private date: DatePipe) { }

  ngOnInit(): void {
    this.crearFomulario();
    if (this.data) {
      this.cargarFormulario(this.data);
    }
  }

  crearFomulario(): void {
    this.inputForm = this.fb.group({
      id: '',
      cursillo: '',
      descripcion: '',
      importe: ['', Validators.required],
      inicio: '',
      fin: '',
      hInicio: '',
      hFin: '',
      finalizado: false,
      anyo: [ new Date().getFullYear(), this.verificarAnyo.bind(this)]
    });
  }

  get anyo(): any {
    return this.inputForm.get('anyo').hasError('error');
  }

  guardar() {
    delete this.inputForm.value.id;
    this.inputForm.controls.inicio.setValue(this.date.transform(this.inputForm.value.inicio, 'yyyy-MM-dd'));
    this.inputForm.controls.fin.setValue(this.date.transform(this.inputForm.value.fin, 'yyyy-MM-dd'));
    this.inputForm.controls.anyo.setValue(parseInt(this.inputForm.value.anyo, 10));
   // console.log(this.inputForm.value);
    this.dialoRef.close(this.inputForm.value);
  }

  editar() {
    this.inputForm.controls.inicio.setValue(this.date.transform(this.inputForm.value.inicio, 'yyyy-MM-dd'));
    this.inputForm.controls.fin.setValue(this.date.transform(this.inputForm.value.fin, 'yyyy-MM-dd'));
    this.inputForm.controls.anyo.setValue(parseInt(this.inputForm.value.anyo, 10));
    this.inputForm.value.id = this.data.id;
    // console.log(this.inputForm.value);
    this.dialoRef.close(this.inputForm.value);
  }

  cargarFormulario(data: any): void {
    this.inputForm.controls.cursillo.setValue(data.cursillo);
    this.inputForm.controls.descripcion.setValue(data.descripcion);
    this.inputForm.controls.importe.setValue(data.importe);
    this.inputForm.controls.inicio.setValue(new Date(data.inicio.date));
    this.inputForm.controls.fin.setValue(new Date(data.fin.date));
    this.inputForm.controls.hInicio.setValue(data.hInicio);
    this.inputForm.controls.hFin.setValue(data.hFin);
    this.inputForm.controls.anyo.setValue(data.anyo);
  }

  verificarAnyo(dato: FormControl): any {
   if (!isNaN(dato?.value) && dato.value !== null) {
     return null;
   } else {
     return {error: {str: true}}
   }
  }
}
