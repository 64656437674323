import {Component, OnDestroy} from '@angular/core';
import {ClasesMService} from '../services/clasesM.service';
import {Subscription} from 'rxjs/Subscription';
import {Router, ActivatedRoute} from '@angular/router';
import {LocalDataSource} from 'ng2-smart-table';
import * as tableData from '../../template/shared/smart-table-notas-examenes';
import {UploadService} from '../../../services/upload.service';


@Component({
  selector: 'app-lista-notas',
  templateUrl: './lista-notasM.component.html',
  styleUrls: ['../clasesM.component.scss']
})
export class ListaNotasMComponent implements OnDestroy {


  private examenes = [];
  estado: boolean;
  id: number;
  asignatura: number;
  curso: number;
  filesToUpload: File[];

  examenesSubscription: Subscription = null;
  source: LocalDataSource;
  filterSource: LocalDataSource;
  alertSource: LocalDataSource;

  constructor (private _clases: ClasesMService, private _route: ActivatedRoute, private _router: Router, private _upload: UploadService) {
    this._route.params.subscribe(params => {
      this.id = params['id'];

    });
    this.curso = this._route.snapshot.parent.params['curso'];
    this.asignatura = this._route.snapshot.parent.params['id'];
    this._clases.getExamenesEntregados(this.id);

    this.examenesSubscription = this._clases.examenes$.subscribe(data => {
      this.examenes = data;
      this.source = new LocalDataSource(this.examenes);
    });

  }

  settings = tableData.settings;

  ver (event) {

    this._router.navigate([`/maestro/info/${this.curso}/${this.asignatura}/lista-files/${event.data.id}`]);
  }

  getExamenes () {
    if (!this.estado) {
      this._clases.getExamenesNoEntregados(this.id);

    } else {
      this._clases.getExamenesEntregados(this.id);
    }
    this.estado = !this.estado;
  }


  ngOnDestroy (): void {
    this.examenesSubscription.unsubscribe();
  }


  //  For confirm action On Delete
  onDeleteConfirm (event) {
    this.id = event.data.id;
    document.getElementById('buscadorPersona').click();
  }

  //  For confirm action On Save
  onSaveConfirm (event) {
    this._clases.updateNota(event.newData);
  }

  //  For confirm action On Create
  onCreateConfirm (event) {
    this._clases.updateNota(event.newData);
    //  this._cursos.updateAsignaturas(event.newData);

  }

  addPhoto () {
    this._upload
      .makeFileRequest(this.id, this.filesToUpload, 'imagen', 'examen_alumno')
      .then(
        (result: any) => {
          this._router.navigate([`/maestro/info/${this.curso}/${this.asignatura}/lista-files/${this.id}`]);
        },
        error => {
          console.log(error);
        }
      );
  }

  fileChangeEvent (fileinput: any) {

    this.filesToUpload = <Array<File>>fileinput.target.files;
    this.addPhoto();
  }
}
