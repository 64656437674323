import {Component, OnInit, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {LoginService} from '../../../../components/pages/services/login.service';
import {InboxService} from '../../../admin/inbox/inbox.service';
import {Subscription} from 'rxjs/Subscription';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit, OnDestroy {
  datos: any = {};
  mensajes: any = {};
  numeroSubscription = new Subscription();

  constructor (private router: Router, private _login: LoginService, private _inbox: InboxService) {
    this.datos = JSON.parse(localStorage.getItem('identity'));
    this._inbox.mensajesNoLeidos();
  }

  logout () {
    this._login.logout();
  }

  editar() {
    this.router.navigate(['/padre/profile']);
  }

  ngOnInit () {
    this.numeroSubscription = this._inbox.numero$.subscribe(res => {
      this.mensajes = res;
    });
  }

  ngOnDestroy () {
    this.numeroSubscription.unsubscribe();
  }
}
