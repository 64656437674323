import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {AsistenciasService} from './services/asistencias.service';
@Component({
  selector: 'app-asistencias',
  templateUrl: './asistencias.component.html',
  styleUrls: ['./asistencias.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AsistenciasComponent {
  public clase: Array<any> = [];
  public id: number;
  public type: string;
  navLinks = [{}];

  constructor (private _push: AsistenciasService) {
    this.navLinks = [{path: 'vista-agenda', label: 'Agenda'}];
    this._push.sendPush();
  }

}

