<div class="container">
  <!-- Logo superior  -->
  <div class="row" *ngFor="let hijo of hijos;">
    <div class="col-12 cajatitulo">
      <h5>Asistencias ultimos 4 dias
        <small>
          <a [routerLink]="['/padre/hijo', hijo.id]">Ver mas..</a>
        </small>
      </h5>
    </div>

    <div class="row cajaalumno">

      <!-- Columno foto -->
      <div class="col-3 cajafoto">
        <div class="col-12 card-style">
          <div class="card-body">
            <img class="card-img-top imagen unselectable" [src]="hijo.img | images: ''" alt="Card image cap" />
            <h4 class="cajanombre">{{hijo.nombre}}</h4>
            <p class="cajaapellidos">{{hijo.apellidos}}</p>
          </div>
        </div>
      </div>

      <div class="col-4 cajatareas">
        <h5 class='col-12'>Tareas escolares</h5>
        <div class='col-3 card-style' *ngFor="let task of tasks">
          <small>{{task.fecha.date | date: 'dd-MM-yy'}}</small>
          <h6>{{task.taskTitle}}</h6>
          <p>{{task.taskMessage}}</p>
        </div>
      </div>
      <div class="col-4 cajaexamenes">
        <h5>Ultimos examenes</h5>
        <div class='col-3 card-style' *ngFor="let examen of examenes">
          <small>{{examen.fecha.date | date: 'dd-MM-yy'}}</small>
          <h6>{{examen.examen}}</h6>
          <p>{{examen.tipo}}</p>
          <p class="nota">{{examen.nota}}</p>
        </div>
      </div>

    </div>
  </div>

<!--  <div class="row cajaasistencias">

    <div class="col-12">
      <div class="row">
        <div class="col-3 fecha-style" *ngFor="let fecha of fechas">
          <small>{{fecha}}</small>
          <div class="row cajaclase">


            <ng-container class="col-12 " *ngFor="let asistencia of asistencias">
              <div *ngIf="asistencia.fecha  === fecha" [ngClass]="{
                    'asistencia-card': true,
                    'falta': !asistencia.estado,
                    'esta': asistencia.estado }">
                {{asistencia.asignatura}}
              </div>
            </ng-container>

            &lt;!&ndash; <div class="col-12 " *ngFor="let asistencia of asistencias">
              <div *ngIf="asistencia.fecha  === fecha" [display] = false [ngClass]="{
                  'asistencia-card': true,
                  'falta': !asistencia.estado,
                  'esta': asistencia.estado,
                  'display': block }">
                {{asistencia.asignatura}}
              </div>
            </div> &ndash;&gt;

          </div>
        </div>
      </div>
    </div>

  </div>-->

</div>
