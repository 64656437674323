

  <div class="col-sm-12 col-xl-10 mx-auto" *ngIf='cursos.length > 0'>
    <div class="row mb-3">
      <div class="col-2 offset-2 pl-0">
        <form class="navbar-form" role="search" autocomplete="off">
          <div class="position-relative ">
            <input  [(ngModel)]="letra"
                    class="form-control "
                    name="search" placeholder="Filtrar letra" type="text">

          </div>
        </form>
      </div>
      <div class="col-2  pl-0">
        <form class="navbar-form" role="search" autocomplete="off">
                            <div class="position-relative ">
                              <input  [(ngModel)]="aulaname"
                                      class="form-control "

                                      name="search" placeholder="Filtrar aula" type="text">

                            </div>
                          </form>
      </div>

      <div class="col-2  pl-0">
        <form class="navbar-form" role="search" autocomplete="off">
          <div class="position-relative ">
            <input  [(ngModel)]="anyo"
                    class="form-control "
                    name="search" placeholder="Filtrar año" type="text">

          </div>
        </form>
      </div>
      <div class="col-2  pl-0">
        <div class="navbar-form" role="search" autocomplete="off">
          <div class="position-relative ">
            <mat-checkbox class="example-margin" [(ngModel)]="checked" (change)="changeCheck()">Ver todas las aulas</mat-checkbox>

          </div>
        </div>
      </div>
    </div>
    <table class="table table-striped table-sm w-100">
      <thead>
      <tr>
        <th scope="col">Finalizado</th>
        <th scope="col">Curso</th>
        <th scope="col">Letra</th>
        <th scope="col">Aula</th>
        <th scope="col">Año de inicio</th>

        <th scope="col">Aula física</th>
        <!--                <th scope="col">-->
        <!--                  <form class="navbar-form" role="search" autocomplete="off">-->
        <!--                  <div class="position-relative has-icon-right">-->
        <!--                    <input  [(ngModel)]="aulaname"-->
        <!--                            class="form-control round"-->
        <!--                            name="search" placeholder="Buscar curso" type="text">-->
        <!--                    <div class="form-control-position">-->
        <!--                      <i class="ft-search"></i>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                </form>-->
        <!--                  Curso-->

        <!--                </th>-->



        <th scope="col">Acciones</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let row  of cursos | filtero:  aulaname : anyo : letra ; index as i" >
        <th *ngIf="row.finalizada" style="color: green"><i class="ft-check-circle"></i></th>
        <th *ngIf="!row.finalizada" style="color: red"><i class="ft-book"></i></th>
        <td>{{row.anyo_curso}}ª</td>
        <td>{{row.aula}}</td>
        <td>{{row.acronimo}}</td>
        <td>{{row.curso}}</td>

        <td>{{row.fisica}}</td>


        <td>
          <ul class="list-inline">
            <li class="list-inline-item danger p-0">
              <i class="ft-x font-medium-3 mr-2" (click)="delete(row.id, i)"></i>
            </li>
            <li class="success p-0 list-inline-item" >
              <a  [routerLink]="['/admin/aula' ,row.id]"><i class="ft-eye font-medium-3 mr-2"></i></a>
            </li>
            <li class="success p-0 list-inline-item">
              <i class="ft-award font-medium-3 mr-2" (click)="calcularNotaFinal(row.id, i)" *ngIf="row.notafinal"></i>
            </li>
          </ul>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

