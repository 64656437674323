import {
  Component, Input, ViewChild, OnDestroy, OnInit, DoCheck, ViewEncapsulation, ElementRef
} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {ClasesMService} from '../services/clasesM.service';


@Component({
  selector: 'app-claseM',
  templateUrl: './clases.component.html',
  styleUrls: ['../clasesM.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClaseMComponent {
  public clase: Array<any> = [];
  public claseSubscription: Subscription = null;
  public id: number;
  public type: string;
  navLinks = [{}];
  aula = sessionStorage.getItem('aula')

  constructor (private route: ActivatedRoute, private router: Router, private _clases: ClasesMService) {
    this.navLinks = [{path: 'asignaturas', label: 'Asignaturas'}, {path: 'alumnos', label: 'Alumnos'},  { path: 'examenes', label: 'Calculo de nota media'} , { path: 'notas/0/0', label: 'Listado de notas Finales'}];
  }


}
