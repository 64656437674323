import {Component, Input, ViewChild, OnDestroy, OnInit} from '@angular/core';
import {AulasService} from '../services/aulas.service';
import {Subscription} from 'rxjs/Subscription';
import {NgForm} from '@angular/forms';
import {CentroService} from '../../admin-centro/services/centro.service';
import {Router, ActivatedRoute} from '@angular/router';
import {UploadService} from '../../../services/upload.service';
import {LoginService} from '../../../pages/services/login.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-aulas-view',
  templateUrl: './aulas-view.component.html',
  styleUrls: ['../main-aula/aulas.component.scss']
})
export class AulasViewComponent implements OnDestroy, OnInit {
  @ViewChild('f') loginForm: NgForm;
  active: boolean;
  private cursoSubscription: Subscription = null;
  private cursoCentroSubscription: Subscription = null;
  private cursoEventoSubscription: Subscription = null;
  public filesToUpload: Array<File>;
  public cursosCentro: any = [];
  turno: boolean;
  aulasFisicas: any ;
  aulaSeleccionada: any;
  aulasFiltered:  any = [];
  cursosEvento;
  curso: any = {};
  iniCurso: any;
  anyoCurso: any;


  constructor(
    private _curso: AulasService,
    private router: Router,
    private _route: ActivatedRoute,
    private _centro: CentroService,
    private _upload: UploadService,
    private _login: LoginService,
  ) {
    const id = this._route.snapshot.parent.params['id'];
    this._curso.getCurso(id);
    this._curso.getCursosCentro();
    this._centro.getEventoCurso();
  }

  onSubmit() {
    if (this.loginForm.valid) {
      console.log(this.loginForm.value);
      // this._curso.edit(this.loginForm.value);
      this._curso.add(this.loginForm.value);
      this.active = !this.active;
      let aula = undefined;
      let fechas: any;
      for ( const f  of this.cursosEvento ) {
        if ( f.id === this.curso.id_evento_curso) {
          fechas = f;
        }
      }
      console.log(fechas);
      if (this.turno ) {
        aula = {
          id: this.aulaSeleccionada,
          old: this.curso.aula_fisica.aula === null ? false : this.curso.aula_fisica.aula ,
          aulaMañana: this.curso.aula,
          inicioMañana: fechas.inicio.date,
          finMañana: fechas.fin.date
        };
      } else {
        aula = {
          id: this.aulaSeleccionada,
          old: this.curso.aula_fisica.aula === null ? false : this.curso.aula_fisica.aula ,
          aulaTarde: this.curso.aula,
          inicioTarde: fechas.inicio.date,
          finTarde: fechas.fin.date
        };
      }
      console.log(aula);
      this._curso.updateClase(aula).subscribe( (res) => {
        this.router.navigate(['/admin/aulas/lista']);
      });
    }
  }

  ngOnInit(): void {

    this.cursoSubscription = this._curso.curso$.subscribe(res => {

      this.curso = res;
      console.log('CURSO', this.curso);
      this.turno = this.curso.aula_fisica.turno;

      this._curso.getAulasFisicas().subscribe( ( data) => {

        this.aulasFisicas = data;

        if ( this.curso.aula_fisica.aula !== null) {
          this.aulasFisicas.map(a => {
            if (a.numeroClase === this.curso.aula_fisica.aula) {
              this.aulasFiltered.push(a);
             this.aulaSeleccionada = a.id
            }
          });
        }
      });
    });

    this.cursoCentroSubscription = this._curso.cursoCentro$.subscribe(res => {
      this.cursosCentro = res;
    });

    this.cursoEventoSubscription = this._centro.eventoCurso$.subscribe(res => {
      this.cursosEvento = res;
      console.log(this.cursosEvento);
    });
  }

  ngOnDestroy(): void {
  }

  addPhoto(type: string) {
    this._upload
      .makeFileRequest(this.curso.id, this.filesToUpload, 'imagen', type)
      .then(
        (result: any) => {
          if (type === 'curso') {
            this.curso.img = result.id;
            location.reload();
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  fileChangeEvent(fileinput: any, type: string) {
    this.filesToUpload = <Array<File>>fileinput.target.files;
    this.addPhoto(type);
  }


  filtro( event) {
    this.aulaSeleccionada = null;
    this.aulasFiltered = [];
    console.log(event.target.value);
    if (event.target.value === '1') {
      console.log('hola');
      this.aulasFisicas.map(a => {
        if (a.aulaManyana === null) {
          this.aulasFiltered.push(a);
        }
      });
      if ( this.curso.aula_fisica.aula !== null ) {
        this.aulasFisicas.map(a => {
          console.log(a.numeroClase);
          if (a.numeroClase === this.curso.aula_fisica.aula && this.curso.aula_fisica.turno === '1') {
            this.aulasFiltered.push(a);
            console.log(a);
          }
        });
      }
    } else if (event.target.value === '0') {
      this.aulasFisicas.map(a => {
        if (a.aulaTarde === null) {
          this.aulasFiltered.push(a);
        }
      });
      if ( this.curso.aula_fisica.aula !== null) {
        console.log(this.curso.aula_fisica.turno);
        this.aulasFisicas.map(a => {
          if (a.numeroClase === this.curso.aula_fisica.aula && this.curso.aula_fisica.turno === '0') {
            this.aulasFiltered.push(a);
          }
        });
    }

    }
  }

  getAulasFisicas() {

  }

  addFormaContacto() {
    (async () => {
      const { value: numClase } = await Swal.fire({
        title: 'Añadir aula',
        input: 'text',
        allowOutsideClick: false,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Guardar',
        reverseButtons: true,
      })
      if (numClase ) {
        this._curso.addAulaFisica(numClase).subscribe((res) => {
          console.log(res);
          this.aulasFisicas = res['id'];
          Swal.fire('', 'Se ha guardado correctamente', 'success');

        }, (error) => {
          console.log(error);
          Swal.fire('', error.error, 'warning');
        });
      }
    })()
  }
}
