import { RouteInfo } from './sidebar.metadata';


// Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  // tslint:disable-next-line:max-line-length
  { path: '/maestro/clases', title: 'Mis clases', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  { path: '/maestro/inbox', title: 'Mensajeria interna', icon: 'ft-mail', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  { path: '/maestro/tablonUser', title: 'Tablón de anuncios', icon: 'ft-mail', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  // tslint:disable-next-line:max-line-length
  { path: '/maestro/horario', title: 'Horario', icon: 'ft-clock', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  // tslint:disable-next-line:max-line-length
  { path: '/maestro/jornada', title: 'Jornada', icon: 'ft-eye', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
];
