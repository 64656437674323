import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {ClasesMService} from './services/clasesM.service';


@Component({
  selector: 'app-clasesM',
  templateUrl: './clasesM.component.html',
  styleUrls: ['./clasesM.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClasesMComponent  implements OnInit{
  public clases: Array<any> = [];
  public clasesSubscription: Subscription = null;


  constructor (private route: ActivatedRoute, private _clases: ClasesMService, private router: Router) {
  }

  ngOnInit() {

    if(this.clases.length === 0) {
      this._clases.getCursos();
      this.clasesSubscription = this._clases.clases$.subscribe(res => {

        this.clases = res;
        console.log(this.clases);
      });
    }
  }

  go(row) {
    this.router.navigate(['/maestro/clase' , row.id])
    sessionStorage.setItem('aula', row.anyo_curso + ' - ' + row.acronimo + row.aula);
  }

}
