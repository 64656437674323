<div class="wrapper">
    <div class="app-sidebar" data-active-color="white" data-background-color="black" data-image="assets/img/sidebar-bg/01.jpg">
        <!-- SIDEBAR -->
        <app-sidebar></app-sidebar>
        <div class="sidebar-background"></div>
    </div>
    <div class="main-panel">
        <!-- NAVBAR -->
        <app-navbar></app-navbar>
        <div class="main-content">
            <div class="content-wrapper">
                <div class="container-fluid">
                    <section id="user-profile">
                        <div class="row">
                            <div class="col-12">
                                <div class="card profile-with-cover">

                                    <!-- ROUTER OUTLET -->
                                    <router-outlet></router-outlet>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <!-- FOOTER -->
        <app-footer></app-footer>
    </div>
    <!-- CUSTUMIZER -->
</div>
