import {Component} from '@angular/core';

@Component({
  selector: 'app-padre',
  templateUrl: './admin-agenda.component.html'
})
export class AdminAgendaComponent {
  navLinks = [{}];

  constructor () {
    this.navLinks = [{path: 'config', label: 'Configurar horarios'}, {path: 'lista', label: 'Listado'}];
  }

}
