<div class="row">
  <div class="col-4">
    <div class="form-group-row">
      <label class="col-md-3 label-control">Nombre</label>
      <div class="col-md-9">
        <input type="text" class="form-control border-primary">
      </div>
    </div>
  </div>



  <div class="col-4">
    <div class="form-group-row">
      <label class="col-md-3 label-control">Tipo examen</label>
      <div class="col-md-9">
        <input type="text" class="form-control border-primary">
      </div>
    </div>
  </div>


  <div class="col-4">
    <div class="form-group-row">
      <label class="col-md-3 label-control">Valor</label>
      <div class="col-md-9">
        <input type="text" class="form-control border-primary">
      </div>
    </div>
  </div>
</div>


