<!--Simple Table Starts-->
<section id="simple">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">Asistencias al aula de {{ clase.nivel }} asigantura de {{ clase.asignatura }}</h5>
                </div>
                <div class="card-body">
                    <div class="card-block">
                        <ng2-smart-table (userRowSelect)="onUserRowSelect($event)" [settings]="settings" [source]="source" ></ng2-smart-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
