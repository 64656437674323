import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtero'
})
export class FilterPipe implements PipeTransform {

  transform(cursos: any[], texto: string, anyo: string , letra: string): any[] {


    if (cursos && cursos.length) {
      return cursos.filter(item => {
        console.log(anyo);

        if (texto && item.acronimo.toLowerCase().indexOf(texto.toLowerCase()) === -1) {
          return false;
        }
        if (anyo && item.curso.toString().indexOf(anyo) === -1) {
          return false;
        }
        if (letra && item.aula.toLowerCase().indexOf(letra.toLowerCase()) === -1) {
          return false;
        }
        return true;
      });
    } else {
      return cursos;
    }
    return cursos
  }

}
