<section id="simple">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">Notas de {{dato}}</h5>
        </div>

        <table class="table table-striped">
          <thead>
          <tr>

            <th scope="col">Asignatura</th>
            <th scope="col">Tipo</th>
            <th scope="col">nota</th>


          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let nota of notas; let i = index">
            <td>{{nota.asignatura}}</td>
            <td>{{nota.tipo}}</td>

            <td>{{nota.nota}}</td>

          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>
