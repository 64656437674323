import { Component, OnInit } from '@angular/core';
import {DocumentosService} from '../../../admin-administracion/services/documentos.service';
import {ActivatedRoute} from '@angular/router';
import {RutasImg} from '../../../../../utiles/Rutas';
import Swal from 'sweetalert2';
import {UsersService} from '../../services/users.service';
import {Dato} from '../../../../../modelos/datos.modelo';

@Component({
  selector: 'app-documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.scss']
})
export class DocumentosComponent implements OnInit {
  documentos: any[] = [];
  tipos: any[] = [];
  idUser: any;
  tipo: any;
  filesToUpload: Array<File>;
  type: any;
  dato: Dato;

  constructor(private serviceDocumental: DocumentosService, private _route: ActivatedRoute, private _sevice: UsersService) {
    this.idUser = this._route.snapshot.parent.params['id'];
    const tp = this._route.snapshot.parent.params['type'];
    console.log(tp);
    if (tp === 'alumnos') {
      this.type = 4;
    } else {
      this.type = 1;
    }
    console.log(this.type);
    this.getTipos();
    this.getDocumentos();
  }

  ngOnInit(): void {
    this._sevice.getDato(this.idUser);
    this._sevice.dato$.subscribe((res: any) => {
      this.dato = res;
    });
  }

  getTipos(): void {
    this.serviceDocumental.getTipoDocumentos().subscribe((res) => {
      console.log(res['id']);
      this.tipos = res['id'];
    }, (error) => {
      console.log(error)
    });
  }


  getDocumentos(): void {
    this.serviceDocumental.getDocumentosUser(this.idUser, this.type).subscribe((res) => {
      console.log(res['id']);
      this.documentos = res['id'];
    }, (error) => {
      console.log(error)
    });
  }


  ver(url: string) {
    console.log(url)
    const URL = RutasImg + url;
    window.open(URL);
  }

  file(event, j) {
    this.filesToUpload = <Array<File>>event.target.files;
    const formData = new FormData();
    const json = {
      id: this.documentos[j].id,
      id_tipo: this.documentos[j].tipo.id,
      id_user: this.idUser,
      entregado: this.documentos[j].entregado
    };
    console.log(json);
    formData.append('json', JSON.stringify(json));
    formData.append('file', this.filesToUpload[0]);
    formData.append('token', localStorage.getItem('token'));
    this.serviceDocumental.addDocumento(formData).subscribe((res) => {
      console.log(res);
      this.getDocumentos();
    }, (error) => {
      console.log(error);
    })
  }

  delete(id, index) {
    console.log(id);
    console.log(index)
    if (!id) {
      this.documentos.splice(index, 1);
    } else {
      this.serviceDocumental.deleteDocumentosUser(this.idUser, id)
        .subscribe((res) => {
          console.log(res);
          Swal.fire('', 'Se ha eliminado correctamente', 'success');
          this.documentos = res['id'];
        }, (error) => {
          console.log(error);
        });
    }

  }


  agregar(tipo) {
    if (tipo) {
      const nombre = tipo.tipo;
      this.tipo = tipo.id;
      const doc = {
        tipo: {id: this.tipo, tipo: nombre},
        entregado: false,
        url: ''
      };
      this.documentos.push(doc);
    }
  }

  addtipo() {
    (async () => {
      const { value: tipo } = await Swal.fire({
        title: 'Ingresar el tipo de documento',
        input: 'text',
        allowOutsideClick: false,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Guardar',
        reverseButtons: true,
      })
      if (tipo) {
        const datos = { tipo: tipo};
        this.serviceDocumental.addTipoDocumento(datos).subscribe((res) => {
          if (res['id']) {
            Swal.fire('', 'Se ha guardado correctamente', 'success');
            this.getTipos();
          }
        }, (error) => {
          console.log(error)
        });
      }
    })()
  }

  entregado(j) {
    console.log(this.documentos[j]);
    const formData = new FormData();
    const json = {
      id: this.documentos[j].id,
      id_tipo: this.documentos[j].tipo.id,
      id_user: this.idUser,
      entregado: this.documentos[j].entregado
    };
    console.log(json);
    formData.append('json', JSON.stringify(json));
    formData.append('file', null);
    formData.append('token', localStorage.getItem('token'));
    this.serviceDocumental.addDocumento(formData).subscribe((res) => {
      console.log(res);
      this.getDocumentos();
    }, (error) => {
      console.log(error);
    })
  }

  abrirInput(i) {
    document.getElementById('input-file' + i).click();
  }
}
