import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-add-tipo-contacto',
  templateUrl: './add-tipo-contacto.component.html',
  styleUrls: ['./add-tipo-contacto.component.scss']
})
export class AddTipoContactoComponent implements OnInit {

  medioForm: FormGroup;

  constructor(public dialoRef: MatDialogRef<AddTipoContactoComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder) {
    this.initForm();
  }

  ngOnInit(): void {
  }
  initForm(): void {
    this.medioForm = new FormGroup({
      medio: new FormControl('', Validators.required),
    });
  }

}
