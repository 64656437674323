export const colors: any[] = [
  {
    name: 'dark',
    primary: '#374250',
    secondary: '#c3c3c3'
  },
  {
    name: 'blue',
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  {
    name: 'lightBlue',
    primary: '#0fdae3',
    secondary: '#b4e9fd'
  },
  {
    name: 'greenOcean',
    primary: '#0fe398',
    secondary: '#b5fdea'
  },
  {
    name: 'green',
    primary: '#1ee314',
    secondary: '#bffdc1'
  },
  {
    name: 'yellow',
    primary: '#e3bc08',
    secondary: '#FDF1BA'
  },
  {
    name: 'orange',
    primary: '#e3760f',
    secondary: '#fdd6b7'
  },
  {
    name: 'red',
    primary: '#ad2121',
    secondary: '#FAE3E3'
  },
  {
    name: 'pink',
    primary: '#e31396',
    secondary: '#fdbfd5'
  },
  {
    name: 'purple',
    primary: '#a70de3',
    secondary: '#ebcffd'
  }
];
