<h4 class="info-top"><i class="ft-user"></i> Convalidaciones: {{dato?.nombre}} {{dato?.apellidos}}</h4>
<div class="table-responsive">
  <table class="table"  style="border: 0">
    <thead>
    <tr class="d-flex">
      <th class="col-5" scope="col">Asignatura</th>
      <th class="col-3" scope="col">Definir como Anteriormente Aprobado?</th>
      <th class="col-2" scope="col">Nota Final</th>
      <th class="col-2" scope="col">Acciones</th>

    </tr>
    </thead>
    <tbody>
    <tr class="d-flex" *ngFor="let asign of asignaturas; let i = index;">
      <td class="col-5">{{asign.asignatura | titlecase}}</td>
      <td class="col-3">
        <mat-checkbox (change)="change($event, asign, i)" [(ngModel)]="asign.aa" ></mat-checkbox>
      </td>
      <td class="col-2" id="a_{{i}}">
        <i *ngIf="asign.nota ; else no">
          {{asign.nota}}
        </i>
        <ng-template #no><i class="ft-stop-circle"></i></ng-template>
      </td>
      <td class="col-2"  hidden id="b_{{i}}" *ngIf="!asign.validar">
        <input style="text-align: center" type="text" size="2"  [(ngModel)]="asign.nota" value="" name="nota"/> &nbsp;
        <small class="upload"><i class="ft-upload-cloud font-medium-3 text-success" (click)="add(asign, asign.nota, i)"></i></small>
      </td>
      <td class="col-2">
        <li class="success p-0 list-inline-item">
          <i class="ft-edit-2 font-medium-3 mr-2" (click)="editar(i)"></i>
        </li>
        <li class="text-warning p-0 list-inline-item">
          <i class="ft-trash-2 font-medium-3 mr-2" (click)="delete(asign.id_convalidacion)"></i>
        </li>
      </td>
    </tr>
    </tbody>
  </table>
</div>
