<div class="container">
  <ng-template #modalEvents let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal">Evento</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <input type="hidden" name="id" [(ngModel)]="event.id">
        <div class="form-group">
          <label>Titulo</label>
          <div class="input-group">
            <input class="form-control" placeholder="titulo" name="titulo" [(ngModel)]="event.title" required>
          </div>
        </div>
        <div class="form-group">
          <label>Tipo</label>
          <div class="input-group">
            <select name="tipo" class="form-control" [(ngModel)]="event.type">
              <option *ngFor="let tipo of tipos" [ngValue]="tipo.id">{{tipo.tipo}}</option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label>Seleccion de fechas</label>
          <div class="input-group">
            <ngb-datepicker #dp (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t"
                            outsideDays="hidden">
            </ngb-datepicker>
          </div>
        </div>
        <div class="form-group">
          <div class="input-group">
            <label>Hora inicio</label>
            <ngb-timepicker [formControl]="fcInicio" [(ngModel)]="inicio" name="h_inicio"></ngb-timepicker>
            <label>Hora fin</label>
            <ngb-timepicker [formControl]="fcFin" [(ngModel)]="fin" name="h_fin"></ngb-timepicker>
          </div>
          <div class="small form-text text-danger" *ngIf="!fcInicio.valid">
            <div *ngIf="fcInicio.errors['FueraRango']">Debes seleccionar una hora de INICIO entre {{inicioFijo.hour}}
              :{{inicioFijo.minute}} y {{finFijo.hour}}:{{finFijo.minute}}</div>
          </div>
          <div class="small form-text text-danger" *ngIf="!fcFin.valid">
            <div *ngIf="fcFin.errors['FueraRango']">Debes seleccionar una hora de FIN entre {{inicioFijo.hour}}
              :{{inicioFijo.minute}} y {{finFijo.hour}}:{{finFijo.minute}}</div>
          </div>
        </div>
      </form>
      <ng-template #t let-date let-focused="focused">
  <span class="custom-day"
        [class.focused]="focused"
        [class.range]="isRange(date)"
        [class.faded]="isHovered(date) || isInside(date)"
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null">
    {{ date.day }}
  </span>
      </ng-template>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close(); addEvent(event.id)"
              [disabled]="!fcInicio.valid || !fcFin.valid || !event.title">Añadir
      </button>
    </div>
  </ng-template>
  <app-demo-utils-calendar-header
    [(view)]="view"
    [(viewDate)]="viewDate"
    [locale]="locale">
  </app-demo-utils-calendar-header>
  <div *ngIf="events">
    <div [ngSwitch]="view">

      <mwl-calendar-month-view
        *ngSwitchCase="'month'"
        [viewDate]="viewDate"
        [events]="events"
        [activeDayIsOpen]="false"
        (dayClicked)="dayClicked($event.day)"
        [weekStartsOn]="weekStartsOn"
        [weekendDays]="weekendDays"
        [locale]="locale"
        [refresh]="refresh">
      </mwl-calendar-month-view>

      <mwl-calendar-week-view
        *ngSwitchCase="'week'"
        [viewDate]="viewDate"
        [events]="events"
        [weekStartsOn]="weekStartsOn"
        [weekendDays]="weekendDays"
        [locale]="locale"
        [refresh]="refresh">
      </mwl-calendar-week-view>

      <mwl-calendar-day-view
        *ngSwitchCase="'day'"
        [viewDate]="viewDate"
        [events]="events"
        [dayStartHour]="inicio.hour"
        [dayStartMinute]="inicio.minute"
        [dayEndHour]="fin.hour"
        [dayEndMinute]="fin.minute"
        [locale]="locale"
        [refresh]="refresh">
      </mwl-calendar-day-view>
    </div>
  </div>
  <h3>
    <button
      class="btn btn-secondary pull-left mt-2"
      (click)="openAdd()">
      Nuevo evento
    </button>
    <div class="clearfix"></div>
  </h3>
</div>

