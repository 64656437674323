import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HijosPadreService } from '../services/hijos-padre.service';
import { Subscription } from 'rxjs/Subscription';


@Component({
  selector: 'app-padre-examenes',
  templateUrl: './padre-examenes.component.html',
  styleUrls: ['../hijos-padre.component.scss']
})

export class PadreExamenesComponent implements OnInit, OnDestroy {
  examenes: any[] = [];
  examenesSubscription = new Subscription();
  constructor(private _router: ActivatedRoute, private _hijo: HijosPadreService) {
    let id: number;
    this._router.parent.params.subscribe(params => {
      if (params['id']) {
        id = params['id'];
      }
      this._hijo.getExamenes(id);
    });
  }
  ngOnInit() {
    this.examenesSubscription = this._hijo.examenes$.subscribe(res => {
      this.examenes = res;
    });
  }
  ngOnDestroy() {
    this.examenesSubscription.unsubscribe();
  }


}
