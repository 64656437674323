import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuardPadreService   as AuthGuardPadre} from '../../services/auth-guard-padre.service';
import {PadreComponent} from './padre.component';
import {HijosPadreComponent} from '../hijos-padre/hijos-padre.component';
import {MenuPadreComponent} from '../hijos-padre/componentes/menu-padre.component';
import {PadreAsistenciasComponent} from '../hijos-padre/componentes/padre-asistencias.component';
import {PadreTareasComponent} from '../hijos-padre/componentes/padre-tareas.component';
import {PadreExamenesComponent} from '../hijos-padre/componentes/padre-examenes.component';
import {PadreInboxComponent} from '../inbox/padre-inbox.component';
import {ProfileContactoComponent} from '../../admin/admin-profile/componentes/profile-contacto.component';
import {ProfileEditComponent} from '../../admin/admin-profile/componentes/profile-edit.component';
import {ProfilePasswordComponent} from '../../admin/admin-profile/componentes/profile-password.component';
import {ProfileComponent} from '../../admin/admin-profile/profile.component';
import {AsistenciasComponent} from '../../componentes/asistencias/asistencias.component';
import {AgendaAlumnoComponent} from '../../componentes/agenda/agenda-alumno/agenda-alumno.component';
import {AgendaPadreComponent} from '../../componentes/agenda/agenda-padre/agenda-padre.component';
import {TablonUserComponent} from '../../componentes/tablon/tablon-user/tablon-user.component';

const routes: Routes = [
  {
    path: 'padre',
    component: PadreComponent,
    canActivate: [AuthGuardPadre],
    children: [
      {path: 'lista', component: HijosPadreComponent},
      {
        path: 'hijo/:id', component: MenuPadreComponent,
        children: [
          {path: 'asistencias', component: AsistenciasComponent},
          {path: 'tareas', component: PadreTareasComponent},
          {path: 'examenes', component: PadreExamenesComponent},
          {path: 'horarios', component: AgendaPadreComponent},
          {path: '**', pathMatch: 'full', redirectTo: 'asistencias'}

        ]
      },
      {
        path: 'hijo', component: MenuPadreComponent,
        children: [
          {path: 'asistencias', component: PadreAsistenciasComponent},
          {path: 'tareas', component: PadreTareasComponent},
          {path: 'examenes', component: PadreExamenesComponent},
          {path: '**', pathMatch: 'full', redirectTo: 'asistencias'}
        ]
      },
      {
        path: 'inbox',
        component: PadreInboxComponent
      },
      {
        path: 'tablonUser',
        component: TablonUserComponent,
      },
      {
        path: 'profile',
        component: ProfileComponent,
        children: [
          {path: 'editar', component: ProfileEditComponent},
          {path: 'contacto', component: ProfileContactoComponent},
          {path: 'password', component: ProfilePasswordComponent},
          {path: '**', pathMatch: 'full', redirectTo: 'editar'}
          ]
      },

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PadreRoutingModule {
}
