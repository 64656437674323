<div class="container">

  <div class="row align-items-center mt-5">
    <div class="col-1 ">
      <button type="button"  [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" class="align-self-center menu">
        <i class="ft-menu" style="font-size: x-large"></i>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item [disabled]="finalizada ===true" (click)="finalizarAula()">
          <span>Finalizar Aula</span>
        </button>
        <button mat-menu-item [disabled]="finalizada !=true || alumnosAula.length === 0" (click)="vaciarAula()">

          <span>Vaciar aula</span>
        </button>
        <button mat-menu-item [disabled]="finalizada !=true || alumnosAula.length === 0" (click)="transeferirAlumnos()">

          <span>Mover alumnos</span>
        </button>
        <button mat-menu-item  [mat-menu-trigger-for]="exportar" [disabled]=" alumnosAula.length === 0">

          <span>Exportar datos</span>
        </button>
      </mat-menu>
      <mat-menu #exportar="matMenu">
        <button mat-menu-item (click)="exportDatosAlumnos(1)">
          <span>CSV Google</span>
        </button>
        <button mat-menu-item  (click)="exportDatosAlumnos(2)">

          <span>CSV Microsoft</span>
        </button>
        <button mat-menu-item  (click)="exportarSol()">

          <span>Excel Contasol</span>
        </button>
        <button mat-menu-item  (click)="exportarBancarios()">

          <span>Excel Bancarios</span>
        </button>
        <button mat-menu-item [disabled]="exportMails.length === 0" (click)="exportarMails()">
          <span>Excel Emails</span>
        </button>
        <button mat-menu-item [disabled]="pga.length === 0" (click)="exportPgaExcel()">

          <span>Excel PGA</span>
        </button>
        <button mat-menu-item [disabled]="exportMails.length === 0" (click)="exportTutores()">

          <span>Excel Tutores</span>
        </button>
      </mat-menu>
    </div>
    <div class="col-6">
      <h3 class="titulo-cabecera" *ngIf="!finalizada">{{aulaname}}</h3>
      <h3 class="titulo-cabecera" *ngIf="finalizada">{{aulaname}} (FINALIZADA)</h3>
    </div>

    <div class="col-lg-5">
      <form class="navbar-form" role="search" autocomplete="off">
        <div class="position-relative has-icon-right">
          <input (input)="searchActionNombre()" [(ngModel)]="nombreSearch"
                 class="form-control round"
                 name="search" placeholder="Buscar por Nombre" type="text">
          <div class="form-control-position">
            <i class="ft-search"></i>
          </div>
        </div>
      </form>
    </div>

  </div>





  <div class="row">
    <div class="col boton-agregar">
      <button *ngIf="estado" (click)="estado = !estado" type="button" class="btn btn-block btn-raised">Agregar alumnos
      </button>
      <button *ngIf="!estado" (click)="estado = !estado" type="button" class="btn btn-block btn-raised">Ver alumnos
      </button>
    </div>
  </div>

  <div class="row">
    <div [ngClass]="{'contenedor-alumnos': true}" *ngIf="!estado">
      <div [ngClass]="{'card-style': true}" *ngFor="let alumno of alumnos" draggable="true" (dragstart)="drag($event)"
           [id]="alumno.id">
        <div class="card-body">
          <img class="card-img-top unselectable" [src]="alumno.img | images: ''" alt="Card image cap"/>
          <div class="">
            <h4 [ngClass]="{'title-big':estado,'card-small':!estado}">{{alumno.nombre}}</h4>
            <p [ngClass]="{'text-big':estado, 'text-small': !estado}">{{alumno.apellidos}}</p>
            <p class="card-text">
            </p>
          </div>
        </div>
      </div>
    </div>

    <div [ngClass]="{'contenedor-alumnos': true,  'col':estado}" (drop)="drop($event)" (dragover)='allowDrop($event)'
         id="continer_aula">
      <div [ngClass]="{'card-style': true ,'col-2':estado}" *ngFor="let alumno of alumnosAula" draggable="false"
           [id]="alumno.id">
        <div class="card-body">
          <img draggable="false" (dragstart)="false;" class="card-img-top unselectable"
               [src]="alumno.img | images: ''" alt="Card image cap"/>
          <div class="caja">
            <h4 [ngClass]="{'title-big':estado,'card-small':!estado}">{{alumno.nombre}}</h4>

            <p [ngClass]="{'text-big':estado, 'text-small': !estado}">{{alumno.apellidos}}</p>
            <p class="card-icon">
              <i class="ft-x danger" (click)="delete(alumno.id)"></i>
              <i class="ft-eye success" [routerLink]="['/admin/users/',alumno.id, 'alumnos']"></i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
