import { LoginService } from '../../../pages/services/login.service';
import { UploadService } from '../../../services/upload.service';
import {Component, OnDestroy, ViewChild, OnInit, TemplateRef} from '@angular/core';
import { ClasesMService } from '../services/clasesM.service';
import { Subscription, Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import {FormControl, NgForm} from '@angular/forms';
import {CalendarEvent, DAYS_OF_WEEK} from 'angular-calendar';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';
import {ModalDismissReasons, NgbCalendar, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CalendarioService} from '../../../componentes/calendario/services/calendario.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-generar-examen',
  templateUrl: 'generar-examen.component.html',
  styleUrls: ['../clasesM.component.scss']
})
export class GenerarExamenMComponent implements OnInit, OnDestroy {
  @ViewChild('modalEvents') modalEvents: TemplateRef<any>;
  view = 'month';
  viewDate: Date = new Date();
  aulaClase = sessionStorage.getItem('aula');
  modalData: {
    action: string,
    event: CalendarEvent
  };
  closeResult: string;
  refresh: Subject<any> = new Subject();


  event = {
    start: new Date(),
    end: new Date(),
    from: '',
    to: '',
    title: '',
    color: '#ad2121',
    type: 1,
    id: '',
    typo: 1,
    h_inicio: {
      hour: 0,
      minute: 0,
      second: 0
    },
    h_fin: {
      hour: 0,
      minute: 0,
      second: 0
    }
  };

  inicio: any;
  fin: any;

  hoveredDate: NgbDate;
  fromDate: NgbDate;
  toDate: NgbDate;
  locale = 'es';
  weekendDays: number[] = [DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY];


  events: CalendarEvent[] = [];

  paramsSubscription: Subscription = new Subscription();

  fcInicio: any;
  fcFin: any;
  inicioFijo: any;
  finFijo: any;


  asignatura: number;
  aula: number;
  id: number;
  calendario: false;
  constructor(private _clases: ClasesMService, private _upload: UploadService, private _login: LoginService, private _route: ActivatedRoute,
    private _router: Router, private modal: NgbModal, private calendar: NgbCalendar, public service: CalendarioService
  ) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getToday();
    this.service.lista_tipos();
    this.service.lista();
    this.service.params();
    this.aula = this._route.snapshot.parent.params['curso'];
    this.asignatura = this._route.snapshot.parent.params['id'];

  }

  ngOnInit() {
    this._clases.idExamen.subscribe(res => {
      this.id = res;
    });
    this.paramsSubscription = this.service.params$.subscribe(resp => {
      this.event.h_inicio.hour = resp.horaInicio;
      this.event.h_inicio.minute = resp.minutoInicio;
      this.event.h_fin.hour = resp.horaFin;
      this.event.h_fin.minute = resp.minutoFin;
      this.inicio = {hour: resp.horaInicio, minute: resp.minutoInicio};
      this.fin = {hour: resp.horaFin, minute: resp.minutoFin};
      this.inicioFijo = this.inicio;
      this.finFijo = this.fin;
      this.fcInicio = new FormControl('', (control: FormControl) => {
        const value = control.value;
        if (value.hour >= this.inicioFijo.hour && value.hour <= this.finFijo.hour) {
          return null;
        }
        return {FueraRango: true};
      });
      this.fcFin = new FormControl('', (control: FormControl) => {
        const value = control.value;
        if (value.hour >= this.inicioFijo.hour && value.hour <= this.finFijo.hour) {
          return null;
        }
        return {FueraRango: true};
      });
    });
    this.service.modal$.subscribe(resp => {
      if (resp) {
        this.modal.open(this.modalEvents);
      }
    });

  }


  openAdd() {
    this.fromDate = this.calendar.getToday();
    this.toDate = this.calendar.getToday();
    this.event = {
      start: new Date(),
      end: new Date(),
      from: '',
      to: '',
      title: '',
      color: '',
      type: 1,
      id: '',
      typo: this.aula,
      h_inicio: {
        hour: 0,
        minute: 0,
        second: 0
      },
      h_fin: {
        hour: 0,
        minute: 0,
        second: 0
      }
    };
    this.open(this.modalEvents);
  }
  open(content) {
    this.modal.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

   getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }
  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

  addEvent(id?: any, modal?: any): void {
    let setEnd = '';

    this.event.h_inicio.hour = this.inicio.hour;
    this.event.h_inicio.minute = this.inicio.minute;
    this.event.h_fin.hour = this.fin.hour;
    this.event.h_fin.minute = this.fin.minute;

    const setFrom = this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day + ' '
      + this.inicio.hour + ':' + this.inicio.minute + ':00';

    this.event.from = this.fromDate.day + '-' + this.fromDate.month + '-' + this.fromDate.year + ' '
      + this.inicio.hour + ':' + this.inicio.minute + ':00';

    if (this.toDate === null) {
      this.event.start = new Date(setFrom);
      this.event.end = new Date(setFrom);
      this.event.to = this.fromDate.day + '-' + this.fromDate.month + '-' + this.fromDate.year + ' '
        + this.fin.hour + ':' + this.fin.minute + ':00';
      setEnd = this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day + ' '
        + this.fin.hour + ':' + this.fin.minute + ':00';

    } else if (this.fromDate && this.toDate) {
      setEnd = this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day + ' '
        + this.fin.hour + ':' + this.fin.minute + ':00';
      this.event.to = this.toDate.day + '-' + this.toDate.month + '-' + this.toDate.year + ' '
        + this.fin.hour + ':' + this.fin.minute + ':00';

      this.event.start = new Date(setFrom);
      this.event.end = new Date(setEnd);

    }
    if (id) {
      this.event.id = id + '';
    }
    const idi = this._login.getIdentity();
    if (this.event.type) {
      const ex = {
        examen: this.event.title,
        tipo: this.event.type,
        asignatura: this.asignatura,
        aula: this.aula,
        user: idi.id,
        date: new Date(setFrom)
      };
      if (this.calendario) {
        console.log(ex);
        console.log(this.event);
        this.service.add(this.event);
        this._clases.addExamen(ex);
        modal.close();
        this._router.navigate([`/maestro/info/${this.aula}/${this.asignatura}/lista-examenes`]);
      } else {
        console.log(ex);
        this._clases.addExamen(ex);
        modal.close();
        this._router.navigate([`/maestro/info/${this.aula}/${this.asignatura}/lista-examenes`]);
      }
    } else {
      Swal.fire('', 'Favor seleccionar un tipo', 'warning');
    }
  }
  ngOnDestroy() {
    this.paramsSubscription.unsubscribe();
  }
}
