import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import {CentroService} from '../services/centro.service';
import {Subscription} from 'rxjs';
import {DatePipe} from '@angular/common';
import swal from 'sweetalert2';


@Component({
  selector: 'app-temporada',
  templateUrl: './temporada.component.html',
  providers: [DatePipe]
})

export class TemporadaComponent implements OnDestroy, OnInit {
  // cursos: any;
  eventoCurso: any;
  id = 1;
  cursoSubscription: Subscription = new Subscription();
  eventosSubscripcion: Subscription = new Subscription();
  evento = {
    evento: '',
    inicio: {},
    fin: {}
  };
  indexEdit = -1;

  constructor(private calendar: NgbCalendar, private _centro: CentroService, private  datePipe: DatePipe) {
    this.id = 0;
    this._centro.getEventoCurso();
  }

  ngOnInit(): void {
    this.cursoSubscription = this._centro.eventoCurso$.subscribe(resp => {
      this.eventoCurso = resp;
    });
    this.eventosSubscripcion = this._centro.addEventoCurso$.subscribe(result => {
      this.eventoCurso = result;
    });
  }

  ngOnDestroy(): void {
    this.cursoSubscription.unsubscribe();
  }

  onDateSelection(event) {
  }

  editar(index: number) {
    this.setClickedRow(index);
    this.evento.evento = this.eventoCurso[index].evento;
    const inicio = this.datePipe.transform(this.eventoCurso[index].inicio.date, 'dd/MM/yyyy');
    const i = inicio.split('/');
    const ini = {
      day: +i[0],
      month: +i[1],
      year: +i[2]
    };
    const fin = this.datePipe.transform(this.eventoCurso[index].fin.date, 'dd/MM/yyyy');
    const f = fin.split('/');
    const fi = {
      day: +f[0],
      month: +f[1],
      year: +f[2]
    };
    this.evento.inicio = ini;
    this.evento.fin = fi;
    this.id = this.eventoCurso[index].id;
  }

  eliminar(index: number) {
    swal.fire({
      title: 'BORRAR',
      text: '¿Seguro que quiere borrar el calendario ' + this.eventoCurso[index].evento + '?',
      icon: 'error',
      showCancelButton: true,
      confirmButtonText: 'Si, Eliminar!',
      cancelButtonText: 'No, Cancelar!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        swal.fire('Mensaje', 'Se ha eliminado el calendario ' + this.eventoCurso[index].evento, 'success');
        this._centro.deleteEventoCurso(+this.eventoCurso[index].id);
        this.eventoCurso.splice(index, 1);
      }
    });
  }

  onSubmit(form: any): void {
    if (form.inicio && form.fin) {
      const evento = {
        evento: form.evento,
        inicio: form.inicio.year + '-' + form.inicio.month + '-' + form.inicio.day,
        fin: form.fin.year + '-' + form.fin.month + '-' + form.fin.day,
        id: this.id
      };
      this.id = 0;
      this._centro.addEventoCursos(evento);
      this.indexEdit = -1;
      this.limpiarEvento();
    }
  }

  setClickedRow(index) {
    this.indexEdit = index;
  }

  cancelEdit() {
    this.indexEdit = -1;
    this.limpiarEvento();
  }

  limpiarEvento() {
    this.evento = {
      evento: '',
      inicio: {},
      fin: {}
    };
  }
}
