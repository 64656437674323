import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup} from '@angular/forms';
import {UsersService} from '../../../services/users.service';

@Component({
  selector: 'app-add-tutor',
  templateUrl: './add-tutor.component.html',
  styleUrls: ['./add-tutor.component.scss']
})
export class AddTutorComponent implements OnInit {

  searchForm: FormGroup;
  tutores = [];

  constructor(public dialogRef: MatDialogRef<AddTutorComponent>, private userService: UsersService) {
    this.initForm();
  }

  ngOnInit(): void {

  }
  initForm(): void {
    this.searchForm = new FormGroup({
      nombre: new FormControl(''),
      apellidos : new FormControl(''),
    });
  }

  search() {
    if (this.searchForm.value.nombre.length >= 3 || this.searchForm.value.apellidos.length >= 3  ) {
      console.log('Llamo a la api');
      this.userService.searchTutores(this.searchForm.value.nombre, this.searchForm.value.apellidos).subscribe( (res) => {
        this.tutores = res['id'];
      })
    }
  }

  addTutor(id) {
    console.log('tutorAñadido');
    this.dialogRef.close(id);
  }

}
