import {Component} from '@angular/core';
import {LoginService} from '../../pages/services/login.service';

@Component({
  selector: 'app-maestro-inbox',
  templateUrl: './maestro-inbox.component.html'
})

export class MaestroInboxComponent {
  id_usuario: number;
  id_centro: number;
  aula: any;

  constructor (private _login: LoginService) {
    this.id_usuario = this._login.getIdentity().id;
    this.id_centro = this._login.getIdentity().centro.id;
    this.aula = 'maestro';

  }


}
