// Importamos los componentes necesarios
import {Pipe, PipeTransform} from '@angular/core';

// Le ponemos un nombre a la tuberia
@Pipe({name: 'contactos'})

// Definimos la clase implementado la interface PipeTransform
export class ContactosPipe implements PipeTransform {
  transform (value: Array<any>, tipo: string): Array<string> {

    const contacto: Array<string> = [];
    if (value != null) {
      for (let i = 0; i < value.length; i++) {
        if (value[i]['tipo'] === tipo) {

          contacto.push(value[i]);
        }
      }
      return contacto;
    }
    return contacto;

  }
}
