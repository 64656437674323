import { Injectable } from '@angular/core';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import {DatePipe} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class MatriculaCursillosService {
  dato: any;
  cursillo: any;
  cursillos: any;
  observaciones: any;
  docList:  any;
  fecha
  constructor(private datePipe: DatePipe) { }

  async generatePdfAbastos(action = 'download', alumnoData, cursillo, cursillos, observaciones, docList, fecha) {
    this.dato = alumnoData;
    this.cursillo = cursillo;
    this.cursillos = cursillos;
    this.observaciones = observaciones;
    this.docList = docList;
    this.fecha = fecha;
    const documentDefinition = await this.getDocumentDefinitionAbastos();
    switch (action) {
      case 'open':
        pdfMake.createPdf(documentDefinition).open();
        break;
      case 'print':
        pdfMake.createPdf(documentDefinition).print();
        break;
      case 'download':
        pdfMake.createPdf(documentDefinition).download('Abastos-' + this.dato.nombre + ' ' + this.dato.apellidos + '.pdf');
        break;
      default:
        pdfMake.createPdf(documentDefinition).open();
        break;
    }
  }

  async getDocumentDefinitionAbastos() {
    sessionStorage.setItem('resume', JSON.stringify(this.dato));
    return {
      content: [
        {
          // 1. CABECERA
          columns: [
            await this.imagenPdf('https://apiescuela.cieep.com/images/centro/logo_con_datos.png', 220, 'left', 0),
            // await this.imagenPdf(RutasImg + 'images/centro/logo_con_datos.png', 220, 'left', 0),
            {
              table: {
                widths: ['*'],
                body: [
                  [
                    {text: 'MATRICULA', bold: true, fontSize: 20, alignment: 'center', border: [false, false, false, false]}
                  ],
                  [
                    {text: 'CURSO: ' + new Date().getFullYear() , border: [false, false, false, false]}
                  ],
                  [
                    {text: 'Nº MATRICULA: CI' + this.cursillo.id + '/' + this.dato.id, border: [false, false, false, false]}
                  ],
                  [
                    // tslint:disable-next-line:max-line-length
                    {
                      text: 'FECHA DE MATRICULA: ' + this.datePipe.transform(this.fecha, 'dd-MM-YYYY'),
                      border: [false, false, false, false]
                    }
                    // {text: 'FECHA DE MATRICULA: ' + '', border: [false, false, false, false]}
                  ]
                ]
              }
            },
            //await this.imagenPdf(RutasImg + this.dato.img, 70, 'right', 0)
          ],
          columnGap: 10
        },
        {
          text: ' '
        },
        {
          table: {
            widths: [10 , '*', '*', 60],
            body: [
              [ {text: '', style: 'tableHead2'},
                {text: 'CURSO INTENSIVO ' + new Date().getFullYear(), style: 'tableHead2'},
                {text: 'FECHAS', style: 'tableHead2'},
                {text: 'IMPORTE', style: 'tableHead2'}
              ],
              ...this.cursillos.map(cur => {
                return this.formatCursillos(cur);
              }),
            ],
          }
        },
        {
          // Salto de linea
          text: ' '
        },
        // 2. DATOS PERSONALES DEL ALUMNO
        {
          table: {
            widths: ['*', '*', '*', '*', '*', '*'],
            body: [
              [{text: 'DATOS PERSONALES DEL ALUMNO', style: 'tableHeader1', colSpan: 6}, {}, {}, {}, {} , {}],
              [

                {width: 300, text: 'Apellidos, nombre', style: 'tableHead2' },
                {text: this.dato.apellidos + ', ' + this.dato.nombre, colSpan: 5 }

              ],
              [
                {text: 'DNI/Pasaporte', style: 'tableHead2'},
                {text: this.dato.dni},
                {text: 'F. nacimiento', style: 'tableHead2'},
                { text: this.datePipe.transform(this.dato.nacimiento, 'dd-MM-YYYY') },
                { text: 'Provincia', style: 'tableHead2'},
                { text: this.dato.provincia }
              ],
            ]
          }
        },
        this.getTitulo(),
        {
          // Salto de linea
          text: ' ',
        },
        // 4. DOCUMENTACIÓN APORTADA (A RELLENAR POR EL CENTRO)
        {
          table: {
            widths: [10, '*',  10, '*'],
            body: [
              [
                {text: 'DOCUMENTACIÓN APORTADA (A RELLENAR POR EL CENTRO)', style: 'tableHeader1', colSpan: 4}, {}, {}, {}
              ],
              [
                {text: this.docList.dni === true ? 'X' : ''},
                {text: 'FOTOCOPIA DNI'},
                {text: this.docList.sip === true ? 'X' : ''},
                {text: 'FOTOCOPIA SIP'}
              ]

            ]
          }
        },

        {
          // Salto de linea
          text: ' '
        },
        // OBSERVACIONES
        {
          table: {
            widths: ['*'],
            heights: ['*', 30],
            body: [
              [
                {text: 'OBSERVACIONES', style: 'tableHeader1'}
              ],
              [
                {text: this.observaciones, alignment: 'justify'}
              ]
            ],
          }
        },
        {
          table: {
            widths: ['*'],
            heights: [100],
            body: [
              [
                {text: '', border: [false, false, false, false]}
              ],
            ],


          }, layout: 'noBorders'
        },
        // SALTO DE LINEA
        {
          text: ' ',
        },
        {
          style: ['textoPequenyo'],
          // Salto de linea
          text: 'CFP Progresa se reserva el derecho a no impartir alguno de los cursosintensivos ofertados o a retrasar su inicio si no se alcanza el número mínimo de alumnos. En este caso se exime de cuantas responsabilidades pudieran derivarse de esta circunstancia. Solo en este caso se devolverá el importe total del curso.'
        },
        {
          text: ' ',
        },
        // 8. FIRMA
        {

          style: ['textoPequenyo', 'justificado'],
          text: [

            {
              text: 'Recibida la información detallada de las características del curso, contenido y demás cuestiones relacionadas, ' +
                'el alumno/titular acepta las condiciones generales y académicas amparadas en este contrato y ', margin: [0, 0, 0, 20]
            },
            {
              text: 'SOLICITA LA MATRÍCULA PARA EL CURSO', bold: true
            }
          ],
        },
        {
          // Salto de linea
          text: ' '
        },
        {
          style: ['textoPequenyo', 'justificado'],
          text: 'Valencia a ' + this.datePipe.transform(this.fecha, 'dd') + ' de ' + this.datePipe.transform(this.fecha, 'MMMM') + ' del ' +this.datePipe.transform(this.fecha, 'YYYY')
        },
        {
          // Salto de linea
          text: ' '
        },
        {
          columns: [
            {
              style: ['tituloTextoPequenyo'],
              table: {
                widths: ['*'],
                heights: [70],
                body: [
                  [
                    {text: 'Firma del alumno:'}
                  ]
                ]
              }
            },
            {
              style: ['tituloTextoPequenyo'],
              table: {
                widths: ['*'],
                heights: [70],
                body: [
                  [
                    {text: 'Firma y sello del centro:'}
                  ]
                ]
              }
            }
          ],
          columnGap: 10,
        },
      ],
      styles: {
        tableHeader1: {
          bold: true,
          alignment: 'center',
          fillColor: '#003300',
          color: 'white'
        },
        tableHead2: {
          bold: true,
          alignment: 'center',
          fillColor: '#ffffcc',
          fontSize: 10

        },
        tableHeader2: {
          bold: true,
          alignment: 'center',
          fillColor: '#ffffcc',
          fontSize: 9
        },
        tableHeader3: {
          bold: true,
          alignment: 'center',
        },
        textoPequenyo: {
          fontSize: 8
        },
        cursosList: {
          fontSize: 10,
          alignment: 'center'
        },
        centrado: {
          alignment: 'center',
        },
        justificado: {
          alignment: 'justify',
        },
        tituloTextoPequenyo: {
          fontSize: 10
        },
        negrita: {
          bold: true
        }
      }
    };
  }

  async imagenPdf(url: string, width: number, alineacion: string, rowSpan: number) {
    if (rowSpan > 1) {
      return {
        image: await this.getBase64ImageFromURL(url),
        width: width,
        alignment: alineacion,
        rowSpan: rowSpan
      };
    } else {
      return {
        image: await this.getBase64ImageFromURL(url),
        width: width,
        alignment: alineacion
      };
    }
  }

  // Para generar una imgen
  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = error => {
        reject(error);
      };
      img.src = url;
    });
  }

  private formatCursillos(cur) {
    return [
      {text: cur.id === this.cursillo.id ? 'X' : '', style: 'cursosList'},
      {text: cur.cursillo, style: 'cursosList'},
      {text: this.datePipe.transform(cur.inicio.date, 'dd') + ' de ' + this.datePipe.transform(cur.inicio.date, 'MMMM') + ' al '
        + this.datePipe.transform(cur.fin.date, 'dd') + ' de ' + this.datePipe.transform(cur.fin.date, 'MMMM')
          + ' - ' + cur.hInicio + ' a ' + cur.hFin, style: 'cursosList' },
      {text: cur.importe + '€', style: 'cursosList'}
    ];
  }

  getTitulo() {
    if ( this.dato.titulos.length > 0 ) {
      switch (this.dato.titulos[0].titulo) {
        case 'ESO': return  {
          table: {
            widths: [200, 10, '*', 10, '*', 10, '*'],
            body: [
              [
                {text: 'Req. de acceso:', style: 'tableHeader2', border: [true, false, true, true]},
                {text: '', border: [true, false, true, true]},
                {text: 'Título de Bachiller', border: [true, false, true, true]},
                {text:  '', border: [true, false, true, true]},
                {text: 'Prueba de acceso', border: [true, false, true, true]},
                {text: 'X', border: [true, false, true, true]},
                {text: 'ESO', border: [true, false, true, true]}
              ],
              [
                {text: 'Otros:', style: 'tableHeader3'},
                {text: '', colSpan: 6},
                {},
                {},
                {},
                {},
                {}
              ]
            ]
          }
        };
          break;

        case 'TITULO DE BACHILLER': return  {
          table: {
            widths: ['*', 10, '*', 10, '*', 10, '*'],
            body: [
              [
                {text: 'Req. de acceso:', style: 'tableHeader2', border: [true, false, true, true]},
                {text: 'X', border: [true, false, true, true]},
                {text: 'Título de Bachiller', border: [true, false, true, true]},
                {text:  '', border: [true, false, true, true]},
                {text: 'Prueba de acceso', border: [true, false, true, true]},
                {text: '', border: [true, false, true, true]},
                {text: 'ESO', border: [true, false, true, true]}
              ],
              [
                {text: 'Otros:', style: 'tableHeader3'},
                {text: '', colSpan: 6},
                {},
                {},
                {},
                {},
                {}
              ]
            ]
          }
        };
          break;

        case 'PRUEBA DE ACCESO': return  {
          table: {
            widths: ['*', 10, '*', 10, '*', 10, '*'],
            body: [
              [
                {text: 'Req. de acceso:', style: 'tableHeader2', border: [true, false, true, true]},
                {text: 'X', border: [true, false, true, true]},
                {text: 'Título de Bachiller', border: [true, false, true, true]},
                {text:  '', border: [true, false, true, true]},
                {text: 'Prueba de acceso', border: [true, false, true, true]},
                {text: '', border: [true, false, true, true]},
                {text: 'ESO', border: [true, false, true, true]}
              ],
              [
                {text: 'Otros:', style: 'tableHeader3'},
                {text: '', colSpan: 6},
                {},
                {},
                {},
                {},
                {}
              ]
            ]
          }
        };
        default: return  {
          table: {
            widths: ['*', 10, '*', 10, '*', 10, '*'],
            body: [
              [
                {text: 'Req. de acceso:', style: 'tableHeader2', border: [true, false, true, true]},
                {text: '', border: [true, false, true, true]},
                {text: 'Título de Bachiller', border: [true, false, true, true]},
                {text:  '', border: [true, false, true, true]},
                {text: 'Prueba de acceso', border: [true, false, true, true]},
                {text: '', border: [true, false, true, true]},
                {text: 'ESO', border: [true, false, true, true]}
              ],
              [
                {text: 'Otros:', style: 'tableHeader3'},
                {text: this.dato.titulos[0].titulo, colSpan: 6},
                {},
                {},
                {},
                {},
                {}
              ]
            ]
          }
        };
      }
    } else {
      return '';
    }
  }

}


