import {Component} from '@angular/core';


@Component({
  selector: 'app-centro',
  templateUrl: './centro.component.html'
})
export class CentroComponent {
  navLinks = [{}];

  constructor () {
    this.navLinks = [{path: 'add', label: 'Centro'},
      {path: 'curso', label: 'Crear curso'},
      {path: 'lista', label: 'Lista cursos'},
      {path: 'temporada', label: 'Inicio y Fin'}];


  }

}
