import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';
import {ActivatedRoute} from '@angular/router';
import {UsersService} from '../services/users.service';
import {User} from '../../../../modelos/user.modelo';
import swal from 'sweetalert2';
import {ExcelService} from '../../comercial/services/excel.service';

@Component({
  selector: 'app-lista-users',
  templateUrl: './lista.component.html',
  styleUrls: ['../users.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class ListaComponent implements  OnInit, OnDestroy {
  public tipo: string;
  public users: Array<User> = [];
  public allUsersForFilter: Array<User> = [];
  public id: number;
  public aula: number;
  public role: number;
  public usersSubscription: Subscription = null;
  actualPage: number;
  // Search de Ciclo
  cicloSearch: string;
  items: any[] = [];
  cDAM: string;
  cSMR: string;
  c3D: string;
  cSA: string;
  cALL: string;
  cc: any;
  exportCsvGoogle: any;

  // Search Nombre
  nombreSearch = '';

  // Search Apellido
  apellidoSearch = '';

  constructor(private _user: UsersService, private route: ActivatedRoute, private exportService: ExcelService) {
    this.cDAM = 'T.S. Desarrollo de Aplicaciones Multiplataforma (LOE)';
    this.cSMR = 'T. Sistemas Microinformáticos y Redes (LOE)';
    this.c3D = 'T.S. en Animaciones 3D, juegos y entornos interactivos (LOE)';
    this.cSA = 'Sin Asignación';
    this.cALL = 'Ver Todos';
    this.cicloSearch = this.cALL;
    this.actualPage = 1;
    this.route.params.subscribe(params => {
      this.tipo = params['tipo'];
      this.aula = params['aula'];
      console.log(this.aula);
      this._user.getUsers(this.tipo, this.aula);
      this._user.getCursosCentro();
    });
  }

  ngOnInit() {
    this.getUser();
    this.getCc();
  }

  ngOnDestroy() {
    this.usersSubscription.unsubscribe();
  }

  getCc() {
    this._user.getCC().subscribe( (res) => {
      this.cc = res;
    });
  }

  getUser() {
    this.usersSubscription = this._user.users$.subscribe(res => {
      this.users = res;
      if (this.users.length > 0) {
        this.role = +this.users[0].id_role;
        this.allUsersForFilter = this.users;
      }
    });
  }

  remove(id: number, index: number) {
    swal.fire({
      title: 'BORRAR',
      text: '¿Seguro que quiere borrar a ' + this.users[index].nombre + ' ' + this.users[index].apellidos + '?',
      icon: 'error',
      showCancelButton: true,
      confirmButtonText: 'Si, Eliminar!',
      cancelButtonText: 'No, Cancelar!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        swal.fire('Mensaje', 'Se ha eliminado',  'success');
          this._user.removeUser(id);
          this.users.splice(index, 1);
        setTimeout(function() { document.location.reload(); }, 2000);
      }
    });
  }

  selectActionCiclo() {
    this.nombreSearch = '';
    this.apellidoSearch = '';
    if (this.tipo !== undefined) {
      if (this.cicloSearch.length > 0 && this.cicloSearch !== this.cALL) {
        this.users = this.allUsersForFilter.filter((item) => item.nivel.toLowerCase().includes(this.cicloSearch.toLowerCase()));
      } else {
        this._user.getUsers(this.tipo, this.aula);
      }
    }
  }

  searchActionNombre() {
    this.apellidoSearch = '';
    if (this.tipo !== undefined) {
      if (this.nombreSearch.length > 0) {
        this.cicloSearch = this.cALL;
        const valor = this.allUsersForFilter.filter((item) => {
          const nombre = item.nombre;
          return nombre.toLowerCase().indexOf(this.nombreSearch.toLowerCase()) > -1;
        });
        this._user.usersSource.next(valor);
      } else {
        this._user.getUsers(this.tipo, this.aula);
      }
    }
  }

  searchActionApellidos() {
    this.nombreSearch = '';
    if (this.tipo !== undefined) {
      if (this.apellidoSearch.length > 0) {
        this.cicloSearch = this.cALL;
        const valor = this.allUsersForFilter.filter((item) => {
          const apellidos = item.apellidos;
          return apellidos.toLowerCase().indexOf(this.apellidoSearch.toLowerCase()) > -1;
        });
        this._user.usersSource.next(valor);
      } else {
        this._user.getUsers(this.tipo, this.aula);
      }
    }
  }


  exportDatosAlumnos() {
    console.log(this.users);
    if (this.cicloSearch === this.cALL) {
     this.exportService.exportExcelMultipleUsers(this.users, this.cc, 'Alumnos por modulo');
    } else {
      this.exportGoogleCSV( this.users);
      this.exportService.exportExcel(this.exportCsvGoogle, this.cicloSearch);
    }
  }




  exportGoogleCSV(users) {
    this.exportCsvGoogle = [];
    for (const a of users) {
      this.exportCsvGoogle.push({
        'Apellidos': a.apellidos ,
        'Nombre': a.nombre,
        'DNI' : a.dni,
      });
    }
  }
}
