import {Component, Input, ViewChild, OnDestroy, OnInit} from '@angular/core';
import {AulasService} from '../../services/aulas.service';
import {Subscription} from 'rxjs/Subscription';
import {NgForm} from '@angular/forms';
import {LoginService} from '../../../../pages/services/login.service';
import {Router} from '@angular/router';
import {CentroService} from '../../../admin-centro/services/centro.service';
import Swal from 'sweetalert2';



@Component({
  selector: 'app-add-aula',
  templateUrl: './add-aula.component.html',
  styleUrls: ['../aulas.component.scss']
})

export class AddAulaComponent implements OnDestroy, OnInit {
  @Input() id = 0;
  @ViewChild('f') loginForm: NgForm;
  cursosCentroSubscription: Subscription = new Subscription();
  eventoCursoSubscription: Subscription = new Subscription();
  eventoCurso: any[] = [];
  loadingIndicator = true;
  cursosCentro: any[] = [];
  nivel: any;
  aula: any;
  anyoCurso: number;
  ratio: number;
  curso: number;
  iniCurso: any;
  turno: any;
  aulasFisicas: any ;
  aulaSeleccionada: any;
  aulasFiltered:  any = [];

  constructor(private _login: LoginService, private _curso: AulasService, private route: Router, private _centro: CentroService) {
    setTimeout(() => {
      this.loadingIndicator = false;
    }, 1500);
    this._curso.getCursosCentro();
    this._centro.getEventoCurso();
  }

  ngOnInit(): void {
    this.getAulasFisicas();
    this.cursosCentroSubscription = this._curso.cursoCentro$.subscribe(res => {
      this.cursosCentro = res;
      this.nivel = res.nivel;
    });
    this.eventoCursoSubscription = this._centro.eventoCurso$.subscribe(resp => {
      this.eventoCurso = resp;
      console.log(this.eventoCurso);
    });
    console.log(this.eventoCurso);
  }

  ngOnDestroy(): void {
    this.cursosCentroSubscription.unsubscribe();
  }

  showAnyoCurso(id: number) {
    this._curso.getAnyoCurso(id);
  }

  onSubmit() {
    console.table(this.loginForm.value);
    if (this.loginForm.valid) {

      // if (this.turno ) {
      //     aula = {
      //     id: this.aulaSeleccionada,
      //     aulaMañana: this.aula,
      //     inicioMañana: this.eventoCurso[this.iniCurso].inicio.date,
      //     finMañana: this.eventoCurso[this.iniCurso].fin.date
      //   };
      // } else {
      //     aula = {
      //     id: this.aulaSeleccionada,
      //     aulaTarde: this.aula,
      //     inicioTarde: this.eventoCurso[this.iniCurso].inicio.date,
      //     finTarde: this.eventoCurso[this.iniCurso].fin.date
      //   };


      this._curso.add(this.loginForm.value);
      // this._curso.updateClase(aula).subscribe( (res) => {
      // });
    }
  }

  getAulasFisicas() {
    this._curso.getAulasFisicas().subscribe( (res) => {
      console.log(res);
      console.log(this.turno);
      this.aulasFisicas = res;
    });
  }
  filtro() {
    this.aulaSeleccionada = undefined;
    this.aulasFiltered = [];
    if (this.turno === '1') {
      this.aulasFisicas.map(a => {
        if (a.aulaManyana === null) {
          this.aulasFiltered.push(a);
        }
      });
    } else if (this.turno === '0') {
      this.aulasFisicas.map(a => {
        if (a.aulaTarde === null) {
          this.aulasFiltered.push(a);
        }
      });
    }
  }


  addFormaContacto() {
    (async () => {
      const { value: numClase } = await Swal.fire({
        title: 'Añadir aula',
        input: 'text',
        allowOutsideClick: false,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Guardar',
        reverseButtons: true,
      })
      if (numClase ) {
        this._curso.addAulaFisica(numClase).subscribe((res) => {
          console.log(res);
          this.aulasFisicas = res['id'];

          Swal.fire('', 'Se ha guardado correctamente', 'success');

        }, (error) => {
          console.log(error);
          Swal.fire('', error.error, 'warning');
        });
      }
    })()
  }
}


