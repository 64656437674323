<div class="container">
  <h1 *ngIf="alumno" class="titulo-cabecera"> {{alumno.nombre}} {{alumno.apellidos}}</h1>
  <div class="row ficha_alumno" *ngIf="alumno">
    <div class="col-2" *ngIf="alumno.observaciones == null"></div>
    <div class="col-4">
      <img [src]="alumno.img | images: ''" class="img-thumbnail"/>
    </div>

    <div class="col-4">
      <label>Datos de contacto</label>
      <ul class="list-group">
        <li class="list-group-item alumno-general"  *ngFor="let contacto of contactos;">{{contacto.tipo}} : {{contacto.dato}}</li>
      </ul>
    </div>

    <div class="col-4" *ngIf="alumno.observaciones != null">
      <label>Observaciones</label>
      <ul class="list-group">
        <li class="list-group-item alumno-general"  >{{alumno.observaciones}}</li>
      </ul>
    </div>



  </div>


</div>

