import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {AlumnosService} from '../services/alumnos.service';


@Component({
  selector: 'app-datos-alumno',
  templateUrl: './datos-alumno.component.html',
  styleUrls: ['../alumnos.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DatosAlumnoComponent implements OnInit, OnDestroy {
  alumnoSubscription: Subscription = new Subscription();
  totalSubscription: Subscription = new Subscription();
  asistenciasSubscription: Subscription = new Subscription();
  totalHorasSubscription: Subscription = new Subscription();
  id: number;
  alumno: any;
  contactos: any[] = [];
  total: number;
  asistencias: number;
  totalHoras: number;


  constructor (private _route: ActivatedRoute, private _alumnos: AlumnosService) {
    this.id = this._route.snapshot.parent.params['id'];
    this._alumnos.getAlumno(this.id);

  }

  ngOnInit () {
    this.alumnoSubscription = this._alumnos.alumno$.subscribe(res => {
      this.alumno = res.dato;
      console.log(res);
      this.contactos = res.contactos;
    });
  }

  ngOnDestroy () {
    this.alumnoSubscription.unsubscribe();
    this.totalSubscription.unsubscribe();
  }

}

