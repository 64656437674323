import {Component, ViewChild, OnDestroy, OnInit} from '@angular/core';
import {ProfileService} from '../services/profile.service';
import {Subscription} from 'rxjs/Subscription';
import {NgForm} from '@angular/forms';
import {LoginService} from '../../../pages/services/login.service';
import {UploadService} from '../../../services/upload.service';
import {CentroService} from '../../admin-centro/services/centro.service';
import {UsersService} from '../../admin-users/services/users.service';


@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html'
})

export class ProfileEditComponent implements OnDestroy, OnInit {
  id: number;
  @ViewChild('f') loginForm: NgForm;
  public provinciaSubscription: Subscription = null;
  public userSubscription: Subscription = null;
  public empleadoSubscription: Subscription = null;
  private municipioSubscription: Subscription = null;
  municipios: any = [];
  public filesToUpload: Array<File>;
  public empleado: any = {};
  public provincias: any[] = [];

  constructor (private _user: ProfileService,
               private _login: LoginService,
               private _upload: UploadService,
               private _centro: CentroService,
               private _empleado: UsersService,
               private _profile: ProfileService) {
    this._profile.getProfile();
    this._centro.getProvincias();
  }

  ngOnInit () {
    this.empleadoSubscription = this._profile.user$.subscribe(data => {
      this.empleado = data;
    });
    this.provinciaSubscription = this._centro.provincias$.subscribe(data => {
      this.provincias = data;
    });
  }

  ngOnDestroy (): void {
    if (this.municipioSubscription !== null) {
      this.municipioSubscription.unsubscribe();
    }

    this.provinciaSubscription.unsubscribe();
    this.empleadoSubscription.unsubscribe();
  }

  getMunicipio () {
    this._centro.getMunicipios(this.loginForm.value.provincia);
    this.municipioSubscription = this._centro.municipios$.subscribe(data => {
      this.municipios = data;
    });
  }

  onSubmit () {
    if (this.loginForm.valid) {
      this._user.update(this.loginForm.value);
    }
  }

  addPhoto (type: string) {
    this._upload.makeFileRequest(this.id, this.filesToUpload, 'imagen', type)
      .then((result: any) => {
        if (type === 'user') {
          const identity = this._login.getIdentity();
          identity.img = 'images/users/' + result.id;
          localStorage.setItem('identity', JSON.stringify(identity));
          this.empleado.img = identity.img;
        }
      }, error => {
        console.log(error);
      });
  }

  fileChangeEvent (fileinput: any, type: string) {
    this.filesToUpload = <Array<File>>fileinput.target.files;
    this.addPhoto(type);
  }


}


