import {Component, OnInit, ViewChild, OnDestroy, TemplateRef} from '@angular/core';
import {Horarios} from '../modelo/horarios';
import {HttpService} from '../services/http.service';
import {Subscription} from 'rxjs/Subscription';
import {colors} from '../../calendario/utils/color';
import {Subject} from 'rxjs';
import {CalendarEvent} from 'angular-calendar';
import {addDays, isFriday, isMonday, isThursday, isTuesday, isWednesday, startOfWeek} from 'date-fns';
import {LoginService} from '../../../pages/services/login.service';


@Component({
  selector: 'app-agenda-alumno',
  templateUrl: './agenda-alumno.component.html',
  styleUrls: ['./agenda-alumno.component.scss']
})
export class AgendaAlumnoComponent implements OnInit, OnDestroy {

  // Suscripciones
  horariosSubscrition: Subscription = new Subscription();
  datosSourceSubscrition: Subscription = new Subscription();

  horarios: Horarios;
  height: any;
  profesores: any[] = [];
  asignaturas: any[] = [];
  aula: any[] = [];
  aulaIDArray: any[] = [];
  aulaID: number;
  id: number;
  curso: string;

  @ViewChild('modalContent', {static: true}) modalContent: TemplateRef<any>;
  refresh: Subject<any> = new Subject();
  viewDate: Date = new Date();

  start: any;
  end: any;

  asignaturasMostrar: CalendarEvent[] = [];

  constructor(private _http: HttpService,
              private _login: LoginService) {
  }

  ngOnInit() {

    this._http.getAula();

    this.datosSourceSubscrition = this._http.datos$.subscribe(iDdelAula => {
      this.aulaIDArray = iDdelAula;
      for (const a of this.aulaIDArray) {
        this.aulaID = a.aulaId;
      }
      console.log(this.aulaID);
      this._http.get(this.aulaID);
    });

    this.horariosSubscrition = this._http.aula$.subscribe(resHorarios => {
      this.aula = resHorarios;
      console.log(this.aula);
      this.mostrarClasesEnCalendario();
    });
  }

  mostrarClasesEnCalendario() {
    this.asignaturasMostrar = [];
    const principioSemanaActual = addDays(startOfWeek(new Date()), 1);
    console.log(this.aula);
    this.aula.forEach(clase => {
      console.log(clase);

      clase = this.actualizarFechaParaMostrar(clase, principioSemanaActual);
      console.log(clase);

        this.asignaturasMostrar = [
          ...this.asignaturasMostrar, {
            id: clase.id,
            start: new Date(clase.start),
            end: new Date(clase.fin),
            title: this.asignaturaFormatter(clase),
            color: colors[colors.findIndex(color => color.name === clase.color)],
            allDay: false,
          }
        ];

    });

  }

  asignaturaFormatter(clase: any) {
    return '<strong>' + clase.asignatura + '</strong>';
  }

  actualizarFechaParaMostrar(clase: any, principioSemanaActual: any) {
    switch (true) {
      case isMonday(clase.start): {
        return this.setDiaEnSemanaActual(clase, principioSemanaActual, 0);
      }
      case isTuesday(clase.start): {
        return this.setDiaEnSemanaActual(clase, principioSemanaActual, 1);
      }
      case isWednesday(clase.start): {
        return this.setDiaEnSemanaActual(clase, principioSemanaActual, 2);
      }
      case isThursday(clase.start): {
        return this.setDiaEnSemanaActual(clase, principioSemanaActual, 3);
      }
      case isFriday(clase.start): {
        return this.setDiaEnSemanaActual(clase, principioSemanaActual, 4);
      }
      default: {
        return clase;
      }
    }
  }

  setDiaEnSemanaActual(clase: any, principioSemanaActual: any, amount: number) {
    const anyo = addDays(principioSemanaActual, amount).getFullYear();
    const mes = addDays(principioSemanaActual, amount).getMonth();
    const dia = addDays(principioSemanaActual, amount).getDate();
    clase.start = new Date(clase.start).setFullYear(anyo, mes, dia);
    clase.fin = new Date(clase.fin).setFullYear(anyo, mes, dia);
    return clase;
  }

  ngOnDestroy() {
    this.horariosSubscrition.unsubscribe();
    this.datosSourceSubscrition.unsubscribe();
  }
}
