<section id="task" *ngIf="!ver_">
<div class="row">
  <div class="col-sm-12">
    <div class="card">
      <div class="card-header">
        <h5 class="card-title">Tareas alumno</h5>
      </div>
    </div>
  </div>
</div>
  <div class="row">
    <div class="col-12">
      <table class="table table-striped">
        <thead>
        <tr>
          <th scope="col">Asignatura <i [ngClass]="{'ft-chevron-up': order , 'ft-chevron-down': !order}"
                                        (click)="ordenar('asignatura')"></i></th>
          <th scope="col">Tarea <i [ngClass]="{'ft-chevron-up': order , 'ft-chevron-down': !order}"
                                   (click)="ordenar('tarea')"></i></th>
          <th scope="col">Estado <i [ngClass]="{'ft-chevron-up': order , 'ft-chevron-down': !order}"
                                    (click)="ordenar('estado_t')"></i></th>
          <th scope="col">Fecha <i [ngClass]="{'ft-chevron-up': order , 'ft-chevron-down': !order}"
                                   (click)="ordenar('fecha_t')"></i></th>
          <th scope="col">Acciones</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let task of tasks; let i = index">
          <td>{{task.asignatura}}</td>
          <td>{{task.taskTitle}}</td>
          <td><i [ngClass]="{'ft-check success' : task.status === 'completed', 'ft-zap warning': task.status === 'inProcess', 'ft-info danger':task.status === 'todo' }"></i></td>
          <td> {{ task.createdOn.date | date: "dd-MM-yy" }}</td>
          <td>
            <ul class="list-inline">
              <li class="list-inline-item success p-0">
                <i class="ft-eye font-medium-3 mr-2" (click)="ver(i)"></i>
              </li>
            </ul>
          </td>
        </tr>
        </tbody>
      </table>


  </div>
</div>
</section>


<section id="verTask" *ngIf="ver_ && task">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">{{task.taskTitle}}</h5>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-9">

      <table class="table table-files" *ngIf="task.files.length > 0">
        <thead>
        <tr>
          <th scope="col">Id</th>
          <th scope="col">Fecha</th>
          <th scope="col">Acciones</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let file of task.files; let i = index">
          <td>{{i}}</td>
          <td>{{file.fecha.date | date: 'dd/MM/yy'}}</td>
          <td>
            <ul class="list-inline">
              <li class="success p-0 list-inline-item">
                <i class="ft-file font-medium-3 mr-2"></i>
              </li>
            </ul>
          </td>
        </tr>
        </tbody>

      </table>

    </div>

    <div class="col-3">
      <ul class="list-group tool-panel">
        <li class="list-group-item" (click)="ver_ = false">Volver <i class="ft-arrow-left"></i></li>
        <li class="list-group-item">Creado:
          <small>{{task.createdOn.date| date: 'dd/MM/yy' }}</small>
        </li>
        <li class="list-group-item">Estado: <i (click)="changeEstado(task.index, task.status)"
                                               [ngClass]="{'ft-check success' : task.status === 'completed', 'ft-zap warning': task.status === 'inProcess', 'ft-info danger':task.status === 'todo' }"></i>
        </li>

      </ul>
    </div>

  </div>


</section>



