import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';
import {JornadaService} from '../../admin-users/services/jornada.service';
import {RutasImg} from '../../../../utiles/Rutas';
import {CentroService} from '../../admin-centro/services/centro.service';
@Component({
  selector: 'app-fichajes',
  templateUrl: './fichajes.component.html',
  styleUrls: ['./fichajes.component.scss']
})
export class FichajesComponent implements OnInit, OnDestroy {
  public fichados: any[] = [];
  public id: number;
  public fecha: string;
  public hoy: string;
  public fechas: any[] = [];
  public config: any;
  public alerta: number;
  public rutaimg: any;
  public empleados = false;
  public administracion = false;
  public numeroEmpresas: any[] = [];
  radio = '';

  public fichadosSubscription = new Subscription();
  public configSubscription = new Subscription();
  public empresaSubscription = new Subscription();

  empresas: any[] = [];

  constructor(private _empleados: JornadaService, private _centro: CentroService) {
    this.hoy = new Date().toISOString().substring(0, 10);
    this.alerta = 0;
    this.rutaimg = RutasImg;
    this._empleados.getConfig().subscribe(res => {
      this.config = res['id'][0];
      this.radio = this.config.radio;
      console.log(this.config);
      this._empleados.getUsers(2, this.alerta, this.fecha);
    });
    this.empresaSubscription = this._centro.centro$.subscribe(res => {
      this.empresas = res;
    });
  }

  ngOnInit() {
    this.fichadosSubscription = this._empleados.fichados$.subscribe(r => {
      this.fichados = r;
      console.log(this.fichados);
      this.comprobarDepartamentos();
      this._centro.getCentro();
    });
  }

  ngOnDestroy() {
    this.fichadosSubscription.unsubscribe();
    this.configSubscription.unsubscribe();
    this.empresaSubscription.unsubscribe();
  }

  empieza(fecha: string) {
    if (fecha.startsWith(this.hoy)) {
      return true;
    } else {
      return false;
    }
  }

  comprobarDepartamentos() {
    this.fichados.forEach(user => {
      if (user.id_role === '2') {
        this.empleados = true;
      }
      if (user.id_role === '3') {
        this.administracion = true;
      }
    })
  }
}
