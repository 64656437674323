import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {UsersService} from '../../services/users.service';
import {ActivatedRoute} from '@angular/router';
import Swal from 'sweetalert2';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-observaciones',
  templateUrl: './observaciones.component.html',
  styleUrls: ['./observaciones.component.scss']
})
export class ObservacionesComponent implements OnInit, AfterViewInit {

  dato = { nombre: '', apellidos: ''};
  id: any;
  Observaciones: FormGroup;
  obs: any[] = [];
  displayedColumns = ['observacion', 'fecha'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  constructor(private _user: UsersService, private _route: ActivatedRoute, private fb: FormBuilder) {
    this.id = this._route.snapshot.parent.params['id'];
    this._user.getDato(this.id);
    this.crearFormulario();
  }

  ngOnInit() {
    this._user.dato$.subscribe(res => {
      console.log(res);
      this.dato = res;
    });
    document.getElementById('tabla').hidden = true;
  }

  ngAfterViewInit() {
    this.getObs();
  }

  crearFormulario(): any {
    this.Observaciones = this.fb.group({
      obs: [''],
    });
  }

  getObs() {
    this._user.getObs(this.id).subscribe( (res: any) => {
      if (res['code'] === 200) {
        this.obs = res['id'];
        if (this.obs.length > 0) {
          this.dataSource = new MatTableDataSource(this.obs);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          document.getElementById('tabla').hidden = false;
          console.log(this.obs);
        }
      } else {
        console.log(res);
      }
    });
  }

  onSubmit() {
    if (this.Observaciones.value.obs) {
      const user = {
        idUser: this.id,
        observacion: this.Observaciones.value.obs,
        tipo: 0
      }
      this._user.addObservaciones(user).subscribe( res => {
        if (res['code'] === 200) {
          Swal.fire('', 'Observación Guardada', 'success');
          this.getObs();
          this.Observaciones.reset();
        } else {
          console.log(res);
        }
      });
    } else {
      Swal.fire('', 'Favor ingresar la Observación', 'warning');
    }
  }
}
