<h4 class="info-top"><i class="ft-user"></i> Asistencias: {{datos}}</h4>
<div class="row">
  <div class="card">
    <div class="row">
      <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
        <select class="form-control" (change)="listado($event)" name="evento">
          <option value="undefined" disabled selected hidden>Seleccionar</option>
          <option value=false>Faltas</option>
          <option value=true>Asistencias</option>
        </select>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <div class="input-group">
          <div class="input-group-prepend">
            <div class="input-group-text">Desde</div>
            <input [ngModel]="dateFrom | date:'yyyy-MM-dd'" (ngModelChange)="dateFrom=$event" placeholder="Desde"
                   type="date">
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <div class="input-group">
          <div class="input-group-prepend">
            <div class="input-group-text">Hasta</div>
            <input [ngModel]="dateTo | date:'yyyy-MM-dd'" (ngModelChange)="dateTo=$event" placeholder="Hasta"
                   type="date">
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <select class="form-control" name="miselect" [(ngModel)]="asignaturaSelect">
          <option
            [value]="asignatura"
            *ngFor="let asignatura of arrayAsignaturas; let i = index">
            {{asignatura}}
          </option>
        </select>
      </div>

      <div class="col-lg-1 col-md-4 col-sm-6 col-xs-12">
        <div class="input-group">
          <div class="example-button-row">
            <button mat-raised-button (click)="export()">Exportar todo</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="table-responsive">
    <table class="table" *ngIf="asistenciasYfaltas.length > 0">
      <thead>
      <tr>
        <th scope="col">Estado</th>
        <th scope="col">Nombre</th>
        <th scope="col">Apellidos</th>
        <th scope="col">Fecha</th>
        <th scope="col">Aula</th>
        <th scope="col">Asignatura</th>
        <th *ngIf="!this.evento" scope="col">Justificante</th>
        <th scope="col">Acciones</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let asistencia of asistenciasYfaltas; let i = index">
        <ng-template
          [ngIf]="
              (asistencia.fecha.date | date:'yyyy-MM-dd') >= (dateFrom | date:'yyyy-MM-dd')
              &&
              (asistencia.fecha.date | date:'yyyy-MM-dd') <= (dateTo | date:'yyyy-MM-dd')
              &&
              ((asistencia.asignatura == [(asignaturaSelect)]) || ([(asignaturaSelect)] == 'all'))">

          <td scope="row">
            <label>
              <input name="foo" type="checkbox" [checked]="asistencia.estado" [value]="i"
                     (change)="change(i)">
            </label>
          </td>
          <td>{{asistencia.nombre | titlecase}}</td>
          <td>{{asistencia.apellidos | titlecase}}</td>
          <td>{{ asistencia.fecha.date | date: "dd-MM-yyyy"}}</td>

          <td>{{asistencia.clase}}</td>
          <td>{{asistencia.asignatura}}</td>
          <td *ngIf="asistencia.estado == false" style="text-align: center">
            <i *ngIf="asistencia.img" class="fa fa-file success" (click)="verjustificante(i)"></i>
            <i *ngIf="!asistencia.img && this.roll === 'ROLE_PROFESOR'" class="fa fa-plus-circle success"
               (click)="abrirInput(i)">
              <input id="file-input{{i}}" type="file" accept="application/pdf, image/*" name="{{i}}" style="display: none;"
                     (change)="fileChangeEvent($event, asistencia, i)"/>
            </i>

          <td>
            <ul class="list-inline" style="text-align: center">
              <li class="list-inline-item success p-0>">
                <i *ngIf="!asistencia.comentario" class="fa fa-pencil success"
                   [swal]="{ title: 'Comentario', input: 'textarea', 'inputPlaceholder': 'Escribe un comentario' }"
                   (confirm)="saveComentario($event, asistencia.id, i)">
                </i>
              </li>
              <li *ngIf="asistencia.comentario" class="list-inline-item success p-0">
                <i class="fa fa-comments primary"
                   [swal]="{ title: 'Comentario', input: 'textarea','inputValue': asistencia.comentario }"
                   (confirm)="saveComentario($event, asistencia.id, i)">
                </i>
              <li *ngIf="asistencia.comentario" class="list-inline-item success p-0">
                <i class="fa fa-remove danger"
                   (click)="deleteComentario(i)">
                </i>
              </li>
            </ul>
          </td>
        </ng-template>
      </tr>
      </tbody>
    </table>
  </div>
</div>
