import {AfterViewChecked, ChangeDetectorRef, Component, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {DatePipe, TitleCasePipe} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {AulasService} from '../services/aulas.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NgxSpinnerService} from 'ngx-spinner';
import {HttpService} from '../../../componentes/agenda/services/http.service';
import {AdminExamenesService} from '../../../maestro/admin-examenes/services/admin-examenes.service';
import Swal from 'sweetalert2';
import {MatDialog} from '@angular/material/dialog';
import {ObsModalComponent} from './obs-modal/obs-modal.component';

@Component({
  selector: 'app-claustro',
  templateUrl: './claustro.component.html',
  styleUrls: ['./claustro.component.scss']
})
export class ClaustroComponent implements OnInit, AfterViewChecked, OnDestroy {

  closeResult: string;
  date: any;
  trimestre = 'def';
  datosCurso: any[] = [];
  datosAlumnos: any[] = [];
  datosCentro: any[] = [];
  imprimir: any[] = [];
  id: any;
  activar = false;
  check: boolean;
  loader = false;
  curso: any[] = [];
  ok: boolean;
  editable: boolean;

  datosCursosSubscription = new Subscription();
  datosAlumnosSubscription = new Subscription();
  datosCentroSubscription = new Subscription();
  cursoSubscription = new Subscription();
  asignaturasSubscription = new Subscription();

  asignaturas: any;

  constructor(private titlecasePipe: TitleCasePipe, private dates: DatePipe, private _route: ActivatedRoute,
              private service: AulasService, private modalService: NgbModal, private cdr: ChangeDetectorRef,
              private spinner: NgxSpinnerService, private _http: HttpService, private _clases: AdminExamenesService,
  ) {
    this.id = this._route.snapshot.parent.params['id'];
    this._http.getCurso(this.id);
    this.service.getCursoBoletin(this.id);
    this.service.getCentro();
  }


  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  ngOnInit() {
    this.getDatosCursos();
    this.getCentro();
    this.getCurso();
    this.getAsignaturas();
    document.getElementById('boton1').hidden = true;
    document.getElementById('boton2').hidden = true;
  }

  ngOnDestroy() {
    this.datosAlumnosSubscription.unsubscribe();
    this.datosCursosSubscription.unsubscribe();
    this.datosCentroSubscription.unsubscribe();
    this.cursoSubscription.unsubscribe();
    this.asignaturasSubscription.unsubscribe();
  }

  getDatosCursos() {
    this.datosCursosSubscription = this.service.cursoBoletin$.subscribe(res => {
      this.datosCurso = res;
    });
  }

  getCurso() {
    this.cursoSubscription = this._http.curso$.subscribe(resp => {
      console.log(resp)
      this._http.getAsignaturas(resp[0], resp[1]);
      this.curso = [resp[2], resp[3], resp[4]];
    });
  }

  getCentro() {
    this.datosCentroSubscription = this.service.centro$.subscribe(res => {
      this.datosCentro = res;
    });
  }

  getAsignaturas() {
    this.asignaturasSubscription = this._http.asignaturas$.subscribe(respAsignaturas => {
      this.asignaturas = respAsignaturas;
      console.log(this.asignaturas);
    });
  }

  cargar(event, content) {
    this.spinner.show()
    this.datosAlumnos = [];
    this.check = false;
    const checkboxes: any = document.getElementsByName('foo');
    for (let i = 0, n = checkboxes.length; i < n; i++) {
      checkboxes[i].checked = this.check;
    }
    this.imprimir = [];

    console.log(this.trimestre);
    if (this.trimestre !== 'def') {
      this.service.getClaustro(this.id, this.trimestre).subscribe(data => {
        if (data['code'] === 200) {
          this.datosAlumnos = data['id'];
          for (let i = 0; i < this.datosAlumnos.length; i++) {
            for (let j = 0; j < this.datosAlumnos[i].notas.length; j++) {
              if (this.datosAlumnos[i].notas[j].aa === true) {
                this.datosAlumnos[i].notas[j].nota = 'AA';
              }
            }
          }
          console.log(this.datosAlumnos);
          event.srcElement.blur();
          event.preventDefault();
          this.spinner.hide();
        } else {
          console.log(data['id']);
          return false;
        }
      });
    }
  }

  toggle() {
    console.log('Toggle');
    this.check = !this.check;
    const checkboxes: any = document.getElementsByName('foo');
    for (let i = 0, n = checkboxes.length; i < n; i++) {
      checkboxes[i].checked = this.check;
    }
    if (this.check) {
      this.imprimir = this.datosAlumnos.slice(0);
    } else {
      this.imprimir = [];
    }

    document.getElementById('boton1').hidden = false;
    document.getElementById('boton2').hidden = false;
  }

  change(alumno, event) {
    console.log(event.target.checked);
    if (event.target.checked) {

      this.imprimir.push(alumno);
      console.log(this.imprimir);
    } else {

      this.imprimir.forEach((object, index) => {
        if (object.id === alumno.id) {
          this.imprimir.splice(index, 1);
        }
      });
      console.log(this.datosAlumnos);
      console.log(this.imprimir);
    }
  }

  editar(i: number, h: number) {
    console.log(i, h);
    if (!this.editable) {
      document.getElementById('a' + i + h).hidden = true;
      document.getElementById('b' + i + h).hidden = false;
    } else {
      document.getElementById('a' + i + h).hidden = false;
      document.getElementById('b' + i + h).hidden = true;
    }
    this.editable = !this.editable;
  }

  add(alumno: any, nota2: any, i: number, h: number) {
    if (!isNaN(nota2)) {
      const notas = parseInt(nota2, 10);
      const nota = {
        id: alumno.id,
        nota: notas,
        tipo: this.trimestre
      };
      console.log(nota);
      this._clases.updateNotasFinal(nota);
      document.getElementById('a' + i + h).hidden = false;
      document.getElementById('b' + i + h).hidden = true;
    } else {
      Swal.fire('', 'La nota es Incorrecta', 'warning');
    }
  }

  observacion(alumno) {
    console.log(alumno.id);
    const modalRef =  this.modalService.open(ObsModalComponent, { size: 'lg' , centered: true, scrollable: true});
    modalRef.componentInstance.id = alumno.id;
  }
}
