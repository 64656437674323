import {
  Component, Input, ViewChild, OnDestroy, OnInit, DoCheck, ViewEncapsulation, ElementRef
} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {ClasesMService} from '../services/clasesM.service';
import {Subscriber} from 'rxjs/Subscriber';


@Component({
  selector: 'app-info-asignaturaM',
  templateUrl: './info-asignaturaM.component.html',
  styleUrls: ['../clasesM.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InfoAsignaturaMComponent implements OnInit {
  navLinks = [{}];

  constructor (private _route: ActivatedRoute, private _clases: ClasesMService) {
    this.navLinks = [
      {path: 'lista-alumnos/', label: 'Asistencias'},
      {path: 'examen', label: 'Crear Parcial/Ejercicio evaluable'},
      {path: 'lista-examenes', label: 'Listar Parciales'},
    ];
  }

  ngOnInit () {
  }


}
