import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {QuillModule} from 'ngx-quill';
import {InboxComponent} from './inbox.component';
import {InboxService} from './inbox.service';
import {FormsModule} from '@angular/forms';
import {PipesModule} from '../../../pipes.module';
import { SafeHtml2Pipe } from './safe-html2.pipe';
import { SafeUrl2Pipe } from './safe-url2.pipe';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    QuillModule,
    FormsModule,
    PipesModule
  ],
  exports: [InboxComponent],
  declarations: [
    InboxComponent,
    SafeHtml2Pipe,
    SafeUrl2Pipe,

  ],
  providers: [InboxService]
})
export class InboxModule {
}
