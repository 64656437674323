<!--Login Page Starts-->
<section id="login">
  <div class="container-fluid">
    <div class="row full-height-vh">
      <div class="col-12 d-flex align-items-center justify-content-center">
        <div class="card card-inverse  text-center width-400 fondo">
          <div class="card-img overlap">
            <img id="logo" alt="logo" class="mb-1" src="assets/img/centro/logo.png" width="80">
          </div>
          <div class="card-body">
            <div class="card-block">
              <h2>Iniciar Sesión</h2>
              <form novalidate="" (ngSubmit)="onSubmit()" #f="ngForm">
                <div class="form-group">
                  <input type="email" class="form-control" name="username" id="inputEmail" placeholder="Email" ngModel
                         required email>
                </div>
                <div class="form-group">
                  <div class="input-group">
                    <input [type]="fieldTextType ? 'text' : 'password'" class="form-control" name="password"
                           id="inputPass" placeholder="Password" ngModel required>
                    <div class="input-group-append">
                         <span class="input-group-text">
                           <i class="fa" [ngClass]="{'fa-eye-slash': !fieldTextType,'fa-eye': fieldTextType}"
                              (click)="toggleFieldTextType()"></i>
                         </span>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <button type="submit" class="btn btn-pink btn-block btn-raised">Entrar</button>
                </div>
              </form>
            </div>
          </div>
          <div class="card-footer futer">
            <div class="float-left"><a (click)="onForgotPassword()"><i class="fa fa-unlock"></i> Recuperar
              Contraseña</a></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Login Page Ends-->
