 import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Router } from '@angular/router';
import { Rutas } from '../../../../utiles/Rutas';
import { LoginService } from '../../../pages/services/login.service';



@Injectable()
export class CursosService {
  private moduloEducativoSource = new Subject<any>();
  public moduloEducativo$ = this.moduloEducativoSource.asObservable();

  private moduloSource = new Subject<any>();
  public modulo$ = this.moduloSource.asObservable();

  private modalidadSource = new Subject<any>();
  public modalidad$ = this.modalidadSource.asObservable();

  private familiaSource = new Subject<any>();
  public familia$ = this.familiaSource.asObservable();

  private cicloEducativoSource = new Subject<any>();
  public cicloEducativo$ = this.cicloEducativoSource.asObservable();

  private cursosSource = new Subject<any>();
  public curso$ = this.cursosSource.asObservable();

  private asignaturaSource = new Subject<any>();
  public asignatura$ = this.asignaturaSource.asObservable();

 constructor(private http: HttpClient, private router: Router, private _login: LoginService) {}

   add(curso: any) {
    curso.id = this._login.getIdentity().centro.id;
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = { curso };
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    console.log(params);
    this.http.post(Rutas + 'add/curso', params, { headers: headers }).subscribe(datas => {
      if (datas['code'] === 200) {
          this.cursosSource.next(datas['id']);
     }
    }, error => {
      console.log(error);
    });
  }

  getCiclosFormativos() {
    this.http.get(Rutas + 'get/ciclosformativos?token=' + localStorage.getItem('token')).subscribe(data => {
      if (data['code'] === 200) {
        this.moduloEducativoSource.next(data['id']);
      } else {
        return false;
      }
    });
  }




  getModulosEducativos() {
    this.http.get(Rutas + 'modulo_educativo?token=' + localStorage.getItem('token')).subscribe(data => {
      if (data['code'] === 200) {
         this.moduloEducativoSource.next(data['id']);
        } else {
        return false;
      }
    });
  }
  updateAsignaturas(asignatura: any) {

  const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
  const json = { asignatura };
  const data = JSON.stringify(json);
  const params = 'json=' + data + '&token=' + localStorage.getItem('token');
  this.http.post(Rutas + 'update/asignatura', params, { headers: headers }).subscribe(datas => {
    if (datas['code'] === 200) {
      this.asignaturaSource.next(datas['id']);
    }
  }, error => {
    console.log(error);
  });
}


  getAsignaturas(id: number) {
    this.http.get(Rutas + 'get/asignaturas?id=' + id + '&token=' + localStorage.getItem('token'))
    .subscribe(data => {
        if (data['code'] === 200) {
          this.asignaturaSource.next(data['id']);
          } else {
          return false;
        }
      });
  }

  getModulos(id: number) {
     this.http.get(Rutas + 'modulos?id=' + id + '&token=' + localStorage.getItem('token'))
    .subscribe(data => {
        if (data['code'] === 200) {
          this.moduloSource.next(data['id']);
          } else {
          return false;
        }
      });
  }


  getModalidad(id: number) {
    this.http.get(Rutas + 'modalidad?id=' + id + '&token=' + localStorage.getItem('token'))
    .subscribe(data => {
        if (data['code'] === 200) {
          this.modalidadSource.next(data['id']);
          } else {
          return false;
        }
      });
  }

  getFamilias(id: number) {
    this.http.get(Rutas + 'familias?id=' + id + '&token=' + localStorage.getItem('token'))
    .subscribe(data => {
        if (data['code'] === 200) {
          this.familiaSource.next(data['id']);
          } else {
          return false;
        }
      });
  }

   getCiclosEducativos(id: number) {
    this.http.get(Rutas + 'ciclos?id=' + id + '&token=' + localStorage.getItem('token'))
    .subscribe(data => {
        if (data['code'] === 200) {
          this.cicloEducativoSource.next(data['id']);
          } else {
          return false;
        }
      });
  }

  getModulosBasicos(id: number) {
    this.http.get(Rutas + 'modulos_basicos?id=' + id + '&token=' + localStorage.getItem('token'))
    .subscribe(data => {
        if (data['code'] === 200) {
          this.moduloSource.next(data['id']);
          } else {
          return false;
        }
      });
  }

  getCursos() {
    const id = this._login.getIdentity().centro.id;
    this.http.get(Rutas + 'cursos/centro?id=' + id + '&token=' + localStorage.getItem('token'))
    .subscribe(data => {
        if (data['code'] === 200) {
          this.cursosSource.next(data['id']);
          } else {
          return false;
        }
      });
  }

  deleteCursos(id: number) {
    this.http.get(Rutas + 'delete/curso/centro?id=' + id + '&token=' + localStorage.getItem('token'))
    .subscribe(data => {
      if (data['code'] === 200) {
        this.cursosSource.next(data['id']);
        } else {
        return false;
      }
      });
  }



}
