export interface Dato {
  id_provincia?: Number;
  provincia: String;
  nombre: String;
  apellidos: String;
  direccion: String;
  nacimiento: Date;
  codPostal: String;
  dni: String;
  municipio: String;
  iban: any[];
  id: Number;
  id_municipio: Number;
  img: String;
  tutores: Array<any>;
  contactos: Array<any>;
  titulos: Array<any>;
  observaciones: String;
  sip: string;
  observacionesMatricula: string;

}


export class Datos {
  id_provincia?: Number;
  provincia: String;
  nombre: String;
  apellidos: String;
  direccion: String;
  nacimiento: Date;
  dni: String;
  municipio: String;
  iban: Array<any>;
  id: Number;
  id_municipio: Number;
  codPostal: String;
  img: String;
  tutores: Array<any>;
  contactos: Array<any>;
  titulos: Array<any>;
  permisoImagen: boolean;
  permisoNotas: boolean;
  provinciaNacimiento: String;
  Nacionalidad: String;
  ciudadNacimiento: String;
  paisEstudiosPrevios: String;
  observaciones: String;
  curso: any = {
    nivel: '',
    id: null
  };
  sip: string;
  observacionesMatricula: string;


  constructor( id_provincia: number, provincia: String, nombre: String, apellidos: String,
                direccion: String, nacimiento: Date, dni: String,
                municipio: String, iban: Array<any>, id: Number, id_municipio: Number, img: String,
                tutores: Array<any>, contactos: Array<any>, titulos: Array<any>, permisoImagen: boolean, permisoNotas: boolean,
                provinciaNacimiento: String, Nacionalidad: String, ciudadNacimiento: String, paisEstudiosPrevios: String, curso: any,
               codPostal: String, observaciones: String, sip: string,  observacionesMatricula: string,
) {

    this.id_provincia = id_provincia;
    this.provincia = provincia;
    this.nombre = nombre;
    this.apellidos = apellidos;
    this.direccion = direccion;
    this.nacimiento = nacimiento;
    this.dni = dni;
    this.municipio = municipio;
    this.iban = iban;
    this.codPostal = codPostal;
    this.observaciones = observaciones;
    this.id = id;
    this.id_municipio = id_municipio;
    this.img = img;
    this.tutores = tutores;
    this.contactos = contactos;
    this.titulos = titulos;
    this.permisoImagen = permisoImagen;
    this.permisoNotas = permisoNotas;
    this.provinciaNacimiento = provinciaNacimiento;
    this.Nacionalidad = Nacionalidad;
    this.ciudadNacimiento = ciudadNacimiento;
    this.paisEstudiosPrevios = paisEstudiosPrevios;
    this.curso = curso;
    this.sip = sip;
    this.observacionesMatricula = observacionesMatricula;

  }


}



