<h4 class="info-top"><i class="ft-user"></i> Crear {{type | titlecase}}</h4>

  <div class="col-md-10" id="content">
    <div class="card">
      <div class="card-body">
        <div class="px-3">
          <form class="form form-horizontal striped-rows form-bordered" novalidate="" (ngSubmit)="onSubmit()"
                #f="ngForm" autocomplete="off">
            <input type="hidden" name="id" [(ngModel)]="dato.id">
            <div class="form-body">
              <div class="form-group row">
                <label class="col-md-3 label-control">Provincias</label>
                <div class="col-md-5">
                  <select [(ngModel)]='dato.id_provincia' name="provincia"
                          class="form-control"
                          (change)="getMunicipios()">
                    <ng-container *ngIf="provincias.length > 0 ">
                      <option selected *ngFor="let provincia of provincias"
                              [ngValue]="provincia.id">{{provincia.provincia}}
                      </option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-3 label-control">Municipio</label>
                <div class="col-md-5">
                  <select [(ngModel)]='dato.id_municipio'
                          name="id_municipio"
                          required
                          class="form-control">
                    <ng-container *ngIf="municipios">
                      <option *ngFor="let municipio of municipios"
                              [ngValue]="municipio.id"> {{ municipio.municipio }}
                      </option>
                    </ng-container>
                    <ng-container *ngIf="dato.municipio &&  !active">
                      <option [ngValue]="dato.id_municipio">{{dato.municipio}}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-3 label-control">Nombre</label>
                <div class="col-md-5">
                  <input [(ngModel)]='dato.nombre'
                         required
                         type="text"
                         class="form-control width-input"
                         name="nombre" placeholder="Nombre">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-3 label-control">Apellidos</label>
                <div class="col-md-5">
                  <input [(ngModel)]='dato.apellidos'
                         required
                         type="text"
                         class="form-control"
                         name="apellidos"
                         placeholder="Apellidos">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-3 label-control">Direccion</label>
                <div class="col-md-5">
                  <input [(ngModel)]='dato.direccion'
                         required
                         type="text"
                         class="form-control"
                         name="direccion"
                         placeholder="Direccion">
                </div>
              </div>
              <div class="form-group row">
              <label class="col-md-3 label-control">Código postal</label>
              <div class="col-md-5">
                <input [(ngModel)]='dato.codPostal'
                       required
                       type="text"
                       class="form-control"
                       name="codPostal"
                       placeholder="CP">
              </div>
            </div>
              <div class="form-group row" *ngIf="type === 'alumnos'">
                <label class="col-md-3 label-control">Nacionalidad</label>
                <div class="col-md-5">
                  <input [(ngModel)]='dato.Nacionalidad'
                         required
                         type="text"
                         class="form-control width-input"
                         name="nacionalidad" placeholder="Nacionalidad">
                </div>
              </div>
              <div class="form-group row" *ngIf="type === 'alumnos'">
                <label class="col-md-3 label-control">Provincia nacimiento</label>
                <div class="col-md-5">
                  <input [(ngModel)]='dato.provinciaNacimiento'
                         required
                         type="text"
                         class="form-control width-input"
                         name="provinciaNacimiento" placeholder="Provincia de nacimiento">
                </div>
              </div>
              <div class="form-group row" *ngIf="type === 'alumnos'">
                <label class="col-md-3 label-control">Ciudad nacimiento</label>
                <div class="col-md-5">
                  <input [(ngModel)]='dato.ciudadNacimiento'
                         required
                         type="text"
                         class="form-control width-input"
                         name="ciudadNacimiento" placeholder="Ciudad de nacimiento">
                </div>
              </div>
              <div class="form-group row" *ngIf="type === 'alumnos'">
                <label class="col-md-3 label-control">País de estudios previos</label>
                <div class="col-md-5">
                  <input [(ngModel)]='dato.paisEstudiosPrevios'
                         required
                         type="text"
                         class="form-control width-input"
                         name="paisEstudios" placeholder="País de estudios previos">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-3 label-control">Fecha de Nacimiento</label>
                <div class="col-md-5">
                  <input [(ngModel)]='dato.nacimiento'
                         required
                         type="date"
                         class="form-control"
                         name="nacimiento">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-3 label-control">Dni</label>
                <div class="col-md-5">
                  <input [(ngModel)]='dato.dni'
                         type="text"
                         class="form-control"
                         name="dni"
                         placeholder="Dni"
                         pattern="(([X-Z]{1})([-]?)(\d{7})([-]?)([A-Z]{1}))|((\d{8})([-]?)([A-Z]|[^A-Z]{1}))">
                </div>
              </div>
              <div class="form-group row" *ngIf="type === 'alumnos'">
                <label class="col-md-3 label-control">Modulo</label>
                <div class="col-md-5">
                  <select class="form-control"
                          [(ngModel)]='dato.curso.id'
                          name="curso"
                          required>
                    <ng-container *ngFor="let curso of cursos">
                      <option *ngIf="dato.curso.id == curso.id" [value]="curso.id"
                              selected> {{curso.nivel}} - {{curso.acronimo}}</option>
                      <option *ngIf="dato.curso.id != curso.id"
                              [value]="curso.id"> {{curso.nivel}} -  {{curso.acronimo}}</option>
                    </ng-container>>
                  </select>
                </div>
              </div>
              <div class=" row" *ngIf="type === 'alumnos'">
                <label class="col-md-3 label-control" style="text-align: end">Autoriza</label>
                <div class="col-md-5 ">
                  <div class="form-group form-check">
                    <input type="checkbox" class="form-check-input " id="check1" [(ngModel)]="dato.permisoImagen" name="permisoImagen">
                    <label class="form-check-label" for="check1">Uso de imágen</label>
                  </div>

                  <div class="form-group form-check">
                    <input type="checkbox" class="form-check-input " id="check2" [(ngModel)]="dato.permisoNotas" name="permisoNotas">
                    <label class="form-check-label" for="check2">Envio de notas</label>
                  </div>

                </div>


              </div>

            </div>
            <div class="form-actions">
              <button type="submit" class="btn btn-raised btn-primary" style="float: right">
                <i class="ft-save"></i> Guardar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
