<i [id]="id"></i>
<h3 class="titulo-cabecera" *ngIf="curso.length != 0">{{curso[1]}}º {{curso[2]}} {{curso[0]}}</h3>

<table class="table table-bordered">
  <thead>
  <tr>
    <th>Color</th>
    <th>Profesor</th>
    <th>Asignatura</th>
    <th>Día</th>
    <th>Hora Inicio</th>
    <th>Hora Fin</th>
    <th>Añadir</th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td class="tdColorsAdd">
      <div class="row">
        <div class="col-6 p-1" *ngFor="let color of colorList" [ngClass]="backgroundColorAdd(color.name)"
             (click)="selectColor(color)"></div>
      </div>
    <td>
      <select [(ngModel)]="idProfesorAdd"
              class="form-control"
              name="profesor"
              required>
        <ng-container *ngFor="let profesor of profesores">
          <option [value]="profesor.id">{{profesor.nombre}} {{profesor.apellidos}}</option>
        </ng-container>
      </select>
    </td>
    <td>
      <select [(ngModel)]="idAsignaturaAdd"
              class="form-control"
              name="asignatura"
              required>
        <ng-container *ngFor="let asignatura of asignaturas">
          <option [value]="asignatura.id">{{asignatura.asignatura}}</option>
        </ng-container>
      </select>
    </td>
    <td>
      <select [(ngModel)]="diaSemanaSelectedAdd"
              class="form-control"
              name="dia_semana"
              required>
        <ng-container *ngFor="let diaSemana of diaSemanaAdd">
          <option [value]="diaSemana.fromEndWeek">{{diaSemana.diaSemana}}</option>
        </ng-container>
      </select>
    </td>
    <td>
      <div class="form-group">
        <div class="input-group">
          <ngb-timepicker [(ngModel)]="startAdd" name="h_start"></ngb-timepicker>
        </div>
      </div>
    </td>
    <td>
      <div class="form-group">
        <div class="input-group">
          <ngb-timepicker [(ngModel)]="endAdd" name="h_end"></ngb-timepicker>
        </div>
      </div>
    </td>
    <td class="tdBtnAdd">
      <a (click)="addAsignatura()">
        <i class="ft-plus font-large-1 success"></i>
      </a>
    </td>
  </tr>
  </tbody>
</table>
<mwl-calendar-week-view
  [viewDate]="viewDate"
  [events]="asignaturasMostrar"
  [weekStartsOn]="1"
  [excludeDays]="[6, 0]"
  [dayStartHour]="9"
  [dayEndHour]="21"
  [refresh]="refresh"
  (eventTimesChanged)="eventTimesChanged($event)">
</mwl-calendar-week-view>
