<div class="row">
  <h4 class="info-top"><i class="ft-user"></i> Tutores: {{dato?.nombre}} {{dato?.apellidos}} </h4>  <!-- TABLA TUTORES -->
  <div class="col-12" *ngIf='existe'>
    <table class="table table-striped">
      <thead>
      <tr>
        <th scope="col">Nombre</th>
        <th scope="col">Apellidos</th>
        <th scope="col">Email</th>
        <th scope="col">Telefono</th>
        <th scope="col" class="info align-content-center">
          <i class="ft-plus font-medium-3 mr-2" (click)="existe = !existe; active=!active"></i>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let tutor of tutores; let i = index">
        <td>{{tutor.nombre}}</td>
        <td>{{tutor.apellidos}}</td>
        <td *ngIf="tutor.dni.includes('@')">{{tutor.dni}}</td>
        <td *ngIf="!tutor.dni.includes('@')"> - </td>
        <td>{{tutor.dato }}</td>
        <td>
          <ul class="list-inline">
            <li class="list-inline-item danger p-0" (click)="removeUser(tutor.id,i)">
              <i class="ft-x font-medium-3 mr-2"></i>
            </li>
            <li class="success p-0 list-inline-item" [routerLink]="['/admin/padre/' , tutor.id]">
              <i class="ft-user font-medium-3 mr-2"></i>
            </li>
          </ul>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <!--  END AÑADIR PADRES -->
  <div class="col-12" *ngIf="!existe">
    <form class="form form-horizontal striped-rows form-bordered" novalidate="" (ngSubmit)="onSubmit()" #p="ngForm" autocomplete="off">
      <input type="hidden" name="id" [(ngModel)]="tutor.id">
      <div class="form-body">
        <div class="form-group row">
          <label class="col-md-3 label-control">Nombre</label>
          <div class="col-md-5">
            <input [(ngModel)]='tutor.nombre' required type="text" class="form-control width-input" name="nombre"
                   placeholder="Nombre">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-3 label-control">Apellidos</label>
          <div class="col-md-5">
            <input [(ngModel)]='tutor.apellidos' required type="text" class="form-control width-input" name="apellidos"
                   placeholder="Apellidos">
          </div>
        </div>
        <!-- No todos los padres proporcionan el DNI, pero es más probale que sí den el email. En caso de que no se introduzca nada,
         como el campo es unique y not null, se generará un texto a partir de la fecha actual.-->
        <div class="form-group row">
          <label class="col-md-3 label-control">Email</label>
          <div class="col-md-5">
            <input [(ngModel)]='tutor.dni' type="text" class="form-control width-input" name="dni" placeholder="Email">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-3 label-control">Teléfono</label>
          <div class="col-md-5">
            <input [(ngModel)]='telefono' type="text" class="form-control width-input" name="telf" placeholder="Telefono">
          </div>
        </div>
        <div class="form-actions">
          <button type="submit" class="btn btn-raised btn-primary mr-2">Crear</button>

          <button type="button" class="btn btn-raised btn-danger" (click)="existe = !existe; active=!active">Cancelar
          </button>
          <button type="button" class="btn btn-raised btn-primary ml-2" (click)="existe = !existe; active=!active; showSearchModal()">Añadir ya esxistente</button>

        </div>
      </div>
    </form>
  </div>
</div>
