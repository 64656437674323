<section id="simple">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">Asistencias al aula de {{ aula }} asignatura de {{ clase.asignatura }}</h5>
        </div>
        <table class="table table-striped">
          <thead *ngIf="!aviso; else avi">
          <tr >
            <th scope="col">Estado</th>
            <th scope="col">Nombre</th>
            <th scope="col">Apellidos</th>
            <th scope="col">Fecha</th>
            <th scope="col">Accion</th>
          </tr>
          </thead>
          <ng-template #avi>  <h1 *ngIf="aviso">{{aviso}}</h1></ng-template>
          <tbody>
          <tr *ngFor="let alumno of alumnos; let i = index">
            <td scope="row"><input name="foo" type="checkbox" [checked]="alumno.estado" [value]="i" (change)="change(i)"> </td>
            <td>{{alumno.nombre}}</td>
            <td>{{alumno.apellidos}}</td>
            <td *ngIf="!alumno.fecha; else fecha"> {{ now_date |  date: "dd-MM-yy"}} </td>
            <td> <ul class="list-inline">
              <li class="list-inline-item success p-0>">
                <i *ngIf="!alumno.comentario" class="fa fa-pencil success"
                   [swal]="{ title: 'Comentario', input: 'textarea', 'inputPlaceholder': 'Escribe un comentario' }"
                   (confirm)="saveComentario($event, alumno.id, i)">
                </i>

              </li>
              <li *ngIf="alumno.comentario" class="list-inline-item success p-0">
                <i class="fa fa-comments primary"
                   [swal]="{ title: 'Comentario', input: 'textarea','inputValue': alumno.comentario }"
                   (confirm)="saveComentario($event, alumno.id, i)">

                </i>
              <li *ngIf="alumno.comentario" class="list-inline-item success p-0">
                <i class="fa fa-remove danger"
                   (click)="deleteComentario(i)">
                </i>
              </li>
            </ul></td>
            <ng-template #fecha><td>{{ alumno.fecha.date | date: "dd-MM-yy"}}</td></ng-template>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>


