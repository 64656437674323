
<div class="container">

  <div class="row ficha_alumno" *ngIf="padres">

    <div class="col-6" *ngFor="let padre of padres" >
      <div class="row">
        <div class="col-5">
          <img  [src]="padre.img | images: ''" class="img-thumbnail" width="200px"/>
        </div>
        <div class="col-7">
          <h6>{{padre.nombre}} {{padre.apellidos}}</h6>

          <ul>
            <li>Email: {{padre.email}}</li>
            <li *ngFor="let contacto of padre.contactos;">{{contacto.tipo}} : {{contacto.dato}}</li>
          </ul>
        </div>
      </div>


    </div>

  </div>
</div>




