<h4 class="info-top"><i class="ft-user"></i> Documentación Info: {{dato?.nombre}} {{dato?.apellidos}}</h4>
<div class="row">
  <div class="col-lg-5" style="margin-bottom: 1rem">
    <div class="input-group">
      <div class="input-group-prepend">
        <div class="input-group-text">Tipo Documento</div>
        <select class="form-control" (change)="agregar(tipo)" [(ngModel)]="tipo">
          <option label="-- Seleccionar --"></option>
          <option [ngValue]="tipo" *ngFor="let tipo of tipos">{{tipo.tipo}}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="col-lg-2">
    <button class="btn" (click)="addtipo()"><i class=" fa fa-plus mr-1"></i>Añadir tipo</button>
  </div>

  <div class="table-responsive">
    <table class="table">
      <thead>
      <tr class="d-flex">
        <th class="col-3" scope="col">Tipo Documento</th>
        <th class="col-3" scope="col">Entregado?</th>
        <th class="col-3" scope="col">Subir Documento</th>
        <th class="col-3" scope="col">Acciones</th>
      </tr>
      </thead>
      <tbody>
      <tr class="d-flex" *ngFor="let doc of documentos; let i = index;">
        <td class="col-3">{{doc.tipo.tipo | uppercase}}</td>
        <td class="col-3">
          <mat-checkbox (change)="entregado(i)" [(ngModel)]="doc.entregado"></mat-checkbox>
        </td>
        <td id="a{{i}}" class="col-3" *ngIf="doc.url">
          <i class="fa fa-eye fa-lg" (click)="ver(doc.url)"></i>
        </td>
        <td id="b{{i}}" class="col-3" *ngIf="!doc.url">
          <i class="fa fa-plus-circle fa-lg success" (click)="abrirInput(i)">
            <input id="input-file{{i}}" type="file" accept="application/pdf, image/*" name="{{i}}" style="display: none;"
                   (change)="file($event, i)"/>
          </i>
        <td>
        <td class="col-3">
          <li class="text-warning p-0 list-inline-item">
            <i class="ft-trash-2 font-medium-3 mr-2" (click)="delete(doc.id, i)"></i>
          </li>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

</div>
