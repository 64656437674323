<section  >
  <mat-card style="width: 100%">
    <mat-card-header class="header">
      <mat-card-title  class="pl-5 titles">Tablón de anuncios


      </mat-card-title>

    </mat-card-header>
    <mat-divider></mat-divider>
<mat-card-content>

  <div class="row">
    <div class="col-xl-4  col-lg-4  col-xxl-4 mt-3" *ngFor="let mensaje of mensajes">
      <mat-card class="example-card" >
        <mat-card-header>
          <div mat-card-avatar style="background-image: url('assets/img/centro/logo.png');
  background-size: cover;"></div>
          <mat-card-title>{{mensaje.titulo}}</mat-card-title>
          <mat-card-subtitle>{{mensaje.fecha}}</mat-card-subtitle>
        </mat-card-header>
        <img mat-card-image src="{{rutaImg + mensaje.imgCabecera}}" style="background-color: #dedddd">
        <mat-card-content  >
          <div class="text-oneline" style="height: 50px" [innerHTML]="mensaje.mensaje">
          </div>
          <button type="button" class="btn btn-link" style="color: black;" (click)="openModalDetalle(mensaje)">..ver más</button>

        </mat-card-content>
        <mat-card-actions>
          <button mat-icon-button class="ml-3" (click)="openFile(mensaje.adjunto)"  *ngIf="mensaje.adjunto != null">
                        <i class="icon-docs" style="font-size: large; font-weight: bold; "></i>
                     </button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</mat-card-content>

</mat-card>
</section>
