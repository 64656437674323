import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../pages/services/login.service';
import { UploadService } from '../../../services/upload.service';
import { RutasImg } from '../../../../utiles/Rutas';
declare var $: any;
@Component({
  selector: 'app-customizer',
  templateUrl: './customizer.component.html',
  styleUrls: ['./customizer.component.scss']
})
export class CustomizerComponent implements OnInit {
  public id: string;
  public token: any;
  public filesToUpload: Array<File>;
  constructor(private _login: LoginService, private _upload: UploadService) {
    if (this._login.getIdentity()) {
      this.id = this._login.getIdentity().centro.id;
      this.token = this._login.getToken();
    }

  }
  ngOnInit() {
    // Customizer JS File
    if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
      $.getScript('./assets/js/customizer.js');
    } else {
      $.getScript('./assets/js/customizer.js');
    }
  }

  addLogo() {
    const id = this._login.getIdentity().centro.id;
    this._upload.makeFileRequest(id, this.filesToUpload, 'imagen', 'logo')
      .then((result: any) => {
        $('.logo-img img').attr({ 'src': RutasImg + 'images/centro/' + result.id, 'width': 30 });
      }, error => {
        console.log(error);
      });
  }

  addHeader() {
    const id = this._login.getIdentity().centro.id;
    this._upload.makeFileRequest(id, this.filesToUpload, 'imagen', 'header')
      .then((result: any) => {
        $('.card-img-top')
        .css('background-image', 'url(' + RutasImg + 'images/centro/' + result.id + ')');
      }, error => {
        console.log(error);
      });
  }

  addSidebar() {
    const id = this._login.getIdentity().centro.id;
    this._upload.makeFileRequest(id, this.filesToUpload, 'imagen', 'sidebar')
      .then((result: any) => {
        $('.sidebar-background').css('background-image', 'url(' + RutasImg + 'images/centro/' + result.id + ')');
      }, error => {
        console.log(error);
      });
  }

  fileChangeEventLogo(fileinput: any) {
    this.filesToUpload = <Array<File>>fileinput.target.files;
    this.addLogo();
  }
  fileChangeEventHeader(fileinput: any) {
    this.filesToUpload = <Array<File>>fileinput.target.files;
    this.addHeader();
  }

  fileChangeEventSidebar(fileinput: any) {
    this.filesToUpload = <Array<File>>fileinput.target.files;
    this.addSidebar();
  }

}
