import {Component} from '@angular/core';
import {AgendaComponent} from '../../componentes/agenda/agenda-aula/agenda.component';

@Component({
  selector: 'app-horarios-alumno',
  templateUrl: './horarios-alumno.component.html',
  providers: [AgendaComponent]
})
export class HorariosAlumnoComponent  {}

