import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-users',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  encapsulation: ViewEncapsulation.None
})


export class ProfileComponent {

  navLinks = [{}];

  constructor () {
    this.navLinks = [
      {path: 'editar', label: 'Editar'},
      {path: 'contacto', label: 'Contacto'},
      {path: 'password', label: 'Passwords'},
    ];
    }
}
