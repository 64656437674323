import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TablonService} from '../tablon.service';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {RutasImg} from '../../../../utiles/Rutas';

@Component({
  selector: 'app-tablon-modal',
  templateUrl: './tablon-modal.component.html',
  styleUrls: ['./tablon-modal.component.scss']
})
export class TablonModalComponent implements OnInit {

  close = false;
  mensajeForm: FormGroup;
  filesToUpload: Array<File> = [];
  imagenCabecera: File;
  destino = {
    todos: false,
    alumnos: false,
    padres: false,
    profesores: false,
    aulas: false,
  }
  rutaIMG = RutasImg;
  showAulas =  false;
  cursos: any ;
  cursosFiltered: any = [] ;
  cursosDest: any  = [];
  actual: number;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Escribe aqui el mensaje del tablón de anuncios...',
    translate: 'no',
    sanitize: true,


    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [

    ],

  };
 imageSrc: string;

  constructor(public dialoRef: MatDialogRef<TablonModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, private tablonService: TablonService) { }

  ngOnInit(): void {
    this.getActual();
    this.getCursos();
    this.initForm();


  }

  getCursos() {
    this.tablonService.getCursosTablon().subscribe( (res) => {
      console.log(res);
      this.cursos = res['id'];
      this.filterArray();
    });
  }
  initForm() {
    this.mensajeForm = new FormGroup({
      'titulo' : new FormControl('', Validators.required),
      'mensaje': new FormControl('', Validators.required),
      'curso': new FormControl(''),
      'cursoDestinatarios': new FormControl(''),
      'todos': new FormControl(this.destino.todos),
      'alumnos': new FormControl(this.destino.alumnos),
      'padres': new FormControl(this.destino.padres),
      'profesores': new FormControl(this.destino.profesores),
      'aulas': new FormControl(this.destino.aulas),
      'id': new FormControl(null),
    });
    if ( this.data ) {
      this.mensajeForm.patchValue(this.data);
    }
  }
  print() {
    this.showAulas = false;
    if ( this.mensajeForm.controls['todos'].value === true) {
      this.mensajeForm.controls['alumnos'].setValue(false);
      this.mensajeForm.controls['padres'].setValue(false);
      this.mensajeForm.controls['profesores'].setValue(false);
      this.mensajeForm.controls['aulas'].setValue(false);
    }
    if ( this.mensajeForm.controls['alumnos'].value === true && this.mensajeForm.controls['padres'].value === true
      && this.mensajeForm.controls['profesores'].value === true) {
      this.mensajeForm.controls['alumnos'].setValue(false);
      this.mensajeForm.controls['padres'].setValue(false);
      this.mensajeForm.controls['profesores'].setValue(false);
      this.mensajeForm.controls['todos'].setValue(true);
    }if ( this.mensajeForm.controls['aulas'].value === true){
      this.mensajeForm.controls['alumnos'].setValue(false);
      this.mensajeForm.controls['padres'].setValue(false);
      this.mensajeForm.controls['profesores'].setValue(false);
      this.mensajeForm.controls['todos'].setValue(false);
      this.showAulas = true;
    }
    console.log(this.mensajeForm.value);
  }

  file(event) {
    this.filesToUpload = <Array<File>>event.target.files;
  }


  addCurso() {
    this.cursosDest.push({
      id: this.mensajeForm.controls['curso'].value.id,
      aula: this.mensajeForm.controls['curso'].value.aula,
      destinatarios: this.mensajeForm.controls['cursoDestinatarios'].value
    });
    console.log(this.cursosDest);
  }

   send() {
    console.log(this.mensajeForm.value);
    const formData = new FormData();
    const json = {
      destino: {todos :  this.mensajeForm.controls['todos'].value,
        alumnos: this.mensajeForm.controls['alumnos'].value,
        padres: this.mensajeForm.controls['padres'].value,
        profesores: this.mensajeForm.controls['profesores'].value,
        aulas: this.mensajeForm.controls['aulas'].value,
      },
      id: this.mensajeForm.controls['id'].value,
      cursos: this.cursosDest,
      titulo: this.mensajeForm.controls['titulo'].value,
      mensaje: this.mensajeForm.controls['mensaje'].value,
    };
    console.log(json);
    formData.append('json', JSON.stringify(json));

    formData.append('files', this.filesToUpload[0]);
    formData.append('imgCabecera', this.imagenCabecera);

    formData.append('token', localStorage.getItem('token'));
    console.log(formData);
    this.tablonService.addMensaje(formData).subscribe( (res) => {
      console.log(res);
      this.close = true;
    }, error => {
      alert('Ha ocurrido un error, intentelo mas tarde');
      this.close = false;
    })
    this.mensajeForm.reset();
    this.cursosDest = [];
    this.showAulas = false;
    this.dialoRef.close(this.close);
  }

  filterArray() {
    for ( const c of this.cursos) {
      if (c.finalizada === false) {
        this.cursosFiltered.push(c);
      }
    }
  }

  getActual() {
    const today = new Date();
    if (today.getMonth() >= 9) {
      this.actual = today.getFullYear();
    }this.actual = today.getFullYear() - 1;
    console.log(this.actual);
    console.log(today);
    console.log(today.getMonth());
  }


  selectFiles($event) {
    console.log($event);
    this.imagenCabecera = <File>$event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(this.imagenCabecera);
    reader.onload = () => {
      this.imageSrc = reader.result as string;
    };
  }
}
