import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {AdminAlumnoService} from '../services/admin-alumno.service';
import {UploadService} from '../../../services/upload.service';


@Component({
  selector: 'app-alumnos-listado',
  templateUrl: './alumno-listado.component.html',
  styleUrls: ['../alumno-asignaturas.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [AdminAlumnoService]
})
export class AlumnoListadoComponent implements OnInit, OnDestroy {
  navLinks: any[] = [];


  constructor (private _route: ActivatedRoute, private _router: Router,
               private _admin: AdminAlumnoService, private _upload: UploadService) {
    this.navLinks = [
      {path: 'task', label: 'Tareas'},
      {path: 'notas', label: 'Notas examenes'},
      {path: 'asistencias', label: 'Asistencias'}
    ];
  }

  ngOnInit () {
  }

  ngOnDestroy () {
  }


}

