<h4 class="info-top"><i class="ft-user"></i> Credenciales: {{dato?.nombre}} {{dato?.apellidos}} </h4>
<div class="table-responsive">
  <table class="table">
    <thead>
    <tr>
      <th>Imagen</th>
      <th>Nombre</th>
      <th>Apellidos</th>
      <th>Username</th>
      <th>Acciones</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let user of users; let i = index" >

      <td><img class="media-object round-media img-thumbnail" [src]="user.img | images: ''" style="height: 45px"></td>
      <td>{{user.nombre}}</td>
      <td>{{user.apellidos}}</td>
      <td  id="a_{{i}}">
         <small *ngIf="user.username | mail">{{user.username}}</small>


      </td>
      <td hidden id="b_{{i}}">
         <input type="text" name="email" [(ngModel)]="email"/>
          &nbsp; <i class="ft-upload-cloud success" (click)="setUser(email, user.id, i)"></i>
        </td>
      <td>
        <ul class="list-inline">
          <li class="success p-0 list-inline-item" *ngIf="user.username | mail">
            <i *ngIf="user.credencial; else no" class="ft-lock font-medium-3 mr-2" (click)="activar(user.credencial, i)"></i>
            <ng-template #no><i class="ft-lock danger font-medium-3 mr-2" (click)="activar(user.credencial, i)"></i></ng-template>
          </li>

          <li class="success p-0 list-inline-item">
            <i (click)="editar(i);" class="ft-edit-2 success"></i>
          </li>

          <li class="success p-0 list-inline-item" *ngIf="user.credencial">
            <i class="ft-mail"></i>
          </li>
        </ul>
      </td>
    </tr>
    </tbody>
  </table>
</div>
