import { Component, OnInit } from '@angular/core';
import {AulasService} from '../../../admin-aulas/services/aulas.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-gestion-clases',
  templateUrl: './gestion-clases.component.html',
  styleUrls: ['./gestion-clases.component.scss']
})
export class GestionClasesComponent implements OnInit {
  aulas: any;
  numero: any;
  tipos: any[] = [];
  active = false;
  edit = false;
  id: any;
  i: any;

  constructor(private aulaService: AulasService) { }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.aulaService.getAulasFisicas().subscribe( ( res ) => {
      console.log(res);
      this.aulas = res;
    });
  }

  updateAlquilable(aula) {
    console.log(aula);
    this.aulaService.updateAlquilable(aula.numeroClase, aula.alquilable).subscribe( (res) => {
      console.log(res);
      this.aulas = res['id'];
    });
  }

  editar(id, numero, index): void {
    this.edit = true;
    this.active = true;
    this.id = id;
    this.numero = numero;
    this.i = index;
  }


  Guardar() {
    if (this.edit === true) {
      console.log('voy a editar')
      this.aulaService.updateNumClase(this.numero, this.id ).subscribe((res) => {
        if (res['code'] === 200) {
          this.aulas = res['id'];
          this.active = false;
          Swal.fire('', 'Se ha actualizado correctamente', 'success');
        }
      }, (error) => {
        console.log(error)
      });
    } else {
      this.aulaService.addAulaFisica(this.numero).subscribe((res) => {
        if (res) {
          this.aulas = res['id'];
          this.active = false;
          Swal.fire('', 'Se ha guardado correctamente', 'success');
        }
      }, (error) => {
        console.log(error)
      });
    }
  }

  abrir() {
    this.edit = false;
    this.active = !this.active;
    this.id = '';
    this.numero = '';
  }

}
