<h4 class="info-top"><i class="ft-user"></i> Gestión de aulas</h4>
<div class="row">
  <div class="col-12" *ngIf="active">
    <form class="form  form-horizontal striped-rows form-bordered" novalidate="" (ngSubmit)="Guardar()" #c="ngForm" autocomplete="off">
      <div class="form-body">
        <div class="form-group row">
          <label class="col-md-3 label-control">Aula número</label>
          <div class="col-md-5">
            <input [(ngModel)]='numero' type="text"  class="form-control width-input" name="tipo">
          </div>
          <div class="col-md-4">
            <button type="submit" class="btn btn-raised btn-success">{{edit ? 'Editar' : 'Crear'}}</button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="table-responsive">
    <table class="table">
      <thead>
      <tr class="d-flex">
        <th class="col-4" scope="col">Aula</th>
        <th class="col-4" scope="col">En alquiler</th>
        <th class="col-4" scope="col">
          Agregar Aula Número <br> <i class="ft-plus-circle font-medium-3 mr-2 text-success"(click)="abrir()"></i>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr class="d-flex" *ngFor="let aula of aulas; let i = index">
        <td class="col-4">{{aula.numeroClase}}</td>
        <td class="col-4">
          <mat-checkbox (change)="updateAlquilable(aula)" [(ngModel)]="aula.alquilable"></mat-checkbox>
        </td>

        <td class="col-4">
          <ul class="list-inline">
            <li class="list-inline-item danger p-0" (click)="editar(aula.id, aula.numeroClase, i)">
              <i class="fa fa-edit font-medium-3 mr-2"></i>
            </li>
          </ul>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
