
<div class="email-application">
  <div class="email-app-sidebar float-left d-none d-xl-block">
    <div class="email-app-sidebar-content">
      <div class="email-app-menu">


        <h6 class="text-muted text-bold-500 mt-1 mb-1">Grupos</h6>
        <div class="list-group list-group-messages">
          <a class="list-group-item list-group-item-action no-border"
             (click)="getGrupos('todos'); grupo = 'todos'">
            <i class="ft-circle mr-1 success"></i>Todos
            <span *ngIf="grupo == 'todos'"
                  class="badge badge-warning badge-pill float-right">{{mensajes.length}}</span>
          </a>
          <a class="list-group-item list-group-item-action no-border"
             (click)="getGrupos('ROLE_PROFESOR');  grupo = 'ROLE_PROFESOR'">
            <i class="ft-circle mr-1 warning"></i>Maestros
            <span *ngIf="grupo == 'ROLE_PROFESOR'"
                  class="badge badge-warning badge-pill float-right">{{mensajes.length}}</span>
          </a>
          <a class="list-group-item list-group-item-action no-border"
             (click)="getGrupos('ROLE_ALUMNO'); grupo = 'ROLE_ALUMNO'">
            <i class="ft-circle mr-1 danger"></i>Alumnos
            <span *ngIf="grupo == 'ROLE_ALUMNO'"
                  class="badge badge-warning badge-pill float-right">{{mensajes.length}}</span>
          </a>
          <a class="list-group-item list-group-item-action no-border"
             (click)="getGrupos('ROLE_PADRE'); grupo = 'ROLE_PADRE' ">
            <i class="ft-circle mr-1 primary"></i>Padre
            <span *ngIf="grupo == 'ROLE_PADRE'"
                  class="badge badge-warning badge-pill float-right">{{mensajes.length}}</span>
          </a>
          <a class="list-group-item list-group-item-action no-border"
             (click)="getGrupos('ROLE_ADMINISTRATIVO'); grupo = 'ROLE_ADMINISTRATIVO'">
            <i class="ft-circle mr-1 success"></i>Administración
            <span *ngIf="grupo == 'ROLE_ADMINISTRATIVO'"
                  class="badge badge-warning badge-pill float-right">{{mensajes.length}}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="email-app-content row">
    <!-- ******** 1. Esto es el listado de correos ******** -->
    <div class="email-app-list-mails p-0">
      <div class="email-app-list">
        <div id="users-list" >
          <div style="padding-left: 10px; padding-right: 10px;">
            <button type="button" class="btn btn-raised btn-danger btn-block my-2 botonredactar" (click)="open(content)">
              <i class="ft-mail"></i> Nueva conversacion
            </button>
          </div>

          <div class="list-group">
            <div class="form-group form-group-compose text-center">

            </div>
            <div class="users-list-padding">
              <a class="list-group-item list-group-item-action no-border"
                 (click)="SetActive($event, i); getMessage(mensaj);"
                 *ngFor="let mensaj of mensajes; let i = index">
                <span class="media">
                  <span class="avatar avatar-md mr-2">
                    <span class="media-object rounded-circle text-circle d-flex mr-2 align-items-center bg-info">
                      <img [src]="mensaj.img | imagesInbox: ''"/>
                    </span>
                  </span>
                  <div class="media-body">
                    <h6 class="list-group-item-heading text-bold-400">{{mensaj.nombre}} {{mensaj.apellidos}}
                      <span class="float-right">
                        <span class="font-small-2 primary">{{mensaj.fecha}}</span>
                      </span>
                    </h6>
                    <p class="list-group-item-text"><span
                      *ngIf="mensaj.id_emi == id_usuario">Tu: </span>{{mensaj.mensaje | slice:0:15}}</p>
                    <!-- ******** No leido == 0 ******** -->
                    <span class="float-right primary">
                      <i *ngIf="mensaj.leido == 0 && mensaj.id_emi != id_usuario "
                         class="font-medium-1 ft-mail  iconoMensaje"></i>

                    </span>
                  </div>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ******** 1. ************************************ -->

    <!-- ******** 2. Esto es el correo seleccionado ******** -->
    <div class="email-app-mail-content"  *ngIf="contacto.length != 0">
      <div class="email-app-title card-block">
        <h5 class="list-group-item-heading white">{{contacto.nombre}} {{contacto.apellidos}}</h5>

      </div>
      <div class="email-app-mail-content-detail">

        <div class="mesgs">
          <div #scrollMe class="msg_history">
            <li class="message" *ngFor="let men of mensaje">
              <div
                *ngIf="
                  men.id != id_usuario;
                  then incoming_msg;
                  else outgoing_msg
                "
              ></div>
              <ng-template #incoming_msg>
                <div class="incoming_msg">

                  <div class="received_msg">
                    <div class="received_withd_msg">
                      <p>{{ men.mensaje }}</p>
                      <span class="float-right">
                        <span class="font-small-2 primary">{{men.fecha}}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template #outgoing_msg>
                <div class="outgoing_msg">
                  <div class="sent_msg">
                    <p>{{ men.mensaje }}</p>
                    <span class="float-right">
                        <span class="font-small-2 primary">{{men.fecha}}</span>
                      </span>

                  </div>
                </div>
              </ng-template>
            </li>
          </div>
          <!-- ******** 2.3 ************************** -->

          <div class="email-app-text-action card-block">
          </div>
        </div>

      </div>
      <div class="type_msg">
        <form class="input_msg_write">
          <input
            type="text"
            class="write_msg"
            placeholder="Escribe tu mensaje ..."
            name="newMessage"
            [(ngModel)]="newMessage"
          />
          <button class="msg_send_btn" type="button" (click)="send()">
            <i class="fa fa-paper-plane-o" aria-hidden="true"></i>
          </button>
        </form>
      </div>

      <!-- ******** 2. **************************************** -->

    </div>
    <ng-template #content let-c="close" let-d="dismiss">
      <div class="modal-header px-4">
        <h4 class="modal-title">Contactos</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body px-4">
        <form role="form" class="form form-horizontal" novalidate="">
          <div class="form-group row">
            <div class="col-md-10">
              <div class="col-12" id="sendUsers">
              </div>
              <input type="text" id="emailTo" class="form-control" [value]="cont"
                     name="emailTo" placeholder="Buscar contacto ..." (keyup)="key($event)">
              <ng-container *ngIf="users.length > 0 || itKey">
                <div *ngFor="let user of users; let i = index;"
                     (click)="addUser(i, user.id) ; onSubmit(user); c('Close click')" [id]=user.id
                     [innerHTML]=" user.nombre +' '+ user.apellidos | recortar: user.tipo">

                </div>
              </ng-container>
            </div>
          </div>
        </form>

      </div>
    </ng-template>

  </div>
</div>
