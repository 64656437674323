export class Horarios {
  public duracion;
  public horas;
  public bloques;
  public array: any[] = [];
  public array_vacio: any[] = [];
  public id; number;

  constructor(public inicio: number,
              public minutos_inicio: number,
              public fin: number,
              public minutos_fin,
              public tramo: number,
              public nombre: string) {
    this.nombre = nombre;
    this.inicio = inicio;
    this.minutos_inicio = minutos_inicio;
    this.fin = fin;
    this.minutos_fin = minutos_fin;
    this.bloques = tramo / 60;
    this.tramo = tramo;
    this.duracion = this.fin - this.inicio;
    this.horas = this.duracion / this.bloques;
    this.array = this.cargar_array(this.horas);
    this.array_vacio = this.cargar_array_vacio();
  }


  cargar_array(horas) {
    const hora = horas - 1;
    this.array.push(this.inicio + ':' + this.minutos_inicio);
    const fecha = new Date();
    // funcion para distingir horas
    fecha.setHours(this.inicio);
    fecha.setMinutes(this.minutos_inicio);
    fecha.setSeconds(0);
    let fecha2;
    for (let i = 0; i < hora; i++) {
      fecha2 = fecha.getMinutes() + this.tramo;
      fecha.setMinutes(fecha2);
      this.array.push(fecha.getHours() + ':' + this.make_hora(fecha.getMinutes()));
    }
    this.array.push(this.fin + ':' + this.minutos_fin);
    return this.array;
  }

  cargar_array_vacio() {
    for (let i = 0; i < this.horas; i++) {
      this.array_vacio.push(i);
    }
    return this.array_vacio;
  }

  make_hora(minutos) {
    const m = '' + minutos;
    if (m.length === 1) {
      return m + '0';
    }
    return m;
  }
}
