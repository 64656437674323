// Importamos los componentes necesarios
import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

// Le ponemos un nombre a la tuberia
@Pipe({name: 'recortar'})

// Definimos la clase implementado la interface PipeTransform
export class RecortarRolePipe implements PipeTransform {
  constructor (private _sanitizer: DomSanitizer) {
  }

  transform (nombre: string, value: string): any {
    const valores = ['ROLE_ALUMNO', 'ROLE_PROFESOR', 'ROLE_PADRE', 'ROLE_AULA', 'ROLE_TODO', 'ROLE_ADMIN'];
    const colores = ['red', 'green', 'blue', 'black', 'crimson', 'indigo'];
    let color = '';
    valores.forEach((values, index) => {
      if (values === value) {
        color = colores[index];
      }
    });
    const role = value.split('_');
    const html = this._sanitizer.bypassSecurityTrustHtml(`<p>${nombre}<small
    style="background-color:${color}; text-align: center;
           padding: 2px;
           margin-left: 5px;
           color: white;
           font-size: 0.6em;"> ${role[1]}</small></p>`);
    return html;
  }
}
