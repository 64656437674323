import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {TablonService} from './tablon.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {Rutas, RutasImg} from '../../../utiles/Rutas';
import {TablonModalComponent} from './tablon-modal/tablon-modal.component';
import Swal from 'sweetalert2';
import {DetalleModalComponent} from './detalle-modal/detalle-modal.component';

@Component({
  selector: 'app-tablon',
  templateUrl: './tablon.component.html',
  styleUrls: ['./tablon.component.scss']
})
export class TablonComponent implements OnInit {

  filesToUpload: Array<File> = [];
  mensajes: any;
  mensajeForm: FormGroup;
  destino = {
    todos: false,
    alumnos: false,
    padres: false,
    profesores: false,
    aulas: false,
  };
  rutaImg = RutasImg;
  data = {
    'todos' : true,
    'profesores' : false,
    'alumnos': false,
    'tutores': false,
    'aula': null
  };
  showAulas =  false;
  cursos: any ;
  cursosDest: any  = [];


  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Escribe aqui el mensaje del tablón de anuncios...',
    translate: 'no',
    sanitize: true,

    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [

    ],

  };

  @ViewChild('mensaje', { static: true }) mensajeDialog: TemplateRef<any>;

  constructor(private tablonService: TablonService,  private dialog: MatDialog) { }

  ngOnInit(): void {
    this.initForm();
    this.getMensajesTablon();

  }

  private initForm(): void {
    this.mensajeForm = new FormGroup({
      titulo: new FormControl('', Validators.required),
      todos: new FormControl(''),
      alumnos: new FormControl(''),
      padres: new FormControl(''),
      profesores: new FormControl(''),
      aulas: new FormControl(''),
      curso: new FormControl(''),
      cursoDestinatarios: new FormControl(''),
      mensaje: new FormControl('', Validators.required),
      id: new FormControl(null, Validators.required),


    })
  }
  getMensajesTablon() {
    this.tablonService.getMensajeTablonAdmin().subscribe( ( res ) => {

      this.mensajes = res;
      console.log(this.mensajes);
    });
  }






  abrirInput() {
    document.getElementById('input-file').click();
  }

  openModal() {
    this.mensajeForm.reset();
    const dialogRef = this.dialog.open(TablonModalComponent, { hasBackdrop: true });
    dialogRef.afterClosed().subscribe( result => {
      console.log(result);

        this.getMensajesTablon();

      });
  }


  openModalDetalle(mensaje: any) {
    this.mensajeForm.reset();
    const dialogRef = this.dialog.open(DetalleModalComponent, { hasBackdrop: true, data: mensaje, width: '600px', panelClass: 'custom-modalbox' });

  }




  openFile(url) {
    window.open(Rutas + url);
  }

// asda
  deleteMensaje(id: number): void {


    Swal.fire({
      title: '',
      text: '¿Desea eliminar este anúncio del tablón? Se borrará de forma permanente para todo usuario.',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if ( result.isConfirmed ) {
        this.tablonService.deleteMensajeTablon(id).subscribe( ( res ) => {

          this.getMensajesTablon();
          console.log(res);
        });
      }
    });


  }

  updateAnuncio(mensaje: any) {
    this.mensajeForm.patchValue(mensaje);
    console.log(this.mensajeForm.value);
    const dialogRef = this.dialog.open(TablonModalComponent, { hasBackdrop: true, data: mensaje });
    dialogRef.afterClosed().subscribe( result => {
      console.log(result);

      this.getMensajesTablon();

    });
  }
}
