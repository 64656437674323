import {Component, OnInit, ViewChild} from '@angular/core';
import {ComercialService} from '../../services/comercial.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {KtdGridComponent, KtdGridLayout} from '@katoid/angular-grid-layout';
import {Subscription} from 'rxjs/Subscription';
import {fromEvent, merge} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-estadisticas-comercial',
  templateUrl: './estadisticas-comercial.component.html',
  styleUrls: ['./estadisticas-comercial.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class EstadisticasComercialComponent implements OnInit {
  @ViewChild(KtdGridComponent, {static: true}) grid: KtdGridComponent;
  contactosDiarios: any = [];
  plazas: any = [];
  cursosEscolares: any = [];
  cursoForm: FormGroup;
  polarForm: FormGroup;
  datosPie: any ;
  datospolar: any = [] ;
  totaldata: any = [];
  barData: any = [];
  barTotalData: any = [];
  a: number [] = [];
  b: number  [] = [];
   l: any [] = [];
   t = ''
  arr = [];
   years: any;
  expandedElement: any | null;
  today: any;
  yearO: number ;

  labelspolar: any = [] ;
  datospolar2: any = [] ;
  labelspolar2: any = [] ;
  fakeNgFor = ['hola'];
  contador = 0;
  columnsToDisplay = ['modulo', 'ocupadasT', 'ratioT',  'restantes'];
  cols = 6;
  rowHeight = 10;
  layout: KtdGridLayout = localStorage.getItem('formato') === null ? [
    {id: '0', x: 0, y: 0, w: 3, h: 82},
    {id: '1', x: 3, y: 114, w: 3, h: 58},
    {id: '2', x: 0, y: 82, w: 3, h: 189},
    {id: '3', x: 3, y: 31, w: 3, h: 83},
    {id: '4', x: 3, y: 0, w: 3, h: 31}

  ] : JSON.parse(localStorage.getItem('formato'));

  private resizeSubscription: Subscription;


  constructor( private comercialService: ComercialService) {

  }

  ngOnInit(): void {
    this.years = this.generateArrayOfYears();
    this.getDefaultYear();
    this.getDiarios();
    this.getCursosEscolares();
    this.initForms();
    this.initPie();
    this.initPolar();
    this.getBarData();

    //Autoresize del drag an drop
    this.resizeSubscription = merge(
      fromEvent(window, 'resize'),
      fromEvent(window, 'orientationchange')
    ).pipe(
      debounceTime(50)
    ).subscribe(() => {
      this.grid.resize();
    });
  }

  getDiarios() {
    this.comercialService.getDiarios().subscribe( (res ) => {
      this.contactosDiarios = res;
    });
    this.comercialService.getPlazas(this.yearO).subscribe( (res ) => {
      this.plazas = res  as string[];
      console.log(this.plazas);

    });
  }
  getCursosEscolares() {
    this.comercialService.getCursosEscolares().subscribe( ( res ) => {
      this.cursosEscolares = res;
    });
  }
  initForms() {
    this.cursoForm = new FormGroup({
     curso: new FormControl('', Validators.required)
    });
    this.polarForm = new FormGroup({
      cursoPolar: new FormControl('', Validators.required)
    });
  }

  initPie() {
    this.comercialService.getCursosInitPieData().subscribe( ( res ) => {
      this.datosPie = res ;
      console.log(this.datosPie);
    });
  }

  changePieData() {
    console.log(this.cursoForm.controls['curso'].value);
    this.comercialService.getCursosPieData(this.cursoForm.controls['curso'].value).subscribe( ( res ) => {
      console.log(res);
      this.datosPie = res;
    });

  }

  initPolar() {
    this.comercialService.getInitPolar().subscribe( ( res ) => {
      console.log(res);
      this.setDataForCharts(res);

    });
  }

  changePolarData() {


    this.comercialService.getCursosPolarData(this.polarForm.controls['cursoPolar'].value).subscribe( ( res ) => {
      this.setDataForCharts(res);
      console.log(res);

    });
  }

  setDataForCharts(res) {
    this.contador += 1;
    this.datospolar = [];
    this.datospolar2 = [];
    this.datospolar = res.referidos;
    this.datospolar2 = res.forma;
    this.fakeNgFor = ['hola' + this.contador];
  }


  getBarData() {
    this.comercialService.getBarrasData().subscribe( (res) => {

      this.barData = res;
    });
    this.comercialService.getBarrasTotalData().subscribe( (res) => {

      this.barTotalData = res;

      this.t = this.barTotalData[0].name;
      for (const fi of this.barTotalData[0].datos) {

        this.a.push(fi.value);
      }
      this.b = this.barTotalData[1].datos

    });
    this.comercialService.getTotalesData().subscribe( ( res) => {
      console.log(res);
      this.totaldata = res;
    });
  }

  print( event) {
    localStorage.setItem('formato', JSON.stringify(event));
  }
  getDefaultYear() {
    this.today =  new Date();
    console.log(this.today.getMonth());
    if (this.today.getMonth() >= 11) {
      this.yearO =  this.today.getFullYear() + 1;

    } else {
      this.yearO =  this.today.getFullYear();
    }
    console.log(this.yearO);
  }

   generateArrayOfYears() {
    const max = new Date().getFullYear() + 20;
    const min = new Date().getFullYear() - 5;
    const years = [];

    for (let i = max; i >= min; i--) {
      years.push(i);
    }
    return years
  }

  cambiarAnyo() {
    this.comercialService.getPlazas(this.yearO).subscribe( (res ) => {
      this.plazas = res  as string[];
      console.log(this.plazas);
    });
  }




}


