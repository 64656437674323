import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {StatsService} from './service/stats.service';
import {Subscription} from 'rxjs/Subscription';

@Component({
  selector: 'app-admin-stats',
  templateUrl: './admin-stats.component.html',
  styleUrls: ['./admin-stats.component.scss'],
  encapsulation: ViewEncapsulation.None
})


export class AdminStatsComponent implements OnInit, OnDestroy {
  navLinks = [{}];
  statsSubscription = new Subscription();
  alumnos: any;
  profesores: any;
  asistencias: Number [];
  datos: any[] = [];
  calculo_asistencias: any[] = [];
  start: boolean;
  fecha: Date;

  constructor (private _route: ActivatedRoute, private _statService: StatsService) {
    this.navLinks = [{path: 'stats', label: 'Estadisticas'}];
    this._statService.getCentroStats();
    this.fecha = new Date();
  }

  ngOnInit () {
    this.statsSubscription = this._statService.stats$.subscribe(resp => {
      console.log(resp);
      this.alumnos = resp[0].alumnos;
      this.profesores = resp[1].profesores;
      this.asistencias = resp[2].asistencia;
      console.log(this.asistencias, 13.85);
      const ratio = this.profesores / this.alumnos * 100;
      this.datos = ['Alumnos', 'Profesores', this.alumnos, this.profesores, ratio, 'Ratio profesor alumno' ];
      this.start = true;
    });


  }

  ngOnDestroy () {
    this.statsSubscription.unsubscribe();
  }
}
