<h4 class="info-top"><i class="ft-user"></i> {{type}} Info</h4>
<div class="row">

    <div class="col-md-2" *ngIf="dato.nombre">
        <img class="img-aula img-thumbnail rounded mx-auto d-block" [src]="dato.img | images: ''" onclick="document.getElementById('buscadorPersona').click()">
    </div>

    <div class="form-group" id="barraImages" hidden>
        <div class="input-group">
            <span class="input-group-btn">
                    <span class="btn btn-default btn-file">
                        <input id="buscadorPersona" name="filebutton-normal" type="file" class="file" (change)="fileChangeEvent($event, 'user')">
                    </span>
            </span>
            <input type="text" class="form-control" readonly>
        </div>
    </div>
    <div class="col-md-10">
        <div class="card">
            <div class="card-body">
                <div class="px-3">
                    <form class="form form-horizontal striped-rows form-bordered" novalidate="" (ngSubmit)="onSubmit()" #f="ngForm">
                        <input type="hidden" name="id" [(ngModel)]="dato.id">

                        <div class="form-body">
                            <div class="form-group row">
                                <label class="col-md-3 label-control" >Provincias</label>
                                <div class="col-md-5">
                                    <select [(ngModel)]='provincia' name="provincia" class="form-control" (change)="getMunicipios()">
                                        <ng-container *ngIf="provincias.length > 0" >
                                            <option selected *ngFor="let provincia of provincias" [value]="provincia.id">{{provincia.provincia}}</option>
                                        </ng-container>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 label-control">Municipio</label>
                                <div class="col-md-5">
                                    <select [(ngModel)]='dato.id_municipio' name="id_municipio" class="form-control">
                                        <ng-container *ngIf="municipios.length > 0">
                                            <option *ngFor="let municipio of municipios" [ngValue]="municipio.id">{{municipio.municipio}}</option>
                                        </ng-container>

                                        <ng-container *ngIf="dato.municipio">
                                            <option [ngValue]="dato.id_municipio">{{dato.municipio}}</option>
                                        </ng-container>
                                    </select>
                                </div>
                            </div>


                            <div class="form-group row">
                                <label class="col-md-3 label-control">Nombre</label>
                                <div class="col-md-5">
                                    <input [(ngModel)]='dato.nombre' required type="text" class="form-control width-input" name="nombre" placeholder="Nombre">
                                </div>
                            </div>



                            <div class="form-group row">
                                <label class="col-md-3 label-control">Apellidos</label>
                                <div class="col-md-5">
                                    <input [(ngModel)]='dato.apellidos' required type="text" class="form-control" name="apellidos" placeholder="Apellidos">
                                </div>
                            </div>


                            <div class="form-group row">
                                <label class="col-md-3 label-control">Direccion</label>
                                <div class="col-md-5">
                                    <input [(ngModel)]='dato.direccion' required type="text" class="form-control" name="direccion" placeholder="Direccion">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-md-3 label-control">Email</label>
                                <div class="col-md-5">
                                    <input [(ngModel)]='dato.dni' type="text" class="form-control" name="dni" placeholder="Email">
                                </div>
                            </div>

                          <div class="form-group row">
                            <label class="col-md-3 label-control">Telefono</label>
                            <div class="col-md-5">
                              <input [(ngModel)]='this.contacto.dato' type="text" class="form-control" name="telefono" placeholder="Telefono">
                            </div>
                          </div>


                            <div class="form-group row" *ngIf="isAlumno">
                                <label class="col-md-3 label-control">Curso</label>
                                <div class="col-md-5">
                                    <select class="form-control" [(ngModel)]='cursos.id' name="curso">
                                <ng-container *ngIf="cursos.length > 0">
                                    <option *ngFor="let curso of cursos" [value]="curso.id">{{curso.nivel}}</option>
                                </ng-container>
                                <ng-container *ngIf="dato.nivel">
                                    <option [value]="dato.nivel" selected>{{dato.nivel}}</option>
                                </ng-container>
                            </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-actions">
                            <!--<button type="button" (click)="active=!active" class="btn btn-raised btn-success mr-1" [disabled]="active">
                                <i class="ft-edit-2"></i> Editar
                            </button>-->
                            <button type="submit" class="btn btn-raised btn-primary"  [disabled]="disabled">
                                <i class="ft-save"></i> Guardar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
