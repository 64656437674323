export const Horarios = {
  inicio: 9,
  fin: 14,
  tramo: 0.8333,
  hora_descanso: 11,
  minuto_descanso: 0,
  minutos: 50,
  descanso: 30,
  hora_inicio: 9,
  minuto_inicio: 0
};

export const Agenda = {
  bloques: 15,
  descanso: [2, 5]
};

export const AppId = {
  app_id: '4744e9ab-17a4-4869-b3c7-277837e57d52'
};

export const MapId = 'AIzaSyB0ayuqe7UdQkN_F_2Q2c0o2IH_mF3Emb0';
