import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Subject} from 'rxjs/Subject';
import {CalendarEvent, CalendarEventAction} from 'angular-calendar';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Rutas} from '../../../../utiles/Rutas';

@Injectable()
export class CalendarioService {
  private tipoSource: Subject<any> = new Subject();
  tipos$ = this.tipoSource.asObservable();
  private eventosSource: Subject<any> = new Subject<any>();
  eventos$ = this.eventosSource.asObservable();
  events: CalendarEvent[] = [];
  modalSource: Subject<boolean> = new Subject<boolean>();
  modal$ = this.modalSource.asObservable();
  eventSource: Subject<any> = new Subject<any>();
  event$ = this.eventSource.asObservable();
  paramsSource: Subject<any> = new Subject<any>();
  params$ = this.paramsSource.asObservable();

  actions: CalendarEventAction[] = [
    {
      label: '<i class="ft-edit-2 font-medium-3 mr-2"></i>',
      onClick: ({event}: { event: CalendarEvent }): void => {
        this.open(event);
      }
    },
    {
      label: '<i class="ft-trash font-medium-3 mr-2 text-danger"></i><br/>',
      onClick: ({event}: { event: CalendarEvent }): void => {
        this.events = this.events.filter(iEvent => iEvent !== event);
        this.eventosSource.next(this.events);
        this.delete('Deleted', event);
      }
    }
  ];

  constructor(private http: HttpClient, private modal: NgbModal) {
  }

  params() {
    this.http.get(Rutas + 'agenda/activa?token=' + localStorage.getItem('token'))
      .subscribe(resp => this.paramsSource.next(resp['id']), err => console.log('Error: ' + err.message));
  }

  delete(action, event) {
    this.http.get(Rutas + 'evento/delete?token=' + localStorage.getItem('token') + '&id=' + event.id)
      .subscribe(resp => () => err => console.log('Error: ' + err.message));
  }

  open(event) {
    this.modalSource.next(true);
    this.eventSource.next(event);
  }

  lista() {
    this.http.get(Rutas + 'evento/eventos?token=' + localStorage.getItem('token'))
      .subscribe(data => {
        if (data['code'] === 200) {
          let evento: any;
          this.events = [];
          data['id'].forEach(item => {
            evento = {
              title: item.title,
              start: new Date(item.start.date),
              end: new Date(item.end.date),
              actions: this.actions,
              meta: {
                incrementsBadgeTotal: true
              },
              id: item.id,
            };
            this.events.push(evento);
          });
          this.eventosSource.next(this.events);
        }
      }, err => console.log(err + ' : error'));
  }

  lista_tipos() {
    this.http.get(Rutas + 'evento/tipos?token=' + localStorage.getItem('token'))
      .subscribe(data => {
        if (data['code'] === 200) {
          this.tipoSource.next(data['id']);
        } else {
          return false;
        }
      });
  }

  add(evento) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    console.log(evento);
    const json = {evento};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'evento/add', params, {headers: headers}).subscribe(resp => {
      },
      error => {
        console.log(error);
      });
  }

  addTipo(tipo) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {tipo};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'evento/add/tipo', params, {headers: headers}).subscribe(resp => {
        console.log(resp);
      },
      error => {
        console.log(error);
      });
  }
}
