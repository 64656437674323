import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import 'rxjs/add/operator/map';
import {Subject} from 'rxjs/Subject';
import {Rutas} from '../../../utiles/Rutas';
import {LoginService} from '../../pages/services/login.service';


@Injectable()
export class InboxService {
  private mensajesSource = new Subject<any>();
  public mensajes$ = this.mensajesSource.asObservable();
  private usersSource = new Subject<any>();
  public users$ = this.usersSource.asObservable();
  private mensajesEnviadosSource = new Subject<any>();
  public mensajesEnviados$ = this.mensajesEnviadosSource.asObservable();
  private numeroSource = new Subject<any>();
  numero$ = this.numeroSource.asObservable();

  constructor(private http: HttpClient, private _login: LoginService) {
  }

  getReceptoresAdmin(id: number, key: string) {
    this.http.get(Rutas + 'get/receptores/admin?id=' + this._login.getIdentity().id + '&key=' + key + '&token='
      + localStorage.getItem('token'))
      .subscribe(res => {
        if (res['code'] === 200) {
          this.usersSource.next(res['id']);
        }
      });
  }

  mensajesNoLeidos() {
    this.http.get(Rutas + 'aviso/noleidos?id=' + this._login.getIdentity().id + '&token=' + localStorage.getItem('token'))
      .subscribe(res => {
        if (res['code'] === 200) {
          this.numeroSource.next(res['id']);
        }
      });
  }

  getPadre(id: number, key: string) {
    this.http.get(Rutas + 'get/profesores/padre?id=' + id + '&key=' + key + '&token=' + localStorage.getItem('token'))
      .subscribe(res => {
        if (res['code'] === 200) {
          this.usersSource.next(res['id']);
        }
      }, error => {
        console.log(error);
      });
  }

  getAulaMaestro(id: number, key: string) {
    this.http.get(Rutas + 'get/alumnos/aulas?id=' + id + '&key=' + key + '&token=' + localStorage.getItem('token'))
      .subscribe(res => {
        if (res['code'] === 200) {
          this.usersSource.next(res['id']);
        }
      }, error => {
        console.log(error);
      });
  }

  getAulaAlumno(id: number, key: string) {
    this.http.get(Rutas + 'get/profesores/aula?id=' + id + '&key=' + key + '&token=' + localStorage.getItem('token'))
      .subscribe(res => {
        if (res['code'] === 200) {
          this.usersSource.next(res['id']);
        }
      }, error => {
        console.log(error);
      });
  }

  add(msg: any) {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
      const json = { msg };
      const data = JSON.stringify(json);
      const params = 'json=' + data + '&token=' + localStorage.getItem('token');
      this.http.post(Rutas + 'add/message', params, { headers: headers }).subscribe(datas => {
        if (datas['code'] === 200) {
          resolve(datas['id']);
        } else {
          reject(false);
        }
      }, error => {
        console.log(error);
      });
    });

  }

  public delMessage(id: number, id_user: number) {
    this.http.get(Rutas + 'delete/message?id_user=' + id_user + '&id=' + id + '&token=' + localStorage.getItem('token')).subscribe(data => {
      if (data['code'] === 200) {
        this.mensajesSource.next(data['id']);
      } else {
        this.mensajesSource.next(false);
      }
    }, err => console.log(err));
  }

  public getGrupo(grupo: string, id: number) {
    this.http.get(Rutas + 'get/grupo?grupo=' + grupo + '&id=' + id + '&token=' + localStorage.getItem('token')).subscribe(data => {
      if (data['code'] === 200) {
        this.mensajesSource.next(data['id']);
      } else {
        this.mensajesSource.next(false);
      }
    }, err => console.log(err));
  }

  public getUser(key: string) {
    this.http.get(Rutas + 'search/user?key=' + key + '&token=' + localStorage.getItem('token')).subscribe(data => {
      if (data['code'] === 200) {
        this.usersSource.next(data['id']);
      } else {
        this.usersSource.next(false);
      }
    }, err => console.log(err));
  }

  public readMessage(id: number, id_usuario: number) {
    const url = Rutas + 'read/messages?id=' + id + '&id_u=' + id_usuario;
    this.http.get(url + '&token=' + localStorage.getItem('token')).subscribe(data => {
      if (data['code'] === 200) {
        this.mensajesNoLeidos();
      } else {
        this.mensajesSource.next(false);
      }
    }, err => console.log(err));
  }

  public getMessages(id: number, tipo: string) {
    let url = '';
    switch (tipo) {
      case 'centro':
        url = Rutas + 'mensaje/get/centro?id=' + id;
        break;
      case 'user':
        url = Rutas + 'mensaje/get?id=' + id + '&tipo=' + tipo;
        break;

      case 'send':
        url = Rutas + 'send/messages?id=' + id;
        break;

      case 'noleidos':
        url = Rutas + 'notread/messages?id=' + id;
        break;
    }

    this.httpResponse(url);
  }

  private httpResponse(url: string) {
    this.http.get(url + '&token=' + localStorage.getItem('token')).subscribe(data => {
      console.log(data);
      if (data['code'] === 200) {
        this.mensajesSource.next(data['id']);
      } else {
        this.mensajesSource.next(false);
      }
    }, err => console.log(err));
  }

  async  httpResponse2(id, idRem) {
    const url = Rutas + 'mensajes/get/user?id=' + id + '&idi=' + idRem;
    return this.http.get(url + '&token=' + localStorage.getItem('token')).toPromise();
  }
}
