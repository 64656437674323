<!-- Navbar (Header) Starts -->
<nav class="navbar navbar-expand-lg navbar-light bg-faded">
  <div class="container-fluid">
    <div class="navbar-header">
      <button type="button" class="navbar-toggle d-lg-none float-left" data-toggle="collapse">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <form class="navbar-form navbar-right mt-1" role="search">
        <div class="position-relative has-icon-right">
          <input type="text" class="form-control round" placeholder="Search">
          <div class="form-control-position">
            <i class="ft-search"></i>
          </div>
        </div>
      </form>
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="nav-item" ngbDropdown placement="bottom-right" [routerLink]="['/alumno/inbox']">
            <a class="nav-link position-relative" id="dropdownBasic2">
              <i class="ft-bell font-medium-3 blue-grey darken-4"></i>
              <span *ngIf="mensajes" class="notification badge badge-pill badge-danger">{{mensajes.total}}</span>
            </a>
          </li>
          <li class="nav-item" ngbDropdown placement="bottom-right">
            <a class="nav-link position-relative" id="dropdownBasic3" ngbDropdownToggle>
              <i class="ft-user font-medium-3 blue-grey darken-4"></i>
              <p class="d-none">User Settings</p>
              <i class="">{{datos.nombre}} {{datos.apellidos}}</i>
            </a>

            <div ngbDropdownMenu aria-labelledby="dropdownBasic3">
              <a class="dropdown-item py-1" href="javascript:;">
                <i class="ft-settings mr-2"></i>
                <span>Settings</span>
              </a>
              <a class="dropdown-item py-1" (click)="editar()">
                <i class="ft-edit mr-2"></i>
                <span>Editar perfil</span>
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="logout()">
                <i class="ft-power mr-2"></i>
                <span>Logout</span>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
<!-- Navbar (Header) Ends -->

