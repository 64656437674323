import {Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild, OnDestroy, TemplateRef} from '@angular/core';
import {ScreenService} from '../services/screen.service';
import {Horarios} from '../modelo/horarios';
import {HttpService} from '../services/http.service';
import {Subscription} from 'rxjs/Subscription';
import {ActivatedRoute} from '@angular/router';
import {
  addDays,
  addHours,
  addMilliseconds,
  endOfMonth, endOfWeek, getDay, getMonth,
  getYear, isFriday, isMonday, isThursday,
  isTuesday, isWednesday,
  setDay, setMonth,
  setYear,
  startOfDay,
  startOfWeek,
  subDays
} from 'date-fns';
import {colors} from '../../calendario/utils/color';
import {CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView} from 'angular-calendar';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Subject} from 'rxjs';
import {CalendarioService} from '../../calendario/services/calendario.service';
import {Asignatura} from '../modelo/asignatura';
import {DatePipe} from '@angular/common';
import swal from 'sweetalert2';

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.css'],
  providers: [ScreenService]
})

export class AgendaComponent implements OnInit, OnDestroy {
  profesoresSubscription: Subscription = new Subscription();
  asignaturasSubscription: Subscription = new Subscription();
  aulaSbuscription: Subscription = new Subscription();
  parametroSubscription: Subscription = new Subscription();
  cursoSubscription: Subscription = new Subscription();
  cursoAddSubscription: Subscription = new Subscription();
  cursoOcupadoSubscription: Subscription = new Subscription();
  horarios: Horarios;
  height: any;
  profesores: any[] = [];
  asignaturas: any[] = [];
  aula: any[] = [];
  dias: any[] = [];
  id: number;
  curso: any[] = [];
  colorList = colors;
  // @ts-ignore
  @ViewChild('modalContent', {static: true}) modalContent: TemplateRef<any>;
  refresh: Subject<any> = new Subject();
  viewDate: Date = new Date();

  start: any;
  end: any;

  startEndSubscription: Subscription = new Subscription();

  asignaturasMostrar: CalendarEvent[] = [];

  idCurso: number;
  colorM: any;
  colorAdd: any;
  idProfesorAdd: number;
  idAsignaturaAdd: number;
  startAdd: any;
  endAdd: any;
  asignaturaAdd: {
    asignatura: number;
    aula: number;
    hora: number;
    start: string | Date; profesor: number; end: string | Date; id: number; dia: string, color: string
  };
  diaSemanaAdd = [
    {fromEndWeek: 5, diaSemana: 'Lunes'},
    {fromEndWeek: 4, diaSemana: 'Martes'},
    {fromEndWeek: 3, diaSemana: 'Miércoles'},
    {fromEndWeek: 2, diaSemana: 'Jueves'},
    {fromEndWeek: 1, diaSemana: 'Viernes'}
  ];

  diaSemanaSelectedAdd = 5;

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-fw fa-times danger"></i>',
      onClick: ({event}: { event: CalendarEvent }): void => {
        this._http.remove(event.id);
        this.removeClaseMostrarYAula(event.id);
      }
    }
  ];

  constructor(private renderer: Renderer2,
              private _screen: ScreenService,
              private _http: HttpService,
              private _router: ActivatedRoute,
              private modal: NgbModal,
              public service: CalendarioService,
              public datepipe: DatePipe) {
    this.dias = ['lunes', 'martes', 'miercoles', 'jueves', 'viernes'];
    this._screen.width_heigth_screen();
    this._http.getConfiguracion();
    this._router.parent.params.subscribe(params => {
      this.id = params['id'];
      this._http.get(this.id);
      this._http.getCurso(this.id);
    });
    this._http.getProfesores();
    this.service.params();
    this.colorAdd = this.colorList[0].name;
  }

  ngOnInit() {
    this.parametroSubscription = this._http.parametros$.subscribe(resp => {
      this.horarios = new Horarios(resp.horaInicio, resp.minutoInicio, resp.horaFin, resp.minutoFin, resp.tramo, resp.nombre);
    });

    this.profesoresSubscription = this._http.users$.subscribe(respProfesores => {
      this.idCurso = +this._router.snapshot.parent.params.id;
      this.profesores = respProfesores;
      if (this.profesores) {
        this.idProfesorAdd = this.profesores[0].id;
      }

    });
    this.asignaturasSubscription = this._http.asignaturas$.subscribe(respAsignaturas => {
      this.asignaturas = respAsignaturas;
      if (this.asignaturas) {
        this.idAsignaturaAdd = this.asignaturas[0].id;
      }
    });

    this.aulaSbuscription = this._http.aula$.subscribe(respAulaCalendario => {
      this.aula = respAulaCalendario;
      console.log(this.aula);
      this.mostrarClasesEnCalendario();
      // this.anyadirAsignaturasGuardar();
      // this.insertar_aula();
    });

    this.cursoSubscription = this._http.curso$.subscribe(resp => {
      this._http.getAsignaturas(resp[0], resp[1]);
      console.log(resp);
      this.curso = [resp[2], resp[3], resp[4]];
    });

    this.cursoOcupadoSubscription = this._http.cursoOcupado$.subscribe(resp => {
      if (resp === 'libre') {
        this._http.addAsignatura(this.asignaturaAdd);
        this.asignaturaAdd = null;
      } else {
        swal.fire('Mensaje', this.profesores[this.profesores.findIndex(profesor => profesor.id === this.idProfesorAdd)].nombre
          + ' ya tiene esa franja horaria ocupada.', 'error'
 );
        // this.anyadirAsignaturaMostrar();
        this.mostrarClasesEnCalendario();
      }
    });
    this.cursoAddSubscription = this._http.cursoAdd$.subscribe(resp => {
      this._http.get(this.id);
    });
    this.startEndSubscription = this.service.params$.subscribe(resp => {
      this.startAdd = {hour: resp.horaInicio, minute: resp.minutoInicio};
      this.endAdd = {hour: resp.horaFin, minute: resp.minutoFin};
    });
  }

  /* START - Eventos para DRAG y RESZE */

  handleEvent(action: string, event: CalendarEvent, newStart: Date, newEnd: Date): void {
    this.editAsignatura(event, newStart, newEnd);
  }

  eventTimesChanged({
                      event,
                      newStart,
                      newEnd
                    }: CalendarEventTimesChangedEvent): void {
    this.asignaturasMostrar = this.asignaturasMostrar.map(iEvent => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event, newStart, newEnd);
  }

  private editAsignatura(event: CalendarEvent, newStart: Date, newEnd: Date) {
    const indexClaseModificada = this.aula.findIndex(clase => clase.id === event.id);
    const start = this.datepipe.transform(newStart, 'yyyy-MM-ddTHH:mm:ss');
    const end = this.datepipe.transform(newEnd, 'yyyy-MM-ddTHH:mm:ss');

    this.asignaturaAdd = {
      id: +event.id,
      profesor: this.profesores[this.profesores.findIndex(
        profesor => profesor.apellidos === this.aula[indexClaseModificada].apellidos)].id,
      dia: this.diaSemana(newStart),
      hora: 0,
      asignatura: this.asignaturas[this.asignaturas.findIndex(
        asignatura => asignatura.asignatura === this.aula[indexClaseModificada].asignatura)].id,
      aula: +this.idCurso,
      start: this.datepipe.transform(new Date(start), 'yyyy-MM-ddTHH:mm:ss'),
      end: this.datepipe.transform(new Date(end), 'yyyy-MM-ddTHH:mm:ss'),
      color: event.color['primary']
    };
    console.log(this.asignaturaAdd);

    this._http.comprobarOcupado(0, this.asignaturaAdd.dia, this.asignaturaAdd.profesor, this.asignaturaAdd.start, this.asignaturaAdd.end);
  }

  /* END - Eventos para DRAG y RESZE */

  mostrarClasesEnCalendario() {
    this.asignaturasMostrar = [];
    const principioSemanaActual = addDays(startOfWeek(new Date()), 1);
    console.log(principioSemanaActual);
    this.aula.forEach(clase => {
      clase = this.actualizarFechaParaMostrar(clase, principioSemanaActual);
      this.asignaturasMostrar = [
          ...this.asignaturasMostrar, {
            id: clase.id,
            start: new Date(clase.start),
            end: new Date(clase.fin),
            title: this.asignaturaFormatter(clase),
            color: colors[colors.findIndex(color => color.primary === clase.color)],
            actions: this.actions,
            allDay: false,
            // Al hacerle resize existe el problema de que está comprobando sobre una clase que ya existe por lo que l profesor, realmente,
            // está ocupado. Futura implementación.
            // resizable: {
            //   beforeStart: true,
            //   afterEnd: true
            // },
            // draggable: true
          }
        ];

    });
    console.log(this.asignaturasMostrar);
  }

  asignaturaFormatter(clase: any) {
    return '<strong>' + clase.nombre + ' ' + clase.apellidos + '</strong><br/>' + clase.asignatura;
  }

  actualizarFechaParaMostrar(clase: any, principioSemanaActual: any) {
    switch (true) {
      case isMonday(clase.start): {
        console.log(this.setDiaEnSemanaActual(clase, principioSemanaActual, 0))
        return this.setDiaEnSemanaActual(clase, principioSemanaActual, 0);
        break;
      }
      case isTuesday(clase.start): {
        console.log(this.setDiaEnSemanaActual(clase, principioSemanaActual, 0))
        return this.setDiaEnSemanaActual(clase, principioSemanaActual, 1);
        break;
      }
      case isWednesday(clase.start): {
        return this.setDiaEnSemanaActual(clase, principioSemanaActual, 2);
        break;
      }
      case isThursday(clase.start): {
        return this.setDiaEnSemanaActual(clase, principioSemanaActual, 3);
        break;
      }
      case isFriday(clase.start): {
        return this.setDiaEnSemanaActual(clase, principioSemanaActual, 4);
        break;
      }
      default: {
        return clase;
      }
    }
  }

  setDiaEnSemanaActual(clase: any, principioSemanaActual: any, amount: number) {
    const anyo = addDays(principioSemanaActual, amount).getFullYear();
    const mes = addDays(principioSemanaActual, amount).getMonth();
    const dia = addDays(principioSemanaActual, amount).getDate();
    clase.start = new Date(clase.start).setFullYear(anyo, mes, dia);
    clase.fin = new Date(clase.fin).setFullYear(anyo, mes, dia);
    return clase;
  }

  addAsignatura() {
    const finalSemanaActual = new Date(endOfWeek(new Date()).setDate(endOfWeek(new Date()).getDate() - this.diaSemanaSelectedAdd));
    let start = finalSemanaActual.setHours(this.startAdd.hour);
    start = finalSemanaActual.setMinutes(this.startAdd.minute);
    start = finalSemanaActual.setSeconds(0);

    let end = finalSemanaActual.setHours(this.endAdd.hour);
    end = finalSemanaActual.setMinutes(this.endAdd.minute);
    end = finalSemanaActual.setSeconds(0);

    this.asignaturaAdd = {
      id: 0,
      profesor: this.idProfesorAdd,
      dia: this.diaSemana(new Date(start)),
      hora: 0,
      asignatura: this.idAsignaturaAdd,
      aula: +this.idCurso,
      start: this.datepipe.transform(new Date(start), 'yyyy-MM-ddTHH:mm:ss'),
      end: this.datepipe.transform(new Date(end), 'yyyy-MM-ddTHH:mm:ss'),
      color: this.colorAdd
    };
    this._http.comprobarOcupado(0, this.asignaturaAdd.dia, this.asignaturaAdd.profesor, this.asignaturaAdd.start, this.asignaturaAdd.end);
  }

  diaSemana(date: any) {
    switch (true) {
      case isMonday(date): {
        return this.dias[0];
        break;
      }
      case isTuesday(date): {
        return this.dias[1];
        break;
      }
      case isWednesday(date): {
        return this.dias[2];
        break;
      }
      case isThursday(date): {
        return this.dias[3];
        break;
      }
      case isFriday(date): {
        return this.dias[4];
        break;
      }
      default: {
        return '';
      }
    }
  }

  private removeClaseMostrarYAula(id: string | number) {
    this.aula.splice(this.aula.findIndex(aula => aula.id === id), 1);
    this.mostrarClasesEnCalendario();
  }

  /* Controla la vista de los colores */

  selectColor(color: any) {
    console.log(color);
    this.colorM = color.name
    this.colorAdd = color.primary;
    this.backgroundColorAdd(color.name)

  }

  backgroundColorAdd(color: any) {
    switch (color) {
      case 'dark': {
        if (this.colorM === 'dark') {
          return 'darkBorderSelected';
        } else {
          return 'dark';
        }
      }

      case 'blue': {
        if (this.colorM === 'blue') {
          return 'blueBorderSelected';
        } else {
          return 'blue';
        }
      }

      case 'lightBlue': {
        if (this.colorM === 'lightBlue') {
          return 'lightBlueBorderSelected';
        } else {
          return 'lightBlue';
        }
      }

      case 'greenOcean': {
        if (this.colorM === 'greenOcean') {
          return 'greenOceanBorderSelected';
        } else {
          return 'greenOcean';
        }
      }

      case 'green': {
        if (this.colorM === 'green') {
          return 'greenBorderSelected';
        } else {
          return 'green';
        }
      }

      case 'yellow': {
        if (this.colorM === 'yellow') {
          return 'yellowBorderSelected';
        } else {
          return 'yellow';
        }
      }

      case 'orange': {
        if (this.colorM === 'orange') {
          return 'orangeBorderSelected';
        } else {
          return 'orange';
        }
      }

      case 'red': {
        if (this.colorM === 'red') {
          return 'redBorderSelected';
        } else {
          return 'red';
        }
      }

      case 'pink': {
        if (this.colorM === 'pink') {
          return 'pinkBorderSelected';
        } else {
          return 'pink';
        }
      }

      case 'purple': {
        if (this.colorM === 'purple') {
          return 'purpleBorderSelected';
        } else {
          return 'purple';
        }
      }
    }
  }

  ngOnDestroy() {
    this.profesoresSubscription.unsubscribe();
    this.asignaturasSubscription.unsubscribe();
    this.aulaSbuscription.unsubscribe();
    this.parametroSubscription.unsubscribe();
    this.cursoSubscription.unsubscribe();
    this.cursoAddSubscription.unsubscribe();
    this.cursoOcupadoSubscription.unsubscribe();
  }
}
