<div style="display: block; padding-top: 20px">
  <div style="text-align: center">
    <p>{{titulo}}- Total: {{datos['aforo'] }}</p>
  </div>
  <canvas baseChart
          [data]="pieChartData"
          [labels]="pieChartLabels"
          [chartType]="pieChartType"
          [options]="pieChartOptions"
          [colors]="pieChartColors"
          [legend]="pieChartLegend">

  </canvas>

</div>
