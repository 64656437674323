import {Component, ViewChild, OnDestroy, OnInit, ElementRef, NgZone} from '@angular/core';
import {CentroService} from '../services/centro.service';
import {Subscription} from 'rxjs/Subscription';
import {NgForm} from '@angular/forms';
import {Router} from '@angular/router';
import {MapsAPILoader} from '@agm/core';

@Component({
  selector: 'app-add-empresa',
  templateUrl: './add.component.html',
  styleUrls: ['../centro.component.scss']

})

export class AddComponent implements OnInit, OnDestroy {

  @ViewChild('f') loginForm: NgForm;
  searchElementRef: ElementRef;

  private provinciaSubscription: Subscription = null;
  private muncipioSubscription: Subscription = null;
  private centroSubscription: Subscription = null;

  provincias: any = [];
  municipios: any = [];
  provincia: any;
  municipio: any;
  centro: any = {};
  active: boolean;
  title = 'Progresa Mapa';
  lat = 0;
  long = 0;
  zoom: any;
  address = '';
  private geoCoder;

  @ViewChild('search', { static: false }) set content(content: ElementRef) {
    if (content) {
      this.searchElementRef = content;
    }
  }

  constructor(private _centro: CentroService, private _router: Router, private mapsAPILoader: MapsAPILoader,
              private ngZone: NgZone) {
    this._centro.getProvincias();
  }


  ngOnInit(): void {
    this._centro.getCentro().subscribe(res => {
      this.centro = res['id'];
      console.log(this.centro);
      if (this.centro.hasOwnProperty('id') && this.centro.id != null) {
        document.getElementById('longitud').hidden = false;
        document.getElementById('latitud').hidden = false;
        document.getElementById('mapa').hidden = false;
        this.mapsAPILoader.load().then(() => {
          this.setCurrentLocation();
        });
        this.loadPlaces();
      }
    });
    this.provinciaSubscription = this._centro.provincias$.subscribe(data => {
      this.provincias = data;
    });
    this.muncipioSubscription = this._centro.municipios$.subscribe(data => {
      this.municipios = data;
    });
  }


  ngOnDestroy(): void {
    if (this.provinciaSubscription !== null) {
      this.provinciaSubscription.unsubscribe();
    }
    if (this.muncipioSubscription !== null) {
      this.muncipioSubscription.unsubscribe();
    }
    if (this.centroSubscription !== null) {
      this.centroSubscription.unsubscribe();
    }

  }

  onSubmit() {
    if (this.loginForm.valid) {
      this.loginForm.value.longitud = this.long;
      this.loginForm.value.latitud = this.lat;
      console.log(this.loginForm.value)
      this._centro.add(this.loginForm.value);
      this._router.navigate(['/admin/centro/lista']);

    }


  }

  getMunicipios(id: number) {
    this._centro.getMunicipios(id);
  }

  // Get Current Location Coordinates
  setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (!this.centro.hasOwnProperty('longitud') && !this.centro.hasOwnProperty('latitud') ) {
          this.lat = position.coords.latitude;
          this.long = position.coords.longitude;
          this.zoom = 15;
          this.getAddress(this.lat, this.long);
        } else {
          this.lat = this.centro.latitud;
          this.long = this.centro.longitud;
          this.zoom = 15;
          this.getAddress(this.lat, this.long);
        }
      });
    }
  }

// Load Places Autocomplete
  loadPlaces() {
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {types: ['address']});
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          // set latitude, longitude and zoom
          this.lat = place.geometry.location.lat();
          this.long = place.geometry.location.lng();
          this.zoom = 15;
          this.address = place.formatted_address;
        });
      });
    });

  }

// Get Address
  getAddress(latitude, longitude) {
    this.geoCoder.geocode({'location': {lat: latitude, lng: longitude}}, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 15;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }


  markerDragEnd($event: any) {
     this.lat = $event.latLng.lat();
     this.long = $event.latLng.lng();
    this.getAddress(this.lat, this.long);
  }

  activar() {
    this.active = !this.active;
  }
}


