<h2 mat-dialog-title class="text-center">Añadir referido</h2>
<mat-dialog-content class="text-center">
  <div [formGroup]="referidopForm"   style="padding: 16px">
    <div class="row">
      <div class="col-12">
        <label class="form-label">Referido</label>
        <input type="text" class="form-control" formControlName="referido" >
      </div>

    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions  >
  <div class="row">
    <div class="col-1"></div>
    <div class="col-10">
      <button mat-button matDialogClose  class="aceptar float-left" >Cerrar</button>
      <button mat-button [mat-dialog-close]="referidopForm.value.referido" class="aceptar" [disabled]="!referidopForm.valid" >Guardar</button>
    </div>
    <div class="col-1"></div>
  </div>
</mat-dialog-actions>
