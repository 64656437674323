import {Component, ViewChild, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';
import {CentroService} from '../../admin-centro/services/centro.service';
import {Router, ActivatedRoute} from '@angular/router';
import {LoginService} from '../../../pages/services/login.service';
import swal from 'sweetalert2';

import {UploadService} from '../../../services/upload.service';
import {NgForm} from '@angular/forms';
import {Datos} from '../../../../modelos/datos.modelo';
import {UsersService} from '../../admin-users/services/users.service';


@Component({
  selector: 'app-datos-padre',
  templateUrl: './datos-padre.component.html',
  styleUrls: ['../padre.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DatosPadreComponent implements OnInit, OnDestroy {
  public provinciasSubscription: Subscription = null;
  public municipiosSubscription: Subscription = null;
  public datoSubscription: Subscription = null;
  public active: boolean;
  contacto = {
    dato: '',
    id: '',
    tipo: 2,
  };
  update = true;
  disabled = false;

  public id: number;
  public type: string;
  public provincias: any[] = [];
  public municipios: any[] = [];
  public provincia: number;
  public municipio: number;
  public tipo: string;
  public filesToUpload: Array<File>;
  isAlumno: boolean;
  cursos: any[] = [];
  @ViewChild('f') loginForm: NgForm;
  public dato: Datos;

  constructor (
    private _route: ActivatedRoute,
    private _centro: CentroService,
    private _upload: UploadService,
    private _login: LoginService,
    private _router: Router,
    private _user: UsersService
  ) {
    this.dato = new Datos(0,
      '', '', '', '', new Date(), '', '', [], 0, 0, '', [], [], [], false, false,'' , '', '', '', {nivel: '', id: ''}, '' , '', '', '');
    this.id = this._route.snapshot.parent.params['id'];
    this._centro.getProvincias();
    this._user.getDato(this.id);
  }

  ngOnInit () {
    this.provinciasSubscription = this._centro.provincias$.subscribe(res => {
      this.provincias = res;
    });
    this.datoSubscription = this._user.dato$.subscribe(res => {
      this.dato = new Datos(res.id_provincia, res.provincia, res.nombre, res.apellidos, res.direccion, res.nacimiento, res.dni, res.municipio, res.iban, res.id, res.id_municipio, res.img, res.tutores, res.contactos, res.titulos, res.permisoIamgen, res.pemisoNotas, res.provinciaNaciemto, res.Nacionalidad, res.ciudadNacimiento, res.paisEstudiosPrevios, res.curso, res.codPostal, res.observaciones, res.sip, res.observacionesMatricula);

      if (res.contactos !== null) {
        this.contacto = res.contactos[0];
        console.log(this.contacto);
      } else {
        this.update = false
      }
      console.log(this.update)
    });

  }

  ngOnDestroy () {
    if (this.provinciasSubscription != null) {
      this.provinciasSubscription.unsubscribe();
    }
  }

  onSubmit () {
    if (this.loginForm.valid) {
      this.active = false;
      this.disabled = true;

      if ( this.update === true ) {
        console.log("----------------------------HAGO UPDATE------------");
        this._user.editContacto(this.contacto.id, this.contacto.dato).subscribe( (res) => {
          console.log(res);
          if (res['code'] === 200) {
            this._user.edit(this.loginForm.value);
            swal.fire('ACTUALIZADO', 'Tutor actualizado correctamente', 'success');
          }
        })
      } else {
        console.log("----------------------------GUARDO------------");

        this._user.addContacto(this.contacto.dato, 2, this.id);
        this._user.edit(this.loginForm.value);
        swal.fire('GUARDADO', 'Tutor guardado correctamente', 'success');

      }

    }
  }

  getMunicipios () {
    this._centro.getMunicipios(this.provincia);
    this.municipiosSubscription = this._centro.municipios$.subscribe(res => {
      this.municipios = res;
    });
  }

  addPhoto (type: string) {
    this._upload
      .makeFileRequest(this.id, this.filesToUpload, 'imagen', type)
      .then(
        (result: any) => {
          if (type === 'user') {
            const identity = this._login.getIdentity();
            identity.img = 'images/users/' + result.id;
            localStorage.setItem('identity', JSON.stringify(identity));
            this.dato['img'] = identity.img;
          } else {
            this.dato['titulos'] = result.img;
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  fileChangeEvent (fileinput: any, type: string) {
    this.filesToUpload = <Array<File>>fileinput.target.files;
    this.addPhoto(type);
  }


}
