 <!--Simple Table Starts-->
<section id="simple">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Administracion asignaturas</h4>
                </div>
                <div class="card-body">
                    <div class="card-block">
<!--                        <ng2-smart-table [settings]="settings" [source]="source"  (deleteConfirm)="onDeleteConfirm($event)" (editConfirm)="onSaveConfirm($event, e)"></ng2-smart-table>-->
                      <ng2-smart-table [settings]="settings" [source]="source"  (deleteConfirm)="onDeleteConfirm($event)" (editConfirm)="onSaveConfirm($event)"></ng2-smart-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
