import {Component, Input, ViewChild, OnDestroy, OnInit} from '@angular/core';
import {ProfileService} from '../services/profile.service';
import {Subscriber} from 'rxjs/Subscriber';
import {Subscription} from 'rxjs/Subscription';
import {NgForm} from '@angular/forms';
import {CentroService} from '../../admin-centro/services/centro.service';
import {LoginService} from '../../../pages/services/login.service';


@Component({
  selector: 'app-profile-contacto',
  templateUrl: './profile-contacto.component.html'
})

export class ProfileContactoComponent implements OnDestroy, OnInit {

  public rows = [];
  id: number;
  user: number;
  @ViewChild('f') loginForm: NgForm;
  public contactoSubscription: Subscription = null;
  public tipoSubscription: Subscription = null;
  public tipos: any[] = [];
  tipo: any;
  valor: any;

  contacto: string;
  loadingIndicator = true;
  reorderable = true;

  constructor (private _user: ProfileService, private _login: LoginService) {
    this.id = this._login.getIdentity().id;
    this.user = this._login.getIdentity().id;

    setTimeout(() => {
      this.loadingIndicator = false;
    }, 1500);
    this._user.getContactos(this.id);
    this._user.getTipos();
  }

  ngOnInit (): void {
    this.contactoSubscription = this._user.contactos$.subscribe(data => {
      this.rows = data;
    });
    this.tipoSubscription = this._user.tipos$.subscribe(data => {
      this.tipos = data;
    });


  }

  ngOnDestroy (): void {
    this.contactoSubscription.unsubscribe();
    this.tipoSubscription.unsubscribe();
  }


  delete (id: any, position: number) {
    this._user.deleteContacto(id);
    this.rows.slice(position, 1);
  }

  onSubmit () {

    if (this.loginForm.valid) {
      this._user.addContacto(this.loginForm.value);
    }

    this._user.contactos$.subscribe(data => {
      this.rows = data.contactos;
    });
  }

}


