import { Injectable } from '@angular/core';
import {Router, ActivatedRoute, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LoginService } from '../pages/services/login.service';
import { Observable } from 'rxjs/Observable';


@Injectable()
export class AuthGuardMaestroService implements CanActivate {
  constructor(
    private router: Router,
    private _login: LoginService,
    private route: ActivatedRoute
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._login.authGuardMaestro().then(res => {
      if (!res) {
        this.router.navigate(['/public/login'], { relativeTo: this.route });
        return false;
      } else {
        return true;
      }
    });
  }
}
