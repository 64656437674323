import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import {InboxModule} from '../../admin/inbox/inbox.module';
import {TaskboardModule} from '../../../taskboard/taskboard.module';
import {PipesModule} from '../../../pipes.module';
import {MatTabsModule} from '@angular/material/tabs';
import {Ng2SmartTableModule} from 'ng2-smart-table';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {QuillModule} from 'ngx-quill';

// BASE
import {FooterComponent} from './footer/footer.component';
import {NavbarComponent} from './navbar/navbar.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {CustomizerComponent} from './customizer/customizer.component';
import {MainComponent} from './main/main.component';
import {MaestroComponent} from './maestro.component';

// GUARD
import {AuthGuardMaestroService} from '../../services/auth-guard-maestro.service';

// ROUTING
import {HttpClientModule} from '@angular/common/http';
import {MaestroRoutingModule} from './maestro.routing';

// MAESTRO
import {ClasesMComponent} from '../admin-clases/clasesM.component';
import {ClaseMComponent} from '../admin-clases/componentes/clases.component';
import {AlumnosMComponent} from '../admin-clases/componentes/alumnosM.component';
import {AsignaturasMComponent} from '../admin-clases/componentes/asignaturasM.component';
import {InfoAsignaturaMComponent} from '../admin-clases/componentes/info-asignaturaM.component';


import {GenerarExamenMComponent} from '../admin-clases/componentes/generar-examen.component';
import {ListaExamenesComponent} from '../admin-clases/componentes/lista-examenes.component';
import {ListaNotasMComponent} from '../admin-clases/componentes/lista-notasM.component';
import {ListaFilesExamenesComponent} from '../admin-clases/componentes/lista-files-examenes.component';
import {AlumnosExamenComponent} from '../admin-clases/componentes/alumnos-examen.component';
import {AlumnosComponent} from '../admin-alumnos/alumnos.component';
import {DatosAlumnoComponent} from '../admin-alumnos/componentes/datos-alumno.component';

// TAREAS
import {TaskAlumnoComponent} from '../admin-alumnos/componentes/task-alumno.component';

// ASISTENCIAS
import {AsistenciasComponent} from '../admin-asistencias/asistencias.component';
import {AsistenciasService} from '../admin-asistencias/services/asistencias.service';
import {VistaAgendaComponent} from '../admin-asistencias/componentes/vista-agenda.component';
import {ListaAlumnosAComponent} from '../admin-asistencias/componentes/lista-alumnos.component';
import {CheckAsistenciasComponent} from '../admin-asistencias/componentes/check-asistencias.component';

// EXAMENES
import {ExamenesAlumnoComponent} from '../admin-alumnos/componentes/examenes-alumno.component';
import {AdminNotasfinalComponent} from '../admin-notasfinal/admin-notasfinal.component';

// SERVICES
import {ClasesMService} from '../admin-clases/services/clasesM.service';
import {AlumnosService} from '../admin-alumnos/services/alumnos.service';
import {MaestroInboxService} from '../inbox/services/maestro-inbox.service';

import { MaestroInboxComponent } from '../inbox/maestro-inbox.component';

import {AgendaModule} from '../../componentes/agenda/agenda.module';
import {PadresAlumnoComponent} from '../admin-alumnos/componentes/padres-alumno.component';
import {GraficasModule} from '../../componentes/graficas/graficas.module';
import {AdminExamenesModule} from '../admin-examenes/admin-examenes.module';
import {MatButtonModule} from '@angular/material/button';
import {MatSidenavModule} from '@angular/material/sidenav';
import {TablonComponent} from '../../componentes/tablon/tablon.component';
import {NotaFinalAlumnosComponent} from '../admin-aulumnos/componentes/nota-final-alumnos/nota-final-alumnos.component';
import {MatCardModule} from '@angular/material/card';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatDividerModule} from '@angular/material/divider';
import { ProgressBarColorDirective} from '../admin-clases/progres-bar.directive';
import {MatIconModule} from '@angular/material/icon';
@NgModule({
  imports: [

    CommonModule,
    NgbModule,
    MaestroRoutingModule,
    HttpClientModule,
    FormsModule,
    NgxDatatableModule,
    InboxModule,
    TaskboardModule,
    PipesModule,
    MatTabsModule,
    Ng2SmartTableModule,
    BrowserAnimationsModule,
    QuillModule,
    AgendaModule,
    SweetAlert2Module.forRoot(),
    ReactiveFormsModule,
    GraficasModule,
    AdminExamenesModule,
    MatSidenavModule,
    MatButtonModule,
    MatCardModule,
    MatProgressBarModule,
    MatDividerModule,
    MatIconModule
  ],
  declarations: [
    ProgressBarColorDirective,
    FooterComponent,
    NavbarComponent,
    MainComponent,
    MaestroComponent,
    ClasesMComponent,
    ClaseMComponent,
    CustomizerComponent,
    SidebarComponent,
    AlumnosMComponent,
    AsignaturasMComponent,
    InfoAsignaturaMComponent,
    AsistenciasComponent,
    GenerarExamenMComponent,
    ListaExamenesComponent,
    ListaNotasMComponent,
    ListaFilesExamenesComponent,
    VistaAgendaComponent,
    ListaAlumnosAComponent,
    CheckAsistenciasComponent,
    AlumnosComponent,
    DatosAlumnoComponent,
    AlumnosExamenComponent,
    TaskAlumnoComponent,
    ExamenesAlumnoComponent,
    MaestroInboxComponent,
    PadresAlumnoComponent,
    AdminNotasfinalComponent,
    NotaFinalAlumnosComponent

  ],
  providers: [
    AuthGuardMaestroService,
    ClasesMService,
    AsistenciasService,
    AlumnosService,
    MaestroInboxService
  ],

  exports: [
    ProgressBarColorDirective
  ]

})
export class MaestroModule {
}
