<div class="col-md-10">
  <div class="card">
    <div class="card-body">
      <div class="px-3">
        <form class="form form-horizontal striped-rows form-bordered" novalidate="" (ngSubmit)="onSubmit()" #f="ngForm">

          <div class="form-group row">
            <label class="col-md-3 label-control">Nombre</label>
            <div class="col-md-5">
              <input [(ngModel)]='horarios.nombre' required type="text" class="form-control width-input" name="nombre"
                     placeholder="Nombre del horario" [disabled]="!active">
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-3 label-control">Hora inicio</label>
            <div class="col-md-5">
              <input [(ngModel)]='hora_inicio' required type="text" class="form-control width-input" name="inicio"
                     placeholder="Hora inicio" [disabled]="!active">
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-3 label-control">Hora fin</label>
            <div class="col-md-5">
              <input [(ngModel)]='hora_fin' required type="text" class="form-control width-input" name="fin"
                     placeholder="Hora fin" [disabled]="!active">
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-3 label-control">Tramos</label>
            <div class="col-md-5">
              <input [(ngModel)]='horarios.tramo' required type="text" class="form-control width-input" name="tramo"
                     placeholder="Tramo en minutos" [disabled]="!active">
            </div>
          </div>

          <div class="form-actions">
            <button type="button" (click)="active=!active" class="btn btn-raised btn-success mr-1" [disabled]="active">
              <i class="ft-edit-2"></i> Editar
            </button>
            <button type="submit" class="btn btn-raised btn-primary" [disabled]="!active">
              <i class="ft-save"></i> Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
