<div class="col-sm-12" *ngIf='rows.length > 0 '>
    <table class="table table-striped">
        <thead>
            <tr>
                <th scope="col">Enseñanza</th>
                <th scope="col">Familia</th>
                <th scope="col">Modulo</th>
                <th scope="col">Acrónimo</th>
                <th scope="col">Acciones</th>
            </tr>
        </thead>
        <tbody class="table ">
            <tr *ngFor="let row  of rows; let i = index">
                <td>{{row.familia}}</td>
                <td>{{row.modulo}}</td>
                <td>{{row.nivel}}</td>
                <td>{{row.acronimo}}</td>
                <td>
                    <ul class="list-inline">
                        <li class="list-inline-item danger p-0">
                            <i class="ft-x font-medium-3 mr-2" (click)="delete(row.id, i)"></i>
                        </li>
                        <li class="success p-0 list-inline-item" [routerLink]="['/admin/centro/asignaturas',row.id]">
                            <i class="ft-eye font-medium-3 mr-2"></i>
                        </li>
                    </ul>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<!-- <app-cursos-asignaturas [asignaturas]="asignaturas" (table)="open(event)" *ngIf="table"></app-cursos-asignaturas> -->
