// Smart DataTable
export const settings = {
  columns: {
    id: {
      title: 'ID',
      filter: false,
      editable: false
    },

    examen: {
      title: 'Examen',
      filter: false,
      editable: false
     },

   url: {
      title: 'Url',
      filter: false,
      editable: false
    },

    fecha: {
      title: 'Entregado',
      filter: false,
      editable: false
    },






  },
  attr: {
    class: 'table table-responsive'
  },
  edit:{
    editButtonContent: '<i class="ft-edit success font-medium-1 mr-2"></i>',
    confirmSave: true
  },
  delete:{
    deleteButtonContent: '<i class="ft-x danger font-medium-1 mr-2"></i>',
    confirmDelete: true
  },
  add:{
    addButtonContent: '<i class="ft-upload success font-medium-1 mr-2"></i>',
    confirmAdd: false
  },






  actions: {
    position: 'right',
    title: 'Acciones',
    add: false,
    delete: true,
    edit: false,
    custom: [{ name: 'ver', title: `<i  class="ft-file info font-medium-1 mr-2"></i>`},






  ],

}





};



