import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Subject} from 'rxjs/Subject';
import {Router} from '@angular/router';
import {Rutas} from '../../../../utiles/Rutas';
import {LoginService} from '../../../pages/services/login.service';
import {Observable, Observer} from 'rxjs';

@Injectable()
export class CentroService {
  private provinciasSource = new Subject<any>();
  public provincias$ = this.provinciasSource.asObservable();

  private municipiosSource = new Subject<any>();
  public municipios$ = this.municipiosSource.asObservable();

  private centroSource = new Subject<any>();
  public centro$ = this.centroSource.asObservable();

  private eventoCursoSource = new Subject<any>();
  public eventoCurso$ = this.eventoCursoSource.asObservable();

  public addEventoCursoSource = new Subject<any>();
  public addEventoCurso$ = this.addEventoCursoSource.asObservable();

  constructor(private http: HttpClient, private router: Router, private _login: LoginService) {

  }

  getEventoCurso() {
    this.http.get(Rutas + 'evento/curso?token=' + localStorage.getItem('token'))
      .subscribe(data => {
        if (data['code'] === 200) {
          this.eventoCursoSource.next(data['id']);
        } else {
          return false;
        }
      });
  }

  addEventoCursos(evento: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {evento};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'evento/add/curso', params, {headers: headers}).subscribe(datas => {
      if (datas['code'] === 200) {
        this.addEventoCursoSource.next(datas['id']);
      }
    }, error => {
      console.log(error);
    });
  }

  add(centro: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {centro};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    this.http.post(Rutas + 'add/centro', params, {headers: headers}).subscribe(datas => {
      if (datas['code'] === 200) {
        }
    }, error => {
      console.log(error);
    });
  }

  getProvincias() {
    this.http.get(Rutas + 'provincias?token=' + localStorage.getItem('token')).subscribe(data => {
      if (data['code'] === 200) {
        this.provinciasSource.next(data['id']);
      } else {
        return false;
      }
    });
  }

  getMunicipios(id: number) {
    this.http.get(Rutas + 'municipios?id=' + id + '&token=' + localStorage.getItem('token'))
      .subscribe(data => {
        if (data['code'] === 200) {
          this.municipiosSource.next(data['id']);
        } else {
          return false;
        }
      });
  }

  getCentro() {
    const id = this._login.getIdentity().centro.id;
    return  this.http.get(Rutas + 'centro?token=' + localStorage.getItem('token') + '&id=' + id);
  }

  deleteEventoCurso(id: number) {
    this.http.get(Rutas + 'calendario/delete/curso?token=' + localStorage.getItem('token') + '&id=' + id).subscribe(data => {
      if (data['code'] === 200) {
        return data['code'];
      } else {
        return false;
      }
    });
  }
}
