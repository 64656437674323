import {Component} from '@angular/core';
@Component({
  selector: 'app-menu-padre',
  templateUrl: './menu-padre.component.html'

})
export class MenuPadreComponent  {
  navLinks: any[] = [];
  constructor() {
    this.navLinks = [
      {path: 'asistencias', label: 'Asistencias'},
      {path: 'tareas', label: 'Tareas'},
      {path: 'examenes', label: 'Examenes'},
      {path: 'horarios', label: 'Horarios'}
      ];
  }
}
