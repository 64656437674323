<!--<p>tablon works!</p>
<i class="fa fa-plus-circle fa-lg success" (click)="abrirInput()">
  <input id="input-file" type="file"  accept="application/pdf, image/*" name="input_file" style="display: none;"
         (change)="file($event)"/>
</i>



<div class="flex-wrap margin">
  <div class="flex-wrap">
    <div class="margin">
      Single
      <div class="flex margin" style="border:1px solid #555;border-radius: 5px;">
        <input
          ngfSelect
          type       = "file"

          (change) = "file($event)"
        />
        <button  type="button" >clear</button>
      </div>
    </div>
  </div>
</div>-->

<!--<section >-->
<!--  <mat-card style="width: 100%">-->
<!--    <mat-card-header>-->
<!--      <mat-card-title  class="pl-5">Tablón de anuncios-->
<!--        <button mat-icon-button class="float-right mr-5" (click)="openModal()" >-->
<!--          Nuevo <i class="icon-plus"></i>-->
<!--        </button>-->

<!--      </mat-card-title>-->

<!--    </mat-card-header>-->
<!--    <mat-divider></mat-divider>-->

<!--    <mat-card-content>-->


<!--      <div class="contenedor" *ngFor="let mensaje of mensajes">-->
<!--        <div class="cabecera">-->
<!--          <div class="titulo">-->
<!--            <div class="t1 logo-img">-->
<!--              <img width="25" src="assets/img/centro/logo.png">-->
<!--            </div>-->
<!--            <div class="t2">-->
<!--              {{mensaje.titulo}}-->
<!--            </div>-->
<!--            <div class="t3">-->
<!--              {{mensaje.fecha}}-->
<!--            </div>-->

<!--          </div>-->

<!--        </div>-->
<!--        <div class="contenido" [innerHTML]="mensaje.mensaje">-->

<!--        </div>-->
<!--        <div class="archivo">-->
<!--          <button mat-icon-button class="ml-3" (click)="openFile(mensaje.adjunto)"  *ngIf="mensaje.adjunto != null">-->
<!--             <i class="icon-docs" style="font-size: large; font-weight: bold; "></i>-->
<!--          </button>-->
<!--          <button mat-icon-button class="ml-3" (click)="deleteMensaje(mensaje.id)"  >-->
<!--            <i class="icon-trash" style="font-size: large; font-weight: bold; "></i>-->
<!--          </button>-->
<!--          <button mat-icon-button class="ml-3" (click)="deleteMensaje(mensaje.id)"  >-->
<!--            <i class="icon-pencil" style="font-size: large; font-weight: bold; "></i>-->
<!--          </button>-->

<!--        </div>-->

<!--      </div>-->



<!--    </mat-card-content>-->

<!--  </mat-card>-->
<!--</section>-->

<section  >
  <mat-card style="width: 100%">
    <mat-card-header class="header">
      <mat-card-title  class="pl-5 titles">Tablón de anuncios
        <button mat-icon-button class="float-right mr-5" (click)="openModal()" >
          Nuevo <i class="icon-plus"></i>
        </button>

      </mat-card-title>

    </mat-card-header>
    <mat-divider></mat-divider>

    <mat-card-content>

      <div class="row">
        <div class="col-xl-6  col-lg-6  col-xxl-6 mt-3" *ngFor="let mensaje of mensajes">
          <mat-card class="example-card" >
            <mat-card-header>
              <div mat-card-avatar style="background-image: url('assets/img/centro/logo.png');
  background-size: cover;"></div>
              <mat-card-title>{{mensaje.titulo}}</mat-card-title>
              <mat-card-subtitle>{{mensaje.fecha.date | date: 'dd/MM/yyyy'}}</mat-card-subtitle>
            </mat-card-header>
            <img mat-card-image src="{{ rutaImg + mensaje.imgCabecera}}" style="background-color: #dedddd">
            <mat-card-content>
              <div class="text-oneline" style="height: 50px" [innerHTML]="mensaje.mensaje">
              </div>
              <div class="mt-3" *ngIf="mensaje.adjunto">
                <a href="{{rutaImg + mensaje.adjunto}}" target="_blank"> Ver Adjunto {{mensaje.adjunto | slice:-12}}</a>
              </div>
              <button type="button" class="btn btn-link" style="color: black;" (click)="openModalDetalle(mensaje)">..ver más</button>

            </mat-card-content>
            <mat-card-actions>
              <button mat-button (click)="updateAnuncio(mensaje)">MODIFICAR</button>
              <button mat-button (click)="deleteMensaje(mensaje.id)" >ELIMINAR</button>
            </mat-card-actions>
          </mat-card>
        </div>
      </div>
    </mat-card-content>

  </mat-card>
</section>



<ng-template #mensaje class="text-center">
  <h2 mat-dialog-title class="text-center">Nuevo anuncio</h2>
  <mat-dialog-content class="text-center">
    <div [formGroup]="mensajeForm"   style="padding: 16px">
      <div class="row">
        <div class="col-12 ">
          <label class="form-label">Título</label>
          <input type="text" class="form-control" formControlName="titulo" >
        </div>

      </div>
      <div class="row">
        <div class="col-6">
          <label class="form-check-label">Destinatarios</label>
          <div class="row">
            <div class="col-12">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" formControlName="todos"
                       [value]="destino.todos" (change)="print()">
                <label class="form-check-label" for="inlineCheckbox1">Todos</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="inlineCheckbox2" formControlName="alumnos"
                       [value]="destino.alumnos" (change)="print()">
                <label class="form-check-label" for="inlineCheckbox2">Alumnos</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="inlineCheckbox3" formControlName="padres"
                       [value]="destino.padres" (change)="print()">
                <label class="form-check-label" for="inlineCheckbox3">Padres</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="inlineCheckbox4" formControlName="profesores"
                       [value]="destino.profesores" (change)="print()">
                <label class="form-check-label" for="inlineCheckbox4">Profesores  |</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="inlineCheckbox5" formControlName="aulas"
                       [value]="destino.aulas" (change)="print()">
                <label class="form-check-label" for="inlineCheckbox5">Aulas</label>
              </div>

            </div>
          </div>

        </div>
        <div class="col-6">
          <div class="flex-wrap margin">
            <div class="flex-wrap">
              <div class="margin">
                Adjuntar archivo
                <div class="flex margin" style="border:1px solid #555;border-radius: 5px;">
                  <input
                    ngfSelect
                    type       = "file"

                    (change) = "file($event)"
                  />
                  <button  type="button" *ngIf="filesToUpload.length > 0" (click)="filesToUpload = []">borrar</button>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>

      <div class="row" *ngIf="showAulas">
        <div class="col-2">
          <label class="form-label">Aula</label>
          <select class="custom-select form-control"  formControlName="curso" >
            <option selected value="">Selecciona</option>
            <option *ngFor="let curso of cursos" [ngValue]="curso">{{curso.aula}}</option>
          </select>
        </div>

        <div class="col-2">
          <label class="form-label">Destinatarios</label>
          <select class="custom-select form-control"  formControlName="cursoDestinatarios" >
            <option selected value="">Selecciona</option>
            <option  [ngValue]="false">Alumnos</option>
            <option  [ngValue]="true">Alumnos y padres</option>
          </select>
        </div>
        <div class="col-2 botones">
          <button   class="aceptar btn btn-raised btn-primary m"  (click)="addCurso()" >
            Añadir
          </button>

        </div>
        <div class="col-6">
          <ul>
            <li *ngFor="let dest of cursosDest">
              <label>{{dest.aula}} - <label *ngIf="dest.destinatarios">Alumnos y padres</label>  <label *ngIf="!dest.destinatarios">Alumnos</label> </label>
            </li>
          </ul>
        </div>
      </div>



      <div class="row">
        <div class="col-12">
          <label class="form-label">Anuncio</label>
          <angular-editor formControlName="mensaje" [config]="config"></angular-editor>
        </div>

      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions  >
    <div class="row">
      <div class="col-1"></div>
      <div class="col-10">
        <button mat-button matDialogClose  class="aceptar float-left" >Cancelar</button>
        <button mat-button [mat-dialog-close]="true" class="aceptar"  [disabled]="!mensajeForm.valid">Enviar</button>
      </div>
      <div class="col-1"></div>
    </div>
  </mat-dialog-actions>
</ng-template>
