<section id="simple">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">Asistencias asignaturas </h5>
        </div>

        <table class="table table-striped tablaasistencias">
          <thead>
            <tr>
              <th class="">Asignatura</th>
              <th class="">Fecha</th>
              <th class="">Estado</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let asistencia of asistencias; let i = index">
              <td>{{asistencia.asignatura}}</td>
              <td> {{ asistencia.fecha}} </td>
              <td scope="row" class="esta" *ngIf="asistencia.estado; else asis">Presente</td>
              <ng-template #asis>
                <td scope="row" class="falta">Falto</td>
              </ng-template>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>