import {Component, OnDestroy} from '@angular/core';
import {CursosService} from '../services/cursos.service';
import {Subscriber} from 'rxjs/Subscriber';
import {Subscription} from 'rxjs/Subscription';
import {Router, ActivatedRoute} from '@angular/router';
import {LocalDataSource} from 'ng2-smart-table';
import * as tableData from '../../template/shared/smart-table-asignaturas';


@Component({
  selector: 'app-cursos-asignaturas',
  templateUrl: './cursos-asignaturas.component.html',
  styleUrls: ['../centro.component.scss']
})
export class CursosAsignaturasComponent implements OnDestroy {


  private asignaturas = [];

  asignaturaSubscription: Subscription = null;
  source: LocalDataSource;
  filterSource: LocalDataSource;
  alertSource: LocalDataSource;
  id: number;
  settings = tableData.settings;

  constructor(private _cursos: CursosService, private _route: ActivatedRoute) {
    this._route.params.subscribe(params => {
      this.id = params['id'];
      this._cursos.getAsignaturas(this.id);
      this.asignaturaSubscription = this._cursos.asignatura$.subscribe(data => {
        this.asignaturas = data;
        console.log(data);
        this.source = new LocalDataSource(this.asignaturas); // create the source

      });
    });
  }




  ngOnDestroy(): void {
    if (this.asignaturaSubscription !== null) {
      this.asignaturaSubscription.unsubscribe();
    }
  }


  //  For confirm action On Delete
  onDeleteConfirm(event) {
    console.log(event.data.id);
  }


  //  For confirm action On Save
  onSaveConfirm(event) {
   console.log(event.newData);
    this._cursos.updateAsignaturas(event.newData);
    this._cursos.getAsignaturas(this.id)

  }

}
