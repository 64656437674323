<h4 class="info-top"><i class="ft-user"></i> Titulos: {{dato?.nombre}} {{dato?.apellidos}} </h4>
<div class="row">
  <div class="col-lg-2 col-md-2 title">
    <label>Títulos:</label>
  </div>
  <div class="col-lg-4 col-md-6" style="margin-bottom: 1rem">
    <select class="form-control" (change)="agregar()" [(ngModel)]="titulo">
      <option value="null" selected>Seleccionar</option>
      <option value="TITULO DE BACHILLER">TÍTULO DE BACHILLER</option>
      <option value="PRUEBA DE ACCESO">PRUEBA DE ACCESO</option>
      <option value="ESO">ESO</option>
    </select>
  </div>
  <div class="col-lg-2 col-md-2">
    <button class="btn btn-raised btn-success" (click)="addtitulo()"><i class=" fa fa-plus mr-1"></i>Añadir titulo</button>
  </div>
</div>

<table class="table text-center">
  <thead>
  <tr class="d-flex">
    <th class="col-6" scope="col">Titulo</th>
    <th class="col-6" scope="col">Acciones</th>
  </tr>
  </thead>
  <tbody>
  <tr class="d-flex" *ngFor="let titulo of dato.titulos; let i = index">
    <td class="col-6">{{titulo.titulo | uppercase}}</td>
    <td class="col-6">
      <span *ngIf="titulo.archivo else uploadFile">
        <span>
          <a (click)="borrarTitulo(titulo.id, i)">
            <i class="ft-x font-medium-3 mr-2 danger"></i>
          </a>
        </span>
        <span>
          <a [href]="titulo.archivo | images: ''" target="_blank" class="success">
            <i class="ft-airplay font-medium-3 mr-2"></i>
          </a>
        </span>
      </span>
      <ng-template #uploadFile>
        <span>
          <a (click)="borrarTitulo(titulo.id, i)">
            <i class="ft-x font-medium-3 mr-2 danger"></i>
          </a>
        </span>
        <span>
          <a (click)="id_file  = titulo.id" class="info"
             onclick="document.getElementById ('upload_file').click()">
            <i class="ft-upload font-medium-3 mr-2"></i>
          </a>
        </span>
      </ng-template>
      <div class="form-group" hidden id="barraImages">
        <div class="input-group">
          <span class="input-group-btn">
            <span class="btn btn-default btn-file">
              <input (change)="fileChangeEventFile($event,'file')" class="file" id="upload_file"
                     name="filebutton-normal" type="file">
            </span>
          </span>
          <input class="form-control" readonly type="text">
        </div>
      </div>
    </td>
  </tr>
  </tbody>
</table>
