import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';
import {MatTableDataSource} from '@angular/material/table';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {AlquilerService} from '../services/alquiler.service';
import {CrearContactoComponent} from '../crear-contacto/crear-contacto.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-listar-alquiler',
  templateUrl: './listar-contacto.component.html',
  styleUrls: ['./listar-contacto.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ListarContactoComponent implements OnInit {
  public fContacto = '';
  public email = '';
  public contacto = '';
  public telefono = '';
  public web = '';
  public estado = '';
  public fecha = '';
  public refer = '';
  datos: any;
  dataSource: any;
  columnas = ['contacto', 'email', 'telefono', 'fContacto', 'fecha', 'web', 'referido',  'estado'];
  actividadesColumn = ['fecha', 'observaciones'];
  expandedElement: any | null;
  inputForm: FormGroup;
  searchForm: FormGroup;

  formaContacto: any;
  estados: any;
  referidos: any;
  ciclos: any;
  accionesList: any;

  @ViewChild('input', { static: true }) inputDialog: TemplateRef<any>;
  @ViewChild('MatPaginator', {static: false}) paginator: MatPaginator;

  constructor( private serviceAlquiler: AlquilerService, private dialog: MatDialog, private date: DatePipe) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource();
    this.initForms();
    this.getSelectData();
    this.getData();
  }

  async initForms(): Promise<void> {
    this.searchForm = new FormGroup( {
      contacto: new FormControl(''),
      email: new FormControl(''),
      telefono: new FormControl(''),
      web: new FormControl(''),
      referido: new FormControl(''),
      fContacto: new FormControl(''),
      fecha: new FormControl(''),
      estado: new FormControl(),
      fromDate: new FormControl(),
      toDate: new FormControl(),
    });
  }

  getSelectData() {
    this.serviceAlquiler.getSelects().subscribe( (res) => {
        console.log(res);
        this.estados = res['estados'];
        this.referidos = res['referidos'];
        this.formaContacto = res['forma_contacto'];
      }, (error) => {
        console.log(error);
      }
    );
  }

  getData() {
    this.serviceAlquiler.getData().subscribe( (res) => {
      console.log(res);
      this.dataSource.data = res;
      this.datos = res;
      this.dataSource.filterPredicate = this.getFilterPredicate();
      this.setFilters();
    });
  }

  setFilters(): void {
    this.dataSource.paginator = this.paginator;
  }


  setElements(element) {
    console.log(element);
    this.accionesList = new MatTableDataSource(element.actividades);
    console.log(this.accionesList.data);

  }

  addLinea(element) {
    (async () => {
      const { value: observacion } = await Swal.fire({
        title: 'Observaciones',
        input: 'textarea',
        allowOutsideClick: false,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Guardar',
        reverseButtons: true,
      })
      if (observacion) {
        this.serviceAlquiler.addLinea(observacion, element.id).subscribe((res) => {
          console.log(res);
          Swal.fire('', 'Se ha guardado correctamente', 'success');
          this.getData();
        }, (error) => {
          console.log(error)
        });
      }
    })()
  }

  changeEstado($event, contacto) {
    console.log($event.target.value, contacto);
    Swal.fire({
      title: 'Cambiar Estado',
      text: '¿Esta seguro que desea cambiar?',
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        console.log('aqui voy');
        this.serviceAlquiler.updateEstado(contacto.id, $event.target.value).subscribe( (res) => {
          const resp = [];
          resp.push(res);
          const i = this.dataSource.data.findIndex(item => item.id === res['id']);
          this.dataSource.data[i] = res;
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.getData();
      }
    })
  }


  getFilterPredicate(): any {
    return ( row: any, filters: string) => {
      const filterArray = filters.split('$');
      const contacto = filterArray[0];
      const email = filterArray[1];
      const telefono = filterArray[2];
      const fContacto = filterArray[3];
      const fecha = filterArray[4];
      const web = filterArray[5];
      const referido = filterArray[6];
      const estado = filterArray[7];
      console.log(filterArray);



      const matchFilter = [];
      console.log(row);
      // Filtra datos de la row
      const columnContacto =  row.info_contacto.nombre && row.info_contacto.apellidos === null ? '' : row.info_contacto.nombre
        + ', ' + row.info_contacto.apellidos;
      const columnFContacto = row.forma_contacto.forma_contacto === null ? '' : row.forma_contacto.forma_contacto;
      const columnFecha = row.fecha_contacto === null ?  '' : this.date.transform(row.fecha_contacto, 'dd-MM-YYY');
      const columnEmail = row.info_contacto.email === null ? '' : row.info_contacto.email;
      const columnWeb = row.info_contacto.web  === null ? '' : row.info_contacto.web;
      const columnTelefono = row.info_contacto.telefono === null ? '' : row.info_contacto.telefono;
      const columnReferido = row.info_contacto.referido.descripcion === null ? '' : row.info_contacto.referido.descripcion;
      const columnEstado = row.estado.id + '' === null ?  '' : row.estado.id + '';





      // Verificamos que concuerda
      const customFContacto = columnFContacto.toLowerCase().includes(fContacto);
      const customFecha = columnFecha.toLowerCase().includes(fecha);
      const customEmail = columnEmail.toLowerCase().includes(email);
      const customContacto = columnContacto.toLowerCase().includes(contacto);
      const customTelefono = columnTelefono.toLowerCase().includes(telefono);
      const customReferido = columnReferido.toLowerCase().includes(referido);
      const customWeb = columnWeb.toLowerCase().includes(web);
      const customEstado = columnEstado.toLowerCase().includes(estado);


      // Push de los que concuerdan ( true o false)
      matchFilter.push(customContacto);
      matchFilter.push(customEmail);
      matchFilter.push(customTelefono);
      matchFilter.push(customFContacto);
      matchFilter.push(customFecha);
      matchFilter.push(customWeb);
      matchFilter.push(customReferido);
      matchFilter.push(customEstado);
      return matchFilter.every(Boolean);
    };
  }

  applyFilter(): void {
    const fcon = this.searchForm.get('fContacto').value;
    const fec = this.date.transform(this.searchForm.get('fecha').value, 'dd-MM-yyyy');
    const ema = this.searchForm.get('email').value;
    const cont = this.searchForm.get('contacto').value;
    const telf = this.searchForm.get('telefono').value;
    const ref = this.searchForm.get('referido').value;
    const web = this.searchForm.get('web').value;
    const est = this.searchForm.get('estado').value;
    // const ent = this.date.transform(this.searchForm.get('entrada').value, 'dd-MM-yyyy');
    // const sal = this.date.transform(this.searchForm.get('salida').value, 'dd-MM-yyyy');


    this.fContacto = fcon === null ? '' : fcon;
    this.fecha = fec === null ? '' : fec;
    this.email = ema === null ? '' : ema;
    this.contacto = cont === null ? '' : cont;
    this.telefono = telf === null ? '' : telf;
    this.web = web === null ? '' : web;
    this.refer = ref === null ? '' : ref;
    this.estado = est === null ? '' : est;
    // this.salida = sal === null ? '' : sal;


    // Creamos el string para busqueda separados por $
    // tslint:disable-next-line:max-line-length
    const  filterValue = this.contacto + '$' + this.email + '$' + this.telefono + '$' + this.fContacto + '$' + this.fecha  + '$' + this.web + '$'
      + this.refer + '$'  + this.estado ;
    console.log(filterValue);
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  getDateRange(): void {
    if (this.searchForm.get('fromDate').value && this.searchForm.get('toDate').value) {
      this.dataSource.data = this.datos;
      // getting date from calendar
      const fromDate = this.searchForm.get('fromDate').value;
      const toDate = this.searchForm.get('toDate').value;

      this.dataSource.data = this.dataSource.data
        .filter(e => new Date(e.fecha_contacto) >= fromDate && new Date(e.fecha_contacto) <= toDate)
        .sort((a, b) => a.fecha_contacto - b.fecha_contacto);

    }

  }

  resetFechas(): void {
    this.dataSource.data = this.datos;
    this.searchForm.get('fromDate').reset();
    this.searchForm.get('toDate').reset();
  }

  resetAll(): void {
    this.resetFechas();
    this.searchForm.reset();
    this.dataSource.filter = '';
  }


  addContacto() {
    const dialogRef = this.dialog.open(CrearContactoComponent, { disableClose: true, hasBackdrop: true, width: '90%', });
    dialogRef.afterClosed().subscribe( result => {
      if (result ) {
        console.log(result);
        this.serviceAlquiler.addContacto(result).subscribe( (res) => {
         console.log(res);
         this.getData();
        });
      }
    });
  }
}
