import {Component, OnDestroy, OnInit, ElementRef} from '@angular/core';
import {AulasService} from '../../services/aulas.service';
import {Subscription} from 'rxjs/Subscription';
import {LoginService} from '../../../../pages/services/login.service';
import swal from 'sweetalert2';


@Component({
  selector: 'app-aulas-lista',
  templateUrl: './aulas-lista.component.html',
})

export class AulasListaComponent implements OnDestroy, OnInit {
  loadingIndicator = true;
  public cursosNoFilter: any[] = [];
  public cursosFilter: any[] = [];
  public cursos: any[] = [];
  private cursoSubscription: Subscription = null;
  private cursoFilterSubscription: Subscription = null;
  aulaname = '';
  letra = '';
  anyo = '';
  checked = false;


  constructor(private _curso: AulasService,
              private _login: LoginService, private _aula: AulasService, private elRef: ElementRef) {
    this._curso.getCursos(this._login.getIdentity().centro.id);
    this._curso.getCursosFilter(this._login.getIdentity().centro.id);
  }

  ngOnInit(): void {
    this.cursoSubscription = this._curso.cursos$.subscribe(res => {
      this.cursosNoFilter = res;

    });
    this.cursoFilterSubscription = this._curso.cursosFilter$.subscribe(res => {
      this.cursosFilter = res;

        this.cursos = this.cursosFilter;

      console.log(this.cursos);
    });

  }

  ngOnDestroy(): void {
    this.cursoSubscription.unsubscribe();
  }

  delete(row: any, position: number) {
    swal.fire({
      title: 'BORRAR',
      text: '¿Seguro que quiere borrar ' + this.cursos[position].aula + '?',
      icon: 'error',
      showCancelButton: true,
      confirmButtonText: 'Si, Eliminar!',
      cancelButtonText: 'No, Cancelar!',
      reverseButtons: true
    })
      .then((result) => {
        if (result.isConfirmed) {
          const ids = this.cursos[position].id;
          this._curso.deleteCursos(ids);
          this.cursos.splice(position, 1);
        }
      });


  }

  changeCheck(){

    this.cargarLista();
  }

  cargarLista(): void {
    if(!this.checked){
      this.cursos = this.cursosFilter;
    }else{
      this.cursos = this.cursosNoFilter;
    }
  }


  calcularNotaFinal(id, i: number) {


    swal.fire({
      title: 'Calculo de nota final de curso actual',
      text: 'Se calcularán las notas medias finales del curso actual en base a los trimestres, ¿esta seguro?',
      icon: 'error',
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    })
      .then((result) => {
        console.log(result);
        if ( result.isConfirmed ) {
          this._curso.generateNotasFinales(id).subscribe((res: any) => {
            console.log(res);
          });
        }
      });
  }
}


