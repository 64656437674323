import { Injectable } from '@angular/core';
import {Rutas} from '../../../utiles/Rutas';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TablonService {

  constructor( private http: HttpClient) {
  }


  getMensajeTablon() {
    return this.http.get(Rutas + 'get/mensajes_tablon?token=' + localStorage.getItem('token') );
  }

  getCursosTablon() {
    return this.http.get(Rutas + 'get/cursos?token=' + localStorage.getItem('token') + '&id=1');
  }
  getMensajeTablonUser( datos?) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    const json = {datos};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    return this.http.post(Rutas + 'get/mensajes_tablon', params, {headers: headers})
  }

  getMensajeTablonAdmin( ) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    const params = 'token=' + localStorage.getItem('token');
    return this.http.post(Rutas + 'get/mensajes_tablon_admin', params, {headers: headers})
  }

  addMensaje(data) {
    return this.http.post(Rutas + 'add/mensaje_tablon', data, {responseType: 'text' as 'json'});
  }

  deleteMensajeTablon(id) {
    return this.http.get(Rutas + 'remove/mensaje/tablon?id=' + id + '&token=' + localStorage.getItem('token') );
  }
}
