// Importamos los componentes necesarios
import { Pipe, PipeTransform } from '@angular/core';

// Le ponemos un nombre a la tuberia
@Pipe({name: 'user'})

// Definimos la clase implementado la interface PipeTransform
export class UserPipe implements PipeTransform {
transform(value: string): string {
    const role = value.split('_');
    return role[1];
 }
}
