import {Component, Input, OnInit} from '@angular/core';


@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit {

  single: any[];
  multi: any[];



  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = false;

  pintar = false;
  colorScheme = {
    domain: ['#67eb85', '#4b4b4b']
  };

  @Input() datos: any;
  titulo: any;
  constructor() {
  }

  ngOnInit(): void {
    this.titulo = this.datos['name'];
    console.log(this.datos);
    this.single = this.datos.datos;

  }


}
