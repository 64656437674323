import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
  name: 'repartirAulas'
})
export class RepartirAulasPipe implements PipeTransform {

  transform (value: any, total: number, dia: string, index: number): any {
    index = index + 1;
    switch (dia) {
      case 'lunes':
        return index;

      case 'martes':
        total = total + index;
        return total;

      case 'miercoles':
        total = total * 3;
        return total + index;

      case 'jueves':
        total = total * 4;
        return total + index;

      case 'viernes':

        total = total * 5;
        return total + index;


    }


    return null;
  }

}
