export class Task {
  public taskTitle: string;
  public taskMessage: string;
  public createdOn: string;
  public createdBy: number;
  public assignedTo: number;
  public status: string;
  public type: string;
  public img: string;
  public id: number;
  public asignatura: number;
  public curso: number;


  constructor (taskTitle: string, taskMessage: string, createdOn: string,
               createdBy: number, assignedTo: number, status: string, type: string, img: string, id: number, asignatura: number, curso: number) {
    this.taskTitle = taskTitle;
    this.taskMessage = taskMessage;
    this.createdOn = createdOn;
    this.createdBy = createdBy;
    this.assignedTo = assignedTo;
    this.status = status;
    this.type = type;
    this.img = img;
    this.id = id;
    this.asignatura = asignatura;
    this.curso = curso;

  }
}
