// Importamos los componentes necesarios
import {Pipe, PipeTransform} from '@angular/core';

// Le ponemos un nombre a la tuberia
@Pipe({name: 'total_horas'})

// Definimos la clase implementado la interface PipeTransform
export class TotalHorasPipe implements PipeTransform {
  transform (total_dias: number, horas_semana: number): number {
    const semanas = total_dias / 5;
    const total_horas = horas_semana * semanas;
    return Math.round(total_horas);
  }
}
