<section id="simple">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">Examenes  de {{dato}}</h5>
        </div>

        <table class="table table-striped">
          <thead>
          <tr>

            <th scope="col">Examen</th>
            <th scope="col">Nota</th>
            <th scope="col">Fecha</th>
            <th scope="col">Asignatura</th>

          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let examen of examenes; let i = index">
            <td>{{examen.examen}}</td>
            <td>{{examen.nota}}</td>
            <td>{{examen.fecha.date | date: 'dd/MM/yy'}}</td>
            <td>{{examen.asignatura}}</td>

          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>

