import {Component, OnDestroy} from '@angular/core';
import {ClasesMService} from '../services/clasesM.service';
import {Subscriber} from 'rxjs/Subscriber';
import {Subscription} from 'rxjs/Subscription';
import {Router, ActivatedRoute} from '@angular/router';
import {LocalDataSource} from 'ng2-smart-table';
import * as tableData from '../../template/shared/smart-table-files-examenes';
import {RutasImg} from '../../../../utiles/Rutas';
import {UploadService} from '../../../services/upload.service';


@Component({
  selector: 'app-lista-files-examenes',
  templateUrl: './lista-files-examenes.component.html',
  styleUrls: ['../clasesM.component.scss']
})
export class ListaFilesExamenesComponent implements OnDestroy {


  private examenes = [];
  id: number;

  examenesSubscription: Subscription = null;
  source: LocalDataSource;
  filterSource: LocalDataSource;
  alertSource: LocalDataSource;
  filesToUpload: File[];

  constructor (private _clases: ClasesMService,
               private _route: ActivatedRoute, private _router: Router,
               private _upload: UploadService) {

    this._route.params.subscribe(params => {
      this.id = params['id'];
    });
    this._clases.getFiles(this.id);
    this.examenesSubscription = this._clases.examenes$.subscribe(data => {
      this.examenes = data;
      this.source = new LocalDataSource(this.examenes); // create the source
    });

  }

  settings = tableData.settings;


  ngOnDestroy (): void {
    this.examenesSubscription.unsubscribe();
  }

  ver (event) {
    window.open(RutasImg + event.data.url);
  }

  addPhoto () {
    this._upload
      .makeFileRequest(this.id, this.filesToUpload, 'imagen', 'examen_alumno')
      .then(
        (result: any) => {
        },
        error => {
          console.log(error);
        }
      );
  }

  fileChangeEvent (fileinput: any) {

    this.filesToUpload = <Array<File>>fileinput.target.files;
    this.addPhoto();
  }

  //  For confirm action On Delete
  onDeleteConfirm (event) {
    this._clases.deleteFileExamen(event.data.id);
  }


}
