import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LoginService} from '../../../pages/services/login.service';
import {Rutas} from '../../../../utiles/Rutas';
import {Subject} from 'rxjs/Subject';

@Injectable()
export class MaestroInboxService {
  id: number;
  token: string;
  datoSource = new Subject<any>();
  dato$ = this.datoSource.asObservable();

  constructor (private http: HttpClient, private _login: LoginService) {
    this.id = this._login.getIdentity().id;
    this.token = localStorage.getItem('token');
  }

  getAulaMaestro() {
    return new Promise((resolve, reject) => {
      this.http.get(Rutas + 'get/alumnos/aulas?id=' + this.id + '&token=' + this.token).subscribe(res => {
        resolve(res['id']);
        }, error => {
        reject(error);
      });
    });
  }
}
