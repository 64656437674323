import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {AlumnosService} from '../services/alumnos.service';


@Component({
  selector: 'app-padres-alumno',
  templateUrl: './padres-alumno.component.html',
  styleUrls: ['../alumnos.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PadresAlumnoComponent implements OnInit, OnDestroy {
  id: number;
  padres: any[] = [];
  contactos: any[] = [];
  datosSubscription: Subscription = new Subscription();

  constructor (private _route: ActivatedRoute, private _alumnos: AlumnosService) {
    this.id = this._route.snapshot.parent.params['id'];
    this._alumnos.getPadres(this.id);
  }

  ngOnInit () {
    this.datosSubscription = this._alumnos.datos$.subscribe(resp => {
      this.padres = resp;
      console.log(this.padres);
    });
  }

  ngOnDestroy () {
    this.datosSubscription.unsubscribe();
  }


}

