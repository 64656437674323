<div class="table-responsive">
  <table class="table">
    <thead>
    <tr>
      <th>Imagen</th>
      <th>Nombre
        <form class="navbar-form" role="search" autocomplete="off">
          <div class="position-relative has-icon-right">
            <input (input)="searchActionNombre()" [(ngModel)]="nombreSearch"
                   class="form-control round"
                   name="search" placeholder="Buscar por Nombre" type="text">
            <div class="form-control-position">
              <i class="ft-search"></i>
            </div>
          </div>
        </form>
      </th>
      <th>Apellidos
        <form class="navbar-form" role="search" autocomplete="off">
          <div class="position-relative has-icon-right">
            <input (input)="searchActionApellidos()" [(ngModel)]="apellidoSearch"
                   class="form-control round"
                   name="search" placeholder="Buscar por Apellidos" type="text">
            <div class="form-control-position">
              <i class="ft-search"></i>
            </div>
          </div>
        </form>
      </th>
      <th *ngIf="role === 4">Módulo
        <select (change)="selectActionCiclo()" [(ngModel)]='cicloSearch' class="form-control" name="ciclo">
          <option [ngValue]="cALL">Ver Todos
          </option>
          <option *ngFor="let c of cc" [value]="c.nivel">{{c.nivel}}</option>
        </select>
      </th>
      <th>Acciones
        <button class="aceptar btn btn-raised btn-primary form-control" (click)="exportDatosAlumnos()">Exportar Excel</button>
      </th>

    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let user of users | paginate: { itemsPerPage: 15, currentPage: actualPage }; let i = index">
      <td><img [src]="user.img | images: ''" class="media-object round-media img-thumbnail" style="height: 45px"></td>
      <td>{{user.nombre | titlecase}}</td>
      <td>{{user.apellidos | titlecase}}</td>
      <td *ngIf="role === 4">{{user.nivel}}</td>
      <td>
        <ul class="list-inline">
          <li class="list-inline-item danger p-0">
            <i (click)="remove(user.id,i)" class="ft-x font-medium-3 mr-2"></i>
          </li>
          <li class="success p-0 list-inline-item">
            <i [routerLink]="['/admin/users/',user.id, tipo]" class="ft-user font-medium-3 mr-2"></i>
          </li>
        </ul>
      </td>
    </tr>
    </tbody>
  </table>
  <pagination-controls (pageChange)="actualPage = $event"></pagination-controls>
</div>
