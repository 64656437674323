<div style="display: block">
  <canvas baseChart
          [data]="doughnutChartData"
          [labels]="doughnutChartLabels"
          [chartType]="doughnutChartType"
          (chartHover)="chartHovered($event)"
          (chartClick)="chartClicked($event)"
          [colors]="colors">

  </canvas>
  <p>{{cantidad | number}} % de {{ texto }}</p>
</div>
