 <h4 class="info-top"><i class="ft-user"></i>Contacto: {{dato?.nombre}} {{dato?.apellidos}} </h4><div class="row">
  <div class="col-3">
    <ul class="list-group">
      <li class="list-group-item active">Telefono
        <i class="icon-plus add_btn float-right" (click)="open('telefono', 3)"></i>
      </li>
      <li class="list-group-item"
          *ngFor="let contacto of dato.contactos | contactos: 3 ; let i = index">{{contacto.dato}}
        <i class="ft-trash  danger float-right" (click)="removeContacto(contacto)"></i>
      </li>
    </ul>
  </div>

  <div class="col-3">
    <ul class="list-group">
      <li class="list-group-item active">Movil
        <i class="icon-plus add_btn float-right" (click)="open('movil', 2)"></i>
      </li>
      <li class="list-group-item"
          *ngFor="let contacts of dato.contactos | contactos: 2 ; let i = index">{{contacts.dato}}
        <i class="ft-trash  danger float-right" (click)="removeContacto(contacts)"></i>
      </li>
    </ul>
  </div>
  <div class="col-3">
    <ul class="list-group">
      <li class="list-group-item active">Email
        <i class="icon-plus add_btn float-right" (click)="open('email', 1)"></i>
      </li>
      <li class="list-group-item"
          *ngFor="let contac of dato.contactos | contactos: 1 ; let i = index">{{contac.dato}}
        <i class="ft-trash  danger float-right" (click)="removeContacto(contac)"></i>
      </li>
    </ul>
  </div>
  <div class="col-3">
    <ul class="list-group">
      <li class="list-group-item active">Fax
        <i class="icon-plus add_btn float-right" (click)="open('fax',4)"></i>
      </li>
      <li class="list-group-item"
          *ngFor="let cont of dato.contactos | contactos: 4 ; let i = index">{{cont.dato}}
        <i class="ft-trash  danger float-right" (click)="removeContacto(cont)"></i>
      </li>
    </ul>
  </div>
</div>
