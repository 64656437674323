<h4 class="info-top"><i class="ft-user"></i>Bancarios: {{dato?.nombre}} {{dato?.apellidos}} </h4><div class="row">
    <div class="col-12">
        <form class="form  form-horizontal striped-rows form-bordered" novalidate="" (ngSubmit)="onSubmit()" #c="ngForm" autocomplete="off">
            <div class="form-body">
                <div class="form-group row">
                    <label class="col-md-3 label-control">Iban</label>
                    <div class="col-md-5">
                        <input [(ngModel)]='iban' type="text"  class="form-control width-input" name="iban" placeholder="Cuenta bancaria" [disabled]="!activeIban">
                    </div>
                </div>

                <div class="form-group row" *ngIf="dato.iban">

                    <label class="col-md-3 label-control">Titular</label>
                    <div class="col-md-5">
                        <select name="id_titular" class="custom-select width-input" [disabled]="!activeIban" required *ngIf="alumno" (change)="print($event)">
                        <ng-container *ngIf="!activeIban">
                             <option [value]="idtitular" [selected]>{{titularNombre}} {{titularApellidos}}</option>
                        </ng-container>

                        <ng-container *ngIf="dato.tutores && activeIban">
                            <option [value]="dato.id" [selected]>{{dato.nombre}} {{dato.apellidos}}</option>
                            <option *ngFor=" let tutor of dato.tutores" [value]="tutor.id">{{tutor.nombre}} {{tutor.apellidos}}</option>
                        </ng-container>

                    </select>

                    </div>
                </div>
              <div class="form-group row">
                <label class="col-md-3 label-control">Matricula</label>
                <div class="col-md-5">
                  <input [(ngModel)]='matricula' type="text"  class="form-control width-input" name="matricula" placeholder="Importe matricula" [disabled]="!activeIban">
                </div>
              </div>

              <div class="form-group row">
                <label class="col-md-3 label-control">Forma pago Matricula</label>
                <div class="col-md-5">
                  <input [(ngModel)]='formaPagoMatricula' type="text"  class="form-control width-input" name="formaPagoMatricula" placeholder="Forma de pago de Matricula" [disabled]="!activeIban">
                </div>
              </div>

              <div class="form-group row">
                <label class="col-md-3 label-control">Cuota</label>
                <div class="col-md-5">
                  <input [(ngModel)]='cuota' type="text"  class="form-control width-input" name="cuota" placeholder="Importe cuota" [disabled]="!activeIban">
                </div>
              </div>

              <div class="form-group row">
                <label class="col-md-3 label-control">Forma pago Cuota</label>
                <div class="col-md-5">
                  <textarea [(ngModel)]='formaPagoCuota' type="text"  class="form-control width-input" rows="6" name="formaPagoCuota" placeholder="Forma de pago de Cuota" [disabled]="!activeIban"> </textarea>
                </div>
              </div>

                <div class="form-actions">
                    <button type="button" [ngClass]="{'btn btn-raised': true ,'btn-primary': !activeIban, 'btn-warning': activeIban}" (click)="activeIban = !activeIban">
                         Editar
                        </button>
                    <button *ngIf="activeIban" type="submit" class="btn btn-raised btn-success">Crear</button>
                </div>
            </div>

        </form>
    </div>
</div>
