import {Component, Input, OnInit} from '@angular/core';
import {Label, SingleDataSet} from 'ng2-charts';
import {ChartType} from 'chart.js';

@Component({
  selector: 'app-polar-chart',
  templateUrl: './polar-chart.component.html',
  styleUrls: ['./polar-chart.component.scss']
})
export class PolarChartComponent implements OnInit {
  @Input() datos: any[] = [];

  @Input() titulo: any ;
  single: any[];


  // options

  showLegend = true;
  showLabels = true;



  constructor() { }

  ngOnInit(): void {
    this.single = this.datos
  }
  labelFormat( value) {

    return value + '';
  }

}
