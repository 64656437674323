import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Subject} from 'rxjs/Subject';
import {Router} from '@angular/router';
import {Rutas} from '../utiles/Rutas';
import {LoginService} from '../components/pages/services/login.service';
import 'rxjs/add/operator/toPromise';


@Injectable()
export class AgendaMService {
  private datosSource = new Subject<any>();
  public datos$ = this.datosSource.asObservable();


  constructor(
    private http: HttpClient,
    private router: Router,
    private _login: LoginService
  ) {
  }

  getAula() {
    const id = this._login.getIdentity().id;
    const role = this._login.getIdentity().role;
    let url = 'alumno/id/curso?id=';
    let wath = false;
    if (role === 'ROLE_PROFESOR') {
      url = 'profesor/id/curso?id=';
      wath = true;
    }
    return new Promise((resolve, reject) => {
      console.log('Consola 1', Rutas + url + id + '&token=' + localStorage.getItem('token'));
      this.http.get(Rutas + url + id + '&token=' + localStorage.getItem('token'))
        .subscribe(res => {
          if (res['code'] === 200) {
            if (!wath) {
              resolve(res['id'].id);
            } else {
              resolve(res['id']);
            }
          } else {
            reject(false);
          }
        });
    }).then(res => {
      if (!wath) {
        this.http.get(Rutas + 'get/aula?id=' + res + '&token=' + localStorage.getItem('token'))
          .subscribe(data => {
            if (data['code'] === 200) {
              this.datosSource.next(data['id']);
            } else {
              return false;
            }
          });
      } else {
        this.datosSource.next(res);
      }
    });
  }

  getAlumno(id) {
    this.http.get(Rutas + 'get/alumnos/curso?id=' + id + '&token=' + localStorage.getItem('token'))
      .subscribe(data => {
        if (data['code'] === 200) {
          this.datosSource.next(data['id']);
        } else {
          return false;
        }
      });
  }
}
