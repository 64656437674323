import {Component, OnInit, OnDestroy} from '@angular/core';
import {LoginService} from '../../pages/services/login.service';
import {Subscription} from 'rxjs/Subscription';
import {RutasImg} from '../../../utiles/Rutas';


@Component({
  selector: 'app-shared',
  templateUrl: './admin.component.html'
})
export class AdminComponent implements OnInit, OnDestroy {
  private sidebarSubscriber: Subscription = null;
  private sidebar: string;
  private userSubscription: Subscription = null;
  private user: string;
  private datos: any = {};

  constructor (private _login: LoginService) {
    this._login.getUserImage();
    this.datos = JSON.parse(localStorage.getItem('identity'));
  }

  ngOnInit () {
    this.sidebarSubscriber = this._login.$lateralImage.subscribe(data => {
      this.sidebar = RutasImg + data;
    });
    this.userSubscription = this._login.$userImage.subscribe(data => {
      this.user = RutasImg + data;
    });
  }

  ngOnDestroy () {
    if (this.userSubscription != null) {
      this.userSubscription.unsubscribe();
    }

  }


}
