import {Component, ViewEncapsulation, ViewChild, ElementRef, Input, OnInit, Renderer2} from '@angular/core';

import {TaskBoardService} from './taskboard.service';
import {Task} from './taskboard.model';
import {Subscription} from 'rxjs/Subscription';
// import {DragulaService} from 'ng2-dragula';
import {LoginService} from '../components/pages/services/login.service';
import {ActivatedRoute} from '@angular/router';
import {UploadService} from '../components/services/upload.service';
import {Rutas} from '../utiles/Rutas';


@Component({
  selector: 'app-taskboard',
  templateUrl: './taskboard.component.html',
  styleUrls: ['./taskboard.component.scss'],
  providers: [TaskBoardService],
  encapsulation: ViewEncapsulation.None
})
export class TaskboardComponent implements OnInit {
  @ViewChild('todoTitle') titleInputRef: ElementRef;
  @ViewChild('todoMessage') messageInputRef: ElementRef;
  @Input() type: string;

  public inProcessSubscription: Subscription = null;
  public todoSubscription: Subscription = null;
  public completedSubscription: Subscription = null;
  public todo: any;
  public completed: any;
  public inProcess: any;
  public active: boolean;
  public types: boolean;
  public sendId: number;
  public receiver: any;
  public aula: number;
  asignatura: number;
  curso: number;
  public filesToUpload: Array<File>;
  id: number;
  public rutas = Rutas + 'images/centro/attachment.png';

  constructor (
    private elRef: ElementRef,
    private taskBoardService: TaskBoardService,
    private renderer: Renderer2,
    // private dragulaService: DragulaService,
    private _login: LoginService,
    private _route: ActivatedRoute,
    private _upload: UploadService,
  ) {

    this.asignatura = this._route.snapshot.parent.params['id'];
    this.curso = this._route.snapshot.parent.params['curso'];
    this.types = false;

    if (this.asignatura && this.curso) {
      this.type = 'aula';
    }

  /*  dragulaService.drop.subscribe(value => {
      this.onDrop(value.slice(1));
    }); */
    this.taskBoardService.getTask();
  }

  private onDrop (args) {

    const id = args[0].children[0].children[0].id;
    const estado = args[1].id;
    this.taskBoardService.changeStatus(estado, id);
    const [e, el] = args;
  }

  ngOnInit () {
    this.todoSubscription = this.taskBoardService.todo$.subscribe(res => {
      if (res.length > 0) {
        this.todo = res;
      } else {
        this.todo = false;
      }
    });

    this.inProcessSubscription = this.taskBoardService.inProcess$.subscribe(
      res => {
        if (res.length > 0) {
          this.inProcess = res;
        } else {
          this.inProcess = false;
        }
      }
    );

    this.completedSubscription = this.taskBoardService.completed$.subscribe(
      res => {
        if (res.length > 0) {
          this.completed = res;
        } else {
          this.completed = false;
        }
      }
    );
  }

  deleteElement () {
    const content_todo = document.getElementById('todo_l');
    const content_process = document.getElementById('inProcess_l');
    const content_completed = document.getElementById('completed_l');
    if (content_todo != null) {
      while (content_todo.hasChildNodes()) {
        content_todo.removeChild(content_todo.firstChild);
      }
    }
    if (content_process != null) {
      while (content_process.hasChildNodes()) {
        content_process.removeChild(content_process.firstChild);
      }
    }
    if (content_completed != null) {
      while (content_completed.hasChildNodes()) {
        content_completed.removeChild(content_completed.firstChild);
      }
    }
  }

  btnTask () {
    // this.deleteElement()
    this.taskBoardService.getTask();
    this.types = true;
    this.type = 'aula';
    this.sendId = this._login.getIdentity().id;
    this.receiver = {asignatura: this.asignatura, curso: this.curso};
  }

  btnMyTask () {
    //  this.deleteElement()
    this.taskBoardService.getMyTask();
    this.types = false;
    this.type = 'personal';
    this.sendId = this._login.getIdentity().id;
    this.receiver = this._login.getIdentity().id;
  }

  deleteTask (id: number) {
    this.taskBoardService.deleteTask(id, this.types);
  }

  openFiles () {
    document.getElementById('buscadorPersona').click();
  }

  onAddTask () {
    if (
      this.messageInputRef.nativeElement.value !== '' &&
      this.titleInputRef.nativeElement.value !== ''
    ) {
      let id = 0;
      if (this.types === false) {
        id = this.receiver;
      }
      const task = new Task(
        this.titleInputRef.nativeElement.value,
        this.messageInputRef.nativeElement.value,
        '',
        this.sendId,
        this.receiver,
        '',
        this.type,
        '',
        id,
        this.asignatura,
        this.curso
      );

      if (!this.filesToUpload) {
        this.taskBoardService.add(task);
      } else {

        this.taskBoardService.add(task).then((res: any) => {
          this.addTaskFile(res);
        });
      }

    }

    this.titleInputRef.nativeElement.value = '';
    this.messageInputRef.nativeElement.value = '';
    this.titleInputRef.nativeElement.focus();
  }

  addTaskFile (id: number) {
    this._upload
      .makeFileRequest(id, this.filesToUpload, 'imagen', 'task')
      .then(
        (result: any) => {
          console.log(result);
        },
        error => {
          console.log(error);
        }
      );
  }

  fileChangeEvent (fileinput: any) {
    this.filesToUpload = <Array<File>>fileinput.target.files;
    }
}
