import {
  Component, OnInit, DoCheck, ViewEncapsulation, OnDestroy
} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {ClasesMService} from '../services/clasesM.service';
import {AsistenciasService} from '../../admin-asistencias/services/asistencias.service';


@Component({
  selector: 'app-asignaturasM',
  templateUrl: './asignaturasM.component.html',
  styleUrls: ['../clasesM.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AsignaturasMComponent implements OnInit, OnDestroy {
  public id: number;
  asignaturasSubscription: Subscription = null;
  asignaturas: any[] = [];

  constructor (private _route: ActivatedRoute, private _clases: ClasesMService, private _asistencias: AsistenciasService) {
    this.id = this._route.snapshot.parent.params['id'];
    this._clases.asignaturasHoy (this.id);
  }

  ngOnInit () {
    this.asignaturasSubscription = this._clases.asignaturas$.subscribe(res => {
      this.asignaturas = res;
      });
  }

  ngOnDestroy () {
    this.asignaturasSubscription.unsubscribe();
  }
}
