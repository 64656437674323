<div style="display: block; padding-top: 20px" #containerRef2 >
  <div style="text-align: center">
    <p>Total:{{total}}</p>
  </div>
  <ngx-charts-pie-grid
    [view]="[containerRef2.offsetWidth, 200]"
    [scheme]="colorScheme"
    [designatedTotal]="designatedTotal"
    label="Matriculados"
    [animations]="false"
    [results]="single">
  </ngx-charts-pie-grid>
</div>
