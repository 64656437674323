// Importamos los componentes necesarios
import {Pipe, PipeTransform} from '@angular/core';

// Le ponemos un nombre a la tuberia
@Pipe({name: 'horas_minutos'})

// Definimos la clase implementado la interface PipeTransform
export class HorasMinutosPipe implements PipeTransform {
  transform (hora: string, minutos: string): string {
    const hora_ = '' + hora;
    const minutos_ = '' + minutos;
    if (hora_.length === 1) {
      hora = '0' + hora;
    }
    if (minutos_.length === 1) {
      minutos = '0' + minutos;
    }
    return hora + ':' + minutos;
  }

}
