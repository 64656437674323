import {Component, Input, OnInit} from '@angular/core';
import {ChartOptions, ChartType} from 'chart.js';
import {Label, SingleDataSet} from 'ng2-charts';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit {

  @Input() datos: any[] = [];

  public pieChartLabels: Label[] ;
  public pieChartData: SingleDataSet ;
  public pieChartType: ChartType = 'doughnut';
  public pieChartLegend = true;
  public pieChartColors = [
    {
      backgroundColor: ['#67eb85', '#4b4b4b', '#96DAD8', '#C0C6D1', '#F1F2F4', '#FFFFFF', '#60B1EB', '#FFE2A1'  ],
    },
  ];
  public pieChartOptions: ChartOptions = {
    responsive: true,
  };

  titulo: any;
  constructor() { }

  ngOnInit(): void {
     this.titulo = this.datos['ciclo'].ciclo + '-' + this.datos['ciclo'].modalidad;
     this.pieChartLabels = [ 'Matriculados ' + this.datos['matriculados'] , 'Libres ' + ( this.datos['aforo'] - this.datos['matriculados'])]
    this.pieChartData = [ this.datos['matriculados'],  ( this.datos['aforo'] - this.datos['matriculados'])]
  }

}
