


<div class="row">
    <div class="col-sm-12">
        <div class="content-header">
            <div class="btn-group btn-group-justified btn-group-raised">
                <button type="button" (click)='active=!active; btnTask()' [ngClass]="{'btn':true, 'btn-primary':!active, 'btn-secondary':active}">Tareas aula</button>
                <button type="button" (click)='active=!active; btnMyTask()' [ngClass]="{'btn':true, 'btn-primary':active, 'btn-secondary':!active}">Mis tareas</button>
            </div>
        </div>
        <p class="content-sub-header"></p>
    </div>
</div>
<!-- Taskboard Starts -->
<section id="taskboard">

    <div class="row">
        <div class="col-md-12 col-lg-12">
            <div class='parent'>
                <div class='dragdrop-wrapper'>
                    <div class="row">
                        <input type="hidden" name="type" [id]="type">

                        <div class="col-md-4 col-12">
                            <h4 class="ml-2 mt-2 text-bold-500"><i class="ft-list mr-1"></i>Tareas</h4>

                            <div class='dragdrop-container' id="todo"   >
                                <div *ngIf="todo; else todo_vacio">
                                    <div *ngFor='let todo of todo'>
                                        <div class="card" [id]=todo.id>
                                            <div class="card-block pt-3">
                                                <div class="clearfix">
                                                    <h5 id="color_todo" class="text-bold-500 primary float-left">{{todo.taskTitle}}</h5>
                                                    <div class="actions float-right">
                                                        <i class="ft-edit mr-1 info"></i>
                                                        <i class="ft-trash-2 danger" (click)="deleteTask(todo.id)"></i>
                                                    </div>
                                                </div>
                                                <p>{{todo.taskMessage}}</p>
                                                <img [src]="[todo.img | images: '']" class="rounded-circle width-50 mr-2"> <span class="primary">{{todo.createdOn}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ng-template #todo_vacio>
                                    <div class="card" [id]="">
                                        <div class="card-block pt-3">
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>


                        <div class="col-md-4 col-12">
                            <h4 class="ml-2 mt-2 text-bold-500"><i class="ft-trending-up mr-1"></i>En proceso</h4>
                            <div class='dragdrop-container' id="inProcess">
                                <div *ngIf="inProcess; else inProcess_vacio">
                                    <div *ngFor='let inProcess of inProcess'>
                                        <div class="card" [id]="inProcess.id">
                                            <div class="card-block pt-3">
                                                <div class="clearfix">
                                                    <h5 id="color_inProcess" class="text-bold-500 info float-left">{{inProcess.taskTitle}}</h5>
                                                    <div class="actions float-right">
                                                        <i class="ft-edit mr-1 info"></i>
                                                        <i class="ft-trash-2 danger" (click)="deleteTask(inProcess.id)"></i>
                                                    </div>
                                                </div>
                                                <p>{{inProcess.taskMessage}}</p>
                                                <img [src]="[inProcess.img | images: '']" class="rounded-circle width-50 mr-2"> <span class="info">{{inProcess.createdOn}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ng-template #inProcess_vacio>
                                    <div class="card" [id]="">
                                        <div class="card-block pt-3">
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>



                        <div class="col-md-4 col-12">
                            <h4 class="ml-2 mt-2 text-bold-500"><i class="ft-thumbs-up mr-1"></i>Terminado</h4>
                            <div class='dragdrop-container' id="completed">
                                <div *ngIf="completed; else completed_vacio">
                                    <div *ngFor='let completed of completed'>
                                        <div class="card" [id]="completed.id">
                                            <div class="card-block pt-3">
                                                <div class="clearfix">
                                                    <h5 id="color_completed" class="text-bold-500 success float-left">{{completed.taskTitle}}</h5>
                                                    <div class="actions float-right">
                                                        <i class="ft-edit mr-1 info"></i>
                                                        <i class="ft-trash-2 danger" (click)="deleteTask(completed.id)"></i>
                                                    </div>
                                                </div>
                                                <p>{{completed.taskMessage}}</p>
                                                <img [src]="[completed.img | images: '']" class="rounded-circle width-50 mr-2"> <span class="success">{{completed.createdOn}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ng-template #completed_vacio>
                                    <div class="card" [id]="">
                                        <div class="card-block pt-3">
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>

    <section class="taskboard-app-form bg-blue-grey bg-lighten-5">
        <div class="card">
            <div class="card-block pt-3">
                <h5 class="text-bold-500">Create new Task</h5>
                <form class="taskboard-app-input row">



                    <fieldset class="form-group position-relative has-icon-left col-md-4 col-12 mb-1">
                        <div class="form-control-position">
                            <i class="icon-emoticon-smile"></i>
                        </div>
                        <input type="text" class="form-control" id="todoTitle" placeholder="Title" (keydown.enter)="$event.preventDefault()" #todoTitle>
                        <div class="form-control-position control-position-right">
                            <i class="ft-image"></i>
                        </div>
                    </fieldset>

                    <fieldset class="form-group position-relative has-icon-left col-md-6 col-12 mb-1">
                        <div class="form-control-position">
                            <i class="icon-emoticon-smile"></i>
                        </div>
                        <input type="text" class="form-control" id="todoMessage" placeholder="Message" (keydown.enter)="$event.preventDefault()" #todoMessage>
                        <div class="form-control-position control-position-right">
                            <i class="ft-image"></i>
                        </div>
                    </fieldset>

                  <fieldset class="form-group position-relative has-icon-left col-md-2 col-12 mb-1">
                    <img [src]= "rutas" class="img-xl img-thumbnail" (click)="openFiles()"/>
                  </fieldset>

                  <fieldset class="form-group position-relative has-icon-left col-md-2 col-12 mb-1">
                        <button type="button" class="btn btn-raised btn-primary " (click)="onAddTask()">
                    <i class="fa fa-paper-plane-o hidden-lg-up"></i> Create</button>
                    </fieldset>
                </form>
            </div>
        </div>

      <div class="form-group" id="barraImages" hidden>
        <div class="input-group">
            <span class="input-group-btn">
                    <span class="btn btn-default btn-file">
                        <input id="buscadorPersona" name="filebutton-normal" type="file" class="file" (change)="fileChangeEvent($event)">
                    </span>
            </span>
          <input type="text" class="form-control" readonly>
        </div>
      </div>

    </section>

</section>
<!-- Taskboard Ends -->
