import {Component, OnDestroy, OnInit} from '@angular/core';
import {AsistenciasService} from '../services/asistencias.service';
import {Subscription} from 'rxjs/Subscription';
import {Router, ActivatedRoute} from '@angular/router';
import {DatePipe} from '@angular/common';


@Component({
  selector: 'app-check-asistencias',
  templateUrl: './check-asistencias.component.html',
  styleUrls: ['../asistencias.component.scss'],
  providers: [DatePipe]
})
export class CheckAsistenciasComponent implements OnDestroy, OnInit {


  alumnos = [];
  alumnosSubscription: Subscription = null;
  id: number;
  asignatura: number;
  curso: number;
  fecha: Date;
  clase: any = {};
  check: boolean;
  now_date = new Date();
  bloqueado: boolean;
  aviso: string;
  aula = sessionStorage.getItem('aula');


  constructor (private _asistencias: AsistenciasService, private _route: ActivatedRoute, private  _date: DatePipe) {
    this.asignatura = this._route.snapshot.parent.params['id'];
    this.curso = this._route.snapshot.parent.params['curso'];
    this._asistencias.getAlumnos(this.curso, this.asignatura);
  }

  saveComentario (comentario, id, i) {
    if (comentario) {
     this.alumnos[i].comentario = comentario;
    this._asistencias.addComentario(comentario, id);
      return;
    }
  }

  deleteComentario (index) {
    this.alumnos[index].comentario = '';
   this._asistencias.deleteComentario(this.alumnos[index].id);
  }


  ngOnInit () {
    this.alumnosSubscription = this._asistencias.datos$.subscribe(data => {
      if (data) {
        for (let i = 0; i < data.length - 1; i++) {
          this.alumnos[i] = data[i];
        }
        this.clase = data[data.length - 1].clase_aula;
      } else {
        this.aviso = 'Hoy no tienes clase con este grupo';
      }


    });

  }

  toggle () {
    this.check = !this.check;
    const checkboxes: any = document.getElementsByName('foo');
    for (let i = 0, n = checkboxes.length; i < n; i++) {
      checkboxes[i].checked = this.check;
      this.alumnos[i].status = this.check;
    }
    this._asistencias.addAsistencias(this.alumnos, this.clase);
  }

  change (position: number) {
    const estado = !this.alumnos[position].estado;
    this.alumnos[position].estado = !this.alumnos[position].estado;
    const id = this.alumnos[position].id;
    this._asistencias.update(estado, id);
  }

  ngOnDestroy (): void {
    this.alumnosSubscription.unsubscribe();
  }
}

