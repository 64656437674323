import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


// import { DragulaModule } from 'ng2-dragula';




import { TaskboardComponent } from './taskboard.component';
import { PipesModule } from '../pipes.module';


@NgModule({
    imports: [
        CommonModule,
        PipesModule,
      //  DragulaModule
    ],
    declarations: [
        TaskboardComponent
    ],
    exports: [TaskboardComponent]
})
export class TaskboardModule { }
