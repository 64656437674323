import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Subject} from 'rxjs/Subject';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Router} from '@angular/router';
import {Rutas} from '../../../utiles/Rutas';
import 'rxjs/add/operator/map';

import Swal from 'sweetalert2';
import {NgxSpinnerService} from 'ngx-spinner';


@Injectable()
export class LoginService {

  private authSource = new BehaviorSubject(false);
  public auth$ = this.authSource.asObservable();
  public autorizado: boolean;

  private headerImageSource = new BehaviorSubject<any>(false);
  public $headerImage = this.headerImageSource.asObservable();

  private logoImageSource = new BehaviorSubject<any>(false);
  public logoImage$ = this.logoImageSource.asObservable();

  private lateralImageSource = new BehaviorSubject<any>(false);
  public $lateralImage = this.lateralImageSource.asObservable();

  private userImageSource = new BehaviorSubject<any>(false);
  public $userImage = this.userImageSource.asObservable();

  private roleSource = new Subject<string>();
  public role$ = this.roleSource.asObservable();

  private activateSource = new Subject<any>();
  public activate$ = this.activateSource.asObservable();

  private updateSource = new BehaviorSubject<any>(false);
  public update$ = this.updateSource.asObservable();


  constructor (private http: HttpClient, private router: Router, private spinner: NgxSpinnerService) {
  }

  forgotPassword (value: any) {
    this.spinner.show();
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {value};
    const data = JSON.stringify(json);
    const params = 'json=' + data;
    this.http.post(Rutas + 'forgot/password', params, {headers: headers}).subscribe(datas => {
      console.log(datas);
      if (datas['code'] === 200) {
        if (datas['id']) {
          this.updateSource.next(true);
          this.spinner.hide();
          Swal.fire('ok',
            'Se ha enviado un mensaje a tu correo',
            'success',
          ).then( (ok) => {
            if (ok.isConfirmed) {
              this.router.navigate(['/public/login']);
            }
          });
        }
      }
    }, error => {
      console.log(error);
    });
  }

  updatePassword (value: any) {
    this.spinner.show();
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {value};
    const data = JSON.stringify(json);
    const params = 'json=' + data;
    this.http.post(Rutas + 'update/password', params, {headers: headers}).subscribe(datas => {
      if (datas['code'] === 200) {
        if (datas['id']) {
          this.spinner.hide();
          this.updateSource.next(true);
          Swal.fire('ok',
            'Contraseña restablecida.',
            'success',
          ).then( (ok) => {
            if (ok.isConfirmed) {
              this.router.navigate(['/public/login']);
            }
          });
        }
      }
    }, error => {
      console.log(error);
    });
  }

  validateToken (token: string) {
    this.http.get(Rutas + 'validate/token?token=' + token).subscribe(data => {
      if (data['code'] === 200) {
        this.activateSource.next(data['id']);
      }
    }, err => {
      console.log(err.message);
    });
  }


  getUserImage () {
    if (this.getIdentity()) {
      this.userImageSource.next(this.getIdentity().img);
    }
  }

  getLogo () {
   return this.http.get(Rutas + 'get/logo');
  }

  getImages () {
    if (this.getIdentity()) {
      this.http.get(Rutas + 'get/images?token=' + this.getToken() + '&id=' + this.getIdentity().centro.id).subscribe(data => {
        if (data['code'] === 200) {
          this.headerImageSource.next(data['id'].header);
          this.lateralImageSource.next(data['id'].sidebar);
          return;
        }
      }, err => {
        console.log(err.message);
      });
    } else {
      this.headerImageSource.next('images/centro/not_header.jpg');
      this.lateralImageSource.next('images/centro/not_sidebar.jpg');
      this.logoImageSource.next('images/centro/not_logo.png');
    }


  }


  login (user: string, password: string) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {username: user, password: password};
    const data = JSON.stringify(json);
    const params = 'json=' + data;
    this.http.post(Rutas + 'login', params, {headers: headers}).subscribe(datas => {
      if (datas['code'] === 200) {
        localStorage.setItem('identity', JSON.stringify(datas['id']['id']));
        localStorage.setItem('token', datas['id']['token']);
        this.authSource.next(true);
        this.roleSource.next(this.getIdentity().role);
      } else {
        Swal.fire('LOGIN INCORRECTO', 'Introduce un usuario/contraseña válido', 'error');
      }
    }, error => {
      console.log(error);
    });
  }

  getIdentity () {
    const identity = JSON.parse(localStorage.getItem('identity'));
    if (identity !== null) {
      return identity;
    }
    return false;
  }

  getToken () {
    const token = localStorage.getItem('token');
    if (token !== null) {
      return token;
    }
    return false;
  }

  checkToken () {
    this.http.get(Rutas + 'checktoken?token=' + this.getToken()).subscribe(data => {
      if (data['code'] === 200) {
        this.authSource.next(true);
      } else {
        this.logout();
      }
    });
  }

  authGuardAdmin (): Promise<boolean> {
    const url = Rutas + 'check/role/admin?token=' + this.getToken() + '&id=' + this.getIdentity().id;
    return new Promise(function (resolve, reject) {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            if (xhr.response === 'true') {
              resolve(true);
            } else {
              resolve(false);
            }
          } else {
            reject(xhr.response);
          }
        }
      };
      xhr.open('GET', url, true);
      xhr.send();
    });
  }

  authGuardMaestro (): Promise<boolean> {
    const url = Rutas + 'check/role/maestro?token=' + this.getToken() + '&id=' + this.getIdentity().id;
    return new Promise(function (resolve, reject) {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            if (xhr.response === 'true') {
              resolve(true);
            } else {
              resolve(false);
            }
          } else {
            reject(xhr.response);
          }
        }
      };
      xhr.open('GET', url, true);
      xhr.send();
    });
  }


  authGuardAlumno (): Promise<boolean> {
    const url = Rutas + 'check/role/alumno?token=' + this.getToken() + '&id=' + this.getIdentity().id;
    return new Promise(function (resolve, reject) {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            if (xhr.response === 'true') {
              resolve(true);
            } else {
              resolve(false);
            }
          } else {
            reject(xhr.response);
          }
        }
      };
      xhr.open('GET', url, true);
      xhr.send();
    });
  }

  authGuardPadre (): Promise<boolean> {
    const url = Rutas + 'check/role/padre?token=' + this.getToken() + '&id=' + this.getIdentity().id;
    return new Promise(function (resolve, reject) {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            if (xhr.response === 'true') {
              resolve(true);
            } else {
              resolve(false);
            }
          } else {
            reject(xhr.response);
          }
        }
      };
      xhr.open('GET', url, true);
      xhr.send();
    });
  }


  logout () {
    localStorage.clear();
    this.authSource.next(false);
    this.router.navigate(['/public/login']);
  }

}
