<!--Simple Table Starts-->
<section id="simple">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Listado de examenes</h4>
                    <a class="back-button" href="javascript:history.back();"><i class="ft-arrow-left"></i></a>

                </div>
                <div class="card-body">
                    <div class="card-block">
                        <ng2-smart-table [settings]="settings" (custom)="ver($event)" [source]="source" (onCreateConfirm)="fileChangeEvent($event)" (deleteConfirm)="onDeleteConfirm($event)"></ng2-smart-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>