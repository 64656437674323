import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {AdminAlumnoService} from '../services/admin-alumno.service';
import {UploadService} from '../../../services/upload.service';



@Component({
  selector: 'app-upload-examen',
  templateUrl: './upload-examen.component.html',
  styleUrls: ['../admin.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UploadExamenComponent implements OnInit, OnDestroy {
  public examenes: Array<any> = [];
  public examenesSubscription: Subscription = null;
  public id: number;
  public examen = {
    files: []
  };
  public ver: boolean;
  public filesToUpload: Array<File>;
  public index: number;


  constructor (private route: ActivatedRoute, private router: Router, private _admin: AdminAlumnoService, private _upload: UploadService) {
    this._admin.getExamenesActivos();
  }

  ngOnInit () {
    this.examenesSubscription = this._admin.datos$.subscribe(res => {
      this.examenes = res;
    });
  }

  ngOnDestroy () {
    this.examenesSubscription.unsubscribe();
  }

  verExamen (index: number) {
    this.index = index;
    this.examen = this.examenes[index];
    this.ver = true;
  }

  deleteFile (id: number, task_id: number) {
    this._admin.deleteFileExamen(id);
    this.examenes.forEach((value, index) => {
      if (value.id === task_id) {
        this.examenes[index].files.forEach((v, i) => {
          if (v.id === id) {
            this.examenes[index].files.splice(i, 1);
          }
        });
      }
    });
    }

  changeEstado (id: number, es: boolean) {
    this.examenes[this.index].estado = !es;
    es = !es;
    this._admin.changeStatusExamen(es, id);
  }

  addTaskFile () {
    const id = this.examenes[this.index].id;
    this._upload
      .makeFileRequest(id, this.filesToUpload, 'imagen', 'examen_alumno')
      .then(
        (result: any) => {
          this._admin.getExamen(id);
          this._admin.examen$.subscribe(res => {
            this.examen.files = res;
          });
        },
        error => {
          console.log(error);
        }
      );
  }

  fileChangeEvent (fileinput: any) {
    this.filesToUpload = <Array<File>>fileinput.target.files;
    this.addTaskFile();
  }

  openViewer () {
    document.getElementById('buscadorPersona').click();
  }

}

