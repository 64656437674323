import {Component, OnInit, ElementRef, Input, Renderer2, ViewChild, AfterViewChecked} from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {InboxService} from './inbox.service';
import {Subscription} from 'rxjs/Subscription';
import {UploadService} from '../../services/upload.service';
import {ImagesPipe} from '../../../pipes/images.pipe';
import {DomSanitizer} from '@angular/platform-browser';
import {DatePipe} from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss'],
})

export class InboxComponent implements OnInit, AfterViewChecked {
  public isCollapsed = false;
  public mensajes: any[] = [];
  public users: any[] = [];
  @Input() id_usuario: number;
  @Input() id_centro: number;
  @Input() aula: any;
  public mensaje: any;
  public user: any = {};
  public itKey: boolean;
  public array_users: any[] = [];
  public reponer_users: any[] = [];
  public subject: string;
  public msg: string;
  public filesToUpload: Array<File>;
  filesUp: Array<File>[] = [];
  public fileExist: boolean;
  public fileTitle: string;
  public array_files: any[] = [];
  public enviados: any[] = [];
  public marcador: string;
  grupo: any;
  countMessages = 0;
  newMessage: any;
  contacto: any = {};
  cont = '';
  mensajestemp: any;

  @ViewChild('scrollMe') private myScrollContainer: ElementRef;


  public mensajesSubscription: Subscription = null;
  public enviadosSubscription: Subscription = null;
  closeResult: string;

  constructor(private elRef: ElementRef,
              private modalService: NgbModal,
              private _inbox: InboxService,
              private renderer: Renderer2,
              private _upload: UploadService,
              private sanitizer: DomSanitizer,
              private datePipe: DatePipe) {
    this.marcador = 'recibidos';
  }

  ngOnInit() {
    this.scrollToBottom();
    const dominio = window.location.host;
    if (dominio !== 'localhost:4200') {
      $.getScript('./assets/js/inbox.js');
    } else {
      $.getScript('./assets/js/inbox.js');
    }
    this.getMessages(this.id_usuario, 'user');
    this.mensajesSubscription = this._inbox.mensajes$.subscribe(res => {
      this.mensajes = res;
      this.mensajestemp = res;

    });
    console.log(this.contacto);
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  viewFile(url) {
    const imagesPipe = new ImagesPipe();
    const u = imagesPipe.transform(url, '');
    window.open(u);
  }

  addUser(position: number, id: number) {
    const div = document.getElementById('sendUsers');
    const bubble = this.renderer.createElement('span');
    const palabra = this.users[position].nombre + 'hola';
    const user = this.renderer.createText(palabra);

    // Añadimos al array de mensajes
    this.array_users.push(this.users[position]);
    // Borramos de la lista
    this.users.splice(position, 1);
    this.users = [];
    this.renderer.addClass(bubble, 'senders');
    this.renderer.appendChild(bubble, user);

    const del = this.renderer.createElement('i');
    const xdel = this.renderer.createText('X');
    this.renderer.addClass(del, 'del');
    this.renderer.appendChild(del, xdel);
    this.renderer.setAttribute(del, 'id', id.toString());
    this.renderer.listen(del, 'click', () => {
      this.delUser(id);
    });

    this.renderer.appendChild(div, bubble);
    this.renderer.appendChild(bubble, del);
    this.cont = '';
  }

  delUser(id: any) {
    const spans = document.getElementById(id).parentElement;
    spans.remove();
    this.array_users.forEach((num) => {
      if (num.id === id) {
        this.users.push(num);
      }
    });
    this.users = Array.from(new Set(this.users));
  }

  onSubmit(user) {
    if (this.array_users.length > 0) {
      this.mensaje = [];
      this.contacto = {
        apellidos: user.apellidos,
        fecha: '11',
        id: -1,
        id_emi: this.id_usuario,
        id_user: user.id,
        img: 'sdsd',
        leido: 0,
        mensaje: 'hola',
        nombre: user.nombre
      };

    } else {
      alert('Debes seleccionar un contacto');
    }
  }

  delMessage(id: number) {
    this._inbox.delMessage(id, this.id_usuario);
    this._inbox.mensajes$.subscribe(res => {
      this.mensajes = res;
    });
  }

  getGrupos(grupo: string) {
    this.mensajes = this.mensajestemp;
    if (grupo !== 'todos') {
      this.mensajes = this.mensajes.filter(item => item.role === grupo);
    }
  }

  getEnviados() {
    this._inbox.getMessages(this.id_usuario, 'send');
    this.enviadosSubscription = this._inbox.mensajes$.subscribe(res => {
      this.mensajes = res;
      this.marcador = 'enviados';
    });
  }

  getRecibidos() {
    this._inbox.getMessages(this.id_usuario, 'user');
    this.enviadosSubscription = this._inbox.mensajes$.subscribe(res => {
      this.mensajes = res;
      this.marcador = 'recibidos';
    });
  }

  getNoLeidos() {
    this._inbox.getMessages(this.id_usuario, 'noleidos');
    this.enviadosSubscription = this._inbox.mensajes$.subscribe(res => {
      this.mensajes = res;
      this.marcador = 'noleidos';
    });
  }

  showFile(): void {
    document.getElementById('files').click();
  }

  fileChangeEvent(fileInput: any) {
    this.filesToUpload = <Array<File>>fileInput.target.files;
    this.filesUp[this.countMessages] = <Array<File>>fileInput.target.files;
    // const preview = (<HTMLInputElement>document.getElementById('files'));
    const file = this.filesToUpload[0];
    const reader = new FileReader();
    this.countMessages++;

    reader.onloadend = function () {
      // preview.src = reader.result;
    };
    if (file) {
      this.fileExist = true;
      this.fileTitle = file.name;
      this.array_files.push(file);
    }
  }

  delFile(file) {
    for (let i = 0; i < this.array_files.length; i++) {
      if (this.array_files[i].name === file.name) {
        this.array_files.splice(i, 1);
      }
    }
  }

  key(event: any) {
    if (event.target.value !== '') {
      this.array_users = [];
      if (this.aula === 'maestro') {
        this._inbox.getAulaMaestro(this.id_usuario, event.target.value);
        this._inbox.users$.subscribe(res => {
          this.users = res;
          this.reponer_users = res;
          this.itKey = true;
          return;
        });
      } else if (this.aula === 'alumno') {
        this._inbox.getAulaAlumno(this.id_usuario, event.target.value);
        this._inbox.users$.subscribe(res => {
          this.users = res;
          this.reponer_users = res;
          this.itKey = true;
          return;
        });
      } else if (this.aula === 'padre') {
        this._inbox.getPadre(this.id_usuario, event.target.value);
        this._inbox.users$.subscribe(res => {
          this.users = res;
          this.reponer_users = res;
          this.itKey = true;
          return;
        });
      } else {
        this._inbox.getReceptoresAdmin(this.id_usuario, event.target.value);
        this._inbox.users$.subscribe(res => {
          this.users = res;
          this.reponer_users = res;
          this.itKey = true;
          return;
        });
      }
      this.users = [];
      this.itKey = false;
    }
  }

  getMessages(id: number, tipo: string) {
    this._inbox.getMessages(id, tipo);
  }


  getMessage(mensaje) {
    this.contacto = mensaje;
    this._inbox.httpResponse2(this.id_usuario, mensaje.id_user).then(result => {
      if (result['code'] === 200) {
        this.mensaje = result['id'];
        this.leerMensajes();
      }
    });

  }

  public getSantizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustHtml(url);
    // return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  readMessage() {
    this._inbox.readMessage(this.mensaje.id, this.id_usuario);
  }

  // inbox user list click event function
  SetActive(event, pos) {
    this.mensajes[pos].leido = 0; // Marcamos como leido
    // Bloque para hacer bnoita la seleccion
    const hElement: HTMLElement = this.elRef.nativeElement;
    // now you can simply get your elements with their class name
    const allAnchors = hElement.querySelectorAll('.users-list-padding > a.list-group-item');
    const menuMensajes = hElement.querySelectorAll('.list-group-messages > a.list-group-item');
    [].forEach.call(menuMensajes, function (item: HTMLElement) {
      item.setAttribute('class', 'list-group-item list-group-item-action no-border');
    });
    // do something with selected elements
    [].forEach.call(allAnchors, function (item: HTMLElement) {
      item.setAttribute('class', 'list-group-item list-group-item-action no-border');
    });
    if (event.target.className === 'list-group-item list-group-item-action no-border') {
      event.currentTarget.setAttribute('class', 'list-group-item active no-border');
    } else {
      event.currentTarget.setAttribute('class', 'list-group-item list-group-item-action bg-blue-grey bg-lighten-5 ' +
        'border-right-primary border-right-2');
    }
  }

  // compose popup start
  open(content) {
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.array_users = [];
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    this.array_users = [];
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  // Mensaje nuevo
  send() {
    const date = new Date();
    const mensaje = {
      fecha: this.datePipe.transform(date, 'HH:MM dd/MM'),
      id: this.id_usuario,
      id_men: 1,
      leido: '1',
      mensaje: this.newMessage
    };
    if (this.contacto.id === -1) {
      this.mensaje = [];
    }
    this.mensaje.push(mensaje);

    const users = {
      nombre: this.contacto.nombre,
      id: this.contacto.id_user
    };
    const arrayUsers: any[] = [];
    arrayUsers.push(users);
    const msg = {
      subject: '1',
      mensaje: this.newMessage,
      receiver: arrayUsers,
      emmiter: this.id_usuario
    };
    this.newMessage = '';

    this._inbox.add(msg).then(res => {
      this.getMessages(this.id_usuario, 'user');

    });
  }

  async leerMensajes() {
    for (const men of this.mensaje) {
      if (+men.leido === 0 && men.id !== this.id_usuario) {
        this._inbox.readMessage(men.id_men, this.id_usuario);
      }
    }
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {
    }
  }
}
