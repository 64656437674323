import {
  Component, OnDestroy, OnInit, ViewEncapsulation
} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {ClasesMService} from '../services/clasesM.service';
import {UsersService} from '../../../admin/admin-users/services/users.service';


@Component({
  selector: 'app-alumnosM',
  templateUrl: './alumnosM.component.html',
  styleUrls: ['../clasesM.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AlumnosMComponent implements OnInit, OnDestroy {
  public alumnos: Array<any> = [];


  public id: number;

  public alumnosSubscription: Subscription = null;

  constructor(private _route: ActivatedRoute,
              private _clases: ClasesMService,
              private _router: Router,
              private _user: UsersService) {
    this.id = this._route.snapshot.parent.params['id'];
    this._clases.getAlumnos(this.id);
  }

  ngOnInit() {
    this.pushAlumnos();
  }

  open(id: number) {
    this._router.navigate(['/maestro/alumnos', id]);
  }

  // ********* Subsripciones *********
  pushAlumnos() {
    this.alumnosSubscription = this._clases.alumnos$.subscribe(res => {
      this.alumnos = res;
    });
  }

  // Fin
  ngOnDestroy(): void {
    this.alumnosSubscription.unsubscribe();
    localStorage.setItem('id_curso', '' + this.id);
  }
}
