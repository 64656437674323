import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {AdminExamenesService} from '../services/admin-examenes.service';


@Component({
  selector: 'app-config-examenes',
  templateUrl: './config-examenes.component.html',
  styleUrls: ['../admin-examenes.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ConfigExamenesComponent implements OnInit, OnDestroy {
 id: any;

  constructor (private _route: ActivatedRoute, private router: Router, private _service: AdminExamenesService) {
    console.log(' awui esrtoy')
   this._route.params.subscribe( id => {
      if (id) {
        this.id = id;
        this._service.getExamen(this.id, 1);
      }
    });

  }

  ngOnInit (): void {}
  ngOnDestroy (): void {}



}
