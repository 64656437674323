import { Component, Input, ViewChild, OnDestroy, OnInit } from '@angular/core';
import { ProfileService } from '../services/profile.service';
import { Subscriber } from 'rxjs/Subscriber';
import { Subscription } from 'rxjs/Subscription';
import { NgForm } from '@angular/forms';
import { CentroService } from '../../admin-centro/services/centro.service';
import { LoginService } from '../../../pages/services/login.service';


@Component({
    selector: 'app-profile-password',
    templateUrl: './profile-password.component.html'
})

export class ProfilePasswordComponent implements OnDestroy, OnInit {
   
    
    private auth: boolean;
    id: number;
    user: string;
    n_password: any;
    r_password: any;
    pass: any;
    

    @ViewChild('f') loginForm: NgForm;
    @ViewChild('p') passForm: NgForm;
    private authSubscribe: Subscription = null;
    private userSubscription: Subscription = null;
    constructor(private _user: ProfileService, private _login: LoginService) {
        this.id = this._login.getIdentity().id;
        this._user.getUser(this.id);
    }

    ngOnInit(): void {
        this.userSubscription = this._user.user$.subscribe(data => {
            this.user = data;
        });
           
    }
    ngOnDestroy(): void {
        this.userSubscription.unsubscribe();
    }

    onSubmit() {
        if (this.loginForm.valid) {

            this._user.updateAuth(this.loginForm.value);

            this.authSubscribe = this._user.auth$.subscribe(data => {
                if (data) {
                    this.auth = true;
                    this._user.updateUser(this.loginForm.value);
                } else {
                    alert('El password no es correcto');
                }
            });
            this.userSubscription = this._user.user$.subscribe(data => {
                if (data) {
                    this.user = data;
                } else {
                    alert('El usuario ya existe');
                }
            });
        }
    }

    onSubmitPass() {
        if (this.passForm.valid) {
            if (this.passForm.value.r_password === this.passForm.value.n_password) {
                this._user.updateAuth(this.passForm.value);
                this.authSubscribe = this._user.auth$.subscribe(data => {
                    if (data) {
                        this.auth = true;
                        this._user.updatePass(this.passForm.value);
                    } else {
                        alert('El password no es correcto');
                    }
                });
            } else {
                alert('Los passwords no son iguales');
            }
        }

    }

}
