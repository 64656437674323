<div class="mt-3">
  <div class="table-responsive">
    <table class="table">
      <thead>
      <tr>
        <th style="border-top: none !important;">Imagen</th>
        <th style="border-top: none !important;">Nombre</th>
        <th style="border-top: none !important;">Apellidos</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let user of users; let i = index">
        <td><img [src]="user.img | images: ''" class="media-object round-media img-thumbnail" style="height: 45px"></td>
        <td>{{user.nombre | titlecase}}</td>
        <td>{{user.apellidos | titlecase}}</td>
        <td>
          <ul class="list-inline">
            <li class="success p-0 list-inline-item">
              <i [routerLink]="['/admin/users/',user.id, 'alumnos']" class="ft-user font-medium-3 mr-2"></i>
            </li>
          </ul>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

