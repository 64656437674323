import {RouteInfo} from './sidebar.metadata';

// Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [

  {
    path: '', title: 'Admin. Centro', icon: 'icon-settings', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
      {
        path: '/admin/centro',
        title: 'Centro',
        icon: 'icon-settings',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/admin/aulas',
        title: 'Aulas',
        icon: 'icon-settings',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/admin/agenda',
        title: 'Agenda',
        icon: 'icon-settings',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/admin/calendario',
        title: 'Calendario',
        icon: 'icon-settings',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/admin/administracion',
        title: 'Administracion',
        icon: 'icon-settings',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
    ]
  },

  {
    path: '', title: 'Alquiler', icon: 'ft-clipboard', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
      {
        path: '/admin/listar-contacto',
        title: 'Contacto',
        icon: 'ft-users',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/admin/listar-alquiler',
        title: 'Alquiler',
        icon: 'ft-file-text',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
    ]
  },

  {
    path: '', title: 'Comercial', icon: 'ft-pie-chart', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [

      {
        path: '/admin/comercial',
        title: 'Listado',
        icon: 'ft-users',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },

      {
        path: '/admin/estadisticas-comercial',
        title: 'Estadísticas',
        icon: 'ft-bar-chart',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
    ]
  },


  {
    path: '', title: 'Listado', icon: 'ft-users', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
      {
        path: '/admin/list/alumnos/0',
        title: 'Alumnos',
        icon: 'ft-align-justify',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/admin/list/padres/0',
        title: 'Padres',
        icon: 'ft-align-justify',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/admin/list/profesores/0',
        title: 'Profesores',
        icon: 'ft-align-justify',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/admin/list/administrativos/0',
        title: 'Administrativos',
        icon: 'ft-align-justify',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },

    ]
  },


  {
    path: '', title: 'Crear', icon: 'ft-user-plus', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
     /* {
        path: '/admin/crear/alumnos',
        title: 'Alumnos',
        icon: 'ft-edit',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },*/
      {
        path: '/admin/crear/profesores',
        title: 'Profesores',
        icon: 'ft-edit',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/admin/crear/administrativos',
        title: 'Administrativos',
        icon: 'ft-edit',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },

    ]
  },
  {
    path: '/admin/inbox',
    title: 'Mensajeria interna',
    icon: 'ft-mail',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: []
  },
  {
    path: '/admin/tablon',
    title: 'Tablón de anuncios',
    icon: 'ft-message-square',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: []
  },


];
