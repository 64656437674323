<div class="row">
  <div class="col-md-12 col-sm-12">

    <form class="form form-horizontal" novalidate="" #horarioForm="ngForm">
      <input type="text" class="form-control border-dark" name="fantasma" [(ngModel)]="fantasma" hidden required>
      <div class="col-md-12 col-sm-12 float-left ">
        <div class="input-group mb-5 crearHorarioRow" *ngIf="role === 'ROLE_ADMIN'">
          <div class="input-group-prepend">
            <button class="btn btn-dark opa-1" type="button" disabled>
              <i class="fa fa-calendar"></i>
            </button>
          </div>

          <select id="dia" class="form-control custom-select selectHorario" (blur)="controlValido('dia')"
                  (focus)="controlValido('dia')" required>
            <option value="" selected>Dia de la semana</option>
            <option *ngFor="let dia of dias; let i=index;" [value]="dia.valor">{{dia.nombre}}</option>
          </select>


          <div class="timePickerHorario">
            Entrada
            <ngb-timepicker id="in" [(ngModel)]="timeEntrada" name="entrada" (blur)="controlValido('in')"
                            (focus)="controlValido('in')"></ngb-timepicker>
          </div>

          <div class="timePickerHorario">
            Salida
            <ngb-timepicker id="out" [(ngModel)]="timeSalida" name="salida" (blur)="controlValido('out')"
                            (focus)="controlValido('out')"></ngb-timepicker>
          </div>

          <input id="margen" class="form-control selectHorario" placeholder="Margen minutos para fichar"
                 type="number" (blur)="controlValido('margen')" (focus)="controlValido('margen')" required>

          <div class="input-group-append">
            <button class="btn btn-dark" type="button" (click)="open('dia', 'in', 'out', 'margen')">
              <i class="fa fa-plus"></i>
            </button>
          </div>
        </div>

        <div class="list-group" *ngIf="horarios && horarios.length > 0">
          <ng-container *ngFor="let horario of horarios; let i = index;">
            <div class="input-group">
              <div class="input-group-prepend">
                <button *ngIf="disabled" class="btn btn-dark opa-1" type="button" (click)="able()">
                  <i class="fa fa-edit"></i>
                </button>
                <button *ngIf="!disabled" class="btn btn-dark opa-1" type="button" (click)="update(horario)">
                  <i class="fa fa-edit"></i>
                </button>
              </div>

              <!-- ************ DIA ******************-->
              <select class="form-control custom-select" [(ngModel)]="horario.dia" name="diag{{i}}"
                      [disabled]="disabled">

                <option *ngFor="let dia of dias; let i=index;" [value]="dia.valor">{{dia.nombre}}</option>
              </select>

              <input type="time" class="form-control border-dark" name="inat{{i}}" [(ngModel)]="horario.inat" required
                     [readOnly]="disabled">

              <input type="time" class="form-control border-dark" name="outat{{i}}" [(ngModel)]="horario.outat" required
                     [readOnly]="disabled">


              <!-- ************ MARGEN ******************-->
              <input type="number" class="form-control" name="margen{{i}}" [(ngModel)]="horario.margen"
                     required [readOnly]="disabled">

              <div class="input-group-append" id="button-addon4" *ngIf="role === 'ROLE_ADMIN'">
                <button class="btn btn-outline-danger" type="button" (click)="borrar(i)">
                  <i class="fa fa-trash"></i>
                </button>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

    </form>
  </div>

</div>
