import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder} from '@angular/forms';
import {TablonService} from '../tablon.service';
import {RutasImg} from '../../../../utiles/Rutas';

@Component({
  selector: 'app-detalle-modal',
  templateUrl: './detalle-modal.component.html',
  styleUrls: ['./detalle-modal.component.scss']
})
export class DetalleModalComponent implements OnInit {
  mensaje: any;
  rutaImg = RutasImg;
  constructor(public dialoRef: MatDialogRef<DetalleModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, private tablonService: TablonService) {
    this.mensaje = this.data;
  }
  ngOnInit(): void {
  }

}
