import {Component, Input} from '@angular/core';


@Component({
  selector: 'app-doughnut-chart',
  template: `
    <div style="display: block">
      <canvas baseChart
              [data]="doughnutChartData"
              [labels]="doughnutChartLabels"
              [chartType]="doughnutChartType"
              (chartHover)="chartHovered($event)"
              (chartClick)="chartClicked($event)"></canvas>
    </div>`
})
export class DoughnutComponent  {
  // Doughnut
 @Input() doughnutChartLabels = [];
 @Input() doughnutChartData = [];
 @Input() doughnutChartType = 'doughnut';

  // events
  public chartClicked (e: any): void {
    console.log(e);
  }

  public chartHovered (e: any): void {
    console.log(e);
  }
}
