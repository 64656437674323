<mat-card class="example-card" >
  <mat-card-header>
    <div mat-card-avatar style="background-image: url('assets/img/centro/logo.png');
  background-size: cover;"></div>
    <mat-card-title>{{mensaje.titulo}}</mat-card-title>
    <mat-card-subtitle>{{mensaje.fecha.date | date: 'dd/MM/yyyy'}}</mat-card-subtitle>
  </mat-card-header>
  <img mat-card-image src="{{rutaImg + mensaje.imgCabecera}}" style="background-color: #dedddd">
  <div class="mt-1" *ngIf="mensaje.adjunto">
    <a href="{{rutaImg + mensaje.adjunto}}" target="_blank"> Ver Adjunto {{mensaje.adjunto | slice:-12}}</a>
  </div>

  <mat-card-content class="mt-1">
    <div class="text-oneline"  [innerHTML]="mensaje.mensaje">
    </div>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button mat-dialog-close="">CERRAR</button>
  </mat-card-actions>
</mat-card>
