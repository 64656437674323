import {NgModule} from '@angular/core';
import {Routes, RouterModule, CanActivate} from '@angular/router';
import {AdminComponent} from './admin.component';


import {AuthGuardService as AuthGuard} from '../../services/auth-guard.service';

// INBOX ROUTING
import {AdminInboxComponent} from '../admin-inbox/admin-inbox.component';

// ADMIN AULA
import {AulasComponent} from '../admin-aulas/main-aula/aulas.component';

// AULA ROUTING
import {AddAulaComponent} from '../admin-aulas/main-aula/add/add-aula.component';
import {AulasListaComponent} from '../admin-aulas/main-aula/list/aulas-lista.component';
import {AulasViewComponent} from '../admin-aulas/view-aula/aulas-view.component';
import {ViewComponent} from '../admin-aulas/view-aula/view.component';


// USERS ROUTING
import {UsersComponent} from '../admin-users/users.component';
import {DatosComponent} from '../admin-users/componentes/datos.component';
import {ContactoComponent} from '../admin-users/componentes/contacto.component';
import {TutoresComponent} from '../admin-users/componentes/tutores.component';
import {BancariosComponent} from '../admin-users/componentes/bancarios.component';
import {TitulosComponent} from '../admin-users/componentes/titulos.component';
import {ListaComponent} from '../admin-users/componentes/lista.component';


// CENTRO ROUTING
import {AddComponent} from '../admin-centro/componentes/add.component';
import {CursosAsignaturasComponent} from '../admin-centro/componentes/cursos-asignaturas.component';
import {CursosListaComponent} from '../admin-centro/componentes/cursos-lista.component';
import {CursosComponent} from '../admin-centro/componentes/cursos.component';
import {CentroComponent} from '../admin-centro/centro.component';

// PROFILE ROUTING
import {ProfileComponent} from '../admin-profile/profile.component';
import {ProfileContactoComponent} from '../admin-profile/componentes/profile-contacto.component';
import {ProfilePasswordComponent} from '../admin-profile/componentes/profile-password.component';
import {ProfileEditComponent} from '../admin-profile/componentes/profile-edit.component';
import {AulaComponent} from '../aula/aulas.componet';
import {CredencialesComponent} from '../admin-users/componentes/credenciales.component';
import {DatosPadreComponent} from '../admin-padres/componentes/datos-padre.component';
import {PadreComponent} from '../admin-padres/padre.component';
import {HijosPadreComponent} from '../admin-padres/componentes/hijos-padre.component';
import {AdminStatsComponent} from '../admin-stats/admin-stats.component';
import {AdminAgendaComponent} from '../admin-agenda/admin-agenda.component';
import {AddConfiguracionComponent} from '../admin-agenda/componentes/add-configuracion.component';
import {AgendaListaComponent} from '../admin-agenda/componentes/agenda-lista.component';
import {AgendaComponent} from '../../componentes/agenda/agenda-aula/agenda.component';
import {TemporadaComponent} from '../admin-centro/componentes/temporada.component';
import {CalendarioComponent} from '../../componentes/calendario/calendario.component';
import {AsistenciasComponent} from '../../componentes/asistencias/asistencias.component';
import {JornadaComponent} from '../admin-users/componentes/jornada/jornada.component';
import {HorarioComponent} from '../admin-users/componentes/horario/horario.component';
import {CrearDatosComponent} from '../crear-datos/crear-datos.component';
import {ConvalidacionesComponent} from '../admin-users/componentes/convalidaciones/convalidaciones.component';
import {PdfComponent} from '../admin-aulas/pdf/pdf.component';
import {AdminAdministracionComponent} from '../admin-administracion/admin-administracion.component';
import {GestorDocumentalComponent} from '../admin-administracion/components/gestor-documental/gestor-documental.component';
import {DocumentosComponent} from '../admin-users/componentes/documentos/documentos.component';

import {ComercialComponent} from '../comercial/comercial.component';
import {CrearContactoComponent} from '../alquiler/crear-contacto/crear-contacto.component';
import {ListarContactoComponent} from '../alquiler/listar-contacto/listar-contacto.component';
import {ListarAlquilerComponent} from '../alquiler/listar-alquiler/listar-alquiler.component';
import {GestionClasesComponent} from '../admin-administracion/components/gestion-clases/gestion-clases.component';
import {EstadisticasComercialComponent} from '../comercial/componentes/estadisticas-comercial/estadisticas-comercial.component';
import {ExportacionesComponent} from '../admin-administracion/components/exportaciones/exportaciones.component';
import {ObservacionesComponent} from '../admin-users/componentes/observaciones/observaciones.component';
import {TablonModule} from '../../componentes/tablon/tablon.module';
import {TablonComponent} from '../../componentes/tablon/tablon.component';
import {ClaustroComponent} from '../admin-aulas/claustro/claustro.component';
import {CursosIntensivosComponent} from '../admin-administracion/components/cursos-intensivos/cursos-intensivos.component';
import {CursosExtracurricularesComponent} from '../admin-users/componentes/cursos-extracurriculares/cursos-extracurriculares.component';
import {HistorialComponent} from '../admin-users/componentes/historial/historial.component';
import {AlumnosComponent} from '../admin-users/componentes/alumnos/alumnos.component';



const routes: Routes = [
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'centro',
        component: CentroComponent,
        children: [
          {path: 'add', component: AddComponent},
          {path: 'curso', component: CursosComponent},
          {path: 'lista', component: CursosListaComponent},
          {path: 'temporada', component: TemporadaComponent},
          {path: 'asignaturas/:id', component: CursosAsignaturasComponent},
          {path: '**', pathMatch: 'full', redirectTo: 'add'}
        ]
      },
      {
        path: 'aulas',
        component: AulasComponent,
        children: [
          {path: 'lista', component: AulasListaComponent},
          {path: 'add', component: AddAulaComponent},

          {path: '**', pathMatch: 'full', redirectTo: 'lista'}
        ]
      },
      {
        path: 'aula/:id',
        component: ViewComponent,
        children: [
          {path: 'view', component: AulasViewComponent},
          {path: 'agenda', component: AgendaComponent},
          {path: 'aula', component: AulaComponent},
          {path: 'pdf', component: PdfComponent},
          {path: 'claustro', component: ClaustroComponent}
        ]
      },
      {
        path: 'profile',
        component: ProfileComponent,
        children: [
          {path: 'editar', component: ProfileEditComponent},
          {path: 'contacto', component: ProfileContactoComponent},
          {path: 'password', component: ProfilePasswordComponent},
          {path: '**', pathMatch: 'full', redirectTo: 'editar'}

        ]
      },
      {
        path: 'padre/:id',
        component: PadreComponent,
        children: [
          {path: 'dato', component: DatosPadreComponent},
          {path: 'hijos', component: HijosPadreComponent},
          {path: '**', pathMatch: 'full', redirectTo: 'dato'}
        ]
      },
      {
        path: 'stats',
        component: AdminStatsComponent,
      },
      {
        path: 'users/:id/:type',
        component: UsersComponent,
        children: [
          {path: 'dato', component: DatosComponent},
          {path: 'contacto', component: ContactoComponent},
          {path: 'tutores', component: TutoresComponent},
          {path: 'bancarios', component: BancariosComponent},
          {path: 'titulos', component: TitulosComponent},
          {path: 'credenciales', component: CredencialesComponent},
          {path: 'asistencias', component: AsistenciasComponent},
          {path: 'jornada', component: JornadaComponent},
          {path: 'horario', component: HorarioComponent},
          {path: 'convalidaciones', component: ConvalidacionesComponent},
          {path: 'documentacion', component: DocumentosComponent},
          {path: 'observaciones', component: ObservacionesComponent},
          {path: 'cursosextracurriculares', component: CursosExtracurricularesComponent},
          {path: 'historial', component: HistorialComponent},
          {path: 'alumnos', component: AlumnosComponent},
          {path: '**', pathMatch: 'full', redirectTo: 'dato'}
        ]
      },
      {
        path: 'crear/:type',
        component: CrearDatosComponent,
        data: {
          title: 'Crear'
        }
      },
      {
        path: 'inbox',
        component: AdminInboxComponent,
        data: {
          title: 'Correo'
        }
      },
      {
        path: 'tablon',
        component: TablonComponent,
        data: {
          title: 'Tablón de anuncios'
        }
      },
      {
        path: 'comercial',
        component: ComercialComponent,
        data: {
          title: 'Sección comercial'
        }
      },
      {
        path: 'estadisticas-comercial',
        component: EstadisticasComercialComponent,
        data: {
          title: 'Estadisticas de contactos comerciales'
        }
      },
      {
        path: 'crear-contacto',
        component: CrearContactoComponent,
        data: {
          title: 'Crear Contacto'
        }
      },
      {
        path: 'listar-contacto',
        component: ListarContactoComponent,
        data: {
          title: 'Listar Contacto'
        }
      },
      {
        path: 'listar-alquiler',
        component: ListarAlquilerComponent,
        data: {
          title: 'Listar Alquiler'
        }
      },
      {
        path: 'list/:tipo/:aula',
        component: ListaComponent,
        data: {
          title: 'Listas'
        }
      },
      {
        path: 'agenda',
        component: AdminAgendaComponent,
        children: [
          {path: 'config', component: AddConfiguracionComponent},
          {path: 'config/:id', component: AddConfiguracionComponent},
          {path: 'lista', component: AgendaListaComponent},
          {path: '**', pathMatch: 'full', redirectTo: 'config'}

        ]
      },
      {
        path: 'calendario',
        component: CalendarioComponent,
      },
      {
        path: 'administracion',
        component: AdminAdministracionComponent,
        children: [
          {path: 'docu', component: GestorDocumentalComponent},
          {path: 'clases', component: GestionClasesComponent},
          {path: 'exportaciones', component: ExportacionesComponent},
          {path: 'cursosintensivos', component: CursosIntensivosComponent},
          {path: '**', pathMatch: 'full', redirectTo: 'docu'}
        ]
      },
    ],
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {
}
