import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'horario'
})
export class HorarioPipe implements PipeTransform {
  transform(d: number): string {
    const dias = [
      {valor: 1, nombre: 'Lunes'},
      {valor: 2, nombre: 'Martes'},
      {valor: 3, nombre: 'Miércoles'},
      {valor: 4, nombre: 'Jueves'},
      {valor: 5, nombre: 'Viernes'},
      {valor: 6, nombre: 'Sabado'},
      {valor: 0, nombre: 'Domingo'},
    ];
    if (d === 0) {
      return 'Domingo';
    } else {
       return dias[d - 1].nombre;
    }
  }
}
