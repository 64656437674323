<section id="simple" *ngIf="!ver">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">Examenes activos</h5>
        </div>
        <table class="table table-striped">
          <thead>
          <tr>
            <th scope="col">Asignatura <i [ngClass]="{'ft-chevron-up': order , 'ft-chevron-down': !order}" (click)="ordenar('asignatura')"></i></th>
            <th scope="col">Examen  <i [ngClass]="{'ft-chevron-up': order , 'ft-chevron-down': !order}" (click)="ordenar('examen')"></i></th>
            <th scope="col">Tipo  <i [ngClass]="{'ft-chevron-up': order , 'ft-chevron-down': !order}" (click)="ordenar('tipo')"></i></th>
            <th scope="col">Fecha  <i [ngClass]="{'ft-chevron-up': order , 'ft-chevron-down': !order}" (click)="ordenar('fecha_')"></i></th>
            <th scope="col">Acciones</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let examen of examenes; let i = index">
            <td>{{examen.asignatura}}</td>
            <td>{{examen.examen}}</td>
            <td>{{examen.tipo}}</td>
            <td>{{examen.fecha.date | date: "dd-MM-yy"}} </td>
            <td>
              <ul class="list-inline">
                <li class="success p-0 list-inline-item">
                  <i class="ft-eye font-medium-3 mr-2" (click)="verExamen(i)"></i>
                </li>
              </ul>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>

<section id="verTask" *ngIf="ver">
  <div class="row">
    <div class="col-sm-9">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">{{examen.examen}}</h5>
        </div>
      </div>

      <table class="table table-files" *ngIf="examen.files.length > 0">
        <thead>
        <tr>
          <th scope="col">Id</th>
          <th scope="col">Fecha</th>
          <th scope="col">Acciones</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let file of examen.files; let i = index">
          <td>{{i + 1 }}</td>
          <td>{{file.fecha.date | date: 'dd/MM/yy'}}</td>
          <td>
            <ul class="list-inline">
              <li class="list-inline-item danger p-0">
                <i class="ft-delete font-medium-3 mr-2" (click)="deleteFile(file.id, examen.id)"></i>
              </li>
              <li class="success p-0 list-inline-item">
                <i class="ft-file font-medium-3 mr-2" (click)="verFile(file.url)"></i>
              </li>
            </ul>
          </td>
        </tr>
        </tbody>

      </table>

    </div>

    <div class="col-3">
      <ul class="list-group tool-panel">
        <li class="list-group-item" (click)="ver_ = false">Volver <i class="ft-arrow-left"></i></li>

        <li class="list-group-item">Creado:
          <small>{{examen.fecha.date | date: 'dd/MM/yy' }}</small>
        </li>
        <li class="list-group-item">Estado: <i (click)="changeEstado(examen.id, examen.estado)"
                                               [ngClass]="{'ft-check success' : examen.estado , 'ft-zap warning': !examen.estado}"></i>
        </li>
        <li class="list-group-item">Subir tarea: <i (click)="openViewer()" class="ft-upload-cloud"></i></li>
      </ul>
    </div>

  </div>


</section>

<div class="form-group" id="barraImages" hidden>
  <div class="input-group">
            <span class="input-group-btn">
                    <span class="btn btn-default btn-file">
                        <input id="buscadorPersona" name="filebutton-normal" type="file" class="file"
                               (change)="fileChangeEvent($event)">
                    </span>
            </span>
    <input type="text" class="form-control" readonly>
  </div>
</div>



