import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdminExamenesComponent} from './admin-examenes.component';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {AdminExamenesService} from './services/admin-examenes.service';
import {SinNotaPipe} from './pipes/sin-nota.pipe';
import {MediaNotaPipe} from './pipes/media-nota.pipe';
import {GetIdPipe} from './pipes/get-id.pipe';
import {GetIdExamenPipe} from './pipes/get_id_examen.pipe';
import {ConfigExamenesComponent} from './componentes/config-examenes.component';
import { RouterModule } from '@angular/router';
import {MatGridListModule} from '@angular/material/grid-list';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    MatGridListModule,
    NgbModule,
    MatSelectModule,
    MatButtonModule
  ],
  declarations: [
    AdminExamenesComponent,
    ConfigExamenesComponent,
    SinNotaPipe,
    MediaNotaPipe,
    GetIdPipe,
    GetIdExamenPipe
  ],
  providers: [AdminExamenesService],
  exports: [AdminExamenesComponent, ConfigExamenesComponent]
})
export class AdminExamenesModule { }
