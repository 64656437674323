import {NgModule} from '@angular/core';
import {AgendaComponent} from './agenda-aula/agenda.component';
import {ScreenService} from './services/screen.service';
import {RepartirAulasPipe} from './pipes/repartir-aulas.pipe';
import {CommonModule} from '@angular/common';
import {HttpService} from './services/http.service';
import {AgendaMaestroComponent} from './agenda-maestro/agenda-maestro.component';
import {CalendarWeekModule} from 'angular-calendar';
import {NgbTimepickerModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule} from '@angular/forms';
import { AgendaAlumnoComponent } from './agenda-alumno/agenda-alumno.component';
import { AgendaPadreComponent } from './agenda-padre/agenda-padre.component';

@NgModule({
  imports: [
    CommonModule,
    CalendarWeekModule,
    NgbTimepickerModule,
    FormsModule
  ],
  declarations: [
    AgendaComponent,
    RepartirAulasPipe,
    AgendaMaestroComponent,
    AgendaAlumnoComponent,
    AgendaPadreComponent
  ],
  providers: [ScreenService, HttpService],
  exports: [AgendaComponent, AgendaMaestroComponent, AgendaAlumnoComponent]
})
export class AgendaModule {
}
