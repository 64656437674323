import {
  Component, ViewChild, OnInit, ViewEncapsulation
} from '@angular/core';
import {UsersService} from '../services/users.service';
import {NgForm} from '@angular/forms';
import {Datos} from '../../../../modelos/datos.modelo';
import { ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-bancarios',
  templateUrl: './bancarios.component.html',
  styleUrls: ['../users.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BancariosComponent implements OnInit {
  dato: Datos;
  activeIban: boolean;
  type: string;
  alumno: boolean;
  active_c: boolean;
  iban: any;
  matricula: any;
  formaPagoMatricula: any;
  cuota: any;
  formaPagoCuota: any;
  titularNombre: string;
  titularApellidos: string;
  idtitular: any;
  @ViewChild('c') loginFormC: NgForm;


  constructor(private _user: UsersService, private _route: ActivatedRoute) {
    this.dato = new Datos(0,
      '', '', '', '', new Date(), '', '', [], 0, 0, '', [], [], [], false, false, '', '', '', '',  {nivel: '', id: ''}, '', '', '', '');

    const id = this._route.snapshot.parent.params['id'];
    this.type = this._route.snapshot.parent.params['type'];
    if (this.type !== 'profesores' && this.type !== 'administrativos') {
      this.alumno = true;
    }
    this._user.getDato(id);
  }

  ngOnInit() {
    this._user.dato$.subscribe(res => {
      console.log(res);

      if (!res.iban.id) {
        this.activeIban = true;
      }
      this.dato = res;
      this.iban = this.dato.iban['dato'];
      this.matricula = this.dato.iban['importeMatricula'];
      this.formaPagoMatricula = this.dato.iban['formaPagoMatricula'];
      this.cuota = this.dato.iban['importeCuota'];
      this.formaPagoCuota = this.dato.iban['formaPagoCuota'];
      this.titularNombre = this.dato.iban['nombre'];
      this.titularApellidos = this.dato.iban['apellidos'];
      this.idtitular = this.dato.iban['id']
    });


  }

  onSubmit() {
    if (this.loginFormC.valid) {
      this.loginFormC.value.id = this.dato.id ;
      console.log(this.loginFormC.value);
      this._user.addIban(this.loginFormC.value);
      this.activeIban = false;
    }
  }

  print(event){
    this.loginFormC.value.id_titular = + event.target.value;
  }


}
