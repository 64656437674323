import {Component, ViewEncapsulation} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UsersComponent {
  public id: string;
  public type: string;
  public active: boolean;
  navLinks = [{}];


  constructor(private _route: ActivatedRoute, private _router: Router) {
    this._route.params.subscribe(params => {
      this.id = params['id'];
      this.type = params['type'];
      this.getLinks(this.id, this.type);
    });
  }

  getLinks(id, type) {
      console.log(type);
      if (type === 'alumnos') {
        this.navLinks = [
          {path: 'dato', label: 'Datos'},
          {path: 'documentacion', label: 'Documentacion'},
          {path: 'contacto', label: 'Contacto'},
          {path: 'tutores', label: 'Tutores'},
          {path: 'observaciones', label: 'Observaciones internas '},
          {path: 'bancarios', label: 'Bancarios'},
          {path: 'titulos', label: 'Titulos'},
          {path: 'credenciales', label: 'Credenciales'},
          {path: 'asistencias', label: 'Asistencias'},
          {path: 'convalidaciones', label: 'Convalidaciones'},
          {path: 'cursosextracurriculares', label: 'Cursos Extracurriculares'},
          {path: 'historial', label: 'Historial de Nota'},
        ];
      }
    if (type === 'padres') {
      this.navLinks = [
        {path: 'dato', label: 'Datos'},
        {path: 'alumnos', label: 'Alumnos'},
        {path: 'contacto', label: 'Contacto'},
        {path: 'bancarios', label: 'Bancarios'},
        {path: 'titulos', label: 'Titulos'},
        {path: 'credenciales', label: 'Credenciales'},
      ];
    }
    if (type === 'profesores' || type === 'administrativos')  {
        this.navLinks = [
          {path: 'dato', label: 'Datos'},
          {path: 'contacto', label: 'Contacto'},
          {path: 'bancarios', label: 'Bancarios'},
          {path: 'titulos', label: 'Titulos'},
          {path: 'credenciales', label: 'Credenciales'},
          {path: 'horario', label: 'Horarios'},
          {path: 'jornada', label: 'Control de Jornada'},
          {path: 'documentacion', label: 'Documentacion'}
        ];
      }
  }
}
