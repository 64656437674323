import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {AdminAlumnoService} from '../services/admin-alumno.service';


@Component({
  selector: 'app-alumno-notas',
  templateUrl: './alumno-notas.component.html',
  styleUrls: ['../alumno-asignaturas.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AlumnoNotasComponent implements OnInit, OnDestroy {
  public examenes: Array<any> = [];
  public examenesSubscription: Subscription = null;
  public id: number;
  public type: string;
  public order: boolean;
  navLinks = [{}];
  asignatura: number;

  constructor (private _route: ActivatedRoute, private _router: Router, private _admin: AdminAlumnoService) {
    this.asignatura = this._route.snapshot.parent.params['asignatura'];
    this._admin.getExamenesAsignatura(this.asignatura);
  }

  ngOnInit () {
    this.examenesSubscription = this._admin.datos$.subscribe(res => {
      this.examenes = res;
    });
  }

  ngOnDestroy () {
    this.examenesSubscription.unsubscribe();
  }

  ordenar (type: string) {
    this.order = !this.order;
    this._admin.getExamenes(this.order, type);
  }
}

