import { Component, OnInit } from '@angular/core';
import {DocumentosService} from '../../services/documentos.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-gestor-documental',
  templateUrl: './gestor-documental.component.html',
  styleUrls: ['./gestor-documental.component.scss']
})
export class GestorDocumentalComponent implements OnInit {
  tipo: any;
  tipos: any[] = [];
  active = false;
  edit = false;
  id: any;
  i: any;

  constructor(private serviceDocumental: DocumentosService) {
    this.getTipos()
  }

  ngOnInit(): void {
  }

  getTipos(): void {
    this.serviceDocumental.getTipoDocumentos().subscribe((res) => {
      console.log(res['id']);
      this.tipos = res['id'];
    }, (error) => {
      console.log(error)
    });
  }

  editar(id, tipo, index): void {
    this.edit = true;
    this.active = true;
    this.id = id;
    this.tipo = tipo;
    this.i = index;
  }

  Guardar() {
    if (this.edit === true) {
      console.log('voy a editar')
      const datos = { id: this.id, tipo: this.tipo, alumno: this.tipos[this.i].alumno,
        personal: this.tipos[this.i].personal};
      this.serviceDocumental.updateTipoDocumento(datos).subscribe((res) => {
        if (res['code'] === 200) {
          this.getTipos();
          this.active = false;
          Swal.fire('', 'Se ha actualizado correctamente', 'success');
        }
      }, (error) => {
        console.log(error)
      });
    } else {
      const datos = { tipo: this.tipo};
      this.serviceDocumental.addTipoDocumento(datos).subscribe((res) => {
        if (res) {
          this.tipos.push(res)  ;
          this.active = false;
          Swal.fire('', 'Se ha guardado correctamente', 'success');
        }
      }, (error) => {
        console.log(error)
      });
    }
  }

  abrir() {
    this.edit = false;
    this.active = !this.active;
    this.id = '';
    this.tipo = '';
  }

  alumno(j) {
    const datos = { id: this.tipos[j].id, tipo: this.tipos[j].tipo,
      alumno: this.tipos[j].alumno, personal: this.tipos[j].personal};
    this.serviceDocumental.updateTipoDocumento(datos).subscribe((res) => {
      if (res['code'] === 200) {
        this.getTipos();
      }
    }, (error) => {
      console.log(error)
    });
  }

  personal(i) {
    const datos = { id: this.tipos[i].id, tipo: this.tipos[i].tipo,
      alumno: this.tipos[i].alumno, personal: this.tipos[i].personal};
    this.serviceDocumental.updateTipoDocumento(datos).subscribe((res) => {
      if (res['code'] === 200) {
        this.getTipos();
      }
    }, (error) => {
      console.log(error)
    });
  }


}

