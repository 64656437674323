import {Component, Renderer2, ChangeDetectorRef, ChangeDetectionStrategy, OnInit} from '@angular/core';
import {AgendaMService} from './agenda.service';
import {Agenda} from '../utiles/Config';
import {Router} from '@angular/router';


@Component({
  selector: 'app-agendaM',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss']

})
export class AgendaMComponent implements OnInit {
  end: number;
  finish: boolean;
  clases: Array<any> = [{}];
  color: string;
  colores: any[] = [];
  profesores: Array<any> = [];
  asignaturas: Array<any> = [];
  dias: any[] = [];
  agenda: any;
  bloques: any[] = [];


  constructor(private renderer: Renderer2, private cdRef: ChangeDetectorRef,
              private _agenda: AgendaMService, private _router: Router) {
    this._agenda.getAula();
    this.finish = true;
    this.colores = [
      '#F9F9F9',
      '#B5A795',
      '#F4F9D7',
      '#C7E6C7',
      '#CFFFF0',
      '#D3F2F4',
      '#B4E8E8',
      '#FFEFBC',
      '#FEF7E0',
      '#F8DECA'
    ];
    this.dias = ['lunes', 'martes', 'miercoles', 'jueves', 'viernes'];
  }

  ngOnInit() {
    this._agenda.datos$.subscribe(res => {
      this.clases = res;
      for (let i = 0; i < res.length; i++) {
        this.clases[i].id_ =
          res[i].id_asignatura + res[i].id_profesor + res[i].id;

      }


      this.array_bloques();
      this.agenda = Agenda;
      this.end = Agenda.bloques * this.dias.length;
    });
  }

  array_bloques() {
    for (let e = 0; e < Agenda.bloques; e++) {
      this.bloques[e] = e;
    }
  }

  charge_aula(dia: string, posicion: number) {
    this.end = this.end - 1;
    if (this.end === 0) {
      this.finish = false;
    }

    if (this.finish) {
      for (let i = 0; i < this.clases.length; i++) {
        const p = parseInt(this.clases[i].posicion) - 1;
        if (p === posicion && this.clases[i].dia === dia) {
          const id = dia + '_' + posicion;
          const content = document.getElementById(id);

          const div = this.crearDiv(
            this.clases[i].id,
            this.clases[i].asignatura,
            this.clases[i].nombre,
            this.clases[i].id_asignatura,
            this.clases[i].id_profesor
          );
          // this.renderer.setStyle(div, 'background-color', this.clases[i].color);
          content.appendChild(div).firstChild;
        }
      }
    }

    this.cdRef.markForCheck();
  }


  crearDiv(data, a, p, ia, ip): any {
    const elem = this.renderer.createElement('div');
    const id = data;
    this.renderer.addClass(elem, 'col-12');
    this.renderer.addClass(elem, 'clases_llena');
    this.renderer.setAttribute(elem, 'id', id);
    this.renderer.setStyle(elem, 'widht', '100%');
    this.renderer.setStyle(elem, 'height', '79px');
    // this.renderer.setStyle(elem, 'background-color', this.color);
    this.color = undefined;
    // this.renderer.listen(elem, 'click', (event: any) => {
    //   this.open_list(id);
    // });

    const asignatura = this.renderer.createText(a);
    const profesor = this.renderer.createText(p);
    this.renderer.appendChild(elem, asignatura);
    const salto = this.renderer.createElement('br');
    this.renderer.appendChild(elem, salto);
    this.renderer.appendChild(elem, profesor);


    return elem;
  }

  open_list(id) {
    this._router.navigate(['/maestro/asistencias/lista-alumnos/' + id]);
  }

}
