<section id="simple">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">Examenes alumno</h5>
        </div>
        <table class="table table-striped tablaexamenes">
          <thead>
            <tr>
              <th scope="col">Asignatura
                <!-- <i [ngClass]="{'ft-chevron-up': order , 'ft-chevron-down': !order}" (click)="ordenar('asignatura')"></i> -->
              </th>
              <th scope="col">Examen
                <!-- <i [ngClass]="{'ft-chevron-up': order , 'ft-chevron-down': !order}" (click)="ordenar('examen')"></i> -->
              </th>
              <th scope="col">Tipo
                <!-- <i [ngClass]="{'ft-chevron-up': order , 'ft-chevron-down': !order}" (click)="ordenar('tipo')"></i> -->
              </th>
              <th scope="col">Nota
                <!-- <i [ngClass]="{'ft-chevron-up': order , 'ft-chevron-down': !order}" (click)="ordenar('nota')"></i> -->
              </th>
              <th scope="col">Fecha
                <!-- <i [ngClass]="{'ft-chevron-up': order , 'ft-chevron-down': !order}" (click)="ordenar('fecha_')"></i> -->
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let examen of examenes">
              <td>{{examen.asignatura}}</td>
              <td>{{examen.examen}}</td>
              <td>{{examen.tipo}}</td>
              <td>{{examen.nota}}</td>
              <td>{{ examen.fecha.date | date: "dd-MM-yy"}} </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>