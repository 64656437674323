import { AgendaMService } from './agenda.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PipesModule } from '../pipes.module';
import { AgendaMComponent } from './agenda.component';



@NgModule({
    imports: [
        CommonModule,
        PipesModule
    ],
    declarations: [
        AgendaMComponent,

    ],

    providers: [AgendaMService],

    exports: [AgendaMComponent]
})
export class AgendaModule { }
