<h2 mat-dialog-title class="text-center">Añadir ciclo</h2>
<mat-dialog-content class="text-center">
  <div [formGroup]="cicloForm"   style="padding: 16px">
    <div class="row">
      <!--- LINEA OBSERVACION --->

      <div class="col-8">
        <label class="form-label">Ciclo </label>
        <select class="custom-select form-control"  formControlName="ciclo"  >
          <option selected value="">Selecciona</option>
          <option *ngFor="let ciclo of ciclos" [value]="ciclo.id">{{ciclo.ciclo}}-{{ciclo.modalidad}}</option>
        </select>

      </div>

      <div class="col-4">
        <label class="form-label">Aforo</label>
        <input type="text" class="form-control" formControlName="aforo" >
      </div>

    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions  >
  <div class="row">
    <div class="col-1"></div>
    <div class="col-10">
      <button mat-button mat-dialog-close  class="aceptar float-left" >Cerrar</button>
      <button mat-button [mat-dialog-close]="cicloForm" class="aceptar" [disabled]="!cicloForm.valid" >Guardar</button>
    </div>
    <div class="col-1"></div>
  </div>
</mat-dialog-actions>
