<h2 mat-dialog-title class="text-center">{{data.update === false ? 'Añadir' : 'Editar'}} contacto de matriculación</h2>
  <mat-dialog-content>
    <form [formGroup]="inputForm" autocomplete="off">
      <div class="form-body">
        <div class="row">
          <div class="col-md-3">
            <label class="form-label">Nombre contacto</label>
            <mat-form-field appearance="outline" class="col-md-12">
              <input matInput matNativeControl type="text" formControlName="contactoNombre">
            </mat-form-field>
          </div>

          <div class="col-md-3">
            <label class="form-label">Apellidos contacto</label>
            <mat-form-field appearance="outline" class="col-md-12">
              <input matInput matNativeControl type="text" formControlName="contactoApellidos">
            </mat-form-field>
          </div>

          <div class="col-md-3">
            <label class="form-label">Donde nos ha encontrado <i class="ft-plus-circle"  (click)="addReferido()"></i></label>
            <mat-form-field appearance="outline" class="col-md-12">
              <select matInput matNativeControl formControlName="referido">
                <option selected value="">Selecciona</option>
                <option *ngFor="let ref of referidos" [value]="ref.id">{{ref.descripcion}}</option>
              </select>
            </mat-form-field>
          </div>

          <div class="col-md-3">
            <label class="form-label">Año inicio</label>
            <mat-form-field appearance="outline" class="col-md-12">
              <select matInput matNativeControl formControlName="cursoEscolar" (change)="filterCiclosByCursoEscolar()">
                <option selected value="">Selecciona</option>
                <option *ngFor="let curso of cursosEscolares" [value]="curso.id">{{curso.cursoEscolar | slice: 0:4}} </option>
              </select>
            </mat-form-field>
          </div>



          <div class="col-md-2">
            <label class="form-label">Email contacto</label>
            <mat-form-field appearance="outline" class="col-md-12">
              <input matInput matNativeControl type="email" formControlName="email">
            </mat-form-field>
          </div>

          <div class="col-md-2">
            <label class="form-label">Telefono </label>
            <mat-form-field appearance="outline" class="col-md-12">
              <input matInput matNativeControl type="text" formControlName="telefono">
            </mat-form-field>
          </div>

          <div class="col-md-3">
            <label class="form-label">Nombre alumno</label>
            <mat-form-field appearance="outline" class="col-md-12">
              <input matInput matNativeControl type="text" formControlName="alNombre">
            </mat-form-field>
          </div>

          <div class="col-md-3">
            <label class="form-label">Apellidos alumno</label>
            <mat-form-field appearance="outline" class="col-md-12">
              <input matInput matNativeControl type="text" formControlName="alApellidos">
            </mat-form-field>
          </div>

          <div class="col-md-2">
            <label class="form-label">Ciclo <i class="ft-plus-circle"   (click)="addCiclo()"></i></label>
            <mat-form-field appearance="outline" class="col-md-12">
              <select matInput matNativeControl formControlName="ciclo">
                <option selected value="">Selecciona</option>
                <option *ngFor="let ciclo of ciclosFiltered" [value]="ciclo.id">{{ciclo.ciclo.ciclo}}-{{ciclo.ciclo.modalidad}}</option>
              </select>
            </mat-form-field>
          </div>

          <div class="col-md-2">
            <label class="form-label">Medio de contacto <i class="ft-plus-circle"  (click)="addTipoContacto()"></i></label>
            <mat-form-field appearance="outline" class="col-md-12">
              <select matInput matNativeControl formControlName="formaContacto" >
                <option selected value="">Selecciona</option>
                <option *ngFor="let con of formaContacto" [value]="con.id">{{con.forma_contacto}}</option>
              </select>
            </mat-form-field>
          </div>

          <div class="col-md-2">
            <label class="form-label">Estado de contacto</label>
            <mat-form-field appearance="outline" class="col-md-12">
              <select matInput matNativeControl formControlName="estado" >
                <option selected value="">Selecciona</option>
                <option *ngFor="let stat of estados" [value]="stat.id">{{stat.descripcion}}</option>
              </select>
            </mat-form-field>
          </div>



          <div class="col-md-8">
            <label class="form-label">Observaciones</label>
            <mat-form-field appearance="outline" class="col-md-12">
              <textarea matInput matNativeControl formControlName="observaciones" rows="4"></textarea>
            </mat-form-field>
          </div>

          <div class="col-md-3">

            <mat-form-field appearance="standard" class="col-md-12">
              <input matInput [hidden]="true" >
              <mat-checkbox  formControlName="enviar">Enviar email a comercial</mat-checkbox>
            </mat-form-field>
          </div>

          <div class="col-md-3">
            <label class="form-label">Email Comercial</label>
            <mat-form-field appearance="outline" class="col-md-12">
              <input matInput matNativeControl type="text" formControlName="emailComercial" >

            </mat-form-field>
          </div>




        </div>
      </div>
      <div class="form-actions row">
        <div class="col-6">
          <button mat-button matDialogClose class="btn btn-raised btn-secondary float-right cerrar">
            <i class="ft-x"></i> Cancelar
          </button>
        </div>
        <div class="col-6">
          <button mat-button (click)="data.update === false ? guardar() : editar()" class="btn btn-raised btn-primary float-left"
                  [disabled]="!inputForm.valid"> <i class="ft-save"></i> {{data.update === false ? 'Guardar' : 'Editar'}}
          </button>
        </div>
      </div>
    </form>
  </mat-dialog-content>

