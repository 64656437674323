<div class="col-12">
    <div class="row row_dias">
        <div *ngFor="let dia of dias;" class="col-2 dia">{{dia}}</div>
    </div>

    <div class="row" id="contenedor">
        <div *ngFor="let dia of dias; let e = index" class="col-2 bloque_vacio" id={{dias[e]}}>
            <ng-container *ngFor="let bloque of bloques; let i = index">
                <div class="col-12 bloque img-thumbnail" id={{dias[e]}}_{{i}} (drop)="drop($event)" (dragover)='allowDrop($event)'>

                    {{charge_aula(dias[e], i )}}
                </div>
                <!-- DESCANSO -->
                <ng-container *ngFor='let descanso of agenda.descanso; let a = index'>
                    <div *ngIf="i == descanso" class="col-12 descanso"></div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>