import { Component, OnInit } from '@angular/core';
import {HijosPadreService} from '../../../../padre/hijos-padre/services/hijos-padre.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-alumnos',
  templateUrl: './alumnos.component.html',
  styleUrls: ['./alumnos.component.scss']
})
export class AlumnosComponent implements OnInit {
  id: any;
  users: any;
  constructor(private servicePadre: HijosPadreService, private activatedRoute: ActivatedRoute) {
    this.id = this.activatedRoute.snapshot.parent.params['id'];
    console.log(this.id);
  }

  ngOnInit(): void {
    this.servicePadre.hijosGet(this.id).subscribe((resp) => {
      console.log(resp['id']);
      this.users = resp['id'];
    })
  }

}
