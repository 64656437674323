import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Subject} from 'rxjs/Subject';
import {Router} from '@angular/router';
import {Rutas} from '../../../../utiles/Rutas';
import {LoginService} from '../../../pages/services/login.service';


@Injectable()
export class AulaService {
  public alumnosSource = new Subject<any>();
  public alumnos$ = this.alumnosSource.asObservable();

  private alumnosAulaSource = new Subject<any>();
  public alumnosAula$ = this.alumnosAulaSource.asObservable();

  constructor(private http: HttpClient, private router: Router, private _login: LoginService) {
  }

  getAlumnosAula(id: number) {
    this.http.get(Rutas + 'alumnos/aula?id=' + id + '&token=' + localStorage.getItem('token')).subscribe(data => {
      console.log(data);
      if (data['code'] === 200) {
        this.alumnosAulaSource.next(data['id']);
      }
    }, err => {
      console.log(err);
    });
  }


  delete(id: number, cursoId: number) {
    return new Promise((reject, resolve) => {
      // tslint:disable-next-line:max-line-length
      this.http.get(Rutas + 'delete/alumno/aula?id=' + id + '&curso=' + cursoId + '&token=' + localStorage.getItem('token')).subscribe(data => {
        if (data['code'] === 200) {
          this.alumnosAulaSource.next(data['id']);
          console.log(data);
          reject(true);
        }
      }, err => {
        resolve(false);
        console.log(err);
      });
    });
  }
 //GHOla

  getAlumnosModulo(id: number) {
    this.http.get(Rutas + 'get/promociones_alumno_aula?idAula=' + id + '&token=' + localStorage.getItem('token')).subscribe(data => {
      if (data['code'] === 200) {
        console.log('ESTOS SONNNNNN',  data);
        this.alumnosSource.next(data['id']);
      } else {
        return false;
      }
    });
  }

  add(id: number, cursoId: number) {
    return new Promise((reject, resolve) => {
      const centro = this._login.getIdentity().centro.id;
      const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
      const json = {id: id, cursoId: cursoId};
      const data = JSON.stringify(json);
      const params = 'json=' + data + '&token=' + localStorage.getItem('token');
      this.http.post(Rutas + 'add/alumno/modulo', params, {headers: headers}).subscribe(
        datas => {
          if (datas['code'] === 200) {
            this.alumnosAulaSource.next(datas['id']);
            reject(true);
          }
        },
        error => {
          resolve(false);
          console.log(error);
        }
      );
    });
  }

  finalizarAula(id) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {curso: id};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    return  this.http.post(Rutas + 'end/aula', params, {headers: headers})
  }

  vaciarAula(id) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {curso: id};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    return  this.http.post(Rutas + 'empty/aula', params, {headers: headers})
  }

  moveAlumnosAula(id, alumnos) {
    console.log(id);
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const json = {aulaId: id, alumnos: alumnos};
    const data = JSON.stringify(json);
    const params = 'json=' + data + '&token=' + localStorage.getItem('token');
    return  this.http.post(Rutas + 'move/alumnos/aula', params, {headers: headers})
  }
}
