import {Component, OnDestroy, OnInit} from '@angular/core';
import {CursosService} from '../services/cursos.service';
import {Subscription} from 'rxjs/Subscription';
import swal from 'sweetalert2';

@Component({
  selector: 'app-cursos-lista',
  templateUrl: './cursos-lista.component.html'
})

export class CursosListaComponent implements OnDestroy, OnInit {

  rows = [];
  loadingIndicator = true;
  reorderable = true;
  public asignaturas = [];
  public table: boolean;
  private cursosSubscription: Subscription = new Subscription();
  private asignaturaSubscription: Subscription = new Subscription();


  constructor (private _cursos: CursosService) {
    this._cursos.getCursos();
    setTimeout(() => {
      this.loadingIndicator = false;
    }, 1500);
  }


  ngOnInit (): void {
    this.cursosSubscription = this._cursos.curso$.subscribe(data => {
      this.rows = data;
    });
  }

  ngOnDestroy (): void {
    if (this.cursosSubscription !== null) {
      this.cursosSubscription.unsubscribe();
    }

  }

  delete (id: any, position: number) {
    swal.fire({
      title: 'BORRAR',
      text: '¿Seguro que quiere borrar ' +  this.rows[position].nivel + '?',
      icon: 'error',
      showCancelButton: true,
      confirmButtonText: 'Si, Eliminar!',
      cancelButtonText: 'No, Cancelar!',
      reverseButtons: true
    })
      .then((result) => {
        if (result.isConfirmed) {
          swal.fire('Mensaje', 'Se ha eliminado ' + this.rows[position].nivel, 'success');
          this._cursos.deleteCursos(id);
          this.rows.slice(position, 1);
        }
      });
  }


  view (id: number) {

    this._cursos.getAsignaturas(id);
    this.asignaturaSubscription = this._cursos.asignatura$.subscribe(data => {
      this.asignaturas = data;
      this.table = true;
    });
  }

  open (event) {

    this.table = event;
  }


}


