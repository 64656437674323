<h2 mat-dialog-title class="text-center">Cambiar estado</h2>
<mat-dialog-content class="text-center">
  <h6>¿Esta seguro de que desea cambiar el estado?</h6>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="row">
    <div class="col-1"></div>
    <div class="col-10">
      <button mat-button matDialogClose  class="aceptar float-left" >Cerrar</button>
      <button mat-button [mat-dialog-close]="true" class="aceptar"  >Guardar</button>
    </div>
    <div class="col-1"></div>
  </div>
</mat-dialog-actions>
