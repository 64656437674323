import {Injectable} from '@angular/core';
import {LoginService} from '../../../pages/services/login.service';
import {HttpClient} from '@angular/common/http';
import {Rutas} from '../../../../utiles/Rutas';
import {Subject} from 'rxjs/Subject';


@Injectable()
export class HijosPadreService {
  id: number;
  token: string;
  private datosSource = new Subject<any>();
  datos$ = this.datosSource.asObservable();

  private asistenciasSource = new Subject<any>();
  asistencias$ = this.asistenciasSource.asObservable();

  private tareasSource = new Subject<any>();
  tareas$ = this.tareasSource.asObservable();

  private examenesSource = new Subject<any>();
  examenes$ = this.examenesSource.asObservable();

  constructor (private _login: LoginService, private http: HttpClient) {
    this.id = this._login.getIdentity().id;
    this.token = localStorage.getItem('token');
  }

  getHijos () {
    this.http.get(Rutas + 'padre/lista?id=' + this.id + '&token=' + this.token).subscribe(res => {
      if (res['code'] === 200) {
        this.datosSource.next(res['id']);
      }
    }, err => {
      console.log(err);
    });
  }

  hijosGet (id: number) {
    return this.http.get(Rutas + 'padre/lista?id=' + id + '&token=' + this.token);
  }

  getAsistencias(id: number) {
    this.http.get(Rutas + 'padre/asistencia?id=' + id + '&token=' + this.token).subscribe(res => {
      if (res['code'] === 200) {
        this.asistenciasSource.next(res['id']);
      }
    }, err => {
      console.log(err);
    });
  }

  getTareas(id: number) {
    this.http.get(Rutas + 'padre/tasks?id=' + id + '&token=' + this.token).subscribe(res => {
      if (res['code'] === 200) {
        this.tareasSource.next(res['id']);
      }
    }, err => {
      console.log(err);
    });
  }

  getExamenes(id: number) {
    this.http.get(Rutas + 'padre/examenes?id=' + id + '&token=' + this.token).subscribe(res => {
      if (res['code'] === 200) {
        this.examenesSource.next(res['id']);
      }
    }, err => {
      console.log(err);
    });
  }


}
