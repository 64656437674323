import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule} from '@angular/forms';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {InboxModule} from '../../admin/inbox/inbox.module';
import {TaskboardModule} from '../../../taskboard/taskboard.module';
import {PipesModule} from '../../../pipes.module';
import {MatTabsModule} from '@angular/material/tabs';
import {Ng2SmartTableModule} from 'ng2-smart-table';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {QuillModule} from 'ngx-quill';
import {AgendaModule} from '../../../agenda/agenda.module';

// BASE
import {FooterComponent} from './footer/footer.component';
import {NavbarComponent} from './navbar/navbar.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {CustomizerComponent} from './customizer/customizer.component';
import {MainComponent} from './main/main.component';
import {AlumnoComponent} from './alumno.component';

// GUARD
import {AuthGuardAlumnoService} from '../../services/auth-guard-alumno.service';

// ROUTING
import {HttpClientModule} from '@angular/common/http';
import {AlumnoRoutingModule} from './alumno.routing';
import {AdminComponent} from '../admin-alumno/admin.component';

// SERVICES
import {AdminAlumnoService} from '../asignaturas-alumno/services/admin-alumno.service';
import {AlumnoInboxService} from '../inbox/services/alumno-inbox.service';

// COMPONENTES
import {AlumnoAsistenciasComponent} from '../asignaturas-alumno/componentes/alumno-asistencias.component';
import {AlumnoExamenComponent} from '../admin-alumno/componentes/alumno-examen.component';
import {AlumnoTaskComponent} from '../asignaturas-alumno/componentes/alumno-task.component';
import {UploadExamenComponent} from '../admin-alumno/componentes/upload-examen.component';
import {AlumnoAsignaturasComponent} from '../asignaturas-alumno/alumno-asignaturas.component';
import {AlumnoListadoComponent} from '../asignaturas-alumno/componentes/alumno-listado.component';
import {AlumnoNotasComponent} from '../asignaturas-alumno/componentes/alumno-notas.component';
import {AlumnoInboxComponent} from '../inbox/alumno-inbox.component';
import {Horarios} from '../../../utiles/Config';
import {HorariosAlumnoComponent} from '../horarios-alumno/horarios-alumno.component';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        AlumnoRoutingModule,
        HttpClientModule,
        FormsModule,
        NgxDatatableModule,
        InboxModule,
        TaskboardModule,
        PipesModule,
        MatTabsModule,
        Ng2SmartTableModule,
        BrowserAnimationsModule,
        QuillModule,
        AgendaModule,
        SweetAlert2Module,
    ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    MainComponent,
    AlumnoComponent,
    CustomizerComponent,
    SidebarComponent,
    AdminComponent,
    AlumnoAsistenciasComponent,
    AlumnoExamenComponent,
    AlumnoTaskComponent,
    UploadExamenComponent,
    AlumnoAsignaturasComponent,
    AlumnoListadoComponent,
    AlumnoNotasComponent,
    AlumnoInboxComponent,
    HorariosAlumnoComponent
  ],
  providers: [
    AuthGuardAlumnoService,
    AdminAlumnoService,
    AlumnoInboxService
  ],
  exports: [

  ]
})
export class AlumnoModule {
}
